<script setup lang="ts">
import { useTools } from '@/composables/tools'
import type { GridApi, GridReadyEvent } from 'ag-grid-community'
import { themeQuartz } from 'ag-grid-community'
import type { 
  Panel as PanelType,
  PanelData
} from '@/types/projectdock'
import type { Tool } from '@/types/tools'
import { columnSchema as defaultColumnSchema, columnDisplayLabels } from '@/types/schedule'
import { toRef, watch, computed, ref, nextTick, onMounted, onBeforeUnmount } from 'vue'
import { storeToRefs } from 'pinia'
import { useDockStore } from '@/stores/dockStore'
import { Button } from '@/components/ui/button'
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger 
} from '@/components/ui/dropdown-menu'
import { usePanelConnections } from '@/composables/usePanelConnections'
import { useLayoutStore } from '@edanweis/vue-code-layout'
import type { LayoutStore } from '@edanweis/vue-code-layout'
import { useMitter } from '#imports'
import { usePanelDataTransformers } from '@/composables/useTransformers'
import { useDebounceFn } from '@vueuse/core'
import { useResizeObserver } from '@vueuse/core'
import { useMouse, useElementSize } from '@vueuse/core'
import type { ColDef } from 'ag-grid-community'
import { usePlantGrid } from '@/composables/tools/usePlantGrid'

// Import AG Grid components client-side only
let AutocompleteSelectCellEditor: any = null
let AgGridVue: any = null

if (import.meta.client) {
  AutocompleteSelectCellEditor = (await import('ag-grid-autocomplete-editor')).AutocompleteSelectCellEditor
  AgGridVue = (await import('ag-grid-vue3')).AgGridVue
}

// Define a type for the panel connections return value
interface PanelConnectionsType {
  emitData: (dataType: string, data: any) => void;
  [key: string]: any;
}

// Create a reactive reference to the layout store that will be initialized when available
const layoutStoreRef = ref<LayoutStore | null>(null)

// Function to safely get the layout store
const getLayoutStore = () => {
  if (!import.meta.client || !window.VUE_CODE_LAYOUT_READY) {
    console.log('Layout system not ready yet, will try again later')
    return null
  }
  
  try {
    return useLayoutStore()
  } catch (error) {
    console.warn('Could not access layout store:', error)
    return null
  }
}

// Try to initialize the layout store
const initLayoutStore = () => {
  const store = getLayoutStore()
  if (store) {
    layoutStoreRef.value = store
    return true
  }
  return false
}

const colorMode = useColorMode()

// Update the Panel prop type to match what's actually being passed
interface Panel extends PanelType {
  name: string;
  id?: string;
  data: PanelData;
  // Other properties that might be available
  title?: string;
  tooltip?: string;
  iconSmall?: string;
}

const {panel, height, tool} = defineProps<{
  panel: Panel
  height?: number | string
  tool?: Tool
}>()

// Define emits to fix the "ReferenceError: emit is not defined" error
const emit = defineEmits(['update:modelValue', 'data-update', 'error'])

const parentElement = ref<HTMLElement>()
const {width: parentElWidth } = useElementSize(parentElement)

// Add grid state
const gridApi = ref<GridApi>()

// Add store
const dockStore = useDockStore()
const { defaultApiService } = storeToRefs(dockStore)

// Add zoom level state
const zoomLevel = ref(1)
const MIN_ZOOM = 0.5
const MAX_ZOOM = 2

// Add toggle for sizing
const useFitContents = ref(true)

// Initialize panel connections as a reactive reference that will be set when available
const panelConnectionsRef = ref<PanelConnectionsType | null>(null)

// Function to safely initialize panel connections
const initPanelConnections = () => {
  if (!panel.name) {
    console.warn('[DEBUG] Cannot initialize panel connections: no panel.name available')
    return false
  }
  
  try {
    // Only initialize if we don't already have connections
    if (!panelConnectionsRef.value) {
      console.log(`[DEBUG] Initializing panel connections for panel ${panel.name}`)
      
      // Check if we're already initializing to prevent duplicate calls
      if (window._panelConnectionsInitializing && window._panelConnectionsInitializing[panel.name]) {
        console.log(`[DEBUG] Panel connections for ${panel.name} already being initialized, skipping duplicate call`)
        return false
      }
      
      // Mark this panel as being initialized
      if (!window._panelConnectionsInitializing) window._panelConnectionsInitializing = {};
      window._panelConnectionsInitializing[panel.name] = true;
      
      const connections = usePanelConnections(panel.name)
      
      if (connections) {
        panelConnectionsRef.value = connections as PanelConnectionsType
        
        // Ensure panel.data.connections is initialized
        if (!panel.data.connections) {
          panel.data.connections = []
        }
        
        // Sync any existing connections from panel.data
        if (Array.isArray(panel.data.connections) && panel.data.connections.length > 0) {
          console.log(`[DEBUG] Restoring ${panel.data.connections.length} existing connections for panel ${panel.name}`)
          panel.data.connections.forEach(targetPanelId => {
            if (connections.connect && typeof connections.connect === 'function') {
              connections.connect(targetPanelId)
            }
          })
        }
        
        // Clear initialization flag
        if (window._panelConnectionsInitializing) {
          window._panelConnectionsInitializing[panel.name] = false;
        }
        
        return true
      }
    } else {
      console.log(`[DEBUG] Panel connections already initialized for panel ${panel.name}`)
      return true
    }
  } catch (error) {
    console.error('[DEBUG] Error initializing panel connections:', error)
  }
  
  // Clear initialization flag in case of error
  if (window._panelConnectionsInitializing) {
    window._panelConnectionsInitializing[panel.name] = false;
  }
  
  return false
}

// Add selectedApiService computed
const selectedApiService = computed({
  get: () => {
    // First try to get from panel data
    const panelService = panel.data?.options?.apiService
    if (panelService) return panelService
    
    // Otherwise use store default
    return defaultApiService.value
  },
  set: (value) => {
    // Update both panel and store
    if (!panel.data.options) {
      panel.data.options = {}
    }
    panel.data.options.apiService = value
    dockStore.setDefaultApiService(value)
  }
})

// Initialize panel data with defaults if not present
if (!panel.data.rowData) {
  panel.data.rowData = []
}
if (!panel.data.columnDefs) {
  panel.data.columnDefs = []
}

// Create reactive refs that sync with panel data
const rowData = computed({
  get: () => panel.data.rowData,
  set: (newValue) => {
    panel.data.rowData = newValue
  }
})

const columnDefs = computed({
  get: () => panel.data.columnDefs,
  set: (newValue) => {
    panel.data.columnDefs = newValue
  }
})

// Add theme parameters
const BASE_FONT_SIZE = 14
const BASE_ROW_HEIGHT = 48
const BASE_HEADER_HEIGHT = 48

const themeParams = ref({
  fontSize: BASE_FONT_SIZE,
  headerFontSize: BASE_FONT_SIZE + 2,
  rowHeight: BASE_ROW_HEIGHT,
  headerHeight: BASE_HEADER_HEIGHT,
  fontFamily: 'InterVariable, sans-serif',
  cellHorizontalPadding: 12,
  cellVerticalPadding: 16,
  rangeSelectionBorderStyle: 'solid' as const,
  rangeSelectionBorderColor: 'hsl(var(--primary) / 0.5)',
  selectedRowBackgroundColor: 'hsl(var(--muted) / 0.5)',
  accentColor: 'hsl(var(--muted))',
  backgroundColor: 'hsl(var(--background))',
  foregroundColor: 'hsl(var(--foreground))',
  headerBackgroundColor: 'hsl(var(--muted) / 0.5)',
  controlPanelBackgroundColor: 'hsl(var(--muted))',
  checkboxCheckedBackgroundColor: 'hsl(var(--primary))',
  rangeSelectionBackgroundColor: 'hsl(var(--muted) / 0.5)',
  cellEditingShadow: { offsetX: 0, offsetY: 0, radius: 0, spread: 0, color: 'rgba(0, 0, 0, 0)' },
  inputFocusShadow: { offsetX: 0, offsetY: 0, radius: 0, spread: 0, color: 'rgba(0, 0, 0, 0)' },
  rowHoverColor: 'hsl(var(--muted) / 0.5)',
  inputBorderRadius: 4,
  cellEditingBorderColor: 'hsl(var(--primary))',
  cellEditingBorder: { width: 2, color: 'hsl(var(--primary))', radius: 4 },
  inputBorder: { width: 2, color: 'hsl(var(--primary))', radius: 4 },
  inputFocusBorder: { width: 2, color: 'hsl(var(--primary))', radius: 4 }
})

// Create theme
const myTheme = themeQuartz.withParams({
  fontFamily: themeParams.value.fontFamily,
  cellHorizontalPadding: themeParams.value.cellHorizontalPadding,
  rangeSelectionBorderStyle: themeParams.value.rangeSelectionBorderStyle as 'solid',
  rangeSelectionBorderColor: themeParams.value.rangeSelectionBorderColor,
  rangeSelectionBackgroundColor: themeParams.value.rangeSelectionBackgroundColor,
  cellEditingShadow: themeParams.value.cellEditingShadow,
  inputFocusShadow: themeParams.value.inputFocusShadow,
  selectedRowBackgroundColor: themeParams.value.selectedRowBackgroundColor,
  accentColor: themeParams.value.accentColor,
  backgroundColor: themeParams.value.backgroundColor,
  foregroundColor: themeParams.value.foregroundColor,
  headerBackgroundColor: themeParams.value.headerBackgroundColor,
  checkboxCheckedBackgroundColor: themeParams.value.checkboxCheckedBackgroundColor,
  rowHoverColor: themeParams.value.rowHoverColor,
  inputBorderRadius: themeParams.value.inputBorderRadius,
  cellEditingBorder: themeParams.value.cellEditingBorder
})

// Add computed style for grid
const gridStyle = computed(() => ({
  width: '100%',
  height: '100%',
  '--ag-font-size': `${themeParams.value.fontSize}px`,
  '--ag-header-font-size': `${themeParams.value.headerFontSize}px`,
  '--ag-row-height': `${themeParams.value.rowHeight}px`,
  '--ag-header-height': `${themeParams.value.headerHeight}px`
}))

// Watch color mode changes to update grid theme
watch(() => colorMode.value, (newMode) => {
  const isDark = newMode === 'dark'
  document.body.setAttribute('data-ag-theme-mode', isDark ? 'dark' : 'light')
}, { immediate: true })

// Create a custom column definition interface that includes the properties we need
interface ExtendedColDef extends ColDef {
  headerCheckboxSelection?: boolean;
  checkboxSelection?: boolean;
  editable?: boolean;
  minWidth?: number;
  maxWidth?: number;
  suppressSizeToFit?: boolean;
  autoSize?: boolean;
  resizable?: boolean;
  field: string;
  headerName: string;
  sortable: boolean;
  filter: boolean;
  type: string;
  valueFormatter?: (params: any) => string;
  headerTooltip?: string;
  cellClass?: (params: any) => string;
}

// Create initial column definitions only if they don't exist in panel.data
if (!columnDefs.value?.length) {
  columnDefs.value = [
  {
      headerName: '',
      field: 'checkboxSelection',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 48,
      minWidth: 48,
      maxWidth: 48,
      pinned: 'left',
      lockPosition: true,
      suppressMenu: true,
      suppressMovable: true,
      sortable: false,
      filter: false,
      resizable: false,
  } as ExtendedColDef,
  {
      headerName: "Code",
      field: "code",
      type: "string",
      headerTooltip: "Plant code or identifier",
      editable: true,
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100
    },
  {
   headerName: "Botanical Name",
   field: "botanicalname",
   minWidth: 200,
   width: 250,
   suppressSizeToFit: true,
   autoSize: true,
   resizable: true,
   cellEditor: AutocompleteSelectCellEditor,
   cellEditorParams: {
       autocomplete: {
           debounceWaitMs: 100,
           fetch: async (cellEditor: any, text: any, update: any) => {
               try { 
                   let match = text?.toLowerCase() || cellEditor.eInput.value.toLowerCase();
                   
                   // Skip API calls if match is empty or just whitespace
                   if (!match || match.trim() === '') {
                       update([]);
                       return;
                   }
                   
                   // Start spell check in parallel immediately so it has time to complete
                   const spellCheckPromise = debouncedSpellCheck(match);

                   // 1. First priority: GBIF suggest API (fastest and most direct)
                   const suggestData = await $fetch<any[]>(`https://api.gbif.org/v1/species/suggest?q=${encodeURIComponent(match)}&higherTaxonKey=6`);
                   
                   let items = suggestData.map((d: any) => ({ 
                       value: d.canonicalName,
                       label: d.canonicalName,
                       group: d.rank
                   }));
                   
                   // If suggest API returned results, use them immediately
                   if (items.length > 0) {
                       update(items);
                       return;
                   }
                   
                   // 2. Second priority: GBIF search API (if suggest didn't return results)
                   if (match.length > 2) {
                       try {
                           const searchData = await $fetch<{results: any[]}>(`https://api.gbif.org/v1/species/search`, {
                               method: 'GET',
                               params: {
                                   q: match,
                                   datasetKey: 'd7dddbf4-2cf0-4f39-9b2a-bb099caae36c', // GBIF Backbone Taxonomy
                                   higherTaxonKey: 6, // Plantae
                                   limit: 10
                               }
                           });
                           
                           if (searchData.results && searchData.results.length > 0) {
                               const searchItems = searchData.results.map((result: any) => ({
                                   value: result.canonicalName,
                                   label: result.canonicalName,
                                   group: result.rank || 'Search Result'
                               }));
                               update(searchItems);
                               return;
                           }
                       } catch (searchError) {
                           console.error('Error with GBIF search API:', searchError);
                           // Continue to AI fallback if search fails
                       }
                   }
                   
                   // 3. Third priority: Gemini AI suggestions (should be resolved by now)
                   try {
                       const spellCheckResults = await spellCheckPromise;
                       if (spellCheckResults && spellCheckResults.length > 0) {
                           update(spellCheckResults);
                           return;
                       }
                   } catch (aiError) {
                       console.error('Error with AI spell check:', aiError);
                   }
                   
                   // If all methods failed, return empty results
                   update([]);
               } catch (error) {
                   console.error('Error fetching species data:', error);
                   update(false);
               }
           },
           emptyMsg: 'No results found',
           showOnFocus: true,
           strict: false,
           onFreeTextSelect: (cellEditor: any, userInput: string) => {
               console.log('[DEBUG] onFreeTextSelect:', userInput, cellEditor)
               return userInput;
           }
       },
       placeholder: 'Search for a species...',
   },
   // Add value parser to ensure we store string values
   valueParser: (params: any) => {
       if (typeof params.newValue === 'object') {
           return params.newValue?.label || '';
       }
       return params.newValue || '';
   },
   // Simplify value formatter to just return the string value
   valueFormatter: (params: any) => {
       if (typeof params.value === 'object') {
           return params.value?.label || '';
       }
       return params.value || '';
   },
   editable: true,
    },
    ...Object.entries(defaultColumnSchema.properties).map(([key, value]) => {
      const isFixedColumn = ['commonname'].includes(key.toLowerCase());
      return {
        field: key,
        headerName: columnDisplayLabels[key as keyof typeof defaultColumnSchema.properties],
        headerTooltip: `${columnDisplayLabels[key as keyof typeof defaultColumnSchema.properties]} (${value.type})`,
        type: value.type === 'number' ? 'numberColumn' : 'textColumn',
        editable: true,
        sortable: true,
        filter: true,
        resizable: true,
        suppressSizeToFit: isFixedColumn,
        autoSize: isFixedColumn,
        ...(isFixedColumn ? {
          minWidth: 180,
        } : {
          minWidth: 50
        })
      } as ExtendedColDef;
    })
  ] as ExtendedColDef[]
}

// Initialize empty row data if it doesn't exist
if (!rowData.value) {
  // Create an empty row with null values for all fields
  const emptyRow = Object.fromEntries(
    Object.keys(defaultColumnSchema.properties).map(key => [key, null])
  )
  rowData.value = [emptyRow]
}

// Add a computed property for the tool ID
const toolId = computed(() => tool?.id || 'createplantgrid')

// Add the transformers to the component setup
const { transformData } = usePanelDataTransformers()

// Function to emit grid data to connected panels
const emitGridDataChanges = useDebounceFn(async () => {
  if (!gridApi.value) {
    console.log('Grid API not available yet')
    return
  }

  // Initialize empty row data if it doesn't exist
  if (!rowData.value) {
    rowData.value = []
  }

  // Get the current grid data
  const gridData: any[] = []
  gridApi.value.forEachNode((node) => {
    if (node.data) {
      gridData.push({ ...node.data })
    }
  })
  console.log('gridData', gridData)
  // Create the data package that will be sent
  const dataPackage = {
    type: 'grid',
    columnDefs: columnDefs.value,
    rowData: gridData.map((row) => ({
      ...row,
      botanicalname: row.botanicalname?.label || row.botanicalname || ''
    }))
  }

  // Always emit the event directly to the parent
  console.log('Emitting grid data directly to parent:', gridData)
  emit('update:modelValue', gridData)
  
  // Emit the data-update event for PanelContainer
  emit('data-update', dataPackage)
  

  // Only attempt to use the layout store if it's available
  if (import.meta.client && window.VUE_CODE_LAYOUT_READY) {
    try {
      // Try to initialize layout store if not already done
      if (!layoutStoreRef.value) {
        initLayoutStore()
      }
      
      // Use the layout store and panel connections if available
      if (layoutStoreRef.value && panel.name && panelConnectionsRef.value) {
        console.log(`[DEBUG] Emitting grid data from panel ${panel.name}:`, gridData)
        
        // Transform the data for each connected panel based on their tool type
        const connectedPanels = Array.from(panelConnectionsRef.value.connections || [])
        
        for (const targetPanelId of connectedPanels) {
          // Get the target panel's tool ID
          const targetPanel = layoutStoreRef.value.layoutInstance?.getPanelById(targetPanelId)
          const targetToolId = targetPanel?.data?.tool?.id || targetPanel?.data?.tool_uid
          
          console.log('[DEBUG] sourceToolId:', toolId.value)
          if (targetToolId) {
            // Transform the data for the specific target panel
            const transformedData = await transformData(dataPackage, toolId.value, targetToolId)
            
            // Use the emitData function from the panel connections
            panelConnectionsRef.value.emitData('grid', transformedData)
          }
        }
        
        // Also emit a direct event for diagnostics panel
        const mitter = useMitter()
        console.log(`[DEBUG] Emitting panel:toolData event with panelId=${panel.name}, toolId=${toolId.value}`)
        mitter.emit('panel:toolData', {
          panelId: panel.name,
          toolId: toolId.value,
          data: dataPackage
        })
        
        mitter.emit('panel:data:validate', {
          panelId: panel.name,
          validation_tool_uid: 'name-validation-tool',
          tool_uid: toolId.value,
          data: {
            // row and column data
            rowData: rowData.value?.map(row => ({...row, botanicalname: row.botanicalname?.label || row.botanicalname || ''})) || [],
            columnDefs: columnDefs.value
          }
        })

        console.log('[DEBUG] panel:toolData event emitted successfully')
      } else {
        console.warn('[DEBUG] Cannot emit grid data via panel connections:', {
          layoutStoreAvailable: !!layoutStoreRef.value,
          panelIdAvailable: !!panel.name,
          panelConnectionsAvailable: !!panelConnectionsRef.value
        })
      }
    } catch (error) {
      console.warn('Could not access layout store for panel connections:', error)
      console.log('Continuing with direct parent emission only')
    }
  }
}, 300) // 300ms debounce delay

// Update the adjustColumnWidths function to better handle fixed columns
const adjustColumnWidths = () => {
  if (!gridApi.value) return;
  
  // First auto-size specific columns that need fixed widths
  const columnsToAutoSize = ['botanicalname', 'commonname'];
  
  if (gridApi.value) {
    // First auto-size specific columns that need it
    gridApi.value.autoSizeColumns(columnsToAutoSize);
    
    // Then handle the rest based on user preference
    if (useFitContents.value) {
      gridApi.value.autoSizeAllColumns();
    } else {
      gridApi.value.sizeColumnsToFit();
    }
  }
}

// Initialize panel connections when component is mounted
onMounted(() => {
  console.log('📊 CreatePlantGrid mounted')
  const mitter = useMitter()
  // diagnostic panel hardcoded panel id
  mitter.emit('panel:toolData', {
    panelId: '39a3aa49-23d5-4157-867b-c17bcfbe79df',
    toolId: 'createplantgrid',
    data: {}
  })

  mitter.emit('panel:data:validate', {
    panelId: panel.name,
    validation_tool_uid: 'name-validation-tool',
    tool_uid: toolId.value,
    data: {
      // row and column data
      rowData: rowData.value?.map(row => ({...row, botanicalname: row.botanicalname?.label || row.botanicalname || ''})) || [],
      columnDefs: columnDefs.value
    }
  })

  // Check if the layout system is ready
  if (import.meta.client) {
    console.log('VUE_CODE_LAYOUT_READY flag:', window.VUE_CODE_LAYOUT_READY)
    
    // Try to initialize immediately if the flag is already set
    if (window.VUE_CODE_LAYOUT_READY) {
      initializeLayoutSystem()
      
      // Add this new code to connect to DiversityStatus
      if (panel.name) {
        // Initialize panel connections first
        initPanelConnections()
        
       
        panelConnectionsRef.value?.connect('7cd08128-4c43-4016-9bb1-48bd11b8c94f')
        
      }
    }
    
  }
  
  // Ensure we always have columnDefs and rowData even without layout store
  if (!columnDefs.value?.length) {
    columnDefs.value = Object.entries(defaultColumnSchema.properties).map(([key, value]) => ({
      field: key,
      headerName: columnDisplayLabels[key as keyof typeof defaultColumnSchema.properties],
      headerTooltip: `${columnDisplayLabels[key as keyof typeof defaultColumnSchema.properties]} (${value.type})`,
      type: value.type === 'number' ? 'numberColumn' : 'textColumn',
      editable: true,
      sortable: true,
      filter: true,
      resizable: true,
    }))
  }
  
  if (!rowData.value) {
    rowData.value = []
  }

  if (!panelConnectionsRef.value && panel.name) {
    console.log('[DEBUG] Initializing panel connections on mount for panel', panel.name)
    initPanelConnections()
  } else if (panelConnectionsRef.value) {
    console.log('[DEBUG] Panel connections already initialized on mount for panel', panel.name)
  } else {
    console.log('[DEBUG] Cannot initialize panel connections on mount: no panel.name available')
  }
})

// Watch for changes in row data and emit updates
watch([rowData, columnDefs], () => {
  console.log('📊 Grid data changed, emitting updates')
  // Call the async function without awaiting it in the watcher
  emitGridDataChanges().catch(error => {
    console.error('Error emitting grid data changes:', error)
  })

  
}, { deep: true })

// Add watcher to ensure there's always an empty row at the end
watch(() => rowData.value, (newRowData) => {
  if (!newRowData || !Array.isArray(newRowData)) return

  // Check if we have any rows
  if (newRowData.length === 0) {
    // Add an empty row if the grid is empty
    const emptyRow = Object.fromEntries(
      Object.keys(defaultColumnSchema.properties).map(key => [key, null])
    )
    rowData.value = [emptyRow]
    return
  }

  // Get the last row
  const lastRow = newRowData[newRowData.length - 1]
  
  // Check if the last row has any non-null values
  const hasValues = Object.entries(lastRow).some(([key, value]) => {
    // If the only non-null value is botanicalname with {label: ''}, consider it empty
    if (key === 'botanicalname' && 
        typeof value === 'object' && 
        value !== null && 
        Object.keys(value).length === 1 && 
        value.label === '') {
      return false
    }
    
    // Otherwise check if any field has a value
    if (typeof value === 'string' && value === '') {
      return false
    }
    return value !== null && value !== undefined
  })

  if (hasValues) {
    // Create a new empty row
    const emptyRow = Object.fromEntries(
      Object.keys(defaultColumnSchema.properties).map(key => [key, null])
    )
    // Add it to the end
    rowData.value = [...newRowData, emptyRow]
  }
}, { deep: true })

// Function to initialize the layout system
const initializeLayoutSystem = () => {
  // Try to initialize the layout store
  const layoutStoreInitialized = initLayoutStore()
  console.log('Layout store initialized:', layoutStoreInitialized)
  
  if (layoutStoreRef.value) {
    console.log('Layout store instance available:', !!layoutStoreRef.value)
    console.log('Layout instance available:', !!layoutStoreRef.value.layoutInstance)
  } else {
    console.log('Layout store reference is null')
  }
  
  // Try to initialize panel connections if needed
  if (!panelConnectionsRef.value && panel.name) {
    console.log('[DEBUG] Initializing panel connections during layout system initialization for panel', panel.name)
    const connectionsInitialized = initPanelConnections()
    console.log('[DEBUG] Panel connections initialized:', connectionsInitialized)
  } else if (panelConnectionsRef.value) {
    console.log('[DEBUG] Panel connections already initialized during layout system initialization for panel', panel.name)
  } else {
    console.log('[DEBUG] Cannot initialize panel connections during layout system initialization: no panel.name available')
  }
  
  // Emit grid data if we have it
  if (gridApi.value) {
    emitGridDataChanges().catch(error => {
      console.error('Error emitting grid data after layout system initialization:', error)
    })
  }
}

// Add resize observer to handle parent container size changes
const resizeObserver = useResizeObserver(parentElement, (entries) => {
  const entry = entries[0];
  if (entry && gridApi.value) {
    // Debounce the resize handler to avoid too many calls
    nextTick(() => {
      adjustColumnWidths();
    });
  }
});

// Update the gridOptions to include column state handling
const gridOptions = {
  onGridReady: (params: GridReadyEvent) => {
    gridApi.value = params.api;
    
    // If no data exists, initialize with an empty row
    if (!rowData.value?.length) {
      const emptyRow = Object.fromEntries(
        Object.keys(defaultColumnSchema.properties).map(key => [key, null])
      );
      rowData.value = [emptyRow];
    }
    
    params.api.setGridOption('rowData', rowData.value);
    params.api.refreshCells();
    
    // First auto-size specific columns that need it
    const columnsToAutoSize = ['botanicalname', 'commonname'];
    params.api.autoSizeColumns(columnsToAutoSize);
    
    // Then size the rest to fit
    params.api.sizeColumnsToFit();

    // Start editing botanicalname field in the first row after a short delay
    // only if the botanicalname value is empty
    setTimeout(() => {
      const firstRow = params.api.getDisplayedRowAtIndex(0);
      if (firstRow) {
        const botanicalNameValue = firstRow.data.botanicalname;
        const hasValue = botanicalNameValue && 
                         (typeof botanicalNameValue === 'string' ? 
                           botanicalNameValue.trim() !== '' : 
                           (botanicalNameValue?.label ? botanicalNameValue.label.trim() !== '' : false));
        
        if (!hasValue) {
          params.api.startEditingCell({
            rowIndex: 0,
            colKey: 'botanicalname'
          });
        }
      }
    }, 100);

    console.log("Grid is ready, checking if layout system is initialized...")
    
    // Create a function to safely emit grid data
    const safelyEmitGridData = async () => {
      try {
        // Try to initialize layout store and panel connections if not already done
        if (!layoutStoreRef.value) {
          const initialized = initLayoutStore()
          console.log("Layout store initialization attempt result:", initialized)
        }
        
        // Add a more robust check - only initialize if we don't have connections AND panel.name is available
        if (!panelConnectionsRef.value && panel.name) {
          console.log(`[DEBUG] Panel connections not initialized for panel ${panel.name}, initializing now`)
          const initialized = initPanelConnections()
          console.log("Panel connections initialization attempt result:", initialized)
        } else {
          console.log(`[DEBUG] Panel connections already initialized for panel ${panel.name || 'unknown'}, skipping initialization`)
        }
        
        
      } catch (error) {
        console.error("Error checking layout system readiness:", error)
        // Ensure we still emit to parent even if there's an error
        await emitGridDataChanges()
      }
    }

    // Start the safe emission process
    safelyEmitGridData()
  },
  // Add cell value changed handler to emit updates
  onCellValueChanged: (event: any) => {
    console.log('📊 Cell value changed, updating data')
    // When cell value changes, update the panel data
    const updatedData = [...(rowData.value || [])]
    updatedData[event.rowIndex] = event.data
    rowData.value = updatedData
    
    // Emit the updated data
    
  },
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
    suppressDragLeaveHidesColumns: true,
    minWidth: 100,
    // Add suppressKeyboardEvent handler to retain changes on Escape
    suppressKeyboardEvent: (params: { event: KeyboardEvent; editing: boolean }) => {
      const { event, editing } = params;
      // When Escape is pressed during editing, prevent default behavior
      if (event.key === 'Escape' && editing) {
        // Return true to suppress the default AG Grid behavior
        return true;
      }
      return false; // Let AG Grid handle all other keyboard events
    }
  },
  rowSelection: 'multiple',
  suppressRowClickSelection: true, // Only select through checkbox
  rowMultiSelectWithClick: false, // Prevent selection with click
  headerCheckboxSelection: true, // Enable header checkbox for all-selection
  headerCheckboxSelectionFilteredOnly: true, // Only select filtered items
  columnTypes: {
    numberColumn: {
      filter: 'agNumberColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset']
      },
      cellClass: 'text-right'
    },
    textColumn: {
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset']
      },
      cellClass: 'text-left'
    }
  },
  onDragStopped: (event: any) => {
    console.log('Column drag stopped, recalculating sizes');
    // After column drag, recalculate column sizes
    adjustColumnWidths();
  },
  onColumnResized: (event: any) => {
    if (event.finished) {
      const column = event.column;
      if (column) {
        const colId = column.getColId();
        const isFixedColumn = ['botanicalname', 'commonname'].includes(colId);
        
        if (isFixedColumn) {
          // Ensure minimum width is maintained
          const currentWidth = column.getActualWidth();
          const minWidth = column.getMinWidth();
          if (currentWidth < minWidth) {
            column.setActualWidth(minWidth);
          }
        }
      }
    }
  }
} as const;

// Add row management functions
const addRow = async () => {
  // Create the new row with default values
  const newRow = Object.fromEntries(
    Object.keys(defaultColumnSchema.properties).map(key => [key, null])
  )
  
  // Create a new array with the existing rows plus the new row
  const updatedRowData = [...(rowData.value || []), newRow]
  
  // Update rowData directly - this is the Vue way
  rowData.value = updatedRowData
  
  // Update panel data directly with object spreading to preserve all other properties
  // including connections
  panel.data = {
    ...panel.data,
    rowData: updatedRowData,
    // Ensure we preserve any existing connections
    connections: panel.data?.connections || []
  }
  
  // Update the grid if available
  if (gridApi.value) {
    gridApi.value.setGridOption('rowData', updatedRowData)
    gridApi.value.refreshCells()
  }
  
  // Ensure panel connections are initialized
  if (!panelConnectionsRef.value && panel.name) {
    console.log('[DEBUG] Initializing panel connections for row addition for panel', panel.name)
    initPanelConnections()
  } else if (panelConnectionsRef.value) {
    console.log('[DEBUG] Panel connections already initialized for row addition for panel', panel.name)
  } else {
    console.log('[DEBUG] Cannot initialize panel connections for row addition: no panel.name available')
  }
  
  // Trigger data emission to connected panels
  await emitGridDataChanges()
}

const deleteSelectedRows = async () => {
  const selectedNodes = gridApi.value?.getSelectedNodes()
  if (!selectedNodes?.length) return
  
  const selectedIndices = selectedNodes.map(node => node.rowIndex)
  
  if (rowData.value) {
    // Create a new filtered array
    const updatedRowData = rowData.value.filter((_, index) => 
      !selectedIndices.includes(index)
    )
    
    // Update rowData directly - this is the Vue way
    rowData.value = updatedRowData
    
    // Update panel data directly with object spreading to preserve all other properties
    // including connections
    panel.data = {
      ...panel.data,
      rowData: updatedRowData,
      // Ensure we preserve any existing connections
      connections: panel.data?.connections || []
    }
    
    // Update the grid if available
    if (gridApi.value) {
      gridApi.value.setGridOption('rowData', updatedRowData)
      gridApi.value.refreshCells()
    }
    
    // Trigger data emission to connected panels
    await emitGridDataChanges()
  }
}

// Add refresh handler
const handleRefresh = () => {
  if (gridApi.value) {
    // First ensure the grid has the latest data
    gridApi.value.setGridOption('rowData', rowData.value);
    
    // Then refresh cells to update any formatting
    gridApi.value.refreshCells({ force: true });
    
    // Apply column sizing
    adjustColumnWidths();
    
    console.log('Grid refreshed with', rowData.value?.length || 0, 'rows');
  }
}

// Update handler for toggling sizing
const handleToggleSizing = () => {
  useFitContents.value = !useFitContents.value;
  if (gridApi.value) {
    adjustColumnWidths();
  }
}

// Update zoom handlers to include all theme parameters
const handleZoomIn = () => {
  if (zoomLevel.value < MAX_ZOOM) {
    zoomLevel.value = Math.min(zoomLevel.value + 0.1, MAX_ZOOM)
    const newParams = {
      ...themeParams.value,
      fontSize: Math.round(BASE_FONT_SIZE * zoomLevel.value),
      headerFontSize: Math.round((BASE_FONT_SIZE + 2) * zoomLevel.value),
      rowHeight: Math.round(BASE_ROW_HEIGHT * zoomLevel.value),
      headerHeight: Math.round(BASE_HEADER_HEIGHT * zoomLevel.value),
    }
    // Update themeParams
    themeParams.value = newParams
    
    if (gridApi.value) {
      gridApi.value.resetRowHeights()
      nextTick(() => {
        adjustColumnWidths();
      })
    }
  }
}

const handleZoomOut = () => {
  if (zoomLevel.value > MIN_ZOOM) {
    zoomLevel.value = Math.max(zoomLevel.value - 0.1, MIN_ZOOM)
    const newParams = {
      ...themeParams.value,
      fontSize: Math.round(BASE_FONT_SIZE * zoomLevel.value),
      headerFontSize: Math.round((BASE_FONT_SIZE + 2) * zoomLevel.value),
      rowHeight: Math.round(BASE_ROW_HEIGHT * zoomLevel.value),
      headerHeight: Math.round(BASE_HEADER_HEIGHT * zoomLevel.value),
    }
    // Update themeParams
    themeParams.value = newParams
    
    if (gridApi.value) {
      gridApi.value.resetRowHeights()
      nextTick(() => {
        adjustColumnWidths();
      })
    }
  }
}

// Add TypeScript declaration for the global flag and panel connections initializing
declare global {
  interface Window {
    VUE_CODE_LAYOUT_READY?: boolean;
    _panelConnectionsInitializing?: Record<string, boolean>;
  }
}

// Define the structure of the layout instance
interface CodeLayoutSplitNInstance {
  getRootGrid: () => any;
  getPanelById: (id: string) => any;
}

// Extend the LayoutStore type to match the actual structure
declare module '@edanweis/vue-code-layout' {
  interface LayoutStore {
    layoutInstance: CodeLayoutSplitNInstance
  }
}

// Helper function to get all panels (to fix the getAllPanels error)
const getAllPanels = () => {
  if (!layoutStoreRef.value || !layoutStoreRef.value.layoutInstance) {
    return [];
  }
  
  try {
    const layoutInstance = layoutStoreRef.value.layoutInstance;
    const rootGrid = layoutInstance.getRootGrid();
    if (!rootGrid) return [];
    
    // Collect all panels from the grid
    const panels: Panel[] = [];
    const collectPanels = (grid: any) => {
      if (!grid) return;
      
      if (grid.type === 'panel' && grid.id) {
        const panel = layoutInstance.getPanelById(grid.id);
        if (panel) panels.push(panel);
      } else if (grid.children && Array.isArray(grid.children)) {
        grid.children.forEach((child: any) => collectPanels(child));
      }
    };
    
    collectPanels(rootGrid);
    return panels;
  } catch (error) {
    console.error('Error getting all panels:', error);
    return [];
  }
};

// Add MitterEvents interface to fix the keyof MitterEvents error
interface MitterEvents {
  'panel:toolData': any;
  'panel:data:validate': any;
}

// Add onBeforeUnmount handler
onBeforeUnmount(() => {
  // Clean up panel connections
  if (panelConnectionsRef.value && typeof panelConnectionsRef.value.cleanup === 'function') {
    console.log(`Cleaning up panel connections for panel ${panel.name}`)
    panelConnectionsRef.value.cleanup()
  }
  
  // Remove event listener for layout ready
  if (import.meta.client) {
    window.removeEventListener('vue-code-layout-ready', initializeLayoutSystem)
  }
  
  // Clean up resize observer
  if (resizeObserver) {
    resizeObserver.stop()
  }
})

// Create the base spell check function
const spellCheck = async (match: string): Promise<any[]> => {
  try {
    console.log('Performing spell check with Gemini API for:', match);
    
    // Try up to 3 times with exponential backoff
    for (let attempt = 0; attempt < 3; attempt++) {
      try {
        const spellCheckResponse = await $fetch<{botanicalname: string, rank?: string, text?: string}>('/api/ai/gmni', {
          method: 'POST',
          body: {
            prompts: [
              {
                hidden: true,
                prompt: 'spellcheckPlantNamePrompt'
              }
            ],
            prompt: `${match}. Return only a JSON object with the corrected botanical name and its taxonomic rank. Only use names from the Plantae kingdom.`,
            responseSchema: {
              type: "object",
              properties: {
                botanicalname: {
                  type: "string",
                  description: "The closest botanical name to the one requested"
                },
                rank: {
                  type: "string",
                  description: "The taxonomic rank of the name, eg: genus, variety, family, etc."
                }
              },
              required: ["botanicalname", "rank"]
            },
            temperature: 0.1
          },
          retry: attempt > 0 ? 1 : false,
          retryDelay: Math.pow(2, attempt) * 1000, // Exponential backoff: 1s, 2s, 4s
          onResponseError({ response }) {
            console.error(`Attempt ${attempt + 1} failed with status:`, response.status);
            throw new Error(`API request failed with status ${response.status}`);
          }
        });

        // Parse the response - Gemini API should return a JSON object directly
        if (spellCheckResponse && 
            typeof spellCheckResponse === 'object' && 
            'botanicalname' in spellCheckResponse) {
          // Return the suggestion as a single item
          return [{
            value: spellCheckResponse.botanicalname,
            label: spellCheckResponse.botanicalname,
            group: spellCheckResponse.rank || 'AI Suggestion',
            key: spellCheckResponse.botanicalname
          }];
        } else {
          // If response is in text format, check if it's JSON
          if (spellCheckResponse && typeof spellCheckResponse === 'object' && 'text' in spellCheckResponse) {
            try {
              const textContent = spellCheckResponse.text as unknown as string;
              const parsedContent = JSON.parse(textContent);
              
              if (parsedContent && parsedContent.botanicalname) {
                return [{
                  value: parsedContent.botanicalname,
                  label: parsedContent.botanicalname,
                  group: parsedContent.rank || 'AI Suggestion',
                  key: parsedContent.botanicalname
                }];
              }
            } catch (parseError) {
              console.error('Error parsing suggestion text:', parseError);
            }
          }
        }
        
        // If we get here with no valid response, try again
        throw new Error('Invalid response format from Gemini API');
        
      } catch (requestError) {
        console.error(`Attempt ${attempt + 1} failed:`, requestError);
        if (attempt === 2) { // Last attempt
          console.error('All retry attempts failed');
          return [];
        }
        // Wait before next attempt
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
      }
    }
    
    return []; // Return empty array if all attempts fail
  } catch (error) {
    console.error('Error with Gemini API spell check:', error);
    return [];
  }
};

// Create a debounced version using VueUse's useDebounceFn
const debouncedSpellCheck = useDebounceFn(spellCheck, 300);

// Add a flag to prevent recursive calls
const isProcessingOutput = ref(false);

// Create a function to safely process output data
const safelyProcessOutput = (newOutput: any) => {
  // Prevent recursive processing
  if (isProcessingOutput.value) {
    console.log('Already processing output, skipping');
    return;
  }

  // Set flag to prevent recursive calls
  isProcessingOutput.value = true;
  
  try {
    console.log('CreatePlantGrid safely processing output:', {
      outputExists: !!newOutput,
      panel: panel.name || 'unnamed',
      outputType: typeof newOutput,
      outputKeys: newOutput ? Object.keys(newOutput) : []
    });
    
    // Special handling for different output formats
    if (newOutput?.model) {
      // Direct model output format
      const parser = newOutput.model.toLowerCase().includes('gemini') ? 'gemini' : 'llamaparse';
      console.log('Using parser based on model:', parser);
      const parsedData = parse(newOutput, parser);
      // Processing logic...
    } else if (newOutput?.data?.model) {
      // Nested data format handling
    } else if (newOutput?.pages) {
      // Direct pages format handling
    } else if (Array.isArray(newOutput)) {
      // Direct array format handling
    } else {
      // Try generic parser as fallback
    }
  } catch (error) {
    console.error('Error processing output:', error);
  } finally {
    // Reset flag after processing
    setTimeout(() => {
      isProcessingOutput.value = false;
    }, 100);
  }
};

// Add the parse function to component setup
const { parse: originalParse } = usePlantGrid()

// Create a function to safely process output data
const parse = (output: any, parser: any): any => {
  // Ensure parser is one of the expected values
  const validParser = (typeof parser === 'string' && (parser === 'gemini' || parser === 'llamaparse')) 
    ? parser as 'gemini' | 'llamaparse' 
    : 'llamaparse';

  console.log(`CreatePlantGrid intercepted parse call with parser: ${parser}`, {
    outputType: typeof output,
    isNull: output === null,
    isUndefined: output === undefined,
    isObject: typeof output === 'object' && output !== null,
    keys: typeof output === 'object' && output !== null ? Object.keys(output) : [],
    parser: validParser
  });
  
  try {
    const result = originalParse(output, validParser);
    return result;
  } catch (error) {
    console.error(`Error in parse function with parser ${validParser}:`, error);
    return null;
  }
};

// Add a computed property for panel output
const panelOutput = computed(() => panel?.data?.output || null);

// Watch for panel data changes
watch(() => panel.data?.output, (newOutput, oldOutput) => {
  // Skip if no data or if processing or if the same reference (to avoid loops)
  if (!newOutput || isProcessingOutput.value || newOutput === oldOutput) {
    return;
  }
  
  if (gridApi.value) {
    // Use our safe processing function with a slight delay to break reactivity chains
    setTimeout(() => {
      safelyProcessOutput(newOutput);
    }, 0);
  }
}, { deep: true });

// Add method to explicitly handle data received from panel connections
const onDataReceived = (data: any) => {
  console.log('CreatePlantGrid received data:', data);
  
  if (data?.data?.type === 'grid' && data?.data?.rowData) {
    // Handle direct grid data
  }
  
  // Try to parse as analysis result
  const parser = data?.data?.model?.toLowerCase().includes('gemini') ? 'gemini' : 'llamaparse';
  const parsedData = parse(data.data, parser);
  if (parsedData) {
    columnDefs.value = parsedData.columnDefs;
    rowData.value = parsedData.rowData;
    nextTick(() => {
      gridApi.value?.sizeColumnsToFit();
    });
  }
}

// Expose methods for external use
defineExpose({
  refresh: handleRefresh,
  onDataReceived
});
</script>

<template>
  <div ref="parentElement" class="w-full h-full p-4 ">
    
    <div class="w-full h-full relative font-intervariable">
      <!-- Header controls section -->
      <PlantGridUiDropdown
        :panel="panel"
        :grid-api="gridApi"
        :on-add-row="addRow"
        :on-delete-selected-rows="deleteSelectedRows"
        :on-refresh="handleRefresh"
        :use-fit-contents="useFitContents"
        :zoom-level="zoomLevel"
        :on-zoom-in="handleZoomIn"
        :on-zoom-out="handleZoomOut"
        :on-toggle-sizing="handleToggleSizing"
        :min-zoom="MIN_ZOOM"
        :max-zoom="MAX_ZOOM"
      />
      <ClientOnly>
      <AgGridVue
        :enterNavigatesVerticallyAfterEdit="true"
        :theme="myTheme"
        :style="gridStyle"
        :rowData="rowData"
        :columnDefs="columnDefs"
        :gridOptions="gridOptions"
        :pagination="parentElWidth > 480"
        rowSelection="multiple"
        class="font-intervariable pt-16 ag-theme-alpine-dark"
      />
    </ClientOnly>
    </div>
  </div>
</template>
