export interface ColumnMapping {
  [key: string]: string
}

export interface ColumnInference {
  canonical: string
  type: 'string' | 'number'
  units: string
}

export interface ColumnInferenceResponse {
  [columnName: string]: ColumnInference
}

export interface ColumnTypeInfo {
  type: 'string' | 'number'
  units: string
}

export interface InferredColumnData {
  inferredColumnTitles: Record<string, string>
  columnToCanonical: Record<string, string>
  columnTypes: Record<string, ColumnTypeInfo>
  columnUnits: Record<string, string>
}

export interface GridData {
  columnDefs: Array<{
    field: string
    headerName: string
    sortable: boolean
    filter: boolean
    type: string
    valueFormatter?: (params: any) => string
    headerTooltip?: string
    cellClass?: (params: any) => string
  }>
  rowData: Array<Record<string, any>>
}

export interface OpenAIResponse {
  choices: Array<{
    message: {
      content: string
      role: string
    }
  }>
}

export interface TransientGridState {
  columnDefs?: GridData['columnDefs']
  rowData?: GridData['rowData']
  isDirty?: boolean
  lastSaved?: string
  localChanges?: {
    columns?: {
      [key: string]: {
        width?: number
        hide?: boolean
        sort?: string
        order?: number
      }
    }
    rows?: {
      [key: string]: {
        [key: string]: any
      }
    }
  }
}

export const columnSchema = {
  type: "object",
  properties: {
    // code: {
    //   type: "string",
    //   description: "Botanical code of the plant"
    // },
    // botanicalname: {
    //   type: "string", 
    //   description: "Scientific/botanical name of the plant"
    // },
    commonname: {
      type: "string",
      description: "Common/vernacular name of the plant"
    },
    qty: {
      type: "number",
      description: "Quantity or number of plants"
    },
    maturewidth: {
      type: "number",
      description: "Width of the plant at maturity"
    },
    matureheight: {
      type: "number",
      description: "Height of the plant at maturity"
    },
    maturesize: {
      type: "string",
      description: "Overall mature size of the plant when singular numerical units cannot be inferred reliably"
    },
    potsize: {
      type: "string",
      description: "Size of the container/pot"
    },
    spacing: {
      type: "string",
      description: "Recommended spacing between plants"
    },
    density: {
      type: "string",
      description: "Planting density or plants per square meter"
    },
    width: {
      type: "number",
      description: "Current width of the plant"
    },
    height: {
      type: "number",
      description: "Current height of the plant"
    },
    spread: {
      type: "string",
      description: "Spread or coverage of the plant"
    },
    proportionpercentage: {
      type: "number",
      description: "Percentage proportion in the planting mix"
    }
  }
} as const 

export const columnDisplayLabels: Record<string, string> = {
  qty: "Quantity",
  botanicalname: "Botanical Name",
  commonname: "Common Name",
  maturesize: "Mature Size",
  maturewidth: "Mature Width",
  matureheight: "Mature Height",
  potsize: "Pot Size",
  spacing: "Spacing",
  density: "Density",
  code: "Code",
  width: "Width",
  height: "Height",
  spread: "Spread",
  proportionpercentage: "Proportion (%)"
} as const 