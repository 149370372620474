<!-- Species Layer Accordion Component -->
<script setup lang="ts">
const props = defineProps({
  title: { type: String, required: true },
  value: { type: String, required: true },
  source: { type: String, required: true },
  layers: { type: Object, required: true },
  layerVisibility: { type: Object, required: true }
})

// Add a prop to track which layer is currently soloed
const currentSoloedLayer = defineModel('currentSoloedLayer', { default: null as string | null })

const emit = defineEmits(['toggle-layer', 'remove-layer', 'solo-layer'])

// Filter layers by source
const filterLayersBySource = (layers: Record<string, any>, source: string) => {
  return Object.entries(layers)
    .filter(([_, layer]) => {
      // Check for multiple patterns that indicate the source
      return (
        layer.source === source || 
        String(_).toLowerCase().endsWith(`-${source.toLowerCase()}`)
      );
    })
    .reduce((acc, [id, layer]) => {
      acc[id] = layer;
      return acc;
    }, {} as Record<string, any>);
}

// Handle solo toggle with cycling behavior
const handleSoloToggle = (layerId: string) => {
  // If this layer is already soloed, un-solo it (show all layers)
  if (currentSoloedLayer.value === layerId) {
    emit('solo-layer', null); // null indicates "show all"
    currentSoloedLayer.value = null;
  } else {
    // Solo this layer
    emit('solo-layer', layerId);
    currentSoloedLayer.value = layerId;
  }
}
</script>

<template>
  <AccordionItem :value="value" class="border-b-0">
    <AccordionTrigger>{{ title }}</AccordionTrigger>
    <AccordionContent>
      <div class="space-y-2 py-2">
        <div v-for="(layer, layerId) in filterLayersBySource(props.layers, props.source)" :key="layerId"
          class="flex items-center justify-between gap-2 hover:bg-white dark:hover:bg-black/10 rounded-md p-1 group/item">
          <div class="flex items-center space-x-2 flex-1">
            <div class="w-3 h-3 aspect-square rounded-full" :style="{ backgroundColor: layer.color }"></div>
            <span class="text-sm">{{ layer.name }}</span>
            <span class="text-xs text-muted-foreground">({{ layer.count || 0 }})</span>
          </div>
          <div class="flex items-center gap-1">
            <Button 
              variant="ghost" 
              size="icon" 
              class="h-6 w-6 rounded-full opacity-0 group-hover/item:opacity-100 transition-opacity" 
              :class="{ 'bg-white dark:bg-black/20': currentSoloedLayer === layerId }"
              @click="handleSoloToggle(layerId)" 
              :title="currentSoloedLayer === layerId ? 'Show all species' : 'Show only this species'">
              <Icon name="material-symbols-light:square-dot-rounded" class="h-3.5 w-3.5" />
            </Button>
            <Button 
              variant="ghost" 
              size="icon" 
              class="h-6 w-6 rounded-full opacity-0 group-hover/item:opacity-100 transition-opacity" 
              @click="emit('toggle-layer', layerId)">
              <Icon :name="props.layerVisibility[layerId] ? 'lucide:eye' : 'lucide:eye-off'" class="h-3.5 w-3.5" />
            </Button>
            <Button 
              variant="ghost" 
              size="icon" 
              class="h-6 w-6 rounded-full opacity-0 group-hover/item:opacity-100 transition-opacity" 
              @click="emit('remove-layer', layerId)">
              <Icon name="lucide:x" class="h-3.5 w-3.5" />
            </Button>
          </div>
        </div>
      </div>
    </AccordionContent>
  </AccordionItem>
</template> 