<template>
  <div class="flex flex-col h-full">
    <!-- Add header section -->
    <div class="sticky sticky-header top-0 z-10  border-b mb-4 mt-2">
      <div class="flex justify-between items-start pb-4 px-4">
        <div>
          <h3 class="text-md font-[600] mb-2 group hover:cursor-pointer relative"
              @click="() => refreshWithForceFlag(true)">
            <Icon 
              v-if="validationLoadingState === 'pending'" 
              name="line-md:loading-loop" 
              class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
            />
            <Icon 
              v-else
              name="lucide:refresh-cw" 
              class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
            />
            <div class="relative group-hover:translate-x-6 transition-all duration-200">
              Biosecurity Assessment
            </div>
          </h3>
          <p class="text-sm text-muted-foreground max-w-2xl">
            Identifies potential biosecurity threats by analyzing pest associations and host relationships. 
            Each card shows pest images and their relationships to plant species, helping assess potential risks and management needs.
          </p>
        </div>
      </div>
    </div>

    <!-- Loading state -->
    <div v-if="validationLoadingState === 'pending'" class="flex items-center justify-center py-4">
      <Loader2 class="w-6 h-6 animate-spin" />
      <span class="ml-2">Loading biosecurity validations...</span>
    </div>

    <!-- No data state -->
    

    <!-- Main content -->
    <ScrollArea v-else class="h-[calc(100vh-380px)] w-full pr-2 scroll-thumb">
      
      <div v-for="[species, data] in Object.entries(biosecurityResults)" :key="species" class="mb-4">
        
        <Card v-if="data?.pests?.filter(pest => pest.name).length" 
              class="relative overflow-hidden squircle-20 rounded-2xl shadow-none" 
              :class="data.isInfoCard ? 'border-muted/30 border-[1px]' : 'border-primary/20 border-[1px]'">
          <div class="absolute inset-0 bg-background"></div>
          <CardHeader class="relative z-10 w-full">
            <!-- chevron - only show for non-info cards -->
             <Button v-if="!data.isInfoCard" @click="toggleAccordion(species)" variant="text" class="absolute top-4 right-4"> 
               <Icon 
                 name="lucide:chevron-down" 
                 class="w-5 h-5 text-muted-foreground transition-transform duration-200" 
                 :class="{ 'rotate-180': expandedAccordions[species] }"
               />
              </Button>
               <CardTitle class="flex items-center items-start mb-2 text-lg">
              <Icon v-if="!data.isInfoCard" name="ph:bug-fill" class="text-primary mr-2 w-7 h-7 relative" />
              
              <span class="font-[400]">{{ species }}</span>
              
            </CardTitle>
            <CardDescription v-if="!data.isInfoCard" class="flex flex-nowrap gap-1 mt-2 overflow-x-hidden scale-[0.9] origin-left h-5 max-h-5 -mr-5">
              <Badge v-if="data?.pests?.filter((p: Pest) => p.type === 'Major host').length" 
                     class="select-none whitespace-nowrap hover:bg-background hover:text-primary bg-red-500 border-[1.5px] border-primary text-white font-light text-[0.8em] p-0 px-1 flex-shrink-0">
                Major host {{ data?.pests?.filter((p: Pest) => p.type === 'Major host').length }}
              </Badge>
              <Badge v-if="data?.pests?.filter((p: Pest) => p.type === 'Host').length" 
                     class="select-none whitespace-nowrap hover:bg-background hover:text-primary bg-orange-400 border-[1.5px] border-primary text-white font-light text-[0.8em] p-0 px-1 flex-shrink-0">
                Host {{ data?.pests?.filter((p: Pest) => p.type === 'Host').length }}
              </Badge>
              <Badge v-if="data?.pests?.filter((p: Pest) => ['Wild/Weed', 'Weed/wild'].includes(p.type)).length" 
                     class="select-none whitespace-nowrap hover:bg-background hover:text-primary bg-lime-600 border-[1.5px] border-primary text-white font-light text-[0.8em] p-0 px-1 flex-shrink-0">
                Weed/wild {{ data?.pests?.filter((p: Pest) => ['Wild/Weed', 'Weed/wild'].includes(p.type)).length }}
              </Badge>
            </CardDescription>
          </CardHeader>
          <CardContent class="relative z-10 text-white">
            <!-- Special display for info cards -->
            <div v-if="data.isInfoCard" class="py-4 px-2 text-center">
              <div class="flex flex-col items-center justify-center gap-3">
                <Icon name="solar:database-bold" class="w-10 h-10 text-muted-foreground" />
                <p class="text-muted-foreground">No biosecurity data was found for this species in the EPPO Global Database.</p>
                <NuxtLink 
                  href="https://gd.eppo.int/" 
                  target="_blank" 
                  class="text-primary hover:underline text-sm flex items-center gap-1 mt-2"
                >
                  <span>Visit EPPO Database</span>
                  <Icon name="lucide:external-link" class="w-3 h-3" />
                </NuxtLink>
              </div>
            </div>
            <!-- Normal content for pest cards -->
            <template v-else>
              <div class="flex items-center gap-2 mb-4">
                <div v-for="(pest, index) in (data.displayPests || []).slice(0, 5)" 
                  :key="index"
                  class="relative w-12 h-12"
                >
                  <NuxtLink 
                    :href="`https://gd.eppo.int/taxon/${pest?.name?.match(/\(([A-Z]+)\)/)?.[1] || ''}`"
                    target="_blank"
                    class="block w-full h-full rounded-full overflow-hidden border-[1.5px] relative transition-all duration-300 ease-out"
                    :class="{
                      'border-red-500': pest.type === 'Major host',
                      'border-orange-400': pest.type === 'Host',
                      'border-lime-600': pest.type === 'Weed/wild' || pest.type === 'Wild/Weed',
                      'border-primary': !pest.type,
                      'hover-expanded': hoveringStates[pest.name]
                    }"
                  >
                    <!-- Show fallback icon if no image -->
                    <div v-if="!pest.imageUrl" class="w-full h-full flex items-center justify-center bg-muted/30">
                      <Icon name="ph:bug-fill" class="w-6 h-6 text-muted-foreground/70" />
                    </div>
                    
                    <Lens
                      v-else
                      :hovering="hoveringStates[pest.name] || false"
                      @hover-update="(value) => updateHoveringState(pest.name, value)"
                      :lens-size="180"
                      :zoom-factor="10"
                    >
                      <div class="relative w-full h-full">
                        <!-- Thumbnail version -->
                        <NuxtImg 
                          v-show="!hoveringStates[pest.name]"
                          :src="pest.imageUrl" 
                          class="w-full h-full object-cover transition-transform duration-200"
                          :alt="pest.name?.replace(/\s*\([^)]*\)\s*/g, '')"
                          loading="lazy"
                          width="120"
                          height="120"
                          sizes="120px"
                          quality="90"
                          @error="handleImageError(pest)"
                        />
                        <!-- High-res version -->
                        <NuxtImg 
                          v-show="hoveringStates[pest.name]"
                          :src="pest.imageUrl" 
                          class="w-full h-full object-cover"
                          :alt="pest.name?.replace(/\s*\([^)]*\)\s*/g, '')"
                          width="1000"
                          height="1000"
                          sizes="1000px"
                          quality="100"
                          :preload="true"
                          @error="handleImageError(pest)"
                        />
                      </div>
                    </Lens>
                  </NuxtLink>
                </div>
                <div v-if="getRemainingPestsCount(data)" 
                  class="text-primary text-md font-medium cursor-pointer hover:opacity-80"
                  @click="toggleAccordion(species)"
                >
                  +{{ (data.totalPests ?? 0) - (data.displayPests?.length || 0) }}
                </div>
              </div>
              <div v-if="data?.scheduleChanged" class="mb-2">
                <Badge class="bg-yellow-500 text-white">Schedule Changed</Badge>
              </div>
              <div v-if="data?.pests?.length > 0" class="mt-2">
                <Accordion v-if="data?.pests?.filter((pest: Pest) => pest.name).length > 0" 
                          type="single" 
                          collapsible 
                          class="w-full"
                          v-model="expandedAccordions[species]"
                >
                  <AccordionItem :value="species" class="accordion-item">
                    <AccordionTrigger class="hidden">{{ data?.pests?.length }} pest{{ data?.pests?.length > 1 ? 's' : '' }}</AccordionTrigger>
                    <AccordionContent class="text-sm border-b-0">
                      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                        <div v-for="pest in data?.pests?.filter((pest: Pest) => pest.name)" 
                            :key="pest?.name" 
                            class="text-xs"
                        >
                          <NuxtLink 
                            :href="`https://gd.eppo.int/taxon/${pest?.name?.match(/\(([A-Z]+)\)/)?.[1] || ''}`" 
                            target="_blank" 
                            class="flex items-center rounded-lg py-1.5 px-2 transition-colors"
                            :class="{
                              'bg-red-500/10 hover:bg-red-500/20': pest.type === 'Major host',
                              'bg-orange-400/10 hover:bg-orange-400/20': pest.type === 'Host',
                              'bg-lime-600/10 hover:bg-lime-600/20': pest.type === 'Weed/wild' || pest.type === 'Wild/Weed'
                            }"
                          >
                            <div class="aspect-square rounded-2xl w-6 h-6 flex items-center justify-center mr-2">
                              <Icon 
                                name="ph:bug-fill" 
                                class="w-5 h-5"
                                :class="{
                                  'text-red-500/70': pest.type === 'Major host',
                                  'text-orange-400/70': pest.type === 'Host',
                                  'text-lime-600/70': pest.type === 'Weed/wild' || pest.type === 'Wild/Weed'
                                }"
                              />
                            </div>
                            <span class="inline-block text-foreground/90 truncate max-w-full">
                              {{ pest?.name?.replace(/\s*\([^)]*\)\s*/g, '') }}
                            </span>
                          </NuxtLink>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
            </template>
          </CardContent>
        </Card>
      </div>
    </ScrollArea>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, nextTick } from 'vue'
import { useValidation } from '@/composables/useValidation'
import { useProjectStore } from '@/stores/projectStore'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Loader2 } from 'lucide-vue-next'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import VTooltip from '@/components/VTooltip.vue'
import Lens from '@/components/Lens.vue'
import { toolDefinitions } from '@/config/tools'

import type { MitterEvents } from '@/types/mitterEvents'
import { isEqual } from 'lodash-es'

interface Host {
  name: string;
  type: 'Major host' | 'Host' | string;
}

interface Pest {
  name: string;
  type: 'Major host' | 'Host' | 'Wild/Weed' | 'Weed/wild' | string;
  imageUrl?: string | null;
  relationshipType?: 'direct' | 'indirect' | string;
  source?: 'eppo' | 'padil' | string;
}

interface ValidationData {
  isBiosecurityThreat: boolean;
  hosts: Host[];
  pests: Pest[];
  images?: string[];
  displayPests?: Pest[];
  totalPests?: number;
  scheduleChanged?: boolean;
  isInfoCard?: boolean;
}

// Define the prop
const props = defineProps<{
  project_id: string;
}>();

const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)
const { validateAndRefreshBiosecurity, validationLoadingState } = useValidation()
const mitter = useMitter()

// Local ref for storing validation results 
const biosecurityResults = ref<Record<string, ValidationData>>({})

// Ref for species names
const speciesNames = ref<string[]>([])

// Add ref for tracking force refresh
const forceRefresh = ref(false)

// Listen for panel:data:validate events
const tool = ref(toolDefinitions.find(t => t.id === 'biosecurityValidation'))

// Debug logging to check the tool configuration
console.log('[BiosecurityStatus] Tool configuration:', tool.value)

// Add ref for tracking previous botanical names
const prevBotanicalNames = ref<string[]>([])

// Listen for validation events from the system
mitter.listen('panel:data:validate', (event: MitterEvents['panel:data:validate']) => {
  const botanicalNames = event?.data?.rowData?.flatMap((row: any) => 
    [row?.botanicalname || row?.botanical_name || row?.species]
  ).filter(Boolean).map(name => name.trim())
  
  const hasNewNames = !isEqual(botanicalNames, prevBotanicalNames.value)
  
  if (event?.validation_tool_uid === tool?.value?.uid && hasNewNames) {
    speciesNames.value = botanicalNames
    prevBotanicalNames.value = botanicalNames
    refresh()
  }
})

// Function to refresh with force flag
const refreshWithForceFlag = async (force: boolean = false) => {
  console.log(`[BiosecurityStatus] Refreshing with force=${force}`)
  forceRefresh.value = force
  await refresh()
}

// Function to refresh biosecurity data
const refresh = async () => {
  try {
    // Validate species names before proceeding
    if (!speciesNames.value || speciesNames.value.length === 0) {
      console.log('[BiosecurityStatus] No species names to validate')
      return
    }
    
    // Filter out invalid species names
    const validSpeciesNames = speciesNames.value.filter(name => 
      name && typeof name === 'string' && name.trim() !== ''
    )
    
    if (validSpeciesNames.length === 0) {
      console.log('[BiosecurityStatus] No valid species names to validate')
      return
    }
    
    console.log('[BiosecurityStatus] Starting refresh with species:', validSpeciesNames)
    console.log('[BiosecurityStatus] Force refresh:', forceRefresh.value)
    
    // Process the validation results to get biosecurity data
    const validationResponse = await validateAndRefreshBiosecurity(
      validSpeciesNames, 
      undefined, // panel_id
      undefined, // country
      undefined, // libtype
      'en',      // codelang
      forceRefresh.value // forceRefresh flag
    )
    
    // Reset force refresh flag
    forceRefresh.value = false
    
    console.log('[BiosecurityStatus] Response structure:', {
      hasResult: !!validationResponse?.result,
      resultType: validationResponse?.result ? typeof validationResponse.result : 'undefined',
      resultKeys: validationResponse?.result ? Object.keys(validationResponse.result) : [],
      status: validationLoadingState.value
    })
    
    // Add more detailed debugging
    if (validationResponse?.result) {
      console.log('[BiosecurityStatus] Result content sample:', 
        Object.entries(validationResponse.result).slice(0, 1).map(([k, v]: [string, any]) => ({
          species: k,
          hasHosts: Array.isArray(v.hosts) && v.hosts.length > 0,
          hasPests: Array.isArray(v.pests) && v.pests.length > 0,
          pestCount: Array.isArray(v.pests) ? v.pests.length : 0
        }))
      )
    }
    
    // Early return if no results
    if (!validationResponse?.result) {
      console.warn('[BiosecurityStatus] No result property in validation response')
      return
    }
    
    if (validationResponse?.result) {
      // Log the raw result to understand its structure better
      console.log('[BiosecurityStatus] Raw result object:', validationResponse.result)
      
      const modifiedResult: Record<string, ValidationData> = {}
      
      // Create entries array and sort it (ensure we have entries to process)
      const entries = Object.entries(validationResponse.result || {})
      
      // If we have no entries, try to handle the cached response format
      if (entries.length === 0) {
        // Try to use our processor to ensure we're not filtering everything out
        const processedResponse = processBiosecurityResponse(validationResponse)
        if (Object.keys(processedResponse).length > 0) {
          console.log('[BiosecurityStatus] Using processed response with', Object.keys(processedResponse).length, 'entries')
          biosecurityResults.value = processedResponse
          return
        }
        
        console.log('[BiosecurityStatus] No biosecurity results found')
        biosecurityResults.value = {}
        return
      }
      
      // Sort entries by presence of pests (most pests first)
      const sortedEntries = entries.sort(([speciesA, dataA], [speciesB, dataB]) => {
        const pestCountA = (dataA as ValidationData).pests?.length || 0
        const pestCountB = (dataB as ValidationData).pests?.length || 0
        return pestCountB - pestCountA
      })
      
      // Process the sorted entries
      sortedEntries.forEach(([species, data]) => {
        const typedData = data as ValidationData
        
        // Skip entries with no pests
        if (!typedData.pests || typedData.pests.length === 0) {
          console.log(`[BiosecurityStatus] Skipping ${species} - no pests found`)
          return
        }
        
        const pests = typedData.pests || []
        console.log(`[BiosecurityStatus] Processing ${species} with ${pests.length} pests`)
        
        // Ensure we have valid pest data
        const validPests = pests.filter(pest => pest?.name)
        
        // Skip entries with no valid pests
        if (validPests.length === 0) {
          console.log(`[BiosecurityStatus] Skipping ${species} - no valid pests found`)
          return
        }
        
        // Process pest data for display
        const majorHostPests = validPests.filter((pest: Pest) => pest.type === 'Major host')
        const otherPests = validPests.filter((pest: Pest) => pest.type !== 'Major host')
        const allPests = [...majorHostPests, ...otherPests]
        
        // Create unique pests list (as in the old version)
        const uniquePests = allPests.reduce((acc: Pest[], pest) => {
          const pestName = pest.name?.replace(/\s*\([^)]*\)\s*/g, '');
          if (pestName && !acc.some(p => p.name?.replace(/\s*\([^)]*\)\s*/g, '') === pestName)) {
            acc.push(pest);
          }
          return acc;
        }, []);
        
        // Include the species in the result even without images
        modifiedResult[species] = {
          ...typedData,
          hosts: typedData.hosts || [],
          pests: validPests,
          displayPests: uniquePests.length > 0 ? uniquePests.slice(0, 5).map(pest => ({
            ...pest,
            imageUrl: (() => {
              const imageUrl = typedData.images?.[allPests.indexOf(pest)]
              return imageUrl && !imageUrl.includes('error') ? imageUrl : null
            })()
          })) : [],
          totalPests: uniquePests.length || 0
        }
      })
      
      console.log('[BiosecurityStatus] Processed results:', modifiedResult)
      
      // Update the local ref with processed results
      biosecurityResults.value = modifiedResult
    } else {
      console.warn('[BiosecurityStatus] No result property in validation response')
    }
  } catch (error) {
    console.error('[BiosecurityStatus] Error refreshing biosecurity data:', error)
  }
}

// Add hovering states for each pest
const hoveringStates = ref<Record<string, boolean>>({})

// Function to update hovering state for a specific pest
const updateHoveringState = (pestName: string, value: boolean) => {
  hoveringStates.value[pestName] = value
  
  if (value && biosecurityResults.value) {
    const pest = Object.values(biosecurityResults.value)
      .flatMap(data => data.displayPests || [])
      .find(p => p?.name === pestName)
    
    if (pest?.imageUrl) {
      const img = new Image()
      img.src = pest.imageUrl
    }
  }
}

// Add ref for tracking expanded accordions
const expandedAccordions = ref<Record<string, string>>({})

// Function to toggle accordion
const toggleAccordion = (species: string) => {
  // Skip toggling for info cards
  const speciesData = biosecurityResults.value[species];
  if (speciesData?.isInfoCard) return;
  
  expandedAccordions.value[species] = expandedAccordions.value[species] ? '' : species
}

// Add this function to handle image load events
const onImageLoad = (event: Event) => {
  // The image has loaded - you could add loading states here if needed
  console.log('Image loaded:', event)
}

// Add a helper function to calculate the remaining pests count
const getRemainingPestsCount = (data: ValidationData) => {
  const totalPests = data.totalPests ?? data.pests?.length ?? 0
  const displayedPests = data.displayPests?.length || 0
  return Math.max(0, totalPests - displayedPests)
}

// Helper function to get species from project data - simplified version
const getProjectSpecies = async () => {
  try {
    const projectId = selectedProject.value?.id
    
    if (!projectId) {
      console.log('[BiosecurityStatus] No project ID available')
      return []
    }
    
    console.log('[BiosecurityStatus] Getting species for project:', projectId)
    
    // Add fallback for getting species directly from project data if available
    const projectData = selectedProject.value as any
    if (projectData?.plants && Array.isArray(projectData.plants)) {
      const projectPlants = projectData.plants
        .map((p: any) => p.botanical_name)
        .filter(Boolean)
      console.log('[BiosecurityStatus] Plants from project data:', projectPlants)
      return projectPlants
    }
    
    // If no project data, return empty array
    return []
  } catch (error) {
    console.error('[BiosecurityStatus] Error getting project species:', error)
    return []
  }
}

// Check to see if we have a valid response but it's being filtered out
const processBiosecurityResponse = (response: any): Record<string, ValidationData> => {
  if (!response || !response.result) {
    console.warn('[BiosecurityStatus] No result in response to process')
    return {}
  }

  // Log the raw response to help with debugging
  console.log('[BiosecurityStatus] Processing raw response:', 
    Object.entries(response.result).length > 0 ? 
    Object.entries(response.result).map(([k, v]: [string, any]) => ({ 
      species: k, 
      hasPests: Array.isArray((v as any).pests) && (v as any).pests.length > 0 
    })) :
    'No entries')
  
  // If we have results but they're being filtered out in the component, bypass the filtering
  if (Object.entries(response.result).length > 0) {
    // Return the result with minimal processing to ensure display
    return response.result
  }
  
  return {}
}

// Add debug watcher to log state changes - these can stay
watch(speciesNames, (newValue) => {
  console.log('[BiosecurityStatus] Species names changed:', newValue)
})

watch(biosecurityResults, (newValue) => {
  console.log('[BiosecurityStatus] Biosecurity results changed:', 
    Object.keys(newValue).length > 0 ? 'Has results' : 'No results',
    Object.keys(newValue))
}, { deep: true })

// Add a function to handle image load errors
const handleImageError = (pest: Pest) => {
  console.warn(`Image failed to load for pest: ${pest.name}`)
  // Set the imageUrl to null to show the fallback icon
  if (pest.imageUrl) {
    pest.imageUrl = null
  }
}

</script>

<style scoped>
.rounded-full {
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  transform-origin: center;
  transition: all 80ms ease-out;
}

.hover-expanded {
  transform: scale(1.1);
  opacity: 0;
  z-index: 50;
  pointer-events: auto;
  cursor: none;
  transition: all 80ms ease-out;
}

/* Clean up conflicting styles */
:deep(.lens-container) {
  border-radius: 50%;
  position: relative !important;
  inset: auto !important;
  width: 100% !important;
  height: 100% !important;
  overflow: visible !important;
}

:deep(.motion-container) {
  opacity: 1 !important;
  z-index: 100;
  pointer-events: none;
  border-radius: 50% !important;
  overflow: hidden !important;
  /* Adjust motion container positioning to accommodate larger lens */
  transform: translate3d(-50%, -50%, 0) scale(1.5) !important;
}

/* Make the magnified content circular */
:deep(.lens-content) {
  border-radius: 50% !important;
  overflow: hidden !important;
  mask-image: radial-gradient(circle, white 100%, transparent 100%);
  -webkit-mask-image: radial-gradient(circle, white 100%, transparent 100%);
  /* Ensure content scales properly with larger lens */
  transform-origin: center !important;
}

/* Ensure the image inside lens-content is also masked */
:deep(.lens-content img) {
  border-radius: 50%;
  mask-image: radial-gradient(circle, white 100%, transparent 100%);
  -webkit-mask-image: radial-gradient(circle, white 100%, transparent 100%);
}

/* Remove previous masking that might interfere */
:deep(.motion-container) {
  mask-image: none;
  -webkit-mask-image: none;
}

/* Remove all the conflicting styles */
.pest-lens,
.scale-\[4\],
.scale-\[4\].opacity-0,
.invisible-but-active,
.scale-\[2\]:hover {
  display: none;
}

/* Keep other necessary styles */
.sticky-header {
  min-height: 52px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
}

.backdrop-blur-sm {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.cursor-pointer {
  transition: opacity 0.2s ease;
}
</style>