<script setup lang="ts">
// No need to import these as they're auto-imported in Nuxt 3
// import { ref, onMounted, onBeforeUnmount } from 'vue'
import type { MitterEvents } from '@/types/mitterEvents'

// Define the structure for event logs
interface EventLog {
  id: string
  timestamp: number
  eventType: string
  data: any
}

// State for storing event logs
const eventLogs = ref<EventLog[]>([])
const maxLogs = ref(100)
const autoScroll = ref(true)
const filterText = ref('')
const selectedEventType = ref<string | null>(null)

// Reference to the log container for auto-scrolling
const logContainer = ref<HTMLElement | null>(null)

// Available event types from MitterEvents
const eventTypes = [
  'panel:output',
  'panel:connect',
  'panel:disconnect',
  'panel:delete',
  'panel:removed',
  'panel:update',
  'panel:gridData',
  'panel:mapData',
  'panel:toolData',
  'panel:data:received',
  'panel:data:send'
]

// Function to add a new log entry
const addLogEntry = (eventType: string, data: any) => {
  const newLog: EventLog = {
    id: crypto.randomUUID(),
    timestamp: Date.now(),
    eventType,
    data
  }
  
  // Add to the beginning for newest-first ordering
  eventLogs.value.unshift(newLog)
  
  // Trim logs if they exceed the maximum
  if (eventLogs.value.length > maxLogs.value) {
    eventLogs.value = eventLogs.value.slice(0, maxLogs.value)
  }
  
  // Auto-scroll to the latest log if enabled
  if (autoScroll.value && logContainer.value) {
    nextTick(() => {
      if (logContainer.value) {
        logContainer.value.scrollTop = 0
      }
    })
  }
}

// Function to clear all logs
const clearLogs = () => {
  eventLogs.value = []
}

// Function to format timestamp
const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp)
  return date.toLocaleTimeString('en-US', { 
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3
  })
}

// Computed property for filtered logs
const filteredLogs = computed(() => {
  return eventLogs.value.filter(log => {
    // Filter by event type if selected
    if (selectedEventType.value && log.eventType !== selectedEventType.value) {
      return false
    }
    
    // Filter by text if provided
    if (filterText.value) {
      const searchText = filterText.value.toLowerCase()
      const eventTypeMatch = log.eventType.toLowerCase().includes(searchText)
      const dataMatch = JSON.stringify(log.data).toLowerCase().includes(searchText)
      return eventTypeMatch || dataMatch
    }
    
    return true
  })
})

// Set up event listeners when component is mounted
onMounted(() => {
  const mitter = useMitter()
  
  // Listen to all event types
  eventTypes.forEach(eventType => {
    // Use the correct typing for mitter.on
    mitter.on(eventType as keyof MitterEvents, (data: any) => {
      addLogEntry(eventType, data)
    })
  })
  
  // Also listen to the wildcard event to catch any other events
  // For wildcard events, we need to handle both the event name and data
  mitter.on('*', (type: string, data: any) => {
    // Only log events that aren't already being logged specifically
    if (!eventTypes.includes(type)) {
      addLogEntry(`${type} (wildcard)`, data)
    }
  })
})

// No need for cleanup since unsubscribing is not necessary
</script>

<template>
  <div class="w-full h-full flex flex-col bg-background">
    <!-- Controls section -->
    <div class="flex items-center justify-between p-2 border-b border-border">
      <div class="flex items-center space-x-2">
        <h2 class="text-sm font-semibold">Diagnostic Panel</h2>
        <div class="flex items-center space-x-1">
          <Button size="sm" variant="outline" @click="clearLogs">Clear</Button>
          <div class="flex items-center space-x-1">
            <input 
              id="auto-scroll" 
              type="checkbox" 
              v-model="autoScroll" 
              class="h-3 w-3"
            />
            <label for="auto-scroll" class="text-xs">Auto-scroll</label>
          </div>
        </div>
      </div>
      
      <div class="flex items-center space-x-2">
        <select 
          v-model="selectedEventType" 
          class="text-xs h-7 px-2 bg-background border border-border rounded"
        >
          <option :value="null">All Events</option>
          <option v-for="type in eventTypes" :key="type" :value="type">{{ type }}</option>
        </select>
        
        <input 
          v-model="filterText" 
          placeholder="Filter logs..." 
          class="text-xs h-7 px-2 bg-background border border-border rounded"
        />
        
        <select 
          v-model="maxLogs" 
          class="text-xs h-7 px-2 bg-background border border-border rounded"
        >
          <option :value="50">50 logs</option>
          <option :value="100">100 logs</option>
          <option :value="200">200 logs</option>
          <option :value="500">500 logs</option>
        </select>
      </div>
    </div>
    
    <!-- Logs section -->
    <div 
      ref="logContainer" 
      class="flex-1 overflow-auto p-2 space-y-2"
    >
      <div 
        v-for="log in filteredLogs" 
        :key="log.id" 
        class="p-2 rounded border border-border text-xs"
      >
        <div class="flex justify-between items-start mb-1">
          <span class="font-mono text-primary font-semibold">{{ log.eventType }}</span>
          <span class="text-muted-foreground">{{ formatTimestamp(log.timestamp) }}</span>
        </div>
        
        <pre class="bg-muted p-2 rounded overflow-x-auto whitespace-pre-wrap break-all">{{ JSON.stringify(log.data, null, 2) }}</pre>
      </div>
      
      <div v-if="filteredLogs.length === 0" class="text-center text-muted-foreground p-4">
        No events logged yet. Events will appear here as they occur.
      </div>
    </div>
  </div>
</template> 