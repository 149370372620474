<template>
  <div
    ref="containerRef"
    class="lens-container"
    @mouseenter="setIsHovering(true)"
    @mouseleave="setIsHovering(false)"
    @mousemove="handleMouseMove"
  >
    <slot />

    <Teleport to="body">
      <div 
        v-if="props.isStatic || isHovering" 
        class="motion-container"
        :style="{
          position: 'fixed',
          left: `${globalPosition.x - (props.lensSize / 2)}px`,
          top: `${globalPosition.y - (props.lensSize / 2)}px`,
          width: `${props.lensSize}px`,
          height: `${props.lensSize}px`,
          pointerEvents: 'none',
          zIndex: 9999,
          borderRadius: '50%',
          overflow: 'hidden',
          clipPath: 'circle(50%)',
          WebkitClipPath: 'circle(50%)'
        }"
      >
        <Motion
          :initial="{ opacity: 0, scale: 0.58 }"
          :enter="{
            opacity: 1,
            scale: 1,
            transition: { duration: 0.3, ease: 'easeOut' },
          }"
          :leave="{ opacity: 0, scale: 0.8 }"
        >
          <div
            class="lens-content"
            :style="{ 
              position: 'absolute',
              left: `${-globalPosition.x + containerRect.left}px`,
              top: `${-globalPosition.y + containerRect.top}px`,
              width: `${containerRect.width}px`,
              height: `${containerRect.height}px`,
              transform: `scale(${props.zoomFactor})`,
              transformOrigin: `${globalPosition.x - containerRect.left}px ${globalPosition.y - containerRect.top}px`,
              clipPath: 'circle(50%)',
              WebkitClipPath: 'circle(50%)'
            }"
          >
            <slot />
          </div>
        </Motion>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
interface LensProps {
  zoomFactor?: number;
  lensSize?: number;
  isStatic?: boolean;
  hovering?: boolean;
}

const props = withDefaults(defineProps<LensProps>(), {
  zoomFactor: 1.5,
  lensSize: 170,
  isStatic: false
});

const emit = defineEmits<{
  (e: "hover-update", value: boolean): void;
}>();

const containerRef = ref<HTMLElement | null>(null);
const localIsHovering = ref(false);
const mousePosition = ref({ x: 0, y: 0 });
const globalPosition = ref({ x: 0, y: 0 });
const containerRect = ref({ width: 0, height: 0, left: 0, top: 0 });

const isHovering = computed(() => props.hovering ?? localIsHovering.value);

function setIsHovering(hover: boolean) {
  localIsHovering.value = hover;
  emit("hover-update", hover);
  
  if (hover && containerRef.value) {
    const rect = containerRef.value.getBoundingClientRect();
    containerRect.value = {
      width: rect.width,
      height: rect.height,
      left: rect.left,
      top: rect.top
    };
  }
}

function handleMouseMove(e: MouseEvent) {
  const rect = containerRef.value?.getBoundingClientRect();
  if (!rect) return;
  
  mousePosition.value = {
    x: e.clientX - rect.left,
    y: e.clientY - rect.top
  };
  
  globalPosition.value = {
    x: e.clientX,
    y: e.clientY
  };
}

onUnmounted(() => {
  setIsHovering(false);
});
</script>

<style scoped>
.lens-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: none;
}

.lens-content {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
}

.lens-content :deep(img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
</style>