<script setup lang="ts">
import { useGeolocation, useDebounceFn } from '@vueuse/core'
import { Loader2 } from 'lucide-vue-next'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion'
import type { NameStatus, NameStatusMatch } from '@/types/validation'
import { Skeleton } from '@/components/ui/skeleton'
import { toolDefinitions } from '@/config/tools'
import { Progress } from '@/components/ui/progress'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import type { MitterEvents } from '@/types/mitterEvents'
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { Badge } from '@/components/ui/badge'
import ScrollArea from './ui/scroll-area/ScrollArea.vue'

import { storeToRefs } from 'pinia'
import { useEmitter } from '@/composables/useEmitter'
import { stringSimilarity } from "string-similarity-js";

// Initialize core services
const mitter = useMitter()
const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)

const nuxtApp = useNuxtApp()

// Set up the hashed emitter for name validation events
const { emitMitter } = useEmitter({
  debounceMs: 300,
  fields: ['botanicalname']
})

// Component props
const props = defineProps<{
  data?: string[],
  metadata?: any,
  cell?: any,
  project_id?: string,
  panel_id?: string,
  full?: boolean,
  panel?: any  // Add this prop to support panel data access
}>()

const emit = defineEmits(['results'])

// Component state
const speciesNames = ref<string[]>([])
const tnrsData = ref<any[]>([])
const gbifLoaded = ref(false)
const tnrsLoaded = ref(false)
const tnrsError = ref<string | null>(null)
const showHide = ref({
  exact: true,
  inexact: true,
  invalid: true
})
// Add filter state
const activeFilter = ref<string | null>(null)

// Track processing state for each API source
const sourceResults = ref<Record<string, any[]>>({})
const pendingSources = ref<string[]>([])
const completedSources = ref<string[]>([])
// EventSource for SSE handling
let eventSource: EventSource | null = null

// Map to store event handlers for each source
const sourceEventHandlers = ref(new Map<string, any>())

// AI recommendation state
const aiRecommendations = ref<string>('')
const isLoadingRecommendations = ref<boolean>(false)
const showRecommendations = ref<boolean>(false)

// Track progress for each source
const expectedSources = ref(0) // Total sources we expect
const processedSources = ref(0) // Sources that have reported back (success or error)
const isFetchingData = ref<boolean>(false)
const isRefreshing = ref(false)

// Track if an AI summary request is in progress
const isAiSummaryRequestInProgress = ref<boolean>(false)

// Add a map to store species quantities from the original data
const speciesQuantities = ref(new Map<string, number>())

// Calculate progress percentage
const progressPercentage = computed(() => {
  // If expected sources is zero or we're just starting
  if (expectedSources.value === 0) {
    return pendingSources.value.length ? 0 : 100
  }
  
  // Otherwise calculate based on processed sources
  return Math.round((processedSources.value / expectedSources.value) * 100)
})

// Define available data sources with display names
const sources = [
  { id: 'gbif', name: 'GBIF' },
  { id: 'tnrs', name: 'TNRS' },
  { id: 'inaturalist', name: 'iNaturalist' },
  { id: 'wikispecies', name: 'Wikispecies' },
  { id: 'powo', name: 'POWO' },
  { id: 'eol', name: 'EOL' },
  { id: 'gemini', name: 'Google' },
  { id: 'ipaustralia', name: 'IP Australia' },
  { id: 'upov', name: 'UPOV' }
]

// Define logos for data sources
const logos = [
  {
    name: "iNaturalist",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/01.svg",
  },
  {
    name: "tnrs",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/02.png",
  },
  {
    name: "WIKISPECIES",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/03.svg",
  },
  {
    name: "GBIF",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/041.svg",
  },
  {
    name: "ALA",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/05.png",
  },
  {
    name: "EPPO",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/06.png",
  },
  {
    name: "PADIL",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/07.svg",
  },
  {
    name: "EvergreenConnect",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/08.svg",
  },
  {
    name: "Google",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/Google_Gemini_logo.svg",
  },
  {
    name: "EOL",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/EOL_logo.svg",
  },
  {
    name: "POWO",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/POWO.webp",
  },
  {
    name: "IP Australia",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/PBR.png",
  },
  {
    name: "UPOV",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/upov.png?tr=w-80p",
  }
]

// Get logo path for a given source
const getLogoPath = (source: string) => {
  const sourceName = sources.find(s => s.id === source)?.name || source.toUpperCase()
  const logo = logos.find(l => l.name.toLowerCase() === sourceName.toLowerCase())
  return logo?.path || ''
}

// Check if we have any results from any source
const hasAnyResults = computed(() => {
  return Object.values(sourceResults.value).some(results => results.length > 0)
})

// Total count of all results
const totalResults = computed(() => {
  return Object.values(sourceResults.value).reduce((sum, results) => sum + results.length, 0)
})

const tool = toolDefinitions.find(t => t.id === 'nameValidation')
console.log('[NameStatus] listening for tool:', tool?.uid)

// Listen for validation events from the system
mitter.listen('panel:data:validate', (event: MitterEvents['panel:data:validate']) => {
  console.log('[NameStatus] Received validation event:', event)
  // Ensure we have valid event data
  if (!event?.data?.rowData) return
  
  if (event.validation_tool_uid === tool?.uid || event.tool_uid === tool?.id) { 
    console.log('[NameStatus] Processing valid validation event')
    
    const botanicalNames = Array.isArray(event.data.rowData) 
      ? event.data.rowData
          .filter(row => row)
          .map(row => {
            const name = row.botanicalname || row.botanical_name || row.species
            return typeof name === 'string' ? name.trim() : null
          })
          .filter(Boolean)
      : []

    // Reset and update quantities map
    speciesQuantities.value.clear()
    event.data.rowData?.forEach?.(row => {
      const name = row?.botanicalname || row?.botanical_name || row?.species
      if (!name) return
      
      const qty = row.qty ?? row.quantity ?? 1
      speciesQuantities.value.set(name.trim(), Number(qty))
    })

    const hasNewNames = botanicalNames.length && 
      (botanicalNames.length !== speciesNames.value.length || 
       !botanicalNames.every(name => speciesNames.value.includes(name)))

    if (hasNewNames) {
      speciesNames.value = botanicalNames
      console.log('[NameStatus] Found new species names with quantities:', 
        botanicalNames.map(name => ({
          name,
          qty: speciesQuantities.value.get(name) ?? 1
        }))
      )
      refresh()
    }
  }
})

// Process results from each data source
function processSourceData(source: string, data: any) {
  if (!data || !data.results || data.results.length === 0) return []
  
  // We expect all data to come in the standardized format from the backend
  const processed = data.results.map((item: any) => {
    // Ensure required fields are present
    if (!item.mappedData) {
      return null
    }
    
    const original = findOriginalName(item, source)
    
    // Log family data from taxonomic sources for debugging
    if (item.mappedData.family) {
      console.log(`[NameStatus] Found family '${item.mappedData.family}' for '${original}' from source '${source}'`)
    }
    
    return {
      id: item.id || `${source}-${Date.now()}-${Math.random()}`,
      original,
      matched: item.mappedData.scientificName || 'Unknown',
      canonicalName: item.mappedData.scientificName || 'Unknown',
      rank: item.mappedData.rank || null,
      status: item.source === 'AI research' ? 'AI Generated' : (item.mappedData.acceptedName ? 'ACCEPTED' : 'Unknown'),
      family: item.mappedData.family || null,
      author: item.mappedData.author || null,
      source: source,
      image: item.mappedData.image || (item.rawData?.images && item.rawData.images[0]?.thumbnail) || null,
      commonName: item.mappedData.commonName || null,
      url: item.mappedData.url || (item.rawData?.url || item.rawData?.link) || null
    }
  }).filter(Boolean) // Remove any nulls resulting from missing data
  
  console.log(`[NameStatus] Source ${source} produced ${processed.length} results with these originals:`, 
    processed.map(r => r.original).slice(0, 5))
  
  // Log whether these originals exist in speciesNames
  const foundInSpecies = processed.filter(item => 
    speciesNames.value.some(name => 
      name.toLowerCase() === item.original.toLowerCase()
    )
  )
  console.log(`[NameStatus] ${foundInSpecies.length} of ${processed.length} have originals that match speciesNames`)
  
  return processed
}

// Helper function to find the original search name
function findOriginalName(item: any, source: string) {
  if (!item) return source
  
  // First try to get from rawData
  if (item.rawData) {
    const submittedName = 
      item.rawData.Name_submitted || 
      item.rawData.name_submitted || 
      item.rawData.matched_term ||
      item.rawData.query
    
    if (submittedName) {
      // If variety is present, suffix it in quotes
      if (item.rawData.variety) {
        return `${submittedName} '${item.rawData.variety}'`
      }
      return submittedName
    }
  }
    
  // Otherwise try to find a matching name in the search list
  const scientificName = item.mappedData?.scientificName
  if (scientificName && speciesNames.value.length > 0) {
    const foundName = speciesNames.value.find(name => 
      name.toLowerCase().includes(scientificName.toLowerCase()) || 
      scientificName.toLowerCase().includes(name.toLowerCase())
    )
    if (foundName) {
      // If variety is present, suffix it in quotes
      if (item.rawData?.variety) {
        return `${foundName} '${item.rawData.variety}'`
      }
      return foundName
    }
  }
  
  // Last resort - return what we have or a default
  const baseName = item.mappedData?.scientificName || item.name || source
  // If variety is present, suffix it in quotes
  if (item.rawData?.variety) {
    return `${baseName} '${item.rawData.variety}'`
  }
  return baseName
}

// Define source priorities from most to least reliable
const sourcePriority = [
  'ipaustralia',
  'tnrs',
  'gbif',
  'powo',
  'wikispecies',
  'inaturalist',
  'eol',
  'upov',
  'gemini'
]

// Calculate source priority score (lower is better)
const getSourcePriority = (source) => {
  const index = sourcePriority.indexOf(source)
  return index === -1 ? sourcePriority.length : index
}

// Categorize results from all sources
const categorizedResults = computed(() => {
  const exact: any[] = []
  const inexact: any[] = []
  const invalid: any[] = []
  const validNames: Record<string, any> = {} // Add this for 1:1 mapping
  
  // Get all processed items from all sources (excluding AI generated results)
  let allItems: any[] = []
  Object.entries(sourceResults.value).forEach(([source, items]) => {
    // Skip AI generated results (gemini)
    if (source === 'gemini') return
    
    allItems = [...allItems, ...items]
  })
  
  console.log(`[NameStatus] Processing ${allItems.length} total items from all sources (excluding AI)`)
  
  // Log the original botanical names we started with
  console.log(`[NameStatus] Original botanical names from table:`, speciesNames.value)
  
  // Group items by original name for comparison
  const itemsByOriginalName = allItems.reduce((groups, item) => {
    if (!item.original) return groups
    const key = item.original.toLowerCase()
    if (!groups[key]) groups[key] = []
    groups[key].push(item)
    return groups
  }, {} as Record<string, any[]>)
  
  console.log(`[NameStatus] Grouped items by ${Object.keys(itemsByOriginalName).length} unique original names`)
  
  // Check which group keys match speciesNames
  const matchingKeys = Object.keys(itemsByOriginalName).filter(key => 
    speciesNames.value.some(name => name.toLowerCase() === key)
  )
  console.log(`[NameStatus] ${matchingKeys.length} of ${Object.keys(itemsByOriginalName).length} group keys match speciesNames`)
  
  // Check for speciesNames not in any group
  const missingNames = speciesNames.value.filter(name => 
    !Object.keys(itemsByOriginalName).some(key => key === name.toLowerCase())
  )
  if (missingNames.length > 0) {
    console.log(`[NameStatus] ${missingNames.length} names from speciesNames are not in any result group:`, 
      missingNames.slice(0, 5))
  }
  
  // For each original name, find the best match (for validNames 1:1 mapping)
  speciesNames.value.forEach(originalName => {
    if (!originalName) return

    const normalizedOriginalName = originalName.toLowerCase()
    const matchesForName = itemsByOriginalName[normalizedOriginalName] || []
    
    // Get quantity from our stored mapping instead of props.panel
    let quantity = speciesQuantities.value.get(originalName);
    if (quantity === undefined) {
      console.log(`[NameStatus] No quantity found for ${originalName}, using default value 1`);
      quantity = 1;
    } else {
      console.log(`[NameStatus] Using quantity ${quantity} for ${originalName} from stored mapping`);
    }
    
    if (matchesForName.length === 0) {
      // No matches found for this name
      invalid.push({
        original: originalName,
        matched: null,
        quantity: quantity, // Include the quantity we found
        source: pendingSources.value.length ? 'pending' : 'none' 
      })
      return
    }
    
    // Score each item by source priority and similarity
    const scoredMatches = matchesForName
      .filter(item => item.matched) // Filter out items without a matched name
      .map(item => ({
        ...item,
        similarityScore: stringSimilarity(
          normalizedOriginalName,
          item.matched.toLowerCase()
        ),
        sourcePriorityScore: getSourcePriority(item.source)
      }))
      .sort((a, b) => {
        // First prioritize source (lower source priority score is better)
        if (a.sourcePriorityScore !== b.sourcePriorityScore) {
          return a.sourcePriorityScore - b.sourcePriorityScore
        }
        // If sources have equal priority, then sort by similarity score (higher is better)
        return b.similarityScore - a.similarityScore
      })
    
    if (scoredMatches.length > 0) {
      // Get the best match for this original name
      const bestMatch = scoredMatches[0]
      
      // Store in validNames with the quantity we found
      validNames[originalName] = {
        original: originalName,
        matched: bestMatch.matched,
        genus: bestMatch.genus || bestMatch.matched.split(' ')[0] || '',
        family: bestMatch.family || 'Unknown',
        source: bestMatch.source,
        score: bestMatch.similarityScore,
        matchType: bestMatch.similarityScore > 0.9 ? 'exact' : 'inexact',
        quantity: quantity // Use the quantity we found from original data
      }
      
      // Also update the quantity in the items we add to exact/inexact arrays
      const itemWithQuantity = {
        ...bestMatch,
        quantity: quantity
      };
      
      // Also add to exact or inexact for backward compatibility
      if (bestMatch.similarityScore > 0.9) {
        exact.push(itemWithQuantity)
      } else {
        inexact.push(itemWithQuantity)
      }
    } else {
      // Store invalid name in the mapping
      validNames[originalName] = {
        original: originalName,
        matched: null,
        source: 'none',
        score: 0,
        matchType: 'none',
        quantity: quantity // Include the quantity we found
      }
      
      // Add to invalid
      invalid.push({
        original: originalName,
        matched: null,
        quantity: quantity, // Include the quantity we found
        source: matchesForName[0]?.source || 'none' 
      })
    }
  })
  
  // For names in speciesNames that didn't get processed above (edge case)
  speciesNames.value.forEach(name => {
    if (!name) return
    
    // If this name isn't in our validNames yet, add as invalid
    if (!validNames[name]) {
      // Get quantity from our stored mapping
      const itemQuantity = speciesQuantities.value.get(name) || 1;
      
      validNames[name] = {
        original: name,
        matched: null,
        source: pendingSources.value.length ? 'pending' : 'none',
        score: 0,
        matchType: 'none',
        quantity: itemQuantity
      }
      
      // Also add to invalid array
      invalid.push({
        original: name,
        matched: null,
        quantity: itemQuantity,
        source: pendingSources.value.length ? 'pending' : 'none'
      })
    }
  })

  // Remove duplicates (same original+matched+source)
  const uniqueExact = [...new Set(exact.map(n => JSON.stringify(n)))].map(n => JSON.parse(n))
  const uniqueInexact = [...new Set(inexact.map(n => JSON.stringify(n)))].map(n => JSON.parse(n))
  const uniqueInvalid = [...new Set(invalid.map(n => JSON.stringify(n)))].map(n => JSON.parse(n))
  
  console.log(`[NameStatus] Final categorized results:`, {
    exact: uniqueExact.length,
    inexact: uniqueInexact.length,
    invalid: uniqueInvalid.length,
    validNames: Object.keys(validNames).length
  })
  
  return {
    exact: uniqueExact,
    inexact: uniqueInexact,
    invalid: uniqueInvalid,
    validNames, // Include the 1:1 mapping
    speciesMapping: Object.values(validNames) // For compatibility with old code
  }
})

// Get unique species names for filters
const uniqueSpeciesNames = computed(() => {
  return [...new Set(speciesNames.value)].filter(Boolean)
})

// Filter results based on selected species name
const filteredResults = computed(() => {
  if (!activeFilter.value) {
    return categorizedResults.value
  }
  
  const filter = activeFilter.value.toLowerCase()
  
  const filterItems = (items: any[]) => {
    return items.filter(item => 
      item.original?.toLowerCase().includes(filter)
    )
  }
  
  return {
    exact: filterItems(categorizedResults.value.exact),
    inexact: filterItems(categorizedResults.value.inexact),
    invalid: filterItems(categorizedResults.value.invalid)
  }
})

// Function to clear the active filter
function clearFilter() {
  activeFilter.value = null
}

// Helper function to generate a cache key for a species
function generateSpeciesCacheKey(speciesName: string) {
  return `species-validation-${speciesName.toLowerCase().trim()}`
}

// Helper function to generate a cache key for multiple species
function generateBatchCacheKey(speciesNames: string[]) {
  const sortedNames = [...new Set(speciesNames.map(name => name.toLowerCase().trim()))].sort()
  return `species-validation-batch-${sortedNames.join(',')}`
}

// Check if we have cached data for all species
function getCachedSpeciesData(speciesNames: string[]) {
  if (!speciesNames.length) return null
  
  // Try batch cache first
  const batchKey = generateBatchCacheKey(speciesNames)
  const batchData = nuxtApp.payload?.data?.[batchKey]
  if (batchData) {
    console.log(`[NameStatus] Using batch cached data for ${speciesNames.length} species`)
    return batchData
  }
  
  // Try individual species caches
  const individualResults: Record<string, any[]> = {}
  let allCached = true
  
  for (const name of speciesNames) {
    const key = generateSpeciesCacheKey(name)
    const data = nuxtApp.payload?.data?.[key]
    
    if (data) {
      // Merge source results from cached data
      Object.entries(data.sourceResults || {}).forEach(([source, results]) => {
        if (!individualResults[source]) {
          individualResults[source] = []
        }
        individualResults[source] = [...individualResults[source], ...results]
      })
    } else {
      allCached = false
      break
    }
  }
  
  if (allCached && Object.keys(individualResults).length > 0) {
    console.log(`[NameStatus] Using individual cached data for ${speciesNames.length} species`)
    return { sourceResults: individualResults }
  }
  
  return null
}

// Save species data to cache
function cacheSpeciesData(speciesNames: string[], data: any) {
  if (!speciesNames.length || !data) return
  
  // Cache batch data
  const batchKey = generateBatchCacheKey(speciesNames)
  nuxtApp.payload ||= {}
  nuxtApp.payload.data ||= {}
  nuxtApp.payload.data[batchKey] = data
  
  // Cache individual species data
  speciesNames.forEach(name => {
    const key = generateSpeciesCacheKey(name)
    
    // Find results for this specific species
    const speciesData = {
      sourceResults: Object.fromEntries(
        Object.entries(data.sourceResults || {}).map(([source, results]) => {
          const filteredResults = (results as any[]).filter(item => 
            item.original?.toLowerCase().includes(name.toLowerCase())
          )
          return [source, filteredResults]
        })
      )
    }
    
    nuxtApp.payload.data[key] = speciesData
  })
}

// Start the search with SSE
async function fetchSpeciesData(speciesSet: Set<string>, sourcesToUse = sources) {
  isFetchingData.value = true

  // Store expected number of sources for progress tracking
  expectedSources.value = sourcesToUse.length
  processedSources.value = 0
  pendingSources.value = sourcesToUse.map(s => s.id)
  completedSources.value = []
  
  console.log(`[NameStatus] Starting fetchSpeciesData with ${speciesSet.size} species`)
  console.log(`[NameStatus] Available sources: ${sourcesToUse.map(s => s.id).join(', ')}`)
  
  // Clear previous event handlers
  sourceEventHandlers.value.clear()
  
  // Create a completion tracking function
  const checkCompletion = () => {
    console.log('[NameStatus] Checking completion status:')
    console.log(`  - Processed: ${processedSources.value}/${expectedSources.value}`)
    console.log(`  - Pending: ${pendingSources.value.join(', ')}`)
    console.log(`  - Completed: ${completedSources.value.join(', ')}`)
    
    // If all expected sources are done, reset the flag
    if (processedSources.value >= expectedSources.value) {
      console.log('[NameStatus] All sources completed, setting isFetchingData to false')
      isFetchingData.value = false
      
      // Cache final results
      cacheSpeciesData(speciesNames.value, { sourceResults: sourceResults.value })
      
      // Emit the final results
      emitValidationResults()
    }
  }
  
  try {
    speciesNames.value = Array.from(speciesSet)
    
    // Handle empty species list
    if (speciesNames.value.length === 0) {
      console.log('[NameStatus] No species names to process, completing immediately')
      isFetchingData.value = false
      return
    }
    
    // Convert set to array for each source request
    sourcesToUse.forEach(source => {
      // Register the completion callback for each source
      sourceEventHandlers.value.set(source.id, {
        onProcessed: () => {
          console.log(`[NameStatus] Source ${source.id} marked as processed`)
          // Update source counters
          if (pendingSources.value.includes(source.id)) {
            pendingSources.value = pendingSources.value.filter(s => s !== source.id)
            completedSources.value.push(source.id)
            processedSources.value++
            // Check if all sources are complete
            checkCompletion()
          }
        }
      })
    })
    
    // Set initial state
    isFetchingData.value = true
    
    // Check for cached data first
    const cachedData = getCachedSpeciesData(speciesNames.value)
    if (cachedData) {
      // Use cached data
      sourceResults.value = cachedData.sourceResults || {}
      pendingSources.value = []
      completedSources.value = sourcesToUse.map(s => s.id)
      gbifLoaded.value = true
      tnrsLoaded.value = true
      
      // Set progress indicators for cached data (100% complete)
      expectedSources.value = sourcesToUse.length
      processedSources.value = sourcesToUse.length
      
      // Emit results from cache
      emitValidationResults()
      
      // Mark fetching as complete
      console.log('[NameStatus] Using cached data, setting isFetchingData to false')
      isFetchingData.value = false
      
      // We're done - no need for SSE
      return
    }
    
    // Reset state for fresh fetch
    sourceResults.value = {}
    pendingSources.value = sourcesToUse.map(s => s.id)
    completedSources.value = []
    gbifLoaded.value = false
    tnrsLoaded.value = false
    
    // Reset progress indicators
    expectedSources.value = sourcesToUse.length
    processedSources.value = 0
    
    // Create a combined query of all species names
    const query = speciesNames.value.join(',')
    const encodedQuery = encodeURIComponent(query)
    
    // Close any existing connection
    if (eventSource) {
      eventSource.close()
    }
    
    // Create EventSource for Server-Sent Events
    eventSource = new EventSource(`/api/bio/suggest?q=${encodedQuery}`)
    
    // Handle messages from the server
    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)
        
        // Process different message types
        if (data.type === 'result' && data.source) {
          // Check if we have the new standardized format or original format
          if (data.originalResults) {
            // Process using original format for backward compatibility
            const processedResults = processSourceData(data.source, data.originalResults)
            sourceResults.value[data.source] = processedResults
          } else {
            // Process using the original method
            const processedResults = processSourceData(data.source, data)
            sourceResults.value[data.source] = processedResults
          }
          
          // Update specific source flags
          if (data.source === 'gbif') {
            gbifLoaded.value = true
          } else if (data.source === 'tnrs') {
            tnrsLoaded.value = true
            // Handle both formats for TNRS data
            if (data.originalResults && data.originalResults.result) {
              tnrsData.value = data.originalResults.result || []
            } else {
              tnrsData.value = data.result || []
            }
          }
          
          // Update source status using the event handler
          const handler = sourceEventHandlers.value.get(data.source)
          if (handler && handler.onProcessed) {
            handler.onProcessed()
          }
          
          // Cache the results as they come in
          cacheSpeciesData(speciesNames.value, { sourceResults: sourceResults.value })
          
          // Emit processed results
          if (data.source === 'gbif' || data.source === 'tnrs') {
            emitValidationResults()
          }
        } 
        else if (data.type === 'error') {
          console.error(`[NameStatus] Error from source ${data.source}:`, data.error)
          // Track TNRS errors
          if (data.source === 'tnrs') {
            tnrsError.value = data.error
            tnrsLoaded.value = true
          }
          
          // Count error as processed using the event handler
          const handler = sourceEventHandlers.value.get(data.source)
          if (handler && handler.onProcessed) {
            handler.onProcessed()
          }
        }
        else if (data.type === 'complete') {
          // Search is complete
          console.log('[NameStatus] Received complete message from server')
          stopSearch()
        }
      } catch (error) {
        console.error('[NameStatus] Error processing SSE message:', error)
      }
    }
    
    // Handle errors
    eventSource.onerror = (error) => {
      console.error('[NameStatus] EventSource error:', error)
      stopSearch()
    }
    
    // Add a safety timeout - if after 30 seconds we're still fetching,
    // force completion as a failsafe
    setTimeout(() => {
      if (isFetchingData.value) {
        console.warn('[NameStatus] Search timeout after 30s, forcing completion')
        stopSearch()
      }
    }, 30000) // 30 second timeout
    
  } catch (error) {
    console.error('Error in fetchSpeciesData:', error)
    isFetchingData.value = false // Reset on error
  }
}

// Stop the search
function stopSearch() {
  console.log('[NameStatus] Stopping search')
  
  if (eventSource) {
    eventSource.close()
    eventSource = null
    console.log('[NameStatus] EventSource closed')
  }
  
  // Log the current state
  console.log('[NameStatus] Stop search - current state:')
  console.log(`  - Pending sources: ${pendingSources.value.join(', ')}`)
  console.log(`  - Completed sources: ${completedSources.value.join(', ')}`)
  console.log(`  - Processed: ${processedSources.value}/${expectedSources.value}`)
  
  // Mark any remaining pending sources as completed
  if (pendingSources.value.length > 0) {
    // Add all pending sources to completed
    const remainingSources = [...pendingSources.value]
    console.log(`[NameStatus] Marking ${remainingSources.length} remaining sources as completed:`, remainingSources)
    completedSources.value = [...completedSources.value, ...remainingSources]
    pendingSources.value = []
    
    // Update progress counter to reflect all sources processed
    processedSources.value = expectedSources.value
    console.log(`[NameStatus] Set processedSources to ${processedSources.value}`)
  }
  
  // Mark fetching as complete
  console.log('[NameStatus] Setting isFetchingData to false')
  isFetchingData.value = false
  
  // If we have completed the search and have results, cache them
  if (completedSources.value.length > 0 && hasAnyResults.value) {
    // Cache the final results
    console.log('[NameStatus] Caching final results')
    cacheSpeciesData(speciesNames.value, { sourceResults: sourceResults.value })
    
    // Log categorizedResults for debugging
    console.log('[NameStatus] Current categorizedResults before emitting:', {
      exact: categorizedResults.value?.exact?.length || 0,
      inexact: categorizedResults.value?.inexact?.length || 0, 
      invalid: categorizedResults.value?.invalid?.length || 0,
      exactSample: categorizedResults.value?.exact?.slice(0, 2),
      inexactSample: categorizedResults.value?.inexact?.slice(0, 2)
    })
    
    // Emit the final results
    console.log('[NameStatus] Emitting final validation results')
    try {
      emitValidationResults()
    } catch (error) {
      console.error('[NameStatus] Error in emitValidationResults:', error)
      // Try a safer alternative approach if our main method fails
      try {
        // Create a minimal safe data structure
        const safeData = {
          plants: [],
          exactMatches: categorizedResults.value?.exact?.length || 0,
          inexactMatches: categorizedResults.value?.inexact?.length || 0,
          invalid: []
        }
        
        // Add plants if possible
        if (categorizedResults.value?.exact) {
          categorizedResults.value.exact.forEach(item => {
            if (item.matched) {
              safeData.plants.push({
                originalName: item.original,
                species: item.matched,
                quantity: item.quantity || 1,
                genus: item.genus || (item.matched ? item.matched.split(' ')[0] : ''),
                family: item.family || 'Unknown',
                score: 1,
                source: item.source || 'unknown'
              })
            }
          })
        }
        
        if (categorizedResults.value?.inexact) {
          categorizedResults.value.inexact.forEach(item => {
            if (item.matched) {
              safeData.plants.push({
                originalName: item.original,
                species: item.matched,
                quantity: item.quantity || 1,
                genus: item.genus || (item.matched ? item.matched.split(' ')[0] : ''),
                family: item.family || 'Unknown',
                score: 0.8,
                source: item.source || 'unknown'
              })
            }
          })
        }
        
        if (categorizedResults.value?.invalid) {
          safeData.invalid = categorizedResults.value.invalid.map(item => ({
            original: item.original,
            score: item.score || 0
          }))
        }
        
        console.log('[NameStatus] Using safe fallback data:', safeData)
        mitter.emit('name:validation:results', safeData)
      } catch (fallbackError) {
        console.error('[NameStatus] Even fallback approach failed:', fallbackError)
      }
    }
  } else {
    console.log('[NameStatus] No sources completed or no results to cache')
  }
}

// Function to add genus and family data to results
function enrichResultsWithTaxonomy(results: any) {
  // Track quantities of species from the original grid data
  const speciesQuantities = new Map()
  
  // Check if we have original data from grid with quantities
  if (speciesNames.value && sourceResults.value && sourceResults.value.gbif) {
    
    const validationData = speciesNames.value
    const rowData = validationData.rowData || validationData.data?.rowData || []
    
    if (Array.isArray(rowData) && rowData.length > 0) {
      rowData.forEach((row: any) => {
        if (!row.botanicalname) return
        
        const name = (typeof row.botanicalname === 'object' 
          ? row.botanicalname?.label || '' 
          : row.botanicalname || ''
        ).trim().toLowerCase()
        
        if (name) {
          let quantity = Number(row.qty) || 1
          
          // If we already have this species, add the quantities
          if (speciesQuantities.has(name)) {
            speciesQuantities.set(name, speciesQuantities.get(name) + quantity)
          } else {
            speciesQuantities.set(name, quantity)
          }
        }
      })
    }
  }
  
  // Create plants for diversity array to return
  const plantsForDiversity: Array<{
    species: string;
    genus: string;
    family: string;
    quantity: number;
    score: number;
  }> = [];
  
  // Process exact matches
  if (results.exact && results.exact.length > 0) {
    results.exact.forEach((item: any) => {
      if (item.matched) {
        plantsForDiversity.push({
          species: item.matched,
          genus: item.genus || item.matched.split(' ')[0],
          family: item.family || 'Unknown',
          quantity: item.quantity || 1,
          score: 1 // Exact match has perfect score
        });
      }
    });
  }
  
  // Process inexact matches
  if (results.inexact && results.inexact.length > 0) {
    results.inexact.forEach((item: any) => {
      if (item.matched) {
        plantsForDiversity.push({
          species: item.matched,
          genus: item.genus || item.matched.split(' ')[0],
          family: item.family || 'Unknown',
          quantity: item.quantity || 1,
          score: 0.8 // Inexact match has slightly lower score
        });
      }
    });
  }
  
  return { results, plantsForDiversity };
}

// Format validation results as plain text
function formatResultsAsText() {
  let text = ''
  
  // Add exact matches
  if (categorizedResults.value.exact.length > 0) {
    text += '## Exact Matches\n\n'
    categorizedResults.value.exact.forEach(item => {
      text += `* Original name: ${item.original}\n`
      text += `  * Matched name: ${item.matched}\n`
      text += `  * Family: ${item.family || 'Unknown'}\n`
      text += `  * Author: ${item.author || 'Unknown'}\n`
      text += `  * Source: ${sources.find(s => s.id === item.source)?.name || item.source}\n\n`
    })
  }
  
  // Add partial matches
  if (categorizedResults.value.inexact.length > 0) {
    text += '## Partial Matches\n\n'
    categorizedResults.value.inexact.forEach(item => {
      text += `* Original name: ${item.original}\n`
      text += `  * Suggested name: ${item.matched}\n`
      text += `  * Family: ${item.family || 'Unknown'}\n`
      text += `  * Author: ${item.author || 'Unknown'}\n`
      text += `  * Source: ${sources.find(s => s.id === item.source)?.name || item.source}\n\n`
    })
  }
  
  return text
}

// Send results to Fireworks AI
const sendResultsToFireworksAI = useDebounceFn(async () => {
  try {
    console.log('[NameStatus] Starting AI summary generation function')
    
    // Only proceed if we have exact or inexact matches
    if (categorizedResults.value.exact.length === 0 && categorizedResults.value.inexact.length === 0) {
      console.log('[NameStatus] No exact or inexact matches found, skipping AI summary')
      return
    }
    
    // If a request is already in progress, don't start another one
    if (isAiSummaryRequestInProgress.value) {
      console.log('[NameStatus] AI summary request already in progress, skipping')
      return
    }
    
    // Create a hash of the current results to use for cache key
    const resultHash = JSON.stringify({
      exact: categorizedResults.value.exact.map(item => ({ 
        original: item.original, 
        matched: item.matched,
        source: item.source
      })),
      inexact: categorizedResults.value.inexact.map(item => ({ 
        original: item.original, 
        matched: item.matched,
        source: item.source
      }))
    })
    
    // Generate a more precise cache key for AI recommendations that includes result content
    const aiCacheKey = `ai-recommendations-hash-${generateBatchCacheKey(speciesNames.value)}-${hashString(resultHash)}`
    console.log('[NameStatus] Generated AI cache key:', aiCacheKey)
   
    // Set loading state
    isLoadingRecommendations.value = true
    isAiSummaryRequestInProgress.value = true
    aiRecommendations.value = ''
    showRecommendations.value = true
    
    // Format the results as plain text
    const formattedResults = formatResultsAsText()
    console.log('[NameStatus] Formatted results length:', formattedResults.length)
    
    // Get name prompt text from a string variable instead of fetching it
    const namePromptText = 'Please follow International Code of Nomenclature (ICN) principles when recommending botanical names. Use accepted botanical naming conventions and consider taxonomic accuracy.'
    
    // Prepare the prompt with botanical naming guidelines, results, and request for advice
    const messages = [
      {
        role: 'system',
        content: 'You are a botanical naming expert helping landscape architects choose the correct scientific names for plants.'
      },
      {
        role: 'user',
        content: `${namePromptText}

## Validation Results
${formattedResults}

Based on the original names provided by the user: ${speciesNames.value.join(', ')} [sic] and the botanical naming guidelines, please provide specific advice that addresses the following: firstly it should explain which is the correct name to use. Recommend the most appropriate name to use explaining your reasoning by referring to the ICN principles (but don't mention ICN or principles in your response). Consider factors like nomenclatural priority, taxonomic accuracy, and current acceptance. And secondly it should provide a simple and confident recommendation in the context of the name being used for a commercial landscape supplier to source correctly (but don't mention or describe this context in your response). Please format your response as a consise sentence or two explaining the recommended name to use that is easy to understand. Be aware that there may be several alternative names for the same species so we have to be careful to not recommend the same name for multiple species. Write out your response in markdown, with a natural sounding tone, as if you were speaking to a person and do not preface with any preamble or introduction that would indicate to the user that you are an AI.`
      }
    ]
    
    console.log('[NameStatus] Prepared messages for AI')
    
    try {
      let content = null
      let apiUsed = 'none'
      
      // Attempt Fireworks API first
      console.log('[NameStatus] Attempting to use Fireworks API...')
      
      try {
        // Send request to Fireworks API
        console.log('[NameStatus] Sending request to Fireworks API')
        const fireworksResponse = await $fetch('/api/ai/fwrk-generic', {
          method: 'POST',
          body: {
            messages: messages,
            model: 'accounts/fireworks/models/llama-v3p1-8b-instruct',
            temperature: 0.3,
            max_tokens: 2000
          }
        })
        
        console.log('[NameStatus] Fireworks response received')
        console.log('[NameStatus] Fireworks response type:', typeof fireworksResponse)
        console.log('[NameStatus] Fireworks response structure:', Object.keys(fireworksResponse || {}))
        
        // Extract content directly from the content field
        if (fireworksResponse && typeof fireworksResponse === 'object' && 'content' in fireworksResponse) {
          content = fireworksResponse.content
          apiUsed = 'fireworks'
          console.log('[NameStatus] Successfully got content from Fireworks:', !!content)
        } else {
          console.warn('[NameStatus] Fireworks API returned unexpected response structure:', fireworksResponse)
        }
      } catch (fireworksError) {
        console.warn('[NameStatus] Fireworks API call error:', fireworksError)
        
        // Fall back to Groq
        console.log('[NameStatus] Falling back to Groq API')
        
        try {
          // Different payload structure for Groq
          const groqResponse = await $fetch('/api/ai/grq-generic.post', {
            method: 'POST',
            body: {
              systemPrompt: messages[0].content,
              prompt: messages[1].content,
              temperature: 0
            }
          })
          
          console.log('[NameStatus] Groq response received')
          console.log('[NameStatus] Groq response type:', typeof groqResponse)
          console.log('[NameStatus] Groq response structure:', groqResponse ? Object.keys(groqResponse) : 'null')
          
          // Check for the data property in the Groq response - the Groq API returns { status: 200, data: "content" }
          if (groqResponse && typeof groqResponse === 'object') {
            // First check for direct data property which is the expected return format
            if ('data' in groqResponse && groqResponse.data) {
              content = groqResponse.data
              apiUsed = 'groq'
              console.log('[NameStatus] Successfully got content from Groq using data field:', !!content)
            } 
            // Then check if the response has a status object and if so, try to find the content
            else if ('status' in groqResponse) {
              // Handle different status response formats safely
              let isSuccess = false
              
              // The status could be a direct value or an object with a status property
              if (typeof groqResponse.status === 'number') {
                isSuccess = groqResponse.status === 200
              } else if (typeof groqResponse.status === 'string') {
                isSuccess = groqResponse.status === '200'
              } else if (typeof groqResponse.status === 'object' && groqResponse.status !== null) {
                // Status might be an object with a nested status property
                if ('status' in groqResponse.status && typeof groqResponse.status.status === 'string') {
                  isSuccess = groqResponse.status.status === '200'
                } else if ('statusInternal' in groqResponse.status) {
                  // Default to success if we can't determine status but don't have obvious errors
                  isSuccess = true
                }
              }
              
              console.log('[NameStatus] Groq response status check:', isSuccess ? 'Success' : 'Failure')
              
              if (isSuccess) {
                // Try common response fields
                const fieldChecks = ['data', 'content', 'text', 'response', 'message']
                
                // Safely check each field
                for (const field of fieldChecks) {
                  if (field in groqResponse && groqResponse[field as keyof typeof groqResponse]) {
                    content = groqResponse[field as keyof typeof groqResponse] as string
                    apiUsed = 'groq'
                    console.log(`[NameStatus] Found content in Groq response field '${field}':`, !!content)
                    break
                  }
                }
              }
              
              // If still no content but response has a structure, convert to string as last resort
              if (!content && Object.keys(groqResponse).length > 0) {
                try {
                  // Stringify the response as a last resort
                  content = JSON.stringify(groqResponse)
                  apiUsed = 'groq-raw'
                  console.log('[NameStatus] Using raw Groq response as content (stringified)')
                } catch (stringifyError) {
                  console.error('[NameStatus] Failed to stringify Groq response:', stringifyError)
                }
              }
            }
          }
          
          if (!content) {
            console.error('[NameStatus] Could not extract content from Groq response:', groqResponse)
          }
        } catch (groqError) {
          console.error('[NameStatus] Both Fireworks and Groq APIs failed:', groqError)
        }
      }
      
      // Process content if we received any
      if (content) {
        // Ensure content is a string before accessing string properties
        const contentStr = typeof content === 'string' ? content : 
                          typeof content === 'object' ? JSON.stringify(content) : 
                          String(content)
                            
        console.log(`[NameStatus] Processing content from ${apiUsed} (${contentStr.length} chars)`)
        const processedContent = contentStr.replace('Recommendation: ', '')
        aiRecommendations.value = processedContent
        console.log(`[NameStatus] Set aiRecommendations.value to: "${processedContent.substring(0, 50)}..." (${processedContent.length} chars)`)
        
        // Make sure showRecommendations is true
        showRecommendations.value = true
        console.log(`[NameStatus] Set showRecommendations.value to: ${showRecommendations.value}`)
        
        // Cache the AI recommendations with the precise cache key
        if (!nuxtApp.payload) {
          console.log('[NameStatus] Creating nuxtApp.payload structure')
          // Create a minimal structure for payload that correctly matches expected types
          nuxtApp.payload = { 
            data: {}, 
            state: {}, 
            once: new Set<string>(), // Must be a Set of strings
            _errors: {} as Record<string, Error | null> // Must have this structure
          }
        }
        if (!nuxtApp.payload.data) {
          nuxtApp.payload.data = {}
        }
        nuxtApp.payload.data[aiCacheKey] = processedContent
        console.log('[NameStatus] Cached results with key:', aiCacheKey)
      } else {
        console.error('[NameStatus] No content received from either API')
        throw new Error('No valid content received from AI services')
      }
    } catch (error) {
      console.error('[NameStatus] Error getting AI recommendations:', error)
      // push.error({
      //   title: 'Analysis failed',
      //   message: `Could not get botanical name recommendations: ${error instanceof Error ? error.message : 'Unknown error'}`,
      //   duration: 3000,
      // })
    } finally {
      isLoadingRecommendations.value = false
      isAiSummaryRequestInProgress.value = false
      console.log('[NameStatus] AI summary request completed')
      
      // Debug final state to verify recommendations should display
      setTimeout(() => {
        console.log('[NameStatus] Final state after AI request:')
        
        
        // Force show recommendations if they exist but aren't showing
        if (aiRecommendations.value && !showRecommendations.value) {
          console.log('[NameStatus] Forcing showRecommendations to true')
          showRecommendations.value = true
        }
      }, 100) // Small delay to ensure state has propagated
    }
  } catch (error) {
    console.error('[NameStatus] Error in sendResultsToFireworksAI:', error)
    isLoadingRecommendations.value = false
    isAiSummaryRequestInProgress.value = false
    push.error({
      title: 'Analysis failed',
      message: `Could not get botanical name recommendations: ${error instanceof Error ? error.message : 'Unknown error'}`,
      duration: 3000,
    })
  }
}, 800)

// Simple string hash function for cache keys
function hashString(str: string): string {
  let hash = 0
  if (str.length === 0) return hash.toString()
  
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = ((hash << 5) - hash) + char
    hash = hash & hash // Convert to 32bit integer
  }
  
  return Math.abs(hash).toString(16)
}

// Main refresh function
const refresh = async () => {
  isRefreshing.value = true
  try {
    console.log('[NameStatus] Starting refresh with species names:', speciesNames.value)
    const uniqueSpeciesNames = [...new Set(speciesNames.value)].filter(Boolean)
    if (!uniqueSpeciesNames.length) {
      console.log('[NameStatus] No unique species names found to process')
      isFetchingData.value = false // Ensure flag is reset even if no names
      return
    }
    
    console.log('[NameStatus] Processing unique species names:', uniqueSpeciesNames)
    
    // Start the SSE search (now with caching)
    fetchSpeciesData(new Set(uniqueSpeciesNames))
    
    // Update UI state based on categorized results
    // Note: This may be populated from cache immediately if available
    showHide.value.exact = categorizedResults.value?.exact?.length > 0
    showHide.value.inexact = categorizedResults.value?.inexact?.length > 0
    showHide.value.invalid = categorizedResults.value?.invalid?.length > 0
    
    // If we already have results and they came from cache, emit them
    if (hasAnyResults.value && pendingSources.value.length === 0) {
      console.log('[NameStatus] Emitting cached results during refresh')
      emitValidationResults()
      
      // Since we used cache, mark the search as complete
      isFetchingData.value = false
      
      // // Check if we should trigger AI summary generation
      // setTimeout(() => {
      //   console.log('[NameStatus] Checking if AI summary needed after refresh with cached results')
      //   triggerAISummary()
      // }, 500)
    }
    
    // Add a safety timeout - if after 30 seconds we're still fetching,
    // force isFetchingData to false as a failsafe
    setTimeout(() => {
      if (isFetchingData.value) {
        console.warn('[NameStatus] Search timeout after 30s, forcing isFetchingData to false')
        isFetchingData.value = false
      }
    }, 30000) // 30 second timeout
    
  } catch (error) {
    console.error('[NameStatus] Error in refresh:', error)
    isFetchingData.value = false // Make sure we reset the flag on error
  }
  isRefreshing.value = false
}

const getTnrsResult = (name: string) => {
  if (!name) return null
  const normalizedName = name.toLowerCase()
  const result = tnrsData.value?.find(d => {
    const submittedName = d.Name_submitted?.toLowerCase()
    const matchedName = d.Name_matched?.toLowerCase()
    return submittedName === normalizedName || matchedName === normalizedName
  })
  return result
}

// Function to emit validation results with deduplication
function emitValidationResults() {
  if (!categorizedResults || !categorizedResults.value) {
    console.warn('[NameStatus] Cannot emit validation results: categorizedResults is not available')
    return
  }

  // Get the 1:1 mapping from validNames
  const validNamesList = Object.values(categorizedResults.value.validNames)
    .map(item => {
      // Try to parse quantity as a number if it's a string
      let quantity = item.quantity;
      if (typeof quantity === 'string') {
        const parsed = parseFloat(quantity);
        if (!isNaN(parsed)) quantity = parsed;
      }
      
      return {
        originalName: item.original,
        species: item.matched || item.original,
        quantity: quantity || 1, // Only default to 1 if no quantity found
        genus: item.genus || (item.matched ? item.matched.split(' ')[0] : ''),
        family: item.family || 'Unknown',
        score: item.score || 0,
        source: item.source || 'unknown',
        matchType: item.matchType || 'none'
      }
    })

  // Log the quantities we're using
  console.log('[NameStatus] Plant quantities for diversity calculation:', 
    validNamesList.map(p => ({
      original: p.originalName,
      species: p.species,
      quantity: p.quantity
    }))
  )

  // Construct the full data object including plants and metadata
  const diversityData = {
    plants: validNamesList,
    exactMatches: categorizedResults.value.exact.length,
    inexactMatches: categorizedResults.value.inexact.length,
    invalid: categorizedResults.value.invalid.map(item => ({
      original: item.original,
      score: item.score || 0
    }))
  }

  console.log('[NameStatus] Final diversity data with 1:1 mapping:', 
    validNamesList.map(p => ({
      original: p.originalName,
      validated: p.species,
      matchType: p.matchType,
      source: p.source,
      quantity: p.quantity
    }))
  )

  mitter.emit('name:validation:results', diversityData)
}

// Watch for meaningful changes in the categorized results
watchDebounced(
  () => categorizedResults.value,
  async (newVal, oldVal) => {
    if (isFetchingData.value) return
    const hasExactOrInexactMatches = categorizedResults.value?.exact?.length > 0 || 
                                    categorizedResults.value?.inexact?.length > 0
    const allSourcesCompleted = pendingSources.value?.length === 0
    // Only proceed if we have results and all sources completed
    if (!isFetchingData.value && hasExactOrInexactMatches && allSourcesCompleted) {
      await sendResultsToFireworksAI()
    }
  },
  { deep: true, debounce: 1000 }
)

// Add a diagnostic function to manually check and trigger the AI summary
function triggerAISummary() {
  if (!isFetchingData.value && 
      (categorizedResults.value.exact.length > 0 || 
      categorizedResults.value.inexact.length > 0) &&
      !isAiSummaryRequestInProgress.value && 
      pendingSources.value.length === 0) { // Make sure ALL sources have completed
    console.log('[NameStatus] Conditions met, manually triggering AI summary generation')
    sendResultsToFireworksAI()
    return true
  } else {
    console.log('[NameStatus] Conditions NOT met for AI summary generation')
    return false
  }
}

// Add a manual test function that can be called from the browser console
function manualFireworksTest() {
  console.log('[NameStatus] Manual test of Fireworks/Groq API fallback initiated')
  
  // Check if we have results to process
  if (categorizedResults.value.exact.length === 0 && categorizedResults.value.inexact.length === 0) {
    console.error('[NameStatus] No results to send to AI - add some names first')
    return false
  }
  
  // Reset state
  isAiSummaryRequestInProgress.value = false
  isLoadingRecommendations.value = false
  aiRecommendations.value = ''
  
  // Trigger the API call
  sendResultsToFireworksAI()
  return true
}

// Expose the test function to the window for console access
if (process.client) {
  // @ts-ignore - allow global access for debugging
  window.testNameStatusAI = manualFireworksTest
  console.log('[NameStatus] Manual test function available at window.testNameStatusAI()')
}



// Function to explicitly handle refresh button click
function handleRefreshClick() {
  console.log('[NameStatus] Refresh button clicked, checking if ready for AI summary generation')
  
  
  // Check if we have results to process
  if (categorizedResults.value.exact.length === 0 && categorizedResults.value.inexact.length === 0) {
    console.error('[NameStatus] No validation results to summarize')
    push.info({
      title: 'No data',
      message: 'No plant names to analyse',
      duration: 3000,
    })
    return
  }
  
  // If AI is already being generated, don't do anything
  if (isAiSummaryRequestInProgress.value) {
    console.log('[NameStatus] AI generation already in progress, ignoring refresh click')
    return
  }
  
  // Check if all sources have completed
  if (pendingSources.value.length > 0) {
    console.log('[NameStatus] Still waiting for sources to complete:', pendingSources.value)
    isLoadingRecommendations.value = true
    showRecommendations.value = true
    
    // Add a visual indicator that we're waiting for sources to complete
    aiRecommendations.value = 'Waiting for all plant names to finish processing...'
    
    // Check again after a short delay
    setTimeout(() => {
      if (pendingSources.value.length === 0) {
        console.log('[NameStatus] All sources have now completed, proceeding with AI summary')
        handleRefreshClick() // Retry now that sources are complete
      }
    }, 1000)
    return
  }
  
  // emit the results
  emitValidationResults()

  // Set loading state first
  isLoadingRecommendations.value = true
  
  // Force-show the recommendations area
  showRecommendations.value = true
  
  // Use a small timeout to prevent state flashing
  setTimeout(() => {
    // Reset state to ensure we can generate a new summary
    isAiSummaryRequestInProgress.value = false
    
    console.log('[NameStatus] State after refresh button click:')
    
    
    // Force trigger the AI summary generation
    sendResultsToFireworksAI()
  }, 50)
}

// Call this in key places
watch(aiRecommendations, () => {
  console.log('[NameStatus] aiRecommendations changed')
  
})

onMounted(async () => {
  try {
    console.log('[NameStatus] Component mounted, checking API endpoints...')

    if (speciesNames.value?.length) {
      // Check if we have cached results before refreshing
      const uniqueSpeciesNames = [...new Set(speciesNames.value)].filter(Boolean)
      const cachedData = getCachedSpeciesData(uniqueSpeciesNames)
      
      if (cachedData) {
        console.log('[NameStatus] Using cached data on mount')
        sourceResults.value = cachedData.sourceResults || {}
        pendingSources.value = []
        completedSources.value = sources.map(s => s.id)
        gbifLoaded.value = true
        tnrsLoaded.value = true
        
        // Initialize progress tracking for cached data
        expectedSources.value = sources.length
        processedSources.value = sources.length
        isFetchingData.value = false
        
        // Emit the cached results
        emitValidationResults()
        
        // // Add a slight delay to allow reactivity to update before checking
        // setTimeout(() => {
        //   triggerAISummary()
        // }, 500)
      } else {
        // No cache, refresh normally
        // Initialize progress tracking for fresh data
        expectedSources.value = sources.length
        processedSources.value = 0
        isFetchingData.value = true
        
        await refresh()
      }
    }
    
    // If we already have results, emit them for DiversityStatus
    if (categorizedResults.value && (categorizedResults.value.exact.length > 0 || categorizedResults.value.inexact.length > 0)) {
      emitValidationResults()
    }
  } catch (error) {
    console.error('[NameStatus] Error on mount:', error)
  }
})

onUnmounted(() => {
  // Clean up any active connections
  stopSearch()
  
  // Reset progress tracking state
  expectedSources.value = 0
  processedSources.value = 0
  isFetchingData.value = false
})

// Add this in the script section, near the other reactive refs
const openAccordions = ref(['exact']) // Initialize with 'exact' to have it open by default
</script>

<template>
  <div class="name-status">
    <ScrollArea class="h-[calc(100vh-10rem)] pb-[5vh]">
    <div class="flex justify-between items-start px-4 py-2">
        <div>
          <h3 
                class="text-md font-[600] mb-2 group hover:cursor-pointer relative"
                @click="() => refresh(false)"
              >
                <Icon 
                  v-if="isRefreshing" 
                  name="line-md:loading-loop" 
                  class="w-4 h-4 absolute left-0 transition-all duration-200 mr-2 relative top-2"
                />
                <Icon 
                  v-else
                  name="lucide:refresh-cw" 
                  class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
                />
                <div class="relative transition-all duration-200" :class="[isRefreshing ? 'translate-x-6' : 'group-hover:translate-x-6']">Name Check</div>
              </h3>
          <p class="text-sm text-muted-foreground max-w-2xl">
            Validates plant names against botanical databases to ensure accuracy and standardization.
          </p>
        </div>
      </div>
      
    <!-- Loading state -->
    <!-- <div v-if="isFetchingData && !hasAnyResults" class="flex items-center justify-center p-4">
      <div class="flex flex-col items-center gap-2 w-full max-w-md">
        <Icon name="line-md:loading-loop" class="w-6 h-6 animate-spin text-primary" />
        <p class="text-sm text-muted-foreground">
          Validating species names across {{ expectedSources }} sources...
        </p>
        <div class="w-full flex items-center gap-2 mt-1">
          <Progress :model-value="progressPercentage" class="w-full h-2" />
          <span class="text-xs text-muted-foreground w-12 text-right">{{ progressPercentage }}%</span>
        </div>
        <p class="text-xs text-muted-foreground">
          {{ processedSources }} of {{ expectedSources }} sources completed
        </p>
      </div>
    </div> -->
    
    <!-- Status indicator while sources are still loading -->
    <div v-if="hasAnyResults && isFetchingData" class="mb-4 p-3 rounded-md bg-muted flex flex-col gap-2">
      <div class="flex items-center text-sm font-intervariable ">
        
          Found {{ totalResults }} results so far. Checking {{ pendingSources.length }} more sources...
        
      </div>
      <div class="flex items-center gap-2">
        <Progress :model-value="progressPercentage" class="w-full h-1.5" />
        <span class="text-xs text-muted-foreground w-12 text-right">{{ progressPercentage }}%</span>
      </div>
      <p class="text-xs text-muted-foreground">
        {{ processedSources }} of {{ expectedSources }} sources completed
      </p>
    </div>

    <!-- AI Recommendations Section -->
    <div v-if="hasAnyResults" class="px-4 py-2 mt-4">
      <div v-if="aiRecommendations" class="flex items-center justify-between mb-2">
        <h3 @click="handleRefreshClick" class="text-md font-[600] mb-0 group cursor-pointer relative">
          <Icon 
            v-if="isLoadingRecommendations" 
            name="line-md:loading-loop" 
            class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
          />
          <Icon 
            v-else
            name="lucide:refresh-cw" 
            class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
            
          />
          <div class="relative group-hover:translate-x-6 transition-all duration-200">
            Summary
          </div>
        </h3>
      </div>
      
      <!-- Unified summary container with v-show instead of v-if to prevent remounting -->
      <div class="summary-container">
        <!-- Actual summary content -->
        <ContainerScrollCard 
              v-auto-animate
              :beam-width="48" 
              :border-thickness="2"
              background="bg-background"
              :show-blob="false"
              :blob-speed="0.5"
              :fit-content="true"
              class="relative my-8 mt-3"
              v-show="hasAnyResults && !isFetchingData"
            >
        <div v-if="aiRecommendations" class="text-sm !font-intervariable p-4 rounded-md transition-opacity duration-300":class="isLoadingRecommendations ? 'opacity-50' : 'opacity-100'">
          <img src="/images/logos/superseeded_logo_s.webp" class="w-12 h-12 mb-4" />
          <MDC class="ai-summary !font-intervariable" :value="aiRecommendations" />
        </div>
        <div v-else class="text-sm !font-intervariable p-4 rounded-md transition-opacity duration-300">
        <div class="space-y-2">
            <Skeleton class="h-[18px] w-full rounded-md bg-primary/80" />
            <Skeleton class="h-[18px] w-[80%] rounded-md bg-primary/80" />
            <Skeleton class="h-[18px] w-[60%] rounded-md bg-primary/80" />
            <Skeleton class="h-[18px] w-[75%] rounded-md bg-primary/80" />
          </div>
        </div>

        </ContainerScrollCard>
      </div>
    </div>
    <!-- Results display -->
    <div v-if="hasAnyResults" class="space-y-2">

      <!-- Data Sources -->
      <div class="relative mt-2 px-4">
        <p class="text-xs text-muted-foreground flex flex-wrap items-center gap-2 py-4">
          Sources: 
          <!-- Add logos for each source that provided results -->
          <template v-for="source in completedSources" :key="source">
            <img 
              v-if="getLogoPath(source)" 
              :src="getLogoPath(source)" 
              class="h-6 object-contain max-h-5" 
              :alt="sources.find(s => s.id === source)?.name || source.toUpperCase()"
            />
            <Badge 
              v-else
              size="sm" 
              variant="outline" 
              class="text-[0.65em] py-0 h-4"
            >
              {{ sources.find(s => s.id === source)?.name || source.toUpperCase() }}
            </Badge>
          </template>
        </p>
      </div>

      <!-- Species Name Filters -->
      <div v-if="hasAnyResults && uniqueSpeciesNames.length > 0" class="px-4 my-3 mt-20">
        <div class="flex flex-col gap-2">
          <div class="flex flex-wrap gap-2 mt-1 py-2">
            <Badge
              v-for="name in uniqueSpeciesNames" 
              :key="name"
              :variant="activeFilter === name ? 'default' : 'outline'"
              class="!border-2 !border-primary cursor-pointer transition-colors text-sm font-[300] max-w-30 truncate relative"
              
              @click="activeFilter = activeFilter === name ? null : name"
            >
              <p class="p-0 m-0 truncate text-ellipsis">
                {{ name }}
              </p>
              <Icon 
                v-if="activeFilter === name" 
                name="lucide:x" 
                class="w-3 h-3 min-h-3 min-w-3 ml-2" 
              />
            </Badge>
          </div>
        </div>
      </div>

      <!-- Exact Matches -->
      <Accordion v-if="filteredResults.exact?.length" v-model="openAccordions" type="multiple" class="w-full mt-12">
        <AccordionItem value="exact" class="border rounded-md overflow-hidden">
          <AccordionTrigger class="px-4 py-2 hover:no-underline hover:bg-accent/30">
            <div class="flex items-center gap-2">
              <Badge variant="default" class="bg-green-400 text-white border-0">
                {{ filteredResults.exact?.length || 0 }}
              </Badge>
              <span class="font-medium">Exact Matches</span>
            </div>
          </AccordionTrigger>
          <AccordionContent class="px-4 pb-4 pt-2">
            <div v-if="filteredResults.exact?.length" class="space-y-3">
              <div v-for="item in filteredResults.exact" :key="item.original + item.matched" class="border rounded-2xl p-3 bg-background">
                <div class="flex justify-between items-start mb-2">
                  <div>
                    <h4 class="text-sm font-medium">{{ item.original }}</h4>
                    <p class="text-xs text-muted-foreground">{{ item.family || '' }}</p>
                  </div>
                  <Badge v-if="item.source === 'gemini'" variant="outline" class="bg-blue-500/10 text-blue-500 border-0">
                    <Icon name="lucide:sparkles" class="w-3 h-3 mr-1" />
                    AI Match
                  </Badge>
                  <Badge v-else variant="outline" class="bg-emerald-500/10 text-emerald-500 border-0">
                    <Icon name="lucide:check" class="w-3 h-3 mr-1" />
                    Valid
                  </Badge>
                </div>
                
                <div class="grid grid-cols-2 gap-x-4 gap-y-2 mb-2">
                  <div>
                    <p class="text-[0.7em] uppercase tracking-wide text-muted-foreground/70 font-medium">Matched Name</p>
                    <p class="text-sm">{{ item.matched }}</p>
                  </div>
                  <div>
                    <p class="text-[0.7em] uppercase tracking-wide text-muted-foreground/70 font-medium">Authority</p>
                    <p class="text-sm">{{ item.author || 'Unknown' }}</p>
                  </div>
                </div>
                
                <div class="grid grid-cols-2 gap-x-4 gap-y-2">
                  <div>
                    <p class="text-[0.7em] uppercase tracking-wide text-muted-foreground/70 font-medium">Source</p>
                    <div class="flex items-center mt-1">
                      <img 
                        v-if="getLogoPath(item.source)" 
                        :src="getLogoPath(item.source)" 
                        class="h-6 object-contain mr-2" 
                        :alt="sources.find(s => s.id === item.source)?.name || item.source"
                      />
                      <span v-else class="text-sm">{{ sources.find(s => s.id === item.source)?.name || item.source }}</span>
                    </div>
                  </div>
                  <div>
                    <p class="text-[0.7em] uppercase tracking-wide text-muted-foreground/70 font-medium">Status</p>
                    <p class="text-sm">{{ item.status || 'ACCEPTED' }}</p>
                  </div>
                </div>
                
                <div class="flex items-center gap-2 mt-1">
                  <a v-if="item.url" 
                    :href="item.url" 
                    target="_blank"
                    class="text-[0.7em] text-primary hover:underline inline-flex items-center"
                  >
                    View Details
                    <Icon name="lucide:external-link" class="w-3 h-3 ml-1" />
                  </a>
                </div>
              </div>
            </div>
            <div v-else class="py-2 text-sm text-muted-foreground">
              No exact matches found.
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      
      <!-- Inexact Matches -->
      <Accordion v-if="filteredResults.inexact?.length" :default-value="showHide.inexact ? ['inexact'] : []" type="multiple" class="w-full">
        <AccordionItem value="inexact" class="border rounded-md overflow-hidden">
          <AccordionTrigger class="px-4 py-2 hover:no-underline hover:bg-accent/30">
            <div class="flex items-center gap-2">
              <Badge variant="default" class="bg-amber-300 text-white border-0">
                {{ filteredResults.inexact?.length || 0 }}
              </Badge>
              <span class="font-medium">Partial Matches</span>
            </div>
          </AccordionTrigger>
          <AccordionContent class="px-4 pb-4 pt-2">
            <div v-if="filteredResults.inexact?.length" class="space-y-3">
              <div v-for="item in filteredResults.inexact" :key="item.original + item.matched" class="border rounded-2xl p-3 bg-background">
                <div class="flex justify-between items-start mb-2">
                  <div>
                    <h4 class="text-sm font-medium">{{ item.original }}</h4>
                    <p class="text-xs text-muted-foreground">{{ item.family || '' }}</p>
                  </div>
                  <Badge variant="outline" class="bg-yellow-500/10 text-yellow-500 border-0">
                    <Icon name="lucide:alert-triangle" class="w-3 h-3 mr-1" />
                    Partial Match
                  </Badge>
                </div>
                
                <div class="grid grid-cols-2 gap-x-4 gap-y-2 mb-2">
                  <div>
                    <p class="text-[0.7em] uppercase tracking-wide text-muted-foreground/70 font-medium">Suggested Name</p>
                    <p class="text-sm">{{ item.matched }}</p>
                  </div>
                  <div>
                    <p class="text-[0.7em] uppercase tracking-wide text-muted-foreground/70 font-medium">Authority</p>
                    <p class="text-sm">{{ item.author || 'Unknown' }}</p>
                  </div>
                </div>
                
                <div class="grid grid-cols-2 gap-x-4 gap-y-2">
                  <div>
                    <p class="text-[0.7em] uppercase tracking-wide text-muted-foreground/70 font-medium">Source</p>
                    <div class="flex items-center mt-1">
                      <img 
                        v-if="getLogoPath(item.source)" 
                        :src="getLogoPath(item.source)" 
                        class="h-6 object-contain mr-2" 
                        :alt="sources.find(s => s.id === item.source)?.name || item.source"
                      />
                      <span v-else class="text-sm">{{ sources.find(s => s.id === item.source)?.name || item.source }}</span>
                    </div>
                  </div>
                  <div>
                    <p class="text-[0.7em] uppercase tracking-wide text-muted-foreground/70 font-medium">Status</p>
                    <p class="text-sm">{{ item.status || 'ACCEPTED' }}</p>
                  </div>
                </div>
                
                <div class="flex items-center gap-2 mt-1">
                  <a v-if="item.url" 
                    :href="item.url" 
                    target="_blank"
                    class="text-[0.7em] text-primary hover:underline inline-flex items-center"
                  >
                    View Details
                    <Icon name="lucide:external-link" class="w-3 h-3 ml-1" />
                  </a>
                </div>
              </div>
            </div>
            <div v-else class="py-2 text-sm text-muted-foreground">
              No partial matches found.
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

       <!-- All Hits -->
       <Accordion v-if="hasAnyResults" :default-value="[]" type="multiple" class="w-full mt-12">
        <AccordionItem value="all-hits" class="border rounded-md overflow-hidden">
          <AccordionTrigger class="px-4 py-2 hover:no-underline hover:bg-accent/30">
            <div class="flex items-center gap-2">
              <Badge variant="default" class="bg-primary text-white border-0">
                {{ totalResults }}
              </Badge>
              <span class="font-medium">All Results</span>
            </div>
          </AccordionTrigger>
          <AccordionContent class="px-4 pb-4 pt-2">
            <div v-if="Object.keys(sourceResults).length" class="space-y-3">
              <div v-for="[source, results] in Object.entries(sourceResults)" :key="source" class="border rounded-2xl p-3 bg-background">
                <div class="flex justify-between items-start mb-2">
                  <div class="flex items-center gap-2">
                    <img 
                      v-if="getLogoPath(source)" 
                      :src="getLogoPath(source)" 
                      class="h-6 object-contain" 
                      :alt="sources.find(s => s.id === source)?.name || source"
                    />
                    <h4 class="text-sm font-medium">{{ sources.find(s => s.id === source)?.name || source }}</h4>
                  </div>
                  <Badge variant="outline" class="bg-primary/10 text-primary border-0">
                    {{ results.length }} hits
                  </Badge>
                </div>
                
                <div class="mt-2 space-y-2">
                  <div v-for="item in results" :key="item.original + item.matched" class="text-sm flex items-center justify-between py-1 border-b last:border-0">
                    <div class="flex-1">
                      <HoverCard>
                        <HoverCardTrigger>
                          <span class="text-muted-foreground">{{ item.original }}</span>
                          <Icon name="lucide:arrow-right" class="w-3 h-3 inline mx-2" />
                          <span>{{ item.matched }}</span>
                        </HoverCardTrigger>
                        <HoverCardContent class="w-80 p-4">
                          <div class="space-y-2">
                            <p class="text-sm"><span class="font-medium">Family:</span> {{ item.family || 'Unknown' }}</p>
                            <p class="text-sm"><span class="font-medium">Author:</span> {{ item.author || 'Unknown' }}</p>
                            <p class="text-sm"><span class="font-medium">Status:</span> {{ item.status || 'Unknown' }}</p>
                            <a v-if="item.url" 
                              :href="item.url" 
                              target="_blank"
                              class="text-xs text-primary hover:underline inline-flex items-center mt-2"
                            >
                              View Details
                              <Icon name="lucide:external-link" class="w-3 h-3 ml-1" />
                            </a>
                          </div>
                        </HoverCardContent>
                      </HoverCard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="py-2 text-sm text-muted-foreground">
              No results found.
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      
      <!-- Invalid Matches -->
      <Accordion v-if="filteredResults.invalid?.length" :default-value="showHide.invalid ? ['invalid'] : []" type="multiple" class="w-full">
        <AccordionItem value="invalid" class="border rounded-md overflow-hidden">
          <AccordionTrigger class="px-4 py-2 hover:no-underline hover:bg-accent/30">
            <div class="flex items-center gap-2">
              <Badge variant="default" class="bg-red-400 text-white border-0">
                {{ filteredResults.invalid?.length || 0 }}
              </Badge>
              <span class="font-medium">Not Found</span>
            </div>
          </AccordionTrigger>
          <AccordionContent class="px-4 pb-4 pt-2">
            <div v-if="filteredResults.invalid?.length" class="space-y-3">
              <div v-for="item in filteredResults.invalid" :key="item.original" class="border rounded-2xl p-3 bg-background">
                <div class="flex justify-between items-start mb-2">
                  <div>
                    <h4 class="text-sm font-medium">{{ item.original }}</h4>
                  </div>
                  <Badge v-if="item.source === 'pending'" variant="outline" class="bg-muted text-muted-foreground border-0">
                    <Icon name="line-md:loading-loop" class="w-3 h-3 mr-1 animate-spin" />
                    Checking...
                  </Badge>
                  <Badge v-else variant="outline" class="bg-destructive/10 text-destructive border-0">
                    <Icon name="lucide:x" class="w-3 h-3 mr-1" />
                    Not Found
                  </Badge>
                </div>
                
                <p class="text-sm text-muted-foreground">
                  <span v-if="item.source === 'pending'">
                    Still checking databases...
                  </span>
                  <span v-else>
                    This species name was not found in any of our botanical databases.
                  </span>
                </p>
              </div>
            </div>
            <div v-else class="py-2 text-sm text-muted-foreground">
              
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
    
    <!-- TNRS API error message -->
    <div v-if="tnrsError && !pendingSources.includes('tnrs')" class="mt-4 p-3 border border-yellow-200 bg-yellow-50 dark:bg-yellow-900/20 dark:border-yellow-800 rounded-md">
      <div class="flex gap-2">
        <Icon name="lucide:alert-triangle" class="w-5 h-5 text-yellow-600 dark:text-yellow-400" />
        <div>
          <h4 class="text-sm font-medium text-yellow-800 dark:text-yellow-300">TNRS API Issue</h4>
          <p class="text-xs text-yellow-700 dark:text-yellow-400 mt-1">
            {{ tnrsError }}
          </p>
        </div>
      </div>
    </div>
    </ScrollArea> 
  </div>
</template> 

<style scoped>
.accordion-content {
  padding: 0.25rem 0.75rem;
}
:deep(.accordion-item) {
  border-bottom: none;
}

:deep(.accordion-item > div) {
  border-bottom: none;
}

:deep(.accordion-item > div > button) {
  border-bottom: none;
}

/* Add hover card animations */
:deep(.HoverCardContent) {
  transform-origin: var(--radix-hover-card-content-transform-origin);
  animation: contentShow 0.2s ease-out;
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: scale(0.96);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

:deep(.HoverCardContent[data-state="open"]) {
  animation: fadeIn 0.2s ease-out;
}

:deep(.HoverCardContent[data-state="closed"]) {
  animation: fadeOut 0.1s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.ai-summary :deep(p) {
  font-family: 'InterVariable', sans-serif !important;
  font-weight: 300 !important;
  padding: 0.5em 0 !important;
  font-size: 0.875rem !important;
}

/* Add smooth transitions to prevent flashing */
.summary-container {
  position: relative;
  min-height: 60px;
  transition: opacity 200ms ease-out;
}

.summary-container > div {
  transition: opacity 200ms ease-out;
}

.ai-summary :deep(p), .ai-summary :deep(li) {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

</style> 