import { ref, reactive, markRaw } from 'vue'
import { MyMap, FileUpload, Gallery, Review, PdfViewerPanel, VisualisePanel, CommunityTool, Discovery, Maintenance, PlantGrid, CreatePlantGrid, DiagnosticPanel } from "#components"
import { v4 as uuidv4 } from "uuid"
import type { Tool, ToolResponse, FileMetadata, ToolExecutionOptions } from '@/types/tools'
import { toolDefinitions } from '@/config/tools'
import { useUiStore } from '@/stores/uiStore'
import { useDockStore } from '@/stores/dockStore'
import { useSupabaseClient } from '#imports'
import { storeToRefs } from 'pinia'
import type { Raw, Component } from 'vue'

type ComponentMapping = {
  readonly MyMap: Raw<typeof MyMap>;
  readonly FileUpload: Raw<typeof FileUpload>;
  readonly PdfViewerPanel: Raw<typeof PdfViewerPanel>;
  readonly PlantGrid: Raw<typeof PlantGrid>;
  readonly CreatePlantGrid: Raw<typeof CreatePlantGrid>;
  readonly Gallery: Raw<typeof Gallery>;
  readonly Discovery: Raw<typeof Discovery>;
  readonly Maintenance: Raw<typeof Maintenance>;
  readonly VisualisePanel: Raw<typeof VisualisePanel>;
  readonly DiagnosticPanel: Raw<typeof DiagnosticPanel>;
}

interface UseToolsReturn {
  tools: Tool[];
  inferTool: (fileMetadata: FileMetadata) => {
    tool_uids: string[];
    tool_ids: string[];
    tools: Tool[];
    tool_objs: Tool[];
  } & FileMetadata;
  docTypeToolMap: { [key: string]: string[] };
  routeToolExecution: (options: ToolExecutionOptions, headers?: Record<string, string>) => Promise<any>;
  getToolComponent: (tool_uid: string | undefined) => Component | null;
}

// First, create the component mapping inside the composable
export function useTools(): UseToolsReturn {
  // Define component mapping here instead of at the top level
  const componentMapping: ComponentMapping = {
    'MyMap': markRaw(MyMap),
    'FileUpload': markRaw(FileUpload),
    'PdfViewerPanel': markRaw(PdfViewerPanel),
    'PlantGrid': markRaw(PlantGrid),
    'CreatePlantGrid': markRaw(CreatePlantGrid),
    'Gallery': markRaw(Gallery),
    'Discovery': markRaw(Discovery),
    'Maintenance': markRaw(Maintenance),
    'VisualisePanel': markRaw(VisualisePanel),
    'DiagnosticPanel': markRaw(DiagnosticPanel),
  }

  const client = useSupabaseClient()
  const uiStore = useUiStore()
  const { toggleChatDialog, togglePinChatDialog, toggleCommandDialog, toggleBottomSheet, setBottomSheetDynamicComponent } = uiStore
  const { chatDialogOpen, chatDialogPinned } = storeToRefs(uiStore)
  
  const dockStore = useDockStore()
  const {addPendingPanel, setPanelId_toolUid } = dockStore
  const { useCache } = storeToRefs(dockStore)
  
  // Initialize reactive tools array with the local componentMapping
  const tools = reactive<Tool[]>(toolDefinitions.map(tool => ({
    ...tool,
    component: componentMapping[tool.componentName as keyof typeof componentMapping] || null
  })))
  
  // Update the getToolComponent function with better debugging
  const getToolComponent = (tool_uid: string | undefined): Component | null => {
    if (!tool_uid) {
      console.log('getToolComponent called with no tool_uid');
      return null;
    }

    
    // Find the tool definition from our tools array
    const toolDef = tools.find(t => t.uid === tool_uid);
    
    if (!toolDef) {
      console.log(`[getToolComponent] No tool definition found for UID: ${tool_uid}`);
      return null;
    }
    
    // If the tool already has a component property, use it
    if (toolDef.component) {
      console.log(`[getToolComponent] Using component directly from tool: ${toolDef.id}`);
      return toolDef.component as Component;
    }
    
    // Use the component name to lookup in the mapping
    if (toolDef.componentName) {
      const compName = toolDef.componentName as keyof typeof componentMapping;
      const component = componentMapping[compName];
      
      if (component) {
        console.log(`[getToolComponent] Found component via mapping: ${compName}`);
        return component as Component;
      }
      
      // Fallback for PlantGrid cases
      if (toolDef.componentName.includes('PlantGrid') || 
          toolDef.id === 'plantgrid' || 
          toolDef.label?.includes('Plant') || 
          toolDef.label?.includes('Grid')) {
        console.log(`[getToolComponent] This looks like a PlantGrid tool, returning PlantGrid component`);
        return markRaw(PlantGrid) as Component;
      }
    }
    
    console.log(`[getToolComponent] No component found for tool UID: ${tool_uid}`);
    return null;
  }
  
  const docTypeToolMap: { [key: string]: string[] } = {
    'plantImage': ['gallery'],
    'PlantGrid': ['plantgrid', 'pdfviewer', 'map'],
    'report': ['schedule', 'pdfviewer'],
    'pdfviewer': ['pdfviewer'],
    'CAD': ['schedule'],
    'publication': ['schedule']
  }
  
  const inferTool = (fileMetadata: FileMetadata) => {
    const extensionMap: Record<string, string[]> = {
      png: ["plantImage"],
      jpg: ["plantImage"],
      jpeg: ["plantImage"],
      xlsx: ["PlantGrid"],
      doc: ["report"],
      pdf: ["PlantGrid"],
    }
    
    const mimeTypeMap: Record<string, string[]> = {
      "image/": ["plantImage"],
      "text/": ["PlantGrid"],
      "application/pdf": ["PlantGrid"],
    }
    
    const extension = fileMetadata.filename?.split(".").pop()?.toLowerCase()
    const toolsByExtension = extension && extensionMap[extension]
    
    const toolsByMimeType = Object.entries(mimeTypeMap)
      .filter(([key]) =>
        key.includes("/")
          ? fileMetadata.content_type === key
          : fileMetadata.content_type.startsWith(key)
      )
      .reduce((acc: string[], [, value]) => acc.concat(value), [])
    
    const docTypes = toolsByExtension || (toolsByMimeType.length ? toolsByMimeType : ['defaultTool'])
    
    const matchedTools = [...new Set(docTypes.flatMap((tool_id: string) => {
      const docTypes = docTypeToolMap[tool_id]
      return docTypes?.map((docType) => tools.find((t: Tool) => t.id === docType)) || []
    }))].filter((tool): tool is Tool => tool !== undefined)
    
    const tool_uids = matchedTools.map(tool => tool.uid)
    const tool_ids = matchedTools.map(tool => tool.id)
    
    return { 
      ...fileMetadata, 
      tool_uids, 
      tool_ids, 
      tools, 
      tool_objs: matchedTools 
    }
  }

  const routeToolExecution = async (options: ToolExecutionOptions, headers: Record<string, string> = {}) => {
    console.log('Starting routeToolExecution with options:', options)
    const selectedTools = options.tool_uids.map(tool_uid => 
      tools.find((tool: Tool) => tool.uid === tool_uid)
    ).filter((tool): tool is Tool => tool !== undefined)
    
    // Separate tools by execution environment
    const nuxtServerTools = selectedTools.filter(tool => tool.apiRoute)
    const edgeFunctionTools = selectedTools.filter(tool => !tool.apiRoute)
    console.log('Nuxt server tools:', nuxtServerTools)
    console.log('Edge function tools:', edgeFunctionTools)
    
    // Handle tools running on Nuxt server
    const nuxtServerPromises = nuxtServerTools.map(async (tool) => {
      console.log('Making request to tool:', tool.apiRoute)
      const body = {
        ...options.fileMetadata,
        file_id: options.file_id,
        tool_uid: tool.uid,
        running: true,
        logs: {
          started_at: new Date().toISOString()
        },
        options
      }
      console.log('Request body:', body)
      
      try {
        const response = await $fetch<ToolResponse>(tool.apiRoute!, {
          method: "POST",
          body,
          headers,
        })
        console.log('Tool response:', response)
        return response
      } catch (error) {
        console.error('Error calling tool:', error)
        throw error
      }
    })
    
    // Create edge function analyses records
    if (edgeFunctionTools.length > 0) {
      if (useCache.value === true) {
        console.log('Using cache for edge function tools')
        // find by file_id
        const { data: analyses, error: insertError } = await client
          .from('fileanalyses')
          .select('*')
          .eq('file_id', options.file_id)
          .eq('tool_uid', edgeFunctionTools[0].uid)
          .order('created_at', { ascending: false })
          .limit(1)
          .maybeSingle()
        console.log('Analyses:', analyses)
        
        if (analyses) {
          return analyses
        }
        // If no cached analysis found, continue to create new ones
      }
      
      // Process edge function tools (either cache disabled or no cached results found)
      console.log('Processing edge function tools:', edgeFunctionTools)
      const bulkInsertData = edgeFunctionTools.map(tool => ({
        file_id: options.file_id,
        tool_uid: tool.uid,
        running: true,
        logs: {
          started_at: new Date().toISOString()
        },
        options
      }));
      console.log('Bulk insert data:', bulkInsertData);
      
      try {
        const { data: analyses, error: insertError } = await client
          .from('fileanalyses')
          .insert(bulkInsertData as any) // Type assertion to bypass type checking
          .select()
        
        console.log('Bulk insert response:', { analyses, insertError })
        
        if (insertError) {
          console.error('Error bulk inserting analyses:', insertError)
          throw insertError
        }
        
        // Return both edge function and Nuxt server results
        const edgeFunctionResults = analyses?.map((analysis: { id: string }, index: number) => ({
          type: 'edge' as const,
          analysis_id: analysis.id,
          tool_uid: edgeFunctionTools[index].uid,
          panel_id: options.panel_id
        })) || []
        
        const nuxtResults = await Promise.all(nuxtServerPromises)
        console.log('Final results:', { edgeFunctionResults, nuxtResults })
        
        return [...edgeFunctionResults, ...nuxtResults]
      } catch (error) {
        console.error('Error in edge function processing:', error)
      }
    } 
    // Return just Nuxt server results if no edge functions
    const results = await Promise.all(nuxtServerPromises)
    console.log('Final Nuxt server results:', results)
    return results
  }

  return { tools, inferTool, docTypeToolMap, routeToolExecution, getToolComponent }
} 