import { reactive, computed } from 'vue'
import { useTools } from './tools/useTools'
import type { Tool } from '@/types/tools'
import { useSearch } from '@/composables/useSearch'
import type { GridData } from '@/types/schedule'
import { push } from 'notivue'
import { useProjectStore } from '@/stores/projectStore'
import { useThrottleFn } from '@vueuse/core'
import dayjs from 'dayjs'
import { useLazyAsyncData } from '#app'
// Types for transformation registry and functions

/**
 * Interface for a transformation function between two panel types
 */


export interface DataTransformer {
  /**
   * Transform data from the source panel for consumption by the target panel
   * @param data Source data from the panel
   * @param sourceToolId The tool ID of the source panel
   * @param targetToolId The tool ID of the target panel
   * @returns Transformed data ready for the target panel (can be async)
   */
  transform: (data: any, sourceToolId: string, targetToolId: string) => Promise<any> | any
  
  /**
   * Optional priority level for this transformer (higher wins when multiple transformers match)
   */
  priority?: number
  
  /**
   * Optional description of what this transformer does
   */
  description?: string

  /**
   * Optional options for this transformer
   */
  options?: {
    concurrentRequests?: number
    [key: string]: any
  }
}

/**
 * Key structure for transformation registry
 * Format: 'sourceToolId:targetToolId'
 */
type TransformerKey = `${string}:${string}`

/**
 * Interface for a transformer registry entry
 */
interface TransformerRegistryEntry {
  key: TransformerKey
  sourceToolId: string
  targetToolId: string
  transformer: DataTransformer
}

/**
 * Interface for the transformer registry
 */
type TransformerRegistry = Map<TransformerKey, DataTransformer>

/**
 * Create a unique key for the transformer registry
 */
const createTransformerKey = (sourceToolId: string, targetToolId: string): TransformerKey => {
  return `${sourceToolId}:${targetToolId}` as TransformerKey
}

/**
 * Parse a transformer key into its source and target components
 */
const parseTransformerKey = (key: TransformerKey): { sourceToolId: string, targetToolId: string } => {
  const [sourceToolId, targetToolId] = key.split(':')
  return { sourceToolId, targetToolId }
}

// Default transformers for common panel connections

/**
 * Transform grid data for map display
 * Extracts coordinates and plant data for visualization on a map
 */
const gridToMapTransformer: DataTransformer = {
  transform: async (data: any, _sourceToolId: string, _targetToolId: string) => {
    console.log('[gridToMapTransformer] Input data:', data);
    
    // Extract rowData from various possible formats using a simplified approach
    let rowData = null;
    
    // Prioritize direct rowData if available
    if (data?.rowData && Array.isArray(data.rowData)) {
      console.log('[gridToMapTransformer] Using data.rowData with', data.rowData.length, 'items');
      rowData = data.rowData;
    } 
    // Then check output.rowData
    else if (data?.output?.rowData && Array.isArray(data.output.rowData)) {
      console.log('[gridToMapTransformer] Using data.output.rowData with', data.output.rowData.length, 'items');
      rowData = data.output.rowData;
    }
    // Then try json directly 
    else if (data?.json && Array.isArray(data.json)) {
      console.log('[gridToMapTransformer] Using data.json with', data.json.length, 'items');
      rowData = data.json;
    }
    // Finally check output.json
    else if (data?.output?.json && Array.isArray(data.output.json)) {
      console.log('[gridToMapTransformer] Using data.output.json with', data.output.json.length, 'items');
      rowData = data.output.json;
    }
    // If we have nested output object, try recursive processing
    else if (data?.output && typeof data.output === 'object' && !rowData) {
      console.log('[gridToMapTransformer] Recursively processing output');
      return gridToMapTransformer.transform(data.output, _sourceToolId, _targetToolId);
    }
    
    // If we still don't have rowData, return empty GeoJSON structure
    if (!rowData || !Array.isArray(rowData) || rowData.length === 0) {
      console.warn('[gridToMapTransformer] No valid rowData found in input');
      // Make sure we explicitly add the metadata correctly
      return {
        type: 'FeatureCollection',
        features: [] as any[],
        metadata: {
          species: [],
          type: 'gridData',
          raw: data,
          complete: true,
          isDummyData: false,
          error: undefined
        }
      };
    }
    
    console.log('[gridToMapTransformer] Processing rowData with', rowData.length, 'rows');
    console.log('[gridToMapTransformer] Sample row:', rowData[0]);
    
    // Extract botanical names from the data
    const speciesEntries = rowData
      .filter(row => row.botanicalname || row.commonname)
      .map(row => {
        // Extract botanical name, preferring certain fields in order
        const name = 
          (typeof row.botanicalname === 'object' ? 
            (row.botanicalname?.label || row.botanicalname?.value) : 
            row.botanicalname) || 
          (typeof row.commonname === 'object' ?
            (row.commonname?.label || row.commonname?.value) :
            row.commonname) || 
          '';
          
        return name
      })
      .filter(name => name !== '');
    
    // Get unique species names
    const species = [...new Set(speciesEntries)];
    
    // Format species for output metadata
    const formattedSpecies = species.map(name => {
      console.log('Processing species name:', name, 'type:', typeof name);
      return {
        original: name,
        normalized: name?.toLowerCase?.()?.replace(/\s+/g, '_') || name || ''
      };
    });
    
    console.log('[gridToMapTransformer] Extracted species:', species);
    
    // Create base GeoJSON response
    const geoJsonResponse = {
      type: 'FeatureCollection',
      features: [] as any[],
      metadata: {
        species: formattedSpecies,
        type: 'gridData',
        raw: data,
        complete: false,
        isDummyData: false,
        error: undefined
      }
    };
    
    // If no species found, return empty GeoJSON
    if (species.length === 0) {
      console.warn('[gridToMapTransformer] No species found in rowData');
      return geoJsonResponse;
    }
    
    // For testing, generate dummy markers as fallback if API calls fail
    const generateDummyData = () => {
      console.log('[gridToMapTransformer] Generating fallback dummy data');
      
      // Create a dummy feature for each species
      return species.map((speciesName, index) => {
        // Generate points with slight offsets (for visualization)
        const baseLat = -37.8136;
        const baseLng = 144.9631;
        const offsetLat = (index * 0.01) % 0.2;
        const offsetLng = (index * 0.01) % 0.2;
        
        return {
          type: 'Feature',
          geometry: {
            type: 'Point', 
            coordinates: [baseLng + offsetLng, baseLat + offsetLat]
          },
          properties: {
            title: speciesName,
            species: speciesName,
            source: 'Generated',
            image_url: null,
            uri: null
          }
        };
      });
    };
    
    try {
      // Attempt to fetch occurrence data
      const projectStore = useProjectStore();
      const projectLocation = projectStore.selectedProject?.project_location;
      const coordinates = projectLocation?.coordinates;
      
      console.log('[gridToMapTransformer] Fetching occurrences for species:', species);
      
      // Create cache keys for each species
      const cacheKeys = species.map(s => coordinates && coordinates.length >= 2 
        ? `bio-search-${s}-${coordinates[0]}-${coordinates[1]}-1-0km`
        : `bio-search-${s}`);
      
      // Try to get cached data first
      const nuxtApp = useNuxtApp();
      const cachedDataArray = cacheKeys.map(key => nuxtApp.payload.data[key]);
      
      // Create fetch promises for species that aren't cached
      const fetchPromises = species.map((s, index) => {
        if (!cachedDataArray[index]) {
          console.log('[gridToMapTransformer] Fetching occurrences for species:', s);
          
          // Determine if we should use vernacular name
          const useVernacularName = !rowData.some(row => {
            const botanicalName = typeof row.botanicalname === 'object' ? 
              (row.botanicalname?.label || row.botanicalname?.value) : 
              row.botanicalname;
            return typeof botanicalName === 'string' && 
              botanicalName.toLowerCase().includes(s.toLowerCase());
          });
          
          return $fetch('/api/bio/search', {
            method: 'POST',
            body: {
              searchType: 'occurrences',
              species: s,
              vernacularName: useVernacularName
            }
          })
          .then(response => {
            // Add debug logging to see what's returned
            console.log(`[gridToMapTransformer] Received response for ${s}:`, response);
            
            if (!response.success) {
              console.warn(`Failed to fetch occurrences for ${s}:`, response.error);
              return {
                species: s,
                occurrences: [],
                error: response.error,
                searchParams: response.searchParams
              };
            }
            
            // Handle case where response.data might be missing or empty
            if (!response.data || !response.data.occurrences) {
              console.warn(`Response for ${s} is missing data or occurrences array:`, response);
              return {
                species: s,
                occurrences: [],
                error: 'No occurrence data found in response',
                searchParams: {
                  species: s
                }
              };
            }
            
            // Log successful data processing
            console.log(`[gridToMapTransformer] Successfully processed ${response.data.occurrences.length} occurrences for ${s}`);
            
            return {
              species: s,
              ...response.data
            };
          })
          .catch(error => {
            console.error(`Error fetching occurrences for ${s}:`, error);
            return {
              species: s,
              occurrences: [],
              error: String(error)
            };
          });
        }
        return Promise.resolve(cachedDataArray[index] || { species: s, occurrences: [] });
      });
      
      // Use batch processing for the fetch promises
      const batchSize = gridToMapTransformer.options?.concurrentRequests || 3;
      
      // Helper function to process batches of promises
      const processBatch = async (items: Promise<any>[], size: number) => {
        const results = [];
        const inProgress = new Set<Promise<any>>();
        let nextPromiseIndex = 0;
        let resolvedCount = 0;

        // Create a promise that resolves when all promises are complete
        return new Promise((resolveAll) => {
          // Helper to start a new promise
          const startNextPromise = () => {
            if (nextPromiseIndex >= items.length) return null;
            
            const promise = items[nextPromiseIndex++];
            if (!promise) return null;

            inProgress.add(promise);
            
            // Process this promise
            promise
              .then(result => {
                if (result) {
                  console.log(`[processBatch] Got result for promise #${resolvedCount + 1} with ${result.occurrences?.length || 0} occurrences`);
                  results.push(result);
                } else {
                  console.warn(`[processBatch] Promise #${resolvedCount + 1} resolved with null or undefined result`);
                  // Add an empty result to maintain index alignment
                  results.push({ occurrences: [] });
                }
                
                inProgress.delete(promise);
                resolvedCount++;

                // Emit partial results after each promise completes
                const partialResults = [...results]; // Create a copy to avoid mutation issues
                
                // Start next promise if available
                if (inProgress.size < size && nextPromiseIndex < items.length) {
                  startNextPromise();
                }

                // If all promises are complete, resolve the main promise
                if (resolvedCount === items.length) {
                  console.log(`[processBatch] All ${items.length} promises completed with ${results.filter(r => r?.occurrences?.length > 0).length} containing occurrences`);
                  resolveAll(results);
                }

                // Return partial results for immediate use
                return partialResults;
              })
              .catch(error => {
                console.error(`[processBatch] Error processing promise #${resolvedCount + 1}:`, error);
                
                // Add an empty result for this failed promise to maintain index alignment
                results.push({ 
                  occurrences: [],
                  error: error?.message || 'Unknown error processing request'
                });
                
                inProgress.delete(promise);
                resolvedCount++;

                // Start next promise if available
                if (inProgress.size < size && nextPromiseIndex < items.length) {
                  startNextPromise();
                }

                // If all promises are complete, resolve the main promise
                if (resolvedCount === items.length) {
                  console.log(`[processBatch] All ${items.length} promises completed with ${results.filter(r => r?.occurrences?.length > 0).length} containing occurrences`);
                  resolveAll(results);
                }
              });

            return promise;
          };

          // Start initial batch of promises
          for (let i = 0; i < Math.min(size, items.length); i++) {
            startNextPromise();
          }

          // If no promises to process, resolve immediately
          if (items.length === 0) {
            resolveAll(results);
          }
        });
      };
      
      const responses = await processBatch(fetchPromises, batchSize);
      
      // Helper function to process responses into features
      const processResponses = (currentResponses: any[]) => {
        // Add debug logging
        console.log('[gridToMapTransformer] Processing responses:', currentResponses);
        
        const allData = species.map((s, index) => {
          if (cachedDataArray[index]) {
            console.log(`[gridToMapTransformer] Using cached data for ${s}`);
            return cachedDataArray[index];
          }
          
          // Find the response that matches this species
          const responseMatch = currentResponses.find(resp => resp?.species === s);
          
          if (responseMatch) {
            console.log(`[gridToMapTransformer] Got fresh data for ${s}: ${responseMatch.occurrences?.length || 0} occurrences`);
            // Cache the fresh response
            nuxtApp.payload.data[cacheKeys[index]] = responseMatch;
            return responseMatch;
          } else {
            console.warn(`[gridToMapTransformer] No response found for ${s}`);
            return { species: s, occurrences: [] };
          }
        });

        // Combine all occurrences, making sure to handle potential missing or invalid data
        const allOccurrences = allData
          .filter(data => data && Array.isArray(data.occurrences)) 
          .flatMap(data => data.occurrences || []);
        
        console.log(`[gridToMapTransformer] Combined ${allOccurrences.length} total occurrences`);

        // Only create markers for valid occurrences with lat/lng
        return allOccurrences
          .filter(occ => occ && typeof occ.lat === 'number' && typeof occ.lng === 'number')
          .map(occ => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [occ.lng, occ.lat]
            },
            properties: {
              title: occ.species || 'Unknown species',
              species: occ.species || 'Unknown species',
              source: occ.source || 'Unknown',
              date: occ.date,
              observer: occ.observer,
              license_code: occ.license_code,
              image_url: occ.image_url,
              uri: occ.uri
            }
          }));
      };
      
      // Process the responses and get features
      const features = processResponses(responses);
      
      // If we have features, use them; otherwise fall back to dummy data
      if (features && features.length > 0) {
        geoJsonResponse.features = features;
      } else {
        console.log('[gridToMapTransformer] No real occurrences found, using dummy data');
        geoJsonResponse.features = generateDummyData();
        geoJsonResponse.metadata.isDummyData = true;
      }
      
      geoJsonResponse.metadata.complete = true;
      return geoJsonResponse;
      
    } catch (error) {
      console.error('[gridToMapTransformer] Error processing data:', error);
      
      // Add dummy data as fallback
      const dummyFeatures = generateDummyData();
      geoJsonResponse.features = dummyFeatures;
      geoJsonResponse.metadata.complete = true;
      geoJsonResponse.metadata.isDummyData = true;
      geoJsonResponse.metadata.error = String(error) as any; // Type fix for the linter error
      
      return geoJsonResponse;
    }
  },
  options: {
    concurrentRequests: 3
  },
  description: 'Transform plant grid data into map markers with species occurrences'
};

/**
 * Transform map data for grid display
 * Converts map markers to grid rows
 */
const mapToGridTransformer: DataTransformer = {
  transform: (data: any, _sourceToolId: string, _targetToolId: string) => {
    // Handle occurrence data from map
    if (data?.occurrences && Array.isArray(data.occurrences)) {
      const rowData = data.occurrences.map((occurrence: any) => {
        return {
          species: occurrence.species || 'Unknown species',
          latitude: occurrence.lat,
          longitude: occurrence.lng,
          source: occurrence.source || 'Map data',
          date: occurrence.date || new Date().toISOString().split('T')[0],
          // Include any other relevant data
          ...(occurrence.observer ? { observer: occurrence.observer } : {}),
          ...(occurrence.license_code ? { license: occurrence.license_code } : {})
        }
      })
      
      // Create column definitions if they don't exist
      const columnDefs = [
        { field: 'species', headerName: 'Species' },
        { field: 'latitude', headerName: 'Latitude' },
        { field: 'longitude', headerName: 'Longitude' },
        { field: 'source', headerName: 'Source' },
        { field: 'date', headerName: 'Date' },
        { field: 'observer', headerName: 'Observer' },
        { field: 'license', headerName: 'License' }
      ]
      
      return {
        type: 'occurrenceData',
        rowData,
        columnDefs
      }
    }
    
    // Handle species data
    if (data?.species && Array.isArray(data.species)) {
      const rowData = data.species.map((species: any) => {
        return {
          species: species.original || species.name || 'Unknown species',
          color: species.color || '#000000',
          count: species.count || 0,
          visible: species.visible || true,
          data: species.data || null
        }
      })
      
      const columnDefs = [
        { field: 'species', headerName: 'Species' },
        { field: 'count', headerName: 'Count' },
        { field: 'visible', headerName: 'Visible' }
      ]
      
      return {
        type: 'speciesData',
        rowData,
        columnDefs
      }
    }
    
    // Return the original data if we can't transform it
    return data
  },
  priority: 10,
  description: 'Converts map markers and species data to grid rows'
}

/**
 * Transform gallery data for map display
 * Extracts image locations and metadata if available
 */
const galleryToMapTransformer: DataTransformer = {
  transform: (data: any, _sourceToolId: string, _targetToolId: string) => {
    const images = data?.images || []
    let coordinates: { lat: number, lng: number, title: string, imageUrl: string }[] = []
    
    // Extract images that have location data
    if (Array.isArray(images)) {
      coordinates = images
        .filter(img => img.metadata?.latitude && img.metadata?.longitude)
        .map(img => ({
          lat: parseFloat(img.metadata.latitude),
          lng: parseFloat(img.metadata.longitude),
          title: img.title || img.name || 'Image',
          imageUrl: img.url || img.src || '',
          species: img.metadata?.species || img.species || ''
        }))
    }
    
    return {
      coordinates,
      type: 'galleryData',
      hasLocationData: coordinates.length > 0,
      raw: data
    }
  },
  priority: 10,
  description: 'Extracts location data from gallery images for display on a map'
}

/**
 * Transform PDF data for grid display
 * Extracts tabular data from PDF content if available
 */
const pdfToGridTransformer: DataTransformer = {
  transform: (data: any, _sourceToolId: string, _targetToolId: string) => {
    // If data already contains extracted tables, use them
    if (data?.tables && Array.isArray(data.tables)) {
      // Convert tables to grid format
      const firstTable = data.tables[0] // Use first table as default
      
      if (firstTable?.rows && Array.isArray(firstTable.rows)) {
        // Use the first row as headers if available
        const headers = firstTable.rows[0] || []
        
        // Create column definitions from headers
        const columnDefs = headers.map((header: string, index: number) => ({
          field: `field${index}`,
          headerName: header
        }))
        
        // Use remaining rows as data
        const rowData = firstTable.rows.slice(1).map((row: string[]) => {
          const rowObj: Record<string, any> = {}
          row.forEach((cell, i) => {
            rowObj[`field${i}`] = cell
          })
          return rowObj
        })
        
        return {
          type: 'extractedTable',
          source: 'PDF',
          columnDefs,
          rowData
        }
      }
    }
    
    // If no structured table data, return the original data
    return data
  },
  priority: 10,
  description: 'Extracts tabular data from PDFs for display in a grid'
}

/**
 * Transform any data for diagnostic display
 * Formats data in a way that's easy to understand in the DiagnosticPanel
 */
const anyToDiagnosticTransformer: DataTransformer = {
  transform: (data: any, sourceToolId: string, targetToolId: string) => {
    // Create a formatted version of the data with metadata
    const formattedData = {
      source: {
        toolId: sourceToolId,
        timestamp: Date.now()
      },
      target: {
        toolId: targetToolId
      },
      originalData: data,
      summary: summarizeData(data)
    };
    
    return formattedData;
  },
  priority: 100, // High priority to ensure it's used
  description: 'Formats any data for display in the DiagnosticPanel'
};

/**
 * Helper function to create a summary of data based on its type
 */
const summarizeData = (data: any): any => {
  if (!data) return { type: 'empty' as const, value: null };
  
  // Handle arrays
  if (Array.isArray(data)) {
    return {
      type: 'array' as const,
      length: data.length,
      sample: data.slice(0, 3), // First 3 items as sample
      hasMore: data.length > 3
    };
  }
  
  // Handle objects
  if (typeof data === 'object' && data !== null) {
    // Special handling for common data structures
    if (data.rowData && Array.isArray(data.rowData)) {
      return {
        type: 'grid' as const,
        rows: data.rowData.length,
        columns: data.columnDefs?.length || 'unknown',
        sample: data.rowData.slice(0, 2) // First 2 rows as sample
      };
    }
    
    if (data.occurrences && Array.isArray(data.occurrences)) {
      return {
        type: 'map' as const,
        points: data.occurrences.length,
        sample: data.occurrences.slice(0, 2) // First 2 points as sample
      };
    }
    
    // Generic object summary
    const keys = Object.keys(data);
    return {
      type: 'object' as const,
      keys: keys,
      keyCount: keys.length,
      topLevelSummary: keys.reduce((acc, key) => {
        const value = data[key];
        let type: 'array' | 'null' | 'object' | string = typeof value;
        
        if (Array.isArray(value)) {
          type = 'array';
          acc[key] = `${type}[${value.length}]`;
        } else if (value === null) {
          acc[key] = 'null';
        } else if (type === 'object') {
          acc[key] = `object{${Object.keys(value).length} keys}`;
        } else {
          acc[key] = `${type}(${String(value).substring(0, 20)}${String(value).length > 20 ? '...' : ''})`;
        }
        
        return acc;
      }, {} as Record<string, string>)
    };
  }
  
  // Handle primitives
  return {
    type: typeof data as string,
    value: data
  };
};

// Helper function to process requests as they complete
const processBatch = async (promises: Promise<any>[], batchSize: number) => {
  const results: any[] = [];
  const inProgress = new Set<Promise<any>>();
  let nextPromiseIndex = 0;
  let resolvedCount = 0;

  // Create a promise that resolves when all promises are complete
  return new Promise((resolveAll) => {
    // Helper to start a new promise
    const startNextPromise = () => {
      if (nextPromiseIndex >= promises.length) return null;
      
      const promise = promises[nextPromiseIndex++];
      if (!promise) return null;

      inProgress.add(promise);
      
      // Process this promise
      promise
        .then(result => {
          if (result) {
            console.log(`[processBatch] Got result for promise #${resolvedCount + 1} with ${result.occurrences?.length || 0} occurrences`);
            results.push(result);
          } else {
            console.warn(`[processBatch] Promise #${resolvedCount + 1} resolved with null or undefined result`);
            // Add an empty result to maintain index alignment
            results.push({ occurrences: [] });
          }
          
          inProgress.delete(promise);
          resolvedCount++;

          // Emit partial results after each promise completes
          const partialResults = [...results]; // Create a copy to avoid mutation issues
          
          // Start next promise if available
          if (inProgress.size < batchSize && nextPromiseIndex < promises.length) {
            startNextPromise();
          }

          // If all promises are complete, resolve the main promise
          if (resolvedCount === promises.length) {
            console.log(`[processBatch] All ${promises.length} promises completed with ${results.filter(r => r?.occurrences?.length > 0).length} containing occurrences`);
            resolveAll(results);
          }

          // Return partial results for immediate use
          return partialResults;
        })
        .catch(error => {
          console.error(`[processBatch] Error processing promise #${resolvedCount + 1}:`, error);
          
          // Add an empty result for this failed promise to maintain index alignment
          results.push({ 
            occurrences: [],
            error: error?.message || 'Unknown error processing request'
          });
          
          inProgress.delete(promise);
          resolvedCount++;

          // Start next promise if available
          if (inProgress.size < batchSize && nextPromiseIndex < promises.length) {
            startNextPromise();
          }

          // If all promises are complete, resolve the main promise
          if (resolvedCount === promises.length) {
            console.log(`[processBatch] All ${promises.length} promises completed with ${results.filter(r => r?.occurrences?.length > 0).length} containing occurrences`);
            resolveAll(results);
          }
        });

      return promise;
    };

    // Start initial batch of promises
    for (let i = 0; i < Math.min(batchSize, promises.length); i++) {
      startNextPromise();
    }

    // If no promises to process, resolve immediately
    if (promises.length === 0) {
      resolveAll(results);
    }
  });
};

/**
 * Composable for managing data transformations between panels
 */
export const usePanelDataTransformers = () => {
  const { tools } = useTools()
  
  // Initialize the transformer registry
  const transformerRegistry = reactive<TransformerRegistry>(new Map())
  
  /**
   * Register a transformer for a specific source-target tool pair
   */
  const registerTransformer = (
    sourceToolId: string, 
    targetToolId: string, 
    transformer: DataTransformer
  ) => {
    const key = createTransformerKey(sourceToolId, targetToolId)
    transformerRegistry.set(key, transformer)
  }
  
  /**
   * Get a transformer for a specific source-target tool pair
   */
  const getTransformer = (sourceToolId: string, targetToolId: string): DataTransformer | undefined => {
    // First try to get an exact match
    const key = createTransformerKey(sourceToolId, targetToolId)
    const exactMatch = transformerRegistry.get(key)
    if (exactMatch) return exactMatch
    
    // If no exact match, try wildcard source
    const wildcardSourceKey = createTransformerKey('*', targetToolId)
    const wildcardSourceMatch = transformerRegistry.get(wildcardSourceKey)
    if (wildcardSourceMatch) return wildcardSourceMatch
    
    // If still no match, try wildcard target
    const wildcardTargetKey = createTransformerKey(sourceToolId, '*')
    const wildcardTargetMatch = transformerRegistry.get(wildcardTargetKey)
    if (wildcardTargetMatch) return wildcardTargetMatch
    
    // No match found
    return undefined
  }
  
  /**
   * Get all registered transformers
   */
  const getAllTransformers = () => {
    return Array.from(transformerRegistry.entries()).map(([key, transformer]) => {
      const { sourceToolId, targetToolId } = parseTransformerKey(key)
      return {
        key,
        sourceToolId,
        targetToolId,
        transformer
      }
    })
  }
  
  /**
   * Transform data from one tool to another
   */
  const transformData = async (data: any, sourceToolId: string, targetToolId: string): Promise<any> => {
    const transformer = getTransformer(sourceToolId, targetToolId)
    
    if (transformer) {
      try {
        const result = await transformer.transform(data, sourceToolId, targetToolId)
        return result
      } catch (error) {
        console.error(`Error processing data from ${sourceToolId} to ${targetToolId}:`, error)
        // push.error({
        //   title: 'Transform error',
        //   message: `Failed to process data from ${sourceToolId} to ${targetToolId}`
        // })
        return data
      }
    }
    
    // If no specific transformer, try to find a generic one based on tool type
    const sourceToolConfig = tools.find(t => t.id === sourceToolId)
    const targetToolConfig = tools.find(t => t.id === targetToolId)
    
    // If no transformer is found, return the original data
    return data
  }
  
  /**
   * Register default transformers
   */
  const registerDefaultTransformers = () => {
    // PlantGrid → Map
    registerTransformer('plantgrid', 'map', gridToMapTransformer)
    
    // CreatePlantGrid → Map (uses same transformer as PlantGrid)
    registerTransformer('createplantgrid', 'map', gridToMapTransformer)
    
    // Map → PlantGrid
    registerTransformer('map', 'plantgrid', mapToGridTransformer)
    
    // Gallery → Map
    registerTransformer('gallery', 'map', galleryToMapTransformer)
    
    // PDF → PlantGrid
    registerTransformer('pdfviewer', 'plantgrid', pdfToGridTransformer)
    
    // PlantGrid → Gallery
    registerTransformer('plantgrid', 'gallery', gridToGalleryTransformer)
    
    // Map → Gallery
    registerTransformer('map', 'gallery', mapToGalleryTransformer)
    
    // Register the diagnostic transformer for all tools to diagnostic panel
    registerTransformer('*', 'diagnostic', anyToDiagnosticTransformer)
  }
  
  // Register default transformers on initialization
  registerDefaultTransformers()
  
  return {
    registerTransformer,
    getTransformer,
    getAllTransformers,
    transformData
  }
}

// Helper function to create a custom transformer
export const createTransformer = (
  transformFn: (data: any, sourceToolId: string, targetToolId: string) => any,
  options?: { priority?: number; description?: string; options?: Record<string, any> }
): DataTransformer => {
  return {
    transform: transformFn,
    priority: options?.priority || 1,
    description: options?.description || '',
    options: options?.options || {}
  }
}

/**
 * Interface for grid cell transformations
 */
export interface GridCellTransformer {
  /**
   * Source column name that triggers the transformation
   */
  sourceColumn: string;
  
  /**
   * Target column(s) that will be updated
   */
  targetColumns: string[];
  
  /**
   * Transform function that takes the source value and returns values for target columns
   * @param value The source cell value
   * @param row The entire row data (optional)
   * @returns An object with keys matching targetColumns and their respective values
   */
  transform: (value: any, row?: any) => Record<string, any> | Promise<Record<string, any>>;
  
  /**
   * Whether to emit events when the transformation occurs
   */
  emitEvents?: boolean;
  
  /**
   * Custom event name to emit (defaults to 'plantgrid:row:set:{targetColumn}')
   */
  eventName?: string;
  
  /**
   * Description of what this transformer does
   */
  description?: string;
}

/**
 * Composable for managing cell-to-cell transformations within a grid
 */
export const useGridDataTransformers = () => {
  const transformers = reactive<GridCellTransformer[]>([]);
  
  /**
   * Register a new cell transformer
   */
  const registerTransformer = (transformer: GridCellTransformer) => {
    console.log('[DEBUG useTransformers] Registering transformer:', {
      sourceColumn: transformer.sourceColumn,
      targetColumns: transformer.targetColumns,
      description: transformer.description
    });
    transformers.push(transformer);
    console.log(`[DEBUG useTransformers] Total registered transformers: ${transformers.length}`);
  };
  
  /**
   * Get transformers applicable to a specific column
   */
  const getTransformersForColumn = (columnName: string): GridCellTransformer[] => {
    return transformers.filter(t => t.sourceColumn === columnName);
  };
  
  /**
   * Apply transformations for a column update and return updates
   */
  const applyTransformations = async (columnName: string, value: any, row?: any) => {
    const updates: Record<string, any> = {};
    const applicableTransformers = getTransformersForColumn(columnName);
    
    for (const transformer of applicableTransformers) {
      try {
        // Await the transform function result in case it's a Promise
        const transformedValues = await transformer.transform(value, row);
        Object.assign(updates, transformedValues);
      } catch (error) {
        console.error(`[DEBUG useTransformers] Error in transformer for ${columnName}:`, error);
      }
    }
    
    return updates;
  };
  
  /**
   * Process a cell update, return updates and emit events if needed
   */
  const processCellUpdate = async (columnName: string, value: any, row?: any) => {
    console.log('[DEBUG useTransformers] processCellUpdate called:', {
      columnName,
      value,
      rowData: row
    });
    
    // Ensure we have a valid value before proceeding
    let processedValue = value;
    if (typeof value === 'string' && value === '[object Object]') {
      console.warn('[DEBUG useTransformers] Received invalid string [object Object], returning empty updates');
      return {
        updates: {},
        events: []
      };
    }
    
    const updates: Record<string, any> = {};
    const events: { name: string, data: any }[] = [];
    
    const applicableTransformers = getTransformersForColumn(columnName);
    console.log(`[DEBUG useTransformers] Found ${applicableTransformers.length} transformers for column ${columnName}`);
    
    for (const transformer of applicableTransformers) {
      try {
        // Apply the transformation - await in case it's async
        console.log(`[DEBUG useTransformers] Applying transformer for ${columnName}:`, transformer.description);
        const transformedValues = await transformer.transform(processedValue, row);
        console.log(`[DEBUG useTransformers] Transformer result:`, transformedValues);
        
        // Collect all updates
        Object.assign(updates, transformedValues);
        
        // Prepare events if needed
        if (transformer.emitEvents) {
          if (transformer.eventName) {
            // Use custom event name
            console.log(`[DEBUG useTransformers] Using custom event name: ${transformer.eventName}`);
            events.push({
              name: transformer.eventName,
              data: { 
                ...transformedValues,
                sourceColumn: columnName,
                sourceValue: value
              }
            });
          } else {
            // Generate standard events for each target column
            transformer.targetColumns.forEach(targetCol => {
              if (transformedValues[targetCol] !== undefined) {
                const eventName = `plantgrid:row:set:${targetCol.toLowerCase()}`;
                console.log(`[DEBUG useTransformers] Creating event: ${eventName} with data:`, {
                  ...transformedValues,
                  sourceColumn: columnName,
                  sourceValue: value
                });
                
                events.push({
                  name: eventName,
                  data: { 
                    [targetCol]: transformedValues[targetCol],
                    sourceColumn: columnName,
                    sourceValue: value 
                  }
                });
              }
            });
          }
        }
      } catch (error) {
        console.error(`[DEBUG useTransformers] Error applying transformer for ${columnName}:`, error);
      }
    }
    
    // Emit all collected events
    console.log(`[DEBUG useTransformers] Emitting ${events.length} events`);
    for (const event of events) {
      try {
        // Cast to any type to bypass type checking - we know the event exists
        console.log(`[DEBUG useTransformers] Emitting event: ${event.name}`, event.data);
        const mitter = useMitter();
        mitter.emit(event.name as any, event.data);
      } catch (error) {
        console.error(`[DEBUG useTransformers] Error emitting event ${event.name}:`, error);
      }
    }
    
    return {
      updates,
      events
    };
  };
  
  /**
   * Create an update queue item from cell update
   */
  const createUpdateQueueItem = async (columnName: string, value: any, row?: any) => {
    console.log('[DEBUG useTransformers] createUpdateQueueItem called:', {
      columnName,
      value,
      rowData: row
    });
    
    // Check for invalid string representation of objects
    if (typeof value === 'string' && value === '[object Object]') {
      console.warn('[DEBUG useTransformers] Received invalid string [object Object], skipping update queue item creation');
      return null;
    }
    
    const updates = await applyTransformations(columnName, value, row);
    console.log('[DEBUG useTransformers] Transformations result:', updates);
    
    if (Object.keys(updates).length === 0) {
      console.log('[DEBUG useTransformers] No updates to apply, returning null');
      return null;
    }
    
    const result = {
      target: {
        columnName,
        cellValue: value
      },
      set: updates
    };
    
    console.log('[DEBUG useTransformers] Created update queue item:', result);
    return result;
  };
  
  // Register common transformers
  
  // Botanical name to common name transformer
  console.log('[DEBUG useTransformers] Registering default transformers...');
  registerTransformer({
    sourceColumn: 'botanicalname',
    targetColumns: ['commonname'],
    transform: async (value) => {
      // Extract the actual value if it's an object
      let botanicalName = '';
      if (typeof value === 'object' && value !== null) {
        botanicalName = value.value || value.label || '';
      } else if (typeof value === 'string') {
        botanicalName = value;
      }
      
      // Check for empty or invalid values
      if (!botanicalName || botanicalName === '[object Object]') {
        console.log('[DEBUG useTransformers] Empty or invalid botanical name, skipping common name transformation');
        return {};
      }
      
      // Check if the botanical name has only one word (genus or family)
      // If so, skip the transformation
      if (botanicalName) {
        const parts = botanicalName.trim().split(/\s+/);
        if (parts.length === 1) {
          console.log('[DEBUG useTransformers] Skipping common name transformation for genus/family:', botanicalName);
          return {};
        }
      }
      
      try {
        // Simple pass-through for now - in real app would fetch common name from API url encoded
        const commonName = await $fetch(`https://api.gbif.org/v1/species/search?q=${encodeURIComponent(botanicalName)}&rank=species&status=ACCEPTED&limit=20&datasetKey=d7dddbf4-2cf0-4f39-9b2a-bb099caae36c`)
        console.log('[DEBUG useTransformers] do we have a common name?:', commonName);
        
        // Apply proper type assertion to handle API response
        const typedResponse = commonName as { results?: Array<{ vernacularNames?: Array<{ vernacularName: string }> }> };
        const result = typedResponse.results?.find((result) => result?.vernacularNames?.[0]?.vernacularName);
        
        console.log('[DEBUG useTransformers] result:', result);
        return { commonname: result?.vernacularNames?.[0]?.vernacularName || '' };
      } catch (error) {
        console.error('[DEBUG useTransformers] Error fetching common name:', error);
        return {};
      }
    },
    emitEvents: true,
    description: 'Updates common name when botanical name changes'
  });
  
  // Botanical name to plant traits transformer (GIFT API)
  registerTransformer({
    sourceColumn: 'botanicalname',
    targetColumns: ['matureheight', 'maturewidth'],
    transform: async (value) => {
      console.log('[TRANSFORMER DEBUG] Botanical name transformer triggered with value:', value);
      
      if (!value) {
        console.log('[TRANSFORMER DEBUG] Empty value, skipping transformer');
        return {};
      }
      
      // Get the botanical name in the correct format
      let botanicalName = value;
      if (typeof value === 'object' && value !== null) {
        botanicalName = value.label || '';
        console.log('[TRANSFORMER DEBUG] Extracted botanical name from object:', botanicalName);
      }
      
      if (!botanicalName) {
        console.log('[TRANSFORMER DEBUG] Could not extract valid botanical name, skipping');
        return {};
      }
      
      try {
        console.log('[TRANSFORMER DEBUG] Importing useValidation for', botanicalName);
        // Import the useValidation composable to use the fetchPlantTraits function
        const { useValidation } = await import('./useValidation');
        const { fetchPlantTraits } = useValidation();
        
        // Fetch the plant traits using the new proxy route
        console.log('[TRANSFORMER DEBUG] Calling fetchPlantTraits for', botanicalName);
        const traits = await fetchPlantTraits(botanicalName);
        console.log('[TRANSFORMER DEBUG] Retrieved plant traits:', traits);
        
        if (!traits) {
          console.log('[TRANSFORMER DEBUG] No traits returned');
          return {};
        }
        
        // Return the traits for updating the grid
        const updates: Record<string, any> = {};
        
        if (traits.matureheight !== undefined) {
          updates.matureheight = traits.matureheight;
          console.log('[TRANSFORMER DEBUG] Setting matureheight to:', traits.matureheight);
        }
        
        if (traits.maturewidth !== undefined) {
          updates.maturewidth = traits.maturewidth;
          console.log('[TRANSFORMER DEBUG] Setting maturewidth to:', traits.maturewidth);
        }
        
        console.log('[TRANSFORMER DEBUG] Final updates to apply:', updates);
        return updates;
      } catch (error) {
        console.error('[TRANSFORMER DEBUG] Error in transformer:', error);
        return {};
      }
    },
    emitEvents: true,
    description: 'Updates plant traits (height, width) when botanical name changes'
  });
  
  // Common name to botanical name transformer
  registerTransformer({
    sourceColumn: 'commonname',
    targetColumns: ['botanicalname'],
    transform: (value, selectedItem) => {
      console.log('[DEBUG useTransformers] Common to Botanical transformer input:', { value, selectedItem });
      // For common name, we expect the selectedItem to contain the botanical name
      if (selectedItem?.botanicalName) {
        return { botanicalname: selectedItem.botanicalName };
      } else if (selectedItem?.botanicalname) {
        return { botanicalname: selectedItem.botanicalname };
      }
      return {};
    },
    emitEvents: true,
    description: 'Updates botanical name when common name changes'
  });
  
  return {
    registerTransformer,
    getTransformersForColumn,
    applyTransformations,
    processCellUpdate,
    createUpdateQueueItem,
    transformers
  };
};

// Utility function for creating attribution HTML
export const generateAttribution = (img) => {
  const {
    title,
    author,
    species,
    preferred_common_name,
    observer,
    observed_on,
    license_code,
    image_url,
    location,
    place_guess,
    quality_grade,
    uri,
    additional_photos,
    taxon_id,
  } = img;

  let attribution = '<div class="text-xs w-full whitespace-normal">';

  // Title
  attribution += `<span class='font-bold'>${title || species} (${preferred_common_name})</span><br/>`;
  
  // Author
  if (author) {
    attribution += `Author: ${author}<br/>`;
  } else if (observer) {
    attribution += `observer: ${observer}<br/>`;
  }

  // Observer
  attribution += `Observed on ${dayjs(observed_on).format('MMMM D, YYYY')}<br/>`;

  if (place_guess) {
    attribution += `${place_guess} (estimated)<br/>`;
  }

  // URI
  attribution += `source: <NuxtLink to="${uri}" target="_blank" rel="noopener noreferrer" class="underline">iNaturalist</NuxtLink>`;

  // License with Image
  let licenseUrl, licenseImage;
  if (license_code === 'cc0') {
    licenseUrl = 'https://creativecommons.org/publicdomain/zero/1.0/';
    licenseImage = 'https://licensebuttons.net/l/zero/1.0/88x31.png';
  } else {
    licenseUrl = `https://creativecommons.org/licenses/${license_code?.replace('cc-', '')}/4.0/`;
    licenseImage = `https://licensebuttons.net/l/${license_code?.replace('cc-', '')}/4.0/88x31.png`;
  }

  attribution += `
    <a href="${licenseUrl}" target="_blank" rel="noopener noreferrer">
      <img src="${licenseImage}" alt="Creative Commons License" style="vertical-align:middle;" />
    </a>
  `;

  attribution += '</div>';

  return attribution;
};

/**
 * Transform grid data for gallery display
 * Extracts plant names from grid data and fetches related images
 */
const gridToGalleryTransformer: DataTransformer = {
  transform: async (data: any, _sourceToolId: string, _targetToolId: string) => {
    // Extract plant names from grid data
    const rowData = data?.rowData || data?.output?.rowData || data?.json || data?.output?.json || [];
    
    if (!rowData.length) {
      return { images: [] };
    }
    
    // Extract both botanical names and common names from the grid data
    const plantData = rowData
      .filter(row => row.botanicalname || row.commonname || row.species)
      .map(row => {
        const botanicalName = typeof row.botanicalname === 'object' 
          ? row.botanicalname?.label || row.botanicalname?.value 
          : row.botanicalname || row.species;
          
        const commonName = typeof row.commonname === 'object'
          ? row.commonname?.label || row.commonname?.value
          : row.commonname;
          
        return {
          botanicalName: botanicalName?.trim(),
          commonName: commonName?.trim()
        };
      })
      .filter(names => names.botanicalName || names.commonName);
    
    if (!plantData.length) {
      return { images: [] };
    }
    
    try {
      console.log('Fetching photos for plants:', plantData);
      
      const INATURALIST_RATE_LIMIT = 1000;
      
      const throttledFetch = useThrottleFn(
        (url: string, options: any) => $fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            'User-Agent': 'Superseeded/1.0 (hi@superseeded.ai)'
          }
        }),
        1000
      )
      
      const fetchSpecies = async (searchData: { botanicalName?: string, commonName?: string }) => {
        // Try botanical name first
        if (searchData.botanicalName) {
          const { data } = await useLazyAsyncData(
            `species-${searchData.botanicalName}`,
            () => throttledFetch('https://api.inaturalist.org/v1/observations', {
              params: {
                photos: true,
                photo_license: 'cc-by,cc-by-sa,cc-by-nd,cc0',
                taxon_name: searchData.botanicalName,
                per_page: 200,
                verifiable: true,
                photo_licensed: true,
                order: 'votes', 
                order_by: 'desc',
                hrank: 'species',
                iconic_taxa: 'Plantae',
              },
              headers: {
                'User-Agent': 'Superseeded/1.0 (hi@superseeded.ai)'
              }
            }),
            {
              getCachedData: (key) => {
                return useNuxtApp().payload.data[key] || null;
              }
            }
          )
          
          // If we got results with botanical name, return them
          if (data.value?.results?.length > 0) {
            console.log(`Found results using botanical name: ${searchData.botanicalName}`);
            return { ...data.value, searchTerm: searchData.botanicalName };
          }
        }
        
        // If no results with botanical name and we have a common name, try that
        if (searchData.commonName) {
          console.log(`No results with botanical name, trying common name: ${searchData.commonName}`);
          const { data } = await useLazyAsyncData(
            `species-common-${searchData.commonName}`,
            () => throttledFetch('https://api.inaturalist.org/v1/observations', {
              params: {
                photos: true,
                photo_license: 'cc-by,cc-by-sa,cc-by-nd,cc0',
                q: searchData.commonName,
                per_page: 200,
                verifiable: true,
                photo_licensed: true,
                order: 'votes',
                order_by: 'desc',
                iconic_taxa: 'Plantae',
              },
              headers: {
                'User-Agent': 'Superseeded/1.0 (hi@superseeded.ai)'
              }
            }),
            {
              getCachedData: (key) => {
                return useNuxtApp().payload.data[key] || null;
              }
            }
          )
          return { ...data.value, searchTerm: searchData.commonName };
        }
        
        return { results: [], searchTerm: searchData.botanicalName || searchData.commonName };
      }

      // Add Serper image search with common name fallback
      const fetchSerperImages = async (searchData: { botanicalName?: string, commonName?: string }) => {
        try {
          // Try botanical name first
          if (searchData.botanicalName) {
            const response = await $fetch('/api/serp/serper', {
              method: 'POST',
              body: { q: searchData.botanicalName }
            })

            if (response && Array.isArray(response) && response.some(img => img.imageUrl)) {
              return response.map(img => ({
                image_url: img.imageUrl,
                species: searchData.botanicalName,
                attribution: 'Image via Google Images',
                source: 'serper',
                license_code: 'unknown'
              })).filter(img => img.image_url)
            }
          }

          // If no results with botanical name and we have a common name, try that
          if (searchData.commonName) {
            console.log(`No Serper results with botanical name, trying common name: ${searchData.commonName}`);
            const response = await $fetch('/api/serp/serper', {
              method: 'POST',
              body: { q: searchData.commonName }
            })

            if (response && Array.isArray(response)) {
              return response.map(img => ({
                image_url: img.imageUrl,
                species: searchData.botanicalName || searchData.commonName,
                attribution: 'Image via Google Images (common name search)',
                source: 'serper',
                license_code: 'unknown'
              })).filter(img => img.image_url)
            }
          }

          return []
        } catch (error) {
          console.warn(`Error fetching Serper images for ${searchData.botanicalName || searchData.commonName}:`, error)
          return []
        }
      }
      
      // Process species data in parallel from both sources
      const [iNatResponses, serperResponses] = await Promise.all([
        Promise.all(plantData.map(fetchSpecies)),
        Promise.all(plantData.map(fetchSerperImages))
      ])
      
      // Process iNaturalist results
      const iNatImages = iNatResponses.flatMap(response => 
        response.results
          ?.slice(0, 5)
          ?.filter(o => !o?.license_code?.includes('nc') && o?.taxon?.iconic_taxon_name == 'Plantae')
          ?.map(obs => ({
            image_url: obs.photos[0]?.url_square?.replace('square', 'original') || obs.photos[0]?.url?.replace('square', 'original'),
            attribution: generateAttribution({
              title: obs.photos[0].title || '',
              author: obs.user.name || obs.user.login,
              species: obs.taxon?.name || response.searchTerm || 'Unknown Species',
              preferred_common_name: obs.taxon?.preferred_common_name || 'N/A',
              observer: obs.user.login,
              observed_on: obs.observed_on_string,
              license_code: obs.license_code,
              image_url: `https://ik.imagekit.io/8qxqs9cznn/${obs.photos[0]?.url_square?.replace('square', 'medium') || obs.photos[0]?.url?.replace('square', 'medium')}`,
              location: obs.location || 'Unknown Location',
              place_guess: obs.place_guess || 'N/A',
              quality_grade: obs.quality_grade || 'N/A',
              uri: obs.uri || '#',
              additional_photos: obs.photos.slice(1),
              taxon_id: obs.taxon?.id || 'N/A',
            }),
            species: obs.taxon?.name || response.searchTerm,
            preferred_common_name: obs.taxon?.preferred_common_name,
            observer: obs.user.login,
            observed_on_string: obs.observed_on_string,
            license_code: obs.license_code,
            location: obs.location,
            place_guess: obs.place_guess,
            quality_grade: obs.quality_grade,
            uri: obs.uri,
            additional_photos: obs.photos.slice(1),
            taxon_id: obs.taxon?.id,
            source: 'iNaturalist'
          })) || []
      )

      // Flatten Serper results
      const serperImages = serperResponses.flat()
      
      // Combine and deduplicate images based on image_url
      const allImages = [...iNatImages, ...serperImages]
      const uniqueImages = allImages.filter((img, index, self) => 
        index === self.findIndex(i => i.image_url === img.image_url)
      )
      
      console.log('[gridToGalleryTransformer] Processed images:', uniqueImages.length);
      
      return { 
        images: uniqueImages,
        metadata: {
          plantData,
          sourceType: 'grid',
          count: uniqueImages.length,
          sources: {
            iNaturalist: iNatImages.length,
            serper: serperImages.length
          }
        }
      };
    } catch (error) {
      console.error('[gridToGalleryTransformer] Error:', error);
      return { 
        images: [],
        error: String(error)
      };
    }
  },
  priority: 10,
  description: 'Extracts plant names from grid data and fetches photos from iNaturalist and Google Images for the gallery, falling back to common names if botanical name search yields no results'
};

/**
 * Transform map data for gallery display
 * Extracts species information from map and fetches related images
 */
const mapToGalleryTransformer: DataTransformer = {
  transform: async (data: any, _sourceToolId: string, _targetToolId: string) => {
    // Extract species from map features or metadata
    let species = [];
    
    // Check for GeoJSON features with species properties
    if (data?.features?.length) {
      species = data.features
        .filter(feature => feature.properties?.species)
        .map(feature => feature.properties.species)
        .filter(Boolean);
    }
    
    // Check for metadata.species
    if (data?.metadata?.species?.length) {
      species.push(...data.metadata.species
        .map(s => s.original || s.normalized)
        .filter(Boolean));
    }
    
    // Check for taxon array (specific to certain map data)
    if (data?.taxon?.length) {
      species.push(...data.taxon
        .filter(t => t.name)
        .map(t => t.name));
    }
    
    // Deduplicate species names
    species = [...new Set(species)];
    
    if (!species.length) {
      return { images: [] };
    }
    
    try {
      const fetchTaxonImages = async (speciesName: string) => {
        // Use the bio/images endpoint to fetch images
        return $fetch('/api/bio/images', {
          method: 'POST',
          body: {
            query: speciesName,
            limit: 5
          }
        }).catch(error => {
          console.warn(`Error fetching images for ${speciesName}:`, error);
          return { images: [] };
        });
      };
      
      // Process all species in parallel
      const results = await Promise.all(species.map(fetchTaxonImages));
      
      // Combine and format all images
      const images = results
        .flatMap(result => result.images || [])
        .map(img => ({
          image_url: img.url,
          species: img.species || img.taxon?.name,
          attribution: img.attribution || img.credit,
          license_code: img.license_code,
          observer: img.observer,
          metadata: {
            species: img.species,
            location: img.location,
            date: img.date,
            ...img.metadata
          }
        }));
      
      return { 
        images,
        metadata: {
          species,
          sourceType: 'map',
          count: images.length
        }
      };
    } catch (error) {
      console.error('[mapToGalleryTransformer] Error:', error);
      return { 
        images: [],
        error: String(error)
      };
    }
  },
  priority: 10,
  description: 'Extracts species information from map data and fetches related images for the gallery'
}; 