<template>
  <ClientOnly>
    <div class="radar-chart-wrapper">
      <div v-if="hasValidChartData" class="radar-chart-container mx-2">
        <p v-if="props.title" class="w-full text-center text-md">
          {{ props.title }}
        </p>
        <RadarChart
          ref="chartRef"
          v-if="loaded"
          :data="chartData"
          :options="chartOptions"
          aria-label="Radar chart displaying data"
        />
        <button
          v-if="shouldShowResetButton"
          @click="resetChart"
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                 rounded-full p-2 
                 bg-muted hover:bg-background
                 transition-colors duration-200"
        >
          <Icon name="iconoir:collapse" class="w-7 h-7" />
        </button>
      </div>
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-sm text-muted-foreground">Initializing chart...</span>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { Radar as RadarChart } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
} from 'chart.js'
import DragDataPlugin from 'chartjs-plugin-dragdata'
import { theme } from "#tailwind-config";
import { useDebounceFn } from '@vueuse/core'

// Add custom plugin to draw numbers inside points
const numberInsidePointPlugin = {
  id: 'numberInsidePoint',
  afterDatasetsDraw: (chart) => {
    const { ctx } = chart;
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((point, index) => {
        // Set text styling
        ctx.font = `Roboto, Arial`;
        ctx.fontSize = Math.min(16, Math.max(12, containerWidth.value / 30))
        ctx.fillStyle = colorMode.value === 'dark' ? '#000' : '#000';
        ctx.color = colorMode.value === 'dark' ? '#000' : '#000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Get value and position
        const value = dataset.data[index] > 0.31 ? dataset.data[index].toFixed(1) : '';
        const { x, y } = point.getCenterPoint();
        
        // Draw the text
        ctx.fillText(value, x, y);
      });
    });
  }
};

// Register Chart.js components and plugins
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  DragDataPlugin,
  numberInsidePointPlugin  // Add the new plugin
)

const props = defineProps<{
  data: {
    label: string;
    axis: string;
    value: number;
    order: number;
    explainer?: string;
  }[]
  title: string;
}>()

const emit = defineEmits(['update:data', 'hoverDataPoint'])

const loaded = ref(false)
const chartInitialized = ref(false)

// Add validation computed
const hasValidChartData = computed(() => {
  return props.data && 
         props.data.length > 0 && 
         props.data.every(item => 
           item.label && 
           typeof item.value === 'number' && 
           typeof item.order === 'number'
         )
})

const colorMode = useColorMode()

// Replace the tailwindColors array with grayscale values
const tailwindColors = [
  'rgba(100, 100, 100, 0.7)', // darkest
  'rgba(120, 120, 120, 0.7)',
  'rgba(140, 140, 140, 0.7)',
  'rgba(160, 160, 160, 0.7)',
  'rgba(180, 180, 180, 0.7)',
  'rgba(200, 200, 200, 0.7)',
  'rgba(220, 220, 220, 0.7)'  // lightest
]

const chartRef = ref(null)
const containerWidth = ref(0)

// Add a computed for dynamic point sizes
const pointSizes = computed(() => {
  const baseSize = containerWidth.value / 20 // Changed from 25 to 20 for larger points
  return {
    pointRadius: Math.max(12, Math.min(24, baseSize)), // Increased min and max sizes
    pointHoverRadius: Math.max(10, Math.min(22, baseSize * 0.9)),
    pointHitRadius: Math.max(16, Math.min(40, baseSize * 2)),
    pointBorderWidth: Math.max(1, Math.min(0, baseSize * 0.2)),
  }
})

// Add initialData computed property
const initialData = computed(() => 
  props.data.map(item => ({
    ...item,
    value: 0.3 // Set initial value to 0.3
  }))
)

// Update chartData computed to use monochromatic styling
const chartData = computed(() => ({
  labels: props.data.map(item => item.label),
  datasets: [{
    label: false,
    ticksDisplay: true,
    padding: 0,
    data: loaded.value ? props.data.map(item => item.value) : initialData.value.map(item => item.value),
    backgroundColor: 'rgba(200, 200, 200, 0.1)',
    borderColor: 'transparent',
    pointBackgroundColor: props.data.map((_, index) => tailwindColors[index % tailwindColors.length]),
    borderWidth: 0,
    borderJoinStyle: 'round',
    pointRadius: props.data.map(item => {
      const minSize = 4;
      const maxSize = 24;
      const scale = (maxSize - minSize) / 5;
      return minSize + (item.value * scale);
    }),
    pointHoverRadius: props.data.map(item => {
      const minSize = 4;
      const maxSize = 28;
      const scale = (maxSize - minSize) / 5;
      return minSize + (item.value * scale);
    }),
    pointHitRadius: pointSizes.value.pointHitRadius,
    pointBorderWidth: 0,
    pointBorderColor: 'transparent',
    pointHoverBorderColor: 'transparent',
    pointHoverBorderWidth: 0,
  }]
}))

const pendingChanges = ref(new Map())

const emitDebounced = useDebounceFn((newData) => {
  emit('update:data', newData)
  pendingChanges.value.clear()
}, 1000)

// Update chartOptions to use grayscale
const chartOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 180
  },
  transitions: {
    active: {
      animation: {
        duration: 0
      }
    }
  },
  scales: {
    r: {
      backgroundColor: colorMode.value === 'dark' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.3)',
      grid: {
        color: 'rgba(0,0,0,0.1)',
        lineWidth: 1,
        drawTicks: false,
      },
      angleLines: {
        display: true,
        color: 'rgba(0,0,0,0.15)', // Single color for all lines
        lineWidth: 6,
      },
      min: 0,
      max: 5,
      ticks: {
        display: true,
        showLabelBackdrop: false,
        backdropColor: colorMode.value === 'dark' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.8)',
        stepSize: 2.5,
        font: {
          size: Math.min(16, Math.max(10, containerWidth.value / 45)),
        },
        backdropPadding: 20,
        radius: 16,
        offset: 100,
        z: 0,
        callback: function(value: number, index: number, ticks: any) {
          if (index < 2 && index > 0) return `not important`;
          if (index === ticks.length - 1) return `Critical                                 `;
          return '';
        },
        color: colorMode.value === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,0.4)',
      },
      pointLabels: {
        padding: 20,
        font: {
          size: Math.min(16, Math.max(12, containerWidth.value / 30)),
        },
        color: 'rgba(0,0,0,0.6)', // Single color for all labels
        callback: function(label: string) {
          return label.split(' ').join('\n');
        },
        offset: 0
      }
    },
  },
  plugins: {
    legend:{
      display: false
    },
    tooltip: {
      renderMode: 'html',
      extraCssText: "white-space: pre-wrap; background-color: rgba(255, 255, 255, 0.8) !important; backdrop-filter: blur(5px) !important; border-radius: 4px;",
      confine: true,
      enabled: true,
      titleFont:{
        size: Math.min(16, Math.max(14, containerWidth.value / 35)), // Max 16px, min 14px
        weight: 'normal'
      },
      bodyFont: {
        size: Math.min(14, Math.max(12, containerWidth.value / 40)), // Max 14px, min 12px
      },
      callbacks: {
        title: (context) => {
          return props.data[context[0].dataIndex].label;
        },
        label: (context) => {
          const currentValue = context.raw;
          const dataPoint = props.data[context.dataIndex];
          return [
            `${dataPoint.label} ${currentValue.toFixed(2)}`
          ];
        },
        labelTextColor: function(context) {
          return theme.colors.muted;
        },
      },
      caretSize:0,
      borderWidth: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      titleColor: 'rgba(0, 0, 0, 0.8)',
      bodyColor: 'rgba(0, 0, 0, 0.6)',
      padding: 12,
      borderColor: 'rgba(0, 0, 0, 0.1)',
      
    },
    dragData: {
      showTooltip: true,
      onDragStart: (e, element) => {
        // Optional: Handle drag start event
      },
      onDrag: (e, datasetIndex, index, value) => {
        // Update point size during drag
        if (chartRef.value) {
          const chart = chartRef.value.chart;
          const minSize = 4;
          const maxSize = 24;
          const scale = (maxSize - minSize) / 5;
          const newSize = minSize + (value * scale);
          
          chart.data.datasets[datasetIndex].pointRadius[index] = newSize;
          chart.data.datasets[datasetIndex].pointHoverRadius[index] = newSize + 2;
          chart.update('none'); // Update without animation
        }
      },
      onDragEnd: (e, datasetIndex, index, value) => {
        // Update the local data immediately
        const newData = props.data.map((item, i) => ({
          ...item,
          value: i === index ? value : item.value
        }))
        
        // Emit the update
        emit('update:data', newData)
      },
    },
    onHover: (event, chartElements) => {
      console.log('chartElements', chartElements)
      if (chartElements && chartElements.length > 0) {
        const dataIndex = chartElements[0].index;
        const hoveredDataPoint = props.data[dataIndex];
        console.log('hoveredDataPoint', hoveredDataPoint)
        emit('hoverDataPoint', hoveredDataPoint);
      } else {
        emit('hoverDataPoint', null);
      }
    },
  },
}))

const shouldShowResetButton = computed(() => {
  return props.data.every(item => item.value > 2)
})

// Update the mounting logic to emit initial data
onMounted(() => {
  if (hasValidChartData.value) {
    // Emit initial data with all values at 0.3
    emit('update:data', initialData.value)
    
    loaded.value = true
    nextTick(() => {
      chartInitialized.value = true
      
      // Initialize ResizeObserver
      const container = document.querySelector('.radar-chart-container')
      if (container) {
        const resizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            containerWidth.value = entry.contentRect.width
          }
        })
        resizeObserver.observe(container)
      }
    })
  }
})

// Clean up resize observer on unmount
onUnmounted(() => {
  const container = document.querySelector('.radar-chart-container')
  if (container) {
    const resizeObserver = new ResizeObserver(() => {})
    resizeObserver.unobserve(container)
  }
  // pendingChanges.value.clear()
})

// Watch for data changes to update loaded state
watch(
  () => props.data,
  (newData) => {
    if (newData && newData.length > 0) {
      loaded.value = true
    }
  },
  { immediate: true }
)

// Add reset function
const resetChart = () => {
  const resetData = props.data.map(item => ({
    ...item,
    value: 0.3
  }))
  emit('update:data', resetData)
}
</script>

<style scoped>
.radar-chart-wrapper {
  display: flex;
  justify-content: center;
  alignalign-items: center;
  width: 100%;
  height: 80%;
  min-height: inherit;
  aspect-ratio: 1/1;
}

.radar-chart-container {
  position: relative;
  width: 100%;
  height: 80%;
  min-height: inherit;
}

@media (max-width: 768px) {
  .radar-chart-wrapper {
    min-height: 300px;
  }
}
</style>
