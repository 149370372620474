<script setup lang="ts">
import { useLayoutStore } from '@edanweis/vue-code-layout'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

// Initialize dayjs plugins
dayjs.extend(relativeTime)

const uiStore = useUiStore()
const {windowHeight} = storeToRefs(uiStore)
const config = useRuntimeConfig()
const { $useLicense } = useNuxtApp()
const colorMode = useColorMode()
const layoutStore = useLayoutStore()
const dockStore = useDockStore()

// Fetch license key from server API - keep this in the parent as it's a data fetch
const { data: licenseData, pending: licenseLoading } = useFetch<{ licenseKey: string }>('/api/vpv-license-key')

// Extract license key from fetched data for use with the PDF viewer
const licenseKey = computed(() => licenseData.value?.licenseKey || '')

// Track loading and error states at the panel level
const isLoading = ref(true)
const loadError = ref<any>(null)

// State for sidebar visibility - always show by default when no PDF
const sidebarOpen = ref(true)

// Computed property to find PDF panels
const pdfPanels = computed(() => {
  // Get all panels from the layout
  const allPanels = layoutStore.getAllPanels()

  if (!allPanels?.length) {
    console.log('No panels found in layout')
    return []
  }
  
  // Filter for PDF panels that are not the current panel
  const filteredPanels = allPanels.filter(panel => {
    // Check if it has a PDF file
    const hasPdfFile = panel.data?.fileMetadata?.url && 
      (panel.data.fileMetadata.content_type === 'application/pdf' ||
       panel.data.fileMetadata.url.endsWith('.pdf'))
    
    // Make sure it's not the current panel
    const isNotCurrentPanel = panel.name !== props.panel.name
    
    return (hasPdfFile) && isNotCurrentPanel
  })
  
  return filteredPanels
})

// Function to switch to another PDF
const switchToPdf = (panelData: any) => {
  if (!panelData) return
  
  // Use the PDF URL from the correct property
  const pdfUrl = panelData.fileMetadata?.url || panelData.url
  if (!pdfUrl) {
    console.error('No PDF URL found in panel data:', panelData)
    return
  }
  
  console.log('Switching to PDF:', pdfUrl)
  
  // Set the PDF URL in the current panel's data
  if (!props.panel.data) {
    props.panel.data = {}
  }
  
  props.panel.data.pdfUrl = pdfUrl
  
  // Also update fileMetadata if it exists
  if (panelData.fileMetadata && !props.panel.data.fileMetadata) {
    props.panel.data.fileMetadata = { ...panelData.fileMetadata }
  }
  
  // Reset error state when switching PDFs
  loadError.value = null
  isLoading.value = true

  // Close sidebar after selecting a PDF
  sidebarOpen.value = false
}

const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false }
});

const emit = defineEmits(['error', 'update:shared-data']);

// Handle events from AppPdfViewer
const onLoaded = () => {
  isLoading.value = false
  console.log('PDF loaded successfully')
}

const onError = (error: any) => {
  loadError.value = error
  isLoading.value = false
  emit('error', error)
}

// Add isDark computation based on colorMode - we just need to pass this to AppPdfViewer
const isDark = computed(() => colorMode.value === 'dark')

// Define basic toolbar options, the detailed configuration is in AppPdfViewer
const toolbarOptions = {
  fullscreen: false,
  themeSwitchable: false
}

// Compute PDF source URL
const pdfSrc = computed(() => {
  const url = props.panel.data?.pdfUrl || props.panel.data?.fileMetadata?.url
  console.log('Current PDF source:', url)
  return url
})

// Computed property to determine if we should show the sidebar
const shouldShowSidebar = computed(() => {
  return !pdfSrc.value
})

// Watch pdfSrc changes to update recent PDFs and manage sidebar
watch(pdfSrc, (newUrl, oldUrl) => {
  // Only process valid URLs
  if (newUrl) {
    // Close sidebar if it was open
    sidebarOpen.value = false
    
    // Add to recent PDFs in store
    dockStore.addRecentPdf({
      url: newUrl,
      title: props.panel.data?.fileMetadata?.filename || props.panel.title || 'PDF Document',
      filename: props.panel.data?.fileMetadata?.filename || '',
      fileMetadata: props.panel.data?.fileMetadata
    })
  } else if (!newUrl && oldUrl) {
    // No PDF loaded now, but had one before - show sidebar
    sidebarOpen.value = true
  } else if (!newUrl && !oldUrl) {
    // Starting with no PDF - show sidebar
    sidebarOpen.value = true
  }
}, { immediate: true })

// Get recent PDFs from store
const recentPdfs = computed(() => dockStore.recentPdfs)

// Function to scan and refresh available PDFs
const refreshPanels = () => {
  console.log('Refreshing panels...')
  
  // Force recompute by accessing the layout store again
  const allPanels = layoutStore.getAllPanels()
  
  // Log current panel details
  console.log('Current panel details:', {
    name: props.panel.name,
    id: props.panel.name,
    fileId: props.panel.data?.fileMetadata?.id || props.panel.data?.file_id,
    url: props.panel.data?.fileMetadata?.url || props.panel.data?.pdfUrl,
    toolUid: props.panel.data?.tool_uid
  })
  
  // Filter for PDF panels with more detailed logging
  const filtered = allPanels.filter(p => {
    const isPdfPanel = p.data?.tool_uid === '8b6ee2d8-ef11-462a-a6c8-b31b6bd21424'
    const hasPdfFile = p.data?.fileMetadata?.url && 
      (p.data.fileMetadata.url.endsWith('.pdf') || 
       p.data.fileMetadata.mimetype === 'application/pdf')
    
    const isNotCurrentPanel = p.name !== props.panel.name
    
    return isPdfPanel && hasPdfFile && isNotCurrentPanel
  })
  
  console.log('Filtered PDF panels:', filtered)
}

// Function called when dropdown button is clicked
const selectPdfPanel = () => {
  refreshPanels()
}

// Open or close the sidebar
const toggleSidebar = () => {
  sidebarOpen.value = !sidebarOpen.value
}

// Format time for recent PDFs
const formatTime = (timestamp: number) => {
  if (!timestamp) return ''
  return dayjs(timestamp).fromNow()
}

</script>

<template>
  <keep-alive>
    <div @dragover.prevent class="overflow-hidden w-full h-full p-0 m-0 rounded-lg">
      <div class="flex flex-col w-full h-full p-0 m-0">
        <!-- PDF Viewer -->
        <div class="flex justify-center align-center items-center flex-1 relative">
          <template v-if="loadError">
            <div class="flex flex-col items-center justify-center h-full w-full text-destructive p-4">
              <Icon name="ph:warning-circle" class="w-12 h-12 mb-2" />
              <h3 class="font-medium">Failed to load PDF</h3>
              <p class="text-sm text-center">{{ typeof loadError === 'string' ? loadError : 'The PDF could not be loaded. Please check the URL or try again.' }}</p>
            </div>
          </template>
          <template v-else>
            <ClientOnly>
              <template #default>
                <!-- Show PDF viewer when we have a source -->
                <AppPdfViewer
                  v-if="licenseKey && pdfSrc"
                  :darkMode="isDark"
                  :toolbarOptions="toolbarOptions" 
                  @loaded="onLoaded"
                  @error="onError"
                  :src="pdfSrc"
                  :licenseKey="licenseKey"
                  :showParentLoader="isLoading"
                />
                
                <!-- When no PDF source is available, show the sidebar with recent PDFs -->
                <div v-else-if="licenseKey" class="w-full h-full flex items-center justify-center bg-background">
                  <SidebarProvider :open="sidebarOpen" class="w-full h-full relative">
                    <Sidebar class="w-full md:w-[350px] h-full" collapses="false">
                      <SidebarContent class="p-0 h-full flex flex-col">
                        <SidebarHeader class="px-4 py-3 border-b">
                          <div class="flex items-center justify-between">
                            <h2 class="text-xl font-semibold">Recent PDFs</h2>
                          </div>
                        </SidebarHeader>

                        <div class="flex-1 overflow-auto">
                          <ScrollArea class="h-full px-2">
                            <div v-if="recentPdfs.length === 0" class="flex flex-col items-center justify-center p-8 h-32 text-muted-foreground">
                              <Icon name="lucide:file" class="w-10 h-10 mb-2 opacity-30" />
                              <p>No recent PDFs</p>
                            </div>
                            <div v-else class="divide-y">
                              <div 
                                v-for="(pdf, index) in recentPdfs" 
                                :key="index"
                                class="py-3 px-2 hover:bg-accent/20 cursor-pointer rounded-md transition-colors flex items-start gap-3"
                                @click="switchToPdf(pdf)"
                              >
                                <Icon name="ph:file-pdf-light" class="w-8 h-8 flex-shrink-0 text-muted-foreground" />
                                <div class="flex flex-col overflow-hidden">
                                  <span class="font-medium truncate">{{ pdf.title || 'Untitled PDF' }}</span>
                                  <span class="text-xs text-muted-foreground truncate">
                                    {{ pdf.filename || pdf.url.split('/').pop() || 'PDF Document' }}
                                  </span>
                                  <span class="text-xs text-muted-foreground mt-1">
                                    {{ formatTime(pdf.timestamp) }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </ScrollArea>
                        </div>

                        <SidebarFooter class="p-4 border-t">
                          <p class="text-xs text-muted-foreground text-center">
                            Drop a PDF file here to view it
                          </p>
                        </SidebarFooter>
                      </SidebarContent>
                    </Sidebar>
                    
                    <!-- Add this to ensure the sidebar fills the available space -->
                    <div class="bg-background w-full h-full flex items-center justify-center">
                      <div v-if="!sidebarOpen" class="text-center">
                        <Button @click="sidebarOpen = true" variant="outline" class="mb-2">
                          <Icon name="lucide:file-text" class="w-5 h-5 mr-2" />
                          Open Recent PDFs
                        </Button>
                        <p class="text-sm text-muted-foreground mt-2">
                          or drop a PDF file here to view it
                        </p>
                      </div>
                    </div>
                  </SidebarProvider>
                </div>
              </template>
            </ClientOnly>
          </template>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<style>
.vue-pdf-embed {
  width: 100%;
  height: 100%;
}

.vpv-toolbar-wrapper{
  border-radius: 0px !important;
}

.vpv-pages-inner-container .vpv-pages-scroll-mode__vertical .vpv-pages-view-mode__single {
  border: none !important;
  border-bottom: 0px !important
}


.vpv-page-inner-container{
  background-color: hsl(var(--background)) !important;
}

.vpv-toolbar-wrapper{
  background-color: hsl(var(--background)) !important;
}

.vpv-toolbar{
  background-color: hsl(var(--background)) !important;
}

.vpv-dropdown-menu{
  background-color: hsl(var(--background)) !important;
}

.vpv-dropdown-menu-content{
  background-color: hsl(var(--background)) !important;
}

.vpv-pages-inner-container, .vpv-pages-scroll-mode__vertical, .vpv-pages-view-mode__single{
  background-color: hsl(var(--background)) !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  
}

</style>
