interface Plant {
  species: string
  genus: string
  family: string
  quantity: number
  spacing?: number  // in meters
  area?: number     // in square meters
}

interface DiversityResult {
  isValid: boolean
  speciesCount: number
  totalPlants: number
  genusDistribution: Record<string, number>
  familyDistribution: Record<string, number>
  simpsonsIndex: number
  criteria: {
    minimumSpecies: {
      passed: boolean
      message: string
    }
    genusRule: {
      passed: boolean
      message: string
      violations: string[]
    }
    familyRule: {
      passed: boolean
      message: string
      violations: string[]
    }
    simpsonsRule: {
      passed: boolean
      message: string
      violations: string[]
    }
  }
}

export const useSpeciesDiversity = () => {
  // Helper to estimate area from spacing or vice versa
  const getPlantArea = (plant: Plant): number => {
    if (plant.area) return plant.area
    if (plant.spacing) {
      // Estimate area as a circle using spacing as diameter
      return Math.PI * Math.pow(plant.spacing / 2, 2)
    }
    // Default to 1 square meter if no spacing/area info
    return 1
  }

  const calculateSimpsonsIndex = (speciesCounts: Record<string, number>): number => {
    console.log('Calculating Simpson\'s Index with species counts:', speciesCounts)
    if (!speciesCounts || Object.keys(speciesCounts).length === 0) return 0
    
    // Try area-based calculation first
    const totalArea = Object.values(speciesCounts).reduce((sum, area) => sum + area, 0)
    const proportions = Object.values(speciesCounts).map(area => area / totalArea)
    const sumSquaredProportions = proportions.reduce((sum, p) => sum + (p * p), 0)
    const areaBasedIndex = 1 - sumSquaredProportions
    
    console.log('Area-based Simpson\'s Index:', {
      totalArea,
      proportions,
      sumSquaredProportions,
      areaBasedIndex
    })
    
    // Check if area-based index is valid (between 0 and 1)
    if (areaBasedIndex >= 0 && areaBasedIndex <= 1) {
      return areaBasedIndex
    }
    
    // Fallback to count-based calculation
    console.log('Falling back to count-based calculation')
    const N = Object.values(speciesCounts).reduce((sum, n) => sum + Math.round(n), 0)
    if (N <= 1) return 0
    
    const sumNumerator = Object.values(speciesCounts).reduce((sum, n) => {
      const count = Math.round(n)
      return sum + (count * (count - 1))
    }, 0)
    
    const countBasedIndex = 1 - (sumNumerator / (N * (N - 1)))
    
    console.log('Count-based Simpson\'s Index:', {
      N,
      sumNumerator,
      countBasedIndex
    })
    
    return countBasedIndex
  }

  const evaluateDiversity = (plants: Plant[]): DiversityResult => {
    console.log('Evaluating diversity with plants:', plants)
    // Initialize counts
    const speciesCounts: Record<string, number> = {}
    const genusCounts: Record<string, number> = {}
    const familyCounts: Record<string, number> = {}
    
    // Count species, genera and families using area/spacing
    plants.forEach(plant => {
      const quantity = plant.quantity || 1
      // Calculate area coverage for this plant
      const area = getPlantArea(plant) * quantity
      
      // Add area to counts
      speciesCounts[plant.species] = (speciesCounts[plant.species] || 0) + area
      genusCounts[plant.genus] = (genusCounts[plant.genus] || 0) + area
      familyCounts[plant.family] = (familyCounts[plant.family] || 0) + area
    })

    console.log('Species area coverage:', speciesCounts)
    console.log('Genus area coverage:', genusCounts)
    console.log('Family area coverage:', familyCounts)

    const totalSpecies = Object.keys(speciesCounts).length
    const totalArea = Object.values(speciesCounts).reduce((sum, area) => sum + area, 0)
    const simpsonsIndex = calculateSimpsonsIndex(speciesCounts)

    // Check violations using area proportions
    const genusViolations = Object.entries(genusCounts)
      .filter(([_, area]) => (area / totalArea) > 0.3)
      .map(([genus]) => `${genus} exceeds 30% of total area`)

    const familyViolations = Object.entries(familyCounts)
      .filter(([_, area]) => (area / totalArea) > 0.3)
      .map(([family]) => `${family} exceeds 30% of total area`)

    const simpsonsViolations = []
    if (simpsonsIndex < 0.5) {
      simpsonsViolations.push("Simpson's Diversity Index is below 0.5, indicating low diversity")
    }

    return {
      isValid: totalSpecies >= 10 && genusViolations.length === 0 && 
               familyViolations.length === 0 && simpsonsIndex >= 0.5,
      speciesCount: totalSpecies,
      totalPlants: totalArea,
      genusDistribution: genusCounts,
      familyDistribution: familyCounts,
      simpsonsIndex,
      criteria: {
        minimumSpecies: {
          passed: totalSpecies >= 10,
          message: totalSpecies >= 10 ? 
            'Meets minimum species requirement' : 
            'Does not meet minimum of 10 species'
        },
        genusRule: {
          passed: genusViolations.length === 0,
          message: genusViolations.length === 0 ? 
            'No genus exceeds 30% of total area' : 
            'Some genera exceed 30% of total area',
          violations: genusViolations
        },
        familyRule: {
          passed: familyViolations.length === 0,
          message: familyViolations.length === 0 ? 
            'No family exceeds 30% of total area' : 
            'Some families exceed 30% of total area',
          violations: familyViolations
        },
        simpsonsRule: {
          passed: simpsonsIndex >= 0.5,
          message: simpsonsIndex >= 0.5 ?
            `Good diversity (Simpson's Index: ${simpsonsIndex.toFixed(2)})` :
            `Low diversity (Simpson's Index: ${simpsonsIndex.toFixed(2)})`,
          violations: simpsonsViolations
        }
      }
    }
  }

  return {
    evaluateDiversity,
    calculateSimpsonsIndex
  }
}
