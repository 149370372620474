<script lang="ts" setup>
import { VPdfViewer, useLicense, VPVBaseProps } from '@vue-pdf-viewer/viewer';
import { watch, computed, ref, onMounted, onBeforeUnmount, provide } from 'vue';
import type { ToolbarOptions, ZoomLevel } from '@vue-pdf-viewer/viewer';

const props = defineProps({ 
  ...VPVBaseProps, 
  licenseKey: { 
    type: String, 
    required: true 
  },
  darkMode: {
    type: Boolean,
    default: false,
    required: false
  },
  src: {
    type: String,
    required: true
  },
  showParentLoader: {
    type: Boolean,
    default: true,
    required: false
  }
})

const emit = defineEmits(['loaded', 'error']);

// Track loading state
const isLoading = ref(false);
const hasError = ref(false);
const errorMessage = ref('');
const blobUrl = ref<string | null>(null);
const pdfLoaded = ref(false);
const loadingTimedOut = ref(false);
const licenseApplied = ref(false);

// Initialize loadingTimeout variable
let loadingTimeout: ReturnType<typeof setTimeout> | null = null;

// Define any necessary provide values here in the setup function
// Example:
// provide('pdfViewerLoaded', pdfLoaded);
// provide('pdfViewerError', hasError);

// Apply license in setup and track its state
onMounted(() => {
  if (!props.licenseKey) {
    console.warn('No license key provided yet, waiting for prop update');
    return;
  }
  
  try {
    useLicense(props.licenseKey);
    licenseApplied.value = true;
    console.log('PDF Viewer license applied successfully on mount');
  } catch (error) {
    console.error('Failed to apply PDF Viewer license:', error);
    hasError.value = true;
    errorMessage.value = error instanceof Error ? error.message : 'License application failed';
    emit('error', errorMessage.value);
  }
});

// Watch for license key changes
watch(() => props.licenseKey, (newKey) => {
  if (!newKey) {
    console.warn('Empty license key received');
    return;
  }
  
  if (!licenseApplied.value) {
    try {
      useLicense(newKey);
      licenseApplied.value = true;
      console.log('PDF Viewer license applied after prop change');
      hasError.value = false;
      errorMessage.value = '';
    } catch (error) {
      console.error('Failed to apply PDF Viewer license after change:', error);
      hasError.value = true;
      errorMessage.value = error instanceof Error ? error.message : 'License application failed';
      emit('error', errorMessage.value);
    }
  }
});

// Handle PDF source validation
const isValidSource = computed(() => {
  return !!props.src;
});

// Default blank PDF to use when no source is provided
const BLANK_PDF_URL = '/blank.pdf';

// Handle source type (URL, Blob, or File path)
const processedSrc = computed(() => {
  // If no source is provided, use blank PDF
  if (!props.src) return BLANK_PDF_URL;
  
  // Otherwise, return source as is (URL or file path)
  return props.src;
});

const onLoadSuccess = () => {
  console.log('PDF loaded successfully!');
  isLoading.value = false;
  pdfLoaded.value = true;
  hasError.value = false;
  loadingTimedOut.value = false;
  emit('loaded');
};

const onLoadError = (error: any) => {
  console.error('PDF load error:', error);
  isLoading.value = false;
  pdfLoaded.value = false;
  hasError.value = true;
  loadingTimedOut.value = false;
  errorMessage.value = error instanceof Error ? error.message : 'Failed to load PDF';
  emit('error', errorMessage.value);
};

// VPdfViewer has a loaded event that we need to handle
const handleVPdfViewerLoaded = () => {
  console.log('VPdfViewer loaded event received');
  onLoadSuccess();
};

// Clean up blob URLs if needed
onBeforeUnmount(() => {
  if (blobUrl.value) {
    console.log('Cleaning up blob URL on component unmount:', blobUrl.value);
    URL.revokeObjectURL(blobUrl.value);
    blobUrl.value = null;
  }
});

// Retry loading the PDF after timeout or error
const retryLoading = () => {
  console.log('Retrying PDF load');
  hasError.value = false;
  loadingTimedOut.value = false;
  errorMessage.value = '';
  
  // Only set loading to true if we have a valid source
  if (isValidSource.value) {
    isLoading.value = true;
    pdfLoaded.value = false;
    startLoadingTimeout();
    
    // Force a re-render of the VPdfViewer component
    // by temporarily setting the source to null and back
    const currentSrc = props.src;
    if (currentSrc) {
      setTimeout(() => {
        console.log('Re-initializing PDF viewer with source:', currentSrc);
      }, 100);
    }
  }
};

// Function to start the loading timeout
const startLoadingTimeout = () => {
  // Clear any existing timeout
  if (loadingTimeout) {
    clearTimeout(loadingTimeout);
  }
  
  // Only start timeout if we have a valid source and are loading
  if (isLoading.value) {
    // Use a shorter timeout for blank PDF
    const timeoutDuration = props.src === BLANK_PDF_URL ? 5000 : 15000;
    
    loadingTimeout = setTimeout(() => {
      if (isLoading.value && !hasError.value && !pdfLoaded.value) {
        console.log('PDF loading timeout reached');
        isLoading.value = false;
        // Only set timeout flag for actual PDFs, not the blank one
        loadingTimedOut.value = props.src !== BLANK_PDF_URL;
      }
    }, timeoutDuration);
  }
};

// Watch for source changes to reset loading/error states
watch(() => props.src, (newSrc, oldSrc) => {
  if (newSrc !== oldSrc) {
    console.log(`Source changed from ${oldSrc} to ${newSrc}`);
    
    // Reset error and timeout states first
    hasError.value = false;
    loadingTimedOut.value = false;
    errorMessage.value = '';
    
    // Clean up any existing timeout
    if (loadingTimeout) {
      clearTimeout(loadingTimeout);
      loadingTimeout = null;
    }
    
    if (newSrc) {
      // If we have a new valid source, set to loading and start timeout
      console.log('Valid source detected, starting loading process');
      isLoading.value = true;
      pdfLoaded.value = false;
      startLoadingTimeout();
    } else {
      // If source is cleared, reset to ready state with blank PDF
      console.log('No source detected, using blank PDF');
      isLoading.value = true; // Still need to load the blank PDF
      pdfLoaded.value = false;
      startLoadingTimeout();
    }
  }
}, { immediate: true });

// Default toolbar options
const defaultToolbarOptions: Partial<ToolbarOptions> = {
    docPropertiesEnabled: false,
    jumpNavigatable: false,
    pointerSwitchable: false,
    navigatable: false,
    newFileOpenable: false,
    pageViewSwitchable: false,
    printable: false,
    rotatable: true,
    scrollingSwitchable: true,
    searchable: true,  // Enable search functionality
    sidebarEnable: false,
    themeSwitchable: false,
    thumbnailViewable: false,
    zoomable: true
};

// Merge parent toolbar options with defaults
const mergedToolbarOptions = computed(() => {
  return { 
    ...defaultToolbarOptions, 
    ...(props.toolbarOptions || {}),
    theme: props.darkMode ? 'dark' : 'light'
  };
});

// Create a new props object without toolbarOptions to avoid conflicts
const filteredProps = computed(() => {
  const { toolbarOptions, ...rest } = props;
  
  return {
    ...rest,
    // Always provide a source - either the actual source or blank PDF
    src: processedSrc.value,
    onLoadSuccess: onLoadSuccess,
    onLoadError: onLoadError
  };
});

// Explicitly wait for a tiny delay and handle PDF load success
onMounted(() => {
  // Only initialize if we have a source
  if (props.src) {
    console.log('Component mounted with source:', props.src);
    isLoading.value = true;
    startLoadingTimeout();
    
    // Add a small delay before assuming viewer is ready
    // This helps with timing issues in some cases
    setTimeout(() => {
      if (isLoading.value && !pdfLoaded.value && !hasError.value) {
        console.log('Checking if PDF is loaded after initial delay');
      }
    }, 500);
  } else {
    console.log('Component mounted without source, in ready state');
  }
});

onBeforeUnmount(() => {
  if (loadingTimeout) {
    clearTimeout(loadingTimeout);
    loadingTimeout = null;
  }
});
</script>

<template>
    <div v-if="hasError" class="pdf-viewer-error flex items-center justify-center h-full w-full">
      <div class="text-center p-4">
        <div class="mb-2">Failed to load PDF</div>
        <div class="text-sm text-muted-foreground mb-4">{{ errorMessage }}</div>
        <button 
          @click="retryLoading" 
          class="px-4 py-2 bg-primary text-primary-foreground rounded hover:bg-primary/90 transition-colors"
        >
          Try Again
        </button>
      </div>
    </div>
    <div v-else-if="!licenseApplied" class="flex items-center justify-center h-full w-full">
      <div class="text-center p-4">
        <div class="animate-pulse">Initializing PDF viewer...</div>
      </div>
    </div>
    <template v-else>
      <div v-if="loadingTimedOut" class="pdf-viewer-error flex items-center justify-center h-full w-full">
        <div class="text-center p-4 max-w-md">
          <Icon name="ph:clock-countdown" class="w-12 h-12 mb-2 mx-auto text-amber-500" />
          <div class="mb-2 text-lg font-medium">Taking too long to load</div>
          <div class="text-sm text-muted-foreground mb-4">
            The PDF is taking longer than expected to load. This could be due to a slow connection or a large file.
          </div>
          <button 
            @click="retryLoading" 
            class="px-4 py-2 bg-primary text-primary-foreground rounded hover:bg-primary/90 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
      <div v-else-if="isLoading && !pdfLoaded && props.showParentLoader" class="flex items-center justify-center h-full w-full absolute z-10 top-0 left-0 right-0 bottom-0 bg-background/80">
        <div class="flex flex-col items-center">
          <img src="/images/logos/superseeded_logo_s.webp" width="80" height="80" class="animate-pulse mb-2" />
          <div>{{ !isValidSource ? 'Getting ready...' : 'Loading PDF...' }}</div>
        </div>
      </div>
      <!-- Only render the PDF viewer once license is applied -->
      <VPdfViewer 
        v-bind="filteredProps" 
        :toolbarOptions="mergedToolbarOptions" 
        class="pdf-viewer-custom"
        @loaded="handleVPdfViewerLoaded"
        @error="onLoadError"
        :src="processedSrc"
      >
        <template #loaderImage>
          <img src="/images/logos/superseeded_logo_s.webp" width="80" height="80" />
        </template>
      </VPdfViewer>
    </template>
</template> 

<style lang="scss">
/* Apply styles globally to ensure they override the component's internal styles */

/* Additional direct overrides for the component */
.pdf-viewer-custom {
  :deep(.vpv-container) {
    border-color: var(--vpv-container-border-color);
  }
  
  :deep(.vpv-toolbar) {
    background-color: var(--vpv-toolbar-background-color);
    color: var(--vpv-toolbar-color);
    border-color: var(--vpv-toolbar-border-color);
  }
  
  :deep(.vpv-sidebar-content) {
    background-color: var(--vpv-sidebar-content-background-color);
  }
  
  :deep(.vpv-pages-container) {
    background: var(--vpv-pages-container-background);
  }
}
</style>