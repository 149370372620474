import { useGeolocation } from '@vueuse/core'
import type { Ref } from 'vue'

interface SearchOptions {
  limit?: number;
  distinct?: string;
  sort?: string[];
}

interface BiosecurityResults {
  eppo: Array<{ hits: any[] }>;
  padil: Array<{ data: any[] }>;
}

interface GbifAutocompleteParams {
  q: string;
  datasetKey?: string;
  rank?: string;
  higherTaxonKey?: string;
  status?: string;
  isExtinct?: boolean;
  habitat?: string;
  threat?: string;
  nameType?: string;
  nomenclaturalStatus?: string;
  origin?: string;
  issue?: string;
  limit?: number;
}

interface GbifAutocompleteResult {
  key: number;
  nameKey: number;
  kingdom: string;
  phylum: string;
  order: string;
  family: string;
  genus: string;
  kingdomKey: number;
  phylumKey: number;
  classKey: number;
  orderKey: number;
  familyKey: number;
  genusKey: number;
  parent: string;
  parentKey: number;
  nubKey: number;
  scientificName: string;
  canonicalName: string;
  rank: string;
  status: string;
  synonym: boolean;
  higherClassificationMap: Record<string, string>;
  class: string;
  species?: string;
}

export function useSearch() {
  const authStore = useAuthStore();
  const { setOpenverseAccessToken } = authStore;
  const { openverseAccessToken } = storeToRefs(authStore);

  const { search } = useMeiliSearch('gbif')
  const { search: searchEPPO } = useMeiliSearch('eppo')
  const meilisearch = useMeiliSearchRef()
  const { coords } = useGeolocation()

  const results: Ref<BiosecurityResults | any> = ref(null)
  const fetchStatus = ref<'idle' | 'pending' | 'error'>('idle')
  const gbifAutocompleteResults = ref<GbifAutocompleteResult[]>([])

  const fetchSpeciesDataIndividually = async (
    names: string[], 
    options: SearchOptions = {}
  ): Promise<Array<{species: string}>> => {
    const { 
      limit = 1, 
      distinct = 'species'
    } = options

    fetchStatus.value = 'pending'
    results.value = []

    try {
      // Filter and clean names before processing
      const validNames = names
        .filter(Boolean) 
        .map(name => name.trim())
        .filter(name => name !== '')
      
      if (validNames.length === 0) {
        console.warn('No valid names provided to fetchSpeciesDataIndividually')
        fetchStatus.value = 'idle'
        return []
      }

      // Create cache key with cleaned names
      const uniqueNames = [...new Set(validNames.map(n => n.toLowerCase()))]
      const cacheKey = `gbif-search-${uniqueNames.sort().join(',')}`
      
      // Try to get from Nuxt's payload cache first
      const nuxtApp = useNuxtApp()
      let cachedData = nuxtApp.payload?.data?.[cacheKey]
      
      if (cachedData) {
        console.log('Using cached GBIF data')
        results.value = cachedData
        fetchStatus.value = 'idle'
        return cachedData
      }

      // Fetch data for each unique name
      const processedNames = new Set()
      const searchPromises = uniqueNames.map(async name => {
        if (!name || processedNames.has(name)) return null
        processedNames.add(name)

        const { data: speciesData } = await useAsyncData(
          `gbif-${name}`,
          () => search(name, { limit }),
          {
            getCachedData: (key) => useNuxtApp().payload.data[key] || null
          }
        )

        // If no hits, create a fallback result with the original name
        if (!speciesData.value?.hits?.length) {
          console.log(`No GBIF match found for: ${name}, using as-is`)
          return [{ species: name, source: 'original', matchType: 'unmatched' }]
        }
        
        return speciesData.value?.hits || []
      })

      const searchResults = await Promise.all(searchPromises)
      
      // Process and deduplicate results
      const seenSpecies = new Set()
      results.value = searchResults
        .flat()
        .filter(Boolean)
        .filter((hit: any) => {
          // Handle results without species property
          const speciesName = hit?.species || hit?.canonicalName || hit?.scientificName
          if (!speciesName) return false
          
          const key = speciesName.toLowerCase()
          if (seenSpecies.has(key)) return false
          seenSpecies.add(key)
          
          // Ensure species property exists even if it came from another field
          if (!hit.species && speciesName) {
            hit.species = speciesName
          }
          
          return true
        })

      // Cache the results
      if (nuxtApp.payload?.data) {
        nuxtApp.payload.data[cacheKey] = results.value
      }

      // Log if we still have no results after processing
      if (!results.value.length) {
        console.warn(`No species matches found after processing: ${uniqueNames.join(', ')}`)
      }

      fetchStatus.value = 'idle'
      return results.value

    } catch (error) {
      console.error('Error fetching species data:', error)
      fetchStatus.value = 'error'
      throw error
    }
  }

  const fetchSpeciesDataFederated = async (data: string[]) => {
    fetchStatus.value = 'pending'
    const { data: speciesData } = await useAsyncData(
      `botanical-names-${data.join('-')}`,
      async () => {
        return await meilisearch.multiSearch({
          federation: { limit: 1 },
          queries: data.map(d => ({ 
            indexUid: 'gbif', 
            q: d
          }))
        })
      },
      {
        getCachedData: (key) => {
          const nuxtApp = useNuxtApp()
          return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        },
      }
    )
    results.value = speciesData.value?.hits || []

    fetchStatus.value = 'idle'
    return results.value
  }

  const fetchBiosecurityData = async (
    data: string[],
    filters: string[] = ['libtype=plant']
  ): Promise<BiosecurityResults> => {
    fetchStatus.value = "pending";

    const filtersArray = Array.isArray(filters) ? filters : [filters];

    const [eppoData, padilData] = await Promise.all([
      useAsyncData(
        `eppo-${data.join('-')}-${filtersArray.join('-')}`,
        async () => {
          const searchPromises = data.map(query => searchEPPO(query, {
            filter: filtersArray.join(' AND '),
            limit: 100
          }));
          const searchResults = await Promise.all(searchPromises);
          return searchResults.flat();
        },
        {
          getCachedData: (key) => {
            const nuxtApp = useNuxtApp();
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
          },
        }
      ),
      useAsyncData(
        `padil-${data.join('-')}`,
        async () => {
          const searchPromises = data.map(query => $fetch(`/api/bio/padil`, {
            method: 'POST',
            body: { searchQuery: query }
          }));
          const searchResults = await Promise.all(searchPromises);
          return searchResults.flat();
        },
        {
          getCachedData: (key) => {
            const nuxtApp = useNuxtApp();
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
          },
        }
      )
    ]);

    results.value = { eppo: eppoData.data.value, padil: padilData.data.value };
    console.log('padilData', padilData.data.value)
    fetchStatus.value = "idle";
    return results.value;
  };


  const fetchSerper = async (speciesNames: string[]) => {
    const { data, refresh } = await useAsyncData(
      `serper-images-${speciesNames.join('-')}`,
      async () => {
        const requests = speciesNames.map((speciesName) =>
          $fetch('/api/serp/serper', {
            method: 'POST',
            body: { q: speciesName },
          })
        )
        const responses = await Promise.all(requests)
        return responses.flatMap((response) => {
          // Safely handle different response formats
          if (Array.isArray(response)) {
            return response;
          } else if (response && typeof response === 'object' && 'results' in response) {
            return (response as { results: any[] }).results;
          } else {
            return [response]; // Fallback to treating as a single result
          }
        })
      }
    )

    return {
      serperResults: data?.value,
      refreshSerper: refresh,
    }
  };

  const fetchOpenverse = async (speciesNames: string[]) => {
    const headers = useRequestHeaders(['cookie'])
    if (
      !openverseAccessToken.value?.key ||
      openverseAccessToken.value?.expiry < Date.now()
    ) {
      const ov_token = await $fetch("/api/auth/openverse", {
        method: "POST",
        headers,
      })
      setOpenverseAccessToken(ov_token.key, ov_token.expiry)
    }
    
    const apiKey = openverseAccessToken.value?.key;
    
    const openverseResults = ref<any[]>([]);

    const { data: openverseData, refresh } = await useAsyncData(
      `openverse-images-${speciesNames.join('-')}`,
      async () => {
        const requests = speciesNames.map((speciesName) =>
          $fetch(
            `https://api.openverse.org/v1/images/?q=${speciesName}&license=by,by-nd,by-sa,cc0,pdm`,
            {
              headers: {
                // Authorization: `Bearer ${apiKey}`,
                Authorization: `Bearer uVJzZqDjsrjuhqu6hYDzULxw7lx2e1`,
              },
            }
          )
        );
        const responses = await Promise.all(requests);
        return responses.flatMap(
          (response) => (response as { results: any[] }).results
        );
      }
    );

    openverseResults.value = openverseData.value || [];

    return {
      openverseResults,
      refreshOpenverse: refresh,
    };
  }

  /**
   * Fetch autocomplete suggestions from GBIF API
   * @param params - Search parameters for GBIF autocomplete
   * @returns Object containing results and refresh function
   */
  const fetchGbifAutocomplete = async (params: GbifAutocompleteParams) => {
    const { q, limit = 20, ...otherParams } = params
    
    if (!q || q.trim() === '') {
      gbifAutocompleteResults.value = []
      return {
        gbifAutocompleteResults,
        refresh: () => Promise.resolve()
      }
    }

    fetchStatus.value = 'pending'
    
    // Build query parameters
    const queryParams = new URLSearchParams()
    queryParams.append('q', q.trim())
    
    // Add optional parameters if they exist
    Object.entries(otherParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        queryParams.append(key, String(value))
      }
    })
    
    // Create a cache key based on all parameters
    const cacheKey = `gbif-autocomplete-${queryParams.toString()}`
    
    const { data, refresh } = await useAsyncData(
      cacheKey,
      async () => {
        try {
          const results = await $fetch<GbifAutocompleteResult[]>(
            `https://api.gbif.org/v1/species/suggest?${queryParams.toString()}`
          )
          return results
        } catch (error) {
          console.error('Error fetching GBIF autocomplete data:', error)
          return []
        } finally {
          fetchStatus.value = 'idle'
        }
      },
      {
        getCachedData: (key) => {
          const nuxtApp = useNuxtApp()
          return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        }
      }
    )
    
    gbifAutocompleteResults.value = data.value || []
    
    return {
      gbifAutocompleteResults,
      refresh
    }
  }

  return {
    results,
    fetchStatus,
    gbifAutocompleteResults,
    fetchSpeciesDataIndividually,
    fetchSpeciesDataFederated,
    fetchBiosecurityData,
    fetchSerper,
    fetchOpenverse,
    fetchGbifAutocomplete
  }
}
