<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'
import { useSidebar } from './utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()

const { state } = useSidebar()
const isCollapsed = computed(() => state.value === 'collapsed')
</script>

<template>
  <div 
    class="transition-all duration-300 ease-in-out" 
    :class="[
      props.class,
      {
        'opacity-0 translate-x-4': isCollapsed,
        'opacity-100 translate-x-0': !isCollapsed
      }
    ]"
  >
    <slot />
  </div>
</template>

<style scoped>
div {
  will-change: transform, opacity;
}
</style>
