import { ref, onUnmounted } from 'vue'
import { useDebounceFn, useIntervalFn } from '@vueuse/core'
import hashSum from 'hash-sum'

export interface EmitterOptions {
  /** Debounce time in milliseconds */
  debounceMs?: number
  /** Keys in the data object to use for generating hash (if empty, entire data is used) */
  hashKeys?: string[]
  /** Row indices to include for hash calculation (if not specified, all rows are used) */
  rowIndices?: number[]
  /** Fields to include in the hash calculation (if empty, all fields are used) */
  fields?: string[]
  /** Whether duplicate rows should be considered unique */
  considerDuplicatesUnique?: boolean
  /** Auto-cleanup interval in milliseconds (0 to disable) */
  cleanupIntervalMs?: number
}

export function useEmitter(options: EmitterOptions = {}) {
  const {
    debounceMs = 500,
    hashKeys = [],
    rowIndices = [],
    fields = [],
    considerDuplicatesUnique = false,
    cleanupIntervalMs = 5000, // 5 seconds by default
  } = options

  // Store hashes to prevent duplicate emissions
  const lastHashes = ref<Record<string, { timestamp: number, hash: string }>>({})
  
  // Clear expired hashes
  const clearHashCache = () => {
    console.log('[useEmitter] Clearing hash cache')
    lastHashes.value = {}
  }

  // Set up timer for automatic cleanup if enabled
  const { pause } = useIntervalFn(
    clearHashCache,
    cleanupIntervalMs,
    { immediate: false }
  )

  // Prepare data for hashing based on options
  const prepareDataForHash = (data: any) => {
    if (!data) return data

    // If it's an array and rowIndices specified, only include those rows
    if (Array.isArray(data) && rowIndices.length > 0) {
      return rowIndices.map(index => index < data.length ? data[index] : null)
        .filter(Boolean)
    }

    // if fields specified, only include those fields
    if (fields.length > 0) {
      const filtered: Record<string, any> = {}
      fields.forEach(field => {
        if (field in data) {
          filtered[field] = data[field]
        }
      })
      console.log('[useEmitter] Filtered data:', filtered)
      return filtered
    }

    // If hashKeys specified and data is an object, only include those keys
    if (hashKeys.length > 0 && typeof data === 'object' && !Array.isArray(data)) {
      const filtered: Record<string, any> = {}
      hashKeys.forEach(key => {
        if (key in data) {
          filtered[key] = data[key]
        }
      })
      return filtered
    }

    // Consider duplicate rows in arrays
    if (considerDuplicatesUnique && Array.isArray(data)) {
      // Count occurrences of each item
      const counts: Record<string, number> = {}
      data.forEach(item => {
        const itemHash = hashSum(item)
        counts[itemHash] = (counts[itemHash] || 0) + 1
      })
      // Include counts in hash data
      return { data, counts }
    }

    // For arrays, include the length in the hash to detect size changes
    if (Array.isArray(data)) {
      // Include both the array and its length to ensure size changes affect the hash
      return { 
        data, 
        length: data.length 
      }
    }

    return data
  }

  // Create a base debounced emitter function
  const baseEmit = useDebounceFn((
    emitter: any,
    eventName: string | any,
    data: any,
    tag: string = 'default',
    isMitter: boolean = false
  ) => {
    const hashData = prepareDataForHash(data)
    const dataHash = hashSum(hashData)
    // Use both eventName and tag to create a unique key for each event+tag combination
    const emitKey = `${eventName}:${tag}`
    
    if (lastHashes.value[emitKey]?.hash === dataHash) {
      console.log(`[useEmitter] Skipping duplicate ${isMitter ? 'mitter ' : ''}event: ${eventName} (tag: ${tag}, hash: ${dataHash})`)
      return false
    }
    
    lastHashes.value[emitKey] = {
      hash: dataHash,
      timestamp: Date.now()
    }
    
    const enrichedData = typeof data === 'object' && data !== null
      ? { ...data, _hash: dataHash }
      : data
    
    console.log(`[useEmitter] Emitting ${isMitter ? 'mitter ' : ''}event: ${eventName} (tag: ${tag}) with hash: ${dataHash}`)
    emitter.emit(isMitter ? eventName as any : eventName, enrichedData)
    return true
  }, debounceMs)

  // Create specialized versions that use the base emitter
  const emit = (emitter: any, eventName: string, data: any, tag?: string) => 
    baseEmit(emitter, eventName, data, tag, false)

  const emitMitter = (mitter: any, eventName: any, data: any, tag?: string) => 
    baseEmit(mitter, eventName, data, tag, true)

  // Add clearHashCache to both emit functions for easier access
  emit.clearHashCache = clearHashCache
  emitMitter.clearHashCache = clearHashCache

  // Cleanup on component unmount
  onUnmounted(() => {
    pause()
    clearHashCache()
  })

  return {
    emit,
    emitMitter,
    lastHashes,
    clearHashCache
  }
} 