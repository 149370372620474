import { ref, computed, watch, reactive, onMounted, onBeforeUnmount } from 'vue'
import { useLayoutStore } from '@edanweis/vue-code-layout'
import type { LayoutStore } from '@edanweis/vue-code-layout'
import { useNuxtApp } from '#app'
import type { Tool } from '@/types/tools'
import type { PanelData } from '@/types/projectdock'
import { useTools } from '@/composables/tools/useTools'
import { useMitter } from '#imports'
import { usePanelDataTransformers } from './useTransformers'
import { useDebounceFn } from '@vueuse/core'
import { useDockStore } from '@/stores/dockStore'

// Define types
export type PanelId = string
export type ToolId = string

interface PanelConnection {
  sourceId: PanelId
  targetId: PanelId
  active: boolean
}

// Define the structure of the layout instance
interface CodeLayoutSplitNInstance {
  getRootGrid: () => any;
  // Add other properties as needed
}

// Declare module augmentation without the duplicate property error
declare module '@edanweis/vue-code-layout' {
  interface LayoutStore {
    // Make sure the type matches exactly what's in the library
    layoutInstance: Ref<CodeLayoutSplitNInstance | null>; 
  }
}

// Define Mitter event types
interface MitterEvents {
  'panel:removed': { panelId: string }
  'panel:toolData': { panelId: string, toolId: string, data: any }
  'panel:data:received': { 
    targetPanelId: string,
    sourcePanelId: string,
    sourceToolId: string,
    data: any 
  }
  'panel:data:send': { 
    sourcePanelId: string,
    targetPanelId: string,
    sourceToolId: string,
    targetToolId: string,
    data: any 
  }
  'panel:connect': {
    sourcePanelId: string,
    targetPanelId: string,
    sourceToolId: string,
    targetToolId: string
  }
  'panel:disconnect': {
    sourcePanelId: string,
    targetPanelId: string
  }
  // Add a wildcard type to allow any event name
  '*': any
}

// Extend the NuxtMitterEvents interface to include our custom events
declare module '#app' {
  interface NuxtMitterEvents extends MitterEvents {}
}

export const usePanelConnections = (panelId: PanelId) => {
  if (!panelId) {
    console.error('❌ Panel ID is required for usePanelConnections')
    throw new Error('Panel ID is required for usePanelConnections')
  }
  
  // Helper function to safely get the layout store
  const getLayoutStore = () => {
    if (!import.meta.client) {
      console.warn('[usePanelConnections] Running in SSR context, layout store not available')
      return null
    }
    
    try {
      return useLayoutStore()
    } catch (error) {
      console.error('[usePanelConnections] Failed to access layout store:', error)
      return null
    }
  }

  // Create a reactive reference to the layout store that will be updated when available
  const layoutStoreRef = ref<LayoutStore | null>(getLayoutStore())
  
  // Create a reactive reference that will update when the layout store becomes available
  const layoutInstance = computed(() => {
    // Try to get the layout store if we don't have it yet
    if (!layoutStoreRef.value) {
      layoutStoreRef.value = getLayoutStore()
    }
    return layoutStoreRef.value?.layoutInstance
  })

  const { tools } = useTools()
  const nuxtApp = useNuxtApp()
  const mitter = useMitter()
  
  // Track all active connections for this panel
  const connections = ref<Map<PanelId, PanelConnection>>(new Map())
  
  // Get current panel data
  const panel = computed(() => {
    return getAllPanels().find(p => p.name === panelId)
  })
  
  // Get all panels from the layout instance with better error handling
  const getAllPanels = () => {
    // Try to get the layout store if we don't have it yet
    if (!layoutStoreRef.value) {
      layoutStoreRef.value = getLayoutStore()
    }
    
    try {
      if (layoutStoreRef.value?.getAllPanels) {
        return layoutStoreRef.value.getAllPanels()
      }
    } catch (error) {
      console.warn('[usePanelConnections] Could not use getAllPanels from store:', error)
    }
    
    // Fallback to manual panel extraction if layout store method not available
    try {
      const layoutInstanceValue = layoutInstance.value
      if (!layoutInstanceValue) {
        console.warn('[usePanelConnections] Layout instance not available')
        return []
      }
      
      // Get all panels using the layout instance
      const rootGrid = layoutInstanceValue.getRootGrid()
      if (!rootGrid) {
        console.warn('[usePanelConnections] Root grid not available')
        return []
      }
      
      const allPanels: any[] = []
      
      // Recursively collect panels from all grids
      const collectPanelsFromGrid = (grid: any) => {
        if (!grid) return
        
        // Check if the grid has children property instead of getPanels method
        if (grid.children && Array.isArray(grid.children)) {
          // Filter children that are panels (not grids)
          const panels = grid.children.filter((child: any) => !child.childGrid)
          if (panels.length > 0) {
            allPanels.push(...panels)
          }
        }
        
        // Process child grids
        if (grid.childGrid && Array.isArray(grid.childGrid)) {
          grid.childGrid.forEach((childGrid: any) => {
            collectPanelsFromGrid(childGrid)
          })
        } else if (grid.getChildGrids && typeof grid.getChildGrids === 'function') {
          const childGrids = grid.getChildGrids()
          if (childGrids && Array.isArray(childGrids)) {
            childGrids.forEach((childGrid: any) => {
              collectPanelsFromGrid(childGrid)
            })
          }
        }
      }
      
      collectPanelsFromGrid(rootGrid)
      return allPanels
    } catch (error) {
      console.error('[usePanelConnections] Error getting panels:', error)
      return []
    }
  }
  
  // Get current panel's tool
  const panelTool = computed(() => {
    if (!panel.value?.data?.tool_uid) return null
    return tools.find(tool => tool.uid === panel.value?.data?.tool_uid)
  })
  
  // Get allowed connection types for this panel
  const allowedConnectionTypes = computed(() => {
    return panelTool.value?.connectionsAllowed || []
  })
  
  // Get default connection types for this panel
  const defaultConnectionTypes = computed(() => {
    return panelTool.value?.connectionsDefault || []
  })
  
  // Get all panels that this panel can connect to
  const availableConnectionPanels = computed(() => {
    if (!allowedConnectionTypes.value.length) return []
    
    const allPanels = getAllPanels() || []
    
    return allPanels
      .filter(p => {
        if (p.name === panelId) return false // Don't connect to self
        
        // Find the tool for this panel
        const panelToolConfig = tools.find(tool => tool.uid === p.data?.tool_uid)
        if (!panelToolConfig) return false
        
        // Check if this panel's tool type is in our allowed connections
        return allowedConnectionTypes.value.includes(panelToolConfig.id)
      })
      .map(p => {
        const panelToolConfig = tools.find(tool => tool.uid === p.data?.tool_uid)
        
        const isConnected = connections.value.has(p.name)
        const isDefault = defaultConnectionTypes.value.includes(panelToolConfig?.id || '')
        
        return {
          id: p.name,
          label: panelToolConfig?.label || 'Unnamed Panel',
          icon: panelToolConfig?.icon,
          toolId: panelToolConfig?.id,
          isConnected,
          isDefault
        }
      })
  })
  
  // Get panel data from a connected panel
  const getConnectedPanelData = (targetPanelId: PanelId) => {
    const allPanels = getAllPanels()
    return allPanels?.find(p => p.name === targetPanelId)?.data
  }
  
  // Get all connected panels data
  const connectedPanelsData = computed(() => {
    const connectedPanels = Array.from(connections.value.keys())
    
    const allPanels = getAllPanels() || []
    
    return connectedPanels.map(id => {
      const connectedPanel = allPanels.find(p => p.name === id)
      const panelToolConfig = tools.find(tool => tool.uid === connectedPanel?.data?.tool_uid)
      
      return {
        id,
        toolId: panelToolConfig?.id || '',
        toolLabel: panelToolConfig?.label || 'Unknown Tool',
        data: connectedPanel?.data || {}
      }
    })
  })
  
  // Connect to another panel
  const connect = (targetPanelId: PanelId) => {
    if (targetPanelId === panelId) {
      console.warn(`[usePanelConnections] Cannot connect panel ${panelId} to itself`)
      return // Don't connect to self
    }
    
    if (connections.value.has(targetPanelId)) {
      console.log(`[usePanelConnections] Panel ${panelId} already connected to ${targetPanelId}`)
      return // Already connected
    }
    
    // Use dockStore instead of window object
    const dockStore = useDockStore();
    
    // Check if this connection was already established
    if (dockStore.isConnectionEstablished(panelId, targetPanelId)) {
      console.log(`[usePanelConnections] Connection from ${panelId} to ${targetPanelId} already established in registry`)
      // Just ensure the connection exists in our local state
      if (!connections.value.has(targetPanelId)) {
        const newConnections = new Map(connections.value);
        newConnections.set(targetPanelId, {
          sourceId: panelId,
          targetId: targetPanelId,
          active: true
        });
        connections.value = newConnections;
        
        // Update this panel's data.connections array
        updatePanelConnections();
      }
      return;
    }
    
    console.log(`[usePanelConnections] Connecting panel ${panelId} to ${targetPanelId}`)
    
    const targetPanel = getAllPanels()?.find(p => p.name === targetPanelId)
    if (!targetPanel) {
      console.warn(`[usePanelConnections] Target panel ${targetPanelId} not found`)
      return // Target panel not found
    }
    
    // Create a new Map to ensure reactivity
    const newConnections = new Map(connections.value);
    newConnections.set(targetPanelId, {
      sourceId: panelId,
      targetId: targetPanelId,
      active: true
    });
    connections.value = newConnections;
    
    // Get source and target tool IDs for logging
    const sourceToolId = panelTool.value?.id || ''
    const targetToolId = tools.find(t => t.uid === targetPanel.data?.tool_uid)?.id || ''
    
    console.log(`[usePanelConnections] Connected ${sourceToolId || 'Unknown'} panel to ${targetToolId || 'Unknown'} panel`)
    
    // Update this panel's data.connections array
    updatePanelConnections()
    
    // Also update the target panel's connections to ensure bi-directional connection
    // This is critical for the diagnostics panel to recognize incoming connections
    if (targetPanel.data) {
      // Initialize connections array if it doesn't exist
      if (!targetPanel.data.connections) {
        targetPanel.data.connections = []
      }
      
      // Add this panel to target panel's connections if not already there
      if (!targetPanel.data.connections.includes(panelId)) {
        console.log(`[usePanelConnections] Adding ${panelId} to ${targetPanelId}'s connections`)
        targetPanel.data.connections.push(panelId)
        
        // Use object spreading to preserve all existing data in target panel
        targetPanel.data = {
          ...targetPanel.data,
          connections: targetPanel.data.connections
        }
      }
    }
    
    // Emit connection event
    try {
      mitter.emit('panel:connect', {
        sourcePanelId: panelId,
        targetPanelId: targetPanelId,
        sourceToolId: sourceToolId,
        targetToolId: targetToolId
      })
      
      // Also emit data sent event with null data to initialize
      mitter.emit('panel:data:send', {
        sourcePanelId: panelId,
        targetPanelId: targetPanelId,
        sourceToolId: sourceToolId,
        targetToolId: targetToolId,
        data: null
      })
    } catch (error) {
      console.error('[usePanelConnections] Error emitting connection events:', error)
    }
    
    // Register the connection in dockStore
    dockStore.registerConnection(panelId, targetPanelId);
  }
  
  // Disconnect from another panel
  const disconnect = (targetPanelId: PanelId) => {
    if (!connections.value.has(targetPanelId)) {
      console.warn(`[usePanelConnections] Panel ${panelId} not connected to ${targetPanelId}`);
      return // Not connected
    }
    
    console.log(`[usePanelConnections][DEBUG] Disconnecting panel ${panelId} from ${targetPanelId}`, {
      beforeSize: connections.value.size,
      beforeEntries: Array.from(connections.value.entries())
    });
    
    // Create a new Map to ensure reactivity
    const newConnections = new Map(connections.value);
    newConnections.delete(targetPanelId);
    connections.value = newConnections;
    
    console.log(`[usePanelConnections][DEBUG] After removing connection:`, {
      afterSize: connections.value.size,
      afterEntries: Array.from(connections.value.entries())
    });
    
    // Also update the target panel's connections to ensure bi-directional disconnection
    const targetPanel = getAllPanels()?.find(p => p.name === targetPanelId);
    if (targetPanel && targetPanel.data && targetPanel.data.connections) {
      // Remove this panel from target panel's connections if it exists
      if (targetPanel.data.connections.includes(panelId)) {
        console.log(`[usePanelConnections][DEBUG] Removing ${panelId} from ${targetPanelId}'s connections`);
        
        // Create a new array to ensure reactivity
        targetPanel.data = {
          ...targetPanel.data,
          connections: targetPanel.data.connections.filter(id => id !== panelId)
        };
      }
    }
    
    // Emit disconnect event
    try {
      mitter.emit('panel:disconnect', {
        sourcePanelId: panelId,
        targetPanelId: targetPanelId
      });
    } catch (error) {
      console.error('[usePanelConnections] Error emitting disconnect event:', error);
    }
    
    console.log(`[usePanelConnections][DEBUG] Disconnection complete:`, {
      finalSize: connections.value.size,
      finalEntries: Array.from(connections.value.entries())
    });
    
    // Update the panel data to remove the connection
    updatePanelConnections()
  }
  
  // Toggle connection status
  const toggleConnection = (targetPanelId: PanelId) => {
    if (connections.value.has(targetPanelId)) {
      disconnect(targetPanelId)
    } else {
      connect(targetPanelId)
    }
  }
  
  // Initialize connections from panel data
  const initializeConnections = () => {
    try {
      // Get dockStore for connection tracking
      const dockStore = useDockStore();
      
      // If this panel is already fully initialized, skip duplicate initialization
      if (dockStore.isPanelInitialized(panelId)) {
        console.log(`[usePanelConnections] Panel ${panelId} already initialized, checking for changes`);
        
        // Make a local backup of current connections for comparison
        const currentConnectionsSet = new Set(connections.value.keys());
        const currentConnectionsArray = Array.from(currentConnectionsSet);
        
        // Get panel connections data
        const currentPanel = panel.value;
        const currentConnections = currentPanel?.data?.connections || [];
        
        // Validate connection IDs to ensure they exist and are strings
        const validatedConnections = validateConnectionIds(currentConnections);
        
        // Check if connections have actually changed
        if (validatedConnections.length === currentConnectionsArray.length && 
            validatedConnections.every(id => currentConnectionsSet.has(id))) {
          console.log(`[usePanelConnections] Connections for panel ${panelId} already match data, skipping update`);
          return;
        }
        
        // Only update if there are real changes to connections
        console.log(`[usePanelConnections] Connection changes detected for panel ${panelId}, updating`);
      }
      
      // Get panel connections data
      const currentPanel = panel.value;
      const currentConnections = currentPanel?.data?.connections || [];
      
      console.log(`[usePanelConnections] Initializing connections for panel ${panelId}`, currentConnections);
      
      // Validate connections array
      if (!Array.isArray(currentConnections)) {
        console.warn(`[usePanelConnections] Panel ${panelId} connections is not an array:`, currentConnections);
        return;
      }
      
      // Validate connection IDs to ensure they exist and are strings
      const validatedConnections = validateConnectionIds(currentConnections);
      
      // Clear existing connections and set up new ones
      connections.value.clear();
      
      // Set up connections based on current panel data
      validatedConnections.forEach(targetId => {
        if (targetId === panelId) return; // Skip self-connections
        
        console.log(`[usePanelConnections] Adding connection from ${panelId} to ${targetId}`);
        
        connections.value.set(targetId, {
          sourceId: panelId,
          targetId,
          active: true
        });
        
        // Register this connection in dockStore
        dockStore.registerConnection(panelId, targetId);
      });
      
      // Mark this panel as initialized
      dockStore.markPanelInitialized(panelId);
      
      console.log(`[usePanelConnections] Initialized ${connections.value.size} connections for panel ${panelId}`);
    } catch (error) {
      console.error(`[usePanelConnections] Error initializing connections for panel ${panelId}:`, error);
    }
  }
  
  // Helper function to validate and sanitize connection IDs
  const validateConnectionIds = (connectionIds: any[]): string[] => {
    if (!connectionIds || !Array.isArray(connectionIds)) return []
    
    const allPanels = getAllPanels() || []
    const allPanelIds = new Set(allPanels.map(p => p.name))
    
    const validConnectionIds = connectionIds
      .filter(id => typeof id === 'string' && id !== '')
      .filter(id => {
        const valid = allPanelIds.has(id)
        if (!valid) {
          console.warn(`[usePanelConnections] Panel ${id} not found in layout, removing from connections`)
        }
        return valid
      })
    
    if (validConnectionIds.length !== connectionIds.length) {
      console.warn(`[usePanelConnections] Some connection IDs were filtered out because they were invalid or didn't exist`, {
        before: connectionIds,
        after: validConnectionIds
      })
    }
    
    return validConnectionIds
  }
  
  // Auto-connect to default connection types
  const autoConnectDefaults = () => {
    if (!defaultConnectionTypes.value.length) return
    
    const allPanels = getAllPanels() || []
    
    // For each default connection type, find a panel that matches
    defaultConnectionTypes.value.forEach(defaultType => {
      // Already have a connection to this tool type?
      const hasConnectionToType = Array.from(connections.value.entries())
        .some(([id]) => {
          const connectedPanel = allPanels.find(p => p.name === id)
          const connectedTool = tools.find(t => t.uid === connectedPanel?.data?.tool_uid)
          return connectedTool?.id === defaultType
        })
        
      if (hasConnectionToType) return // Already connected to this type
      
      // Find a panel of this type to connect to
      const targetPanel = allPanels.find(p => {
        if (p.name === panelId) return false // Don't connect to self
        
        const panelToolConfig = tools.find(tool => tool.uid === p.data?.tool_uid)
        return panelToolConfig?.id === defaultType
      })
      
      if (targetPanel) {
        connect(targetPanel.name)
      }
    })
  }
  
  const sendDataToConnections = async (data: any, autoTransform: boolean = false) => {
    // If autoTransform is enabled, get the transformer
    let transformData: ((data: any, sourceToolId: string, targetToolId: string) => Promise<any> | any) | undefined
    
    if (autoTransform) {
      const { transformData: transformer } = usePanelDataTransformers()
      transformData = transformer
    }
    
    // Log the data being sent
    console.log('Sending data to connections:', data)
    
    // Make sure we have valid connections
    if (connections.value.size === 0) {
      console.log(`[usePanelConnections] No active connections for panel ${panelId}, skipping data send`)
      return
    }
    
    // Process each connection sequentially with async/await
    for (const targetId of Array.from(connections.value.keys())) {
      // Get source and target tool IDs
      const sourceToolId = panelTool.value?.id || ''
      let targetToolId = ''
      
      // Find the target panel's tool ID
      const targetPanel = getAllPanels()?.find(p => p.name === targetId)
      if (targetPanel?.data?.tool_uid) {
        const targetTool = tools.find(tool => tool.uid === targetPanel.data.tool_uid)
        targetToolId = targetTool?.id || ''
      }
      
      // Add useful debug logging
      console.log(`[usePanelConnections] Sending data from ${panelId} (${sourceToolId}) to ${targetId} (${targetToolId})`)
      
      // Transform data if autoTransform is enabled and both tool IDs are available
      let finalData = data
      if (autoTransform && transformData && sourceToolId && targetToolId) {
        try {
          console.log(`[usePanelConnections] Transforming data from ${sourceToolId} to ${targetToolId}`)
          finalData = await transformData(data, sourceToolId, targetToolId)
          console.log(`[usePanelConnections] Data transformed successfully:`, finalData)
        } catch (error) {
          console.error(`Error transforming data from ${sourceToolId} to ${targetToolId}:`, error)
        }
      }
      
      // Ensure data has a type field that matches the source tool
      // This helps the target component identify what kind of data it's receiving
      if (typeof finalData === 'object' && finalData !== null) {
        // If the data doesn't already have a type field, add one
        if (!finalData.type) {
          finalData = {
            ...finalData,
            type: sourceToolId
          }
        }
      }
      
      // Make sure the data is properly wrapped for sending
      const eventData = {
        sourcePanelId: panelId,
        targetPanelId: targetId,
        sourceToolId: sourceToolId,
        targetToolId,
        data: finalData
      }
      
      try {
        mitter.emit('panel:data:send', eventData)
        console.log(`[usePanelConnections] Data sent successfully to ${targetId}`)
      } catch (error) {
        console.error(`[usePanelConnections] Error sending data to ${targetId}:`, error)
      }
    }
  }
  
  // Rename onDataReceived to handlePanelDataEvent
  const handlePanelDataEvent = (callback: (data: { 
    sourcePanelId: string, 
    sourceToolId: string,
    data: any 
  }) => void) => {
    // Create the event handler function
    const eventHandler = (event: any) => {
      if (event.targetPanelId === panelId) {
        callback({
          sourcePanelId: event.sourcePanelId,
          sourceToolId: event.sourceToolId,
          data: event.data
        })
      }
    }
    
    // Register the event listener
    mitter.listen('panel:data:received', eventHandler)
  }
  
  // Initialize on component mount
  onMounted(() => {
    // Try to get the layout store if we don't have it yet
    if (!layoutStoreRef.value) {
      layoutStoreRef.value = getLayoutStore()
    }
    
    console.log(`[usePanelConnections] onMounted for panel ${panelId}, initializing connections`)
    initializeConnections()
    autoConnectDefaults()

  })
  
  // Clean up connections and event listeners on component unmount
  onBeforeUnmount(() => {
    console.log(`[usePanelConnections] onBeforeUnmount for panel ${panelId}, cleaning up connections`)
    
    // try {
    //   // Make a copy of the connections to avoid modification during iteration
    //   const connectionsCopy = Array.from(connections.value.keys())
      
    //   // Disconnect from all connected panels
    //   connectionsCopy.forEach(targetPanelId => {
    //     console.log(`[usePanelConnections] Disconnecting panel ${panelId} from ${targetPanelId} during cleanup`)
    //     disconnect(targetPanelId)
    //   })
      
    //   // Clear connections map
    //   connections.value.clear()
      
    //   // Update panel data to reflect no connections
    //   updatePanelConnections()
      
    //   // Emit a cleanup event to notify other components
    //   try {
    //     mitter.emit('panel:removed', { panelId })
    //   } catch (error) {
    //     console.error('[usePanelConnections] Error emitting panel removed event:', error)
    //   }
      
    //   console.log(`[usePanelConnections] Cleanup complete for panel ${panelId}`)
    // } catch (error) {
    //   console.error(`[usePanelConnections] Error during cleanup for panel ${panelId}:`, error)
    // }
  })
  
  // Watch for changes to panel data and update connections with debounce
  const updateFromDataChanges = useDebounceFn(() => {
    console.log(`[usePanelConnections] Debounced update for panel ${panelId} connections`)
    if (panel.value?.data?.connections && Array.isArray(panel.value.data.connections)) {
      // Check if the connections actually changed before reinitializing
      const currentConnections = Array.from(connections.value.keys())
      const newConnections = panel.value.data.connections
      
      // Only reinitialize if the connection arrays differ
      if (newConnections.length !== currentConnections.length || 
          !newConnections.every(id => currentConnections.includes(id))) {
        console.log(`[usePanelConnections] Connections changed for panel ${panelId}, reinitializing`, {
          current: currentConnections,
          new: newConnections
        })
        initializeConnections()
      } else {
        console.log(`[usePanelConnections] No connection changes for panel ${panelId}, skipping reinitialization`)
      }
    }
  }, 300) // Increase debounce time to 300ms to reduce update frequency
  
  // Use a more targeted watch to reduce reactivity triggers
  // This creates a computed value of just the connections array and watches that specifically
  const panelConnections = computed(() => {
    // Safely return the connections array or an empty array if not available
    return panel.value?.data?.connections || []
  })
  
  // Watch the computed connections array instead of the whole panel.data.connections object
  watch(panelConnections, (newConnections) => {
    if (newConnections && Array.isArray(newConnections) && newConnections.length > 0) {
      updateFromDataChanges()
    }
  }, { deep: true })
  
  // Function to emit data to connected panels
  const emitData = (dataType: string, data: any) => {
    // Try to get the layout store if we don't have it yet
    if (!layoutStoreRef.value) {
      layoutStoreRef.value = getLayoutStore()
    }
    
    const store = layoutStoreRef.value
    if (!store) {
      console.warn('[usePanelConnections] Cannot emit data: layout store not available')
      return
    }
    
    if (!store.layoutInstance) {
      console.warn('[usePanelConnections] Cannot emit data: layout instance not ready')
      return
    }
    
    console.log(`[usePanelConnections] Emitting ${dataType} data from panel ${panelId}`)
    
    try {
      // Use a local variable for panel connections to avoid reactivity changes
      const panelConnectionsFromData = panel.value?.data?.connections || []
      
      // Only initialize if we have no connections but should have some
      if (connections.value.size === 0 && 
          panelConnectionsFromData.length > 0) {
        console.log(`[usePanelConnections] No active connections but panel has connection data, initializing`)
        initializeConnections()
      } else {
        // Use debug level if needed
        if (connections.value.size > 0) {
          console.log(`[usePanelConnections] Using existing connections (${connections.value.size})`)
        }
      }
      
      // Only log connections if there are any
      if (connections.value.size > 0) {
        // Log the current connections for debugging
        console.log(`[usePanelConnections] Current connections for panel ${panelId}:`, 
          Array.from(connections.value.keys()))
      }
      
      // Use sendDataToConnections instead of trying to access eventBus directly
      if (connections.value.size > 0) {
        sendDataToConnections({
          type: dataType,
          ...data
        }).catch(error => {
          console.error('[usePanelConnections] Error sending data to connections:', error)
        })
      }
      
      // Also emit a global event that the diagnostics panel can listen for
      try {
        mitter.emit('panel:toolData', {
          panelId: panelId,
          toolId: panelTool.value?.id || '',
          data: {
            type: dataType,
            ...data
          }
        })
      } catch (error) {
        console.error('[usePanelConnections] Error emitting global toolData event:', error)
      }
      
      console.log(`[usePanelConnections] Data emitted successfully`)
    } catch (error) {
      console.error('[usePanelConnections] Error emitting data:', error)
    }
  }
  
  // Update panel data with current connections
  const updatePanelConnections = () => {
    const activeConnectionIds = Array.from(connections.value.keys())
    
    console.log(`[usePanelConnections] Updating panel ${panelId} connections:`, activeConnectionIds)
    
    const updatedPanel = getAllPanels()?.find(p => p.name === panelId)
    if (!updatedPanel) {
      console.warn(`[usePanelConnections] Panel ${panelId} not found in layout`)
      return
    }
    
    // Update panel data with connections using object spreading to preserve existing data
    if (!updatedPanel.data) {
      updatedPanel.data = {
        tool_uid: panelTool.value?.uid || '',
        connections: activeConnectionIds
      }
    } else {
      // Use object spreading to preserve all existing data
      updatedPanel.data = {
        ...updatedPanel.data,
        connections: activeConnectionIds
      }
    }
    
    console.log(`[usePanelConnections] Updated panel data:`, updatedPanel.data)
    
    // Force a layout refresh
    if (!layoutStoreRef.value) {
      layoutStoreRef.value = getLayoutStore()
    }
    
    const rootGrid = layoutInstance.value?.getRootGrid()
    if (rootGrid) {
      rootGrid.notifyRelayout()
    }
  }
  
  return {
    connections,
    panel,
    panelTool,
    allowedConnectionTypes,
    defaultConnectionTypes,
    availableConnectionPanels,
    connectedPanelsData,
    getConnectedPanelData,
    getAllPanels,
    connect,
    disconnect,
    toggleConnection,
    sendDataToConnections,
    handlePanelDataEvent,
    emitData
  }
} 