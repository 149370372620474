/**
 * HDBSCAN - Hierarchical Density-Based Spatial Clustering of Applications with Noise
 * 
 * @author Adapted from Python implementation by Leland McInnes
 * @copyright MIT
 */

const KDTREE_VALID_METRICS = ['euclidean', 'l2', 'minkowski', 'p', 'manhattan', 'cityblock', 'l1', 'chebyshev', 'infinity']
const BALLTREE_VALID_METRICS = [...KDTREE_VALID_METRICS, 
  'braycurtis', 'canberra', 'dice', 'hamming', 'haversine', 'jaccard',
  'mahalanobis', 'rogerstanimoto', 'russellrao', 'seuclidean',
  'sokalmichener', 'sokalsneath'
]
const FAST_METRICS = [...KDTREE_VALID_METRICS, ...BALLTREE_VALID_METRICS, 'cosine', 'arccos']

class HDBSCAN {
  dataset: number[][]
  minClusterSize: number
  minSamples: number | null
  alpha: number
  metric: string
  p: number
  algorithm: string
  clusters: number[][]
  noise: number[]
  labels_: number[]
  probabilities_: number[]
  
  constructor(
    dataset: number[][],
    minClusterSize = 5,
    minSamples = null,
    alpha = 1.0,
    metric = 'euclidean',
    p = 2,
    algorithm = 'best'
  ) {
    this.dataset = dataset
    this.minClusterSize = Math.max(2, minClusterSize)
    this.minSamples = minSamples || Math.min(minClusterSize, 5)
    this.alpha = alpha
    this.metric = metric
    this.p = p
    this.algorithm = algorithm
    this.clusters = []
    this.noise = []
    this.labels_ = []
    this.probabilities_ = []
  }

  fit() {
    try {
      // Input validation
      if (!Array.isArray(this.dataset)) {
        throw new Error('Dataset must be an array')
      }

      if (this.dataset.length === 0) {
        throw new Error('Dataset cannot be empty')
      }

      if (!this.dataset.every(point => Array.isArray(point))) {
        throw new Error('All points must be arrays')
      }

      const X = this.dataset
      const size = X.length

      // Initialize min_samples
      this.minSamples = Math.min(size - 1, this.minSamples || 0)
      if (this.minSamples === 0) this.minSamples = 1

      // Calculate distance matrix based on metric
      const distanceMatrix = this._calculateDistanceMatrix()

      // Calculate mutual reachability
      const mutualReachability = this._calculateMutualReachability(distanceMatrix)

      // Build minimum spanning tree
      const mst = this._buildMinimumSpanningTree(mutualReachability)

      // Extract clusters from MST
      const { labels, probabilities } = this._extractClusters(mst)
      
      this.labels_ = labels
      this.probabilities_ = probabilities

      return this
    } catch (error) {
      console.error('HDBSCAN fitting error:', error)
      // Initialize empty results in case of error
      this.labels_ = Array(this.dataset.length).fill(-1)
      this.probabilities_ = Array(this.dataset.length).fill(0)
      throw error
    }
  }

  private _calculateDistanceMatrix(): number[][] {
    try {
      const size = this.dataset.length
      const distanceMatrix = Array(size).fill(0).map(() => Array(size).fill(0))

      // Calculate pairwise distances based on chosen metric
      for (let i = 0; i < size; i++) {
        for (let j = i + 1; j < size; j++) {
          let distance: number
          
          switch (this.metric) {
            case 'euclidean':
            case 'l2':
            case 'minkowski':
              distance = this._euclideanDistance(this.dataset[i], this.dataset[j])
              break
            case 'manhattan':
            case 'cityblock':
            case 'l1':
              distance = this._manhattanDistance(this.dataset[i], this.dataset[j])
              break
            case 'cosine':
              distance = this._cosineDistance(this.dataset[i], this.dataset[j])
              break
            default:
              distance = this._euclideanDistance(this.dataset[i], this.dataset[j])
          }

          // Distance matrix is symmetric
          distanceMatrix[i][j] = distance
          distanceMatrix[j][i] = distance
        }
        // Set diagonal to 0
        distanceMatrix[i][i] = 0
      }

      return distanceMatrix
    } catch (error) {
      console.error('Error calculating distance matrix:', error)
      throw error
    }
  }

  private _calculateMutualReachability(distanceMatrix: number[][]): number[][] {
    const size = this.dataset.length
    const mutualReachability = Array(size).fill(0).map(() => Array(size).fill(0))
    
    // Calculate core distances (distance to minSamples-nearest neighbor)
    const coreDistances = this._calculateCoreDistances(distanceMatrix)

    // Calculate mutual reachability distances
    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        // Mutual reachability distance is the maximum of:
        // 1. The core distance of point i
        // 2. The core distance of point j
        // 3. The original distance between i and j
        mutualReachability[i][j] = Math.max(
          Math.max(coreDistances[i], coreDistances[j]),
          distanceMatrix[i][j]
        )
      }
    }

    return mutualReachability
  }

  private _calculateCoreDistances(distanceMatrix: number[][]): number[] {
    const size = this.dataset.length
    const coreDistances = Array(size).fill(0)

    for (let i = 0; i < size; i++) {
      // Get distances to all other points
      const distances = distanceMatrix[i].slice()
      // Sort distances in ascending order
      distances.sort((a, b) => a - b)
      // Core distance is distance to minSamples-nearest neighbor
      // Add 1 because the first distance is always 0 (distance to self)
      coreDistances[i] = distances[this.minSamples]
    }

    return coreDistances
  }

  private _manhattanDistance(p: number[], q: number[]): number {
    let sum = 0
    const len = Math.min(p.length, q.length)
    
    for (let i = 0; i < len; i++) {
      sum += Math.abs(p[i] - q[i])
    }
    
    return sum
  }

  private _cosineDistance(p: number[], q: number[]): number {
    let dotProduct = 0
    let normP = 0
    let normQ = 0
    const len = Math.min(p.length, q.length)
    
    for (let i = 0; i < len; i++) {
      dotProduct += p[i] * q[i]
      normP += p[i] * p[i]
      normQ += q[i] * q[i]
    }
    
    normP = Math.sqrt(normP)
    normQ = Math.sqrt(normQ)
    
    if (normP === 0 || normQ === 0) return 1
    
    // Return cosine distance (1 - cosine similarity)
    return 1 - (dotProduct / (normP * normQ))
  }

  private _buildMinimumSpanningTree(mutualReachability: number[][]): {
    edges: [number, number, number][],
    weights: number[]
  } {
    const size = this.dataset.length
    const edges: [number, number, number][] = []
    const weights: number[] = []
    
    // Initialize disjoint set for tracking connected components
    const parent = Array(size).fill(0).map((_, i) => i)
    const rank = Array(size).fill(0)

    // Create list of all edges with weights
    const allEdges: [number, number, number][] = []
    for (let i = 0; i < size; i++) {
      for (let j = i + 1; j < size; j++) {
        allEdges.push([i, j, mutualReachability[i][j]])
      }
    }

    // Sort edges by weight
    allEdges.sort((a, b) => a[2] - b[2])

    // Kruskal's algorithm for MST
    for (const [u, v, weight] of allEdges) {
      if (this._union(parent, rank, u, v)) {
        edges.push([u, v, weight])
        weights.push(weight)
      }
    }

    return { edges, weights }
  }

  private _find(parent: number[], i: number): number {
    if (parent[i] !== i) {
      parent[i] = this._find(parent, parent[i])
    }
    return parent[i]
  }

  private _union(parent: number[], rank: number[], x: number, y: number): boolean {
    const xRoot = this._find(parent, x)
    const yRoot = this._find(parent, y)

    if (xRoot === yRoot) return false

    if (rank[xRoot] < rank[yRoot]) {
      parent[xRoot] = yRoot
    } else if (rank[xRoot] > rank[yRoot]) {
      parent[yRoot] = xRoot
    } else {
      parent[yRoot] = xRoot
      rank[xRoot]++
    }

    return true
  }

  private _extractClusters(mst: { edges: [number, number, number][], weights: number[] }): {
    labels: number[],
    probabilities: number[]
  } {
    const size = this.dataset.length
    const labels = Array(size).fill(-1)
    const probabilities = Array(size).fill(0)

    const sortedEdges = [...mst.edges].sort((a, b) => a[2] - b[2])
    const clusters = new Map<number, Set<number>>()
    for (let i = 0; i < size; i++) {
      clusters.set(i, new Set([i]))
    }

    const avgWeight = mst.weights.reduce((a, b) => a + b, 0) / mst.weights.length
    const weightThreshold = avgWeight * 1.5 * this.alpha

    for (const [u, v, weight] of sortedEdges) {
      if (weight > weightThreshold * 1.2) continue

      const clusterU = this._findCluster(clusters, u)
      const clusterV = this._findCluster(clusters, v)

      if (clusterU !== clusterV) {
        const mergedPoints = new Set([...clusters.get(clusterU)!, ...clusters.get(clusterV)!])
        
        if (mergedPoints.size >= this.minClusterSize) {
          clusters.set(clusterU, mergedPoints)
          clusters.delete(clusterV)

          const clusterDensity = this._calculateClusterDensity(Array.from(mergedPoints))
          
          for (const point of mergedPoints) {
            labels[point] = clusterU
            probabilities[point] = Math.min(1, (clusterDensity * Math.sqrt(mergedPoints.size)) / Math.sqrt(size))
          }
        }
      }
    }

    const finalLabels = Array(size).fill(-1)
    let currentLabel = 0
    const seenClusters = new Set<number>()

    for (let i = 0; i < size; i++) {
      const label = labels[i]
      if (label === -1) continue

      if (!seenClusters.has(label)) {
        seenClusters.add(label)
        finalLabels[i] = currentLabel
        
        for (let j = i + 1; j < size; j++) {
          if (labels[j] === label) {
            finalLabels[j] = currentLabel
          }
        }
        currentLabel++
      }
    }

    return { labels: finalLabels, probabilities }
  }

  private _findCluster(clusters: Map<number, Set<number>>, point: number): number {
    for (const [clusterId, points] of clusters.entries()) {
      if (points.has(point)) return clusterId
    }
    return -1
  }

  // Helper methods from DBSCAN that might be useful
  private _euclideanDistance(p: number[], q: number[]): number {
    let sum = 0
    const len = Math.min(p.length, q.length)
    
    for (let i = 0; i < len; i++) {
      const diff = p[i] - q[i]
      sum += diff * diff
    }
    
    return Math.sqrt(sum)
  }

  // Add a method to get cluster statistics
  getClusterStats(): { 
    numClusters: number; 
    noisyPoints: number;
    clusterSizes: number[];
  } {
    const uniqueLabels = new Set(this.labels_)
    const numClusters = uniqueLabels.size - (uniqueLabels.has(-1) ? 1 : 0)
    const noisyPoints = this.labels_.filter(label => label === -1).length
    
    const clusterSizes = Array.from(uniqueLabels)
      .filter(label => label !== -1)
      .map(label => this.labels_.filter(l => l === label).length)

    return {
      numClusters,
      noisyPoints,
      clusterSizes
    }
  }

  // Add new helper method to calculate cluster density
  private _calculateClusterDensity(clusterPoints: number[]): number {
    if (clusterPoints.length < 2) return 0

    let totalDistance = 0
    let count = 0

    // Calculate average pairwise distance within cluster
    for (let i = 0; i < clusterPoints.length; i++) {
      for (let j = i + 1; j < clusterPoints.length; j++) {
        totalDistance += this._euclideanDistance(
          this.dataset[clusterPoints[i]], 
          this.dataset[clusterPoints[j]]
        )
        count++
      }
    }

    const avgDistance = totalDistance / count
    // Convert distance to density (inverse relationship)
    return 1 / (avgDistance + 1e-6) // Add small epsilon to avoid division by zero
  }
}

export { HDBSCAN } 