<script setup lang="ts">
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import DragDataPlugin from 'chartjs-plugin-dragdata'

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  DragDataPlugin
)

// Define types for our data points
interface CostPoint {
  volume: number
  cost: number
}

interface CostSeries {
  id: string
  name: string
  color: string
  points: CostPoint[]
}

const props = defineProps<{
  modelValue: CostSeries[]
  minVolume?: number
  maxVolume?: number
}>()

const emit = defineEmits<{
  'update:modelValue': [value: CostSeries[]]
}>()

// Default min/max values
const minVolume = computed(() => props.minVolume ?? 25)
const maxVolume = computed(() => props.maxVolume ?? 250)

// Active series for highlighting
const activeSeries = ref<string | null>(null)

// Toggle series visibility
const toggleSeries = (seriesId: string) => {
  activeSeries.value = activeSeries.value === seriesId ? null : seriesId
}

// Get interpolated data points for smoother curves
const getInterpolatedPoints = (points: CostPoint[], steps = 50) => {
  if (points.length < 2) {
    // If less than 2 points, just return direct mapping
    return points.map(p => ({ x: p.volume, y: p.cost }))
  }
  
  // Sort points by volume
  const sortedPoints = [...points].sort((a, b) => a.volume - b.volume)
  
  // Create a new array with interpolated points
  const result: {x: number, y: number, __isOriginal?: boolean}[] = []
  
  // First add all original points marked accordingly
  sortedPoints.forEach(p => {
    result.push({ 
      x: p.volume, 
      y: p.cost,
      __isOriginal: true // Mark original points for later reference
    })
  })
  
  // Process each adjacent point pair for interpolation
  for (let i = 0; i < sortedPoints.length - 1; i++) {
    const startPoint = sortedPoints[i]
    const endPoint = sortedPoints[i + 1]
    
    // Skip interpolation if points are too close
    if (endPoint.volume - startPoint.volume < 5) continue
    
    // Calculate step size
    const volumeStep = (endPoint.volume - startPoint.volume) / steps
    
    // Generate points between each pair
    for (let step = 1; step < steps; step++) {
      const volume = startPoint.volume + volumeStep * step
      
      // Use power law interpolation
      const factor = (volume - startPoint.volume) / (endPoint.volume - startPoint.volume)
      const cost = startPoint.cost * Math.pow(endPoint.cost / startPoint.cost, factor)
      
      result.push({
        x: volume,
        y: cost
      })
    }
  }
  
  // Sort the final array by x-value
  return result.sort((a, b) => a.x - b.x)
}

// Chart data
const chartData = computed(() => ({
  datasets: props.modelValue.map(series => ({
    label: series.name,
    data: getInterpolatedPoints(series.points),
    borderColor: series.color,
    backgroundColor: `${series.color}10`,
    tension: 0.3,
    fill: activeSeries.value === series.id,
    borderWidth: activeSeries.value === series.id || activeSeries.value === null ? 2 : 1,
    pointRadius: (ctx: any) => {
      // Show points only for original data points (not interpolated)
      return ctx.raw?.__isOriginal ? 
        (activeSeries.value === series.id || activeSeries.value === null ? 7 : 3) : 
        0
    },
    pointHoverRadius: (ctx: any) => ctx.raw?.__isOriginal ? 7 : 0,
    pointBackgroundColor: series.color,
    pointBorderColor: '#fff',
    pointBorderWidth: 1.5,
    pointHitRadius: 10
  }))
}))

// Chart options
const chartOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'nearest' as const,
    axis: 'xy' as const
  },
  elements: {
    point: {
      hitRadius: 12,
      hoverRadius: 8
    },
    line: {
      tension: 0.3,
      borderWidth: 2
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      titleColor: '#334155',
      bodyColor: '#334155',
      borderColor: '#E2E8F0',
      borderWidth: 1,
      padding: 8,
      position: 'nearest',
      displayColors: false,
      caretPadding: 12,
      yAlign: 'bottom',
      xAlign: 'center',
      caretSize: 8,
      callbacks: {
        title: (context: any[]) => {
          return `Volume: ${context[0].parsed.x.toFixed(0)}L`
        },
        label: (context: any) => {
          return `${context.dataset.label}: $${context.parsed.y.toFixed(2)}`
        }
      }
    },
    dragData: {
      round: 1,
      dragX: true,
      dragY: true,
      showTooltip: true,
      dragDisplayOffset: {
        x: 0,
        y: -40
      },
      magnet: {
        to: (value: any) => ({
          x: Math.max(minVolume.value, Math.min(maxVolume.value, Math.round(value.x))),
          y: Math.max(0, Math.round(value.y * 100) / 100)
        })
      },
      onDragStart: function(e: any) {
        if (e?.target) e.target.style.cursor = 'grabbing'
        
        // Chart.js v3+ might provide element info differently
        const datasetIndex = e?.datasetIndex ?? e?.element?._datasetIndex
        
        // Only activate a series if we can identify which one is being dragged
        if (datasetIndex !== undefined && props.modelValue[datasetIndex]) {
          activeSeries.value = props.modelValue[datasetIndex].id
        }
      },
      onDrag: function(e: any, datasetIndex: any, index: any, value: any) {
        // Early exit if we don't have the required data
        if (!value || datasetIndex === undefined || datasetIndex >= props.modelValue.length) return
        
        const series = props.modelValue[datasetIndex]
        if (!series || !series.points?.length) return
        
        // Make a copy of the data for immutable updates
        const newData = [...props.modelValue]
        
        // Find which original point this is closest to
        let volume = Math.max(minVolume.value, Math.min(maxVolume.value, Math.round(value.x)))
        const cost = Math.max(0, Math.round(value.y * 100) / 100)
        
        // Either use the index directly if it's within bounds
        let pointIndex = index
        
        // Or find the closest point by volume if index is invalid
        if (pointIndex === undefined || pointIndex < 0 || pointIndex >= series.points.length) {
          let closestDist = Infinity
          
          series.points.forEach((point, idx) => {
            const dist = Math.abs(point.volume - volume)
            if (dist < closestDist) {
              closestDist = dist
              pointIndex = idx
            }
          })
        }
        
        // Update the point if we found a valid index
        if (pointIndex !== undefined && pointIndex >= 0 && pointIndex < newData[datasetIndex].points.length) {
          // Sort points to determine if this is the first or last point
          const sortedPoints = [...newData[datasetIndex].points].sort((a, b) => a.volume - b.volume)
          
          // If this is the last point by volume, force it to maxVolume
          if (pointIndex === newData[datasetIndex].points.length - 1 || 
              newData[datasetIndex].points[pointIndex].volume === sortedPoints[sortedPoints.length - 1].volume) {
            volume = maxVolume.value
          } 
          
          // If this is the first point by volume, force it to minVolume
          if (pointIndex === 0 || 
              newData[datasetIndex].points[pointIndex].volume === sortedPoints[0].volume) {
            volume = minVolume.value
          }
          
          newData[datasetIndex].points[pointIndex] = { volume, cost }
          
          // Sort points by volume after updating
          newData[datasetIndex].points.sort((a, b) => a.volume - b.volume)
          
          emit('update:modelValue', newData)
        }
      },
      onDragEnd: function(e: any) {
        if (e?.target) e.target.style.cursor = 'default'
      }
    }
  },
  scales: {
    x: {
      type: 'linear' as const,
      min: minVolume.value,
      max: maxVolume.value,
      grid: {
        color: '#E2E8F0',
        drawBorder: false
      },
      ticks: {
        stepSize: 50,
        color: '#64748B',
        font: {
          size: 11
        },
        callback: (value: number) => `${value}L`
      },
      title: {
        display: true,
        text: 'Volume (L)',
        color: '#64748B',
        font: {
          size: 12,
          weight: '500'
        },
        padding: {
          top: 10
        }
      }
    },
    y: {
      type: 'linear' as const,
      min: 0,
      grid: {
        color: '#E2E8F0',
        drawBorder: false
      },
      ticks: {
        stepSize: 20,
        color: '#64748B',
        font: {
          size: 11
        },
        callback: (value: number) => `$${value}`
      },
      title: {
        display: true,
        text: 'Cost ($)',
        color: '#64748B',
        font: {
          size: 12,
          weight: '500'
        },
        padding: {
          bottom: 10
        }
      }
    }
  }
}))

// Function to add a point to the active series or all series
const addPoint = (forAll = false) => {
  const newData = [...props.modelValue]
  
  // If forAll is true, add a point to all series
  // Otherwise, add a point to the active series or first series if none is active
  const seriesToUpdate = forAll 
    ? newData 
    : [newData.find(s => s.id === activeSeries.value) || newData[0]]
  
  seriesToUpdate.forEach(series => {
    if (!series) return
    
    // Find a good spot for the new point
    const sortedPoints = [...series.points].sort((a, b) => a.volume - b.volume)
    
    let newVolume, newCost
    
    if (sortedPoints.length === 0) {
      // If no points, add at min volume
      newVolume = minVolume.value
      newCost = 50 // Default cost
    } else if (sortedPoints.length === 1) {
      // If only one point, check its position
      const existingPoint = sortedPoints[0]
      
      if (existingPoint.volume === minVolume.value) {
        // If point is at min, add at max
        newVolume = maxVolume.value
        newCost = existingPoint.cost * 2
      } else if (existingPoint.volume === maxVolume.value) {
        // If point is at max, add at min
        newVolume = minVolume.value
        newCost = existingPoint.cost / 2
      } else {
        // If point is somewhere in between, add at max
        newVolume = maxVolume.value
        newCost = existingPoint.cost * 1.5
      }
    } else {
      // Find the largest gap between points
      let maxGap = 0
      let gapStart = sortedPoints[0].volume
      
      for (let i = 0; i < sortedPoints.length - 1; i++) {
        const gap = sortedPoints[i + 1].volume - sortedPoints[i].volume
        if (gap > maxGap) {
          maxGap = gap
          gapStart = sortedPoints[i].volume
        }
      }
      
      // Place new point in the middle of the largest gap
      newVolume = Math.round(gapStart + maxGap / 2)
      
      // Find the points on either side of the gap
      const startPoint = sortedPoints.find(p => p.volume === gapStart)!
      const endPoint = sortedPoints.find(p => p.volume === gapStart + maxGap)!
      
      // Interpolate cost using power law interpolation
      const factor = 0.5 // Middle of the gap
      newCost = startPoint.cost * Math.pow(endPoint.cost / startPoint.cost, factor)
    }
    
    // Add the new point
    series.points.push({
      volume: newVolume,
      cost: newCost
    })
    
    // Ensure points are sorted by volume after adding
    series.points.sort((a, b) => a.volume - b.volume)
  })
  
  emit('update:modelValue', newData)
}

// Function to remove a point from the active series or all series
const removePoint = (forAll = false) => {
  const newData = [...props.modelValue]
  
  // If forAll is true, remove a point from all series
  // Otherwise, remove a point from the active series or first series if none is active
  const seriesToUpdate = forAll 
    ? newData 
    : [newData.find(s => s.id === activeSeries.value) || newData[0]]
  
  seriesToUpdate.forEach(series => {
    if (!series || series.points.length <= 2) return // Keep at least 2 points
    
    // Remove the last point
    series.points.pop()
    
    // If we now have exactly 2 points, ensure they're at the min and max volume
    if (series.points.length === 2) {
      // Sort points by volume
      const sortedPoints = [...series.points].sort((a, b) => a.volume - b.volume)
      
      // Set first point to min volume
      series.points[0] = { volume: minVolume.value, cost: sortedPoints[0].cost }
      
      // Set second point to max volume
      series.points[1] = { volume: maxVolume.value, cost: sortedPoints[1].cost }
    }
  })
  
  emit('update:modelValue', newData)
}

// New function to cycle points between 2 and 5
const cyclePoints = (forAll = false) => {
  const newData = [...props.modelValue]
  
  // If forAll is true, cycle all series
  // Otherwise, cycle the active series or first series if none is active
  const seriesToUpdate = forAll 
    ? newData 
    : [newData.find(s => s.id === activeSeries.value) || newData[0]]
  
  seriesToUpdate.forEach(series => {
    if (!series) return
    
    // Get current number of points
    const numPoints = series.points.length
    
    if (numPoints >= 5) {
      // Reset to 2 points at min and max volume
      const minPoint = series.points.find(p => p.volume === minVolume.value) || 
                      { volume: minVolume.value, cost: series.points[0]?.cost || 50 }
      
      const maxPoint = series.points.find(p => p.volume === maxVolume.value) ||
                      { volume: maxVolume.value, cost: series.points[series.points.length-1]?.cost || 100 }
      
      // Set to just two points
      series.points = [minPoint, maxPoint]
    } else {
      // Add one point in the largest gap
      const sortedPoints = [...series.points].sort((a, b) => a.volume - b.volume)
      
      // Always make sure first point is at minVolume and last is at maxVolume
      if (sortedPoints[0].volume !== minVolume.value) {
        sortedPoints[0].volume = minVolume.value
      }
      
      if (sortedPoints[sortedPoints.length - 1].volume !== maxVolume.value) {
        sortedPoints[sortedPoints.length - 1].volume = maxVolume.value
      }
      
      // Find the largest gap between points
      let maxGap = 0
      let gapIndex = 0
      
      for (let i = 0; i < sortedPoints.length - 1; i++) {
        const gap = sortedPoints[i + 1].volume - sortedPoints[i].volume
        if (gap > maxGap) {
          maxGap = gap
          gapIndex = i
        }
      }
      
      // Calculate new point position
      const newVolume = Math.round(sortedPoints[gapIndex].volume + maxGap / 2)
      
      // Use power law interpolation for the cost
      const p1 = sortedPoints[gapIndex]
      const p2 = sortedPoints[gapIndex + 1]
      const factor = (newVolume - p1.volume) / (p2.volume - p1.volume)
      const newCost = Math.round(p1.cost * Math.pow(p2.cost / p1.cost, factor) * 100) / 100
      
      // Add the new point
      series.points.push({ volume: newVolume, cost: newCost })
      
      // Ensure points are sorted by volume
      series.points.sort((a, b) => a.volume - b.volume)
    }
  })
  
  emit('update:modelValue', newData)
}

// Reset the active series or all series to defaults
const resetSeries = (forAll = false) => {
  const newData = [...props.modelValue]
  
  // If forAll is true, reset all series
  // Otherwise, reset the active series or first series if none is active
  const seriesToUpdate = forAll 
    ? newData 
    : [newData.find(s => s.id === activeSeries.value) || newData[0]]
  
  seriesToUpdate.forEach(series => {
    if (!series) return
    
    // Default to points at min, mid, and max volume with power law relationship
    series.points = [
      { volume: minVolume.value, cost: series.points[0]?.cost || 50 }, // Min volume point
      { volume: Math.round((minVolume.value + maxVolume.value) / 2), cost: series.points[0]?.cost * 1.5 || 75 }, // Mid point
      { volume: maxVolume.value, cost: series.points[0]?.cost * 2 || 100 } // Max volume point
    ]
  })
  
  emit('update:modelValue', newData)
}

// Select the relationship type for a series
const relationshipTypes = [
  { id: 'linear', name: 'Linear', icon: 'proicons:line-diagonal', tooltip: 'Linear' },
  { id: 'power', name: 'Power Law', icon: 'fad:softclipcurve', tooltip: 'Power law' },
  { id: 'exponential', name: 'Exponential', icon: 'mdi:exponent', tooltip: 'Exponential' }
]

const selectedRelationships = ref(props.modelValue.map(() => 'power'))
const globalRelationship = ref('power')

// New function to cycle through relationship types
const cycleRelationship = (forAll = false) => {
  const currentIdx = relationshipTypes.findIndex(r => r.id === globalRelationship.value)
  const nextIdx = (currentIdx + 1) % relationshipTypes.length
  const nextType = relationshipTypes[nextIdx].id
  
  // Apply to all series if forAll is true, or just the active series
  applyRelationship(forAll ? null : props.modelValue.findIndex(s => s.id === activeSeries.value), nextType)
  globalRelationship.value = nextType
}

// Apply relationship function that can apply to a single series or all series
const applyRelationship = (seriesIndex: number | null, relationshipType: string) => {
  const newSeries = [...props.modelValue]
  
  // Determine which series to update
  const indicesToUpdate = seriesIndex === null 
    ? newSeries.map((_, i) => i) // Apply to all series
    : [seriesIndex] // Apply to just one series
  
  indicesToUpdate.forEach(idx => {
    const series = newSeries[idx]
    if (!series || series.points.length < 2) return
    
    const points = [...series.points].sort((a, b) => a.volume - b.volume)
    
    // Get the first and last points to preserve the range
    const firstPoint = { ...points[0], volume: minVolume.value }
    const lastPoint = { ...points[points.length - 1], volume: maxVolume.value }
    
    // Generate new points with the selected relationship
    const newPoints = [firstPoint]
    
    // Generate intermediate points
    for (let i = 1; i < points.length - 1; i++) {
      const ratio = i / (points.length - 1)
      const volume = minVolume.value + (maxVolume.value - minVolume.value) * ratio
      let cost
      
      switch (relationshipType) {
        case 'linear':
          cost = firstPoint.cost + (lastPoint.cost - firstPoint.cost) * (volume - firstPoint.volume) / (lastPoint.volume - firstPoint.volume)
          break
        case 'power':
          const exponent = Math.log(lastPoint.cost / firstPoint.cost) / Math.log(lastPoint.volume / firstPoint.volume)
          cost = firstPoint.cost * Math.pow(volume / firstPoint.volume, exponent)
          break
        case 'exponential':
          const logFactor = Math.log(lastPoint.cost / firstPoint.cost) / (lastPoint.volume - firstPoint.volume)
          cost = firstPoint.cost * Math.exp(logFactor * (volume - firstPoint.volume))
          break
        default:
          cost = firstPoint.cost + (lastPoint.cost - firstPoint.cost) * ratio
      }
      
      newPoints.push({ volume, cost: Math.round(cost * 100) / 100 })
    }
    
    // Add the last point
    newPoints.push(lastPoint)
    
    // Replace the points but keep the same number of points
    newSeries[idx].points = newPoints
    selectedRelationships.value[idx] = relationshipType
  })
  
  emit('update:modelValue', newSeries)
}

// Helper to get current relationship icon
const getCurrentRelationshipIcon = computed(() => {
  const rel = relationshipTypes.find(r => r.id === globalRelationship.value)
  return rel?.icon || 'lucide:trending-up'
})
</script>

<template>
  <div class="">
    
    <!-- Global controls -->
    <div class="flex items-center gap-2 mb-4">
      <V-Tooltip content="Number of points">
      <Button
        variant="ghost"
        size="sm"
        class="h-8 px-3 text-sm flex items-center gap-1 font-intervariable rounded-full"
        @click="cyclePoints(true)"
        title="Cycle between 2-5 points (resets to 2 after 5)"
      >
        <Icon name="material-symbols:fiber-manual-record" class="h-4 w-4" />
        <span>{{ modelValue[0]?.points.length || 0 }}</span>
      </Button>
      </V-Tooltip>
      <V-Tooltip content="Relationship type">
      <Button
        variant="ghost"
        class="h-8 rounded-full aspect-square w-8 p-2"
        @click="cycleRelationship(true)"
        title="Cycle between relationship types (Linear, Power, Exponential)"
      >
        <Icon :name="getCurrentRelationshipIcon" class="h-7 w-7" />
        <span>{{ relationshipTypes.find(r => r.id === globalRelationship.value)?.name }}</span>
      </Button>
      </V-Tooltip>
      <V-Tooltip content="Reset all series">
      <Button
        variant="ghost"
        size="sm"
        class="h-8 w-8 p-2 flex items-center justify-center rounded-full aspect-square"
        @click="resetSeries(true)"
        title="Reset all series"
      >
        <Icon name="ci:arrow-undo-up-left" class="h-10 w-10" />
      </Button>
      </V-Tooltip>
    </div>
  
    
    <!-- Chart -->
    <div class="relative">
      <div class="absolute inset-0 bg-gradient-to-b from-transparent to-background/5 pointer-events-none"></div>
      <div class="h-[300px]">
        <Line
          :data="chartData"
          :options="chartOptions"
        />
      </div>
    </div>
    
   
  </div>
</template> 