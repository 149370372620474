<script setup lang="ts">
import type { VChart } from '#components'
import { use } from 'echarts/core'
import { TreemapChart } from 'echarts/charts'
import { TooltipComponent } from 'echarts/components'
import type { AvailabilityResult, ECResult } from '@/types/validation'
import { Package, DollarSign, Ruler, MapPin } from 'lucide-vue-next'
import chroma from 'chroma-js'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { Command, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem } from '@/components/ui/command'
import { Check, ChevronsUpDown } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { Input } from '@/components/ui/input'
import { useEventListener, onClickOutside } from '@vueuse/core'
import { onUnmounted } from 'vue'

use([TreemapChart, TooltipComponent])

const props = defineProps<{
  data: Record<string, AvailabilityResult>
}>()

const chart = ref<InstanceType<typeof VChart> | null>(null)
const chartContainer = ref<HTMLElement | null>(null)
const isChartActive = ref(false)

const initOptions = ref({
  renderer: 'svg'
})

const colorMode = useColorMode()

const colors = computed(() => {
  if (colorMode.value === 'dark') {
    return {
      mutedForeground: 'hsl(90 20.2% 65.1%)',
      accent: 'hsl(160 32.6% 21.5%)',
      muted: 'hsl(160 22.6% 13.5%)',
      primary: 'hsl(90 20% 84%)',
      available: 'hsl(142.1 76.2% 36.3%)',
      unavailable: 'hsl(0 0% 100%)'
    }
  } else {
    return {
      mutedForeground: 'hsl(160 21% 36.9%)',
      accent: 'hsl(160 30% 79.1%)',
      muted: 'hsl(90 11% 91%)',
      primary: 'hsl(120 27.4% 21.2%)',
      available: 'hsl(142.1 76.2% 36.3%)',
      unavailable: 'hsl(346.8 40% 45%)'
    }
  }
})

// Tailwind pastel colors
const tailwindPastelColors = [
  'rgb(253, 186, 116)', // orange-300
  'rgb(147, 197, 253)', // blue-300
  'rgb(134, 239, 172)', // green-300
  'rgb(252, 165, 165)', // red-300
  'rgb(216, 180, 254)', // purple-300
  'rgb(253, 224, 71)',  // yellow-300
  'rgb(249, 168, 212)', // pink-300
  'rgb(125, 211, 252)', // sky-300
  'rgb(153, 246, 228)', // teal-300
  'rgb(196, 181, 253)', // indigo-300
]

// Generate a color for each species
const getSpeciesColor = (index: number, total: number) => {
  return tailwindPastelColors[index % tailwindPastelColors.length]
}

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(price)
}

const formatTooltipHtml = (data: any, isSpecies: boolean) => {
  // Return null for undefined data to hide tooltip
  if (!data || !data.name) return null;

  if (isSpecies) {
    // For species level, check if we have valid children data
    if (!data.children || !Array.isArray(data.children)) return null;
    
    const totalQuantity = data.children.reduce((sum: number, child: any) => sum + (child.value || 0), 0)
    const nurseryCount = data.children.length
    
    // Only show tooltip if we have valid counts
    if (totalQuantity === 0 || nurseryCount === 0) return null;

    return `
      <div class="tooltip-content">
        <div class="tooltip-title">${data.name}</div>
        <div class="tooltip-stats">
          <div class="tooltip-stat">
            <span class="tooltip-label">Nurseries</span>
            <span class="tooltip-value">${nurseryCount}</span>
          </div>
          <div class="tooltip-stat">
            <span class="tooltip-label">Total Stock</span>
            <span class="tooltip-value">${totalQuantity}</span>
          </div>
        </div>
      </div>
    `
  } else {
    // For nursery level, check if we have any valid data to show
    const hasValidData = data.value > 0 || data.size || data.state;
    if (!hasValidData) return null;

    const details = [];

    if (data.value && data.value !== 1) {
      details.push(`
        <div class="tooltip-detail">
          <span class="tooltip-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7.5 4.27 9 5.15"/><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"/><path d="m3.3 7 8.7 5 8.7-5"/><path d="M12 22V12"/></svg>
          </span>
          <span class="tooltip-label">Quantity</span>
          <span class="tooltip-value">${data.value}</span>
        </div>
      `);
    }

    if (data.size) {
      details.push(`
        <div class="tooltip-detail">
          <span class="tooltip-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21.3 8.7 8.7 21.3c-1 1-2.5 1-3.4 0l-2.6-2.6c-1-1-1-2.5 0-3.4L15.3 2.7c1-1 2.5-1 3.4 0l2.6 2.6c1 1 1 2.5 0 3.4Z"/><path d="m7.5 10.5 2 2"/><path d="m10.5 7.5 2 2"/><path d="m13.5 4.5 2 2"/><path d="m4.5 13.5 2 2"/></svg>
          </span>
          <span class="tooltip-label">Size</span>
          <span class="tooltip-value">${data.size}</span>
        </div>
      `);
    }

    if (data.state) {
      details.push(`
        <div class="tooltip-detail">
          <span class="tooltip-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"/><circle cx="12" cy="10" r="3"/></svg>
          </span>
          <span class="tooltip-label">State</span>
          <span class="tooltip-value">${data.state}</span>
        </div>
      `);
    }

    return `
      <div class="tooltip-content">
        <div class="tooltip-title">${data.name}</div>
        <div class="tooltip-details">
          ${details.join('')}
        </div>
      </div>
    `;
  }
}

// Function to darken a color for text
const getDarkerShade = (color: string) => {
  // Parse RGB values
  const rgb = color.match(/\d+/g)?.map(Number)
  if (!rgb) return '#000000'
  
  // Darken by reducing each component by 40%
  const darker = rgb.map(c => Math.max(0, c - (c * 0.4)))
  return `rgb(${darker[0]}, ${darker[1]}, ${darker[2]})`
}

const getPriceColorScale = (baseColor: string, suppliers: any[]) => {
  const prices = suppliers
    .filter(supplier => supplier.price != null)
    .map(supplier => supplier.price)
    
  if (prices.length === 0) return () => baseColor
  
  const min = Math.min(...prices)
  const max = Math.max(...prices)
  
  // Calculate true median
  const sorted = [...prices].sort((a, b) => a - b)
  const median = sorted.length % 2 === 0
    ? (sorted[sorted.length / 2 - 1] + sorted[sorted.length / 2]) / 2
    : sorted[Math.floor(sorted.length / 2)]
  
  // Create a color scale from light to dark versions of the base color
  return chroma.scale([
    chroma(baseColor).brighten(1), // Lighter version
    baseColor,
    chroma(baseColor).darken(1)    // Darker version
  ]).domain([min, median, max])
}

const supplierFilter = ref('')

const availableSuppliers = computed(() => {
  const suppliers = new Set<string>()
  Object.values(props.data).forEach(result => {
    result.suppliers?.forEach(supplier => {
      if (supplier.supplier.name) {
        suppliers.add(supplier.supplier.name)
      }
    })
  })
  return Array.from(suppliers).sort()
})

// Replace the getContrastText function with this
const getThematicTextColor = (backgroundColor: string) => {
  // Calculate relative luminance using chroma.js
  const luminance = chroma(backgroundColor).luminance()
  
  // For dark backgrounds, lighten the color
  // For light backgrounds, darken the color
  return luminance < 0.5 
    ? chroma(backgroundColor).brighten(2).hex()
    : chroma(backgroundColor).darken(2).hex()
}

const option = computed(() => {
  const entries = Object.entries(props.data)
  
  return {
    tooltip: {
      enterable: false,
      confine: true,
      appendToBody: false,
      className: 'echarts-tooltip-custom',
      formatter: (info: any) => {
        return formatTooltipHtml(info.data, !!info.data.children)
      }
    },
    series: [{
      type: 'treemap',
      data: entries.map(([speciesName, result], index) => {
        const speciesColor = getSpeciesColor(index, entries.length)
        
        if (!result.suppliers?.length) {
          const bgColor = colors.value.unavailable
          return {
            name: speciesName,
            value: 1,
            itemStyle: {
              color: bgColor
            },
            label: {
              color: getThematicTextColor(bgColor),
              fontSize: 14,
              fontWeight: 600
            }
          }
        }

        // Filter suppliers by selected sizes and supplier name
        let filteredSuppliers = result.suppliers
        
        if (selectedSizes.value.length > 0) {
          filteredSuppliers = filteredSuppliers.filter(supplier => 
            selectedSizes.value.includes(supplier.size)
          )
        }

        if (supplierFilter.value) {
          filteredSuppliers = filteredSuppliers.filter(supplier => 
            supplier.supplier.name.toLowerCase().includes(supplierFilter.value.toLowerCase())
          )
        }

        // If no suppliers match the filters, show as unavailable
        if (filteredSuppliers.length === 0) {
          const bgColor = colors.value.unavailable
          return {
            name: speciesName,
            value: 1,
            itemStyle: {
              color: bgColor
            },
            label: {
              color: getThematicTextColor(bgColor),
              fontSize: 14,
              fontWeight: 600
            }
          }
        }

        // Create color scale based on price range
        const getColor = getPriceColorScale(speciesColor, filteredSuppliers)

        return {
          name: speciesName,
          children: filteredSuppliers.map(supplier => {
            const bgColor = supplier.price ? getColor(supplier.price).hex() : speciesColor
            return {
              name: supplier.supplier.name,
              value: supplier.quantity || 1,
              itemStyle: {
                color: bgColor
              },
              label: {
                color: getThematicTextColor(bgColor),
                fontSize: 12,
                fontWeight: 500
              },
              price: supplier.price,
              size: supplier.size,
              state: supplier.supplier.state
            }
          }),
          label: {
            color: getThematicTextColor(speciesColor),
            fontSize: 14,
            fontWeight: 600
          }
        }
      }),
      upperLabel: {
        show: true,
        height: 25,
        fontSize: 12,
        fontWeight: 600,
        color: colors.value.primary,
        align: 'left',
      },
      label: {
        show: true,
        formatter: '{b}',
        position: 'insideTopLeft',
        padding: [4, 8],
        width: 140,
        overflow: 'truncate',
        ellipsis: '...',
        lineHeight: 16,
        align: 'left',
        verticalAlign: 'top'
      },
      emphasis: {
        label: {
          show: true,
          formatter: '{b}'
        }
      },
      levels: [{
        // Parent level (species)
        itemStyle: {
          borderColor: 'hsl(var(--background))',
          borderWidth: 1,
          gapWidth: 8,
          borderRadius: 4
        },
        label: {
          show: true
        },
        upperLabel: {
          show: true,
          height: 40,
          position: 'outside',
          distance: 0,
          fontSize: 14,
          fontWeight: 600,
          color: colors.value.primary,
          backgroundColor: 'hsl(var(--background))',
          padding: [4, 8],
          borderRadius: 4,
          align: 'left',
          verticalAlign: 'top'
        }
      }, {
        // Child level (nurseries)
        itemStyle: {
          borderColor: 'hsl(var(--background))',
          borderWidth: 0.5,
          gapWidth: 1,
          borderRadius: 2
        },
        label: {
          show: true,
          position: 'insideTopLeft',
          padding: [4, 8],
          fontSize: 12,
          fontWeight: 500,
          lineHeight: 16,
          color: colors.value.primary
        }
      }],
      breadcrumb: {
        show: false
      },
      animation: false,
      roam: true,
      scaleLimit: {
        min: 0.5,
        max: 5
      },
      center: ['50%', '50%'],
      toolbox: {
        show: true,
        itemSize: 15,
        top: '10px',
        right: '10px',
        showTitle: false,
        feature: {
          restore: {
            show: true,
            title: 'Reset'
          },
          dataZoom: {
            show: true,
            title: {
              zoom: 'Zoom',
              back: 'Reset Zoom'
            }
          },
          saveAsImage: {
            show: true,
            title: 'Download',
            type: 'png',
            pixelRatio: 2
          }
        },
        iconStyle: {
          borderColor: colors.value.mutedForeground
        },
        emphasis: {
          iconStyle: {
            borderColor: colors.value.primary
          }
        }
      }
    }],
    dataZoom: [{
      type: 'inside',
      filterMode: 'none',
      rangeMode: ['value', 'value'],
      preventDefaultMouseMove: false,
      zoomLock: false,
      disabled: !isChartActive.value,
      zoomOnMouseWheel: true,
      moveOnMouseMove: true,
      moveOnMouseWheel: true
    }],
    toolbox: {
      show: true,
      feature: {
        restore: {
          show: true,
          title: 'Reset'
        },
        saveAsImage: {
          show: false
        }
      },
      iconStyle: {
        borderColor: colors.value.primary
      },
      emphasis: {
        iconStyle: {
          borderColor: colors.value.primary
        }
      }
    }
  }
})

const open = ref(false)
const searchTerm = ref('')
const selectedSizes = ref<string[]>([])

const availableSizes = computed(() => {
  const sizes = new Set<string>()
  Object.values(props.data).forEach(result => {
    result.suppliers?.forEach(supplier => {
      if (supplier.size) {
        sizes.add(supplier.size)
      }
    })
  })
  return Array.from(sizes).sort()
})

const sizeCounts = computed(() => {
  const counts: Record<string, number> = {}
  Object.values(props.data).forEach(result => {
    result.suppliers?.forEach(supplier => {
      if (supplier.size) {
        counts[supplier.size] = (counts[supplier.size] || 0) + 1
      }
    })
  })
  return counts
})

const filteredSizes = computed(() => {
  return availableSizes.value.filter((size: string) => 
    size.toLowerCase().includes(searchTerm.value.toLowerCase())
  )
})

const toggleSize = (size: string) => {
  const index = selectedSizes.value.indexOf(size)
  if (index === -1) {
    selectedSizes.value.push(size)
  } else {
    selectedSizes.value.splice(index, 1)
  }
}

// Add emits definition
const emit = defineEmits(['select'])

// Modify click handler to include parent information
const handleChartClick = (params: any) => {
  if (params.data) {
    const clickedData = {
      name: params.data.name,
      price: params.data.price,
      size: params.data.size,
      state: params.data.state,
      value: params.data.value,
      parent: params.treePathInfo?.[0]?.name // Get the parent (species) name
    }
    console.log('Clicked segment data:', clickedData)
    emit('select', clickedData)
  }
}

const histogramData = computed(() => {
  // Extract price data from all suppliers
  const prices: number[] = []
  Object.values(props.data).forEach(result => {
    result.suppliers?.forEach(supplier => {
      if (supplier.price) {
        prices.push(supplier.price)
      }
    })
  })
  return prices.sort((a, b) => a - b)
})

const maxBins = 20
const priceRange = ref<[number, number]>([0, maxBins - 1])

// Update when price range changes
watch(priceRange, (newRange) => {
  // Filter suppliers based on price range
  const [min, max] = newRange
  const minPrice = histogramData.value[min]
  const maxPrice = histogramData.value[max]
  
  // You can add filtering logic here if needed
  console.log(`Price range: $${minPrice} - $${maxPrice}`)
}, { deep: true })

// Handle chart activation on click
const activateChart = () => {
  console.log('Activating chart', isChartActive.value)
  isChartActive.value = true
}

// Add deactivate function
const deactivateChart = () => {
  isChartActive.value = false
  // Remove focus from the container
  chartContainer.value?.blur()
}

// Update click outside handler to use the new deactivate function
onClickOutside(chartContainer, () => {
  deactivateChart()
})

// Add cleanup
onUnmounted(() => {
  if (chart.value) {
    chart.value.dispose()
  }
})
</script>

<template>
  <div class="relative">
    <!-- Filters -->
    <div class="flex gap-2 mb-4 flex-col w-full ">
      <Popover v-model:open="open">
        <PopoverTrigger as-child>
          <Button 
            variant="outline" 
            role="combobox" 
            :aria-expanded="open" 
            class="w-fit justify-between whitespace-nowrap"
          >
            <Icon name="ep:filter" class="w-4 h-4 mr-2" />
            <span>{{ selectedSizes.length == 0 ? 'Filter by size' : selectedSizes.length == 1 ? selectedSizes[0] : selectedSizes.length + ' sizes selected' }}</span>
            <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent class="w-[300px] p-0">
          <Command>
            <CommandInput 
              placeholder="Search sizes..." 
              :value="searchTerm"
              @input="searchTerm = $event.target.value"
            />
            <CommandEmpty>No size found.</CommandEmpty>
            <CommandList>
              <CommandGroup>
                <CommandItem
                  v-for="size in filteredSizes"
                  :key="size"
                  :value="size"
                  @select="() => toggleSize(size)"
                  class="flex justify-between"
                >
                  <div class="flex items-center">
                    <Check
                      :class="cn(
                        'mr-2 h-4 w-4',
                        selectedSizes.includes(size) ? 'opacity-100' : 'opacity-0'
                      )"
                    />
                    {{ size }}
                  </div>
                  <Badge 
                    variant="secondary" 
                    class="ml-2 font-normal"
                  >
                    {{ sizeCounts[size] }}
                  </Badge>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      
      <Input
        v-model="supplierFilter"
        placeholder="Filter by supplier..."
        class="w-[200px]"
      >
        <template #prefix>
          <Icon name="ep:search" class="w-4 h-4 mr-2 opacity-50" />
        </template>
      </Input>
    </div>

    <!-- Chart -->
    <div 
      ref="chartContainer"
      class="w-full h-[400px] min-h-[400px] cursor-pointer relative transition-all duration-200 rounded-xl bg-background p-1"
      :class="{ 'ring-2 ring-primary ring-opacity-50': isChartActive }"
      @click="activateChart"
      @keydown.escape.prevent="deactivateChart"
      tabindex="0"
    >
      <div 
        class="chart-wrapper w-full h-full"
        :class="{ 'is-active': isChartActive }"
      >
        <VChart 
          ref="chart" 
          :option="option" 
          :init-options="initOptions" 
          autoresize 
          @click="handleChartClick"
        />
      </div>
    </div>
  </div>
</template>

<style>
.echarts-tooltip-custom {
  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(8px) !important;
  border: none !important;
  border-radius: 12px !important;
  padding: 12px !important;
  box-shadow: 0 4px 15px -3px rgb(0 0 0 / 0.1) !important;
  width: 300px !important;
}

.dark .echarts-tooltip-custom {
  background: rgba(30, 30, 30, 0.8) !important;
}

/* Tooltip Styles */
.tooltip-content {
  font-family: system-ui, -apple-system, sans-serif;
  width: 100%;
}

.tooltip-title {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--foreground);
  line-height: 1.3;
  hyphens: auto;
  word-wrap: break-word;
}

.tooltip-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 8px;
  width: 100%;
}

.tooltip-detail {
  display: flex;
  align-items: center;
  width: 100%;
}

.tooltip-icon {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}

.tooltip-icon svg {
  width: 14px;
  height: 14px;
}

.tooltip-label {
  flex: 0 0 70px; /* Fixed width for labels */
  color: var(--muted-foreground);
  font-size: 0.9em;
}

.tooltip-value {
  flex: 1;
  color: var(--foreground);
  font-weight: 500;
  padding-left: 4px;
}

/* Species level tooltip styles */
.tooltip-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-top: 8px;
}

.tooltip-stat {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.dark .tooltip-title {
  color: rgb(230, 230, 230);
}

.dark .tooltip-label {
  color: rgb(180, 180, 180);
}

.dark .tooltip-value {
  color: rgb(230, 230, 230);
}
</style>

<style scoped>
:deep(.echarts) {
  width: 100% !important;
  height: 100% !important;
  min-height: 400px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.events-none {
  pointer-events: none !important;
}

.chart-wrapper {
  position: relative;
}

.chart-wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 10;
  border-radius: 0.75rem;
}

.chart-wrapper:not(.is-active)::before {
  pointer-events: auto;
  cursor: pointer;
}

.chart-wrapper.is-active::before {
  pointer-events: none;
}

/* Add keyboard focus styles */
.chart-wrapper:focus-visible {
  outline: none;
  /* ring: 2px solid hsl(var(--primary));
  ring-opacity: 50%; */
}
</style> 