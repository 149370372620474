<script setup lang="ts">
import PanelComponent from './PanelComponent.vue'
import WordCloud from './WordCloud.vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { ref, computed } from 'vue'

const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
});

const emit = defineEmits(['error', 'update:shared-data']);

// Example of using shared data
// const sharedData = computed(() => props.panel.data.sharedData['some-panel-id'] || {})

const selectedWord = ref<string | null>(null)
const isLoading = computed(() => !props.panel?.data?.output)

const handleWordClick = (word: string) => {
  console.log('Word clicked:', word)
  selectedWord.value = word
  updatePanelData()
}

// Update shared data when a word is selected
const updatePanelData = () => {
  emit('update:shared-data', {
    selectedWord: selectedWord.value,
    // Add any other data you want to share
  })
}

// Add debugging
const words = computed(() => {
  console.log('Panel data:', props.panel?.data)
  console.log('Panel output:', props.panel?.data?.output)
  console.log('Panel species:', props.panel?.data?.output?.species)
  return props.panel?.data?.output?.species || []
})
</script>

<template>
  <PanelComponent :panel="props.panel" :height="props.height" :panel_id="props.panel_id"
     toolLabel="Visualise" dataKey="species">
    <template #default="{ data, tool_id, updateState }">
      <keep-alive>
        <div class="overflow-hidden w-full h-full p-0 m-0 rounded-md p-2 pl-4 pb-10">
          <ScrollArea class="w-full h-full">
            <div class="flex justify-center align-center items-center w-full h-full">
              <div v-if="isLoading" class="flex flex-col items-center gap-2">
                <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                <span class="text-sm text-muted-foreground">Analyzing document...</span>
              </div>
              <div v-else-if="props.panel">
                <WordCloud @click="handleWordClick" class="w-full h-full select-none" :words="words" :radius="370" />
              </div>
              <div v-else>
                <span class="text-sm text-muted-foreground">No document loaded</span>
              </div>
            </div>
          </ScrollArea>
        </div>
      </keep-alive>
      <!-- Display selected word details -->
      <div v-if="selectedWord" class="absolute bottom-4 left-4 bg-white dark:bg-gray-800 p-2 rounded shadow">
        Selected: {{ selectedWord }}
      </div>
      <!-- Display some shared data -->
      
    </template>
  </PanelComponent>
</template>