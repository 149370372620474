import * as cheerio from 'cheerio';
import { useValidationStore } from '@/stores/validationStore';
import { useProjectStore } from '@/stores/projectStore';
import { storeToRefs } from 'pinia';
import { useSearch } from '@/composables/useSearch';
import type { 
  NameStatus, 
  Validation, 
  AvailabilityValidation, 
  AvailabilityResult, 
  ECSearchResponse 
} from '@/types/validation';

// Add missing type for eppoCache
interface NuxtAppWithCache extends ReturnType<typeof useNuxtApp> {
  eppoCache: Record<string, any>;
}

// Add type definitions at the top of the file
interface NameStatusMatch {
  original: string;
  matched: string | null;
  source: 'tnrs' | 'gbif' | null;
}

interface TNRSResult {
  Name_submitted: string;
  Name_matched: string;
  Overall_score: number;
}

export const useValidation = () => {
    const project = useProjectStore();
    // const { selectedProject } = storeToRefs(project);
    // const validationStore = useValidationStore();
    // const { allValidations } = storeToRefs(validationStore);
    const validationLoadingState = ref<"idle" | "pending" | "error">("idle");

    const validationIcons = {
        nameStatus: 'solar:tag-bold',
        biosecurityStatus: 'ph:bug-fill',
        availabilityStatus: 'ph:shopping-cart-fill',
        complianceStatus: 'solar:check-circle-linear',
        diversityStatus: 'bi:globe-americas'
    }

    const { fetchBiosecurityData, fetchSerper } = useSearch();

    const nameStatus: Ref<NameStatus> = ref({
        exact: [] as NameStatusMatch[],
        inexact: [] as NameStatusMatch[],
        invalid: [] as NameStatusMatch[]
    });

    const securityStatus = ref({
        host: [] as { species: string; source: string }[],
        pests: [] as { species: string; source: string }[],
    });

    const nuxtApp = useNuxtApp() as NuxtAppWithCache;

    // Create a cache object if it doesn't exist
    if (!nuxtApp.eppoCache) {
        nuxtApp.eppoCache = {};
    }

    // Create a simple request cache for serper API calls
    const serperRequestCache: Record<string, Promise<any>> = {};

    // Generic function to fetch Eppo data based on type
    const findEppoData = async (type: 'hosts' | 'pests', eppocode: string) => {
        const cacheKey = `${type}:${eppocode}`;

        // Check if the data is already in the cache
        if (nuxtApp.eppoCache[cacheKey]) {
            return nuxtApp.eppoCache[cacheKey];
        }

        console.log(`/api/proxy/eppo/${type}`);
        
        // Add retry logic
        const maxRetries = 3;
        let retryCount = 0;
        let lastError: any = null;
        
        while (retryCount < maxRetries) {
            try {
                const proxyResponse = await $fetch(`/api/proxy/eppo/${type}`, {
                    method: 'POST',
                    body: { eppocode },
                    responseType: 'text',
                });
                
                // Ensure we have a valid string to work with
                // Check for empty or invalid response
                if (!proxyResponse) {
                    console.warn(`Empty response received for ${type} data of ${eppocode}`);
                    retryCount++;
                    await new Promise(resolve => setTimeout(resolve, 1000 * retryCount)); // Exponential backoff
                    continue;
                }
                
                // Ensure we have a string to work with
                const responseText = typeof proxyResponse === 'string' ? proxyResponse : JSON.stringify(proxyResponse);
                
                // If the response is an empty string, retry
                if (!responseText || responseText.trim() === '') {
                    console.warn(`Empty text response for ${type} data of ${eppocode}, attempt ${retryCount + 1}`);
                    retryCount++;
                    await new Promise(resolve => setTimeout(resolve, 1000 * retryCount)); // Exponential backoff
                    continue;
                }
                
                // Proceed with cheerio parsing only when we have content
                const $ = cheerio.load(responseText);
                
                // Verify the table exists before attempting to parse
                if (!$('table#dttable tbody').length) {
                    console.warn(`No data table found for ${type} of ${eppocode}, attempt ${retryCount + 1}`);
                    retryCount++;
                    await new Promise(resolve => setTimeout(resolve, 1000 * retryCount)); // Exponential backoff
                    continue;
                }
                
                const result = $('table#dttable tbody tr')
                    .map((_, row) => ({
                        name: $(row).find('td:nth-of-type(2) a').text().trim(),
                        type: $(row).find('td:nth-of-type(3)').text().trim(),
                        source: 'eppo' as const,
                    }))
                    .get();

                // Store the result in the cache
                nuxtApp.eppoCache[cacheKey] = result;

                console.log(`Found ${result.length} results for ${type} of ${eppocode}`);
                return result;
            } catch (error) {
                console.error(`Error fetching EPPO ${type} data for ${eppocode}, attempt ${retryCount + 1}:`, error);
                lastError = error;
                retryCount++;
                if (retryCount < maxRetries) {
                    await new Promise(resolve => setTimeout(resolve, 1000 * retryCount)); // Exponential backoff
                }
            }
        }
        
        console.error(`Failed to fetch EPPO ${type} data for ${eppocode} after ${maxRetries} attempts`);
        return [];
    };

    const validateSpeciesNames = async (namesToCheck: string[], gbif_names: string[], project_id: string) => {
        validationLoadingState.value = "pending";
        
        try {
            // Clear previous results first
            nameStatus.value = {
                exact: [],
                inexact: [],
                invalid: []
            };

            // Use Sets to ensure uniqueness and normalize case
            const uniqueNames = [...new Set(namesToCheck.filter(Boolean).map(n => n.toLowerCase()))]
            const uniqueGbifNames = [...new Set(gbif_names.filter(Boolean).map(n => n.toLowerCase()))]
            
            // Create Sets to track which names have been added to each category
            const exactSet = new Set<string>()
            const inexactSet = new Set<string>()
            const invalidSet = new Set<string>()
            
            // First try GBIF matching
            for (let name of uniqueNames) {
              if (name === 'undefined') continue
              
              const lowercaseName = name
              const originalName = namesToCheck.find(n => n.toLowerCase() === lowercaseName)
              if (!originalName) continue
              
              // Skip if we've already processed this name in any category
              if (exactSet.has(lowercaseName) || inexactSet.has(lowercaseName) || invalidSet.has(lowercaseName)) {
                continue
              }

              // Try exact GBIF match first
              const gbifMatch = uniqueGbifNames.find(gbif_name => 
                gbif_name === lowercaseName
              )

              if (gbifMatch) {
                const matchedName = gbif_names.find(n => n.toLowerCase() === gbifMatch)
                if (matchedName && !exactSet.has(lowercaseName)) {
                  exactSet.add(lowercaseName)
                  nameStatus.value.exact.push({ 
                    original: originalName, 
                    matched: matchedName,
                    source: 'gbif'
                  })
                  continue
                }
              }

              // Try partial GBIF match
              const partialGbifMatch = uniqueGbifNames.find(gbif_name => 
                gbif_name.includes(lowercaseName)
              )
              if (partialGbifMatch) {
                const matchedName = gbif_names.find(n => n.toLowerCase() === partialGbifMatch)
                if (matchedName && !inexactSet.has(lowercaseName)) {
                  inexactSet.add(lowercaseName)
                  nameStatus.value.inexact.push({ 
                    original: originalName, 
                    matched: matchedName,
                    source: 'gbif'
                  })
                  continue
                }
              }

              // If no GBIF match, mark as temporarily invalid
              if (!exactSet.has(lowercaseName) && !inexactSet.has(lowercaseName)) {
                invalidSet.add(lowercaseName)
              }
            }

            // Get remaining invalid names that need TNRS validation
            const remainingInvalid = [...invalidSet]
            if (remainingInvalid.length > 0) {
              // Get TNRS results for remaining invalid names
              const { result: tnrsResults } = await fetchTnrs(remainingInvalid)
              
              // Create a map for quick lookups
              const tnrsResultMap = new Map<string, TNRSResult>(
                (tnrsResults as TNRSResult[])?.map(r => [r.Name_submitted?.toLowerCase() || '', r]) || []
              )
              
              // Create a Set to track original names that have been processed
              const processedOriginalNames = new Set<string>()
              
              // Process TNRS results
              for (let name of remainingInvalid) {
                // Skip if we've already processed this name in exact or inexact sets
                if (exactSet.has(name) || inexactSet.has(name)) continue
                
                const originalName = namesToCheck.find(n => n.toLowerCase() === name)
                if (!originalName) continue

                // Skip if we've already processed this original name
                if (processedOriginalNames.has(originalName.toLowerCase())) continue
                
                const tnrsMatch = tnrsResultMap.get(name)
                
                if (tnrsMatch?.Overall_score && tnrsMatch.Overall_score >= 0.9 && tnrsMatch.Name_matched) {
                  // High confidence TNRS match - remove from invalid and add to exact
                  invalidSet.delete(name)
                  if (!exactSet.has(name)) {
                    exactSet.add(name)
                    processedOriginalNames.add(originalName.toLowerCase())
                    nameStatus.value.exact.push({ 
                      original: originalName, 
                      matched: tnrsMatch.Name_matched,
                      source: 'tnrs'
                    })
                  }
                } else if (tnrsMatch?.Overall_score && tnrsMatch.Overall_score >= 0.5 && tnrsMatch.Name_matched) {
                  // Partial TNRS match - remove from invalid and add to inexact
                  invalidSet.delete(name)
                  if (!inexactSet.has(name)) {
                    inexactSet.add(name)
                    processedOriginalNames.add(originalName.toLowerCase())
                    nameStatus.value.inexact.push({ 
                      original: originalName, 
                      matched: tnrsMatch.Name_matched,
                      source: 'tnrs'
                    })
                  }
                } else {
                  // Keep as invalid if not already in invalid set
                  if (!invalidSet.has(name)) {
                    processedOriginalNames.add(originalName.toLowerCase())
                    nameStatus.value.invalid.push({ 
                      original: originalName, 
                      matched: null,
                      source: null
                    })
                  }
                }
              }
            }

            const validation: Validation = {
              name: 'nameStatus',
              result: nameStatus.value,
              show: nameStatus.value.invalid.length !== 0 || nameStatus.value.inexact.length !== 0
            }

            // validationStore.addValidation(validation, project_id)

            validationLoadingState.value = "idle"
            return nameStatus.value
            
        } catch (error) {
            console.error("Error in validateSpeciesNames:", error)
            validationLoadingState.value = "error"
            throw error
        }
    }

    const validateAndRefreshBiosecurity = async (
      speciesNames: string[],
      panel_id?: string,
      country?: string,
      libtype?: string,
      codelang: string = 'en',
      forceRefresh: boolean = false
    ) => {
      validationLoadingState.value = "pending";
      console.log('Starting biosecurity validation for species:', speciesNames);
      const { fetchOpenverse, fetchSerper } = useSearch();
      const client = useSupabaseClient();

      try {
        // Skip cache if forceRefresh is true
        let cachedValidations: any[] = [];
        let cacheError = null;
        
        if (!forceRefresh) {
          // Create a normalized, sorted list of species names for consistent cache lookup
          const normalizedSpeciesNames = speciesNames
            .map(name => name.toLowerCase().trim())
            .sort();

          // Check cache for existing validations
          const cacheResult = await client
            .from('validations_biosecurity')
            .select('*')
            .in('species_name', normalizedSpeciesNames)
            .gt('expires_at', new Date().toISOString());
            
          cachedValidations = cacheResult.data || [];
          cacheError = cacheResult.error;
          
          console.log('Cached validations:', cachedValidations);
          // Log the structure of the first cached validation
          if (cachedValidations.length > 0) {
            console.log('First cached validation structure:', {
              speciesName: cachedValidations[0].species_name,
              hasResult: !!cachedValidations[0].result,
              resultKeys: cachedValidations[0].result ? Object.keys(cachedValidations[0].result) : [],
              hasPests: Array.isArray(cachedValidations[0]?.result?.pests),
              pestCount: Array.isArray(cachedValidations[0]?.result?.pests) ? cachedValidations[0].result.pests.length : 0
            });
          }
          
          if (cacheError) {
            console.error("Error fetching cached validations:", cacheError);
          }
        }

        const cachedSpecies = new Set(cachedValidations.map(v => v.species_name) || []);
        // Normalize names for comparison
        const normalizedValidNames = new Set(
          speciesNames.map(name => name.toLowerCase().trim()).sort()
        );

        // Create a normalized set of cached species names
        const normalizedCachedSpecies = new Set(
          cachedValidations.map(v => v.species_name.toLowerCase().trim())
        );

        // Only fetch species that aren't in the cache (unless forceRefresh is true)
        const speciesToFetch = forceRefresh ? 
          speciesNames : 
          speciesNames.filter(name => !normalizedCachedSpecies.has(name.toLowerCase().trim()));
        
        console.log('Species to fetch from EPPO:', speciesToFetch);

        let validationResults: Record<string, any> = {};

        // Process cached results only if not forcing refresh
        if (!forceRefresh) {
          cachedValidations.forEach(validation => {
            // Check if the cached validation has result data
            if (validation && validation.result) {
              console.log(`Processing cached data for ${validation.species_name}:`, 
                           validation.result.isBiosecurityThreat ? 'Has threat data' : 'No threat data');
              
              // Add this species directly to validation results
              validationResults[validation.species_name] = validation.result;
            }
          });
        }
        
        // Log how many cached results were processed
        console.log(`Processed ${Object.keys(validationResults).length} cached species results`);

        // Fetch new data even if speciesToFetch is empty but forceRefresh is true
        if (speciesToFetch.length > 0 || forceRefresh) {
          const filters: string[] = [];
          if (country) filters.push(`country = ${country}`);
          if (libtype) filters.push(`libtype = ${libtype}`);

          console.log('Fetching biosecurity data for:', speciesToFetch);
          const searchResults = await fetchBiosecurityData(speciesToFetch, filters);
          console.log('EPPO search results:', searchResults);

          const upsertPromises = speciesToFetch.map(async (name, index) => {
            const eppoHits = searchResults.eppo?.[index]?.hits || [];
            const padilData = searchResults.padil?.[index]?.data || [];
            
            console.log(`Processing EPPO data for ${name}:`, eppoHits.length, 'hits');

            let threatData = {
              isBiosecurityThreat: false,
              hosts: [] as { name: string; type: string; source: 'eppo' | 'padil'; relationshipType?: 'direct' | 'indirect' | 'genus' }[],
              pests: [] as { name: string; type: string; source: 'eppo' | 'padil'; relationshipType?: 'direct' | 'indirect' | 'genus' }[],
              hasGenusData: false // Add flag to track genus data
            };

            if (eppoHits.length > 0 || padilData.length > 0) {
              // Check for genus-level taxonomic group first - we'll use this to create a placeholder if needed
              const genusHit = eppoHits.find((hit: any) => 
                hit?.libtype?.includes('taxonomic group'));
              
              if (genusHit) {
                console.log(`Found genus hit for ${name}:`, genusHit.fullname);
                threatData.hasGenusData = true;
                
                // Always add a genus placeholder for this case to ensure it's displayed
                threatData.pests.push({
                  name: `Potential pests associated with ${genusHit.fullname}`,
                  type: 'Host',
                  source: 'eppo' as const,
                  relationshipType: 'genus'
                });
                
                // Mark as a biosecurity threat to ensure it gets displayed
                threatData.isBiosecurityThreat = true;
              }

              // Process EPPO data
              await Promise.all(
                eppoHits.map(async (species: any) => {
                  console.log('Processing EPPO hit:', species?.eppocode, species?.libtype);
                  // Direct pest relationships for plants
                  if (species?.libtype?.includes("plant") && species?.codelang?.includes(codelang)) {
                    console.log('Fetching pests for plant:', species.eppocode);
                    const pests = await findEppoData("pests", species.eppocode);
                    if (Array.isArray(pests) && pests.length > 0) {
                      console.log(`Found ${pests.length} pests for ${species.eppocode}`);
                      threatData.pests.push(...pests.map(pest => ({ 
                        ...pest, 
                        source: 'eppo' as const,
                        relationshipType: 'direct'
                      })));
                    } else {
                      // If the species is a genus (taxonomic group) and has no pests, we can add a placeholder
                      // to ensure it shows up in the interface
                      if (species?.libtype?.includes("taxonomic group")) {
                        console.log(`Adding genus placeholder for ${species.fullname}`);
                        // Add a single placeholder pest for the genus
                        threatData.pests.push({
                          name: `Potential pests associated with ${species.fullname}`,
                          type: 'Host',
                          source: 'eppo' as const,
                          relationshipType: 'genus'
                        });
                      }
                    }
                  }

                  // Indirect relationships through pests
                  if (species?.libtype?.includes("animal") && species?.codelang?.includes(codelang)) {
                    console.log('Fetching hosts for pest:', species.eppocode);
                    const hosts = await findEppoData("hosts", species.eppocode);
                    if (Array.isArray(hosts)) {
                      // Only keep hosts that match our validated species names
                      const relevantHosts = hosts.filter(host => {
                        if (!host?.name) return false;
                        const normalizedHostName = host.name.toLowerCase().trim();
                        return normalizedValidNames.has(normalizedHostName);
                      });

                      console.log(`Found ${relevantHosts.length} relevant hosts for ${species.eppocode}`);
                      if (relevantHosts.length > 0) {
                        threatData.hosts.push(...relevantHosts.map(host => ({
                          ...host,
                          source: 'eppo' as const,
                          relationshipType: 'indirect'
                        })));
                      }
                    }
                  }
                })
              );

              // Process PADIL data
              padilData.forEach((pest: any) => {
                if (pest?.scientificName && pest?.taxonomyLvl1) {
                  threatData.hosts.push({
                    name: pest.scientificName,
                    type: pest.taxonomyLvl1,
                    source: 'padil',
                  });
                }
              });

              threatData.isBiosecurityThreat = threatData.hosts.length > 0 || threatData.pests.length > 0;
            }

            validationResults[name] = threatData;

            // Set isBiosecurityThreat to true if we have any pests or genus data
            if (validationResults[name] && 
                validationResults[name].pests && 
                validationResults[name].pests.length > 0) {
              validationResults[name].isBiosecurityThreat = true;
            }

            // Only upsert to cache if we actually fetched new data
            if (speciesToFetch.includes(name)) {
              try {
                await client
                  .from('validations_biosecurity')
                  .upsert(
                    {
                      species_name: name,
                      api: 'eppo_padil',
                      result: threatData,
                      expires_at: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString() // Cache for 7 days
                    } as any,
                    {
                      onConflict: 'species_name,api',
                      ignoreDuplicates: false
                    }
                  );
              } catch (error) {
                console.error(`Error upserting data for ${name}:`, error);
              }
              return Promise.resolve();
            }
            
            return Promise.resolve();
          });

          // Wait for all upsert operations to complete
          await Promise.all(upsertPromises);
        }

        // Check if we have results for every species we were asked to validate
        // If not, force fetch for missing species
        const missedSpecies = speciesNames.filter(name => !validationResults[name]);
        
        if (missedSpecies.length > 0 && !forceRefresh) {
          console.log(`Missing results for ${missedSpecies.length} species. Fetching directly:`, missedSpecies);
          
          // Directly fetch these species
          const searchResults = await fetchBiosecurityData(missedSpecies, []);
          console.log('EPPO search results for missed species:', searchResults);
          
          // Process the results similar to above
          await Promise.all(missedSpecies.map(async (name, index) => {
            const eppoHits = searchResults.eppo?.[index]?.hits || [];
            const padilData = searchResults.padil?.[index]?.data || [];
            
            console.log(`Processing missed species EPPO data for ${name}:`, eppoHits.length, 'hits');
            
            let threatData = {
              isBiosecurityThreat: false,
              hosts: [] as { name: string; type: string; source: 'eppo' | 'padil'; relationshipType?: 'direct' | 'indirect' | 'genus' }[],
              pests: [] as { name: string; type: string; source: 'eppo' | 'padil'; relationshipType?: 'direct' | 'indirect' | 'genus' }[],
              hasGenusData: false // Add flag to track genus data
            };
            
            if (eppoHits.length > 0 || padilData.length > 0) {
              // Check for genus-level taxonomic group first - we'll use this to create a placeholder if needed
              const genusHit = eppoHits.find((hit: any) => 
                hit?.libtype?.includes('taxonomic group'));
              
              if (genusHit) {
                console.log(`Found genus hit for ${name}:`, genusHit.fullname);
                threatData.hasGenusData = true;
                
                // Always add a genus placeholder for this case to ensure it's displayed
                threatData.pests.push({
                  name: `Potential pests associated with ${genusHit.fullname}`,
                  type: 'Host',
                  source: 'eppo' as const,
                  relationshipType: 'genus'
                });
                
                // Mark as a biosecurity threat to ensure it gets displayed
                threatData.isBiosecurityThreat = true;
              }

              // Process EPPO hits
              await Promise.all(
                eppoHits.map(async (species: any) => {
                  console.log('Processing missed EPPO hit:', species?.eppocode, species?.libtype);
                  // Process plant pests
                  if (species?.libtype?.includes("plant") && species?.codelang?.includes(codelang)) {
                    console.log('Fetching pests for missed plant:', species.eppocode);
                    const pests = await findEppoData("pests", species.eppocode);
                    if (Array.isArray(pests)) {
                      console.log(`Found ${pests.length} pests for missed ${species.eppocode}`);
                      threatData.pests.push(...pests.map(pest => ({ 
                        ...pest, 
                        source: 'eppo' as const,
                        relationshipType: 'direct'
                      })));
                    }
                  }
                  
                  // Process animal hosts
                  if (species?.libtype?.includes("animal") && species?.codelang?.includes(codelang)) {
                    console.log('Fetching hosts for missed pest:', species.eppocode);
                    const hosts = await findEppoData("hosts", species.eppocode);
                    if (Array.isArray(hosts)) {
                      // Only keep hosts that match our validated species names
                      const relevantHosts = hosts.filter(host => {
                        if (!host?.name) return false;
                        const normalizedHostName = host.name.toLowerCase().trim();
                        return speciesNames.some(s => s.toLowerCase().trim() === normalizedHostName);
                      });
                      
                      console.log(`Found ${relevantHosts.length} relevant hosts for missed ${species.eppocode}`);
                      if (relevantHosts.length > 0) {
                        threatData.hosts.push(...relevantHosts.map(host => ({
                          ...host,
                          source: 'eppo' as const,
                          relationshipType: 'indirect'
                        })));
                      }
                    }
                  }
                })
              );
              
              // Process PADIL data for missed species
              padilData.forEach((pest: any) => {
                if (pest?.scientificName && pest?.taxonomyLvl1) {
                  threatData.hosts.push({
                    name: pest.scientificName,
                    type: pest.taxonomyLvl1,
                    source: 'padil',
                  });
                }
              });
              
              threatData.isBiosecurityThreat = threatData.hosts.length > 0 || threatData.pests.length > 0;
            }
            
            validationResults[name] = threatData;
            
            // Set isBiosecurityThreat to true if we have any pests or genus data
            if (validationResults[name] && 
                validationResults[name].pests && 
                validationResults[name].pests.length > 0) {
              validationResults[name].isBiosecurityThreat = true;
            }
            
            // Cache the results
            try {
              await client
                .from('validations_biosecurity')
                .upsert(
                  {
                    species_name: name,
                    api: 'eppo_padil',
                    result: threatData,
                    expires_at: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString()
                  } as any,
                  {
                    onConflict: 'species_name,api',
                    ignoreDuplicates: false
                  }
                );
            } catch (error) {
              console.error(`Error caching results for missed species ${name}:`, error);
            }
            
            return Promise.resolve();
          }));
        }

        // Fetch images for species with biosecurity threats
        const threatEntries = Object.entries(validationResults);
            
        console.log(`Processing ${threatEntries.length} species entries for display`);

        const mappedEntries = await Promise.all(threatEntries.map(async ([species, data]) => {
          // Get up to 5 pests, prioritizing Major hosts first
          const majorHostPests = data.pests?.filter((pest: any) => pest.type === 'Major host') || [];
          const otherPests = data.pests?.filter((pest: any) => pest.type !== 'Major host') || [];
          const allPests = [...majorHostPests, ...otherPests];
          
          // Deduplicate pests by name
          const uniquePests = allPests.reduce((acc, pest) => {
            const pestName = pest.name?.replace(/\s*\([^)]*\)\s*/g, '');
            if (pestName && !acc.some((p: any) => p.name?.replace(/\s*\([^)]*\)\s*/g, '') === pestName)) {
              acc.push(pest);
            }
            return acc;
          }, [] as any[]);

          // Randomly shuffle the pests for variety but keep major hosts at the front
          const shuffledOtherPests = otherPests.sort(() => Math.random() - 0.5);
          const shuffledPests = [...majorHostPests, ...shuffledOtherPests];

          // Only select up to 6 random pests to check for images
          const selectedPests = shuffledPests.slice(0, 6);
          
          let images: string[] = [];
          let displayPests = [];
          
          // Fetch images for selected pests
          if (selectedPests.length > 0) {
            // Initialize counter for valid images
            let validImagesCount = 0;
            const maxPestsWithImages = 5;
            const pestsWithImages: any[] = [];
            
            // Process pests one by one until we have enough valid images
            for (const pest of selectedPests) {
              // Stop if we already have enough valid images
              if (validImagesCount >= maxPestsWithImages) {
                break;
              }
              
              if (pest?.name) {
                // Skip image fetching for genus placeholder pests
                if (pest.relationshipType === 'genus') {
                  pestsWithImages.push({
                    pest,
                    imageUrl: null
                  });
                  continue;
                }
                
                const pestName = pest.name.replace(/\s*\([^)]*\)\s*/g, '');
                try {
                  // Check if we already have a request in progress for this pest name
                  if (!serperRequestCache[pestName]) {
                    // Cache the promise to deduplicate concurrent requests
                    serperRequestCache[pestName] = $fetch('/api/serp/serper', {
                      method: 'POST',
                      body: { q: pestName }
                    });
                    
                    // Set a timeout to clear the cache entry after 5 minutes
                    setTimeout(() => {
                      delete serperRequestCache[pestName];
                    }, 5 * 60 * 1000); // 5 minutes
                  }
                  
                  // Use the cached request
                  const response = await serperRequestCache[pestName];
                  
                  // Check if we have a valid response with an image URL
                  if (response && Array.isArray(response) && response[0] && typeof response[0] === 'object') {
                    const imageUrlValue = response[0].imageUrl || response[0].image || null;
                    
                    // Only count it as valid if we got an image URL
                    if (imageUrlValue) {
                      validImagesCount++;
                      pestsWithImages.push({
                        pest,
                        imageUrl: imageUrlValue
                      });
                    } else {
                      // Add pest without image
                      pestsWithImages.push({
                        pest,
                        imageUrl: null
                      });
                    }
                  } else {
                    console.warn(`No valid image found for pest ${pestName}`);
                    pestsWithImages.push({
                      pest,
                      imageUrl: null
                    });
                  }
                } catch (error) {
                  console.warn(`Error fetching image for pest ${pestName}:`, error);
                  pestsWithImages.push({
                    pest,
                    imageUrl: null
                  });
                }
              }
            }
            
            try {
              // Create displayPests array with images
              displayPests = pestsWithImages
                .map(result => ({
                  ...result.pest,
                  imageUrl: result?.imageUrl || null
                }));
              
              // Keep the images array for backward compatibility
              images = displayPests
                .map(p => p.imageUrl)
                .filter(url => url !== null && url !== undefined);
            } catch (imagesError) {
              console.error('Error processing pest images:', imagesError);
              // Fall back to pests without images if image fetching fails
              displayPests = selectedPests.map((pest: any) => ({
                ...pest,
                imageUrl: null
              }));
              images = [];
            }
          }

          return [species, {
            ...data,
            hosts: data.hosts ?? [],
            pests: data.pests ?? [],
            images: images.slice(0, 5), // Limit to 5 unique images
            displayPests, // Add the new displayPests array
            scheduleChanged: false,
            hasGenusData: data.hasGenusData === true
          }];
        }));

        validationResults = Object.fromEntries(mappedEntries);

        validationLoadingState.value = "idle";
        console.log('validationResults', validationResults)
        return {
          name: 'biosecurityStatus',
          result: validationResults,
          show: Object.keys(validationResults).length > 0 // Show result if we have any entries at all
        };
      } catch (error) {
        console.error("Error validating species against biosecurity data:", error);
        validationLoadingState.value = "error";
        return {
          name: 'biosecurityStatus',
          result: {},
          show: false
        };
      }
    };

    // Add this helper function to get all valid species names
    const getAllValidSpeciesNames = (nameStatus: NameStatus): string[] => {
      const validNames = new Set<string>();
      
      // Add exact matches first
      nameStatus.exact.forEach(match => {
        validNames.add(match.matched || match.original);
      });
      
      // Add inexact matches with good confidence (from TNRS)
      nameStatus.inexact.forEach(match => {
        if (match.source === 'tnrs' && match.matched) {
          validNames.add(match.matched);
        }
      });
      
      return Array.from(validNames);
    }


    const fetchTnrs = async (invalidNames: string[]) => {
      // Create a cache key that includes all unique names
      const uniqueNames = [...new Set(invalidNames.map(n => n.toLowerCase()))].sort()
      const cacheKey = `tnrs-${uniqueNames.join(',')}`
      
      const { data: tnrsData, error: tnrsError } = await useAsyncData(
        cacheKey,
        () => $fetch('/api/bio/tnrs', {
          method: 'POST',
          body: { searchQuery: uniqueNames }
        }),
        {
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null
          }
        }
      )

      console.log('TNRS data:', tnrsData.value)
      
      if (tnrsError.value) {
        console.error('Error fetching TNRS data:', tnrsError.value)
      }

      return { 
        result: toRaw(tnrsData.value), 
        error: toRaw(tnrsError.value) 
      }
    }

    const validateAvailability = async (
        speciesNames: string[],
        project_id: string,
        useCache: boolean = true,
        maxCacheAge: number = 3600,
        limit: number = 100
    ) => {
        validationLoadingState.value = "pending";

        try {
            // Prepare search requests for EC
            const searches = speciesNames.map(name => ({
                plantName: name
            }));

            const cacheKey = `ec-search-${speciesNames.join(',')}`;
            const nuxtApp = useNuxtApp();

            // First try to get from Nuxt's payload cache
            let cachedData = nuxtApp.payload?.data?.[cacheKey];
            
            if (!useCache) {
                // Clear Nuxt's payload cache if we donn't want to use cache
                if (nuxtApp.payload?.data) {
                    delete nuxtApp.payload.data[cacheKey];
                }
                cachedData = null;
            }

            // If no cached data, fetch from API
            const { data: response } = await useAsyncData(
                cacheKey,
                () => $fetch('/api/ec/search', {
                    method: 'POST',
                    body: {
                        searches,
                        useCache,
                        maxCacheAge,
                        limit
                    }
                }),
                {
                    getCachedData: (key) => cachedData,
                    immediate: true,
                    server: false,
                    default: () => ({ success: false, results: [] })
                }
            );

            console.log('EC search response:', response.value);
            
            let validationResults: Record<string, AvailabilityResult> = {};

            if (response.value?.success) {
                // Verify response.value.results is an array and has entries
                if (Array.isArray(response.value.results) && response.value.results.length > 0) {
                    // Process each search result
                    response.value.results.forEach((searchResult: ECSearchResponse, index: number) => {
                        // Make sure we have a valid index into speciesNames
                        if (index < speciesNames.length) {
                            const speciesName = speciesNames[index];
                            validationResults[speciesName] = {
                                isAvailable: searchResult.success && Array.isArray(searchResult.results) && searchResult.results.length > 0,
                                source: 'evergreen_connect',
                                suppliers: searchResult.results || [],
                                lastChecked: new Date().toISOString()
                            };
                        }
                    });
                } else if (response.value.result) {
                    // Alternative response structure might have result instead of results
                    console.log('Using alternative response structure with result field');
                    const results = Array.isArray(response.value.result) ? response.value.result : [response.value.result];
                    
                    results.forEach((searchResult: any, index: number) => {
                        if (index < speciesNames.length) {
                            const speciesName = speciesNames[index];
                            validationResults[speciesName] = {
                                isAvailable: !!searchResult?.success && Array.isArray(searchResult?.results) && searchResult.results.length > 0,
                                source: 'evergreen_connect',
                                suppliers: searchResult?.results || [],
                                lastChecked: new Date().toISOString()
                            };
                        }
                    });
                }
            }

            // Ensure every speciesName has a result entry, even if empty
            speciesNames.forEach(name => {
                if (!validationResults[name]) {
                    validationResults[name] = {
                        isAvailable: false,
                        source: 'evergreen_connect',
                        suppliers: [],
                        lastChecked: new Date().toISOString()
                    };
                }
            });

            console.log('Availability validation results:', validationResults);

            validationLoadingState.value = "idle";
            
            const validation: AvailabilityValidation = {
                name: 'availabilityStatus',
                result: validationResults,
                show: Object.values(validationResults).some(result => !result.isAvailable)
            };

            // validationStore.addValidation(validation, project_id);
            return validation;

        } catch (error) {
            console.error("Error validating species availability:", error);
            validationLoadingState.value = "error";
            return {
                name: 'availabilityStatus',
                result: {},
                show: false
            };
        }
    };

    /**
     * Fetches plant traits from the GIFT API based on botanical name
     * @param botanicalName The scientific name of the plant (string or object with value property)
     * @returns Object containing plant traits (mature height and width)
     */
    const fetchPlantTraits = async (botanicalName: string | any) => {
        // Handle empty input
        if (!botanicalName) return null;
        
        // Extract the actual name value if botanicalName is an object
        const nameValue = typeof botanicalName === 'object' && botanicalName !== null
            ? (botanicalName.value || botanicalName.label || '')
            : botanicalName;
            
        // Skip if we don't have a valid string
        if (!nameValue || typeof nameValue !== 'string') {
            console.log(`[useValidation] Invalid botanical name format:`, botanicalName);
            return null;
        }
        
        try {
            console.log(`[useValidation] Fetching plant traits for: ${nameValue}`);
            
            // Extract genus from botanical name
            const genus = nameValue.split(' ')[0];
            if (!genus) {
                console.log(`[useValidation] Invalid botanical name format: ${nameValue}`);
                return null;
            }
            
            const { search } = useMeiliSearch('gift');
            const searchResults = await search(nameValue, {
                limit: 5
            });
            
            console.log(`[useValidation] Found ${searchResults?.hits?.length || 0} results for ${nameValue}`);
            
            // Find best match (exact match first, then first result)
            const exactMatch = searchResults.hits.find((hit: any) => 
                hit.scientific_name?.toLowerCase() === nameValue.toLowerCase()
            );
            const bestMatch = exactMatch || searchResults.hits[0];
            
            console.log('bestMatch', bestMatch)
            if (!bestMatch) return null;
            
            // Initialize result object
            const result: Record<string, any> = {};
            
            // Extract height data if available
            if (bestMatch.height_mean_meters && !isNaN(parseFloat(bestMatch.height_mean_meters))) {
                const heightValue = parseFloat(bestMatch.height_mean_meters);
                console.log(`[useValidation] Found mature height for ${nameValue}: ${heightValue}m`);
                result.matureheight = {
                    value: heightValue,
                    source: 'gift_api',
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                };
            }
            // If mean height is not available, check for min and max height and calculate mean
            else if (bestMatch.height_min_meters && bestMatch.height_max_meters) {
                const minHeight = parseFloat(bestMatch.height_min_meters);
                const maxHeight = parseFloat(bestMatch.height_max_meters);
                
                if (!isNaN(minHeight) && !isNaN(maxHeight)) {
                    const heightValue = (minHeight + maxHeight) / 2;
                    console.log(`[useValidation] Calculated mature height for ${nameValue} from min/max: ${heightValue}m`);
                    result.matureheight = {
                        value: heightValue,
                        source: 'gift_api',
                        sourceDetail: 'min_max_average',
                        minValue: minHeight,
                        maxValue: maxHeight,
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString()
                    };
                }
            }
            
            // Extract canopy diameter as width if available
            if (bestMatch.canopy_diameter_mean_meters && !isNaN(parseFloat(bestMatch.canopy_diameter_mean_meters))) {
                const widthValue = parseFloat(bestMatch.canopy_diameter_mean_meters);
                console.log(`[useValidation] Found mature width (canopy diameter) for ${nameValue}: ${widthValue}m`);
                result.maturewidth = {
                    value: widthValue,
                    source: 'gift_api',
                    sourceDetail: 'canopy_diameter',
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                };
            }
            // If mean width is not available, check for min and max canopy diameter and calculate mean
            else if (bestMatch.canopy_diameter_min_meters && bestMatch.canopy_diameter_max_meters) {
                const minWidth = parseFloat(bestMatch.canopy_diameter_min_meters);
                const maxWidth = parseFloat(bestMatch.canopy_diameter_max_meters);
                
                if (!isNaN(minWidth) && !isNaN(maxWidth)) {
                    const widthValue = (minWidth + maxWidth) / 2;
                    console.log(`[useValidation] Calculated mature width for ${nameValue} from min/max canopy: ${widthValue}m`);
                    result.maturewidth = {
                        value: widthValue,
                        source: 'gift_api',
                        sourceDetail: 'canopy_diameter_min_max_average',
                        minValue: minWidth,
                        maxValue: maxWidth,
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString()
                    };
                }
            }
            
            console.log('[useValidation] Plant traits result:', result);
            
            // Return null if no data was found
            return Object.keys(result).length > 0 ? result : null;
        } catch (error) {
            console.error(`[useValidation] Error fetching plant traits for ${typeof botanicalName === 'object' ? JSON.stringify(botanicalName) : botanicalName}:`, error);
            return null;
        }
    };

    // const getSpeciesNames = (
    //   data?: string[] | null,
    //   panel_id?: string | null,
    //   nameResults?: NameStatus | null
    // ): string[] => {
    //   const speciesNames = new Set<string>()

    //   // 1. First try to get names from name validation results if available
    //   if (nameResults) {
    //     // Add exact matches
    //     nameResults.exact.forEach(match => {
    //       if (match.matched) speciesNames.add(match.matched)
    //       else if (match.original) speciesNames.add(match.original)
    //     })
        
    //     // Add inexact matches that have high confidence
    //     nameResults.inexact.forEach(match => {
    //       if (match.source === 'tnrs' && match.matched) {
    //         speciesNames.add(match.matched)
    //       } else if (match.original) {
    //         speciesNames.add(match.original)
    //       }
    //     })
    //   }

    //   // 2. If no validation results, try to get from data array
    //   if (data?.length && speciesNames.size === 0) {
    //     data.forEach(name => {
    //       if (name && typeof name === 'string' && name.trim()) {
    //         speciesNames.add(name.trim())
    //       }
    //     })
    //   }

    //   // 3. If still no names and we have panel state, try to extract from there
    //   if (speciesNames.size === 0 && panel_id) {
        
    //     if (panel?.data?.output?.data) {
    //       const outputData = panel.data.output.data
    //       if (Array.isArray(outputData)) {
    //         outputData.forEach(row => {
    //           if (row?.botanicalname) {
    //             speciesNames.add(row.botanicalname)
    //           }
    //         })
    //       }
    //     }
    //   }

    //   // 4. If still no names and we have available panels, try to find a plant schedule
    //   if (speciesNames.size === 0 && availablePanels) {
    //     const plantSchedule = availablePanels.find(panel => 
    //       panel.data?.output?.metadata?.type === 'plant-schedule' ||
    //       panel.data?.output?.metadata?.columnToCanonical?.botanicalname
    //     )
        
    //     if (plantSchedule?.data?.output?.data) {
    //       const scheduleData = plantSchedule.data.output.data
    //       if (Array.isArray(scheduleData)) {
    //         scheduleData.forEach(row => {
    //           const botanicalName = row[plantSchedule.data.output.metadata.columnToCanonical.botanicalname]
    //           if (botanicalName && typeof botanicalName === 'string' && botanicalName.trim()) {
    //             speciesNames.add(botanicalName.trim())
    //           }
    //         })
    //       }
    //     }
    //   }

    //   return Array.from(speciesNames)
    // }

    return {
        validateSpeciesNames,
        nameStatus,
        validationLoadingState,
        validateAndRefreshBiosecurity,
        validateAvailability,
        findEppoData,
        fetchPlantTraits,
        validationIcons,
        fetchTnrs
    }
}
