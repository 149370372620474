import { reactive } from 'vue'
import type { FireworksResponse } from '@/types/fireworks'

/**
 * Interface for a validation transformer
 */
export interface ValidationTransformer {
  /**
   * Validate data for a specific tool type
   * @param data The data to validate
   * @param toolId The tool ID
   * @returns Validation result with errors, warnings, and valid status
   */
  validate: (data: any, toolId: string) => Promise<ValidationResult> | ValidationResult
  
  /**
   * Priority level for this validator (higher wins when multiple match)
   */
  priority?: number
  
  /**
   * Description of what this validator checks for
   */
  description?: string
}

/**
 * Interface for validation result
 */
export interface ValidationResult {
  /**
   * Is the data valid
   */
  isValid: boolean
  
  /**
   * Critical errors that prevent the data from being used
   */
  errors: string[]
  
  /**
   * Non-critical warnings about the data
   */
  warnings: string[]
  
  /**
   * Recommendations for improving the data
   */
  suggestions?: string[]
  
  /**
   * Fixed/corrected data if automatic correction was possible
   */
  fixedData?: any
  
  /**
   * Whether the data was automatically fixed
   */
  wasFixed?: boolean
}

/**
 * Transform grid data for name validation
 * Extracts botanical names from grid data for validation
 */
const gridToNameValidationTransformer = {
  validate: (data: any, _toolId: string) => {
    console.log('[gridToNameValidationTransformer] Input data:', data);
    
    // Extract rowData from various possible formats
    let rowData = data?.rowData;
    
    // Log the row data we're processing - to help debug quantity issues
    console.log('[gridToNameValidationTransformer] Processing rowData:', 
      rowData.map(row => ({
        botanicalname: typeof row.botanicalname === 'object' 
          ? (row.botanicalname?.label || row.botanicalname?.value || '') 
          : row.botanicalname,
        quantity: row.quantity || row.qty || 1
      }))
    );
    
    // Extract botanical names and quantities from the data
    const speciesData = rowData
      .filter(row => row.botanicalname)
      .map(row => {
        // Handle botanicalname which might be an object or string
        let name = '';
        let genus = '';
        let family = '';
        
        if (typeof row.botanicalname === 'object' && row.botanicalname !== null) {
          // Extract the species name
          name = row.botanicalname?.label || row.botanicalname?.value || '';
          
          // Check if genus/family is stored directly in the botanicalname object
          if (row.botanicalname.genus) {
            genus = row.botanicalname.genus;
          }
          
          if (row.botanicalname.family) {
            family = row.botanicalname.family;
          }
        } else {
          name = String(row.botanicalname || ''); // Convert to string to ensure toLowerCase works
        }
        
        name = name.trim();
        
        // If no genus/family found in botanicalname, try the standalone fields
        if (!genus && row.genus) {
          if (typeof row.genus === 'object' && row.genus !== null) {
            genus = row.genus?.label || row.genus?.value || '';
          } else {
            genus = row.genus || '';
          }
        }
        
        if (!family && row.family) {
          if (typeof row.family === 'object' && row.family !== null) {
            family = row.family?.label || row.family?.value || '';
          } else {
            family = row.family || '';
          }
        }
        
        // Check various quantity field names with better logging
        let quantity = Number(row.qty)
  
        
        return {
          name: name,
          genus: genus,
          family: family,
          quantity: quantity
        };
      })
      .filter(item => item.name !== '');
    
    // Get unique species names and their quantities
    const uniqueSpeciesMap = new Map();
    
    // Combine quantities for duplicate species
    speciesData.forEach(item => {
      const key = item.name.toLowerCase();
      if (uniqueSpeciesMap.has(key)) {
        // If we already have this species, add the quantities
        const existing = uniqueSpeciesMap.get(key);
        existing.quantity += item.quantity;
        
        // Update genus/family if the current entry has better data
        if ((!existing.genus || existing.genus === 'Unknown') && item.genus) {
          existing.genus = item.genus;
        }
        
        if ((!existing.family || existing.family === 'Unknown') && item.family) {
          existing.family = item.family;
        }
      } else {
        // Otherwise, add it to the map
        uniqueSpeciesMap.set(key, { 
          name: item.name, 
          genus: item.genus || '',
          family: item.family || '',
          quantity: item.quantity 
        });
      }
    });
    
    // Convert map to array
    const uniqueSpecies = Array.from(uniqueSpeciesMap.values());
    
    console.log('[gridToNameValidationTransformer] Extracted species with quantities:', uniqueSpecies);
    
    return {
      isValid: uniqueSpecies.length > 0,
      errors: [],
      warnings: uniqueSpecies.length === 0 ? ['No botanical names found in data'] : [],
      fixedData: {
        speciesData: uniqueSpecies,
        speciesNames: uniqueSpecies.map(item => item.name),
        rowData, // Include original rowData for reference
        metadata: {
          source: 'grid',
          type: 'nameValidation',
          complete: true
        }
      }
    };
  },
  priority: 10,
  description: 'Extracts botanical names and quantities from grid data for name validation'
};

/**
 * Transform name validation results back to grid format
 * Converts validated names to a format the grid can use
 */
const nameValidationToGridTransformer = {
  validate: (data: any, _toolId: string) => {
    console.log('[nameValidationToGridTransformer] Input data:', data);
    
    // If we have validation results, transform them for the grid
    if (data?.result) {
      const nameStatus = data.result;
      const originalData = data.originalData?.rowData || [];
      
      // Create a map of original names to validated names
      const nameMap = new Map();
      
      // Add exact matches first (highest priority)
      if (nameStatus.exact && Array.isArray(nameStatus.exact)) {
        nameStatus.exact.forEach((match: any) => {
          if (match.original && match.matched) {
            nameMap.set(match.original.toLowerCase(), {
              name: match.matched,
              quantity: match.quantity || 1,
              genus: match.genus,
              family: match.family
            });
          }
        });
      }
      
      // Add inexact matches next
      if (nameStatus.inexact && Array.isArray(nameStatus.inexact)) {
        nameStatus.inexact.forEach((match: any) => {
          // Only add if we don't already have an exact match
          if (match.original && match.matched && !nameMap.has(match.original.toLowerCase())) {
            nameMap.set(match.original.toLowerCase(), {
              name: match.matched,
              quantity: match.quantity || 1,
              genus: match.genus,
              family: match.family
            });
          }
        });
      }
      
      // Update the original data with validated names
      const updatedRowData = originalData.map((row: any) => {
        const originalName = typeof row.botanicalname === 'object' ? 
          row.botanicalname?.label || '' : 
          row.botanicalname || '';
        
        const validatedData = nameMap.get(originalName.toLowerCase());
        
        if (validatedData) {
          return {
            ...row,
            botanicalname: validatedData.name,
            // Preserve existing quantity if present, otherwise use the one from validation
            quantity: row.quantity || validatedData.quantity,
            // Add genus and family information if not already present
            genus: row.genus || validatedData.genus,
            family: row.family || validatedData.family
          };
        }
        
        return row;
      });
      
      return {
        isValid: true,
        errors: [],
        warnings: [],
        fixedData: {
          type: 'grid',
          rowData: updatedRowData
        }
      };
    }
    
    // If we have names array directly (from apply button)
    if (data?.names && Array.isArray(data.names)) {
      return {
        isValid: true,
        errors: [],
        warnings: [],
        fixedData: {
          type: 'grid',
          rowData: data.names
        }
      };
    }
    
    // Return a validation error if we can't transform
    return {
      isValid: false,
      errors: ['Invalid data format for name validation conversion'],
      warnings: [],
      fixedData: data
    };
  },
  priority: 10,
  description: 'Transforms validated names back to grid format while preserving quantities'
};


/**
 * Transform grid data for availability validation
 * Extracts botanical names from grid data for availability checks
 */
const gridToAvailabilityTransformer = {
  validate: async (data: any, _toolId: string) => {
    console.log('[gridToAvailabilityTransformer] Input data:', data);
    
    // Extract rowData from various possible formats
    let rowData = data.rowData;
    const plantsData = rowData
      .filter((row: any) => row?.botanicalname)
      .map((row: any) => {
        return {
          name: row?.botanicalname?.label || row?.botanicalname?.value || row?.botanicalname,
          size: row?.size,
          quantity: row?.qty || 1
        };
      })
      .filter((plant: any) => plant.name !== '');

    if (plantsData.length === 0) {
      console.warn('[gridToAvailabilityTransformer] No plants with botanical names found');
      return {
        isValid: true,
        errors: [],
        warnings: ['No plants with botanical names found for availability validation'],
        result: {},
        fixedData: {
          speciesNames: [],
          plantsData,
          rowData,
          metadata: {
            source: 'grid',
            type: 'availabilityValidation',
            complete: true
          }
        }
      }
    }

    // Get unique species names for validation
    const uniqueSpeciesNames = [...new Set(plantsData.map((plant: any) => plant.name))];
    
    console.log('[gridToAvailabilityTransformer] Extracted plants data:', plantsData);
    
    // Define available sizes for matching
    const availableSizes = [
      'Motherstock', 'Community Tray', 'Canes', 'FLASK', 'Plugs', 'Cells', 'Cell tray', 
      'PUNNETS', 'PACKS', 'T50mm', 'T50', 'NT50mm', 'pot n all', '75 mm', '75mm', 
      '100mm', '125mm', '140mm', '140 mm', '175mm', '175 mm', '200mm', '250mm', '300mm',
      '25ltr', '25 Ltr', '45ltr', 'RCB', '50ltr', '75ltr', '60ltr', '100ltr', '120ltr',
      '125ltr', '150ltr', '200ltr', 'Grow Bag', '250ltr', '300ltr', '400ltr', '500ltr',
      '600ltr', '700ltr', '750ltr', '800ltr', '900ltr', '1000ltr', '1500ltr', '2000ltr',
      '2500ltr', '3000ltr', 'Troughs', 'Instant Hedge', 'Bagged', 'Bare Root', 'Strips',
      'Seedling', 'BULBS', 'Cuttings', 'XGR', 'XGR Potted', 'TRUNKS', 'MOUNTED',
      'TREE FERN POT VERTICAL', 'TREE FERN POT HORIZONTAL', 'SHIELD', 'Poly Box', 'Tray',
      'BUNDLE', 'Tiles', 'Vase', 'VIAL', 'Blocks', 'Labels', 'Seed', 'Tissue', 'Division',
      'Bonsai', 'Layering', 'Decorative', 'Log', 'LIFE ON ROCK', 'ALLIED PRODUCT', 'AP Pots',
      'AP Plant Support', 'AP Planter Bags', 'AP Pots to 300mm', 'AP Large Pots 300mm+',
      'AP Tubes/Trays', 'AP Structures', 'AP Plant Labels', 'PACKAGING', 'RUSTIC CONTAINER',
      'PACKTRAY', 'FEATURE'
    ];
    
    // If we have plant data, call the AI to get ec_size recommendations
    if (plantsData.length > 0) {
      console.log('[gridToAvailabilityTransformer] Calling AI for size recommendations');
      try {
        // Prepare data for AI prompt
        const plantsList = plantsData.map((plant: any) => 
          `- ${plant.name} (Size: ${plant.size || 'Not specified'}, Quantity: ${plant.quantity})`
        ).join('\n');
        
        // Create the AI prompt
        const messages = [
          {
            role: "system",
            content: `You are a plant nursery expert system that helps assign container sizes to plants.
You will receive a list of plant species, some with sizes specified. 
Your task is to respond ONLY with a valid JSON object containing recommended sizes for each plant.

For each plant, assign 4 'ec_size' values:
1. First value: The closest match to the original size if provided, or the most appropriate size for the species
2. Second value: The next smaller size option
3. Third value: Another smaller size option
4. Fourth value: Always use '75mm'

Available size options are: ${availableSizes.join(', ')}

Respond ONLY with a JSON object. Do not include any explanations, markdown formatting, or anything else outside the JSON object.
The JSON object should have plant names as keys, and the values should be objects with an 'ec_sizes' array containing the 4 size values.`
          },
          {
            role: "user",
            content: `Please assign appropriate ec_sizes to the following plants:\n${plantsList}`
          }
        ];
        
        // Define the type for the AI response

        console.log('[gridToAvailabilityTransformer] Sending request to AI');
        // Use $fetch with proper typing
        const aiResponse = await $fetch('/api/ai/fwrk-generic', {
          method: 'POST',
          body: {
            model: 'accounts/fireworks/models/llama-v3p3-70b-instruct',
            messages,
            temperature: 0.4,
            max_tokens: 2048,
            response_format: { type: "json_object" }
          }
        });

        let sizeRecommendations = {};
        
        try {
          console.log('[gridToAvailabilityTransformer] AI response:', aiResponse);
          // Parse the response content
          if (aiResponse.content) {
            try {
              // The content should be a JSON string when we use response_format: { type: "json_object" }
              sizeRecommendations = JSON.parse(aiResponse.content);
              console.log('[gridToAvailabilityTransformer] Parsed AI size recommendations:', sizeRecommendations);
            } catch (parseError) {
              // If parsing fails, log the error but don't throw - try to use the content directly
              console.error('[gridToAvailabilityTransformer] Error parsing content as JSON:', parseError);
              console.log('[gridToAvailabilityTransformer] Raw content:', aiResponse.content);
            }
          }
        } catch (error) {
          console.error('[gridToAvailabilityTransformer] Error processing AI response:', error);
          console.log('[gridToAvailabilityTransformer] Raw AI response:', aiResponse);
        }
        
        return {
          isValid: uniqueSpeciesNames.length > 0,
          errors: [],
          warnings: [],
          result: {}, // Empty result object that will be filled by the availability service
          fixedData: {
            speciesNames: uniqueSpeciesNames,
            plantsData,
            sizeRecommendations, // Include the AI-generated size recommendations
            rowData, // Include original rowData for reference
            metadata: {
              source: 'grid',
              type: 'availabilityValidation',
              complete: true
            }
          }
        };
      } catch (error) {
        console.error('[gridToAvailabilityTransformer] Error getting AI recommendations:', error);
        // Continue with basic validation without AI recommendations
      }
    }
    
    // Return basic validation result if AI call fails or no plants found
    return {
      isValid: uniqueSpeciesNames.length > 0,
      errors: [],
      warnings: uniqueSpeciesNames.length === 0 ? ['No botanical names found in data'] : [],
      result: {}, // Empty result object that will be filled by the availability service
      fixedData: {
        speciesNames: uniqueSpeciesNames,
        plantsData,
        rowData, // Include original rowData for reference
        metadata: {
          source: 'grid',
          type: 'availabilityValidation',
          complete: true
        }
      }
    };
  },
  priority: 10,
  description: 'Extracts botanical names from grid data for availability validation and assigns optimal container sizes'
};

/**
 * Composable for data validation transformers
 */
export const useValidationTransformers = () => {
  // Initialize the validator registry
  const validatorRegistry = reactive<Map<string, ValidationTransformer>>(new Map())
  
  /**
   * Register a validator for a specific tool type
   */
  const registerValidator = (toolId: string, validator: ValidationTransformer) => {
    validatorRegistry.set(toolId, validator)
  }
  
  /**
   * Get a validator for a specific tool type
   */
  const getValidator = (toolId: string): ValidationTransformer | undefined => {
    // Try exact match first
    const exactMatch = validatorRegistry.get(toolId)
    if (exactMatch) return exactMatch
    
    // Try wildcard match
    const wildcardMatch = validatorRegistry.get('*')
    if (wildcardMatch) return wildcardMatch
    
    // No match found
    return undefined
  }
  
  /**
   * Get all registered validators
   */
  const getAllValidators = () => {
    return Array.from(validatorRegistry.entries()).map(([toolId, validator]) => ({
      toolId,
      validator
    }))
  }
  
  /**
   * Validate data for a specific tool type
   */
  const validateData = async (data: any, toolId: string): Promise<ValidationResult> => {
    const validator = getValidator(toolId)
    
    if (validator) {
      try {
        return await validator.validate(data, toolId)
      } catch (error) {
        console.error(`Error validating data for ${toolId}:`, error)
        return {
          isValid: false,
          errors: [`Validation error: ${String(error)}`],
          warnings: []
        }
      }
    }
    
    // If no validator is found, assume data is valid
    return {
      isValid: true,
      errors: [],
      warnings: []
    }
  }
  
  /**
   * Register default validators
   */
  const registerDefaultValidators = () => {
    // Register grid data to name validation transformer
    registerValidator('plantgrid', gridToNameValidationTransformer as ValidationTransformer)    
    // Register grid data to availability validation transformer
    registerValidator('availabilityValidation', gridToAvailabilityTransformer as ValidationTransformer)
  }
  
  // Register default validators on initialization
  registerDefaultValidators()
  
  return {
    registerValidator,
    getValidator,
    getAllValidators,
    validateData
  }
}

// Helper function to create a custom validator
export const createValidator = (
  validateFn: (data: any, toolId: string) => ValidationResult | Promise<ValidationResult>,
  options?: { priority?: number; description?: string }
): ValidationTransformer => {
  return {
    validate: validateFn,
    priority: options?.priority || 1,
    description: options?.description || ''
  }
} 