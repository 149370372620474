<script setup lang="ts">
import type { VChart } from '#components'
import { use } from 'echarts/core'
import { ToolboxComponent, DataZoomComponent, BrushComponent, GraphicComponent } from 'echarts/components'
import { ScatterChart } from 'echarts/charts'
import { graphic } from 'echarts' // Add this import
import * as d3 from 'd3-array'
import { line, curveCatmullRomClosed } from 'd3-shape'
import { Button } from '@/components/ui/button'
import chroma from 'chroma-js'
import { useUiStore } from '~/stores/uiStore'
import { storeToRefs } from 'pinia'


use([ToolboxComponent, ScatterChart, DataZoomComponent, BrushComponent, GraphicComponent])

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  cm: {
    type: String,
    default: 'dark'
  },
  labels: {
    type: Array,
    default: () => []
  },
  showAxesAndZoom: {
    type: Boolean,
    default: true
  },
  hdbscanLabels: {
    type: Array,
    default: () => []
  },
  isHdbscanLoading: {
    type: Boolean,
    default: false
  },
  clusterSummaries: {
    type: Array,
    default: () => []
  },
  searchResults: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  desirableShort: {
    type: Object,
    default: () => ({})
  },
  undesirableShort: {
    type: Object,
    default: () => ({})
  },
  selectedQuality: {
    type: String,
    required: false,
    default: 'Sunlight'
  }
})

const emit = defineEmits(['markerHover', 'hullHover', 'update:showAxesAndZoom'])

const chart = ref<InstanceType<typeof VChart> | null>(null)
const chartContainer = ref(null)

const uiStore = useUiStore()
const { plantsInDock } = storeToRefs(uiStore)
const { addPlantToDock, removePlantFromDock, setAlertConfig } = uiStore


const initOptions = ref({
  renderer: 'svg'
})

const colors = computed(() => {
  if (props.cm === 'dark') {
    return {
      mutedForeground: 'hsl(90 20.2% 65.1%)',
      accent: 'hsl(160 32.6% 21.5%)',
      muted: 'hsl(160 22.6% 13.5%)',
      primary: 'hsl(90 20% 84%)'
    }
  } else {
    return {
      mutedForeground: 'hsl(160 21% 36.9%)',
      accent: 'hsl(160 30% 79.1%)',
      muted: 'hsl(90 11% 91%)',
      primary: 'hsl(120 27.4% 21.2%)'
    }
  }
})

const option = ref(null)

const colorScale = (label: number) => {
  const hue = (label * 137.508) % 360 // Use golden ratio to generate distinct colors
  return `hsl(${hue}, 70%, 60%)`
}

const { calculateClusterHulls } = useDataUtils()

const clusterHulls = computed(() => {
  if (props.data && props.hdbscanLabels) {
    let hulls = calculateClusterHulls(props.data, props.hdbscanLabels, 0.1)
    return hulls?.hulls || {}
  }
  return {}
})

// const smoothHull = (points: number[][]) => {
//   const lineGenerator = line().curve(curveCatmullRomClosed.alpha(0.9))
//   return lineGenerator(points)
// }

const showHulls = ref(true)

const toggleHulls = () => {
  showHulls.value = !showHulls.value
  updateChartData()
}

const animationConfig = {
  animation: true,
  animationDuration: 300,
  animationEasing: 'cubicOut',
  animationDurationUpdate: 300,
  animationEasingUpdate: 'cubicInOut',
};

const updateChart = () => {
  requestAnimationFrame(() => {
    if (!props.data || props.data.length === 0 || !props.labels || props.labels.length === 0) {
      option.value = null
      return
    }

    console.log('Cluster Summaries:', props.clusterSummaries); // Debug log

    const hullSeries = Object.entries(clusterHulls.value || {}).map(([label, hull]) => {
      const numericLabel = Number(label);
      const clusterSummary = props.clusterSummaries?.[numericLabel];
      const isLargeCluster = clusterSummary?.includes('too many similarities to differentiate');
      
      if (isLargeCluster) {
        return {
          ...animationConfig,
          type: 'line',
          data: hull,
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 0
          },
          emphasis: {
            areaStyle: {
              opacity: 0.5
            }
          },
          z: -1,
          silent: false,
          areaStyle: {
            color: 'rgba(128, 128, 128, 0.15)',
            opacity: 0.3,
            blendMode: 'multiply'
          }
        };
      }
      
      const baseColor = colorScale(numericLabel);
      const gradientColors = createGradientColors(baseColor);
      
      return {
        ...animationConfig,
        type: 'line',
        data: hull,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 0
        },
        emphasis: {
          areaStyle: {
            opacity: 0.5
          }
        },
        z: -1,
        silent: false,
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: gradientColors[0] },
            { offset: 1, color: gradientColors[1] }
          ]),
          opacity: 0.3,
          blendMode: 'multiply'
        }
      }
    })

    option.value = {
      ...animationConfig,
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          if (params.seriesType === 'scatter') {
            const label = props.labels[params.dataIndex]
            const clusterIndex = props.hdbscanLabels[params.dataIndex]
            
            // Get descriptions only for selected quality
            const desirableDescription = props.desirableShort[label]?.[props.selectedQuality]
            const undesirableDescription = props.undesirableShort[label]?.[props.selectedQuality]
            
            // Create HTML only if descriptions exist for the selected quality
            const desirableHtml = desirableDescription ? `
              <div class="text-xs opacity-90 flex gap-0  overflow-hidden truncate -ml-3">
                <i class="text-white rotate-180 flex-shrink-0 scale-50 -mt-2 ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 56 56">
                    <path fill="currentColor" d="M43.367 18.883c0-7.031-4.383-12.914-10.266-12.914H28.93c4.219 3.117 6.188 7.804 6.094 13.148c-.094 5.953-2.414 10.196-4.476 12.774h3.445c5.297 0 9.375-5.696 9.375-13.008M12.633 4.774c-3.094-.023-5.32.211-6.633.54c-1.828.446-3.656 1.618-3.656 3.868c0 .914.234 1.57.562 2.085c.164.305.14.54-.117.657c-1.477.656-2.719 1.992-2.719 3.867c0 1.078.282 2.016.82 2.695c.282.352.212.657-.21.89c-1.078.61-1.805 1.97-1.805 3.517c0 1.101.352 2.273 1.008 2.859c.351.328.304.562-.117.89c-.75.586-1.172 1.711-1.172 3.047c0 2.297 1.758 4.172 4.101 4.172h8.367c2.11 0 3.54 1.078 3.54 2.836c0 3.211-3.985 9.024-3.985 13.242c0 2.18 1.43 3.47 3.281 3.47c1.688 0 2.532-1.173 3.446-2.954c3.492-6.89 8.226-12.422 11.836-17.203c3.047-4.055 4.523-7.5 4.593-13.102c.118-8.672-6.867-15.257-17.906-15.328l-3.234-.047Z"/>
                  </svg>
                </i>
                <span class="line-clamp-2 truncate">
                  <p class="truncate min-h-[20px] max-h-[30px] flex align-end">${desirableDescription}</p>
                </span>
              </div>
            ` : ''

            const undesirableHtml = undesirableDescription ? `
              <div class="text-xs opacity-90 flex gap-0  overflow-hidden truncate -ml-3">
                <i class="text-white flex-shrink-0 scale-50">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 56 56">
                    <path fill="currentColor" d="M12.039 4.234c-1.828.446-3.656 1.618-3.656 3.868c0 .914.234 1.57.562 2.085c.164.305.14.54-.117.657c-1.477.656-2.719 1.992-2.719 3.867c0 1.078.282 2.016.82 2.695c.282.352.212.657-.21.89c-1.078.61-1.805 1.97-1.805 3.517c0 1.101.352 2.273 1.008 2.859c.351.328.304.562-.117.89c-.75.586-1.172 1.711-1.172 3.047c0 2.297 1.758 4.172 4.101 4.172h8.367c2.11 0 3.54 1.078 3.54 2.836c0 3.211-3.985 9.024-3.985 13.242c0 2.18 1.43 3.47 3.281 3.47c1.688 0 2.532-1.173 3.446-2.954c3.492-6.89 8.226-12.422 11.836-17.203c3.047-4.055 4.523-7.5 4.593-13.102c.118-8.672-6.867-15.257-17.906-15.328l-3.234-.047c-3.094-.023-5.32.211-6.633.54m39.328 14.649c0-7.031-4.383-12.914-10.266-12.914H36.93c4.218 3.117 6.187 7.804 6.093 13.148c-.093 5.953-2.414 10.196-4.476 12.774h3.445c5.297 0 9.375-5.696 9.375-13.008"/>
                  </svg>
                </i>
                <span class="line-clamp-2 truncate">
                  ${undesirableDescription}
                </span>
              </div>
            ` : ''

            return `
              <div class="relative w-[350px] aspect-[3/2] bg-white/5 backdrop-blur-xl rounded-xl border border-white/10 border-8 z-[0]">
                <img 
                  alt="" 
                  onerror="this.style.display='none'; this.nextElementSibling.style.display='block'" 
                  class="absolute inset-0 w-full h-full object-cover rounded-md" 
                  src="https://s3.us-east-1.wasabisys.com/superseeded/images/converted_images/${label.replace(/\s+/g, '_')}/0001.jpg" 
                />
                <div class="hidden absolute inset-0 bg-slate-200 dark:bg-slate-800 rounded-md"></div>
                <div class="absolute inset-0 bg-gradient-to-t from-slate-800/90 via-slate-800/50 to-transparent rounded-md"></div>
                <div class="absolute inset-x-0 bottom-0 p-4 text-white space-y-2">
                  <div class="font-semibold text-base truncate">${label}</div>
                  ${desirableHtml}
                  ${undesirableHtml}
                </div>
              </div>
            `
          } else if (params.seriesType === 'line') {
            return params.series.tooltip.formatter(params)
          }
        },
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'transparent',
        borderRadius: 8,
        padding: 0,
        className: 'echarts-tooltip-dark',
        extraCssText: 'backdrop-filter: blur(8px); box-shadow: 0 8px 32px rgba(0,0,0,0.1); z-index: 0 !important;'
      },
      grid: {
        left: props.showAxesAndZoom ? '10%' : '5%',
        right: props.showAxesAndZoom ? '10%' : '5%',
        top: '5%',
        bottom: props.showAxesAndZoom ? '15%' : '5%',
        containLabel: props.showAxesAndZoom,
      },
      xAxis: {
        type: 'value',
        show: props.showAxesAndZoom,
        z: -10,

      },
      yAxis: {
        type: 'value',
        show: props.showAxesAndZoom,
        z: -10
      },
      series: [
        {
          ...animationConfig,
          type: 'scatter',
          data: props.data.map((point, index) => {
            const clusterIndex = props.hdbscanLabels[index];
            const clusterSummary = props.clusterSummaries?.[clusterIndex];
            const isLargeCluster = clusterSummary?.includes('too many similarities to differentiate');
            
            let color = clusterIndex === -1 
              ? 'rgba(255, 255, 255, 0.5)' 
              : isLargeCluster 
                ? 'rgba(128, 128, 128, 0.5)' 
                : colorScale(clusterIndex);
            
            color = adjustNearWhiteColor(color);

            return {
              value: point,
              itemStyle: { color },
              label: {
                show: false
              }
            }
          }),
          symbolSize: 8,
          opacity: 0.7,
          emphasis: {
            scale: 2
          },
          z: 2,
          selectedMode: 'multiple',
          select: {
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 2,
              shadowBlur: 10,
              shadowColor: 'rgba(0,0,0,0.2)'
            }
          }
        },
        ...(clusterHulls.value ? hullSeries : [])
      ],
      textStyle: {
        color: colors.value.primary
      },
      dataZoom: props.showAxesAndZoom
        ? [
            {
              type: 'slider',
              xAxisIndex: 0,
              filterMode: 'none'
            },
            {
              type: 'slider',
              yAxisIndex: 0,
              filterMode: 'none'
            },
            {
              type: 'inside',
              xAxisIndex: 0,
              filterMode: 'none'
            },
            {
              type: 'inside',
              yAxisIndex: 0,
              filterMode: 'none'
            }
          ]
        : [
            {
              type: 'inside',
              xAxisIndex: 0,
              filterMode: 'none'
            },
            {
              type: 'inside',
              yAxisIndex: 0,
              filterMode: 'none'
            }
          ],
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          saveAsImage: {}
        },
        iconStyle: {
          borderColor: colors.value.primary
        },
        emphasis: {
          iconStyle: {
            borderColor: colors.value.primary
          }
        }
      },
      brush: {
        toolbox: ['rect'],
        xAxisIndex: 0,
        yAxisIndex: 0,
        brushType: 'rect',
        brushMode: 'multiple',
        brushStyle: {
          borderWidth: 1,
          color: 'rgba(120,140,180,0.1)',
          borderColor: 'rgba(120,140,180,0.8)'
        },
        throttleType: 'debounce',
        throttleDelay: 300,
        removeOnClick: true
      }
    }
    console.log('Updated chart option:', option.value)
    chart.value?.setOption(option.value)
  })
}

// Watch for changes in props and colors, and update the chart
watch([() => props.data, () => props.labels, () => props.hdbscanLabels, colors, () => props.showAxesAndZoom], updateChart, { immediate: true })

const hasValidData = ref(false)

watch(() => option.value, (newOption) => {
  hasValidData.value = newOption !== null
})

const getNearestPoints = (index: number, count: number) => {
  if (!props.data || props.data.length === 0) return []
  
  const point = props.data[index]
  return props.data
    .map((p, i) => ({
      index: i,
      distance: Math.sqrt(Math.pow(p[0] - point[0], 2) + Math.pow(p[1] - point[1], 2))
    }))
    .sort((a, b) => a.distance - b.distance)
    .slice(1, count + 1) // Start from 1 to exclude the hovered point itself
    .map(p => p.index)
}

const createGradientColors = (baseColor: string) => {
  const hue = parseInt(baseColor.slice(4, -1).split(',')[0]);
  const startHue = (hue - 15 + 360) % 360;
  const endHue = (hue + 85) % 360;
  
  // Replace colorgrad with chroma bezier interpolation
  const gradient = chroma.bezier([
    `hsl(${startHue}, 70%, 60%)`,
    baseColor,
    `hsl(${endHue}, 70%, 60%)`
  ]);
  
  return [
    gradient(0).hex(),
    gradient(1).hex()
  ];
};

const isPointInSearchResults = (index: number) => {
  if (!props.searchResults.length) return false
  const label = props.labels[index]?.replaceAll(' ', '_')
  return props.searchResults.includes(label)
}

const getSelectedState = (plantName: string) => {
  return plantsInDock.value.some(p => p.species === plantName)
}

const updateChartData = () => {
  if (!chart.value || !props.data || props.data.length === 0) return;

  const updatedData = props.data.map((point, idx) => {
    const clusterIndex = props.hdbscanLabels[idx];
    const isSearchResult = isPointInSearchResults(idx);
    const plantName = props.labels[idx];
    const isSelected = getSelectedState(plantName);
    
    // Check if this point belongs to a large cluster
    const clusterSummary = props.clusterSummaries?.[clusterIndex];
    const isLargeCluster = clusterSummary?.includes('too many similarities to differentiate');
    
    // Use grey for points in large clusters, otherwise use normal color scheme
    let color = clusterIndex === -1 
      ? 'rgba(255, 255, 255, 0.5)' 
      : isLargeCluster 
        ? 'rgba(128, 128, 128, 0.5)' 
        : colorScale(clusterIndex);
        
    const adjustedColor = adjustNearWhiteColor(color);
    
    return {
      value: point,
      itemStyle: { 
        color: adjustedColor,
        opacity: isSearchResult ? 1 : 0.3,
        borderColor: isSelected ? '#fff' : 'transparent',
        borderWidth: isSelected ? 2 : 0
      },
      symbolSize: isSelected || isSearchResult ? 12 : 8,
      label: {
        show: isSearchResult,
        formatter: plantName,
        color: '#fff',
        backgroundColor: adjustedColor,
        padding: [4, 8],
        borderRadius: 4
      }
    };
  });

  const hullSeries = Object.entries(clusterHulls.value || {}).map(([label, hull]) => {
    const numericLabel = Number(label);
    const clusterSummary = props.clusterSummaries?.[numericLabel];
    const isLargeCluster = clusterSummary?.includes('too many similarities to differentiate');
    
    if (isLargeCluster) {
      return {
        type: 'line',
        data: hull,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 0
        },
        areaStyle: {
          color: 'rgba(128, 128, 128, 0.15)',
          opacity: showHulls.value ? 0.3 : 0
        },
        emphasis: {
          areaStyle: {
            opacity: showHulls.value ? 0.5 : 0
          }
        },
        z: 1,
        silent: false
      };
    }
    
    const baseColor = colorScale(numericLabel);
    const gradientColors = createGradientColors(baseColor);
    
    return {
      type: 'line',
      data: hull,
      smooth: true,
      symbol: 'none',
      lineStyle: {
        width: 0
      },
      areaStyle: {
        color: new graphic.LinearGradient(0, 0, 1, 1, [
          { offset: 0, color: gradientColors[0] },
          { offset: 1, color: gradientColors[1] }
        ]),
        opacity: showHulls.value ? 0.3 : 0
      },
      emphasis: {
        areaStyle: {
          opacity: showHulls.value ? 0.5 : 0
        }
      },
      z: 1,
      silent: false
    };
  });

  const option = {
    grid: {
      left: props.showAxesAndZoom ? '10%' : '5%',
      right: props.showAxesAndZoom ? '10%' : '5%',
      top: '5%',
      bottom: props.showAxesAndZoom ? '15%' : '5%',
      containLabel: props.showAxesAndZoom
    },
    xAxis: {
      type: 'value',
      show: props.showAxesAndZoom
    },
    yAxis: {
      type: 'value',
      show: props.showAxesAndZoom
    },
    series: [
      {
        type: 'scatter',
        data: updatedData
      },
      ...(clusterHulls.value ? hullSeries : [])
    ],
    dataZoom: props.showAxesAndZoom
      ? [
          {
            type: 'slider',
            xAxisIndex: 0,
            filterMode: 'none'
          },
          {
            type: 'slider',
            yAxisIndex: 0,
            filterMode: 'none'
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            filterMode: 'none'
          },
          {
            type: 'inside',
            yAxisIndex: 0,
            filterMode: 'none'
          }
        ]
      : [
          {
            type: 'inside',
            xAxisIndex: 0,
            filterMode: 'none'
          },
          {
            type: 'inside',
            yAxisIndex: 0,
            filterMode: 'none'
          }
        ],
    toolbox: props.showAxesAndZoom ? {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: {}
      },
      iconStyle: {
        borderColor: colors.value.primary
      },
      emphasis: {
        iconStyle: {
          borderColor: colors.value.primary
        }
      }
    } : undefined
  };

  chart.value.setOption(option);
}

const hoveredCluster = ref<number | null>(null);
const hoveredPoint = ref<number | null>(null);

const onChartMouseMove = (params: any) => {
  if (params.componentType === 'series') {
    let newHoveredCluster: number | null = null;
    let newHoveredPoint: number | null = null;

    if (params.componentSubType === 'scatter') {
      // Only update hover states for scatter points
      newHoveredCluster = props.hdbscanLabels[params.dataIndex];
      newHoveredPoint = params.dataIndex;
      
      const label = props.labels[params.dataIndex];
      const baseColor = colorScale(newHoveredCluster);
      const gradientColors = createGradientColors(baseColor);
      const textColor = '#fff';
      emit('markerHover', { label, clusterIndex: newHoveredCluster, gradientColors, textColor });
      
      // Only update focus when hovering scatter points
      if (newHoveredCluster !== hoveredCluster.value || newHoveredPoint !== hoveredPoint.value) {
        hoveredCluster.value = newHoveredCluster;
        hoveredPoint.value = newHoveredPoint;
        updateFocus();
      }
    } else if (params.componentSubType === 'line') {
      // For hull hovers, only update hull-related states
      newHoveredCluster = params.seriesIndex - 1;
      const color = colorScale(newHoveredCluster);
      const textColor = color;
      emit('hullHover', { clusterIndex: newHoveredCluster, color, textColor, event: params.event });
      
      // Update hull opacity without affecting scatter labels
      updateHullOpacity(newHoveredCluster);
    }
  }
}

// New function to handle hull opacity updates separately
const updateHullOpacity = (hoveredClusterIndex: number) => {
  const hullSeries = Object.entries(clusterHulls.value || {}).map(([label, hull]) => {
    const numericLabel = Number(label);
    return {
      ...animationConfig,
      type: 'line',
      data: hull,
      areaStyle: {
        opacity: numericLabel === hoveredClusterIndex ? 0.5 : hullOpacity.value,
      },
    };
  });

  chart.value?.setOption({
    series: [
      chart.value.getOption().series[0], // Keep scatter series unchanged
      ...hullSeries
    ]
  });
}

const onChartMouseOut = () => {
  hoveredCluster.value = null;
  hoveredPoint.value = null;
  resetChartFocus();
  emit('markerHover', { label: null, clusterIndex: null, gradientColors: null });
}

const hullOpacity = ref(0.3)
const hullTransitionActive = ref(false)

const getNearestPointsWithDistances = (index: number, count: number) => {
  if (!props.data || props.data.length === 0) return []
  
  const point = props.data[index]
  return props.data
    .map((p, i) => ({
      index: i,
      distance: Math.sqrt(Math.pow(p[0] - point[0], 2) + Math.pow(p[1] - point[1], 2))
    }))
    .sort((a, b) => a.distance - b.distance)
    .slice(1, count + 1) // Start from 1 to exclude the hovered point itself
}

const calculateFontSize = (distance: number, maxDistance: number) => {
  const minFontSize = 12
  const maxFontSize = 22
  const fontSize = maxFontSize - (distance / maxDistance) * (maxFontSize - minFontSize)
  return Math.max(minFontSize, Math.min(maxFontSize, fontSize))
}

const calculateOpacity = (distance: number, maxDistance: number) => {
  const minOpacity = 0.3
  const maxOpacity = 1
  const opacity = maxOpacity - (distance / maxDistance) * (maxOpacity - minOpacity)
  return Math.max(minOpacity, Math.min(maxOpacity, opacity))
}

const updateFocus = () => {
  const nearestPointsWithDistances = hoveredPoint.value !== null ? getNearestPointsWithDistances(hoveredPoint.value, 5) : [];
  const maxDistance = nearestPointsWithDistances.length > 0 ? nearestPointsWithDistances[nearestPointsWithDistances.length - 1].distance : 0;

  hullTransitionActive.value = true
  hullOpacity.value = hoveredCluster.value === null ? 0.3 : 0.1

  chart.value?.setOption({
    ...animationConfig,
    series: [
      {
        ...animationConfig,
        type: 'scatter',
        data: props.data.map((point, idx) => {
          const pointClusterIndex = props.hdbscanLabels[idx];
          const color = pointClusterIndex === -1 ? 'rgba(255, 255, 255, 0.5)' : colorScale(pointClusterIndex);
          const adjustedColor = adjustNearWhiteColor(color);
          
          const isHoveredPoint = hoveredPoint.value !== null && idx === hoveredPoint.value;
          const nearestPoint = nearestPointsWithDistances.find(p => p.index === idx);
          const isNearestPoint = !!nearestPoint;
          const isInHoveredCluster = hoveredCluster.value !== null && pointClusterIndex === hoveredCluster.value;
          
          const shouldShowLabel = isHoveredPoint || isNearestPoint || (hoveredPoint.value === null && isInHoveredCluster);
          
          let fontSize = 8; // Default font size
          let labelOpacity = 1 // Default opacity

          if (isNearestPoint && nearestPoint) {
            fontSize = calculateFontSize(nearestPoint.distance, maxDistance);
            labelOpacity = calculateOpacity(nearestPoint.distance, maxDistance);
          }

          return {
            value: point,
            animation: true,
            itemStyle: { 
              color: adjustedColor,
              opacity: shouldShowLabel ? labelOpacity : 0.3,
              animationDuration: 1000,
              animationDurationUpdate: 500,
              animationEasing: "cubicInOut",
              animationEasingUpdate: "cubicInOut",
              animationThreshold: 2000
            },
            select: {
              itemStyle: {
                opacity: 1
              }
            },
            emphasis: isHoveredPoint ? {
              itemStyle: {
                borderWidth: 2,
                color: adjustedColor
              }
            } : {},
            label: {
              show: true,
              formatter: props.labels[idx],
              color: darkenColor(adjustedColor),
              position: 'top',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              fontSize: fontSize,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: 5,
              padding: 4,
              opacity: shouldShowLabel ? labelOpacity : 0,
              extraCssText: 'backdrop-filter: blur(10px)',
              // Add these animation properties
              animation: true,
              animationDuration: 300,
              animationDurationUpdate: 300,
              animationEasing: 'cubicInOut',
              animationEasingUpdate: 'cubicInOut',
              // animationDelay: (dataIndex: number) => dataIndex * 10,
              // animationDelayUpdate: (dataIndex: number) => dataIndex * 10,
            }
          }
        })
      },
      // Update hull series
      ...Object.entries(clusterHulls.value || {}).map(([label, hull]) => {
        const numericLabel = Number(label);
        const baseColor = colorScale(numericLabel);
        const gradientColors = createGradientColors(baseColor);
        
        return {
          ...animationConfig,
          type: 'line',
          data: hull,
          areaStyle: {
            opacity: Number(label) === hoveredCluster.value ? 0.5 : hullOpacity.value,
          },
        };
      })
    ]
  });
}

const resetChartFocus = () => {
  hullTransitionActive.value = true
  hullOpacity.value = 0.3

  chart.value?.setOption({
    ...animationConfig,
    series: [
      {
        ...animationConfig,
        type: 'scatter',
        data: props.data.map((point, idx) => {
          const pointClusterIndex = props.hdbscanLabels[idx];
          // Check for large clusters here too
          const clusterSummary = props.clusterSummaries?.[pointClusterIndex];
          const isLargeCluster = clusterSummary?.includes('too many similarities to differentiate');
          
          // Maintain grey color for large clusters
          let color = pointClusterIndex === -1 
            ? 'rgba(255, 255, 255, 0.5)' 
            : isLargeCluster 
              ? 'rgba(128, 128, 128, 0.5)' 
              : colorScale(pointClusterIndex);
              
          const adjustedColor = adjustNearWhiteColor(color);
          
          return {
            value: point,
            itemStyle: { 
              color: adjustedColor,
              opacity: 1,
              animationDuration: 1000,
              animationDurationUpdate: 500,
              animationEasing: "cubicInOut",
              animationEasingUpdate: "cubicInOut",
            },
            label: {
              show: false,
              formatter: props.labels[idx],
              color: darkenColor(adjustedColor),
              position: 'top',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              animation: true,
              animationDuration: 300,
              animationDurationUpdate: 300,
              animationEasing: 'cubicInOut',
              animationEasingUpdate: 'cubicInOut',
            }
          }
        })
      },
      // Reset hull series
      ...Object.entries(clusterHulls.value || {}).map(([label, hull]) => {
        const numericLabel = Number(label);
        const clusterSummary = props.clusterSummaries?.[numericLabel];
        const isLargeCluster = clusterSummary?.includes('too many similarities to differentiate');
        
        if (isLargeCluster) {
          return {
            ...animationConfig,
            type: 'line',
            data: hull,
            areaStyle: {
              color: 'rgba(128, 128, 128, 0.15)',
              opacity: hullOpacity.value,
            },
          };
        }
        
        const baseColor = colorScale(numericLabel);
        const gradientColors = createGradientColors(baseColor);
        
        return {
          ...animationConfig,
          type: 'line',
          data: hull,
          areaStyle: {
            color: new graphic.LinearGradient(0, 0, 1, 1, [
              { offset: 0, color: gradientColors[0] },
              { offset: 1, color: gradientColors[1] }
            ]),
            opacity: hullOpacity.value,
          },
        };
      })
    ]
  });
}

const adjustNearWhiteColor = (color: string) => {
  if (color.startsWith('hsl')) {
    const [h, s, l] = color.match(/\d+/g).map(Number)
    if (l > 80) {
      return `hsl(${h}, ${s}%, 50%)` // More drastic adjustment: 50% lightness
    }
  } else if (color.startsWith('rgba')) {
    const [r, g, b, a] = color.match(/[\d.]+/g).map(Number)
    if (r > 220 && g > 220 && b > 220) {
      return `rgba(150, 150, 150, ${a})` // More drastic adjustment: darker gray
    }
  }
  return color
}

const toggleAxesAndZoom = () => {
  emit('update:showAxesAndZoom', !props.showAxesAndZoom)
}

// Add this helper function to calculate contrast color
const getContrastColor = (color: string) => {
  // Simple contrast calculation, you might want to use a more sophisticated method
  const rgb = color.match(/\d+/g).map(Number);
  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return brightness > 128 ? 'black' : 'white';
}

// Add this new helper function to darken colors
const darkenColor = (color: string) => {
  if (color.startsWith('hsl')) {
    const [h, s, l] = color.match(/\d+/g).map(Number);
    const newL = l > 60 ? Math.max(30, l - 30) : Math.max(20, l - 20);
    const newS = Math.min(100, s + 10); // Increase saturation slightly
    return `hsl(${h}, ${newS}%, ${newL}%)`;
  } else if (color.startsWith('rgba')) {
    const [r, g, b, a] = color.match(/[\d.]+/g).map(Number);
    const darkenFactor = Math.min(0.7, Math.max(0.5, 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255));
    return `rgba(${Math.floor(r * darkenFactor)}, ${Math.floor(g * darkenFactor)}, ${Math.floor(b * darkenFactor)}, ${a})`;
  }
  return color;
}

// Add this new function to ensure contrast with the background
const ensureContrast = (color: string, backgroundColor: string) => {
  const getRelativeLuminance = (c: string) => {
    const rgb = c.match(/\d+/g).map(Number);
    const [r, g, b] = rgb.map(v => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  const contrastRatio = (c1: string, c2: string) => {
    const l1 = getRelativeLuminance(c1);
    const l2 = getRelativeLuminance(c2);
    return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
  };

  let adjustedColor = darkenColor(color);
  let contrast = contrastRatio(adjustedColor, backgroundColor);

  while (contrast < 4.5) {  // WCAG AA standard for normal text
    adjustedColor = darkenColor(adjustedColor);
    contrast = contrastRatio(adjustedColor, backgroundColor);
  }

  return adjustedColor;
}

// Watch for changes in props and update the chart
watch(
  [
    () => props.data, 
    () => props.labels, 
    () => props.hdbscanLabels, 
    colors, 
    () => props.showAxesAndZoom, 
    showHulls,
    () => props.searchResults
  ], 
  () => {
    updateChartData();
  }, 
  { deep: true }
);

const onChartClick = (params: any) => {
  if (params.componentType === 'series' && params.componentSubType === 'scatter') {
    const plantName = params.data.label.formatter;
    const isAlreadySelected = getSelectedState(plantName);
    
    if (isAlreadySelected) {
      // Find and remove the plant from dock
      const plantToRemove = plantsInDock.value.find(p => p.species === plantName);
      if (plantToRemove) {
        removePlantFromDock(plantToRemove);
      }
    } else {
      // Add new plant to dock
      const plant = {
        id: params.dataIndex,
        species: plantName,
        photo: `https://s3.us-east-1.wasabisys.com/superseeded/images/converted_images/${plantName.replace(/\s+/g, '_')}/0001.jpg`,
        value: params.data.value
      };
      addPlantToDock(plant);
    }
    
    // Force update chart to reflect selection state
    updateChartData();
  }
};

const validClusterSummaries = computed(() => {
  if (!props.clusterSummaries || !props.hdbscanLabels) return []
  
  // Get unique cluster labels (excluding noise points)
  const uniqueClusters = [...new Set(props.hdbscanLabels)].filter(label => label !== -1)
  
  return uniqueClusters.map(clusterIndex => ({
    clusterIndex,
    summary: props.clusterSummaries[clusterIndex] || 'No summary available'
  }))
})

// Update your logging to use the computed property
watch(validClusterSummaries, (newSummaries) => {
  console.log('Valid Cluster Summaries:', newSummaries)
}, { immediate: true })

// Add a watch for hdbscanLabels changes instead
watch(() => props.hdbscanLabels, (newLabels, oldLabels) => {
  if (newLabels?.length && (!oldLabels?.length || !arraysEqual(newLabels, oldLabels))) {
    // Only emit when clustering actually changes
    emit('clusteringChanged')
  }
}, { deep: true })

// Helper function to compare arrays
const arraysEqual = (a: number[], b: number[]) => {
  if (a.length !== b.length) return false
  return a.every((val, index) => val === b[index])
}

// Update the onBrushSelected handler
const onBrushSelected = (params: any) => {
  // Only trigger when there's a selection and it's finished
  if (params.batch?.[0]?.areas?.length === 0) {
    // Selection was cleared or cancelled
    return;
  }

  if (params.batch[0]?.selected?.[0]?.dataIndex?.length > 0) {
    const selectedIndices = params.batch[0].selected[0].dataIndex
    const selectedPlants = selectedIndices.map((i: number) => ({
      id: i,
      species: String(props.labels[i]),
      photo: `https://s3.us-east-1.wasabisys.com/superseeded/images/converted_images/${String(props.labels[i]).replace(/\s+/g, '_')}/0001.jpg`,
      value: props.data[i]
    }))

    setAlertConfig({
      title: 'Add Plants to Collection',
      description: `Would you like to add ${selectedPlants.length} selected plants to your collection?`,
      confirmText: 'Add Plants',
      cancelText: 'Cancel',
      onAction: (action: 'confirm' | 'cancel') => {
        if (action === 'confirm') {
          // Add each selected plant to dock
          selectedPlants.forEach(plant => {
            addPlantToDock(plant)
          })
          
          // Show success notification
          push.success({
            title: "Plants Added",
            message: `Added ${selectedPlants.length} plants to your collection`
          })
        }
        // Clear the brush selection after handling
        chart.value?.dispatchAction({
          type: 'brush',
          areas: []
        })
      }
    })
  }
}

// Add watch for plantsInDock changes
watch(plantsInDock, () => {
  updateChartData();
}, { deep: true });
</script>

<template>
  <div ref="chartContainer" class="chart-container p-0 m-0">
    
    <div v-if="clusterHulls && !isHdbscanLoading" class="absolute -top-[4px] group max-w-[23px] right-[157px] z-[1] flex flex-col items-center overflow-visible">
      <Button @click="toggleHulls" size="icon" class="" variant="icon">
        <Icon name="system-uicons:venn" class="w-7 h-7 scale-[0.92] text-gray-500 dark:text-gray-300" />
        
        <Icon v-if="showHulls" name="fluent:slash-forward-16-regular" class="absolute w-7 h-7 scale-[0.92] text-gray-500 dark:text-gray-300 rotate-[20deg]" />
        <Icon v-if="showHulls" name="fluent:slash-forward-16-regular" class="absolute w-7 h-7 left-[1px] scale-[0.92] text-muted rotate-[20deg]" />
        
      </Button>
      <p class="min-w-[43px] dark:text-primary whitespace-nowrap text-center group-hover:opacity-100 opacity-0 text-[12px] relative -top-[9px]">{{ showHulls ? 'Hide' : 'Show' }} Groups</p>
    </div>
    
    <div v-if="clusterHulls && !isHdbscanLoading" class="absolute -top-[4px] group max-w-[23px] right-[133px] z-[1] flex flex-col items-center overflow-visible">
      <Button @click="toggleAxesAndZoom" size="icon" class="" variant="icon">
        <Icon name="mdi:axis" class="w-5 h-5 scale-[0.92] text-gray-500 dark:text-gray-300" />
        
        <Icon v-if="showAxesAndZoom" name="fluent:slash-forward-16-regular" class="absolute w-7 h-7 scale-[0.92] text-gray-500 dark:text-gray-300 rotate-[20deg]" />
        <Icon v-if="showAxesAndZoom" name="fluent:slash-forward-16-regular" class="absolute w-7 h-7 left-[1px] scale-[0.92] text-muted rotate-[20deg]" />
        
      </Button>
      <p class="min-w-[43px] dark:text-primary whitespace-nowrap text-center group-hover:opacity-100 opacity-0 text-[12px] relative -top-[9px]">{{ showAxesAndZoom ? 'Hide' : 'Show' }} axes</p>
    </div>
      
    <Transition
      @before-enter="hullTransitionActive = true"
      @after-leave="hullTransitionActive = false"
    >
      <VChart 
        v-if="hasValidData" 
        ref="chart" 
        :option="option" 
        :init-options="initOptions" 
        :autoresize="true"
        @mousemove="onChartMouseMove"
        @mouseout="onChartMouseOut"
        @click="onChartClick"
        @brushSelected="onBrushSelected"
        :class="{ 'hull-transition': hullTransitionActive }"
      />
      <!-- <div v-else-if="isHdbscanLoading" class="loading-message mt-4">Calculating clusters...</div>
      <div v-else class="no-data-message">Getting things ready...</div> -->
    </Transition>
    <div class="absolute opacity-75 w-[230px] h-[30px] bg-background/30 top-5 right-10 opacity-75 z-20 pointer-events-none"></div>
  </div>
</template>

<style scoped>
.chart-container {
  width: 100%;
  height: 600px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-message {
  font-size: 16px;
  color: v-bind('colors.primary');
}

.loading-message {
  font-size: 16px;
  color: v-bind('colors.primary');
}

.hull-transition :deep(.echarts-tooltip-dom) {
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.hull-transition :deep(path) {
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}
</style>












