<script setup lang="ts">
import { watchDebounced } from '@vueuse/core';
import * as tf from '@tensorflow/tfjs';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Command,
  CommandEmpty,   
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { useSearch } from '@/composables/useSearch'
import { Check, ChevronsUpDown } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
import { useDebounceFn } from '@vueuse/core'
import { nextTick } from 'vue'
import { UMAP } from 'umap-js'
import { DBSCAN } from 'density-clustering'
import { HDBSCAN } from '@/composables/hdbscan'
import { KDTree } from 'k-d-tree'
import chroma from 'chroma-js';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Slider } from '@/components/ui/slider';
import {SliderRoot, SliderTrack, SliderRange, SliderThumb} from 'radix-vue'
import { useAsyncData } from '#app'


const uiStore = useUiStore()
const { setAlertConfig } = uiStore

const input = ref('dog,cat,human,flower,earth,artificial,sunlight,cars,bad,good,time,washing,people,sun,galaxy,microbe')




useSquircleObserver()

const colorMode = useColorMode()

const cm = computed(() => colorMode.value || 'light')

const { search } = useMeiliSearch('flemmings')
const { fetchSpeciesDataIndividually } = useSearch()





// const selectedReduction = ref('UMAP')
// const selectedClusteringMethod = ref('DBSCAN')

const reductionMethods = ['FASTMAP', 'UMAP', 'TSNE', 'DBSCAN', 'HDBSCAN']
const computationTime = ref(0)

const selectedSearchAttribute = ref('*')
const searchableAttributes = [
  { value: '*', label: 'All attributes' },
  { value: 'landscapeValue', label: 'Landscape value' },
  { value: 'growthRate', label: 'Growth rate' },
  { value: 'culture', label: 'Culture' },
  { value: 'fruit', label: 'Fruit' },
  { value: 'flowers', label: 'Flowers' },
  { value: 'foliage', label: 'Foliage' },
  { value: 'habit', label: 'Habit' },
  { value: 'size', label: 'Size' }
]

const data = ref([])




const client = useSupabaseClient()

const clusterProbabilities = ref([])
const outlierScores = ref([])

const isUpdating = ref(false)

const performUMAP = async (data: number[][]) => {
  if (isUpdating.value) return
  
  try {
    isUpdating.value = true
    // Validate input data
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error('Invalid or empty data provided to UMAP');
      return;
    }

    // Ensure we have enough data points for nNeighbors
    const nNeighbors = Math.min(15, Math.max(2, Math.floor(data.length * 0.5)));

    const umap = new UMAP({
      nComponents: 2,
      nNeighbors, // Use dynamic nNeighbors based on data size
      minDist: 0.1,
      spread: 1.0,
    });

    // Additional validation before fitting
    if (data.length < nNeighbors) {
      console.error(`Not enough data points (${data.length}) for UMAP calculation`);
      return;
    }

    // Get UMAP embedding using async version
    const embedding = await umap.fitAsync(data, (epoch) => {
      // Optional: Add progress tracking here
      return true // Continue processing
    });
    
    // Validate embedding result
    if (embedding && embedding.length > 0) {
      umap_data.value = embedding;
    } else {
      console.error('UMAP calculation produced no results');
    }
  } catch (error) {
    console.error('Error in UMAP calculation:', error);
    // Optionally reset or maintain previous state
    // umap_data.value = [];
  } finally {
    isUpdating.value = false
  }
};

// Define all available qualities
const QUALITY_STRINGS = [
    "Available space", "Temperature", "Frost", "Wind", "Altitude", 
    "Humidity", "Topography", "Substrate", "Average rainfall", 
    "Drought", "Irrigation", "Sunlight", "Salt", "Micro climate", 
    "Aspect", "Slope gradient", "Reflected light", "Reflected heat", 
    "Wind corridors", "Depressed areas", "Raised areas", "Flat areas",
    "Rockeries", "Pathways", "Playgrounds", "Rain gardens", 
    "Micro-catchments", "Bioswales", "Water cleaning", "Repetition",
    "Unity", "Rhythm", "Contrast", "Balance", "Colour", "Form", 
    "Texture", "Line", "Proportion", "Focal points", "Screening & privacy",
    "Wind break", "Hedging", "Borders", "Roadside planting", "Parkland",
    "Street tree", "Modern garden", "Traditional garden", "Native garden",
    "Biodiversity resources", "Fire retardant", "Growth habit", 
    "Erosion control", "Sensory", "Order and creativity", "Layering",
    "Plant Function Examples", "Irrigation", "Mulch", "Grey water",
    "Maintenance budget", "Mass planting", "Specimen planting", 
    "Feature", "Seasonal interest"
]

// Define default qualities for the radar (limited to 6)
const DEFAULT_RADAR_QUALITIES = [
  "Available space",
  "Temperature",
  "Drought",
  "Growth habit",
  "Maintenance budget",
  "Sunlight"
]

// Initialize all reactive refs
const availableQualities = ref(QUALITY_STRINGS.map((quality, index) => ({
  value: quality,
  label: quality,
  order: index
})))

const selectedQualities = ref(DEFAULT_RADAR_QUALITIES)
const selectedDataPoints = ref([...DEFAULT_RADAR_QUALITIES])

const plantEmbeddings = ref([])
const umap_data = ref([])
const highDimData = ref([])
const labels = ref([])
const clusterSummaries = ref([])
const hoverResults = ref(null)
// Add these refs if not already present
const searchTerm = ref('')
const open = ref(false)


// Initialize radar data with only the default qualities at 0.3
const radarData = ref(
  DEFAULT_RADAR_QUALITIES.map((quality, index) => ({
    label: quality.split(' ')[0].length > 10 ? quality.split(' ')[0] + '...' : quality,
    axis: quality,
    value: 0.3,
    order: index
  }))
)

const qualityCategories = ref({
  "context": {
    key: "context",
    label: "Site Conditions",
    parameters: [
      "Altitude", "Aspect", "Available space", "Average rainfall", "Depressed areas", 
      "Drought", "Flat areas", "Frost", "Humidity", "Irrigation", "Micro climate", 
      "Raised areas", "Reflected heat", "Reflected light", "Salt", "Slope gradient", 
      "Substrate", "Sunlight", "Temperature", "Topography", "Wind", "Wind corridors"
    ].sort()
  },
  "design": {
    key: "design",
    label: "Design Elements",
    parameters: [
      "Balance", "Bioswales", "Borders", "Colour", "Contrast", "Focal points", "Form", 
      "Hedging", "Line", "Micro-catchments", "Modern garden",
      "Parkland", "Pathways", "Playgrounds", "Proportion", "Rain gardens", 
      "Repetition", "Rhythm", "Roadside planting", "Rockeries", "Screening & privacy", 
      "Street tree", "Texture", "Traditional garden", "Unity", "Water cleaning", 
      "Wind break"
    ].sort()
  },
  "resource": {
    key: "resource",
    label: "Resources & Maintenance",
    parameters: [
      "Grey water", "Irrigation", "Maintenance budget", "Mulch"
    ].sort()
  },
  "functional": {
    key: "functional",
    label: "Function & Ecology",
    parameters: [
      "Biodiversity resources", "Erosion control", "Fire retardant", "Growth habit", 
      "Layering", "Order and creativity", "Plant Function Examples", "Sensory"
    ].sort()
  },
  "aesthetic": {
    key: "aesthetic",
    label: "Aesthetic & Structure",
    parameters: [
      "Feature", "Mass planting", "Native garden", "Seasonal interest", 
      "Specimen planting"
    ].sort()
  }
})

// Define the updateProjection function
async function updateProjection() {
  const startTime = performance.now();

  try {
    // Map of plants to their embeddings for each quality
    const plantEmbeddingsMap = {};
    const plantNames = new Set();

    // Validate plantEmbeddings
    if (!plantEmbeddings.value || plantEmbeddings.value.length === 0) {
      console.error('No plant embeddings available');
      return;
    }

    // Organize embeddings per plant
    for (const embedding of plantEmbeddings.value) {
      if (!embedding || !embedding.name || !embedding.quality || !embedding.embedding) {
        console.warn('Invalid embedding found:', embedding);
        continue;
      }

      const plantName = embedding.name;
      const quality = embedding.quality;
      const embeddingVector = embedding.embedding;

      if (!plantEmbeddingsMap[plantName]) {
        plantEmbeddingsMap[plantName] = {};
      }
      plantEmbeddingsMap[plantName][quality] = embeddingVector;
      plantNames.add(plantName);
    }

    const sampleEmbedding = plantEmbeddings.value.find(e => e.embedding)?.embedding;
    if (!sampleEmbedding) {
      throw new Error('No valid embeddings found.');
    }
    const embeddingLength = sampleEmbedding.length;

    // Process embeddings per plant with quality weights
    const combinedEmbeddings = [];
    const labelsArray = [];

    for (const plantName of plantNames) {
      const plantData = plantEmbeddingsMap[plantName];
      const weightedEmbeddings = [];
      let totalWeight = 0;

      // Apply weights from radar data
      for (const quality of selectedQualities.value) {
        const radarItem = radarData.value.find((d) => d.axis === quality);
        const weight = radarItem ? radarItem.value / 5 : 0; // Normalize to 0-1
        const embedding = plantData[quality];

        if (embedding) {
          // Scale embedding by weight
          const scaledEmbedding = embedding.map(val => val * weight);
          weightedEmbeddings.push(scaledEmbedding);
          totalWeight += weight;
        }
      }

      // Only add embeddings if we have valid data
      if (weightedEmbeddings.length > 0 && totalWeight > 0) {
        // Combine weighted embeddings
        const combinedEmbedding = new Array(embeddingLength).fill(0);
        for (let i = 0; i < embeddingLength; i++) {
          for (const embedding of weightedEmbeddings) {
            combinedEmbedding[i] += embedding[i];
          }
          // Normalize by total weight
          combinedEmbedding[i] /= totalWeight;
        }

        combinedEmbeddings.push(combinedEmbedding);
        labelsArray.push(plantName);
      }
    }

    // Validate we have enough data before proceeding
    if (combinedEmbeddings.length === 0) {
      throw new Error('No valid combined embeddings generated');
    }

    // Store high-dimensional data for clustering
    highDimData.value = combinedEmbeddings;
    labels.value = labelsArray;

    // Perform UMAP calculation
    await performUMAP(combinedEmbeddings);

    computationTime.value = performance.now() - startTime;
  } catch (error) {
    console.error('Error in projection update:', error);
  }
}

// Fetch embeddings and update projection
const fetchSelectedEmbeddings = async (includeUndesirable = false) => {
  try {
    isLoading.value = true

    const qualities = selectedQualities.value
    if (!qualities.length) return

    // Build select query with conditional undesirable fields
    const selectQuery = `
      id,
      plant_name,
      quality,
      desirable_short,
      desirable_embedding
      ${includeUndesirable ? ',undesirable_short,undesirable_embedding' : ''}
    `

    // Use useAsyncData for automatic caching
    const { data: queryData } = await useAsyncData(
      // Unique key based on query params
      `embeddings-${qualities.join('-')}-${includeUndesirable}`,
      async () => {
        const { data, error } = await client
          .from('embeddings_plants')
          .select(selectQuery)
          .in('quality', qualities)
        
        if (error) throw error
        return data
      },
      {
        // Cache for 5 minutes
        maxAge: 300,
        // Watch these dependencies to refresh cache when they change
        watch: [selectedQualities, splitPosNeg],
        // Only fetch on client since we need Supabase client
        server: false,
        // Default empty array while loading
        default: () => []
      }
    )

    // Process the data as before
    const desirableMap = {}
    const undesirableMap = {}

    queryData.value?.forEach(item => {
      if (item.plant_name && item.quality) {
        if (!desirableMap[item.plant_name]) {
          desirableMap[item.plant_name] = {}
        }
        if (!undesirableMap[item.plant_name]) {
          undesirableMap[item.plant_name] = {}
        }

        if (item.desirable_short) {
          desirableMap[item.plant_name][item.quality] = item.desirable_short
        }
        if (item.undesirable_short) {
          undesirableMap[item.plant_name][item.quality] = item.undesirable_short
        }
      }
    })

    // Update the refs
    desirableShort.value = desirableMap
    undesirableShort.value = undesirableMap

    plantEmbeddings.value = queryData.value?.map(item => ({
      id: item.id,
      name: item.plant_name,
      quality: item.quality,
      description: item.desirable_short,
      embedding: JSON.parse(item.desirable_embedding),
      ...(includeUndesirable && {
        undesirableDescription: item.undesirable_short,
        undesirableEmbedding: JSON.parse(item.undesirable_embedding)
      })
    }))

    if (plantEmbeddings.value?.length > 0) {
      await updateProjection()
    }

  } catch (error) {
    console.error('Error fetching selected embeddings:', error)
    setAlertConfig(
      {
        title: 'Error',
        text: 'Failed to fetch embeddings',
        confirmText: 'OK'
      },
      true
    )
  } finally {
    isLoading.value = false
  }
}

// Update the clustering and summaries generation
const generateClusterSummaries = async (labels: number[]) => {
  if (!labels || !plantEmbeddings.value.length) return []
  
  try {
    // Create quality factors array from radar data
    const qualityFactors = radarData.value.map(item => ({
      label: item.axis,
      scaleFactor: item.value / 5 // Convert from 0-5 range to 0-1 range
    }))

    console.log('qualityFactors:', qualityFactors)

    // Pass quality factors to concatenateClusterSummaries
    const summaries = await concatenateClusterSummaries(
      plantEmbeddings.value, 
      labels,
      qualityFactors
    )
    return summaries.filter(Boolean)
  } catch (error) {
    console.error('Error generating cluster summaries:', error)
    return []
  }
}

// Update the performClustering function to use the computed values
const performClustering = async () => {
  if (!umap_data.value || umap_data.value.length === 0) return []
  
  try {
    const points = umap_data.value.map(point => [point[0], point[1]])
    
    if (selectedClusteringMethod.value === 'DBSCAN') {
      // DBSCAN logic
      const epsilon = 1.0
      const minPoints = 2
      const dbscan = new DBSCAN()
      const clusters = dbscan.run(points, epsilon, minPoints)
      
      // Initialize all points as noise (-1)
      const labels = new Array(points.length).fill(-1)
      
      // Assign cluster labels
      clusters.forEach((cluster, clusterIndex) => {
        cluster.forEach(pointIndex => {
          labels[pointIndex] = clusterIndex
        })
      })
      
      dbscanLabels.value = labels
      return labels
      
    } else if (selectedClusteringMethod.value === 'HDBSCAN') {
      // Use the computed values from the array refs
      const minPoints = hdbscanMinPoints.value
      const minClusterSize = hdbscanMinClusterSize.value
      
      // Create HDBSCAN instance with slider parameters
      const hdbscan = new HDBSCAN(points, minClusterSize, minPoints, 1.0, 'euclidean')
      hdbscan.fit()
      const labels = hdbscan.labels_
      
      dbscanLabels.value = labels
      
      if (hdbscan.probabilities_) {
        clusterProbabilities.value = hdbscan.probabilities_
      }
      if (hdbscan.outlier_scores_) {
        outlierScores.value = hdbscan.outlier_scores_
      }
      
      return labels
    }
    
    // Default case - return all points as noise
    const defaultLabels = new Array(points.length).fill(-1)
    dbscanLabels.value = defaultLabels
    return defaultLabels
    
  } catch (error) {
    console.error('Error in clustering:', error)
    const defaultLabels = new Array(umap_data.value.length).fill(-1)
    dbscanLabels.value = defaultLabels
    return defaultLabels
  }
}

// Update the watch to properly handle async summaries
watch(
  [() => umap_data.value, () => plantEmbeddings.value],
  async ([newUmapData, newEmbeddings]) => {
    if (!newUmapData?.length || !newEmbeddings?.length) return
    
    try {
      const labels = await performClustering()
      if (labels.some(label => label !== -1)) {
        clusterSummaries.value = await concatenateClusterSummaries(
          plantEmbeddings.value,
          labels,
          getCurrentQualityFactors()
        )
      }
    } catch (error) {
      console.error('Error in clustering update:', error)
    }
  },
  { deep: true }
)

const initializeData = async () => {
  try {
    isLoading.value = true
    await fetchSelectedEmbeddings(splitPosNeg.value)
  } catch (error) {
    console.error('Error in initial data fetch:', error)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  window.addEventListener('resize', resizeChart)
  initializeData() // Call async function after hooks are registered
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeChart)
})


const hoverClusterSummary = ref<{ text: string; gradientColors: string[]; textColor: string } | null>(null)
const isHovering = ref(false)
const isLoading = ref(false)

const handleMarkerHover = async (event) => {
  const { label, clusterIndex, gradientColors } = event;

  if (!label || clusterIndex === undefined) {
    hoverResults.value = null;
    hoverClusterSummary.value = null;
    isHovering.value = false;
    isLoading.value = false;
    return;
  }

  isHovering.value = true;
  isLoading.value = true;
  
  try {
    // Get the summary for this cluster
    const summary = clusterSummaries.value[clusterIndex];
    
    if (summary) {
      let textColor = '#000000';
      if (gradientColors && gradientColors.length > 0) {
        try {
          textColor = getContrastColor(gradientColors[0]);
        } catch (error) {
          console.warn('Error getting contrast color:', error);
        }
      }
      
      // Add appropriate prefix based on sentence start
      const displayText = summary.toLowerCase().startsWith('these plants') ? summary :
        summary.toLowerCase().startsWith('are') ? `These plants ${summary}` :
        summary.toLowerCase().startsWith('is') ? `This group ${summary}` :
        `${summary}`;
      
      hoverClusterSummary.value = {
        text: displayText,
        gradientColors: gradientColors || ['#ffffff', '#ffffff'],
        textColor
      };
    } else {
      hoverClusterSummary.value = {
        text: "This plant stands apart from existing groups because it doesn't share enough similar attributes to be grouped with others. It might represent something unique, rare, or not yet well-connected to the main groups.",
        gradientColors: gradientColors || ['#ffffff', '#ffffff'],
        textColor: '#000000'
      };
    }
  } catch (error) {
    console.error('Error in handleMarkerHover:', error);
    hoverResults.value = null;
    hoverClusterSummary.value = null;
  } finally {
    isLoading.value = false;
  }
};

// Add these refs for timing
const updateTimes = ref<number[]>([])
const averageUpdateTime = computed(() => {
  if (updateTimes.value.length === 0) return 0
  // Only use the last 5 measurements for a more accurate recent average
  const recentTimes = updateTimes.value.slice(-5)
  const sum = recentTimes.reduce((a, b) => a + b, 0)
  return Math.round(sum / recentTimes.length)
})
const currentUpdateStartTime = ref<number | null>(null)
const estimatedTimeRemaining = ref<number | null>(null)
const updateInterval = ref<NodeJS.Timer | null>(null)

// Update the startUpdateTimer function
const startUpdateTimer = () => {
  currentUpdateStartTime.value = performance.now()
  
  // Start countdown based on average time
  if (averageUpdateTime.value > 0) {
    estimatedTimeRemaining.value = averageUpdateTime.value
    
    // Clear any existing interval
    if (updateInterval.value) {
      clearInterval(updateInterval.value)
    }
    
    // Set new interval
    updateInterval.value = setInterval(() => {
      if (estimatedTimeRemaining.value !== null) {
        estimatedTimeRemaining.value = Math.max(0, estimatedTimeRemaining.value - 100)
      }
    }, 100)
  }
}

// Update the stopUpdateTimer function
const stopUpdateTimer = () => {
  if (currentUpdateStartTime.value !== null) {
    const duration = performance.now() - currentUpdateStartTime.value
    updateTimes.value.push(duration)
    
    // Keep only last 10 measurements
    if (updateTimes.value.length > 10) {
      updateTimes.value = updateTimes.value.slice(-10)
    }
  }
  
  if (updateInterval.value) {
    clearInterval(updateInterval.value)
    updateInterval.value = null
  }
  
  currentUpdateStartTime.value = null
  estimatedTimeRemaining.value = null
}

// Update the handleRadarData function
const handleRadarData = useDebounceFn(async (data) => {
  console.log('Radar data updated:', data)
  
  // Update radarData immediately
  radarData.value = data
  
  try {
    startUpdateTimer()
    isClusteringLoading.value = true
    
    const startTime = performance.now()
    
    // Only call updateProjection once
    await debouncedUpdateProjection()
    
    // Generate summaries only once after projection is complete
    const labels = dbscanLabels.value
    if (labels.some(label => label !== -1)) {
      clusterSummaries.value = await concatenateClusterSummaries(
        plantEmbeddings.value,
        labels,
        getCurrentQualityFactors()
      )
    }
    
    const endTime = performance.now()
    updateTimes.value.push(endTime - startTime)
    
  } catch (error) {
    console.error('Error handling radar data:', error)
  } finally {
    stopUpdateTimer()
    isClusteringLoading.value = false
  }
}, 500)

// Add this watcher to handle radarData changes
watch(radarData, (newData) => {
  chartData.value = newData
}, { deep: true })

// Clean up interval on component unmount
onUnmounted(() => {
  if (updateInterval.value) {
    clearInterval(updateInterval.value)
  }
})

const filteredDataPoints = computed(() => {
  return availableQualities.value.filter(point => 
    point.label.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
    point.value.toLowerCase().includes(searchTerm.value.toLowerCase())
  )
})


const toggleDataPoint = (value: string) => {
  const index = selectedDataPoints.value.indexOf(value)
  if (index === -1) {
    selectedDataPoints.value.push(value)
    radarData.value.push({
      label: value,
      axis: value,
      value: 0.3,
      order: radarData.value.length
    })
  } else {
    selectedDataPoints.value.splice(index, 1)
    radarData.value = radarData.value.filter(item => item.axis !== value)
  }
  updateChartData()
}

const updateChartData = () => {
  chartData.value = selectedDataPoints.value.map(point => {
    const existing = radarData.value.find(item => item.axis === point)
    if (existing) {
      return existing
    }
    return {
      label: point,
      axis: point,
      value: 0.3,
      order: radarData.value.length
    }
  })
  
  chartData.value.forEach((item, index) => {
    item.order = index
  })
}

const updateRadarData = () => {
  radarData.value = selectedDataPoints.value.map((point, index) => {
    const existing = radarData.value.find(item => item.axis === point)
    return {
      ...(existing || {
        label: point,
        axis: point,
        value: 0.3
      }),
      order: index
    }
  })
  updateChartData()
}

const chartData = ref(radarData.value)

// Add this helper function to decompress gzipped data
const decompressGzip = async (compressedData: Blob): Promise<string> => {
  const ds = new DecompressionStream('gzip');
  const decompressedStream = compressedData.stream().pipeThrough(ds);
  const decompressedResponse = new Response(decompressedStream);
  return await decompressedResponse.text();
}

// New function to get high-dimensional data for HDBSCAN
const getHighDimDataForHDBSCAN = () => {
  return highDimData.value;
}

const handleHoverDataPoint = (dataPoint) => {
  // console.log('Hover data point:', dataPoint)
}

const tailwindColors = [
  'rgb(252, 165, 165)', // red-300 with reduced opacity
  'rgb(253, 186, 116)', // orange-300 with reduced opacity
  'rgb(253, 224, 71)',  // yellow-300 with reduced opacity
  'rgb(134, 239, 172)', // green-300 with reduced opacity
  'rgb(147, 197, 253)', // blue-300 with reduced opacity
  'rgb(216, 180, 254)', // purple-300 with reduced opacity
  'rgb(249, 168, 212)', // pink-300 with reduced opacity
]

const dbscan = ref(new DBSCAN())
const dbscanLabels = ref<number[]>([])
const isClusteringLoading = ref(false)

// Update the clustering method selection
const selectedClusteringMethod = ref('HDBSCAN')
const clusteringMethods = ['HDBSCAN', 'DBSCAN']

// Update the watch for clustering updates
watch(
  [() => umap_data.value, () => highDimData.value],
  async ([newUmapData, newHighDimData]) => {
    if (newUmapData?.length > 0 && newHighDimData?.length > 0) {
      const labels = await performClustering()
      if (labels.some(label => label !== -1)) {
        try {
          const summaries = await concatenateClusterSummaries(
            plantEmbeddings.value,
            labels,
            getCurrentQualityFactors()
          )
          clusterSummaries.value = summaries.filter(Boolean)
        } catch (error) {
          console.error('Error generating cluster summaries:', error)
          clusterSummaries.value = []
        }
      }
    }
  },
  { deep: true }
)

// Update the watch to use performClustering
watch(
  [() => umap_data.value],
  async () => {
    if (umap_data.value.length === 0) return
    await performClustering()
  },
  { deep: true }
)

// Update the watch for initial clustering
watch(
  () => plantEmbeddings.value,
  async () => {
    if (plantEmbeddings.value.length > 0) {
      const labels = await performClustering()
      if (labels.some(label => label !== -1)) {
        clusterSummaries.value = await concatenateClusterSummaries(
          plantEmbeddings.value,
          labels,
          getCurrentQualityFactors()
        )
      }
    }
  },
  { immediate: false }
)

const summary = ref('')

const { concatenateClusterSummaries, getContrastColor } = useDataUtils();

// Watch for changes in highDimData and fetch HDBSCAN labels
watch(() => highDimData.value, async () => {
  await performClustering();
  if (dbscanLabels.value.length > 0) {
    clusterSummaries.value = [] // Reset cluster summaries
    try {
      // Wait for all summaries to resolve
      const summaries = await Promise.all(
        (await concatenateClusterSummaries(
          plantEmbeddings.value, 
          dbscanLabels.value,
          getCurrentQualityFactors()
        ))
          .map(async (summary) => {
            return typeof summary.then === 'function' ? await summary : summary;
          })
      );
      clusterSummaries.value = summaries;
    } catch (error) {
      console.error('Error processing cluster summaries:', error);
      clusterSummaries.value = [];
    }
  }
}, { deep: true });

// Update the chart when HDBSCAN labels change
watch(dbscanLabels, updateChartData)

// Add this method to handle when hover ends
const handleHoverEnd = () => {
  isHovering.value = false;
  hoverClusterSummary.value = null;
  isWaitingForClusterData.value = false;
}

const showAxesAndZoom = ref(false)

const handleShowAxesAndZoomUpdate = (value: boolean) => {
  showAxesAndZoom.value = value
}

// Add this helper function
const addOpacityToColor = (color: string, opacity: number): string => {
  // If the color is already in rgba format, just update the opacity
  if (color.startsWith('rgba')) {
    return color.replace(/[\d.]+\)$/g, `${opacity})`)
  }
  
  // If it's a hex color, convert to rgba
  if (color.startsWith('#')) {
    const r = parseInt(color.slice(1, 3), 16)
    const g = parseInt(color.slice(3, 5), 16)
    const b = parseInt(color.slice(5, 7), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }
  
  // If it's an rgb color, convert to rgba
  if (color.startsWith('rgb')) {
    return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`)
  }
  
  // If it's a named color, you might want to add a lookup table or use a library
  // For now, we'll just return it as is
  return color
}

// Add this to ensure the chart resizes properly
const resizeChart = () => {
  // You may need to implement this method in your Radar component
  // or use a library-specific method to trigger a resize
}

const searchQuery = ref('')
const searchResults = ref<string[]>([]) // Add this ref to store matching IDs

// Add this ref to store the highlighted matches
const highlightedMatches = ref([])

const handleSearchAttributeClick = (attribute: string) => {
  selectedSearchAttribute.value = attribute
}

// Extract the search logic into a reusable function
const performSearch = async (query: string, attribute: string) => {
  if (!query.trim() || query.trim().length < 3) {
    searchResults.value = []
    highlightedMatches.value = []
    return
  }
  
  try {
    const searchOptions = {
      limit: 200,
      attributesToHighlight: [attribute],
      attributesToSearchOn: [attribute],
      highlightPreTag: '<span class="bg-yellow-200 border-2 border-white rounded-md -mr-[2px]">',
      highlightPostTag: '</span>'
    }

    const results = await search(query, searchOptions)

    highlightedMatches.value = results.hits.map(hit => {
      const matches = {}
      
      Object.entries(hit._formatted).forEach(([key, value]) => {
        if (key !== 'red_font_title' && 
            key !== 'id' && 
            typeof value === 'string' && 
            value.includes('<span class="bg-yellow-200 border-2 border-white rounded-md -mr-[2px]">')) {
          matches[key] = value
        }
      })
      
      return {
        id: hit.id,
        matches
      }
    }).filter(item => Object.keys(item.matches).length > 0)

    searchResults.value = results.hits.map(hit => hit.id)
  } catch (error) {
    console.error('Error searching:', error)
    searchResults.value = []
    highlightedMatches.value = []
  }
}

// Update the existing search watcher to use the new function
watchDebounced(searchQuery, async (newQuery) => {
  await performSearch(newQuery, selectedSearchAttribute.value)
}, { debounce: 100 })

const hideZeroSearchResults = ref(true)
// Add watcher for selectedSearchAttribute
watch(selectedSearchAttribute, async (newAttribute) => {
  hideZeroSearchResults.value = true
  if (searchQuery.value.trim()) {
    await performSearch(searchQuery.value, newAttribute)
    hideZeroSearchResults.value = false
  }
})

// Add new method to handle hull hover events
const handleHullHover = (event: any) => {
  // Reset search when hovering over hulls
  if (event.clusterIndex !== null) {
    searchQuery.value = ''
    searchResults.value = []
  }
}

// Update the watcher to use selectedClusteringMethod instead of selectedReduction
watch(selectedClusteringMethod, async (newMethod) => {
  if (!plantEmbeddings.value.length) return
  
  isClusteringLoading.value = true
  try {
    await performClustering()
  } catch (error) {
    console.error(`Error performing ${newMethod}:`, error)
  } finally {
    isClusteringLoading.value = false
  }
})

// Add this near the top of the script setup where other refs are defined
const motions = useMotions()

// Add these refs
const isResultsMinimized = ref(false)
const searchResultsHeight = ref('240px') // default height

// Add this method
const toggleResultsMinimize = () => {
  if (isResultsMinimized.value) {
    // When expanding
    isResultsMinimized.value = false
    // Set overflow to hidden initially
    if (searchResultsRef.value) {
      searchResultsRef.value.style.overflow = 'hidden'
      searchResultsRef.value.style.overflowY = 'hidden'
      searchResultsRef.value.style.overflowX = 'hidden'
      // Force a style refresh
      searchResultsRef.value.offsetHeight
      
      // After transition, restore overflow
      setTimeout(() => {
        if (searchResultsRef.value) {
          searchResultsRef.value.style.overflow = 'auto'
          searchResultsRef.value.style.overflowY = 'auto'
          searchResultsRef.value.style.overflowX = 'hidden'
        }
      }, 500)
    }
  } else {
    // When minimizing, set overflow to hidden BEFORE changing state
    if (searchResultsRef.value) {
      searchResultsRef.value.style.overflow = 'hidden !important'
      searchResultsRef.value.style.overflowY = 'hidden !important'
      searchResultsRef.value.style.overflowX = 'hidden !important'
      // Force a style refresh
      searchResultsRef.value.offsetHeight
    }
    
    // Use nextTick to ensure DOM updates before starting transition
    nextTick(() => {
      isResultsMinimized.value = true
    })
  }
}

const searchResultsRef = ref(null)

// Create debounced version of the handler
const debouncedMinimize = useDebounceFn(() => {
  isResultsMinimized.value = true
}, 150) // 150ms debounce delay

onClickOutside(searchResultsRef, debouncedMinimize)


// Add this watcher to expand results when new search results arrive
watch(highlightedMatches, (newMatches) => {
  if (newMatches.length > 0) {
    isResultsMinimized.value = false
  }
}, { deep: true })

const about = ref(false)

const handleAddSupplier = async () => {
  const proceed = await setAlertConfig({
    title: "We're working on this",
    html: `If you are a supplier and would like to add your plant catalogue, please get in touch at <a href="mailto:hi@superseeded.ai">hi@superseeded.ai</a>`,
    confirmText: 'OK',
    destructive: false,
    inputRequired: false
  }, true)
}

// Add these refs near the top with other refs

// Add a helper function to get current quality factors
const getCurrentQualityFactors = () => {
  return radarData.value.map(item => ({
    label: item.axis,
    scaleFactor: item.value / 5 // Convert from 0-5 range to 0-1 scale
  }))
}

// Add this ref near the top with other refs
const splitPosNeg = ref(true)

// Add watcher to handle changes in splitPosNeg
watch(splitPosNeg, async (newValue) => {
  try {
    isLoading.value = true
    await fetchSelectedEmbeddings(newValue)
  } catch (error) {
    console.error('Error updating embeddings after split toggle:', error)
  } finally {
    isLoading.value = false
  }
}, { immediate: false })

// Add these refs near the top with other refs
const desirableShort = ref({})
const undesirableShort = ref({})

// Add these refs near the top with other refs
const hdbscanMinPointsArray = ref([1])
const hdbscanMinClusterSizeArray = ref([1])
const hdbscanMinPoints = computed(() => hdbscanMinPointsArray.value[0])
const hdbscanMinClusterSize = computed(() => hdbscanMinClusterSizeArray.value[0])

// Add watcher for both HDBSCAN parameters
watch([hdbscanMinPoints, hdbscanMinClusterSize], async ([newMinPoints, newMinClusterSize]) => {
  if (!plantEmbeddings.value.length) return
  
  isClusteringLoading.value = true
  try {
    await performClustering()
  } catch (error) {
    console.error('Error updating HDBSCAN parameters:', error)
  } finally {
    isClusteringLoading.value = false
  }
}, { immediate: false })

const handleClusteringChanged = () => {
  console.log('clusteringChanged')
}

// Add a debounce to updateProjection
const debouncedUpdateProjection = useDebounceFn(async () => {
  const startTime = performance.now();

  try {
    // Map of plants to their embeddings for each quality
    const plantEmbeddingsMap = {};
    const plantNames = new Set();

    // Validate plantEmbeddings
    if (!plantEmbeddings.value || plantEmbeddings.value.length === 0) {
      console.warn('Plant embeddings not available for projection update');
      return;
    }

    // Organize embeddings per plant
    for (const embedding of plantEmbeddings.value) {
      if (!embedding || !embedding.name || !embedding.quality || !embedding.embedding) {
        console.warn('Invalid embedding found:', embedding);
        continue;
      }

      const plantName = embedding.name;
      const quality = embedding.quality;
      const embeddingVector = embedding.embedding;

      if (!plantEmbeddingsMap[plantName]) {
        plantEmbeddingsMap[plantName] = {};
      }
      plantEmbeddingsMap[plantName][quality] = embeddingVector;
      plantNames.add(plantName);
    }

    const sampleEmbedding = plantEmbeddings.value.find(e => e.embedding)?.embedding;
    if (!sampleEmbedding) {
      throw new Error('No valid embeddings found.');
    }
    const embeddingLength = sampleEmbedding.length;

    // Process embeddings per plant with quality weights
    const combinedEmbeddings = [];
    const labelsArray = [];

    for (const plantName of plantNames) {
      const plantData = plantEmbeddingsMap[plantName];
      const weightedEmbeddings = [];
      let totalWeight = 0;

      // Apply weights from radar data
      for (const quality of selectedQualities.value) {
        const radarItem = radarData.value.find((d) => d.axis === quality);
        const weight = radarItem ? radarItem.value / 5 : 0; // Normalize to 0-1
        const embedding = plantData[quality];

        if (embedding) {
          // Scale embedding by weight
          const scaledEmbedding = embedding.map(val => val * weight);
          weightedEmbeddings.push(scaledEmbedding);
          totalWeight += weight;
        }
      }

      // Only add embeddings if we have valid data
      if (weightedEmbeddings.length > 0 && totalWeight > 0) {
        // Combine weighted embeddings
        const combinedEmbedding = new Array(embeddingLength).fill(0);
        for (let i = 0; i < embeddingLength; i++) {
          for (const embedding of weightedEmbeddings) {
            combinedEmbedding[i] += embedding[i];
          }
          // Normalize by total weight
          combinedEmbedding[i] /= totalWeight;
        }

        combinedEmbeddings.push(combinedEmbedding);
        labelsArray.push(plantName);
      }
    }

    // Validate we have enough data before proceeding
    if (combinedEmbeddings.length === 0) {
      throw new Error('No valid combined embeddings generated');
    }

    // Store high-dimensional data for clustering
    highDimData.value = combinedEmbeddings;
    labels.value = labelsArray;

    // Perform UMAP calculation
    await performUMAP(combinedEmbeddings);

    computationTime.value = performance.now() - startTime;
  } catch (error) {
    console.error('Error in projection update:', error);
  }
}, 300) // 300ms debounce

// Replace updateProjection with debouncedUpdateProjection in your code

// Replace the existing selectedQuality declaration with a computed property
const selectedQuality = computed(() => {
  // Get the quality with highest value from radarData
  console.log('radarData', radarData.value)
  const highestValueQuality = [...radarData.value]
    .sort((a, b) => b.value - a.value)[0];
  console.log('highestValueQuality', highestValueQuality)
  return highestValueQuality?.axis || 'Sunlight' // Fallback to 'Sunlight' if no data
})

// Remove the existing selectedQuality prop declaration:
// const selectedQuality = ref('Sunlight')

</script>

<template>
  <div class="w-full h-full flex flex-col @container/main">
    <div class="flex flex-col @[768px]/main:flex-row w-full justify-between space-y-4 @[768px]/main:space-y-0 @[768px]/main:space-x-4 rounded-lg h-full">
      <div class="w-full @[768px]/main:w-2/3 relative bg-gradient-to-l from-transparent via-transparent to-slate-100/80 dark:from-transparent dark:via-transparent dark:to-muted/30 pt-5 flex flex-col rounded-lg pr-10">
        
        
        <!-- Move settings accordion here -->
        <div class="absolute z-1 top-[4px] right-[50px] flex items-center gap-2" 
             v-show="!(isClusteringLoading || isLoading)">
          <Accordion type="single" collapsible class="w-[195px] text-muted-foreground/80">
            <AccordionItem value="settings" :border="false" class="relative">
              <AccordionTrigger  
                class="text-xs flex items-left justify-start gap-2 group z-10 max-w-[10px] hover:no-underline" 
                :icon="false"
              >
                <div class="relative w-8 h-8 flex items-center justify-center">
                  <Icon 
                    name="solar:settings-linear" 
                    class="w-5 h-5 scale-[0.87] text-slate-500 dark:text-gray-300" 
                  />
                  <p class="absolute min-w-[43px] whitespace-nowrap text-center group-hover:opacity-100 opacity-0 text-[12px] left-1/2 -translate-x-1/2 top-[28px] antialiased transition-opacity duration-200 text-primary">Settings</p>
                </div>
              </AccordionTrigger>
              <AccordionContent class="absolute left-0 top-full mt-1">
                <div class="flex flex-col space-y-4 bg-muted p-2 rounded-lg relative z-50">
                  <div class="flex items-center space-x-2">
                    <div class="text-xs w-fit whitespace-nowrap">HDBSCAN</div>
                    
                  </div>

                  <!-- Add HDBSCAN parameter sliders -->
                  <div v-if="selectedClusteringMethod === 'HDBSCAN'" class="space-y-2">
                    <div class="flex flex-col gap-1">
                      <Label class="text-xs">Min Points</Label>
                      <Slider
                        v-model="hdbscanMinPointsArray"
                        class="w-32"
                        :min="1"
                        :max="20"
                        :step="1"
                      />
                      <span class="text-[10px] text-muted-foreground">{{ hdbscanMinPoints }}</span>
                    </div>
                    
                    <div class="flex flex-col gap-1">
                      <Label class="text-xs">Min Cluster Size</Label>
                      <Slider
                        v-model="hdbscanMinClusterSizeArray"
                        class="w-32"
                        :min="1"
                        :max="20"
                        :step="1"
                      />
                      <span class="text-[10px] text-muted-foreground">{{ hdbscanMinClusterSize }}</span>
                    </div>
                  </div>

                  <!-- Existing checkbox -->
                  <div class="flex items-center space-x-2">
                    <Checkbox 
                      id="split-pos-neg"
                      :checked="splitPosNeg"
                      @update:checked="splitPosNeg = $event"
                      class="scale-75 origin-left"
                    />
                    <Label 
                      for="split-pos-neg" 
                      class="text-xs cursor-pointer"
                    >
                      Split into +/-
                    </Label>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <!-- Add your show axes and hide groups buttons here -->
          <!-- Note: You'll need to add these buttons based on your existing implementation -->
        </div>

          <div class="absolute top-6 left-6 z-20" 
               v-show="!(isClusteringLoading || isLoading)">
               <Input 
            v-model="searchQuery" 
            leading
            trailing
            placeholder="Search Fleming's..." 
            class="rounded-full !border-0 shadow-lg hover:shadow-lg focus:shadow-lg focus-visible:!ring-0 focus-visible:!ring-offset-0 z-10 top-6 left-6 w-[180px] !z-20 bg-background pl-10 min-w-[220px]" 
          >
            <template #leading-content>
              <div class="flex items-center gap-2">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <VTooltip
                tooltipClass="bg-muted/90" 
                :animate="true" 
                side="right" 
                :content="{__html: `<p class='text-xs text-muted-foreground/80 leading-4 max-h-8'><span class='font-semibold'>Select a supplier</span><br/>Searching <a href='https://flemings.com.au' target='_blank' class='underline'>Fleming's urban tree guide catalogue</a ></p>`}"
              >
              
                    <Button 
                      variant="text" 
                      size="icon" 
                      class="flex items-start justify-start gap-2 h-auto p-0 hover:bg-transparent"
                    >
                      <img src="~/public/images/logos/flemings_logo.webp" class="w-4 h-4 pointer-events-none" />
                    </Button>
                  </VTooltip>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent 
                    align="start" 
                    :alignOffset="-8" 
                    class="w-[200px] -ml-2 mt-3 rounded-2xl"
                  >
                    <DropdownMenuItem class="rounded-t-2xl hover:!bg-muted-foreground/5 cursor-pointer">
                      <span class="flex items-center gap-2">
                        <Icon name="ph:tree" class="w-4 h-4" />
                        Fleming's
                      </span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem class="rounded-b-2xl hover:!bg-muted-foreground/5 cursor-pointer" @click="handleAddSupplier">
                      <span class="flex items-center gap-2">
                        <Icon name="material-symbols-light:add-2-rounded" class="w-4 h-4" />
                        Add
                      </span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </template>
            <template #trailing>
              <DropdownMenu>
                  <DropdownMenuTrigger as-child>
                    <Button 
                      variant="text" 
                      size="icon" 
                      class="flex items-end justify-end gap-2 h-auto p-0 hover:bg-transparent"
                    >
                      <Icon name="ep:filter" class="w-4 h-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent 
                    align="end" 
                    :alignOffset="-34" 
                    class="w-[200px] -ml-2 mt-3 rounded-2xl bg-background"
                  >
                    
                    
                    <DropdownMenuRadioGroup v-model="selectedSearchAttribute" class="space-y-1 p-1">
                      <div v-for="attribute in searchableAttributes" :key="attribute.value"
                        class="relative flex cursor-pointer select-none items-center rounded-sm py-1.5 text-sm outline-none transition-colors hover:bg-muted focus:bg-muted focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50" @click="handleSearchAttributeClick(attribute.value)">
                        <DropdownMenuRadioItem @click="handleSearchAttributeClick(attribute.value)" :value="attribute.value" :id="attribute.value" class="" />
                        <DropdownMenuLabel @click="handleSearchAttributeClick(attribute.value)" class="font-normal leading-3 flex items-center gap-2" :for="attribute.value">
                          {{ attribute.label }}
                          <span 
                            v-if="searchQuery && selectedSearchAttribute === attribute.value && !hideZeroSearchResults" 
                            class="text-xs text-muted-foreground/50"
                          >
                            <Badge 
                              variant="outline" 
                              class="absolute text-xs whitespace-nowrap bg-muted-foreground/10 top-[9px] my-0 py-0"
                            >
                              {{ highlightedMatches.length }}
                            </Badge>
                          </span>
                        </DropdownMenuLabel>
                      </div>
                    </DropdownMenuRadioGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
            </template>
          </Input>
          </div>

          <Scatterplot
          v-model:showAxesAndZoom="showAxesAndZoom"
          class="w-full flex-grow" 
          :data="umap_data" 
          :cm="colorMode.value" 
          :labels="labels" 
          :hdbscanLabels="dbscanLabels"
          :clusterSummaries="clusterSummaries"
          :isHdbscanLoading="isClusteringLoading"
          :searchResults="searchResults"
          :desirable-short="desirableShort"
          :undesirable-short="undesirableShort"
          :selected-quality="selectedQuality"
          @markerHover="handleMarkerHover"
          @clusteringChanged="() => { searchQuery = ''; searchResults = [] }"
          @update:showAxesAndZoom="handleShowAxesAndZoomUpdate"
        />
        <!-- Update the cluster summary overlay z-index -->
        <div v-if="isHovering && hoverClusterSummary" 
             class="absolute w-full flex items-start justify-center self-center pointer-events-none bottom-40 z-[99999] bg-white/30 backdrop-blur-md">
          <div :style="{ 
                background: hoverClusterSummary.gradientColors ? 
                  `linear-gradient(135deg, ${addOpacityToColor(hoverClusterSummary.gradientColors[0], 0.1)}, ${addOpacityToColor(hoverClusterSummary.gradientColors[1], 0.1)})` : 
                  'rgba(255, 255, 255, 0.6)'
              }" 
              class="rounded-xl p-4 min-w-[300px] min-h-[100px] w-[80%] backdrop-blur-xl flex gap-2 shadow-xxl relative z-[99999]">
            <div class="relative min-w-[50px] min-h-[50px] ">
              <AnimatedBlob 
                width="50px" 
                height="50px" 
                :gradient="[
                  hoverClusterSummary.gradientColors?.[0] || '#3023AE',
                  hoverClusterSummary.gradientColors?.[1] || '#f09'
                ]"
                :opacity="0.15"
                animation-duration="6s"
                class="absolute -right-20 -bottom-20 z-0 mix-blend-multiply opacity-50"
              />
            </div>
            <p class="text-lg font-[450] text-primary select-none relative z-[99999]">{{ hoverClusterSummary.text }}</p>
          </div>
        </div>

       
        
        
        <!-- <transition :css="false" @leave="(el, done) => motions['search_results'].leave(done)"> -->
          <div v-auto-animate ref="searchResultsRef" 
              
              v-if="highlightedMatches.length > 0" 
              class="absolute top-[80px] left-6 mr-4 bg-background/40 dark:bg-muted/80 backdrop-blur-md rounded-2xl rounded-lg p-4 shadow-lg z-10 overflow-y-auto transition-all duration-300 min-w-[130px]"
              :class="{ 'minimized rounded-lg p-2 overflow-hidden overflow-y-hidden': isResultsMinimized }"
              :style="{
                maxHeight: isResultsMinimized ? '60px' : '240px',
                width: isResultsMinimized ? '120px' : 'auto',
                cursor: isResultsMinimized ? 'pointer' : 'default'
              }"
              @click="isResultsMinimized && toggleResultsMinimize()">
            <div class="flex flex-col gap-4" :class="{ 'pointer-events-none opacity-0 overflow-hidden max-h-4': isResultsMinimized }">
              <div v-for="match in highlightedMatches" :key="match.id" class="text-sm">
                <div class="mb-1 font-bold" v-html="match.matches.title || match.id.replace(/_/g, ' ')"></div>
                <div v-for="(value, key) in match.matches" 
                    :key="key" 
                    class="mb-2"
                    v-if="key !== 'title'">
                  <span class="text-[0.7em] font-regular text-white uppercase bg-muted-foreground/30 p-[1px] -my-[1px] rounded-md px-1 mr-1">{{ key }}</span>
                  <span class="text-xs" v-html="value"></span>
                </div>
              </div>
            </div>
            <div v-if="isResultsMinimized" class="absolute inset-0 flex items-start p-2 justify-start font-semibold text-xs text-muted-foreground z-10">
              {{ highlightedMatches.length }} search result{{ highlightedMatches.length === 1 ? '' : 's' }} <Icon name="stash:expand-diagonal-duotone" class="w-6 h-6 rotate-90 bottom-0 absolute right-0 opacity-50" />
            </div>
          </div>
        
      

        
        <div class="absolute bottom-4 left-4 bg-background dark:bg-background backdrop-blur-md squircle-15 p-4 shadow-xl z-20"
             v-show="!(isClusteringLoading || isLoading)">
          <div class="flex flex-col gap-2">
            <div class="flex items-center gap-2">
              <Icon name="material-symbols-light:scatter-plot" class="w-4 h-4 text-blue-400 -left-1 relative" />
              <span class="text-xs -left-[2px] relative">Plants</span>
            </div>
            
            <div class="flex items-center gap-1 relative -left-1">
              <div class="relative w-6 h-6 -ml-1">
                <svg 
                  viewBox="0 0 80 80" 
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-full h-full"
                >
                  <defs>
                    <linearGradient id="blobGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                      <stop offset="0%" style="stop-color:#FFB6D9;stop-opacity:0.3" />
                      <stop offset="100%" style="stop-color:#D0E2FF;stop-opacity:1" />
                    </linearGradient>
                  </defs>
                  <path 
                    fill="url(#blobGradient)" 
                    d="M29.6,-48.3C32.8,-38.4,25.9,-22.3,25.2,-11.3C24.5,-0.3,29.9,5.6,33.2,15.5C36.4,25.4,37.6,39.3,31.7,47C25.8,54.6,12.9,56,2.8,52.1C-7.2,48.2,-14.4,39,-26.2,33.3C-37.9,27.5,-54.2,25.2,-64.8,15.7C-75.3,6.1,-80,-10.7,-70.5,-17.7C-61,-24.6,-37.3,-21.7,-23.2,-27.9C-9.1,-34.2,-4.5,-49.7,4.3,-55.7C13.2,-61.6,26.4,-58.1,29.6,-48.3Z"
                    transform="translate(50 50) scale(0.5)"
                  />
                </svg>
              </div>
              <span class="text-xs">Similar plants; gray plants are unique</span>
            </div>
          </div>
        </div>
        
        <div v-show="isClusteringLoading || isLoading" 
             class="absolute inset-0 flex items-center justify-center bg-background/50 backdrop-blur-sm z-30">
          <div class="flex flex-col items-center gap-2 bg-white/50 dark:bg-muted-foreground/[5%] backdrop-blur-md rounded-xl p-4 shadow-lg z-10">
            <img src="/dots.gif" class="w-10 h-10 mix-blend-multiply slow-spin" />
            
            <!-- Primary loading message -->
            <div class="text-sm text-muted-foreground font-medium">
              {{ 
                isClusteringLoading 
                  ? 'Analyzing plant relationships in high-dimensional space...' 
                  : isLoading && !plantEmbeddings.value?.length
                    ? 'Fetching plant data and embeddings...'
                    : 'Calculating dimensional reduction...'
              }}
            </div>

            <!-- Detailed step description -->
            <div class="text-xs text-muted-foreground/80 text-center max-w-[300px]">
              {{ 
                isClusteringLoading 
                  ? `Using HDBSCAN to identify natural groupings with min cluster size ${hdbscanMinClusterSize} and min points ${hdbscanMinPoints}`
                  : isLoading && !plantEmbeddings.value?.length
                    ? 'Loading semantic embeddings from database for each quality dimension'
                    : isLoading && plantEmbeddings.value?.length > 0
                      ? 'Using UMAP to project 1536-dimensional embeddings to 2D visualization space'
                      : 'Processing plant relationships...'
              }}
            </div>

            <!-- Progress metrics -->
            <div class="text-xs text-muted-foreground/80 flex flex-col items-center gap-1">
              <template v-if="plantEmbeddings.value?.length > 0">
                <div class="flex items-center gap-2">
                  <Icon name="ph:plant" class="w-4 h-4" />
                  <span>Processing {{ plantEmbeddings.value.length }} plants</span>
                </div>
                <div class="flex items-center gap-2">
                  <Icon name="ph:cube" class="w-4 h-4" />
                  <span>{{ selectedQualities.value.length }} quality dimensions</span>
                </div>
                <div class="flex items-center gap-2">
                  <Icon name="ph:arrows-out" class="w-4 h-4" />
                  <span>Reducing from 1536D to 2D</span>
                </div>
              </template>
              
              <!-- Time estimates -->
              <div v-if="estimatedTimeRemaining !== null" 
                   class="text-sm text-muted-foreground flex items-center gap-2 mt-2">
                <Icon name="ph:clock" class="w-4 h-4" />
                <span>{{ (estimatedTimeRemaining / 1000).toFixed(1) }}s remaining</span>
              </div>
              <div v-else-if="averageUpdateTime > 0" 
                   class="text-xs text-muted-foreground flex items-center gap-2">
                <Icon name="ph:timer" class="w-4 h-4" />
                <span>Average update: {{ (averageUpdateTime / 1000).toFixed(1) }}s</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Right side content - now explicitly 1/3 width -->
      <div class="w-full @[768px]/main:w-1/3 rounded-lg p-4 pl-0 pt-10 bg-gradient-to-b from-muted-foreground/[5%] to-muted-foreground/[10%]" v-auto-animate>
        <div v-if="about" class="flex flex-col gap-4 p-10 justify-start -mt-10 ml-0 h-full">
          <Button @click="about = false" variant="text" class="ml-0 pl-0 left-0 mb-4 self-start">
            <Icon name="heroicons:chevron-right-solid" class="w-4 h-4 mr-2 group-hover:translate-x-1 transition-transform duration-200 rotate-180" />
            Back
          </Button>

          <p class="text-2xl font-semibold text-muted-foreground mb-4">Cutting-edge AI for plant discovery</p>
          <h3 class="text-lg font-semibold text-muted-foreground mb-4">How it works</h3>  
          
          <!-- Make ScrollArea fill remaining height -->
          <ScrollArea class="flex-1">
            <Accordion type="single" collapsible class="w-full" default-value="explanation">
              <AccordionItem value="explanation">
                <AccordionTrigger class="text-muted-foreground tracking-tight text-sm" :icon="false"> 
                  How it works
                </AccordionTrigger> 
                <AccordionContent>
                  <div class="text-sm text-muted-foreground space-y-4">
                    <p class="text-sm text-muted-foreground mb-4">
                      At the heart of today's artificial intelligence and large language models lies the ability to understand language in all its subtlety and nuance. We've applied this technology to botanical data, developing a system that extends beyond the cataloguing of plants through categories and simple tags.
                    </p>
                    <p class="text-sm text-muted-foreground mb-4">  
                      Our approach begins with the natural language that describes plants - the accumulated knowledge of horticulturists captured in maintenance notes, growing guides, and botanical descriptions. These Creative Commons licensed works or consented texts are transformed into vector embeddings - mathematical representations that preserve the full complexity and nuance of language. Where conventional databases might reduce a plant's shade tolerance to a simple rating, our system captures the subtle distinctions between "thrives in dappled shade" and "tolerates deep shade conditions."
                    </p>
                    <p class="text-sm text-muted-foreground mb-4">
                      The true innovation lies in how we've made dimensionality reduction techniques and embeddings directly manipulable - mathematical methods that have already transformed genomics research and financial analysis. We've adapted these techniques to run in real time in a web browser, creating an interactive map that reveals the hidden relationships between plants. As you adjust various charsacteristics - from soil preferences to seasonal interest - the scatter plot responds dynamically, reorganizing to reveal new patterns and connections we can use to broaden our planting palettes.
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="technical">
                <AccordionTrigger class="text-muted-foreground tracking-tight text-sm">
                  Technical details
                </AccordionTrigger>
                <AccordionContent>
                  <div class="text-sm text-muted-foreground space-y-4">
                    <p class="text-sm text-muted-foreground mb-4">
                      Our pipeline uses <NuxtLink class="underline" external target="_blank" href="https://huggingface.co/Alibaba-NLP/gte-Qwen2-1.5B-instruct">Alibaba-NLP/gte-Qwen2-1.5B-instruct</NuxtLink> for vector embedding generation, implementing a dual strategy: document embeddings for botanical descriptions and query embeddings for factor-document text completions. Text completions are handled by <NuxtLink class="underline" external target="_blank" href="https://huggingface.co/meta-llama/Llama-3.1-8B-Instruct">meta-llama/Llama-3.1-8B-Instruct</NuxtLink>.
                    </p>
                    <p class="text-sm text-muted-foreground mb-4">
                      The core innovation is our client-side implementation of high-dimensional embedding manipulation. We use <NuxtLink class="underline" external target="_blank" href="https://umap-learn.readthedocs.io/en/latest/">UMAP</NuxtLink> for reducing 3584-dimensional embeddings to 2D visualization space, coupled with <NuxtLink class="underline" external target="_blank" href="https://hdbscan.readthedocs.io/en/latest/how_hdbscan_works.html">HDBSCAN</NuxtLink> for dynamic cluster identification. Moving these computationally intensive operations to the browser presented significant challenges - these algorithms typically rely on GPU acceleration or Python scientific computing libraries. Our JavaScript implementation sacrifices some computational efficiency for significantly reduced server load and better user experience, eliminating the latency inherent in serverless approaches or WebSocket architectures.
                    </p>
                    <p class="text-sm text-muted-foreground mb-4">
                      We augment the dense embedding retrieval with conventional fuzzy-indexing instant keyword search to capture explicit botanical terms. This hybrid approach compensates for pure embedding-based retrieval. The unique constraints working with botanical data and intentionally limiting results to visual exploration avoids limitations of re-ranking, chunking and other challenges of Q+A systems.
                    </p>
                    <p class="text-sm text-muted-foreground mb-4">
                      The system allows direct manipulation of embedding weights in real-time, enabling users to emphasize different factors while maintaining the mathematical coherence of the representation. This creates a responsive system where botanical relationships can be explored through dynamic adjustment of semantic priorities, all computed locally in the browser.
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </ScrollArea>
        </div>
        <div v-else class="flex flex-col gap-4">
        <!-- Radar chart container - moved up for mobile -->
        <ClientOnly>
          <div class="order-first sm:order-last w-full flex-grow min-h-[400px] sm:min-h-[500px] md:min-h-[300px] pb-10 sm:pb-20 md:pb-0 ">
            <Radar
              @hoverDataPoint="handleHoverDataPoint"
              title=""
              :data="chartData"
              @update:data="handleRadarData"  
            />
          </div>
        </ClientOnly>

        <!-- Explainer content - moved down for mobile -->
        <div class="order-last sm:order-first mb-4 flex-shrink-0 px-10">
          <p class="text-2xl font-semibold text-muted-foreground mb-4">Find similar plants</p>
          <p class="text-sm sm:text-xl text-muted-foreground mb-4">Discover new groups of plants that share something in common.</p>
            <p class="text-sm sm:text-xl text-muted-foreground mb-4">
            Plants that share similar causes of desirable or undesirable characteristics will be closer together.</p>
          

          

          <div class="flex items-center gap-2">
          </div>

  <Popover v-model:open="open">
  <PopoverTrigger as-child>
    <Button 
      variant="outline" 
      role="combobox" 
      :aria-expanded="open" 
      class="w-fit justify-between mb-4 scale-[1] origin-top-left whitespace-nowrap"
    >
      <span>
        <div 
          v-for="i in selectedDataPoints.length" 
          :key="i" 
          :style="{ backgroundColor: tailwindColors[i % tailwindColors.length], filter: 'grayscale(100%)' }" 
          class="inline-flex w-3 h-3 ring-1 ring-white rounded-full mx-1" 
        />
      </span>
      <span class="ml-2">{{ selectedDataPoints.length }} qualities</span>
      <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
    </Button>
  </PopoverTrigger>
  <PopoverContent class="w-[300px] p-0">
    <Command>
      <CommandInput 
        placeholder="Search qualities..." 
        :value="searchTerm"
        @input="searchTerm = $event.target.value"
      />
      <CommandEmpty>No quality found.</CommandEmpty>
      <CommandList>
        <CommandGroup v-for="(category, categoryKey) in qualityCategories" :key="category.key">
          <h3 class="px-2 py-1.5 font-semibold text-muted-foreground">{{ category.label }}</h3>
          <CommandItem
            v-for="dataPoint in category.parameters"
            :key="dataPoint"
            :value="dataPoint"
            @select="() => toggleDataPoint(dataPoint)"
          >
            <Check
              :class="cn(
                'mr-2 h-4 w-4',
                selectedDataPoints.includes(dataPoint) ? 'opacity-100' : 'opacity-0'
              )"
            />
            {{ dataPoint }}
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </Command>
  </PopoverContent>
</Popover>
          <p class="text-sm sm:text-xl text-muted-foreground mb-4">You can weight the factors, by dragging them on the chart below.</p>  

          <Button 
  @click="about = true" 
  variant="" 
  class="mt-5 w-full text-left rounded-full mb-4 bg-muted-foreground/10 group text-muted-foreground/80 hover:bg-muted-foreground/20"
>
  <div class="flex items-center w-full">
    <p class="sm:text-lg whitespace-nowrap overflow-hidden text-ellipsis flex-1">
      Learn more about how this was made
    </p>
    <Icon 
      name="heroicons:chevron-right-solid" 
      class="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform duration-200 flex-shrink-0" 
    />
  </div>
</Button>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style scoped>
/* You can add any additional styles here if needed */
.origin-bottom {
  transform-origin: center bottom;
}

/* Ensure the chart container takes up all available space */
.w-full.flex-grow {
  min-height: 0;
}

/* New CSS class for backdrop-filter with !important */
.blur-overlay {
  backdrop-filter: blur(20px) !important;
}

/* Target the accordion trigger button directly */
[data-radix-vue-collection-item][aria-controls^="radix-vue-collapsible-content"] {
  border-bottom: none !important;
  border-color: transparent !important;
}

/* Additional backup selectors for better specificity */
button[data-state][aria-controls^="radix-vue-collapsible-content"],
[id^="radix-vue-accordion-trigger"] {
  border-bottom: none !important;
  border-color: transparent !important;
}

/* If the border is coming from a hover state */
[data-radix-vue-collection-item][aria-controls^="radix-vue-collapsible-content"]:hover {
  border-bottom: none !important;
  border-color: transparent !important;
}


.no-chevron svg{
display: none;
}


/* Add new spinning animation */
@keyframes slow-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slow-spin {
  animation: slow-spin 10s linear infinite;
}

/* Add these new styles */
.highlight {
  background-color: 'yellow' !important;
  background: 'yellow' !important;
  border-radius: 3px;
}

/* Customize scrollbar for the results div */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: theme('colors.foreground/20') transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: theme('colors.foreground/20');
  border-radius: 3px;
}

.minimized {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.minimized:hover {
  opacity: 1;
  filter: blur(0px) !important;
  transform: scale(1.02);
}

/* Add these styles to ensure proper spacing in mobile view */
@media (max-width: 640px) {
  .order-first {
    order: -1;
  }
  
  .order-last {
    order: 1;
  }
}

</style>

















































