<script setup lang="ts">
import { Loader2, RefreshCw } from 'lucide-vue-next'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Badge } from '@/components/ui/badge'
import type { AvailabilityResult, ECResult } from '@/types/validation'
import { Button } from '@/components/ui/button'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import AvailabilityTreeMap from './AvailabilityTreeMap.vue'
import PriceQuantityScatter from './PriceQuantityScatter.vue'
import { useElementSize } from '@vueuse/core'
import { useValidation } from '@/composables/useValidation'
import { useValidationTransformers } from '@/composables/useValidationTransformers'
import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/stores/projectStore'
import type { MitterEvents } from '@/types/mitterEvents'
import { toolDefinitions } from '@/config/tools'
import { useIntersectionObserver } from '@vueuse/core'
import { markRaw } from 'vue'

dayjs.extend(relativeTime)
dayjs.extend(calendar)

// Initialize core services
const mitter = useMitter()
const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)
const { validateAvailability, validationLoadingState } = useValidation()
const { validateData } = useValidationTransformers()

// Component props
const props = defineProps<{
  data?: string[],
  metadata?: any,
  cell?: any,
  panel_id?: string,
  project_id?: string
}>()

// Define emits
const emit = defineEmits(['results'])

// Component state
const showHide = ref({
  available: true,
  unavailable: true
})

const speciesNames = ref<string[]>([])
const validationResults = ref<Record<string, AvailabilityResult>>({})
const isRefreshing = ref(false)
const tool = ref(toolDefinitions.find(t => t.id === 'availabilityValidation'))


// Handle validation data from panels
mitter.listen('panel:data:validate', async (event: MitterEvents['panel:data:validate']) => {
  if (event.validation_tool_uid !== tool.value?.uid) return;
  
  console.log('[AvailabilityStatus] Received panel:data:validate event', event);
  
  const validationResult = await validateData(event.data, event.tool_uid);
  console.log('[AvailabilityStatus] Validation result', validationResult);
  
  // Update species names from validation result if available
  if (validationResult.fixedData?.speciesNames) {
    speciesNames.value = validationResult.fixedData.speciesNames;
    
    // If we have AI-provided size recommendations, store them for later use
    if (validationResult.fixedData.sizeRecommendations) {
      console.log('[AvailabilityStatus] Using AI size recommendations:', 
        validationResult.fixedData.sizeRecommendations);
    }
    
    // Trigger validation if we have species names
    if (speciesNames.value.length > 0 && selectedProject.value?.id) {
      refresh(true);
    }
  }
});

const refresh = async (useCache: boolean = true, maxCacheAge: number = 86400, limit: number = 100) => {
  if (!selectedProject.value?.id) return
  
  isRefreshing.value = !useCache

  console.log('[AvailabilityStatus] Refreshing with species:', speciesNames.value)
  
  const validation = await validateAvailability(
    speciesNames.value,
    selectedProject.value.id.toString(),
    useCache,
    maxCacheAge,
    limit,
  )
  
  console.log('[AvailabilityStatus] Validation response:', validation)
  
  if (validation && validation.result) {
    validationResults.value = validation.result || {}
    emit('results', validation)
  } else {
    console.error('[AvailabilityStatus] Invalid validation response:', validation)
  }
  
  isRefreshing.value = false
}

// Apply validated results back to the panel
const applyValidatedResults = async () => {
  console.log('[AvailabilityStatus] Applying validated results')
  
  if (props.panel_id) {
    // mitter.emit('panel:data:apply', {
    //   panel_id: props.panel_id,
    //   data: {
    //     type: 'availability',
    //     results: validationResults.value
    //   }
    // })
  }
}

// Initialize on mount
onMounted(() => {
  console.log('[AvailabilityStatus] Component mounted')
  
  // // If we have initial data, use it
  // if (props.data && props.data.length > 0) {
  //   speciesNames.value = props.data
  //   refresh(true)
  // }
})



const getAvailableSpecies = computed(() => {
  return Object.entries(validationResults.value)
    .filter(([_, result]) => result.isAvailable)
    .map(([name, result]) => ({ name, result }))
})

const getUnavailableSpecies = computed(() => {
  return Object.entries(validationResults.value)
    .filter(([_, result]) => !result.isAvailable)
    .map(([name, result]) => ({ name, result }))
})

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(price)
}

const formatDate = (date: string | Date) => {
  return dayjs(date).fromNow()
}

// Add this computed property for tabulator columns
const availableColumns = computed(() => [
  {
    title: "Species",
    field: "name",
    headerSort: true,
    width: 200
  },
  {
    title: "Supplier",
    field: "supplier",
    headerSort: true,
  },
  {
    title: "State",
    field: "state",
    headerSort: true,
    width: 100
  },
  {
    title: "Price",
    field: "price",
    headerSort: true,
    formatter: (cell: any) => formatPrice(cell.getValue()),
    width: 100
  },
  {
    title: "Size",
    field: "size",
    headerSort: true,
    width: 100
  },
  {
    title: "Quantity",
    field: "quantity",
    headerSort: true,
    width: 100
  },
  {
    title: "Last Updated",
    field: "lastUpdated",
    headerSort: true,
    formatter: (cell: any) => formatDate(cell.getValue()),
    width: 150
  }
])

// Add this computed property to format data for the table
const availableTableData = computed(() => {
  return getAvailableSpecies.value.flatMap(({ name, result }) => 
    result.suppliers?.map(supplier => ({
      name,
      supplier: supplier.supplier.name,
      state: supplier.supplier.state,
      price: supplier.price,
      size: supplier.size,
      quantity: supplier.quantity,
      lastUpdated: supplier.last_updated
    })) || []
  )
})

const selectedSpecies = ref<{
  name: string,
  price?: number,
  size?: string,
  state?: string,
  value?: number,
  parentName?: string
}>({ name: '' })

const handleTreeMapSelect = (data: any) => {
  if (data.price !== undefined) {
    selectedSpecies.value = {
      ...data,
      parentName: data.parent
    }
  } else {
    selectedSpecies.value = {
      name: data.name
    }
  }
}

// Add section refs and active section tracking
const activeSection = ref('treemap')
const treemapSection = ref<HTMLElement | null>(null)
const summarySection = ref<HTMLElement | null>(null)
const scatterSection = ref<HTMLElement | null>(null)

// Add scroll area ref
const scrollAreaRef = ref<InstanceType<typeof ScrollArea> | null>(null)

// Add container ref and size tracking
const tabsContainer = ref<HTMLElement | null>(null)
const { width: containerWidth } = useElementSize(tabsContainer)

// Add computed for narrow width
const isNarrowWidth = computed(() => containerWidth.value < 300)

// Add computed for availability statistics
const availabilityStats = computed(() => {
  const totalSpecies = Object.keys(validationResults.value).length
  const availableCount = getAvailableSpecies.value.length
  const unavailableCount = getUnavailableSpecies.value.length
  
  return {
    totalSpecies,
    availableCount,
    unavailableCount,
    availablePercentage: totalSpecies > 0 ? Math.round((availableCount / totalSpecies) * 100) : 0
  }
})

// Add sections data
const sections = computed(() => [
  { 
    id: 'treemap', 
    label: "Availability",
    score: validationResults.value ? Object.keys(validationResults.value).length : null
  },
  {
    id: 'summary',
    label: "Summary",
    score: validationResults.value ? availabilityStats.value.availableCount : null
  },
  { 
    id: 'scatter', 
    label: "Analysis",
    score: validationResults.value ? 
      Object.values(validationResults.value)
        .reduce((acc, curr) => acc + (curr.suppliers?.length || 0), 0) 
      : null
  }
])

// Add scroll to section function
const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId)
  const viewport = document.querySelector('[data-radix-scroll-area-viewport]')
  if (section && viewport) {
    const targetScroll = section.offsetTop - 120
    viewport.scrollTop = targetScroll
    activeSection.value = sectionId
  }
}

// Add intersection observer setup
onMounted(() => {
  // Wait for scroll container to be available
  nextTick(() => {
    const scrollContainer = document.querySelector('.scroll-container')
    if (scrollContainer) {
      const sectionRefs = {
        treemap: treemapSection,
        summary: summarySection,
        scatter: scatterSection
      }
      
      Object.entries(sectionRefs).forEach(([id, ref]) => {
        useIntersectionObserver(
          ref,
          ([{ isIntersecting }]) => {
            if (isIntersecting) {
              activeSection.value = id
            }
          },
          { 
            threshold: [0.1, 0.5, 0.9],
            rootMargin: '-100px 0px 0px 0px',
            root: scrollContainer as Element
          }
        )
      })
    }
  })
})
</script>

<template>
  <div class="flex flex-col h-full">
    <!-- Loading state -->
    <div v-if="validationLoadingState === 'pending'" class="flex items-center gap-2 my-4 px-5">
      <div class="animate-spin w-4 h-4 border-2 border-primary border-t-transparent rounded-full"></div>
      <span>Validating availability for {{ speciesNames.length }} species...</span>
    </div>

    <!-- Section Tabs -->
    <div v-if="validationResults && Object.keys(validationResults).length > 0"
      ref="tabsContainer"
      class="sticky sticky-header top-0 z-10 bg-background/80 backdrop-blur-sm border-b mb-0 rounded-full m-4 mx-5 mb-4"
    >
      <div 
        class="flex gap-2 p-1.5 w-full overflow-x-hidden rounded-full"
        :class="isNarrowWidth ? 'justify-center' : 'justify-evenly'"
      >
        <button
          v-for="section in sections" 
          :key="section.id"
          v-show="!isNarrowWidth || activeSection === section.id"
          @click.stop="scrollToSection(section.id)"
          class="relative pl-2 pr-1.5 py-1.5 text-sm font-medium transition-all duration-300 rounded-full min-w-[120px] text-center"
          :class="[
            activeSection === section.id
              ? 'text-background bg-primary shadow-sm' 
              : 'text-muted-foreground hover:text-foreground/80'
          ]"
        >
          <div class="flex items-center justify-between">
            <p class="truncate overflow-hidden h-5">{{ section.label }}</p>
            <Badge 
              v-if="section.score"
              :variant="activeSection === section.id ? 'secondary' : 'outline'"
              class="text-[0.8em] py-0 px-1 ml-1 border-none ring-0"
            >
              {{ section.score }}
            </Badge>
          </div>
        </button>
      </div>
    </div>

    <!-- Main Content -->
    <ScrollArea 
      ref="scrollAreaRef"
      class="h-[calc(100vh-380px)] w-full scroll-thumb scroll-container"
    >
      <div class="flex flex-col gap-8">

        <!-- Availability Summary Section -->
        <section
          id="summary"
          ref="summarySection"
          class="scroll-mt-20"
        >
          <div class="flex justify-between items-start pb-6 px-4 py-2">
            <div>
              <h3 
                class="text-md font-[600] mb-2 group hover:cursor-pointer relative"
                @click="() => refresh(false)"
              >
                <Icon 
                  v-if="isRefreshing" 
                  name="line-md:loading-loop" 
                  class="w-4 h-4 absolute left-0 transition-all duration-200 mr-2"
                />
                <Icon 
                  v-else
                  name="lucide:refresh-cw" 
                  class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
                />
                <div class="relative transition-all duration-200" :class="[isRefreshing ? 'translate-x-6' : 'group-hover:translate-x-6']">Availability Summary</div>
              </h3>
              <p class="text-sm text-muted-foreground max-w-2xl">
                Summary of plant availability status showing which species are available from suppliers and which are not currently available.
              </p>
            </div>
          </div>

          <div v-if="validationResults && Object.keys(validationResults).length > 0" class="px-4">
            <!-- Summary Statistics -->
            <div class="flex flex-wrap gap-4 mb-6">
              <div class="bg-background rounded-lg p-4 shadow-sm flex-1 min-w-[180px]">
                <div class="text-sm text-muted-foreground">Available</div>
                <div class="text-2xl font-semibold text-emerald-500">{{ availabilityStats.availableCount }}</div>
                <div class="text-xs text-muted-foreground">{{ availabilityStats.availablePercentage }}% of total</div>
              </div>
              <div class="bg-background rounded-lg p-4 shadow-sm flex-1 min-w-[180px]">
                <div class="text-sm text-muted-foreground">Unavailable</div>
                <div class="text-2xl font-semibold text-rose-500">{{ availabilityStats.unavailableCount }}</div>
                <div class="text-xs text-muted-foreground">{{ (100 - availabilityStats.availablePercentage) }}% of total</div>
              </div>
            </div>

            <!-- Available Plants -->
            <div class="mb-6" v-if="getAvailableSpecies.length > 0">
              <Accordion type="single" collapsible class="w-full" :default-value="'available'">
                <AccordionItem value="available">
                  <AccordionTrigger class="text-base font-medium hover:no-underline" icon>
                    <div class="flex items-center gap-2">
                      <span class="text-emerald-500">Available Plants</span>
                    <Badge variant="outline">{{ getAvailableSpecies.length }}</Badge>
                  </div>
                </AccordionTrigger>
              
                <AccordionContent>
                  <div class="flex flex-wrap gap-4 mt-4">
                    <div 
                      v-for="item in getAvailableSpecies" 
                      :key="item.name"
                      class="bg-background rounded-lg p-4 transition-all hover:shadow-md group flex-grow basis-[240px] min-w-[200px]"
                    >
                      <div class="flex justify-between items-start">
                        <h4 class="font-medium text-base">{{ item.name }}</h4>
                        <Badge class="bg-emerald-500 text-white">Available</Badge>
                      </div>
                      <div class="text-sm text-muted-foreground mt-1">
                        {{ item.result.suppliers?.length || 0 }} supplier{{ item.result.suppliers?.length !== 1 ? 's' : '' }}
                      </div>
                      <div v-if="item.result.suppliers && item.result.suppliers.length > 0" class="mt-2">
                        <div class="text-xs font-medium text-muted-foreground uppercase mt-2">Price Range</div>
                        <div class="flex items-center gap-2">
                          <span class="text-sm font-medium">
                            {{ formatPrice(Math.min(...item.result.suppliers.map(s => s.price))) }}
                            <span class="text-muted-foreground">-</span>
                            {{ formatPrice(Math.max(...item.result.suppliers.map(s => s.price))) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
              </Accordion>
            </div>

            <!-- Unavailable Plants -->
            <div v-if="getUnavailableSpecies.length > 0">
              <Accordion type="single" collapsible class="w-full" :default-value="'unavailable'">
                <AccordionItem value="unavailable">
                  <AccordionTrigger class="text-base font-medium hover:no-underline" icon>
                    <div class="flex items-center gap-2">
                      <span class="text-rose-500">Unavailable Plants</span>
                      <Badge variant="outline">{{ getUnavailableSpecies.length }}</Badge>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div class="flex flex-wrap gap-4 mt-4">
                      <div 
                        v-for="item in getUnavailableSpecies" 
                        :key="item.name"
                        class="bg-background rounded-lg p-4 transition-all hover:shadow-md group flex-grow basis-[240px] min-w-[200px]"
                      >
                        <div class="flex justify-between items-start mb-2">
                          <h4 class="font-medium text-base">{{ item.name }}</h4>
                          <Badge variant="destructive" class="whitespace-nowrap">Unavailable</Badge>
                        </div>
                        <div class="text-sm text-muted-foreground mt-1">
                          No suppliers found
                        </div>
                        <div class="text-xs text-muted-foreground mt-2">
                          Last checked: {{ formatDate(String(item.result.lastChecked || new Date())) }}
                        </div>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>

        <!-- TreeMap Section -->
        <section 
          id="treemap"
          ref="treemapSection"
          class="scroll-mt-20"
          v-if="getAvailableSpecies.length"
        >
          <div class="flex justify-between items-start pb-6 px-4 py-2">
            <div>
              <h3 
                class="text-md font-[600] mb-2 group hover:cursor-pointer relative"
                @click="() => refresh(false)"
              >
                <Icon 
                  v-if="isRefreshing" 
                  name="line-md:loading-loop" 
                  class="w-4 h-4 absolute left-0 transition-all duration-200 mr-2"
                />
                <Icon 
                  v-else
                  name="lucide:refresh-cw" 
                  class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
                />
                <div class="relative transition-all duration-200" :class="[isRefreshing ? 'translate-x-6' : 'group-hover:translate-x-6']">Plant Availability</div>
              </h3>
              
            </div>
            <!-- Add Apply Results button -->
            <Button 
              v-if="validationResults && Object.keys(validationResults).length > 0 && panel_id"
              size="sm"
              variant="outline"
              @click="applyValidatedResults"
            >
              Apply Results
            </Button>
          </div>

          <div v-if="validationResults && Object.keys(validationResults).length > 0" class="bg-background rounded-lg p-6 focus-within:ring-2 focus-within:ring-primary focus-within:ring-opacity-50 transition-all duration-200 mx-4">
            <AvailabilityTreeMap :data="validationResults" @selection="handleTreeMapSelect" />
          </div>
        </section>

        <!-- Scatter Section -->
        <section
          v-if="validationResults && Object.keys(validationResults).length > 0 && getAvailableSpecies.length > 0" 
          id="scatter"
          ref="scatterSection"
          class="scroll-mt-20"
        >
          <div class="flex justify-between items-start pb-6 px-4 py-2">
            <div>
              <h3 
                class="text-md font-[600] mb-2 group hover:cursor-pointer relative"
                @click="() => refresh(false)"
              >
                <Icon 
                  v-if="isRefreshing" 
                  name="line-md:loading-loop" 
                  class="w-4 h-4 absolute left-0 transition-all duration-200 mr-2"
                />
                <Icon 
                  v-else
                  name="lucide:refresh-cw" 
                  class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
                />
                <div class="relative transition-all duration-200" :class="[isRefreshing ? 'translate-x-6' : 'group-hover:translate-x-6']">Analysis</div>
              </h3>
              <p class="text-sm text-muted-foreground max-w-2xl">
                Visualizes the correlation between price and quantity across different suppliers. 
                The scatter plot helps identify pricing patterns and potential volume discounts, 
                with each point representing a plant species.
              </p>
            </div>
          </div>

          <div v-if="validationResults && Object.keys(validationResults).length > 0" class="bg-background rounded-lg p-6 focus-within:ring-2 focus-within:ring-primary focus-within:ring-opacity-50 transition-all duration-200 mx-4">
            <PriceQuantityScatter :data="validationResults" />
          </div>
        </section>
      </div>
    </ScrollArea>
  </div>
</template>

<style scoped>
.scroll-thumb {
  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  scrollbar-width: thin;
}

.scroll-thumb::-webkit-scrollbar {
  width: var(--sb-size);
}

.scroll-thumb::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

.scroll-thumb::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
}

.sticky-header {
  min-height: 52px;
  display: flex;
  align-items: center;
}

.scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.backdrop-blur-sm {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

:deep(.echarts-tooltip-custom:not(:has(.tooltip-content))) {
  display: none !important;
}
</style>

