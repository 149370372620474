<script setup lang="ts">
import { useTools } from '@/composables/tools'
import type { GridApi, GridReadyEvent } from 'ag-grid-community'
import { themeQuartz } from 'ag-grid-community'
import type { 
  Panel as PanelType,
  PanelData
} from '@/types/projectdock'
import type { Tool } from '@/types/tools'
import { columnSchema as defaultColumnSchema, columnDisplayLabels } from '@/types/schedule'
import { toRef, watch, computed, ref, nextTick, onMounted, onBeforeUnmount, h, render } from 'vue'
import { storeToRefs } from 'pinia'
import { useDockStore } from '@/stores/dockStore'
import { Button } from '@/components/ui/button'
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger 
} from '@/components/ui/dropdown-menu'
import { usePanelConnections } from '@/composables/usePanelConnections'
import { useLayoutStore } from '@edanweis/vue-code-layout'
import type { LayoutStore } from '@edanweis/vue-code-layout'
import { useMitter } from '#imports'
import { useDebounceFn, watchDebounced } from '@vueuse/core'
import { useResizeObserver } from '@vueuse/core'
import { useMouse, useElementSize } from '@vueuse/core'
import type { ColDef } from 'ag-grid-community'
import { usePlantGrid } from '@/composables/tools/usePlantGrid'
import { usePanelDataTransformers } from '@/composables/useTransformers'
import { useGridDataTransformers } from '@/composables/useTransformers'
import { Badge } from '@/components/ui/badge'
import PlantGridUiDropdown from '@/components/PlantGridUi/Dropdown.vue'
import hashSum from 'hash-sum'
import { onUnmounted } from 'vue'
import { useEmitter } from '@/composables/useEmitter'
import { toolDefinitions } from '@/config/tools'
import { object } from 'zod'


const mitter = useMitter()
// Import AG Grid components client-side only
let AutocompleteSelectCellEditor: any = null
let AgGridVue: any = null
let CustomEscapeAwareEditor: any = null

// Track enabled validation tools
const enabledValidationTools = ref<string[]>(['name-validation-tool', 'diversity-validation-tool', 'availability-validation-tool', 'biosecurity-validation-tool'])
// const enabledValidationTools = ref<string[]>([])
  

if (import.meta.client) {
  AutocompleteSelectCellEditor = (await import('ag-grid-autocomplete-editor')).AutocompleteSelectCellEditor
  AgGridVue = (await import('ag-grid-vue3')).AgGridVue
  
  // Create a custom editor that extends AutocompleteSelectCellEditor to handle Escape differently
  CustomEscapeAwareEditor = class CustomEscapeAwareEditor extends AutocompleteSelectCellEditor {
    constructor() {
      super();
    }
    
    // Override the init method to add our Escape key handler
    init(params: any) {
      // Call the parent's init method first
      super.init(params);
      
      // Store the current value when editing starts
      this.initialValue = params.value;
      
      // Add our custom event listener for Escape
      this.eInput.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          // Prevent the default Escape behavior
          event.stopPropagation();
          event.preventDefault();
          
          // Set focus back to the cell but keep any changes
          params.api.stopEditing();
          params.api.setFocusedCell(params.rowIndex, params.column);
        }
      });
    }
    
    // Override getValue to ensure we always return a value, even on Escape
    getValue() {
      // If using an autocomplete component, it may have stored the selected value differently
      if (this.currentItem) {
        return this.currentItem;
      }
      
      // Otherwise fallback to the input value
      return this.eInput.value || this.initialValue;
    }
  }
}

// Define a type for the panel connections return value
interface PanelConnectionsType {
  emitData: (dataType: string, data: any) => void;
  [key: string]: any;
}

// Create a reactive reference to the layout store that will be initialized when available
const layoutStoreRef = ref<LayoutStore | null>(null)
const panelConnectionsRef = ref<PanelConnectionsType | null>(null)

const updateQueue = ref<{
  target: {
    columnName: string,
    cellValue: any
  },
  set: Record<string, any>
}[]>([])

// Define emit at the top of the script
const emit = defineEmits(['data-update']);

// Define missing dockStore
const dockStore = useDockStore();
const { defaultApiService } = storeToRefs(dockStore);
const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)
const { address_components } = selectedProject.value

// Add MitterEvents type definition
interface MitterEvents {
  [key: string]: any;
  'panel:data:validate': { panelId: string; tool_uid: string; data: any; validation_tool_uid: string };
  'panel:toolData': { panelId: string; toolId: string; data: any };
  'plantgrid:row:set:botanicalname': any;
  'plantgrid:row:set:commonname': any;
  'plantgrid:row:set:code': any;
}

// Add this helper function at the component's root scope (before the setup function)
// Create helper function to determine if a value should be treated as empty
const shouldDisplayAsEmpty = (value: any, field: string): boolean => {
  // Consider null, undefined, empty string, or "null" as empty
  if (value === null || value === undefined || value === '' || value === 'null') {
    return true;
  }
  
  // For specific numeric columns, treat 0 as empty - EXCEPT height related fields
  const zeroAsEmptyFields = ['quantity', 'width', 'proportion(%)', 'proportionpercentage'];
  if (zeroAsEmptyFields.includes(field?.toLowerCase()) && (value === 0 || value === '0')) {
    return true;
  }
  
  return false;
};

// Add a lock flag to prevent concurrent processing
const isProcessingUpdateQueue = ref(false)

watch(updateQueue, async (newQueue) => {
  console.log('[DEBUG] updateQueue changed, items:', newQueue.length);
  
  // Skip processing if already working on the queue or no items
  if (isProcessingUpdateQueue.value || newQueue.length === 0) return;
  
  // Set the lock to prevent concurrent processing
  isProcessingUpdateQueue.value = true;
  
  try {
    if (gridApi.value) {
      let rowCount = 0;
      gridApi.value.forEachNode(() => rowCount++);
      console.log(`[DEBUG] Grid has ${rowCount} total rows`);
    }
    
    // Process all queue items sequentially
    while (updateQueue.value.length > 0) {
      // Always take the first item (FIFO)
      const item = updateQueue.value[0];
      console.log('[DEBUG] Processing queue item:', JSON.stringify(item));
      
      // Check if a row is currently being edited
      const focusedCell = gridApi.value?.getFocusedCell();
      
      let foundMatch = false;
      
      // Wait for grid to be ready
      await nextTick();
      
      gridApi.value?.forEachNode((node, index) => {
        if (foundMatch) return; // Skip if already found a match
        
        const nodeValue = node.data?.[item.target.columnName];
        
        if (node.data) {
          // Try multiple matching strategies in order of preference:
          
          // 1. Exact match on the target column/value
          if (node.data[item.target.columnName] === item.target.cellValue) {
            console.log(`[DEBUG] Match found at node ${index} by exact value match!`);
            foundMatch = true;
            updateGridNode(node, item.set);
          } 
          // 2. If we have a focused row, use that
          else if (focusedCell && node.rowIndex === focusedCell.rowIndex) {
            console.log(`[DEBUG] Match found at node ${index} because it's the focused row!`);
            foundMatch = true;
            updateGridNode(node, item.set);
          }
          // 3. If only one row has a non-null value for the column, use that
          else if (nodeValue) {
            // Count how many rows have a value for this column
            let valueRowCount = 0;
            gridApi.value?.forEachNode(n => {
              if (n.data && n.data[item.target.columnName]) valueRowCount++;
            });
            
            // Only use this match strategy if exactly one row has data
            if (valueRowCount === 1) {
              console.log(`[DEBUG] Match found at node ${index} because it's the only row with data!`);
              foundMatch = true;
              updateGridNode(node, item.set);
            }
          }
        }
      });
      
      if (!foundMatch) {
        console.log('[DEBUG] No matching row found for update. Applying to first row as fallback.');
        // As a fallback, update the first non-empty row
        gridApi.value?.forEachNode((node, index) => {
          if (!foundMatch && node.data && Object.values(node.data).some(val => val)) {
            console.log(`[DEBUG] Applying to first non-empty row at index ${index}`);
            foundMatch = true;
            updateGridNode(node, item.set);
          }
        });
      }
      
      // Give the grid some time to update before removing from queue
      await new Promise(resolve => setTimeout(resolve, 50));
      
      // Remove the processed item from the queue
      if (updateQueue.value.length > 0) {
        updateQueue.value.shift(); // Remove the first item we just processed
        console.log('[DEBUG] Removed item from queue, remaining items:', updateQueue.value.length);
      }
    }
  } catch (error) {
    console.error('[ERROR] Error processing update queue:', error);
  } finally {
    // Release the lock after processing is complete
    isProcessingUpdateQueue.value = false;
  }
}, { deep: true });

// Helper function to update a grid node with changes
const updateGridNode = (node: any, changes: Record<string, any>) => {
  console.log('[DEBUG] Updating node with changes:', changes);
  
  // Store current focus state before making changes
  const focusedCell = gridApi.value?.getFocusedCell();
  
  // Create a local copy of changes to apply
  const updatesToApply = { ...changes };
  
  // Store list of columns that were updated
  const columnsToRefresh: string[] = [];
  
  // Update all fields in the set object
  Object.entries(updatesToApply).forEach(([key, value]) => {
    // Convert the key to lowercase to match the grid column names
    const gridKey = key.toLowerCase();
    columnsToRefresh.push(gridKey);
    
    try {
      // Skip update if value is null/undefined/empty, EXCEPT for height fields
      const isHeightField = gridKey.includes('height');
      if (!isHeightField && (value === null || value === undefined || value === '')) {
        console.log(`[DEBUG] Skipping ${gridKey} update - empty value`);
        return;
      }
      
      // Special handling for height fields to ensure zero values are preserved
      if (isHeightField) {
        console.log(`[DEBUG] Setting height field ${gridKey} =`, value);
        logHeightValue('updateGridNode', gridKey, value, 'incoming height value');
        
        // Check if there's an existing value to preserve metadata from
        const existingData = node.data[gridKey];
        const metadata = (typeof existingData === 'object' && existingData !== null) 
          ? { ...existingData }
          : {};
        
        logHeightValue('updateGridNode', gridKey, existingData, 'existing data', { 
          hasObject: typeof existingData === 'object' && existingData !== null,
          metadata 
        });
        
        // Handle different value types
        if (typeof value === 'object' && value !== null && 'value' in value) {
          // Value is already an object with the correct structure
          const newData = {
            ...metadata,
            ...value,
            source: value.source || metadata.source || 'transformer',
            updatedAt: new Date().toISOString()
          };
          
          logHeightValue('updateGridNode', gridKey, value, 'setting from object', newData);
          node.data[gridKey] = newData;
        } else {
          // Convert to number if possible
          let numericValue = null;
          if (value !== null && value !== undefined) {
            // For string values, parse to number
            if (typeof value === 'string') {
              const parsedValue = parseFloat(value.replace(/[^\d.-]/g, ''));
              if (!isNaN(parsedValue)) {
                numericValue = Number(parsedValue.toFixed(2));
                logHeightValue('updateGridNode', gridKey, value, `parsed string to number: ${numericValue}`);
              }
            } else if (typeof value === 'number') {
              numericValue = Number(value.toFixed(2));
              logHeightValue('updateGridNode', gridKey, value, `formatted number: ${numericValue}`);
            }
          }
          
          const newData = {
            ...metadata,
            value: numericValue,
            source: 'transformer',
            updatedAt: new Date().toISOString()
          };
          
          logHeightValue('updateGridNode', gridKey, value, 'setting from primitive', newData);
          node.data[gridKey] = newData;
        }
      }
      // Special handling for code column to ensure proper formatting
      else if (gridKey === 'code') {
        console.log(`[DEBUG] Setting ${gridKey} with special handling =`, value);
        // Ensure code is properly wrapped in an object with value property
        if (typeof value !== 'object' || value === null) {
          // Check if there's an existing value we should preserve metadata from
          const existingData = node.data[gridKey];
          const metadata = (typeof existingData === 'object' && existingData !== null) 
            ? { ...existingData }
            : {};
            
          node.data[gridKey] = {
            ...metadata,
            value: String(value),
            source: 'transformer',
            updatedAt: new Date().toISOString()
          };
        } else {
          node.data[gridKey] = value;
        }
      }
      // Special handling for botanicalname
      else if (gridKey === 'botanicalname') {
        console.log(`[DEBUG] Setting ${gridKey} with special handling =`, value);
        // Ensure botanicalname has both label and value properties
        if (typeof value === 'object' && value !== null) {
          // Use existing botanicalname object as base if available
          const existingData = node.data[gridKey];
          const metadata = (typeof existingData === 'object' && existingData !== null) 
            ? { ...existingData }
            : {};
            
          node.data[gridKey] = {
            ...metadata,
            label: value.label || value.value || '',
            value: value.value || value.label || '',
            source: value.source || metadata.source || 'transformer',
            updatedAt: new Date().toISOString(),
            // Preserve genus and family information
            genus: value.genus || metadata.genus || '',
            family: value.family || metadata.family || ''
          };
        } else {
          // Convert string to proper object
          const existingData = node.data[gridKey];
          const metadata = (typeof existingData === 'object' && existingData !== null) 
            ? { ...existingData }
            : {};
            
          node.data[gridKey] = {
            ...metadata,
            label: String(value),
            value: String(value),
            source: 'transformer',
            updatedAt: new Date().toISOString(),
            // Preserve genus and family if they exist
            genus: metadata.genus || '',
            family: metadata.family || ''
          };
        }
      } 
      else {
        // Set other fields, preserving object structure if it exists
        console.log(`[DEBUG] Setting ${gridKey} =`, value);
        
        // Check if there's an existing value we should preserve metadata from
        const existingData = node.data[gridKey];
        
        if (typeof existingData === 'object' && existingData !== null && 'value' in existingData) {
          // If the field already uses object structure, preserve metadata
          node.data[gridKey] = {
            ...existingData,
            value: typeof value === 'object' && value !== null && 'value' in value ? value.value : value,
            source: (typeof value === 'object' && value !== null && value.source) ? value.source : 'transformer',
            updatedAt: new Date().toISOString()
          };
        } else if (typeof value === 'object' && value !== null && 'value' in value) {
          // If the new value is already an object with the right structure, use it
          node.data[gridKey] = {
            ...value,
            updatedAt: new Date().toISOString()
          };
        } else {
          // Otherwise create a new object structure
          node.data[gridKey] = {
            value: value,
            source: 'transformer',
            updatedAt: new Date().toISOString()
          };
        }
      }
    } catch (error) {
      console.error(`[ERROR] Failed to update field ${gridKey}:`, error);
    }
  });
  
  // Only refresh cells if we have changes and the grid still exists
  if (columnsToRefresh.length > 0 && gridApi.value) {
    // Use nextTick to ensure Vue has updated the data before refreshing
    nextTick(() => {
      try {
        // Check again if grid still exists
        if (!gridApi.value) return;
        
        console.log(`[DEBUG] Refreshing columns:`, columnsToRefresh);
        
        // Refresh cells with the changes
        gridApi.value.refreshCells({
          rowNodes: [node],
          columns: columnsToRefresh,
          force: true
        });
        
        // Ensure focus is preserved exactly where it was
        if (focusedCell) {
          setTimeout(() => {
            if (gridApi.value) {
              // Restore focus with correct parameter count
              gridApi.value.setFocusedCell(
                focusedCell.rowIndex,
                focusedCell.column.getId(),
                null // pinned parameter ('top', 'bottom', or null)
              );
            }
          }, 10);
        }
        
        // Log after refresh if height fields are involved
        const heightColumns = columnsToRefresh.filter(col => col.toLowerCase().includes('height'));
        if (heightColumns.length > 0) {
          logHeightValue('refreshCells', heightColumns.join(','), null, 'cells refreshed', {
            rowIndex: node.rowIndex,
            refreshedColumns: columnsToRefresh
          });
          
          // After refreshing, force sync the node data to rowData to prevent loss of height values
          if (node.rowIndex !== undefined && rowData.value) {
            // Find this row in rowData array
            if (node.rowIndex >= 0 && node.rowIndex < rowData.value.length) {
              // Create a deep copy to ensure reactivity
              const syncedData = JSON.parse(JSON.stringify(node.data));
              console.log(`[DEBUG] Force syncing row ${node.rowIndex} data after height field update`);
              // Update rowData with a new reference preserving all values including height
              const updatedRowData = [...rowData.value];
              updatedRowData[node.rowIndex] = syncedData;
              rowData.value = updatedRowData;
            }
          }
          
          // Track all height values after refresh
          setTimeout(() => trackHeightValues(gridApi.value), 10);
        }
      } catch (error) {
        console.error(`[ERROR] Failed to refresh grid cells:`, error);
      }
    });
  }
};

// Function to safely get the layout store
const getLayoutStore = () => {
  if (!import.meta.client || !window.VUE_CODE_LAYOUT_READY) {
    console.log('Layout system not ready yet, will try again later')
    return null
  }
  
  try {
    return useLayoutStore()
  } catch (error) {
    console.warn('Could not access layout store:', error)
    return null
  }
}

// Try to initialize the layout store
const initLayoutStore = () => {
  const store = getLayoutStore()
  if (store) {
    layoutStoreRef.value = store
    return true
  }
  return false
}

const colorMode = useColorMode()

// Update the Panel prop type to match what's actually being passed
interface Panel extends PanelType {
  name: string;
  id?: string;
  data: PanelData;
  // Other properties that might be available
  title?: string;
  tooltip?: string;
  iconSmall?: string;
}

const {panel, height, tool} = defineProps<{
  panel: Panel
  height?: number | string
  tool?: Tool
}>()

const parentElement = ref<HTMLElement>()
const {width: parentElWidth } = useElementSize(parentElement)

// Function to safely initialize panel connections
const initPanelConnections = () => {
  // Exit early if panel has no name or connections are already initialized
  if (!panel.name) return false;
  if (panelConnectionsRef.value) return true;
  
  try {
    // Initialize connections
    const connections = usePanelConnections(panel.name);
    if (!connections) return false;
    
    // Store connections in ref
    panelConnectionsRef.value = connections as PanelConnectionsType;
    
    // Initialize panel data and connections array if needed
    if (!panel.data) panel.data = { tool_uid: toolId.value };
    panel.data.connections = panel.data.connections || [];
    
    // Restore any existing connections
    if (panel.data.connections.length > 0) {
      panel.data.connections.forEach(targetPanelId => {
        connections.connect?.(targetPanelId);
      });
    }
    
    return true;
  } catch (error) {
    console.error(`[ERROR] Failed to initialize panel connections for ${panel.name}:`, error);
    return false;
  }
}

// Add a computed property to safely access tool_uid from panel data
const panelToolUid = computed(() => panel?.data?.tool_uid || '');

// Add a computed property to safely access output from panel data
const panelOutput = computed(() => panel?.data?.output || null);

// Add grid state
const gridApi = ref<GridApi>()
const selectedRowCount = ref(0)

// Add selection change handler
const onSelectionChanged = () => {
  if (gridApi.value) {
    selectedRowCount.value = gridApi.value.getSelectedRows().length
  }
}

const { parse: originalParse } = usePlantGrid()

// Add a utility function to ensure botanicalname column is properly configured
const ensureBotanicalNameColumn = (columns: any[]): any[] => {
  if (!columns) return [];
  
  return columns.map(col => {
    if (col.field === 'botanicalname') {
      return {
        ...col,
        cellEditor: CustomEscapeAwareEditor || AutocompleteSelectCellEditor,
        cellEditorParams: cellEditorParams.value,
        valueParser: (params: any) => {
          if (typeof params.newValue === 'object') {
            return params.newValue?.label || '';
          }
          return params.newValue || '';
        },
        valueFormatter: (params: any) => {
          if (typeof params.value === 'object') {
            return params.value?.label || '';
          }
          return params.value || '';
        }
      };
    }
    return col;
  });
};

// Replace with a new utility function that ensures both columns are configured correctly
const ensureAutocompleteColumns = (columns: any[]) => {
  if (!columns) return []
  
  return columns.map(col => {
    if (col.field === 'botanicalname') {
      return {
        ...col,
        cellEditor: CustomEscapeAwareEditor || AutocompleteSelectCellEditor,
        cellEditorParams: cellEditorParams.value,
        valueParser: (params: any) => {
          if (!params.newValue) return null;
          
          // If we're receiving a properly formatted GBIF object already
          if (typeof params.newValue === 'object' && params.newValue.label) {
            return params.newValue;
          }
          
          // Handle if the old value was an object
          const oldValue = typeof params.oldValue === 'object' ? params.oldValue : null;
          
          // If it's a JSON string (might be after stringification)
          if (typeof params.newValue === 'string' && (params.newValue.startsWith('{') && params.newValue.endsWith('}'))) {
            try {
              const parsed = JSON.parse(params.newValue);
              return {
                ...parsed,
                updatedAt: new Date().toISOString(),
                // Add genus/family if they were in old value
                genus: parsed.genus || oldValue?.genus || '',
                family: parsed.family || oldValue?.family || ''
              };
            } catch (e) {
              console.warn('Failed to parse botanicalname JSON value', e);
            }
          }
          
          // If we're receiving just a string (direct editing)
          return {
            label: params.newValue || '',
            value: params.newValue || '',
            source: oldValue?.source || 'user-edit',
            updatedAt: new Date().toISOString(),
            // Preserve genus and family from the old value if any
            genus: oldValue?.genus || '',
            family: oldValue?.family || ''
          };
        },
        valueFormatter: (params: any) => {
          if (typeof params.value === 'object') {
            return params.value?.label || '';
          }
          return params.value || '';
        }
      };
    }
    // Add special handling for code field to ensure it displays as a badge
    if (col.field === 'code') {
      return {
        ...col,
        cellClass: 'text-center',
        cellRenderer: (params: any) => {
          try {
            // Extract the code value from objects or use direct value
            let codeValue = '';
            if (typeof params.value === 'object' && params.value !== null) {
              codeValue = params.value.value || params.value.label || '';
            } else if (typeof params.value !== 'object') {
              codeValue = params.value;
            }
            
            // Skip empty values
            if (!codeValue || codeValue === 'null' || codeValue === '') {
              return '';
            }
            
            // Create a simple HTML element with classes that match the Badge styling
            const span = document.createElement('span');
            span.className = 'inline-flex items-center rounded-full bg-muted-foreground px-2 py-1 text-xs text-background font-medium';
            span.textContent = String(codeValue);
            return span;
          } catch (error) {
            console.error('[ERROR] Code cell renderer error:', error);
            return '';
          }
        }
      };
    }
    return col;
  });
};

// Initialize panel data with defaults if not present
if (!panel.data) panel.data = { tool_uid: toolId.value };
if (!panel.data.rowData) {
  panel.data.rowData = []
}
if (!panel.data.columnDefs) {
  panel.data.columnDefs = []
}

// Initialize enabledValidationTools from panel data if available
if (panel.data?.options?.enabledValidationTools) {
  enabledValidationTools.value = panel.data.options.enabledValidationTools;
}

// Create reactive refs that sync with panel data
const rowData = computed({
  get: () => {
    const data = panel.data?.rowData || []
    // Process data to ensure botanicalname objects are properly formatted
    return data.map(row => {
      const processed = {...row}
      if (processed.botanicalname && typeof processed.botanicalname === 'object') {
        processed.botanicalname = processed.botanicalname.label || processed.botanicalname.value || JSON.stringify(processed.botanicalname)
      }
      return processed
    })
  },
  set: (newValue) => {
    if (!panel.data) panel.data = { tool_uid: toolId.value };
    panel.data.rowData = newValue
  }
})

const columnDefs = computed({
  get: () => ensureAutocompleteColumns(panel.data?.columnDefs || []),
  set: (newValue) => {
    if (!panel.data) panel.data = { tool_uid: toolId.value };
    panel.data.columnDefs = ensureAutocompleteColumns(newValue);
  }
})

// Add zoom level state
const zoomLevel = ref(1)
const MIN_ZOOM = 0.5
const MAX_ZOOM = 2

// Add toggle for sizing
const useFitContents = ref(true)

// Add selectedApiService computed
const apiService = computed({
  get: () => {
    // First try to get from panel data
    const panelService = panel.data?.options?.apiService
    if (panelService) return panelService
    
    // Otherwise use store default
    return defaultApiService.value
  },
  set: (value) => {
    // Update both panel and store
    if (!panel.data.options) {
      panel.data.options = {}
    }
    panel.data.options.apiService = value
    dockStore.setDefaultApiService(value)
  }
})

// Initialize panel data with defaults if not present
if (!panel.data.rowData) {
  panel.data.rowData = []
}
if (!panel.data.columnDefs) {
  panel.data.columnDefs = []
}

// Add theme parameters
const BASE_FONT_SIZE = 14
const BASE_ROW_HEIGHT = 48
const BASE_HEADER_HEIGHT = 48

const themeParams = ref({
  fontSize: BASE_FONT_SIZE,
  headerFontSize: BASE_FONT_SIZE + 2,
  rowHeight: BASE_ROW_HEIGHT,
  headerHeight: BASE_HEADER_HEIGHT,
  fontFamily: 'InterVariable, sans-serif',
  cellHorizontalPadding: 12,
  cellVerticalPadding: 16,
  rangeSelectionBorderStyle: 'solid' as const,
  rangeSelectionBorderColor: 'hsl(var(--primary) / 0.5)',
  selectedRowBackgroundColor: 'hsl(var(--muted) / 0.5)',
  accentColor: 'hsl(var(--muted))',
  backgroundColor: 'hsl(var(--background))',
  foregroundColor: 'hsl(var(--foreground))',
  headerBackgroundColor: 'hsl(var(--muted) / 0.5)',
  controlPanelBackgroundColor: 'hsl(var(--muted))',
  checkboxCheckedBackgroundColor: 'hsl(var(--primary))',
  rangeSelectionBackgroundColor: 'hsl(var(--muted) / 0.5)',
  cellEditingShadow: { offsetX: 0, offsetY: 0, radius: 0, spread: 0, color: 'rgba(0, 0, 0, 0)' },
  inputFocusShadow: { offsetX: 0, offsetY: 0, radius: 0, spread: 0, color: 'rgba(0, 0, 0, 0)' },
  rowHoverColor: 'hsl(var(--muted) / 0.5)',
  inputBorderRadius: 4,
  cellEditingBorderColor: 'hsl(var(--primary))',
  cellEditingBorder: { width: 2, color: 'hsl(var(--primary))', radius: 4 },
  inputBorder: { width: 2, color: 'hsl(var(--primary))', radius: 4 },
  inputFocusBorder: { width: 2, color: 'hsl(var(--primary))', radius: 4 }
})

// Create theme
const myTheme = themeQuartz.withParams({
  fontFamily: themeParams.value.fontFamily,
  cellHorizontalPadding: themeParams.value.cellHorizontalPadding,
  rangeSelectionBorderStyle: themeParams.value.rangeSelectionBorderStyle as 'solid',
  rangeSelectionBorderColor: themeParams.value.rangeSelectionBorderColor,
  rangeSelectionBackgroundColor: themeParams.value.rangeSelectionBackgroundColor,
  cellEditingShadow: themeParams.value.cellEditingShadow,
  inputFocusShadow: themeParams.value.inputFocusShadow,
  selectedRowBackgroundColor: themeParams.value.selectedRowBackgroundColor,
  accentColor: themeParams.value.accentColor,
  backgroundColor: themeParams.value.backgroundColor,
  foregroundColor: themeParams.value.foregroundColor,
  headerBackgroundColor: themeParams.value.headerBackgroundColor,
  checkboxCheckedBackgroundColor: themeParams.value.checkboxCheckedBackgroundColor,
  rowHoverColor: themeParams.value.rowHoverColor,
  inputBorderRadius: themeParams.value.inputBorderRadius,
  cellEditingBorder: themeParams.value.cellEditingBorder
})

// Add computed style for grid
const gridStyle = computed(() => ({
  width: '100%',
  height: '100%',
  '--ag-font-size': `${themeParams.value.fontSize}px`,
  '--ag-header-font-size': `${themeParams.value.headerFontSize}px`,
  '--ag-row-height': `${themeParams.value.rowHeight}px`,
  '--ag-header-height': `${themeParams.value.headerHeight}px`,
  '--ag-value-change-value-highlight-background-color': 'hsl(var(--primary) / 0.5)'
}))

// Watch color mode changes to update grid theme
watch(() => colorMode.value, (newMode) => {
  const isDark = newMode === 'dark'
  document.body.setAttribute('data-ag-theme-mode', isDark ? 'dark' : 'light')
}, { immediate: true })

// Add the transformers to the component setup
const { transformData } = usePanelDataTransformers()

// Add a computed property for the tool ID
const toolId = computed(() => tool?.id || 'plantgrid')


// Create a function to safely process output data
const parse = (output: any, parser: any): any => {
  console.log('PlantGrid intercepted parse call with parser:', parser)
  // Ensure parser is one of the expected values
  const validParser = (typeof parser === 'string' && (parser === 'gemini' || parser === 'llamaparse')) 
    ? parser as 'gemini' | 'llamaparse' 
    : 'llamaparse';

  console.log(`PlantGrid intercepted parse call with parser: ${parser}`, {
    outputType: typeof output,
    isNull: output === null,
    isUndefined: output === undefined,
    isObject: typeof output === 'object' && output !== null,
    keys: typeof output === 'object' && output !== null ? Object.keys(output) : [],
    parser: validParser
  });
  
  try {
    const result = originalParse(output, validParser)
    // Use the utility function instead of inline code
    if (result?.columnDefs) {
      result.columnDefs = ensureAutocompleteColumns(result.columnDefs);
    }

    console.log(`Parse result:`, {
      success: !!result,
      rowCount: result?.rowData?.length || 0,
      columnCount: result?.columnDefs?.length || 0,
      rowData: result?.rowData,
      columnDefs: result?.columnDefs
    });
    return result;
  } catch (error) {
    console.error(`Error in parse function with parser ${validParser}:`, error);
    return null;
  }
};

// Create a flag to prevent recursive calls
const isProcessingOutput = ref(false);

// Add a lock for event processing
const isProcessingEvent = ref(false)

// Create a single listener for botanicalname updates from our transformer system
mitter.listen('plantgrid:row:set:botanicalname' as any, async (data: any) => {
  console.log('[DEBUG Event] plantgrid:row:set:botanicalname event received:', JSON.stringify(data));
  
  // Skip if already processing an event or grid API not ready
  if (isProcessingEvent.value || !gridApi.value) {
    console.log('[DEBUG Event] Skipping botanicalname update - already processing an event or grid not ready');
    return;
  }
  
  // Set the lock
  isProcessingEvent.value = true;
  
  try {
    // Update the row data if we have a valid botanical name
    if (data?.botanicalname) {
      console.log('[DEBUG Event] Updating botanicalname to:', data.botanicalname);
      
      // If we have the source information, add it to logs
      if (data.sourceColumn) {
        console.log(`[DEBUG Event] Update triggered from ${data.sourceColumn} with value: ${data.sourceValue}`);
      }
      
      // Wait for grid to be ready
      await nextTick();
      
      // Call our helper function to find and update the matching row
      await findAndUpdateGridRow(data.sourceColumn, data.sourceValue, {
        botanicalname: data.botanicalname
      });
    }
  } catch (error) {
    console.error('[ERROR] Error processing botanicalname event:', error);
  } finally {
    // Release the lock
    isProcessingEvent.value = false;
  }
});

// Create a listener for commonname updates from our transformer system
mitter.listen('plantgrid:row:set:commonname' as any, async (data: any) => {
  console.log('[DEBUG Event] plantgrid:row:set:commonname event received:', JSON.stringify(data));
  
  // Skip if already processing an event or grid API not ready
  if (isProcessingEvent.value || !gridApi.value) {
    console.log('[DEBUG Event] Skipping commonname update - already processing an event or grid not ready');
    return;
  }
  
  // Set the lock
  isProcessingEvent.value = true;
  
  try {
    // Update the row data if we have a valid common name
    if (data?.commonname) {
      console.log('[DEBUG Event] Updating commonname to:', data.commonname);
      
      // If we have the source information, add it to logs
      if (data.sourceColumn) {
        console.log(`[DEBUG Event] Update triggered from ${data.sourceColumn} with value: ${data.sourceValue}`);
      }
      
      // Wait for grid to be ready
      await nextTick();
      
      // Call our helper function to find and update the matching row
      await findAndUpdateGridRow(data.sourceColumn, data.sourceValue, {
        commonname: data.commonname
      });
    }
  } catch (error) {
    console.error('[ERROR] Error processing commonname event:', error);
  } finally {
    // Release the lock
    isProcessingEvent.value = false;
  }
});

// Create a listener for code updates from our transformer system
mitter.listen('plantgrid:row:set:code' as any, async (data: any) => {
  console.log('[DEBUG] Received transformer event: plantgrid:row:set:code \n', data);
  
  // Skip if already processing an event or grid API not ready
  if (isProcessingEvent.value || !gridApi.value) {
    console.log('[DEBUG Event] Skipping code update - already processing an event or grid not ready');
    return;
  }
  
  // Set the lock
  isProcessingEvent.value = true;
  
  try {
    // Update the row data if we have a valid code
    if (data?.code) {
      console.log('[DEBUG] Updating grid cell code with value:', data.code);
      
      // If we have the source information, add it to logs
      if (data.sourceColumn) {
        console.log(`[DEBUG] Update triggered from ${data.sourceColumn} with value:`, data.sourceValue);
      }
      
      // Format the code value as an object with metadata - this is crucial
      const codeValue = {
        value: data.code,
        source: 'transformer',
        sourceField: data.sourceColumn || 'botanicalname',
        updatedAt: new Date().toISOString()
      };
      
      // Wait for grid to be ready
      await nextTick();
      
      // Call our helper function to find and update the matching row
      await findAndUpdateGridRow(data.sourceColumn, data.sourceValue, {
        code: codeValue
      });
    }
  } catch (error) {
    console.error('[ERROR] Error processing code event:', error);
  } finally {
    // Release the lock
    isProcessingEvent.value = false;
  }
});

// Helper function to find and update a grid row based on source column/value
const findAndUpdateGridRow = async (sourceColumn: string, sourceValue: any, changes: Record<string, any>) => {
  if (!gridApi.value) {
    console.log('[DEBUG] Grid API not available, skipping update');
    return;
  }
  
  console.log('[DEBUG] Finding grid row to update:', {
    sourceColumn,
    sourceValue,
    changes
  });
  
  let foundMatch = false;
  let rowCount = 0;
  let emptyRowIndices: number[] = [];
  
  // First pass: identify ALL empty rows
  gridApi.value.forEachNode((node, index) => {
    rowCount++;
    
    // Skip if row has no data
    if (!node.data) return;
    
    // Check if this is an empty row (no meaningful values)
    const isEmpty = Object.entries(node.data).every(([key, val]) => {
      if (key === 'checkboxSelection') return true; // Skip check column
      
      // If value is an object, check its value property
      if (typeof val === 'object' && val !== null && 'value' in val) {
        return !val.value || val.value === null || val.value === '';
      }
      
      // Otherwise check direct value
      return !val || val === null || val === '';
    });
    
    if (isEmpty) {
      emptyRowIndices.push(index);
    }
  });
  
  console.log(`[DEBUG] Grid has ${rowCount} rows. Empty rows at indices:`, emptyRowIndices);
  
  // Check for currently focused cell
  const focusedCell = gridApi.value.getFocusedCell();
  console.log('[DEBUG] Currently focused cell:', focusedCell);
  
  // Second pass: find the row to update based on various criteria
  gridApi.value.forEachNode((node, index) => {
    // Skip if already found a match or row has no data
    if (foundMatch || !node.data) return;
    
    // Skip ALL empty rows
    if (emptyRowIndices.includes(index)) {
      console.log(`[DEBUG] Skipping empty row at index ${index}`);
      return;
    }
    
    // Different strategies to match the row:
    
    // 1. If we have source column and value, try to match with that
    if (sourceColumn && sourceValue !== undefined) {
      const nodeValue = node.data[sourceColumn];
      let sourceMatch = false;
      
      // Compare based on the type of values
      if (typeof nodeValue === 'object' && nodeValue !== null) {
        // Handle object comparison
        if (typeof sourceValue === 'object' && sourceValue !== null) {
          // Both are objects, compare value/label properties
          sourceMatch = 
            (nodeValue.value === sourceValue.value) || 
            (nodeValue.label === sourceValue.label);
        } else {
          // Node value is object, source value is primitive
          sourceMatch = 
            (nodeValue.value === sourceValue) || 
            (nodeValue.label === sourceValue);
        }
      } else if (typeof sourceValue === 'object' && sourceValue !== null) {
        // Node value is primitive, source value is object
        sourceMatch = 
          (nodeValue === sourceValue.value) || 
          (nodeValue === sourceValue.label);
      } else {
        // Both are primitives
        sourceMatch = nodeValue === sourceValue;
      }
      
      if (sourceMatch) {
        console.log(`[DEBUG] Match found at node ${index} by sourceColumn=${sourceColumn} and sourceValue=${JSON.stringify(sourceValue)}`);
        foundMatch = true;
        updateGridNode(node, changes);
        return;
      }
    }
    // 2. If we have a focused row, use that (but not if it's an empty row)
    else if (focusedCell && node.rowIndex === focusedCell.rowIndex && !emptyRowIndices.includes(node.rowIndex)) {
      console.log(`[DEBUG] Match found at node ${index} because it's the focused row!`);
      foundMatch = true;
      updateGridNode(node, changes);
      return;
    }
  });
  
  // If no row matched the criteria so far, use the first non-empty row as a fallback
  if (!foundMatch) {
    console.log('[DEBUG] No matching row found. Applying to first non-empty row as fallback.');
    
    // Wait to ensure grid is stable
    await nextTick();
    
    gridApi.value.forEachNode((node, index) => {
      if (!foundMatch && !emptyRowIndices.includes(index) && node.data) {
        console.log(`[DEBUG] Applying to first non-empty row at index ${index}`);
        foundMatch = true;
        updateGridNode(node, changes);
      }
    });
  }
};

const cellEditorParams = ref({
  autocomplete: {
    debounceWaitMs: 100,
    fetch: async (cellEditor: any, text: any, update: any) => {
      try { 
        let match = text?.toLowerCase() || cellEditor.eInput.value.toLowerCase();
        
        // Skip API calls if match is empty or just whitespace
        if (!match || match.trim() === '') {
          update([]);
          return;
        }
        
        // Start spell check in parallel immediately so it has time to complete
        const spellCheckPromise = debouncedSpellCheck(match);

        // Helper function to sort by taxonomic rank priority
        const getRankPriority = (rank: string = ''): number => {
          const priority: {[key: string]: number} = {
            'species': 1,
            'variety': 2,
            'subspecies': 3,
            'genus': 4,
            'family': 5
          };
          const lowerRank = rank.toLowerCase();
          return priority[lowerRank] || 999; // Unknown ranks get lowest priority
        };

        // 1. First priority: GBIF suggest API (fastest and most direct)
        const suggestData = await $fetch<any[]>(`https://api.gbif.org/v1/species/suggest?q=${encodeURIComponent(match)}&higherTaxonKey=6`);
        
        let items = suggestData.map((d: any) => ({ 
          value: d.canonicalName,
          label: d.canonicalName,
          group: d.rank,
          genus: d.genus,
          family: d.family,
          source: 'gbif'
        }));
        
        // Sort items by rank priority
        items.sort((a, b) => getRankPriority(a.group) - getRankPriority(b.group));
        
        // If suggest API returned results, use them immediately
        if (items.length > 0) {
          update(items);
          return;
        }
        
        // 2. Second priority: GBIF search API (if suggest didn't return results)
        if (match.length > 2) {
          try {
            const searchData = await $fetch<{results: any[]}>(`https://api.gbif.org/v1/species/search`, {
              method: 'GET',
              params: {
                q: match,
                datasetKey: 'd7dddbf4-2cf0-4f39-9b2a-bb099caae36c', // GBIF Backbone Taxonomy
                higherTaxonKey: 6, // Plantae
                limit: 10
              }
            });
            
            if (searchData.results && searchData.results.length > 0) {
              const searchItems = searchData.results.map((result: any) => ({
                value: result.canonicalName,
                label: result.canonicalName,
                group: result.rank || 'Search Result',
                genus: result.genus,
                family: result.family,
                source: 'gbif'
              }));
              
              // Sort search items by rank priority
              searchItems.sort((a, b) => getRankPriority(a.group) - getRankPriority(b.group));
              
              update(searchItems);
              return;
            }
          } catch (searchError) {
            console.error('Error with GBIF search API:', searchError);
            // Continue to AI fallback if search fails
          }
        }
        
        // 3. Third priority: Gemini AI suggestions (should be resolved by now)
        try {
          const spellCheckResults = await spellCheckPromise;
          
          // Sort AI results by rank priority
          if (spellCheckResults && spellCheckResults.length > 0) {
            spellCheckResults.sort((a, b) => getRankPriority(a.group) - getRankPriority(b.group));
            update(spellCheckResults);
            return;
          }
        } catch (aiError) {
          console.error('Error with AI spell check:', aiError);
        }
        
        // If all methods failed, return empty results
        update([]);
      } catch (error) {
        console.error('Error fetching species data:', error);
        update(false);
      }
    },
    onSelect: async (cellEditor: any, selectedItem: any) => {
      console.log('[DEBUG] botanicalName onSelect called with:', selectedItem);
      
      // Add safety check for selectedItem
      if (!selectedItem) {
        console.warn('[DEBUG] No selectedItem in onSelect, skipping processing');
        return;
      }
      
      // Log detailed information about genus and family from GBIF
      if (selectedItem.genus) {
        console.log(`[PlantGrid.onSelect] Found genus '${selectedItem.genus}' in GBIF response`);
      }
      
      if (selectedItem.family) {
        console.log(`[PlantGrid.onSelect] Found family '${selectedItem.family}' in GBIF response`);
      }
      
      // Set the cell value immediately before async operations
      const valueToSet = selectedItem.value || selectedItem.label || '';
      cellEditor.currentItem = valueToSet;
      
      if (valueToSet) {
        // Create a flat object that includes all relevant information
        const botanicalNameObject = {
          label: valueToSet,
          value: valueToSet,
          source: selectedItem.source || 'gbif', // Default to 'gbif' for autocomplete selections
          updatedAt: new Date().toISOString(),
          // Include genus and family directly at the top level
          genus: selectedItem.genus || '',
          family: selectedItem.family || ''
        };
        
        // Log the created object for debugging
        console.log('[DEBUG] Created botanicalname object with genus/family:', botanicalNameObject);
        
        // Create update queue item using our transformer system
        try {
          const updateItem = await createUpdateQueueItem('botanicalname', valueToSet, botanicalNameObject);
          
          if (updateItem) {
            updateQueue.value.push(updateItem);
            console.log('[DEBUG] Added to updateQueue, new length:', updateQueue.value.length);
          }
          
          // Process the update to emit events
          console.log('[DEBUG] Processing cell update to emit events');
          const result = await processCellUpdate('botanicalname', valueToSet, botanicalNameObject);
          
          console.log('[DEBUG] processCellUpdate result:', result);
        } catch (error) {
          console.error('[DEBUG] Error in botanicalname onSelect:', error);
        }
      }
      
      // Ensure the value is set again after async operations
      cellEditor.currentItem = valueToSet;
      
      // Manually trigger valueChanged event if needed
      if (cellEditor.stopEditing) {
        cellEditor.stopEditing();
      }
    },
    emptyMsg: 'No scientific names found',
    showOnFocus: true,
    strict: false,
    onFreeTextSelect: (cellEditor: any, userInput: string) => {
      console.log('[DEBUG] onFreeTextSelect called with input:', userInput);
      // Return a properly formatted object with user-input source
      return {
        value: userInput,
        label: userInput,
        source: 'user-input',
        updatedAt: new Date().toISOString()
      };
    }
  },
  placeholder: 'Search scientific names...',
});

// Add the gridDataTransformers import and initialization
const { createUpdateQueueItem, processCellUpdate, transformers, registerTransformer } = useGridDataTransformers();

// Register the botanicalname to code transformer
registerTransformer({
  sourceColumn: 'botanicalname',
  targetColumns: ['code'],
  transform: (value) => {
    console.log('[TRANSFORMER DEBUG] botanicalname to code transformer input:', value);
    
    // Extract the actual value from object if needed
    let rawValue = '';
    
    // Handle JSON string objects that might have been stringified
    if (typeof value === 'string' && value.startsWith('{') && value.endsWith('}')) {
      try {
        const parsed = JSON.parse(value);
        if (parsed && typeof parsed === 'object') {
          rawValue = parsed.value || parsed.label || '';
          console.log('[TRANSFORMER DEBUG] Extracted value from JSON string:', rawValue);
        }
      } catch (e) {
        // If parsing fails, use the string value as is
        rawValue = value;
      }
    } else if (typeof value === 'object' && value !== null) {
      // Normal object handling
      rawValue = value.value || value.label || '';
      console.log('[TRANSFORMER DEBUG] Extracted value from object:', rawValue);
    } else if (typeof value === 'string') {
      // Normal string handling
      rawValue = value;
      console.log('[TRANSFORMER DEBUG] Using raw string value:', rawValue);
    } else {
      console.log('[TRANSFORMER DEBUG] Unable to extract value from:', value);
      return { code: '' };
    }
    
    // Return empty if we couldn't extract a valid value or if it's [object Object]
    if (!rawValue || rawValue === '[object Object]') {
      console.log('[TRANSFORMER DEBUG] Invalid value detected:', rawValue);
      return { code: '' };
    }
    
    // Split the botanical name into words
    const parts = rawValue.toString().trim().split(' ');
    
    let codeValue = '';
    if (parts.length >= 2) {
      // If we have at least two words, use first 3 letters of first word (uppercase) + space + first 3 letters of second word (lowercase)
      const genus = parts[0].slice(0, 3).toUpperCase();
      const species = parts[1].slice(0, 3).toLowerCase();
      codeValue = `${genus} ${species}`;
    } else if (parts.length === 1) {
      // If we only have one word, use first 3 letters uppercase
      codeValue = parts[0].slice(0, 3).toUpperCase();
    }
    
    // Important: Return a simple string value instead of an object with nested structure
    // The object wrapping will be done by the event handler
    console.log('[TRANSFORMER DEBUG] Generated code:', codeValue);
    return { code: codeValue };
  },
  emitEvents: true,
  description: 'Generates a code from botanical name using a specific format'
});

// Add a transformer to ensure common name resolutions update the botanical name
registerTransformer({
  sourceColumn: 'commonname',
  targetColumns: ['botanicalname'],
  transform: async (value) => {
    console.log('[TRANSFORMER DEBUG] commonname to botanicalname transformer input:', value);
    
    // Extract common name value
    let commonName = '';
    if (typeof value === 'object' && value !== null) {
      commonName = value.value || value.label || '';
      
      // If the object already has botanicalName property, use it directly
      if (value.botanicalName) {
        console.log('[TRANSFORMER DEBUG] Found botanicalName in object:', value.botanicalName);
        return { 
          botanicalname: {
            label: value.botanicalName,
            value: value.botanicalName,
            source: 'commonname-lookup',
            updatedAt: new Date().toISOString()
          }
        };
      }
    } else if (typeof value === 'string') {
      commonName = value;
    } else {
      return {}; // No updates if we can't extract a common name
    }
    
    // Skip if empty
    if (!commonName || commonName === 'null') {
      return {};
    }
    
    try {
      // Search for botanical name using GBIF API
      console.log('[TRANSFORMER DEBUG] Searching botanical name for common name:', commonName);
      
      const searchData = await $fetch<{results: any[]}>(`https://api.gbif.org/v1/species/search`, {
        method: 'GET',
        params: {
          q: commonName,
          qField: 'VERNACULAR',
          rank: 'species',
          status: 'ACCEPTED',
          datasetKey: 'd7dddbf4-2cf0-4f39-9b2a-bb099caae36c', // GBIF Backbone Taxonomy
          higherTaxonKey: 6, // Plantae kingdom
          limit: 1
        }
      });
      
      if (searchData.results && searchData.results.length > 0) {
        const scientificName = searchData.results[0].canonicalName || searchData.results[0].scientificName;
        const genus = searchData.results[0].genus || '';
        const family = searchData.results[0].family || '';
        
        console.log('[TRANSFORMER DEBUG] Found botanical name:', scientificName);
        
        return {
          botanicalname: {
            label: scientificName,
            value: scientificName,
            source: 'commonname-lookup',
            genus,
            family,
            updatedAt: new Date().toISOString()
          }
        };
      }
    } catch (error) {
      console.error('[TRANSFORMER DEBUG] Error looking up botanical name:', error);
    }
    
    return {}; // No updates if we couldn't find anything
  },
  emitEvents: true,
  description: 'Looks up botanical name based on common name'
});

// Create a similar cellEditorParams for commonname that uses vernacular name search
const commonNameCellEditorParams = ref({
  autocomplete: {
    debounceWaitMs: 100,
    fetch: async (cellEditor: any, text: any, update: any) => {
      try { 
        let match = text?.toLowerCase() || cellEditor.eInput.value.toLowerCase();
                   
        // Skip API calls if match is empty or just whitespace
        if (!match || match.trim() === '') {
          update([]);
          return;
        }
        
        // Helper function to sort by relevance and species rank
        const getRankPriority = (rank: string = ''): number => {
          const priority: {[key: string]: number} = {
            'species': 1,
            'variety': 2,
            'subspecies': 3,
            'genus': 4,
            'family': 5
          };
          const lowerRank = rank.toLowerCase();
          return priority[lowerRank] || 999; // Unknown ranks get lowest priority
        };

        // Search for common names using the vernacular name API
        try {
          const searchData = await $fetch<{results: any[]}>(`https://api.gbif.org/v1/species/search`, {
            method: 'GET',
            params: {
              q: match,
              qField: 'VERNACULAR',
              rank: 'species',
              status: 'ACCEPTED',
              datasetKey: 'd7dddbf4-2cf0-4f39-9b2a-bb099caae36c', // GBIF Backbone Taxonomy
              higherTaxonKey: 6, // Plantae kingdom
              limit: 20
            }
          });
          
          if (searchData.results && searchData.results.length > 0) {
            const vernacularItems = searchData.results
              .filter(result => result.vernacularNames && result.vernacularNames.length > 0)
              .map((result: any) => {
                // Sort vernacular names to prioritize English names first
                const sortedVernacularNames = [...result.vernacularNames].sort((a: any, b: any) => {
                  const aIsEnglish = a.language === 'eng';
                  const bIsEnglish = b.language === 'eng';
                  
                  if (aIsEnglish && !bIsEnglish) return -1;
                  if (!aIsEnglish && bIsEnglish) return 1;
                  return 0;
                });
                
                // Get the common name that matched the search
                const matchingVernacularName = sortedVernacularNames.find((vn: any) => 
                  vn.vernacularName.toLowerCase().includes(match)
                );
                
                // Use matching vernacular name or first one available (which will be English if available)
                const displayName = matchingVernacularName ? 
                  matchingVernacularName.vernacularName : 
                  (sortedVernacularNames[0]?.vernacularName || 'Unknown');
                
                return {
                  value: displayName,
                  label: displayName,
                  botanicalName: result.canonicalName || result.scientificName,
                  group: 'Common Name'
                };
              });
            
            update(vernacularItems);
            return;
          }
        } catch (apiError) {
          console.error('Error with GBIF vernacular search API:', apiError);
        }
        
        // If no results, return empty array
        update([]);
      } catch (error) {
        console.error('Error fetching common name data:', error);
        update([]);
      }
    },
    onSelect: async (cellEditor: any, selectedItem: any) => {
      console.log('[DEBUG] commonName onSelect called with:', selectedItem);
      
      // Add safety check for selectedItem
      if (!selectedItem) {
        console.warn('[DEBUG] No selectedItem in onSelect for commonName, skipping processing');
        return;
      }
      
      // Set the cell value immediately before async operations
      const valueToSet = selectedItem.value || selectedItem.label || '';
      cellEditor.currentItem = valueToSet;
      
      if (valueToSet) {
        // Create update queue item using our transformer system
        try {
          // If we have a botanical name from the common name lookup, use it
          if (selectedItem.botanicalName) {
            console.log(`[DEBUG] Common name selection includes botanical name: ${selectedItem.botanicalName}`);
            
            // First create the common name update
            const commonNameUpdate = await createUpdateQueueItem('commonname', valueToSet, selectedItem);
            if (commonNameUpdate) {
              updateQueue.value.push(commonNameUpdate);
              console.log('[DEBUG] Added commonname to updateQueue, new length:', updateQueue.value.length);
            }
            
            // Then create a botanical name update to trigger transformers for height
            const botanicalNameObject = {
              label: selectedItem.botanicalName,
              value: selectedItem.botanicalName,
              source: 'commonname-resolution',
              updatedAt: new Date().toISOString()
            };
            
            // This will trigger the same transformers as if the botanical name was entered directly
            const botanicalUpdate = await createUpdateQueueItem('botanicalname', selectedItem.botanicalName, botanicalNameObject);
            if (botanicalUpdate) {
              updateQueue.value.push(botanicalUpdate);
              console.log('[DEBUG] Added derived botanicalname to updateQueue, new length:', updateQueue.value.length);
            }
            
            // Process both updates
            console.log('[DEBUG] Processing cell update to emit events for common name and botanical name');
            await processCellUpdate('commonname', valueToSet, selectedItem);
            await processCellUpdate('botanicalname', selectedItem.botanicalName, botanicalNameObject);
          } else {
            // Standard handling for just the common name
            const updateItem = await createUpdateQueueItem('commonname', valueToSet, selectedItem);
            if (updateItem) {
              updateQueue.value.push(updateItem);
              console.log('[DEBUG] Added to updateQueue, new length:', updateQueue.value.length);
            }
            
            // Process the update to emit events
            console.log('[DEBUG] Processing cell update to emit events');
            const result = await processCellUpdate('commonname', valueToSet, selectedItem);
            console.log('[DEBUG] processCellUpdate result:', result);
          }
        } catch (error) {
          console.error('[DEBUG] Error in commonname onSelect:', error);
        }
      }
      
      // Ensure the value is set again after async operations
      cellEditor.currentItem = valueToSet;
      
      // Manually trigger valueChanged event if needed
      if (cellEditor.stopEditing) {
        cellEditor.stopEditing();
      }
    },
    emptyMsg: 'No common names found',
    showOnFocus: true,
    strict: false,
    onFreeTextSelect: (cellEditor: any, userInput: string) => {
      return userInput;
    }
  },
  placeholder: 'Search common names...',
});

// Add back the ExtendedColDef interface before the columnDefs initialization
interface ExtendedColDef extends ColDef {
  field: string;
  headerName: string;
  type?: string;
  // Add other properties as needed
}

// Create initial column definitions only if they don't exist in panel.data
if (!columnDefs.value?.length) {
  columnDefs.value = [
  {
      headerName: "",
      field: "checkboxSelection",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      pinned: "left",
      lockPosition: true,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      sortable: false,
      filter: false,
      resizable: false,
  },
  {
      headerName: "Code",
      field: 'code',
      minWidth: 90,
      type: 'codeColumn',
      // Use AG Grid's Vue component cell renderer approach
      cellRenderer: (params: any) => {
        try {
          console.log('[DEBUG] Code cell renderer received value:', params.value);
          
          // Handle null/undefined/empty values
          if (params.value === null || params.value === undefined) {
            return '';
          }
          
          // Extract the value from the object
          let codeValue = '';
          
          // Direct approach for the known object structure
          if (typeof params.value === 'object' && params.value !== null && params.value.value !== undefined) {
            console.log('[DEBUG] Found direct value property:', params.value.value);
            codeValue = params.value.value;
          } else if (typeof params.value !== 'object') {
            codeValue = params.value;
          }
          
          // Skip empty values
          if (!codeValue || codeValue === 'null' || codeValue === '') {
            return '';
          }
          
          // Create a simple HTML element with classes that match the Badge styling
          // This is more reliable than trying to use the Vue component directly
          const span = document.createElement('span');
          span.className = 'inline-flex items-center rounded-full bg-muted-foreground px-2 py-1 text-xs text-background font-medium';
          span.textContent = String(codeValue);
          return span;
        } catch (error) {
          // Log error but don't crash the renderer
          console.error('[ERROR] Code cell renderer error:', error);
          
          // Return a fallback string to prevent DOM errors
          return '';
        }
      },
      editable: true,
      cellClass: 'text-center',
      // Add valueParser to handle object values when editing 
      valueParser: (params: any) => {
        // If editing an existing object value, preserve metadata
        const oldValue = params.data[params.column.colId];
        const metadata = (typeof oldValue === 'object' && oldValue !== null) 
          ? { ...oldValue, source: oldValue.source || 'user-edit' } 
          : { source: 'user-edit' };
        
        // Create a new object with the updated value and preserved metadata
        return { 
          ...metadata,
          value: params.newValue,
          updatedAt: new Date().toISOString()
        };
      },
      // Add valueFormatter to ensure consistency with column type
      valueFormatter: (params: any) => {
        if (params.value === null || params.value === undefined) {
          return '';
        }
        
        // Extract the value from object structure if needed
        if (typeof params.value === 'object' && params.value !== null) {
          return params.value.value !== undefined ? params.value.value : '';
        }
        
        // Handle case where value is exactly the string "null"
        if (params.value === "null") {
          return '';
        }
        
        return params.value;
      }
    },
  {
   headerName: "Botanical Name",
   field: "botanicalname",
   cellClass: 'font-[500]',
   minWidth: 250,
   suppressSizeToFit: true,
   resizable: true,
   pinned: "left",
   cellEditor: CustomEscapeAwareEditor || AutocompleteSelectCellEditor,
   cellEditorParams: cellEditorParams.value,
   // Add equals function for proper undo/redo comparison
   equals: (valueA: any, valueB: any) => {
     // If both are objects with label/value properties
     if (typeof valueA === 'object' && valueA !== null && typeof valueB === 'object' && valueB !== null) {
       return valueA.label === valueB.label && valueA.value === valueB.value;
     }
     // If one is an object and one isn't
     if (typeof valueA === 'object' && valueA !== null) {
       return valueA.label === valueB || valueA.value === valueB;
     }
     if (typeof valueB === 'object' && valueB !== null) {
       return valueA === valueB.label || valueA === valueB.value;
     }
     // Direct comparison
     return valueA === valueB;
   },
   valueParser: (params: any) => {
     // We need to preserve the genus and family information when editing
     const oldValue = params.data[params.column.colId];
     
     // If we're receiving a full object (e.g., from autocomplete)
     if (typeof params.newValue === 'object' && params.newValue !== null) {
       return {
         label: params.newValue?.label || '',
         value: params.newValue?.value || params.newValue?.label || '',
         source: params.newValue?.source || oldValue?.source || 'user-edit',
         updatedAt: new Date().toISOString(),
         // Preserve genus and family from the new value or from the old value
         genus: params.newValue?.genus || oldValue?.genus || '',
         family: params.newValue?.family || oldValue?.family || ''
       };
     }
     
     // If we're receiving just a string (direct editing)
     return {
       label: params.newValue || '',
       value: params.newValue || '',
       source: oldValue?.source || 'user-edit',
       updatedAt: new Date().toISOString(),
       // Preserve genus and family from the old value
       genus: oldValue?.genus || '',
       family: oldValue?.family || ''
     };
   },
   valueFormatter: (params: any) => {
     if (params.value === null || params.value === undefined) {
       return '';
     }
     
     // Extract the label from object structure if needed
     if (typeof params.value === 'object' && params.value !== null) {
       return params.value.label || params.value.value || '';
     }
     
     return params.value;
   },
   editable: true,
    },
    ...Object.entries(defaultColumnSchema.properties).map(([key, value]) => {
      const isCommonName = key.toLowerCase() === 'commonname';
      const isFixedColumn = ['commonname'].includes(key.toLowerCase());
      const isMeasurementColumn = ['maturewidth', 'width', 'height', 'matureheight'].includes(key.toLowerCase());
      const isHeightColumn = key.toLowerCase().includes('height');
      const isCodeColumn = key.toLowerCase() === 'code';
      
      
      const baseColDef = {
        field: key,
        cellSelection: true,
        headerName: columnDisplayLabels[key as keyof typeof defaultColumnSchema.properties],
        headerTooltip: `${columnDisplayLabels[key as keyof typeof defaultColumnSchema.properties]} (${value.type})`,
        // Special handling for different column types
        type: isCodeColumn ? 'codeColumn' : 
              isHeightColumn ? 'heightColumn' :
              isMeasurementColumn ? 'measurementColumn' :
              (value.type === 'number' ? 'numberColumn' : 'textColumn'),
        editable: true,
        sortable: true,
        filter: true,
        resizable: true,
        suppressSizeToFit: isFixedColumn,
        ...(isFixedColumn ? { minWidth: 180 } : { minWidth: 50 }),
        // Add base valueFormatter and valueParser for all fields to handle object structure
        valueFormatter: (params: any) => {
          // Handle empty/null values
          if (params.value === null || params.value === undefined) {
            return '';
          }
          
          // Extract the value from object structure if needed
          const effectiveValue = typeof params.value === 'object' && params.value !== null
            ? params.value.value
            : params.value;
          
          // Check if this value should be displayed as empty
          if (shouldDisplayAsEmpty(effectiveValue, params.column.colId)) {
            return '';
          }
          
          // Return the value as string
          return String(effectiveValue);
        },
        valueParser: (params: any) => {
          // If editing an existing object value, preserve metadata
          const oldValue = params.data[params.column.colId];
          const metadata = (typeof oldValue === 'object' && oldValue !== null) 
            ? { ...oldValue, source: oldValue.source || 'user-edit' } 
            : { source: 'user-edit' };
          
          // Create a new object with the updated value and preserved metadata
          return { 
            ...metadata,
            value: params.newValue,
            updatedAt: new Date().toISOString()
          };
        },
        // Override with specific formatters for measurement columns (non-height)
        ...(isMeasurementColumn ? {
          valueFormatter: (params: any) => {
            if (params.value === null || params.value === undefined) {
              return '';
            }
            
            // Use our consistent formatter for measurement values
            return formatMeasurementValue(params.value, params.column.colId);
          },
          valueParser: (params: any) => {
            // If editing an existing object value, preserve metadata
            const oldValue = params.data[params.column.colId];
            const metadata = (typeof oldValue === 'object' && oldValue !== null) 
              ? { ...oldValue, source: oldValue.source || 'user-edit' } 
              : { source: 'user-edit' };
            
            // Process the new numeric value
            let newValue = null;
            if (params.newValue !== null && params.newValue !== undefined && params.newValue !== '') {
              // Remove any non-numeric characters (like 'm') and convert to number
              const numValue = Number(params.newValue.toString().replace(/[^\d.-]/g, ''));
              // Process according to field type
              if (!isNaN(numValue)) {
                newValue = Number(numValue.toFixed(2));
              }
            }
            
            // Create a new object with the updated value and preserved metadata
            return { 
              ...metadata,
              value: newValue,
              updatedAt: new Date().toISOString()
            };
          }
        } : {})
      } as ExtendedColDef;

      // Add specific configuration for commonname
      if (isCommonName) {
        return {
          ...baseColDef,
          cellClass: 'italic',
          cellEditor: AutocompleteSelectCellEditor,
          cellEditorParams: commonNameCellEditorParams.value,
          valueParser: (params: any) => {
            // We need to preserve any genus and family information when editing
            const oldValue = params.data[params.column.colId];
            
            // If we're receiving a full object (e.g., from autocomplete)
            if (typeof params.newValue === 'object') {
              return {
                label: params.newValue?.label || params.newValue?.value || '',
                value: params.newValue?.value || params.newValue?.label || '',
                source: params.newValue?.source || oldValue?.source || 'user-edit',
                updatedAt: new Date().toISOString(),
                // Preserve genus and family from the existing value
                genus: params.newValue?.genus || oldValue?.genus || '',
                family: params.newValue?.family || oldValue?.family || ''
              };
            }
            
            // If we're receiving just a string (direct editing)
            return {
              label: params.newValue || '',
              value: params.newValue || '',
              source: oldValue?.source || 'user-edit',
              updatedAt: new Date().toISOString(),
              // Preserve genus and family from the old value if any
              genus: oldValue?.genus || '',
              family: oldValue?.family || ''
            };
          },
          valueFormatter: (params: any) => {
            // Special handling for null values in commonname
            if (params.value === null || params.value === undefined || params.value === 'null') {
              return '';
            }
            
            // Extract value from object structure if needed
            if (typeof params.value === 'object' && params.value !== null) {
              const objValue = params.value.value || params.value.label || '';
              return objValue === 'null' ? '' : objValue;
            }
            
            return params.value === 'null' ? '' : params.value;
          }
        };
      }
      
      return baseColDef;
    })
  ] as ExtendedColDef[]
}

// Initialize empty row data if it doesn't exist
if (!rowData.value) {
  // Create an empty row with null values for all fields
  const emptyRow = Object.fromEntries(
    Object.keys(defaultColumnSchema.properties).map(key => [
      key, 
      {
        value: null,
        source: 'initialization',
        createdAt: new Date().toISOString()
      }
    ])
  );
  rowData.value = [emptyRow];
}

// Watch for panel data changes with a simple check first
watch(() => panel.data?.output, (newOutput, oldOutput) => {
  // Skip if no data or if processing or if the same reference (to avoid loops)
  if (!newOutput || isProcessingOutput.value || newOutput === oldOutput) {
    return;
  }

  console.log('PlantGrid watcher on panel.data?.output', newOutput)
  
  if (gridApi.value) {
    // safelyProcessOutput(newOutput);
  }
}, { deep: true });

// Add a lock to prevent multiple empty row operations running simultaneously
const isAddingEmptyRow = ref(false)
const lastEmptyRowAddTime = ref(0)

// Add watcher to ensure there's always an empty row at the end
// Use a smaller debounce and avoid deep watching to improve performance
watchDebounced(() => rowData.value?.length, (newRowCount, oldRowCount) => {
  // Skip if locked or no data
  if (isAddingEmptyRow.value || !rowData.value || !Array.isArray(rowData.value)) return
  
  console.log('[DEBUG] Row count changed:', { newRowCount, oldRowCount })
  
  // Empty grid case - add a single empty row
  if (newRowCount === 0) {
    isAddingEmptyRow.value = true
    try {
      const emptyRow = createEmptyRow()
      rowData.value = [emptyRow]
    } finally {
      isAddingEmptyRow.value = false
      lastEmptyRowAddTime.value = Date.now()
    }
    return
  }
  
  // Check if the last row has data (should add a new empty row)
  // Only do this if there are actually rows in the grid
  if (newRowCount > 0) {
    checkLastRowAndAddEmptyIfNeeded()
  }
}, { debounce: 300 })

// Helper function to create an empty row with consistent structure
const createEmptyRow = () => {
  return Object.fromEntries(
    Object.keys(defaultColumnSchema.properties).map(key => [
      key, 
      {
        value: null,
        source: 'initialization',
        createdAt: new Date().toISOString()
      }
    ])
  )
}

// Separate the last row check and add logic into its own function
// This helps avoid redundant code and improves readability
const checkLastRowAndAddEmptyIfNeeded = () => {
  // Prevent running this function too frequently
  const now = Date.now()
  if (now - lastEmptyRowAddTime.value < 500) return
  
  // Exit early if locked or no data
  if (isAddingEmptyRow.value || !rowData.value || !Array.isArray(rowData.value) || rowData.value.length === 0) return
  
  isAddingEmptyRow.value = true
  try {
    // Get the last row
    const lastRow = rowData.value[rowData.value.length - 1]
    
    // Check if the last row has any non-null values
    const hasValues = Object.entries(lastRow).some(([key, val]) => {
      // Skip checkboxSelection
      if (key === 'checkboxSelection') return false
      
      // If value is an object with the new structure
      if (typeof val === 'object' && val !== null) {
        // Check the inner value
        return val.value !== null && val.value !== undefined && val.value !== ''
      }
      
      // Otherwise check if the value itself is non-empty
      return val !== null && val !== undefined && val !== ''
    })
    
    if (hasValues) {
      console.log('[DEBUG] Last row has values, adding new empty row')
      
      // Create a new empty row
      const emptyRow = createEmptyRow()
      
      // Append the empty row without recreating the entire array
      rowData.value = [...rowData.value, emptyRow]
      
      // Update the last add time
      lastEmptyRowAddTime.value = now
    }
  } finally {
    isAddingEmptyRow.value = false
  }
}

// Add watcher for validation events separate from row management
// This way row management doesn't get slowed down by validation
watchDebounced(() => rowData.value, (newRowData) => {
  if (!newRowData || !Array.isArray(newRowData) || newRowData.length === 0) return
  
  // Prepare common data for all validation events
  const validationData = {
    panelId: panel.name,
    tool_uid: toolId.value,
    data: {
      // row and column data - normalize data for validation
      rowData: newRowData?.map(row => {
        const normalizedRow: Record<string, any> = {}
        
        // Process each field in the row
        Object.entries(row).forEach(([key, val]) => {
          // Extract value from object structure or use directly
          if (typeof val === 'object' && val !== null && 'value' in val) {
            normalizedRow[key] = val.value
          } else {
            normalizedRow[key] = val
          }
          
          // Special handling for botanicalname
          if (key === 'botanicalname') {
            if (typeof val === 'object' && val !== null) {
              normalizedRow[key] = val.label || val.value || ''
              // Add genus and family if they exist in the object
              if (val.genus) normalizedRow.genus = val.genus
              if (val.family) normalizedRow.family = val.family
            }
          }
        })
        
        return normalizedRow
      }) || [],
      columnDefs: columnDefs.value
    }
  }
  
  // only emit validation events if there's actual data
  if (newRowData.length > 1 || (newRowData.length === 1 && hasAnyValues(newRowData[0]))) {
    emitValidationToTools(validationData, enabledValidationTools.value)
  }
}, { deep: true, debounce: 1000 })

// Helper to check if any row has actual values (not just empty cells)
const hasAnyValues = (row: any) => {
  return Object.entries(row).some(([key, val]) => {
    if (key === 'checkboxSelection') return false
    
    if (typeof val === 'object' && val !== null) {
      return val.value !== null && val.value !== undefined && val.value !== ''
    }
    
    return val !== null && val !== undefined && val !== ''
  })
}

const { emitMitter: emitValidationEvent, clearHashCache: clearValidationCache } = useEmitter()

// Function to emit validation events to multiple tools
const emitValidationToTools = useDebounceFn((validationData: any, toolsToValidate: string[]) => {
  // Clear the hash cache first to ensure events are emitted regardless of data similarity
  clearValidationCache()
  
  toolsToValidate.forEach(toolUid => {
    mitter.emit(`panel:data:validate`, {
      ...validationData,
      validation_tool_uid: toolUid
    })
  })
}, 1000)

// Update the gridOptions to use the new AG Grid API
const gridOptions = {
  // Enable undo/redo for cell editing
  undoRedoCellEditing: true,
  undoRedoCellEditingLimit: 20,  // Set limit for undo/redo stack
  
  onGridReady: (params: GridReadyEvent) => {
    gridApi.value = params.api;
    
    // Initialize undo/redo state tracking
    setTimeout(() => updateUndoRedoState(), 100);
    
    // If no data exists, initialize with an empty row
    if (!rowData.value?.length) {
      const emptyRow = Object.fromEntries(
        Object.keys(defaultColumnSchema.properties).map(key => [
          key, 
          {
            value: null,
            source: 'initialization',
            createdAt: new Date().toISOString()
          }
        ])
      );
      rowData.value = [emptyRow];
    } else {
      // Ensure existing row data uses the object format
      rowData.value = rowData.value.map(row => {
        const updatedRow: Record<string, any> = {};
        
        // Convert simple values to object format
        Object.entries(row).forEach(([key, val]) => {
          // Skip if already in object format with a value property
          if (typeof val === 'object' && val !== null && 'value' in val) {
            updatedRow[key] = val;
          } 
          // Convert primitive values to object format
          else if (val !== null && val !== undefined) {
            updatedRow[key] = {
              value: val,
              source: 'legacy_data',
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            };
          }
          // Handle null/undefined values
          else {
            updatedRow[key] = {
              value: null,
              source: 'initialization',
              createdAt: new Date().toISOString()
            };
          }
        });
        
        return updatedRow;
      });
    }
    
    params.api.setGridOption('rowData', rowData.value);
    
    // First auto-size specific columns that need it
    const columnsToAutoSize = ['botanicalname', 'commonname'];
    params.api.autoSizeColumns(columnsToAutoSize);
    
    // Then size the rest to fit
    params.api.sizeColumnsToFit();

    // Start editing botanicalname field in the first row after a short delay
    // only if the botanicalname value is empty
    setTimeout(() => {
      const firstRow = params.api.getDisplayedRowAtIndex(0);
      if (firstRow) {
        const botanicalNameValue = firstRow.data.botanicalname;
        const hasValue = botanicalNameValue && 
                         (typeof botanicalNameValue === 'string' ? 
                           botanicalNameValue.trim() !== '' : 
                           (botanicalNameValue?.label ? botanicalNameValue.label.trim() !== '' : false));
        
        if (!hasValue) {
          params.api.startEditingCell({
            rowIndex: 0,
            colKey: 'botanicalname'
          });
        }
      }
    }, 100);

    console.log("Grid is ready, checking if layout system is initialized...")
    
    // Create a function to safely emit grid data
    const safelyEmitGridData = async () => {
      try {
        // Try to initialize layout store and panel connections if not already done
        if (!layoutStoreRef.value) {
          const initialized = initLayoutStore()
          console.log("Layout store initialization attempt result:", initialized)
        }
        
        // Add a more robust check - only initialize if we don't have connections AND panel.name is available
        if (!panelConnectionsRef.value && panel.name) {
          console.log(`[DEBUG] Panel connections not initialized for panel ${panel.name}, initializing now`)
          const initialized = initPanelConnections()
          console.log("Panel connections initialization attempt result:", initialized)
        } else {
          console.log(`[DEBUG] Panel connections already initialized for panel ${panel.name || 'unknown'}, skipping initialization`)
        }
      } catch (error) {
        console.error("Error checking layout system readiness:", error)
        // Ensure we still emit to parent even if there's an error
        await emitGridDataChanges()
      }
    }

    // Start the safe emission process
    safelyEmitGridData()
  },
  
  // Add event handlers for undo/redo operations
  onUndoStarted: () => {
    console.log('Undo operation started');
  },
  onUndoEnded: (event: any) => {
    console.log('Undo operation ended, operation performed:', event.operationPerformed);
    // Update the undo/redo availability after operation
    updateUndoRedoState();
    // If the operation was performed, emit grid data changes
    if (event.operationPerformed) {
      emitGridDataChanges();
    }
  },
  onRedoStarted: () => {
    console.log('Redo operation started');
  },
  onRedoEnded: (event: any) => {
    console.log('Redo operation ended, operation performed:', event.operationPerformed);
    // Update the undo/redo availability after operation
    updateUndoRedoState();
    // If the operation was performed, emit grid data changes
    if (event.operationPerformed) {
      emitGridDataChanges();
    }
  },
  
  // Add cell value changed handler to track undo/redo state
  onCellValueChanged: async (event: any) => {
    console.log('📊 Cell value changed:', event);
    
    // Log height field changes specifically
    const isHeightField = event.colDef?.field?.toLowerCase().includes('height');
    if (isHeightField) {
      logHeightValue('onCellValueChanged', event.colDef.field, event.newValue, 'cell value changed', {
        oldValue: event.oldValue,
        rowIndex: event.rowIndex,
        valueType: typeof event.newValue
      });
    }
    
    // Update undo/redo state whenever a cell value changes
    updateUndoRedoState();
    
    // Extract the actual value for comparison
    const newValue = event.newValue?.value !== undefined ? event.newValue.value : event.newValue;
    const isValueEmpty = newValue === '' || newValue === null || newValue === undefined;
    
    // For height fields, never skip processing even if empty (to preserve zero values)
    const shouldSkip = isValueEmpty && !event.fromAutocomplete && !isHeightField;
    
    if (shouldSkip) {
      console.log('[DEBUG] Skipping transformer for empty non-height value');
      return;
    }
    
    // Create a defensive copy of the row data to avoid modification during iteration
    const updatedData = [...(rowData.value || [])];
    
    // Find the index of the changed row
    const rowIndex = event.rowIndex;
    if (rowIndex !== undefined && rowIndex >= 0 && rowIndex < updatedData.length) {
      // Update the row data with a fresh copy to trigger reactivity
      updatedData[rowIndex] = { ...event.data };
      
      if (isHeightField) {
        logHeightValue('onCellValueChanged', event.colDef.field, event.data[event.colDef.field], 
          'after row data copy', { rowIndex });
      }
      
      // Update rowData with the new array
      rowData.value = updatedData;
      
      // Check if this was the last row and if we should add a new empty row
      // Use a slight delay to ensure the row data update is processed first
      if (rowIndex === updatedData.length - 1) {
        nextTick(() => {
          checkLastRowAndAddEmptyIfNeeded();
        });
      }
    }
    
    // Skip transformer application if we're already in the process of applying transformers
    if (isApplyingTransformers.value) {
      console.log('[DEBUG] Skipping transformer application - already applying transformers');
      return;
    }
    
    // Apply transformers for this column if it was directly edited (not from autocomplete)
    if (event.colDef?.field && !event.fromAutocomplete) {
      // Set the flag to prevent recursive transformer application
      isApplyingTransformers.value = true;
      
      try {
        const columnName = event.colDef.field;
        const value = newValue; // Use the extracted value
        
        console.log(`[DEBUG] Processing transformers for direct edit of ${columnName}:`, value);
        
        // Process the cell update to apply transformers and emit events
        const result = await processCellUpdate(columnName, value, event.data);
        console.log('[DEBUG] processCellUpdate result:', result);
        
        // If we have updates, apply them to the grid after a small delay
        if (result?.updates && Object.keys(result.updates).length > 0) {
          // Wait to ensure grid is stable
          await nextTick();
          
          console.log('[DEBUG] Applying transformer updates to row:', result.updates);
          
          // Check for a botanical name update from common name
          const hasBotanicalUpdate = result.updates.botanicalname !== undefined;
          
          // Check for height fields in updates
          let hasHeightUpdates = Object.keys(result.updates).some(key => 
            key.toLowerCase().includes('height')
          );
          
          // Special handling for common name to botanical name transformation
          if (columnName.toLowerCase() === 'commonname' && hasBotanicalUpdate) {
            console.log('[DEBUG] Common name resolved to botanical name:', result.updates.botanicalname);
            
            // If we have a botanical name update but no height, try to trigger the botanical name transformers
            if (!hasHeightUpdates && result.updates.botanicalname) {
              console.log('[DEBUG] No height update found, triggering botanical name transformers');
              
              // Extract the botanical name
              const botanicalName = typeof result.updates.botanicalname === 'object' && result.updates.botanicalname !== null
                ? result.updates.botanicalname.value || result.updates.botanicalname.label
                : result.updates.botanicalname;
                
              if (botanicalName) {
                // Format botanical name as an object to match expected input
                const botanicalNameObject = {
                  label: String(botanicalName),
                  value: String(botanicalName),
                  source: 'commonname-resolution',
                  updatedAt: new Date().toISOString()
                };
                
                // Process botanical name to trigger height transformers
                const secondaryResult = await processCellUpdate('botanicalname', botanicalName, event.data);
                console.log('[DEBUG] Secondary botanical name transform result:', secondaryResult);
                
                // Add any new updates to the original result
                if (secondaryResult && secondaryResult.updates) {
                  Object.assign(result.updates, secondaryResult.updates);
                  console.log('[DEBUG] Combined updates after botanical name processing:', result.updates);
                  
                  // Check if we now have height updates
                  hasHeightUpdates = Object.keys(result.updates).some(key => 
                    key.toLowerCase().includes('height')
                  );
                }
              }
            }
          }
          
          // Use the safer grid node approach
          if (event.node) {
            // Apply updates to both grid node and rowData to keep them in sync
            updateGridNode(event.node, result.updates);
            
            // Make sure the rowData reference is updated to match the grid node data
            if (rowIndex !== undefined && rowIndex >= 0 && rowIndex < updatedData.length) {
              // Create a deep copy of the node data to ensure reactivity
              const syncedRowData = JSON.parse(JSON.stringify(event.node.data));
              console.log(`[DEBUG] Synchronizing row ${rowIndex} in rowData with grid node data`);
              // Update rowData with synchronized data
              updatedData[rowIndex] = syncedRowData;
              rowData.value = [...updatedData];
            }
            
            // Track height values after update if height fields are involved
            if (isHeightField || hasHeightUpdates) {
              // Delay to ensure updates are applied
              setTimeout(() => trackHeightValues(gridApi.value), 50);
            }
          }
        }
      } catch (error) {
        console.error('[DEBUG] Error applying transformers:', error);
      } finally {
        // Clear the flag
        isApplyingTransformers.value = false;
      }
    }
    
    // Queue data emission after changes are processed
    nextTick(() => {
      emitGridDataChanges();
      
      // Track height values after all processing if this was a height field change
      if (isHeightField) {
        setTimeout(() => trackHeightValues(gridApi.value), 100);
      }
    });
  },
  defaultColDef: {
    // enableCellChangeFlash: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
    // Ensure values are correctly displayed from objects
    valueFormatter: (params: any) => {
      // Handle empty/null/undefined values by returning empty string
      if (params.value === null || params.value === undefined) {
        return '';
      }
      
      // Extract value from object structure if needed
      let displayValue;
      if (typeof params.value === 'object' && params.value !== null) {
        displayValue = params.value.value;
        // Return empty string for null/undefined values within objects 
        if (displayValue === null || displayValue === undefined || displayValue === 'null') {
          return '';
        }
      } else {
        displayValue = params.value;
      }
      
      // Handle literal "null" string values
      if (displayValue === "null") {
        return '';
      }
      
      // Check if this value should be displayed as empty
      if (shouldDisplayAsEmpty(displayValue, params.column?.colId)) {
        return '';
      }
      
      // Otherwise return the primitive value as a string
      return String(displayValue);
    },
    
  },
  // Update row selection with a simpler configuration that matches our column setup
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  columnTypes: {
    numberColumn: {
      filter: 'agNumberColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset']
      },
      cellClass: 'text-right',
      valueGetter: (params: any) => {
        // For filtering, extract the numeric value
        if (typeof params.data[params.column.colId] === 'object' && params.data[params.column.colId] !== null) {
          return params.data[params.column.colId].value;
        }
        return params.data[params.column.colId];
      }
    },
    textColumn: {
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset']
      },
      cellClass: 'text-left',
      valueGetter: (params: any) => {
        // For filtering, extract the string value
        if (typeof params.data[params.column.colId] === 'object' && params.data[params.column.colId] !== null) {
          return params.data[params.column.colId].value;
        }
        return params.data[params.column.colId];
      }
    },
    // Add alias for backward compatibility
    string: {
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset']
      },
      cellClass: 'text-left',
      valueGetter: (params: any) => {
        // For filtering, extract the string value
        if (typeof params.data[params.column.colId] === 'object' && params.data[params.column.colId] !== null) {
          return params.data[params.column.colId].value;
        }
        return params.data[params.column.colId];
      }
    },
    // Add a special column type for the code field
    codeColumn: {
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset']
      },
      cellClass: 'text-center font-mono bg-muted/30',
      valueFormatter: (params: any) => {
        if (params.value === null || params.value === undefined) {
          return '';
        }
        
        // Extract the value from object structure if needed
        if (typeof params.value === 'object' && params.value !== null) {
          return params.value.value !== undefined ? params.value.value : '';
        }
        
        // Handle case where value is exactly the string "null"
        if (params.value === "null") {
          return '';
        }
        
        return params.value;
      },
      // Add valueParser to handle object values when editing
      valueParser: (params: any) => {
        // If editing an existing object value, preserve metadata
        const oldValue = params.data[params.column.colId];
        const metadata = (typeof oldValue === 'object' && oldValue !== null) 
          ? { ...oldValue, source: oldValue.source || 'user-edit' } 
          : { source: 'user-edit' };
        
        // Create a new object with the updated value and preserved metadata
        return { 
          ...metadata,
          value: params.newValue,
          updatedAt: new Date().toISOString()
        };
      },
      // Add valueGetter to ensure consistent value extraction for filtering and editing
      valueGetter: (params: any) => {
        // For filtering and editing, extract the value
        if (typeof params.data[params.column.colId] === 'object' && params.data[params.column.colId] !== null) {
          return params.data[params.column.colId].value;
        }
        return params.data[params.column.colId];
      }
    },
    heightColumn: {
      filter: 'agNumberColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset']
      },
      cellClass: 'text-right',
      // Formatter that preserves zero values
      valueFormatter: (params: any) => {
        const isHeight = params.column.colId.toLowerCase().includes('height');
        
        if (params.value === null || params.value === undefined) {
          return '';
        }
        
        return formatMeasurementValue(params.value, params.column.colId);
      },
      // Parser that preserves zero values
      valueParser: (params: any) => {
        const isHeight = params.column.colId.toLowerCase().includes('height');
        if (isHeight) {
          logHeightValue('heightColumn.valueParser', params.column.colId, params.newValue, 'parsing new value', {
            oldValue: params.oldValue,
            rowIndex: params.node?.rowIndex
          });
        }
        
        // Extract metadata from old value
        const oldValue = params.data[params.column.colId];
        const metadata = (typeof oldValue === 'object' && oldValue !== null) 
          ? { ...oldValue, source: oldValue.source || 'user-edit' } 
          : { source: 'user-edit' };
        
        if (isHeight) {
          logHeightValue('heightColumn.valueParser', params.column.colId, oldValue, 'old value metadata', metadata);
        }
        
        // Process numeric value
        let newValue = null;
        if (params.newValue !== null && params.newValue !== undefined && params.newValue !== '') {
          // Remove any non-numeric characters (like 'm') and convert to number
          const numValue = Number(params.newValue.toString().replace(/[^\d.-]/g, ''));
          // Always use the numeric value, even if zero
          if (!isNaN(numValue)) {
            newValue = Number(numValue.toFixed(2));
            if (isHeight) {
              logHeightValue('heightColumn.valueParser', params.column.colId, params.newValue, `parsed to number: ${newValue}`);
            }
          }
        }
        
        const result = { 
          ...metadata,
          value: newValue,
          updatedAt: new Date().toISOString()
        };
        
        if (isHeight) {
          logHeightValue('heightColumn.valueParser', params.column.colId, newValue, 'returning result object', result);
        }
        
        return result;
      },
      // Value getter to extract numeric value for filtering
      valueGetter: (params: any) => {
        const isHeight = params.column.colId.toLowerCase().includes('height');
        
        if (typeof params.data[params.column.colId] === 'object' && params.data[params.column.colId] !== null) {
          const value = params.data[params.column.colId].value;
          if (isHeight) {
            logHeightValue('heightColumn.valueGetter', params.column.colId, params.data[params.column.colId], `extracted value: ${value}`);
          }
          return value;
        }
        
        if (isHeight) {
          logHeightValue('heightColumn.valueGetter', params.column.colId, params.data[params.column.colId], 'direct value');
        }
        return params.data[params.column.colId];
      }
    },
    measurementColumn: {
      filter: 'agNumberColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset']
      },
      cellClass: 'text-right',
      valueFormatter: (params: any) => {
        if (params.value === null || params.value === undefined) {
          return '';
        }
        
        return formatMeasurementValue(params.value, params.column.colId);
      },
      valueParser: (params: any) => {
        // Extract metadata from old value
        const oldValue = params.data[params.column.colId];
        const metadata = (typeof oldValue === 'object' && oldValue !== null) 
          ? { ...oldValue, source: oldValue.source || 'user-edit' } 
          : { source: 'user-edit' };
        
        // Process numeric value
        let newValue = null;
        if (params.newValue !== null && params.newValue !== undefined && params.newValue !== '') {
          // Remove any non-numeric characters (like 'm') and convert to number
          const numValue = Number(params.newValue.toString().replace(/[^\d.-]/g, ''));
          // Process according to field type
          if (!isNaN(numValue)) {
            newValue = Number(numValue.toFixed(2));
          }
        }
        
        // Create a new object with the updated value and preserved metadata
        return { 
          ...metadata,
          value: newValue,
          updatedAt: new Date().toISOString()
        };
      },
      valueGetter: (params: any) => {
        // For filtering, extract the numeric value
        if (typeof params.data[params.column.colId] === 'object' && params.data[params.column.colId] !== null) {
          return params.data[params.column.colId].value;
        }
        return params.data[params.column.colId];
      }
    }
  },
  onDragStopped: (event: any) => {
    console.log('Column drag stopped, recalculating sizes');
    // After column drag, recalculate column sizes
    // adjustColumnWidths();
  },
  // onColumnResized: (event: any) => {
  //   if (event.finished) {
  //     const column = event.column;
  //     if (column) {
  //       const colId = column.getColId();
  //       const isFixedColumn = ['botanicalname', 'commonname'].includes(colId);
        
  //       if (isFixedColumn) {
  //         // Ensure minimum width is maintained
  //         const currentWidth = column.getActualWidth();
  //         const minWidth = column.getMinWidth();
  //         if (currentWidth < minWidth) {
  //           column.setActualWidth(minWidth);
  //         }
  //       }
  //     }
  //   }
  // },
  suppressColumnVirtualisation: true,
  suppressHorizontalScroll: false,
  onSelectionChanged,
  
  // Add undo/redo event handlers
  onUndoStarted: () => {
    console.log('Undo operation started')
  },
  onUndoEnded: (event) => {
    console.log('Undo operation ended, operation performed:', event.operationPerformed)
    // Update the undo/redo availability after operation
    updateUndoRedoState()
  },
  onRedoStarted: () => {
    console.log('Redo operation started')
  },
  onRedoEnded: (event) => {
    console.log('Redo operation ended, operation performed:', event.operationPerformed)
    // Update the undo/redo availability after operation
    updateUndoRedoState()
  },
} as const;

// Function to update the state of undo/redo availability
const updateUndoRedoState = () => {
  if (!gridApi.value) return
  
  // Get current undo/redo stack sizes
  const undoSize = gridApi.value.getCurrentUndoSize()
  const redoSize = gridApi.value.getCurrentRedoSize()
  
  // Update the refs
  undoAvailable.value = undoSize > 0
  redoAvailable.value = redoSize > 0
  
  console.log(`Undo/redo state updated: undoSize=${undoSize}, redoSize=${redoSize}`)
}

// Add an onGridReady handler to initialize the undo/redo state
const onGridReady = (params: GridReadyEvent) => {
  console.log('[DEBUG] Grid is ready, initializing...');
  
  // Store grid API reference
  gridApi.value = params.api;
  
  // Initialize undo/redo state
  updateUndoRedoState();
  
  // Set initialization flag to prevent duplicate processing
  if (isGridInitialized.value) {
    console.log('[DEBUG] Grid already initialized, skipping initialization');
    return;
  }
  
  isGridInitialized.value = true;
  
  // Ensure we have row data
  if (!rowData.value?.length) {
    // Create initial empty row with proper structure
    rowData.value = [createEmptyRow()];
  } else {
    // Normalize existing row data to ensure consistent object structure
    rowData.value = rowData.value.map(row => {
      const normalizedRow = { ...row };
      
      // Convert each field to object format if needed
      Object.keys(defaultColumnSchema.properties).forEach(key => {
        const val = normalizedRow[key];
        
        // Skip if already in object format with value property
        if (typeof val === 'object' && val !== null && 'value' in val) {
          // No change needed
        } 
        // Convert primitive values to object format
        else if (val !== null && val !== undefined) {
          normalizedRow[key] = {
            value: val,
            source: 'normalization',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          };
        }
        // Set null values
        else {
          normalizedRow[key] = {
            value: null,
            source: 'initialization',
            createdAt: new Date().toISOString()
          };
        }
        
        // Special handling for botanicalname to ensure it has label
        if (key === 'botanicalname') {
          if (typeof normalizedRow[key] === 'object' && normalizedRow[key] !== null) {
            normalizedRow[key] = {
              ...normalizedRow[key],
              label: normalizedRow[key].value || '',
              // Initialize genus and family if not present
              genus: normalizedRow[key].genus || '',
              family: normalizedRow[key].family || ''
            };
          }
        }
      });
      
      return normalizedRow;
    });
    
    // Check if we need to add an empty row at the end
    const lastRow = rowData.value[rowData.value.length - 1];
    const hasValues = hasAnyValues(lastRow);
    
    if (hasValues) {
      // Add an empty row at the end
      rowData.value.push(createEmptyRow());
    }
  }
  
  // Explicitly set the row data to ensure grid has latest data
  params.api.setGridOption('rowData', rowData.value);
  
  // Track height values in initial data
  console.log('[HEIGHT DEBUG] Checking initial grid data after setting rowData');
  trackHeightValues(params.api);
  
  // Wait for data to be rendered
  nextTick(() => {
    // First auto-size specific columns that need it
    const columnsToAutoSize = ['botanicalname', 'commonname'];
    params.api.autoSizeColumns(columnsToAutoSize);
    
    // Then size the rest to fit
    params.api.sizeColumnsToFit();
    
    // Start editing botanicalname field in the first row after a short delay
    // only if the botanicalname value is empty
    setTimeout(() => {
      const firstRow = params.api.getDisplayedRowAtIndex(0);
      if (firstRow) {
        const botanicalNameValue = firstRow.data.botanicalname;
        const hasValue = botanicalNameValue && 
                       (typeof botanicalNameValue === 'string' ? 
                         botanicalNameValue.trim() !== '' : 
                         (botanicalNameValue?.label ? botanicalNameValue.label.trim() !== '' : false));
        
        if (!hasValue) {
          params.api.startEditingCell({
            rowIndex: 0,
            colKey: 'botanicalname'
          });
        }
      }
    }, 200);

    console.log("Grid is ready, checking if layout system is initialized...");
    
    // Initialize layout connections and emit initial data
    initializeLayoutSystem();
  });
};

// Add row management functions
const addRow = async () => {
  // Create the new row with default values as objects with metadata
  const newRow = Object.fromEntries(
    Object.keys(defaultColumnSchema.properties).map(key => [
      key, 
      {
        value: null,
        source: 'initialization',
        createdAt: new Date().toISOString()
      }
    ])
  );
  
  // Create a new array with the existing rows plus the new row
  const updatedRowData = [...(rowData.value || []), newRow]
  
  // Update rowData directly - this is the Vue way
  rowData.value = updatedRowData
  
  // Update panel data directly with object spreading to preserve all other properties
  // including connections
  panel.data = {
    ...panel.data,
    rowData: updatedRowData,
    // Ensure we preserve any existing connections
    connections: panel.data?.connections || []
  }
  
  // Update the grid if available
  if (gridApi.value) {
    gridApi.value.setGridOption('rowData', updatedRowData)
    // gridApi.value.refreshCells()
  }
  
  // Ensure panel connections are initialized
  if (!panelConnectionsRef.value && panel.name) {
    console.log('[DEBUG] Initializing panel connections for row addition for panel', panel.name)
    initPanelConnections()
  } else if (panelConnectionsRef.value) {
    console.log('[DEBUG] Panel connections already initialized for row addition for panel', panel.name)
  } else {
    console.log('[DEBUG] Cannot initialize panel connections for row addition: no panel.name available')
  }
  
  clearValidationCache()
  
  
  // Trigger data emission to connected panels
  await emitGridDataChanges()
  
  // Also directly emit validation events to ensure they are sent
  const validationData = {
    panelId: panel.name,
    tool_uid: toolId.value,
    data: {
      rowData: updatedRowData.map(row => {
        const normalizedRow: Record<string, any> = {};
        Object.entries(row).forEach(([key, val]) => {
          if (typeof val === 'object' && val !== null && 'value' in val) {
            normalizedRow[key] = val.value;
          } else {
            normalizedRow[key] = val;
          }
          
          // Special handling for botanicalname
          if (key === 'botanicalname') {
            if (typeof val === 'object' && val !== null) {
              normalizedRow[key] = val.label || val.value || '';
              // Add genus and family if they exist in the object
              if (val.genus) normalizedRow.genus = val.genus;
              if (val.family) normalizedRow.family = val.family;
            }
          }
        });
        return normalizedRow;
      }),
      columnDefs: columnDefs.value
    }
  };
  
   
  emitValidationToTools(validationData, enabledValidationTools.value);
}

const deleteSelectedRows = async () => {
  const selectedNodes = gridApi.value?.getSelectedNodes()
  if (!selectedNodes?.length) return
  
  const selectedIndices = selectedNodes.map(node => node.rowIndex)
  
  if (rowData.value) {
    // Create a new filtered array
    const updatedRowData = rowData.value.filter((_, index) => 
      !selectedIndices.includes(index)
    )
    
    // Update rowData directly - this is the Vue way
    rowData.value = updatedRowData
    
    // Update panel data directly with object spreading to preserve all other properties
    // including connections
    panel.data = {
      ...panel.data,
      rowData: updatedRowData,
      // Ensure we preserve any existing connections
      connections: panel.data?.connections || []
    }
    
    // Update the grid if available
    if (gridApi.value) {
      gridApi.value.setGridOption('rowData', updatedRowData)
      // gridApi.value.refreshCells()
    }
    
    // Clear validation hash cache to ensure events are sent
    
    clearValidationCache()
    
    
    // Trigger data emission to connected panels
    await emitGridDataChanges()
  }
}

// Add refresh handler
const handleRefresh = () => {
  if (gridApi.value) {
    // First ensure the grid has the latest data
    gridApi.value.setGridOption('rowData', rowData.value);
    
    // Then refresh cells to update any formatting
    // gridApi.value.refreshCells({ force: true });
    
    // Apply column sizing
    adjustColumnWidths();
    
    console.log('Grid refreshed with', rowData.value?.length || 0, 'rows');
  }
}

// Add column width adjustment function
const adjustColumnWidths = () => {
  if (!gridApi.value) return;
  
  // First auto-size specific columns that need fixed widths
  const columnsToAutoSize = ['botanicalname', 'commonname'];
  
  if (gridApi.value) {
    // First auto-size specific columns that need it
    gridApi.value.autoSizeColumns(columnsToAutoSize);
    
    // Then handle the rest based on user preference
    if (useFitContents.value) {
      gridApi.value.autoSizeAllColumns();
    } else {
      gridApi.value.sizeColumnsToFit();
    }
  }
}

// Update handler for toggling sizing
const handleToggleSizing = () => {
  useFitContents.value = !useFitContents.value;
  if (gridApi.value) {
    adjustColumnWidths();
  }
}

// Update zoom handlers to include all theme parameters
const handleZoomIn = () => {
  if (zoomLevel.value < MAX_ZOOM) {
    zoomLevel.value = Math.min(zoomLevel.value + 0.1, MAX_ZOOM)
    const newParams = {
      ...themeParams.value,
      fontSize: Math.round(BASE_FONT_SIZE * zoomLevel.value),
      headerFontSize: Math.round((BASE_FONT_SIZE + 2) * zoomLevel.value),
      rowHeight: Math.round(BASE_ROW_HEIGHT * zoomLevel.value),
      headerHeight: Math.round(BASE_HEADER_HEIGHT * zoomLevel.value),
    }
    // Update themeParams
    themeParams.value = newParams
    
    if (gridApi.value) {
      gridApi.value.resetRowHeights()
      nextTick(() => {
        adjustColumnWidths();
      })
    }
  }
}

const handleZoomOut = () => {
  if (zoomLevel.value > MIN_ZOOM) {
    zoomLevel.value = Math.max(zoomLevel.value - 0.1, MIN_ZOOM)
    const newParams = {
      ...themeParams.value,
      fontSize: Math.round(BASE_FONT_SIZE * zoomLevel.value),
      headerFontSize: Math.round((BASE_FONT_SIZE + 2) * zoomLevel.value),
      rowHeight: Math.round(BASE_ROW_HEIGHT * zoomLevel.value),
      headerHeight: Math.round(BASE_HEADER_HEIGHT * zoomLevel.value),
    }
    // Update themeParams
    themeParams.value = newParams
    
    if (gridApi.value) {
      gridApi.value.resetRowHeights()
      nextTick(() => {
        adjustColumnWidths();
      })
    }
  }
}


// Add TypeScript declaration for the global flag and panel connections initializing
declare global {
  interface Window {
    VUE_CODE_LAYOUT_READY?: boolean;
    _panelConnectionsInitializing?: Record<string, boolean>;
  }
}

// Add lock for data emission
const isEmittingData = ref(false)

// Update the emitGridDataChanges function to include validation
const emitGridDataChanges = useDebounceFn(async () => {
  // Skip if already emitting data
  if (isEmittingData.value) {
    console.log('[DEBUG] Skipping data emission - already in progress');
    return;
  }
  
  // Set lock
  isEmittingData.value = true;
  
  try {
    if (!gridApi.value) {
      console.log('Grid API not available yet');
      return;
    }

    // Ensure all cell editing is complete
    gridApi.value.stopEditing();
    
    // Create a snapshot of the current grid data
    const gridData: any[] = [];
    
    // Wait to ensure grid is stable
    await nextTick();
    
    gridApi.value.forEachNode((node) => {
      if (node.data) {
        // Create a defensive copy to prevent mutation during processing
        const rowData = { ...node.data };
        
        // Normalize quantity field (could be in quantity or qty field)
        if (rowData.quantity === undefined && rowData.qty !== undefined) {
          rowData.quantity = rowData.qty;
        } else if (rowData.quantity === undefined) {
          // Default quantity if not found
          rowData.quantity = 1;
        }
        
        // Log the quantity for debugging
        if (rowData.botanicalname) {
          console.log(`[PlantGrid] Emitting row with botanicalname: ${
            typeof rowData.botanicalname === 'object' 
              ? rowData.botanicalname.label || rowData.botanicalname.value 
              : rowData.botanicalname
          }, quantity: ${rowData.quantity}`);
        }
        
        gridData.push(rowData);
      }
    });

    // Create the data package that will be sent
    const dataPackage = {
      type: 'grid',
      columnDefs: columnDefs.value,
      rowData: gridData
    };

    // Wait to ensure data is ready before updating panel
    await nextTick();
    
    // Update panel.data directly to ensure parent component can detect changes
    if (panel.data) {
      // Ensure we're creating new array references to trigger Vue's reactivity
      panel.data.rowData = [...gridData];
      panel.data.columnDefs = [...columnDefs.value];
    }

    // Emit the data-update event for PanelContainer
    emit('data-update', dataPackage);  
    
    // Only attempt to use the layout store if it's available
    if (import.meta.client && window.VUE_CODE_LAYOUT_READY) {
      try {
        // Try to initialize layout store if not already done
        if (!layoutStoreRef.value) {
          initLayoutStore();
        }
        
        // Try to initialize panel connections if not already done
        if (!panelConnectionsRef.value && panel.name) {
          initPanelConnections();
        }
        
        // Use the layout store and panel connections if available
        if (layoutStoreRef.value && panel.name && panelConnectionsRef.value) {
          console.log(`[DEBUG] Emitting grid data from panel ${panel.name}:`, {
            columnDefs: columnDefs.value?.length || 0,
            rowData: gridData.length
          });
          
          // Transform the data for each connected panel based on their tool type
          const connectedPanels = Array.from(panelConnectionsRef.value.connections || []);
          
          for (const targetPanelId of connectedPanels) {
            // Get the target panel's tool ID
            const targetPanel = layoutStoreRef.value.layoutInstance?.value?.getPanelById(targetPanelId);
            const targetToolId = targetPanel?.data?.tool?.id || targetPanel?.data?.tool_uid;
            
            console.log('[DEBUG] sourceToolId:', toolId.value);
            if (targetToolId) {
              // Transform the data for the specific target panel
              const transformedData = await transformData(dataPackage, toolId.value, targetToolId);
              
              // Use the emitData function from the panel connections
              panelConnectionsRef.value.emitData('grid', transformedData);
            }
          }
          
          // Also emit a direct event for diagnostics panel
          const mitter = useMitter();
          console.log(`[DEBUG] Emitting panel:toolData event with panelId=${panel.name}, toolId=${toolId.value}`);
          mitter.emit('panel:toolData', {
            panelId: panel.name,
            toolId: toolId.value,
            data: dataPackage
          });
          
          console.log('[DEBUG] panel:toolData event emitted successfully');
        } else {
          console.warn('[DEBUG] Cannot emit grid data via panel connections:', {
            layoutStoreAvailable: !!layoutStoreRef.value,
            panelIdAvailable: !!panel.name,
            panelConnectionsAvailable: !!panelConnectionsRef.value
          });
        }
      } catch (error) {
        console.warn('Could not access layout store for panel connections:', error);
        console.log('Continuing with direct parent emission only');
      }
    }
  } catch (error) {
    console.error('[ERROR] Error emitting grid data:', error);
  } finally {
    // Release lock
    isEmittingData.value = false;
  }
}, 500); // Increased debounce delay to reduce emission frequency

// Update the onMounted function with more debugging
onMounted(() => {
  console.log('📊 PlantGrid mounted with data:', {
    panelName: panel.name,
    panelId: panel.id,
    toolUid: panelToolUid.value,
    hasOutput: !!panelOutput.value,
    outputType: panelOutput.value ? typeof panelOutput.value : 'none',
    outputKeys: panelOutput.value ? Object.keys(panelOutput.value) : [],
    outputStringLength: panelOutput.value ? JSON.stringify(panelOutput.value).length : 0
  });
  
  // On mount, explicitly log the output to see what we're dealing with
  if (panelOutput.value) {
    console.log('PlantGrid initial output data sample:', 
      typeof panelOutput.value === 'object' 
        ? JSON.stringify(panelOutput.value).substring(0, 1000) + '...' 
        : panelOutput.value
    );
    
    // Always attempt to parse initial output data on mount
    console.log('Attempting to parse initial output data on mount');
    handleInitialDataParsing(panelOutput.value);
  }
  
  // Explicitly check for and handle pages object which is common in llamaparse results
  if (panelOutput.value?.pages && !rowData.value?.length) {
    console.log('Found pages object in output, trying special parser for it');
    const mockLlamaParseFormat = { message: [{ pages: panelOutput.value.pages }] };
  }
  

  // Check if the layout system is ready
  if (import.meta.client) {
    console.log('VUE_CODE_LAYOUT_READY flag:', window.VUE_CODE_LAYOUT_READY)
    
    // Try to initialize immediately if the flag is already set
    if (window.VUE_CODE_LAYOUT_READY) {
      initializeLayoutSystem()
    }
    
    // Also listen for the custom event in case the plugin initializes after this component
    window.addEventListener('vue-code-layout-ready', () => {
      console.log('Received vue-code-layout-ready event')
      initializeLayoutSystem()
    })
  }

  // Setup listeners for transformer events
  ['plantgrid:row:set:commonname', 'plantgrid:row:set:botanicalname', 'plantgrid:row:set:code'].forEach(eventName => {
    mitter.on(eventName as any, (eventData: any) => {
      console.log(`[DEBUG] Received transformer event: ${eventName}`, eventData);
      
      // Only process if grid API is available
      if (!gridApi.value) return;
      
      // Get the currently focused cell
      const focusedCell = gridApi.value.getFocusedCell();
      if (!focusedCell) return;
      
      // Get the row node
      const rowNode = gridApi.value.getDisplayedRowAtIndex(focusedCell.rowIndex);
      if (!rowNode) return;
      
      // Extract the column name from the event
      const targetColumn = eventName.split(':').pop() || '';
      if (!targetColumn) return;
      
      // Get the new value from the event data
      const newValue = eventData[targetColumn];
      if (newValue === undefined) return;
      
      console.log(`[DEBUG] Updating grid cell ${targetColumn} with value:`, newValue);
      
      // Special handling for the code field to ensure we have the correct object structure
      if (targetColumn === 'code' && typeof newValue === 'string') {
        // Wrap the string in proper object structure
        rowNode.data[targetColumn] = {
          value: newValue,
          source: 'transformer',
          sourceField: eventData.sourceColumn || 'botanicalname',
          updatedAt: new Date().toISOString()
        };
      } else {
        // Update the row data
        rowNode.data[targetColumn] = newValue;
      }
      
      // Use setTimeout to avoid grid rendering conflicts
      setTimeout(() => {
        // Only refresh if the grid is still available
        if (gridApi.value) {
          gridApi.value.refreshCells({
            rowNodes: [rowNode],
            columns: [targetColumn],
            force: true
          });
        }
      }, 0);
    });
  });

  // Also update the onFreeTextSelect handler
  onFreeTextSelect: (cellEditor: any, userInput: string) => {
    console.log('[DEBUG] onFreeTextSelect called with input:', userInput);
    // Return a properly formatted object to match autocomplete selections
    return {
      value: userInput,
      label: userInput,
      source: 'free-text-input'
    };
  }
});

// Update the handleInitialDataParsing function
const handleInitialDataParsing = (output: any) => {
  console.log('handleInitialDataParsing', output)
  if (isProcessingOutput.value) return;

  console.log('Initial data parsing - output:', {
    type: typeof output,
    isArray: Array.isArray(output),
    keys: typeof output === 'object' && output !== null ? Object.keys(output) : []
  });

  // Check for direct JSON format that we might have missed
  if (output?.json && Array.isArray(output.json)) {
    console.log('Found JSON array format, processing directly:', output.json.length);

    try {
      if (output.json.length > 0) {
        const firstRow = output.json[0];
        // Only create new column definitions if none exist
        if (!columnDefs.value?.length) {
          const inferredColumns = [{
            headerName: "",
            field: "checkboxSelection",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            pinned: "left",
            lockPosition: true,
            suppressHeaderMenuButton: true,
            suppressMovable: true,
            sortable: false,
            filter: false,
            resizable: false,
          }];

          Object.keys(firstRow).forEach(key => {
            const isFixedColumn = ['botanicalname', 'commonname'].includes(key.toLowerCase());
            const isBotanicalName = key.toLowerCase() === 'botanicalname';
            const isCommonName = key.toLowerCase() === 'commonname';
            const isCodeColumn = key.toLowerCase() === 'code';
            
            inferredColumns.push({
              field: key,
              headerName: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
              type: isCodeColumn ? 'codeColumn' : (typeof firstRow[key] === 'number' ? 'numberColumn' : 'textColumn'),
              editable: true,
              sortable: true,
              filter: true,
              resizable: true,
              suppressSizeToFit: isFixedColumn,
              autoSize: isFixedColumn,
              ...(isFixedColumn ? { minWidth: 180 } : { minWidth: 50 }),
              ...(isBotanicalName ? {
                cellEditor: CustomEscapeAwareEditor || AutocompleteSelectCellEditor,
                cellEditorParams: cellEditorParams.value,
                valueParser: (params: any) => {
                  if (typeof params.newValue === 'object') {
                    return params.newValue?.label || params.newValue?.value || '';
                  }
                  return {
                    label: params.newValue || '',
                    value: params.newValue || ''
                  };
                },
                valueFormatter: (params: any) => {
                  if (typeof params.value === 'object') {
                    return params.value?.label || params.value?.value || '';
                  }
                  return params.value || '';
                }
              } : {}),
              ...(isCommonName ? {
                cellEditor: CustomEscapeAwareEditor || AutocompleteSelectCellEditor,
                cellEditorParams: commonNameCellEditorParams.value,
                valueParser: (params: any) => {
                  if (typeof params.newValue === 'object') {
                    return params.newValue?.label || '';
                  }
                  return params.newValue || '';
                },
                valueFormatter: (params: any) => {
                  if (typeof params.value === 'object') {
                    return params.value?.label || '';
                  }
                  return params.value || '';
                }
              } : {})
            });
          });

          // Use the utility function to ensure botanicalname column is properly configured
          columnDefs.value = ensureAutocompleteColumns(inferredColumns);
        } else {
          // If column definitions already exist, ensure botanicalname has the autocomplete editor
          columnDefs.value = ensureAutocompleteColumns(columnDefs.value);
        }
        
        console.log('setting rowData.value to', output.json)
        rowData.value = [...output.json];

        // Update panel data explicitly
        if (panel.data) {
          panel.data.rowData = [...output.json];
          panel.data.columnDefs = columnDefs.value;
        }

        console.log('Successfully processed JSON data:', { 
          rows: output.json.length, 
          columns: columnDefs.value.length 
        });

        // Update grid if available
        nextTick(() => {
          if (gridApi.value) {
            // gridApi.value.setRowData(output.json);
            // gridApi.value.refreshCells({ force: true });
            gridApi.value.sizeColumnsToFit();
          }
        });

        return; // Exit the function since we've handled the data
      }
    } catch (error) {
      console.error('Error processing JSON data format:', error);
    }
  }
};

// Function to initialize the layout system
const initializeLayoutSystem = () => {
  // Try to initialize the layout store
  const layoutStoreInitialized = initLayoutStore()
  console.log('Layout store initialized:', layoutStoreInitialized)
  
  if (layoutStoreRef.value) {
    console.log('Layout store instance available:', !!layoutStoreRef.value)
    console.log('Layout instance available:', !!layoutStoreRef.value.layoutInstance)
  } else {
    console.log('Layout store reference is null')
  }
  
  // Try to initialize panel connections if needed
  if (!panelConnectionsRef.value && panel.name) {
    console.log('[DEBUG] Initializing panel connections during layout system initialization for panel', panel.name)
    const connectionsInitialized = initPanelConnections()
    console.log('[DEBUG] Panel connections initialized:', connectionsInitialized)
  } else if (panelConnectionsRef.value) {
    console.log('[DEBUG] Panel connections already initialized during layout system initialization for panel', panel.name)
  } else {
    console.log('[DEBUG] Cannot initialize panel connections during layout system initialization: no panel.name available')
  }
  
  // Emit grid data if we have it
  if (gridApi.value) {
    emitGridDataChanges().catch(error => {
      console.error('Error emitting grid data after layout system initialization:', error)
    })
  }
}

// Define the structure of the layout instance
interface CodeLayoutSplitNInstance {
  getRootGrid: () => any;
  getPanelById: (id: string) => any;
}

// Extend the LayoutStore type to match the actual structure
declare module '@edanweis/vue-code-layout' {
  interface LayoutStore {
    layoutInstance: Ref<CodeLayoutSplitNInstance>
  }
}


// Add onBeforeUnmount handler
onBeforeUnmount(() => {
  // Clean up panel connections
  if (panelConnectionsRef.value && typeof panelConnectionsRef.value.cleanup === 'function') {
    console.log(`Cleaning up panel connections for panel ${panel.name}`)
    panelConnectionsRef.value.cleanup()
  }
  
  // Remove event listener for layout ready
  if (import.meta.client) {
    window.removeEventListener('vue-code-layout-ready', initializeLayoutSystem)
  }

})

// Add method to explicitly handle data received from panel connections
const onDataReceived = (data: any) => {
  console.log('PlantGrid received data:', data);
  
  if (data?.data?.type === 'grid' && data?.data?.rowData) {
    // Direct grid data
    // change botanicalname in rowdata to an object with label and value
    data.data.rowData.forEach((row: any) => {
      if (row.botanicalname && typeof row.botanicalname === 'string') {
        row.botanicalname = {
          label: row.botanicalname,
          value: row.botanicalname
        }
      }

      if (row.commonname && typeof row.commonname === 'string') {
        row.commonname = {
          label: row.commonname,
          value: row.commonname
        };
      }

    })
    
    
    // Use the utility function to ensure botanicalname column is properly configured
    if (data.data.columnDefs) {
      columnDefs.value = ensureAutocompleteColumns(data.data.columnDefs);
    } else {
      columnDefs.value = ensureAutocompleteColumns(columnDefs.value);
    }
    
    console.log('finished updating columnDefs.value', columnDefs.value)
    rowData.value = [...data.data.rowData]; // Create a new array to ensure reactivity
    
    // Also update the panel data to ensure it stays in sync
    if (panel.data) {
      panel.data.rowData = [...data.data.rowData];
      panel.data.columnDefs = columnDefs.value;
    }
    
    nextTick(() => {
      if (gridApi.value) {
        // gridApi.value.setRowData(rowData.value); // Explicitly update the grid API
        // gridApi.value.refreshCells({ force: true });
        gridApi.value.sizeColumnsToFit();
      }
    });
    return;
  }
  
  // Try to parse as analysis result
  const parser = data?.data?.model?.toLowerCase().includes('gemini') ? 'gemini' : 'llamaparse';
  const parsedData = parse(data.data, parser);
  if (parsedData) {
    columnDefs.value = [...parsedData.columnDefs];
    rowData.value = [...parsedData.rowData];
    
    // Also update the panel data
    if (panel.data) {
      panel.data.rowData = [...parsedData.rowData];
      panel.data.columnDefs = [...parsedData.columnDefs];
    }
    
    nextTick(() => {
      if (gridApi.value) {
        // gridApi.value.setRowData(rowData.value);
        // gridApi.value.refreshCells({ force: true });
        gridApi.value.sizeColumnsToFit();
      }
    });
  }
}

// Add a handler for the undo action
const handleUndo = () => {
  if (gridApi.value) {
    gridApi.value.undoCellEditing();
    // Update undo/redo state after operation
    setTimeout(() => updateUndoRedoState(), 0);
  }
}

// Add a handler for the redo action
const handleRedo = () => {
  if (gridApi.value) {
    gridApi.value.redoCellEditing();
    // Update undo/redo state after operation
    setTimeout(() => updateUndoRedoState(), 0);
  }
}

// Update the exposed functions to include the redo handler
defineExpose({
  refresh: handleRefresh,
  onDataReceived,
  handleUndo,
  handleRedo
});

// Add resize observer to handle parent container size changes
useResizeObserver(parentElement, (entries) => {
  const entry = entries[0]
  if (entry && gridApi.value) {
    // Debounce the resize handler to avoid too many calls
    nextTick(() => {
      gridApi.value?.sizeColumnsToFit()
    })
  }
})

// Create the base spell check function
const spellCheck = async (match: string): Promise<any[]> => {
  try {
    console.log('Performing spell check with Gemini API for:', match);
    
    // Helper function to sort by taxonomic rank priority
    const getRankPriority = (rank: string = ''): number => {
      const priority: {[key: string]: number} = {
        'species': 1,
        'variety': 2,
        'subspecies': 3,
        'genus': 4,
        'family': 5
      };
      const lowerRank = rank.toLowerCase();
      return priority[lowerRank] || 999; // Unknown ranks get lowest priority
    };
    
    // Try up to 3 times with exponential backoff
    for (let attempt = 0; attempt < 3; attempt++) {
      try {
        const spellCheckResponse = await $fetch<{botanicalname: string, rank?: string, text?: string}>('/api/ai/gmni', {
          method: 'POST',
          body: {
            prompts: [
              {
                hidden: true,
                prompt: 'spellcheckPlantNamePrompt'
              },
              {
                hidden: false,
                prompt: match
              },
              {
                hidden: true,
                prompt: 'spellcheckPlantNamePrompt2',
                arg: rowData.value.map((row: any) => {
                  const name = row.botanicalname?.value || row.botanicalname
                  return typeof name === 'string' ? name : name?.value
                }).filter(Boolean)
              },
              {
                hidden: true,
                prompt: 'spellcheckPlantNamePrompt3',
                arg: `${address_components?.city}, ${address_components?.state}, ${address_components?.country}`
              }
            ],
            responseSchema: {
              type: "object",
              properties: {
                botanicalname: {
                  type: "string",
                  description: "The closest botanical name to the one requested"
                },
                rank: {
                  type: "string",
                  description: "The taxonomic rank of the name, eg: genus, variety, family, etc."
                }
              },
              required: ["botanicalname", "rank"]
            },
            temperature: 0.1
          },
          retry: attempt > 0 ? 1 : false,
          retryDelay: Math.pow(2, attempt) * 1000, // Exponential backoff: 1s, 2s, 4s
          onResponseError({ response }) {
            console.error(`Attempt ${attempt + 1} failed with status:`, response.status);
            throw new Error(`API request failed with status ${response.status}`);
          }
        });

        

        // Parse the response - Gemini API should return a JSON object directly
        if (spellCheckResponse && 
            typeof spellCheckResponse === 'object' && 
            'botanicalname' in spellCheckResponse) {
          // Return the suggestion as a single item
          return [{
            value: spellCheckResponse.botanicalname == 'null' ? "Nothing found" : spellCheckResponse.botanicalname,
            label: spellCheckResponse.botanicalname == 'null' ? "Nothing found" : spellCheckResponse.botanicalname,
            group: spellCheckResponse?.rank || 'AI Suggestion',
            key: spellCheckResponse.botanicalname == 'null' ? "" : spellCheckResponse.botanicalname  
          }].sort((a, b) => getRankPriority(a.group) - getRankPriority(b.group)); // Sort by rank priority
        } else {
          // If response is in text format, check if it's JSON
          if (spellCheckResponse && typeof spellCheckResponse === 'object' && 'text' in spellCheckResponse) {
            try {
              // Type assertion to access text property safely
              const textContent = (spellCheckResponse as { text: string }).text;
              const parsedContent = JSON.parse(textContent);
              
              if (parsedContent && parsedContent.botanicalname) {
                return [{
                  value: parsedContent.botanicalname,
                  label: parsedContent.botanicalname,
                  group: parsedContent.rank || 'AI Suggestion',
                  key: parsedContent.botanicalname
                }].sort((a, b) => getRankPriority(a.group) - getRankPriority(b.group)); // Sort by rank priority
              }
            } catch (parseError) {
              console.error('Error parsing suggestion text:', parseError);
            }
          }
        }
        
        // If we get here with no valid response, try again
        throw new Error('Invalid response format from Gemini API');
        
      } catch (requestError) {
        console.error(`Attempt ${attempt + 1} failed:`, requestError);
        if (attempt === 2) { // Last attempt
          console.error('All retry attempts failed');
          return [];
        }
        // Wait before next attempt
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
      }
    }
    
    return []; // Return empty array if all attempts fail
  } catch (error) {
    console.error('Error with Gemini API spell check:', error);
    return [];
  }
};

// Create a debounced version using VueUse's useDebounceFn
const debouncedSpellCheck = useDebounceFn(spellCheck, 300);

// Handler for validation tools updates from dropdown
const handleValidationToolsUpdate = (enabledTools: string[]) => {
  console.log('Received validation tools update:', enabledTools);
  enabledValidationTools.value = enabledTools;
  
  // Re-emit validation events if we have row data
  if (rowData.value && rowData.value.length > 0) {
    console.log('Re-emitting validation events with tools:', enabledValidationTools.value);
    
    // Prepare validation data
    const validationData = {
      panelId: panel.name,
      tool_uid: toolId.value,
      data: {
        // row and column data - normalize data for validation
        rowData: rowData.value?.map(row => {
          const normalizedRow: Record<string, any> = {};
          
          // Process each field in the row
          Object.entries(row).forEach(([key, val]) => {
            // Extract value from object structure or use directly
            if (typeof val === 'object' && val !== null && 'value' in val) {
              normalizedRow[key] = val.value;
            } else {
              normalizedRow[key] = val;
            }
            
            // Special handling for botanicalname
            if (key === 'botanicalname') {
              if (typeof val === 'object' && val !== null) {
                normalizedRow[key] = val.label || val.value || '';
                // Add genus and family if they exist in the object
                if (val.genus) normalizedRow.genus = val.genus;
                if (val.family) normalizedRow.family = val.family;
              }
            }
          });
          
          return normalizedRow;
        }) || [],
        columnDefs: columnDefs.value
      }
    };
    
    // Use the same debounced function for consistent behavior
    if (enabledValidationTools.value.length > 0) {
      emitValidationToTools(validationData, enabledValidationTools.value);
    }
  }
}

// Add refs to track undo/redo availability
const undoAvailable = ref(false)
const redoAvailable = ref(false)

// Add a helper function to compare cell values
const compareCellValues = (a: any, b: any) => {
  // If both are objects with a value property
  if (typeof a === 'object' && a !== null && typeof b === 'object' && b !== null) {
    return a.value === b.value;
  }
  // If one is an object and one isn't
  if (typeof a === 'object' && a !== null) {
    return a.value === b;
  }
  if (typeof b === 'object' && b !== null) {
    return a === b.value;
  }
  // Direct comparison
  return a === b;
}

// Update the default column definition
const defaultColDef = {
  // ... existing properties ...
  editable: true,
  cellClass: 'text-center',
  // Add equals function for proper undo/redo comparison
  equals: (valueA: any, valueB: any) => {
    return compareCellValues(valueA, valueB);
  },
  // Update valueParser to handle object values when editing 
  valueParser: (params: any) => {
    // If editing an existing object value, preserve metadata
    const oldValue = params.data[params.column.colId];
    const metadata = (typeof oldValue === 'object' && oldValue !== null) 
      ? { ...oldValue, source: oldValue.source || 'user-edit' } 
      : { source: 'user-edit' };
    
    // If new value is already an object with a value property
    if (typeof params.newValue === 'object' && params.newValue !== null && 'value' in params.newValue) {
      return {
        ...metadata,
        ...params.newValue,
        updatedAt: new Date().toISOString()
      };
    }
    
    // Create a new object with the updated value and preserved metadata
    return { 
      ...metadata,
      value: params.newValue,
      updatedAt: new Date().toISOString()
    };
  },
  // Update valueFormatter to ensure consistency
  valueFormatter: (params: any) => {
    if (params.value === null || params.value === undefined) {
      return '';
    }
    
    // Extract the value from object structure if needed
    if (typeof params.value === 'object' && params.value !== null) {
      return params.value.value !== undefined ? params.value.value : '';
    }
    
    // Handle case where value is exactly the string "null"
    if (params.value === "null") {
      return '';
    }
    
    return params.value;
  }
} as const;

// Add a flag to prevent recursive transformations
const isApplyingTransformers = ref(false)

// Add a flag to track grid initialization
const isGridInitialized = ref(false)

// Add a function to consistently format measurement values with meters
const formatMeasurementValue = (value: any, fieldName: string): string => {
  const isHeight = fieldName.toLowerCase().includes('height');
  
  // First extract the numeric value if it's in an object
  let numVal: number | null = null;
  
  if (typeof value === 'object' && value !== null) {
    numVal = typeof value.value === 'number' ? value.value : 
             typeof value.value === 'string' ? parseFloat(value.value) : null;
  } else if (typeof value === 'number') {
    numVal = value;
  } else if (typeof value === 'string' && value.trim() !== '') {
    // Try to parse numeric value from string, removing any non-numeric chars except decimal point
    const cleaned = value.replace(/[^\d.-]/g, '');
    numVal = !isNaN(parseFloat(cleaned)) ? parseFloat(cleaned) : null;
  }
  
  // Return empty string for truly empty values
  if (numVal === null || isNaN(numVal)) {
    return '';
  }
  
  // For height fields, always show the value even if zero
  if (isHeight) {
    return `${numVal.toFixed(2)} m`;
  }
  
  // For other measurement fields, show if non-zero
  if (numVal === 0) {
    return '';
  }
  
  // Format with 2 decimal places and append meters
  return `${numVal.toFixed(2)} m`;
};

// Add dedicated debug logger for height fields
const logHeightValue = (source: string, column: string, value: any, context: string = '', extra: any = null) => {
  console.log(`[HEIGHT DEBUG] ${source} | ${column} | ${context} | Value: ${JSON.stringify(value)} | Type: ${typeof value}`, extra ? extra : '');
};

// Add a function to track height data flow in the grid
const trackHeightValues = (api: GridApi | undefined) => {
  if (!api) return;
  
  console.log('[HEIGHT DEBUG] ---- Current height values in grid: ----');
  api.forEachNode((node, index) => {
    if (!node.data) return;
    
    Object.entries(node.data).forEach(([key, val]) => {
      if (key.toLowerCase().includes('height')) {
        const rawValue = typeof val === 'object' && val !== null && 'value' in val ? val.value : val;
        console.log(`[HEIGHT DEBUG] Row ${index} | ${key} | Raw value: ${rawValue} | Full data: ${JSON.stringify(val)}`);
      }
    });
  });
  console.log('[HEIGHT DEBUG] ---- End of height values ----');
};

</script>

<template>
  <div ref="parentElement" class="w-full h-full p-4 ">
    
    <div class="w-full h-full relative font-intervariable">
      <!-- Header controls section -->
      <PlantGridUiDropdown
        :panel="panel"
        :grid-api="gridApi"
        :selected-row-count="selectedRowCount"
        :undo-available="undoAvailable"
        :redo-available="redoAvailable"
        :on-add-row="addRow"
        :on-delete-selected-rows="deleteSelectedRows"
        :on-refresh="handleRefresh"
        :use-fit-contents="useFitContents"
        :zoom-level="zoomLevel"
        :on-zoom-in="handleZoomIn"
        :on-zoom-out="handleZoomOut"
        :on-toggle-sizing="handleToggleSizing"
        :min-zoom="MIN_ZOOM"
        :max-zoom="MAX_ZOOM"
        :on-undo="handleUndo"
        :on-redo="handleRedo"
        @update:validationTools="handleValidationToolsUpdate"
      />
      <ClientOnly>
      <AgGridVue
        :enterNavigatesVerticallyAfterEdit="true"
        :theme="myTheme"
        :style="gridStyle"
        :rowData="rowData"
        :columnDefs="columnDefs"
        :gridOptions="gridOptions"
        :undoRedoCellEditing="true"
        :undoRedoCellEditingLimit="20"
        :pagination="parentElWidth > 480"
        class="font-intervariable pt-16 ag-theme-alpine-dark"
      />
      </ClientOnly>
    </div>
  </div>
</template>

<style scoped>
.ag-root {
  overflow-x: auto !important;
  width: 100%;
}

.ag-body-horizontal-scroll {
  min-height: auto !important;
}

.ag-header-container, 
.ag-body-horizontal-scroll-container {
  min-width: 100% !important;
}
</style>
