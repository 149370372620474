<script setup lang="ts">
import type { VChart } from '#components'
import { use } from 'echarts/core'
import { ToolboxComponent, DataZoomComponent, TooltipComponent } from 'echarts/components'
import { ScatterChart } from 'echarts/charts'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { Command, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem } from '@/components/ui/command'
import { Check, ChevronsUpDown } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import type { AvailabilityResult } from '@/types/validation'
import chroma from 'chroma-js'
import { graphic } from 'echarts'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useEventListener, onClickOutside } from '@vueuse/core'
import { onUnmounted } from 'vue'


use([ToolboxComponent, DataZoomComponent, ScatterChart, TooltipComponent])

const props = defineProps<{
  data: Record<string, AvailabilityResult>
}>()

const chart = ref<InstanceType<typeof VChart> | null>(null)
const chartContainer = ref<HTMLElement | null>(null)
const isChartActive = ref(false)

const initOptions = ref({
  renderer: 'svg'
})

const colorMode = useColorMode()

const colors = computed(() => {
  if (colorMode.value === 'dark') {
    return {
      mutedForeground: 'hsl(90 20.2% 65.1%)',
      accent: 'hsl(160 32.6% 21.5%)',
      muted: 'hsl(160 22.6% 13.5%)',
      primary: 'hsl(90 20% 84%)',
      available: 'hsl(142.1 76.2% 36.3%)',
      unavailable: 'hsl(0 0% 100%)'
    }
  } else {
    return {
      mutedForeground: 'hsl(160 21% 36.9%)',
      accent: 'hsl(160 30% 79.1%)',
      muted: 'hsl(90 11% 91%)',
      primary: 'hsl(120 27.4% 21.2%)',
      available: 'hsl(142.1 76.2% 36.3%)',
      unavailable: 'hsl(346.8 40% 45%)'
    }
  }
})

// Filter states
const selectedSpecies = ref<string[]>([])
const selectedSizes = ref<string[]>([])
const openSpecies = ref(false)
const openSizes = ref(false)
const searchTermSpecies = ref('')
const searchTermSizes = ref('')

// Get unique species and sizes
const availableSpecies = computed(() => {
  return Object.keys(props.data).sort()
})

const availableSizes = computed(() => {
  const sizes = new Set<string>()
  Object.values(props.data).forEach(result => {
    result.suppliers?.forEach(supplier => {
      if (supplier.size) {
        sizes.add(supplier.size)
      }
    })
  })
  return Array.from(sizes).sort()
})

// Filter functions
const filteredSpecies = computed(() => {
  return availableSpecies.value.filter(species => 
    species.toLowerCase().includes(searchTermSpecies.value.toLowerCase())
  )
})

const filteredSizes = computed(() => {
  return availableSizes.value.filter(size => 
    size.toLowerCase().includes(searchTermSizes.value.toLowerCase())
  )
})

// Toggle selection functions
const toggleSpecies = (species: string) => {
  const index = selectedSpecies.value.indexOf(species)
  if (index === -1) {
    selectedSpecies.value.push(species)
  } else {
    selectedSpecies.value.splice(index, 1)
  }
}

const toggleSize = (size: string) => {
  const index = selectedSizes.value.indexOf(size)
  if (index === -1) {
    selectedSizes.value.push(size)
  } else {
    selectedSizes.value.splice(index, 1)
  }
}

// Format functions
const formatPrice = (price: number) => {
  return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(price)
}

// Add the same color palette as TreeMap
const tailwindPastelColors = [
  'rgb(253, 186, 116)', // orange-300
  'rgb(147, 197, 253)', // blue-300
  'rgb(134, 239, 172)', // green-300
  'rgb(252, 165, 165)', // red-300
  'rgb(216, 180, 254)', // purple-300
  'rgb(253, 224, 71)',  // yellow-300
  'rgb(249, 168, 212)', // pink-300
  'rgb(125, 211, 252)', // sky-300
  'rgb(153, 246, 228)', // teal-300
  'rgb(196, 181, 253)', // indigo-300
]

// Generate a color for each species
const getSpeciesColor = (species: string) => {
  const index = Object.keys(props.data).indexOf(species)
  return tailwindPastelColors[index % tailwindPastelColors.length]
}

// Generate scatter plot data
const scatterData = computed(() => {
  const dataPoints: Array<{
    value: [number, number]
    itemStyle: { color: string }
    species: string
    size: string
    supplier: string
    state: string
  }> = []

  Object.entries(props.data).forEach(([species, result]) => {
    // Skip if species is filtered
    if (selectedSpecies.value.length > 0 && !selectedSpecies.value.includes(species)) {
      return
    }

    result.suppliers?.forEach(supplier => {
      // Skip if size is filtered
      if (selectedSizes.value.length > 0 && !selectedSizes.value.includes(supplier.size)) {
        return
      }

      if (supplier.price && supplier.quantity) {
        const color = getSpeciesColor(species)
        dataPoints.push({
          value: [supplier.price, supplier.quantity],
          itemStyle: {
            color,
            opacity: 0.7,
            borderColor: chroma(color).darken().hex(),
            borderWidth: 1
          },
          species,
          size: supplier.size,
          supplier: supplier.supplier.name,
          state: supplier.supplier.state
        })
      }
    })
  })

  return dataPoints
})

// Add opacity to primary color
const getColorWithOpacity = (color: string, opacity: number) => {
  if (color.startsWith('hsl')) {
    const [h, s, l] = color.match(/\d+/g).map(Number)
    return `hsla(${h}, ${s}%, ${l}%, ${opacity})`
  }
  return color
}

// Add correlation calculation function
const calculateCorrelation = (points: [number, number][]) => {
  const n = points.length
  const xValues = points.map(p => p[0])
  const yValues = points.map(p => p[1])
  
  const xMean = xValues.reduce((a, b) => a + b) / n
  const yMean = yValues.reduce((a, b) => a + b) / n
  
  const numerator = xValues.reduce((sum, x, i) => 
    sum + (x - xMean) * (yValues[i] - yMean), 0
  )
  
  const xVariance = xValues.reduce((sum, x) => 
    sum + Math.pow(x - xMean, 2), 0
  )
  const yVariance = yValues.reduce((sum, y) => 
    sum + Math.pow(y - yMean, 2), 0
  )
  
  return numerator / Math.sqrt(xVariance * yVariance)
}

// Chart options
const option = computed(() => ({
  tooltip: {
    trigger: 'item',
    formatter: (params: any) => {
      if (!params?.data) return ''
      
      const data = params.data
      const price = data.value?.[1] ?? 0
      const quantity = data.value?.[0] ?? 0
      const species = data.species ?? 'Unknown'
      const size = data.size ?? 'N/A'
      const state = data.state ?? 'N/A'

      return `
        <div class="tooltip-content">
          <div class="tooltip-title">${species}</div>
          <div class="tooltip-details">
            <div class="tooltip-detail">
              <span class="tooltip-label">Price:</span>
              <span class="tooltip-value">${formatPrice(price)}</span>
            </div>
            <div class="tooltip-detail">
              <span class="tooltip-label">Quantity:</span>
              <span class="tooltip-value">${quantity}</span>
            </div>
            <div class="tooltip-detail">
              <span class="tooltip-label">Size:</span>
              <span class="tooltip-value">${size}</span>
            </div>
            <div class="tooltip-detail">
              <span class="tooltip-label">State:</span>
              <span class="tooltip-value">${state}</span>
            </div>
          </div>
        </div>
      `
    },
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderColor: 'transparent',
    borderRadius: 8,
    padding: 0,
    className: 'echarts-tooltip-custom',
    extraCssText: 'backdrop-filter: blur(8px); box-shadow: 0 4px 15px -3px rgb(0 0 0 / 0.1);'
  },
  grid: {
    left: '8%',
    right: '5%',
    top: '8%',
    bottom: '8%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    name: 'Quantity',
    nameLocation: 'middle',
    nameGap: 30,
    axisLabel: {
      color: colors.value.primary,
      fontWeight: 500
    },
    axisLine: {
      lineStyle: {
        color: colors.value.muted
      }
    },
    splitLine: {
      lineStyle: {
        color: colors.value.muted
      }
    }
  },
  yAxis: {
    type: 'value',
    name: 'Price (AUD)',
    nameLocation: 'middle',
    nameGap: 65,
    nameTextStyle: {
      color: colors.value.mutedForeground,
      padding: [0, 0, 0, 40]
    },
    min: 0,
    minInterval: 1,
    axisLabel: {
      formatter: (value: number) => formatPrice(value),
      color: colors.value.mutedForeground,
      margin: 20
    },
    axisLine: {
      lineStyle: {
        color: colors.value.muted
      }
    },
    splitLine: {
      lineStyle: {
        color: colors.value.muted
      }
    }
  },
  series: [
    {
      type: 'scatter',
      data: scatterData.value.map(point => ({
        ...point,
        value: [point.value[1], point.value[0]] // Flip x and y values
      })),
      symbolSize: 10,
      itemStyle: {
        opacity: 0.7
      },
      emphasis: {
        scale: false,
        itemStyle: {
          opacity: 1,
          borderWidth: 2
        },
        label: {
          show: true,
          formatter: (params: any) => params.data.species,
          position: 'top',
          distance: 10,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: [4, 8],
          borderRadius: 4,
          color: colors.value.primary
        }
      }
    },
    // Add trend line series
    {
      type: 'line',
      smooth: true,
      showSymbol: false,
      data: (() => {
        if (scatterData.value.length < 2) return []
        // Calculate trend line points
        const points = scatterData.value.map(item => [item.value[1], item.value[0]]) // Flip x and y values
        const xValues = points.map(p => p[0])
        const yValues = points.map(p => p[1])
        const minX = Math.min(...xValues)
        const maxX = Math.max(...xValues)
        
        // Simple linear regression
        const xMean = xValues.reduce((a, b) => a + b) / xValues.length
        const yMean = yValues.reduce((a, b) => a + b) / xValues.length
        const slope = xValues.reduce((a, b, i) => a + (b - xMean) * (yValues[i] - yMean), 0) /
                     xValues.reduce((a, b) => a + Math.pow(b - xMean, 2), 0)
        const intercept = yMean - slope * xMean
        
        // Calculate correlation coefficient
        const r = calculateCorrelation(points)
        
        return [
          [minX, minX * slope + intercept],
          [maxX, maxX * slope + intercept]
        ]
      })(),
      lineStyle: {
        color: getColorWithOpacity(colors.value.primary, 0.3),
        type: 'dashed',
        width: 1
      },
      tooltip: {
        show: false
      }
    }
  ],
  dataZoom: [
    {
      type: 'inside',
      xAxisIndex: 0,
      zoomOnMouseWheel: isChartActive.value,
      moveOnMouseWheel: false
    },
    {
      type: 'inside',
      yAxisIndex: 0,
      zoomOnMouseWheel: isChartActive.value,
      moveOnMouseWheel: false
    }
  ],
  toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      },
      restore: {},
      saveAsImage: {}
    },
    iconStyle: {
      borderColor: colors.value.primary
    },
    emphasis: {
      iconStyle: {
        borderColor: colors.value.primary
      }
    }
  }
}))

// Add computed properties for counts
const speciesCounts = computed(() => {
  const counts: Record<string, number> = {}
  Object.entries(props.data).forEach(([species, result]) => {
    counts[species] = result.suppliers?.filter(supplier => {
      if (selectedSizes.value.length > 0) {
        return selectedSizes.value.includes(supplier.size)
      }
      return true
    }).length || 0
  })
  return counts
})

const sizeCounts = computed(() => {
  const counts: Record<string, number> = {}
  Object.entries(props.data).forEach(([species, result]) => {
    // Skip if species is filtered
    if (selectedSpecies.value.length > 0 && !selectedSpecies.value.includes(species)) {
      return
    }
    
    result.suppliers?.forEach(supplier => {
      if (supplier.size) {
        counts[supplier.size] = (counts[supplier.size] || 0) + 1
      }
    })
  })
  return counts
})

// Add section refs and active section tracking
const activeSection = ref('overview')
const overviewSection = ref<HTMLElement | null>(null)
const distributionSection = ref<HTMLElement | null>(null)
const correlationSection = ref<HTMLElement | null>(null)

// Add scroll area ref
const scrollAreaRef = ref<InstanceType<typeof ScrollArea> | null>(null)

// Add container ref and size tracking
const tabsContainer = ref<HTMLElement | null>(null)
const { width: containerWidth } = useElementSize(tabsContainer)

// Add computed for narrow width
const isNarrowWidth = computed(() => containerWidth.value < 300)

// Add sections data
const sections = computed(() => [
  { 
    id: 'overview', 
    label: "Overview",
    score: scatterData.value.length > 0 ? `${scatterData.value.length} points` : null
  },
  { 
    id: 'distribution', 
    label: "Distribution",
    score: scatterData.value.length > 0 ? 
      `${Object.keys(speciesCounts.value).length} species` : null
  },
  { 
    id: 'correlation', 
    label: "Correlation",
    score: scatterData.value.length > 0 ? 
      calculateCorrelation(scatterData.value.map(d => [d.value[1], d.value[0]])).toFixed(2) : null
  }
])

// Add scroll to section function
const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId)
  const viewport = document.querySelector('[data-radix-scroll-area-viewport]')
  if (section && viewport) {
    const targetScroll = section.offsetTop - 120
    viewport.scrollTop = targetScroll
    activeSection.value = sectionId
  }
}

// Add intersection observer setup
onMounted(() => {
  // Wait for scroll container to be available
  nextTick(() => {
    const scrollContainer = document.querySelector('.scroll-container')
    if (scrollContainer) {
      const sectionRefs = {
        overview: overviewSection,
        distribution: distributionSection,
        correlation: correlationSection
      }
      
      Object.entries(sectionRefs).forEach(([id, ref]) => {
        useIntersectionObserver(
          ref,
          ([{ isIntersecting }]) => {
            if (isIntersecting) {
              activeSection.value = id
            }
          },
          { 
            threshold: [0.1, 0.5, 0.9],
            rootMargin: '-100px 0px 0px 0px',
            root: scrollContainer
          }
        )
      })
    }
  })
})

// Handle chart activation on click
const activateChart = () => {
  isChartActive.value = true
}

// Add deactivate function
const deactivateChart = () => {
  isChartActive.value = false
  // Remove focus from the container
  chartContainer.value?.blur()
}

// Handle click outside to deactivate chart
onClickOutside(chartContainer, () => {
  deactivateChart()
})

// Add cleanup
onUnmounted(() => {
  if (chart.value) {
    chart.value.dispose()
  }
})
</script>

<template>
  <div class="px-[6%]">
    <div class="flex flex-col gap-6 w-full ">
      <!-- Filters -->
      <div class="flex gap-2 mb-4 flex-col w-full ">
        <!-- Species Filter -->
        <Popover v-model:open="openSpecies">
          <PopoverTrigger as-child>
            <Button 
              variant="outline" 
              role="combobox" 
              :aria-expanded="openSpecies" 
              class="w-[180px] justify-between"
            >
              <span>{{ selectedSpecies.length === 0 ? 'Filter by species' : 
                selectedSpecies.length === 1 ? selectedSpecies[0] : 
                `${selectedSpecies.length} species selected` }}</span>
              <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent class="w-[300px] p-0">
            <Command>
              <CommandInput 
                placeholder="Search species..." 
                v-model="searchTermSpecies"
              />
              <CommandEmpty>No species found.</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  <CommandItem
                    v-for="species in filteredSpecies"
                    :key="species"
                    :value="species"
                    @select="() => toggleSpecies(species)"
                    class="flex justify-between"
                  >
                    <div class="flex items-center">
                      <Check
                        :class="cn(
                          'mr-2 h-4 w-4',
                          selectedSpecies.includes(species) ? 'opacity-100' : 'opacity-0'
                        )"
                      />
                      {{ species }}
                    </div>
                    <Badge 
                      variant="secondary" 
                      class="ml-2 font-normal"
                    >
                      {{ speciesCounts[species] }}
                    </Badge>
                  </CommandItem>
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>

        <!-- Size Filter -->
        <Popover v-model:open="openSizes">
          <PopoverTrigger as-child>
            <Button 
              variant="outline" 
              role="combobox" 
              :aria-expanded="openSizes" 
              class="w-[200px] justify-between"
            >
              <span>{{ selectedSizes.length === 0 ? 'Filter by size' : 
                selectedSizes.length === 1 ? selectedSizes[0] : 
                `${selectedSizes.length} sizes selected` }}</span>
              <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent class="w-[300px] p-0">
            <Command>
              <CommandInput 
                placeholder="Search sizes..." 
                v-model="searchTermSizes"
              />
              <CommandEmpty>No size found.</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  <CommandItem
                    v-for="size in filteredSizes"
                    :key="size"
                    :value="size"
                    @select="() => toggleSize(size)"
                    class="flex justify-between"
                  >
                    <div class="flex items-center">
                      <Check
                        :class="cn(
                          'mr-2 h-4 w-4',
                          selectedSizes.includes(size) ? 'opacity-100' : 'opacity-0'
                        )"
                      />
                      {{ size }}
                    </div>
                    <Badge 
                      variant="secondary" 
                      class="ml-2 font-normal"
                    >
                      {{ sizeCounts[size] }}
                    </Badge>
                  </CommandItem>
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      <!-- Chart Container - Updated with new wrapper and styling -->
      <div 
        ref="chartContainer"
        class="w-full bg-background rounded-xl p-6 cursor-pointer transition-all duration-200"
        :class="{ 'ring-2 ring-primary ring-opacity-50': isChartActive }"
        @click="activateChart"
        @keydown.escape.prevent="deactivateChart"
        tabindex="0"
      >
        <div 
          class="chart-wrapper w-full h-[400px] min-h-[400px]"
          :class="{ 'is-active': isChartActive }"
        >
          <VChart 
            ref="chart" 
            :option="option" 
            :init-options="initOptions" 
            autoresize 
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.echarts) {
  width: 100% !important;
  height: 100% !important;
  min-height: 400px !important;
}

.scroll-thumb {
  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  scrollbar-width: thin;
}

.scroll-thumb::-webkit-scrollbar {
  width: var(--sb-size);
}

.scroll-thumb::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

.scroll-thumb::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
}

.sticky-header {
  min-height: 52px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
}

.scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.backdrop-blur-sm {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.cursor-pointer {
  cursor: pointer;
}

.events-none {
  pointer-events: none !important;
}

.chart-wrapper {
  position: relative;
}

.chart-wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 10;
  border-radius: 0.75rem;
}

.chart-wrapper:not(.is-active)::before {
  pointer-events: auto;
  cursor: pointer;
}

.chart-wrapper.is-active::before {
  pointer-events: none;
}

/* Add keyboard focus styles */
.chart-wrapper:focus-visible {
  outline: none;
  /* ring: 2px solid hsl(var(--primary));
  ring-opacity: 50%; */
}
</style>

<style>
.echarts-tooltip-custom {
  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(8px) !important;
  border: none !important;
  border-radius: 12px !important;
  padding: 12px !important;
  box-shadow: 0 4px 15px -3px rgb(0 0 0 / 0.1) !important;
  max-width: 300px !important;
}

.dark .echarts-tooltip-custom {
  background: rgba(30, 30, 30, 0.8) !important;
}

.tooltip-content {
  font-family: system-ui, -apple-system, sans-serif;
}

.tooltip-title {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--foreground);
  line-height: 1.3;
}

.tooltip-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tooltip-detail {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.tooltip-label {
  color: var(--muted-foreground);
  font-size: 12px;
}

.tooltip-value {
  color: var(--foreground);
  font-size: 12px;
  font-weight: 500;
}
</style> 