<script setup lang="ts">
const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
});

const emit = defineEmits(['error', 'update:shared-data']);

// Example of using shared data
// const sharedData = computed(() => props.panel.data.sharedData['some-panel-id'] || {})

// Example of a computed property based on shared data
const computedData = computed(() => {
  // Process sharedData and return something
})

const selectedItem = ref(null)

// Example of updating shared data
const updatePanelData = () => {
  emit('update:shared-data', {
    selectedItem: selectedItem.value,
    // Add any other data you want to share
  })
}

// Add any other necessary logic here
</script>

<template>
  
      <div class="w-full h-full p-0 m-0 rounded-md p-2 pl-4">
        <!-- <ScrollArea class="w-full h-full">   -->
          <!-- <div class="flex flex-wrap justify-center align-center items-center w-full h-full"> -->
            <DiscoveryInterface />
          <!-- </div> -->
        <!-- </ScrollArea> -->
      </div>
      <!-- Example of a conditional element -->
      <div v-if="selectedItem" class="absolute bottom-4 left-4 bg-white dark:bg-gray-800 p-2 rounded shadow">
        <!-- Display details about the selected item -->
      </div>
      <!-- Example of an always-visible element -->
      <!-- <div class="absolute top-4 right-4 bg-white dark:bg-gray-800 p-2 rounded shadow"> -->
        <!-- Display some computed or shared data -->
      <!-- </div> -->
</template>