<script setup>
import { watch, ref, onMounted } from 'vue'

const {words, radius} = defineProps({
  words: { type: Array, required: false, default: () => [] },
  radius: { type: Number, required: false, default: 370 }
})

const cloudContainer = ref(null)
const cloudInstance = ref(null)

const emit = defineEmits(['click'])

const options = {
  radius,
  maxSpeed: 'fast', // 滚动最大速度, 取值: slow, normal(默认), fast
  initSpeed: 'normal', // 滚动初速度, 取值: slow, normal(默认), fast
  direction: -135, // 初始滚动方向, 取值角度(顺时针deg): 0 对应 top, 90 对应 left, 135 对应 right-bottom(默认)...
  keep: false
}

const initCloud = async () => {
  if (import.meta.client && cloudContainer.value) {
    try {
      const JsTagCloud = (await import('js-tag-cloud')).default
      // Clean up old instance if it exists
      if (cloudInstance.value) {
        cloudContainer.value.innerHTML = ''
      }
      console.log('Initializing cloud with words:', words)
      cloudInstance.value = JsTagCloud(cloudContainer.value, words, options)
      // Add click event listener to the cloud container
      cloudContainer.value.addEventListener('click', function clickEventHandler(e) {
        if (e.target.className === 'js-tag-cloud-item') {
          emit('click', e.target.innerText)
        }
      });
    } catch (error) {
      console.error('Error initializing cloud:', error)
    }
  }
}

onMounted(async () => {
  if (words.length > 0) {
    await initCloud()
  }
})

// Watch for changes in words prop
watch(() => words, async (newWords) => {
  console.log('Words changed:', newWords)
  if (newWords.length > 0) {
    await initCloud()
  }
}, { deep: true, immediate: true })
</script>

<template>
  <ClientOnly>
    <div :class="$attrs.class" style="display:flex; justify-content:center; align-items:center;" id="tagCloud" ref="cloudContainer">
      <div v-if="!words || words.length === 0" class="text-muted-foreground text-sm">
        No species found in document
      </div>
    </div>
  </ClientOnly>
</template>

<style>
.js-tag-cloud-item {
  cursor: pointer !important;
}
</style>