<script setup lang="ts">
import type { VChart } from '#components'
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { 
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  GraphicComponent
} from 'echarts/components'
import { SVGRenderer } from 'echarts/renderers'
import type { MaintenanceInputs, CostSeries, VolumeKey, PotSizeKey } from '~/types/maintenance'
import type { VueElement } from 'vue'
import type { BarSeriesOption } from 'echarts/charts'
import type { SeriesOption } from 'echarts/types/dist/shared'
import type { EChartsOption } from 'echarts'
import type { ECElementEvent } from 'echarts/types/src/util/types'

// Import Shadcn components
import { 
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger 
} from '@/components/ui/accordion'
import { Label } from '@/components/ui/label'
import { Slider } from '@/components/ui/slider'
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from '@/components/ui/select'
import {
  NumberField,
  NumberFieldContent,
  NumberFieldDecrement,
  NumberFieldIncrement,
  NumberFieldInput,
} from '@/components/ui/number-field'
import BalanceSlider from '~/components/BalanceSlider.vue'
import PotSizeSelector from '~/components/PotSizeSelector.vue'
import { Button } from '@/components/ui/button'
import { Line as LineChart } from 'vue-chartjs'
import { tree_costs, pot_size_mapping } from '~/constants/maintenance'
import DragDataPlugin from 'chartjs-plugin-dragdata'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import MaintenanceIntensityCurve from '~/components/MaintenanceIntensityCurve.vue'
import VolumeCostRelationship from '~/components/VolumeCostRelationship.vue'



// Add this near other imports
const uiStore = useUiStore()
const dockStore = useDockStore()


const { dragOver, includeDataInChat, activePanel, saving, panelToRefresh, availablePanels } = storeToRefs(dockStore);

const {windowWidth, windowHeight, chatDialogOpen } = storeToRefs(uiStore)

// Remove the old window resize related code and replace with computed values
const isNarrowScreen = computed(() => 
  uiStore.windowWidth < 1280 && uiStore.windowHeight < 1200
)

// Update the settings panel collapse logic
const settingsCollapsedState = ref(false)

// Simplify the isSettingsCollapsed computed
const isSettingsCollapsed = computed({
  get: () => settingsCollapsedState.value,
  set: (value) => {
    settingsCollapsedState.value = value
  }
})

// Update the settings panel collapse button click handler
const handleSettingsCollapse = () => {
  isSettingsCollapsed.value = !isSettingsCollapsed.value
}

// Add watcher to handle width changes
watch(() => uiStore.windowWidth, (newWidth) => {
  // Force collapse when width < 1024px if not already collapsed
  if (newWidth < 1024 && !settingsCollapsedState.value) {
    settingsCollapsedState.value = true
  }
}, { immediate: true })

const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false }
});

const emit = defineEmits(['error', 'update:shared-data']);

// Example of using shared data
// const sharedData = computed(() => props.panel.data.sharedData['some-panel-id'] || {})

// Example of a computed property based on shared data
const computedData = computed(() => {
  // Process sharedData and return something
})

const selectedItem = ref(null)

// Example of updating shared data
const updatePanelData = () => {
  emit('update:shared-data', {
    selectedItem: selectedItem.value,
    // Add any other data you want to share
  })
}

// Example of updating the output property


useSquircleObserver()

// Register required ECharts components
use([
  SVGRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  GraphicComponent
])

// Register Chart.js components and plugins
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  DragDataPlugin,
  annotationPlugin
)

// Then use it in the chart initialization
const { 
  inputs, 
  updateInputs, 
  plotCashflowCosts,
  calculateLifeCycleCosts,
  generateYearlyData,
  calculateResults,
  results
} = useMaintenance()

// Create local chart option ref with current costs
const localChartOption = ref(plotCashflowCosts(inputs.value))

// Update the watch to use the new ref name
watch(inputs, () => {
  console.log('Inputs changed, updating charts');
  // The costs are now handled inside useMaintenance
  localChartOption.value = plotCashflowCosts(inputs.value)
  
  if (barChart.value) {
    barChart.value.setOption(localChartOption.value, {
      replaceMerge: ['series']
    });
  }
  if (pieChart.value) {
    pieChart.value.setOption(pieChartOption.value, {
      replaceMerge: ['series']
    });
  }
}, { deep: true });

const chartContainer = ref<HTMLElement | null>(null)
const barChart = ref<InstanceType<typeof VChart> | null>(null)
const pieChart = ref<InstanceType<typeof VChart> | null>(null)



// Event handlers
const onChartMouseMove = (params: ECElementEvent) => {
  if (params.componentType === 'series') {
    // Handle hover effects if needed
  }
}

const onChartMouseOut = (params: ECElementEvent) => {
  // Reset hover effects if needed
}

// Add state for accordion open sections
const openSections = ref(['physical-params', 'prices'])

// Add computed property for pie chart data
const pieChartOption = computed(() => {
  const yearlyData = generateYearlyData(inputs.value);
  const lifeCycleCosts = calculateLifeCycleCosts(inputs.value, yearlyData);
  
  const data = [
    { 
      name: 'Establishment', 
      value: Math.round(lifeCycleCosts['Establishment'] * 100) / 100
    },
    { 
      name: 'Inspections and maintenance', 
      value: Math.round(lifeCycleCosts['Inspections and maintenance'] * 100) / 100
    },
    { 
      name: 'Net mortality', 
      value: Math.round(lifeCycleCosts['Net mortality'] * 100) / 100
    }
  ];
  
  const total = lifeCycleCosts['Total life cycle costs'];
  
  return {
    animation: false,
    title: false,
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderWidth: 0,
      padding: 10,
      textStyle: {
        color: '#666'
      },
      formatter: (params: any) => {
        const percentage = ((params.value / total) * 100).toFixed(1);
        return `
          <div class="font-semibold text-sm mb-2">${params.name}</div>
          <div class="flex justify-between text-xs py-1">
            <span class="text-gray-600 mr-4">Amount:</span>
            <span class="font-medium">$${params.value.toLocaleString()}</span>
          </div>
          <div class="flex justify-between text-xs py-1">
            <span class="text-gray-600 mr-4">Share:</span>
            <span class="font-medium">${percentage}%</span>
          </div>
        `;
      }
    },
    legend: {
      orient: 'horizontal',
      bottom: 0,
      padding: [0, 0, 20, 0]  // Add some bottom padding
    },
    graphic: [{
      type: 'text',
      left: 'center',
      top: 'center',
      style: {
        text: [
          'Total',
          `$${total.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}`
        ].join('\n'),
        textAlign: 'center',
        fontSize: [24, 35],
        fontWeight: 'bold',
        lineDiff: 20,
        fill: '#334155',
        fontFamily: 'Inter'
      },
      z: 100
    }],
    series: [
      {
        name: 'Life Cycle Costs',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: true,
        animation: false,
        animationDuration: 0,
        animationDurationUpdate: 0,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: true,
          formatter: (params: any) => {
            const percentage = ((params.value / total) * 100).toFixed(1);
            return `${percentage}%\n$${params.value.toLocaleString()}`;
          }
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '16',
            fontWeight: 'bold'
          }
        },
        tooltip: {
          formatter: (params: any) => {
            const percentage = ((params.value / total) * 100).toFixed(1);
            return `
              <div class="font-semibold text-sm mb-2">${params.name}</div>
              <div class="flex justify-between text-xs py-1">
                <span class="text-gray-600 mr-4">NPV:</span>
                <span class="font-medium">$${params.value.toLocaleString()}</span>
              </div>
              <div class="flex justify-between text-xs py-1">
                <span class="text-gray-600 mr-4">Share:</span>
                <span class="font-medium">${percentage}%</span>
              </div>
            `;
          }
        },
        data: data,
        color: [
          '#60A5FA', // blue-400 for Establishment
          '#A78BFA', // violet-400 for Inspections and maintenance
          '#FB7185'  // rose-400 for Net mortality
        ]
      }
    ]
  };
});

// Add immediate update for number inputs
const handleNumberInput = (event: Event, field: keyof MaintenanceInputs) => {
  const input = event.target as HTMLInputElement;
  const value = input.value ? parseInt(input.value.replace(/[^0-9]/g, '')) : 0;
  
  // Only update if it's a valid number within bounds
  if (!isNaN(value) && value >= 0 && value <= 99999) { // Increased max value
    // If this is part of tree quantities, update the specific quantity
    if (typeof field === 'string' && field.startsWith('tree_quantity_')) {
      const index = parseInt(field.split('_')[2]);
      const newQuantities = [...inputs.value.tree_quantities.value];
      newQuantities[index] = {
        ...newQuantities[index],
        quantity: value
      };
      handleInputChange('tree_quantities', newQuantities);
    } else {
      handleInputChange(field, value);
    }
  }
}

const selectedCostType = ref('supply')
const volumeKey = computed(() => {
  const firstPotSize = inputs.value.tree_quantities.value[0]?.size
  return pot_size_mapping[firstPotSize] || 'volume_25_50L'
})

// Format cost type labels
const formatCostLabel = (key: string) => {
  return key.split('_').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1)
  ).join(' ')
}

// Chart data
const priceChartData = computed(() => {
  const defaultCosts = tree_costs[volumeKey.value][selectedCostType.value]
  if (!defaultCosts) return null

  const costs = uiStore.getCostPercentiles(volumeKey.value, selectedCostType.value, defaultCosts)

  return {
    labels: [0, 5, 25, 50, 75, 95, 100], // Use numbers instead of strings
    datasets: [{
      label: formatCostLabel(selectedCostType.value),
      data: [
        costs.p0,
        costs.p5,
        costs.p25,
        costs.p50,
        costs.p75,
        costs.p95,
        costs.p100
      ],
      borderColor: '#60A5FA',
      backgroundColor: 'rgba(96, 165, 250, 0.1)',
      tension: 0.4,
      fill: true,
      pointRadius: 6,
      pointHoverRadius: 8,
    }]
  }
})

// Update the costDescriptions object
const costDescriptions = {
  concrete_cutting: "Cost of cutting an access hole in the footpath or road kerbside",
  supply: "Wholesale price of the tree (delivered to site)",
  tree_installation: "Bundled installation cost (includes labour and equipment) per tree",
  soil_cost: "Cost of delivered soil per m3 per tree",
  mulch_cost: "Cost of mulch per m3 (including installation) at time of planting",
  stakes_and_ties: "Cost of stakes and ties (including installation)",
  installation_cost: "Labor cost per hour for unbundled installation",
  machine_rate: "Equipment/machine cost per hour for installation",
  tree_removal: "Cost of removing and disposing existing trees",
  tree_protection_fencing: "Cost of purchasing, delivery and installation of tree protection fences",
  guard_rails: "Costs of purchasing, delivery and installation of permanent guard rails",
  maintenance: "Base maintenance cost per tree (scaled by intensity curve)",  // Updated description
  watering: "Average watering cost per tree per visit",
  visual_inspection: "Cost of a rapid visual tree inspection per tree",
  arborist_inspection: "Cost of detailed arborist tree health inspection per tree",
  gis_mapping: "One-time cost of GIS mapping and inventory assessment per tree"
} as const

// Add these refs at the top with other refs
const chartRef = ref<HTMLElement | null>(null)
const selectedPercentile = ref(50) // Default to p50
const dragging = ref(false)
const currentYAxisMax = ref(1000)

// Add this computed for the line position
const linePosition = computed(() => {
  // Map percentile to x-axis position (0-6 range for 7 points)
  const percentiles = [0, 5, 25, 50, 75, 95, 100]
  const index = percentiles.indexOf(selectedPercentile.value)
  return index >= 0 ? index : 3 // Default to middle (p50) if not found
})

// Update the priceChartOptions computed
const priceChartOptions = computed(() => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      dragData: {
        round: 2,
        showTooltip: false,
        dragX: false,
        onDragStart: (e: any) => {
          dragging.value = true
        },
        onDrag: (e: any, datasetIndex: number, index: number, value: number) => {
          const newValue = Math.max(value, 0)
          const percentiles = [0, 5, 25, 50, 75, 95, 100]
          
          // Update the selected percentile based on the point being dragged
          selectedPercentile.value = percentiles[index]
          
          // Update the cost value
          const percentileKeys = ['p0', 'p5', 'p25', 'p50', 'p75', 'p95', 'p100']
          const key = percentileKeys[index]
          if (key) {
            uiStore.updateCostPercentile(volumeKey.value, selectedCostType.value, key as keyof CostPercentiles, newValue)
            
            // Force recalculation of results and charts with current costs
            const newResults = calculateResults(inputs.value)
            results.value = newResults
            if (barChart.value) {
              barChart.value.setOption(plotCashflowCosts(inputs.value))
            }
            if (pieChart.value) {
              pieChart.value.setOption(pieChartOption.value)
            }
          }
        },
        onDragEnd: (e: any) => {
          dragging.value = false
        }
      },
      annotation: {
        common: {
          drawTime: 'afterDatasetsDraw'
        },
        annotations: {
          line1: {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x',
            value: linePosition.value,
            borderColor: 'rgb(148, 163, 184)',
            borderWidth: 2,
            borderDash: [5, 5],
            label: {
              backgroundColor: 'rgb(148, 163, 184)',
              content: () => {
                const percentiles = [0, 5, 25, 50, 75, 95, 100]
                return `p${percentiles[linePosition.value]}`
              },
              display: true,
              position: 'start',
              color: 'white',
              font: {
                weight: 'bold'
              }
            }
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: currentYAxisMax.value,
        grace: '5%',
        title: {
          display: true,
          text: 'Cost ($)'
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          callback: (value: any) => `p${[0, 5, 25, 50, 75, 95, 100][value]}`
        }
      }
    },
    animation: {
      duration: 150
    },
    onClick: (event: any, elements: any, chart: any) => {
      if (!chart || !chart.canvas) return;
      
      // If we clicked on a point, use its index to set the percentile
      if (elements && elements.length > 0) {
        const index = elements[0].index
        const percentiles = [0, 5, 25, 50, 75, 95, 100]
        const newPercentile = percentiles[index]
        
        if (newPercentile !== selectedPercentile.value) {
          selectedPercentile.value = newPercentile
          nextTick(() => {
            updateCostsWithPercentile(newPercentile)
          })
        }
        return
      }
      
      // Otherwise, handle click on the chart area as before
      const rect = chart.canvas.getBoundingClientRect()
      const x = event.clientX - rect.left
      const width = rect.width
      
      if (width <= 0) return
      
      const xPercent = Math.max(0, Math.min(x / width, 1))
      const percentiles = [0, 5, 25, 50, 75, 95, 100]
      const index = Math.round(xPercent * (percentiles.length - 1))
      const newPercentile = percentiles[Math.max(0, Math.min(index, percentiles.length - 1))]
      
      if (newPercentile !== selectedPercentile.value) {
        selectedPercentile.value = newPercentile
        nextTick(() => {
          updateCostsWithPercentile(newPercentile)
        })
      }
    }
  }
})

// Add this after the useMaintenance destructuring
const handleInputChange = (field: keyof MaintenanceInputs, value: any) => {
  console.log('Input change:', field, value)
  
  // Special handling for tree_quantities array
  if (field === 'tree_quantities') {
    const newInputs = {
      tree_quantities: { value }
    } as Partial<MaintenanceInputs>
    
    console.log('Updating tree quantities:', newInputs)
    updateInputs(newInputs)
    return
  }
  
  // Handle other inputs as before
  const newInputs = {
    [field]: { value }
  } as Partial<MaintenanceInputs>
  
  console.log('Updating inputs with:', newInputs)
  updateInputs(newInputs)
}

// Pass the reactive values that affect the rendering of squircle elements
const { reapplySquircles } = useSquircleObserver([isNarrowScreen, isSettingsCollapsed]);

// Add a watcher for the tabs value if you're using v-show instead of v-if
const activeTab = ref('cashflow');
watch(activeTab, () => {
  nextTick(reapplySquircles);
});

// Add computed for aspect ratio check
const isWideAspectRatio = computed(() => 
  uiStore.windowWidth / uiStore.windowHeight > 1
)

// Add this to store the manual expand/collapse state
const explanationState = ref(false)

// Add this near other refs
const defaultValues = {
  mortality_under_a_poor_maintenance_regime: 30,
  mortality_under_a_good_maintenance_regime: 10,
  post_establishment_mortality_rate: 2,
  mortality_rate_due_to_accidents_and_vandalism: 1,
  inflation_rate: 2.5,
  discount_rate: 7,
  appraisal_period: 30
} as const

// Add computed properties to check if values have changed
const hasChanged = computed(() => ({
  mortality_under_a_poor_maintenance_regime: 
    inputs.value.mortality_under_a_poor_maintenance_regime.value !== defaultValues.mortality_under_a_poor_maintenance_regime,
  mortality_under_a_good_maintenance_regime:
    inputs.value.mortality_under_a_good_maintenance_regime.value !== defaultValues.mortality_under_a_good_maintenance_regime,
  post_establishment_mortality_rate:
    inputs.value.post_establishment_mortality_rate.value !== defaultValues.post_establishment_mortality_rate,
  mortality_rate_due_to_accidents_and_vandalism:
    inputs.value.mortality_rate_due_to_accidents_and_vandalism.value !== defaultValues.mortality_rate_due_to_accidents_and_vandalism,
  inflation_rate:
    inputs.value.inflation_rate.value !== defaultValues.inflation_rate,
  discount_rate:
    inputs.value.discount_rate.value !== defaultValues.discount_rate,
  appraisal_period:
    inputs.value.appraisal_period.value !== defaultValues.appraisal_period
}))

// Add this near other refs
const sliderKeys = ref({
  mortality_under_a_poor_maintenance_regime: 0,
  mortality_under_a_good_maintenance_regime: 0,
  post_establishment_mortality_rate: 0,
  mortality_rate_due_to_accidents_and_vandalism: 0
})

// Update the resetInput function
const resetInput = (field: keyof typeof defaultValues) => {
  handleInputChange(field, defaultValues[field])
  
  // Increment the key for the corresponding slider to force re-render
  if (field in sliderKeys.value) {
    sliderKeys.value[field as keyof typeof sliderKeys.value]++
  }
}

// Add immediate watcher for tree quantities
watch(() => inputs.value.tree_quantities.value, () => {
  // Force chart updates
  if (barChart.value) {
    barChart.value.setOption(plotCashflowCosts(inputs.value))
  }
  if (pieChart.value) {
    pieChart.value.setOption(pieChartOption.value)
  }
}, { deep: true, immediate: true })

// Add function to reset current cost type
const resetCurrentCostType = () => {
  const defaultCosts = tree_costs[volumeKey.value][selectedCostType.value]
  if (!defaultCosts) return

  // Reset all percentiles for the current cost type
  Object.keys(defaultCosts).forEach(percentile => {
    uiStore.updateCostPercentile(
      volumeKey.value,
      selectedCostType.value,
      percentile as keyof CostPercentiles,
      defaultCosts[percentile as keyof CostPercentiles]
    )
  })

  // Force chart updates
  const newResults = calculateResults(inputs.value)
  results.value = newResults
  if (barChart.value) {
    barChart.value.setOption(plotCashflowCosts(inputs.value))
  }
  if (pieChart.value) {
    pieChart.value.setOption(pieChartOption.value)
  }
}

// Add computed to check if current cost type has custom values
const hasCustomValues = computed(() => {
  const defaultCosts = tree_costs[volumeKey.value][selectedCostType.value]
  const currentCosts = uiStore.getCostPercentiles(
    volumeKey.value,
    selectedCostType.value,
    defaultCosts
  )
  
  // Compare each percentile value
  return Object.entries(defaultCosts).some(([key, value]) => 
    value !== currentCosts[key as keyof CostPercentiles]
  )
})

// Update the updateCostsWithPercentile function
const updateCostsWithPercentile = (percentile: number) => {
  const defaultCosts = tree_costs[volumeKey.value][selectedCostType.value]
  if (!defaultCosts) return

  // Get the value for the selected percentile
  const value = defaultCosts[`p${percentile}` as keyof typeof defaultCosts]
  if (typeof value !== 'number') return

  // Update all percentiles to this value
  const percentiles = ['p0', 'p5', 'p25', 'p50', 'p75', 'p95', 'p100']
  percentiles.forEach(p => {
    uiStore.updateCostPercentile(
      volumeKey.value,
      selectedCostType.value,
      p as keyof CostPercentiles,
      value
    )
  })

  // Force recalculation of results and charts
  nextTick(() => {
    const newResults = calculateResults(inputs.value)
    results.value = newResults
    if (barChart.value) {
      barChart.value.setOption(plotCashflowCosts(inputs.value))
    }
    if (pieChart.value) {
      pieChart.value.setOption(pieChartOption.value)
    }
  })
}

// Add helper function to check if a cost type has been customized
const isCostTypeCustomized = (costType: string) => {
  const defaultCosts = tree_costs[volumeKey.value][costType]
  if (!defaultCosts) return false

  const currentCosts = uiStore.getCostPercentiles(
    volumeKey.value,
    costType,
    defaultCosts
  )
  
  // Compare each percentile value
  return Object.entries(defaultCosts).some(([key, value]) => 
    value !== currentCosts[key as keyof typeof currentCosts]
  )
}

// Add this computed property after other computed properties
const totalTreeQuantity = computed(() => {
  return inputs.value.tree_quantities.value.reduce((sum, item) => sum + item.quantity, 0)
})

// Add this computed for the pie chart visibility
const shouldShowPieChart = computed(() => totalTreeQuantity.value > 0)

// 1. First, create a computed property for the analysis output
const maintenanceAnalysisData = computed(() => {
  if (!results.value) return null

  // Structure the output data
  const output = {
    summary: {
      totalLifeCycleCost: results.value.lifeCycleCosts['Total life cycle costs'],
      establishmentCost: results.value.lifeCycleCosts['Establishment'],
      maintenanceCost: results.value.lifeCycleCosts['Inspections and maintenance'],
      mortalityCost: results.value.lifeCycleCosts['Net mortality'],
      appraisalPeriod: inputs.value.appraisal_period.value,
      totalTrees: totalTreeQuantity.value,
      costPerTree: results.value.lifeCycleCosts['Total life cycle costs'] / totalTreeQuantity.value
    },
    financialParameters: {
      inflationRate: inputs.value.inflation_rate.value,
      discountRate: inputs.value.discount_rate.value,
      realDiscountRate: (1 + inputs.value.discount_rate.value/100) / (1 + inputs.value.inflation_rate.value/100) - 1,
    },
    mortalityAssumptions: {
      poorMaintenance: inputs.value.mortality_under_a_poor_maintenance_regime.value,
      goodMaintenance: inputs.value.mortality_under_a_good_maintenance_regime.value,
      postEstablishment: inputs.value.post_establishment_mortality_rate.value,
      accidentsAndVandalism: inputs.value.mortality_rate_due_to_accidents_and_vandalism.value,
      postEstablishmentYears: inputs.value.post_establishment_years.value
    },
    costBreakdown: {
      establishment: {
        description: "Initial investment costs for tree planting",
        total: results.value.lifeCycleCosts['Establishment'],
        components: results.value.establishmentCosts,
        yearlyData: results.value.yearlyData.establishment
      },
      maintenance: {
        description: "Ongoing tree care and inspection costs",
        total: results.value.lifeCycleCosts['Inspections and maintenance'],
        components: results.value.maintenanceCosts,
        yearlyData: results.value.yearlyData.inspections_and_maintenance
      },
      mortality: {
        description: "Tree replacement costs",
        total: results.value.lifeCycleCosts['Net mortality'],
        yearlyData: results.value.yearlyData.net_mortality
      }
    },
    explanations: {
      npv: "All costs are expressed as Net Present Value (NPV), accounting for inflation and discount rates",
      establishment: "One-time costs including site preparation, tree purchase, planting materials and labor",
      maintenance: "Regular care activities like pruning, watering, inspections scaled by maintenance intensity",
      mortality: "Costs of replacing trees that don't survive, with higher rates in early years",
      inflation: `Annual rate of ${inputs.value.inflation_rate.value}% used to adjust future nominal costs`,
      discounting: `Discount rate of ${inputs.value.discount_rate.value}% applied to calculate present value`,
      appraisalPeriod: `Analysis covers ${inputs.value.appraisal_period.value} years of tree life cycle`,
      realDiscountRate: "Accounts for both inflation and time value of money in NPV calculations"
    }
  }

  // 2. Emit the update through shared data
  emit('update:shared-data', {
    metadata: output.metadata,
    analysis: output
  })

  return output
})

// 3. Watch for changes in analysis data and update panel output
watch(maintenanceAnalysisData, (newData) => {
  if (!newData) return

  // Update the panel's output property
  const updatedPanelState = {
    [props.panel.name]: {
      data: {
        output: newData,
        title: `Maintenance Analysis - ${totalTreeQuantity.value} Trees`,
        timestamp: new Date().toISOString()
      }
    }
  }

  // Add the new state to the dock store
  
}, { deep: true })

// 4. Optional: Update output when specific inputs change
watch(() => inputs.value, () => {
  // Recalculate results
  const newResults = calculateResults(inputs.value)
  results.value = newResults

  // This will trigger the maintenanceAnalysisData computed property
  // and subsequently update the panel output
}, { deep: true })
const beamSize = computed(() => Math.floor(windowWidth.value * 1.4))

// Add these lines after other state variables but before the computed properties
// Cost series for our volume-cost relationship chart
const costSeries = ref<CostSeries[]>([
  {
    id: 'tree-installation',
    name: 'Tree Installation',
    color: '#4ADE80', // green-400
    points: [
      { volume: 37.5, cost: 154.75 }, // 25-50L midpoint
      { volume: 87.5, cost: 181.06 }, // 75-100L midpoint
      { volume: 250, cost: 321.88 }   // 250L
    ]
  },
  {
    id: 'soil-cost',
    name: 'Soil Cost',
    color: '#34D399', // emerald-400
    points: [
      { volume: 37.5, cost: 76.50 },
      { volume: 87.5, cost: 89.51 },
      { volume: 250, cost: 159.12 }
    ]
  },
  {
    id: 'mulch-cost',
    name: 'Mulch Cost',
    color: '#2DD4BF', // teal-400
    points: [
      { volume: 37.5, cost: 60.00 },
      { volume: 87.5, cost: 70.20 },
      { volume: 250, cost: 124.80 }
    ]
  },
  {
    id: 'watering',
    name: 'Watering',
    color: '#38BDF8', // sky-400
    points: [
      { volume: 37.5, cost: 4.00 },
      { volume: 87.5, cost: 4.00 },
      { volume: 250, cost: 4.00 }
    ]
  }
])

// Add a watcher to update costs when volume-cost relationships change
watch(costSeries, (newSeries) => {
  // Instead of just logging, actually integrate with calculations
  console.log('Volume-cost relationships updated:', newSeries)
  
  // Force recalculation of results and charts with current costs
  const newResults = calculateResults(inputs.value)
  results.value = newResults
  if (barChart.value) {
    barChart.value.setOption(plotCashflowCosts(inputs.value))
  }
  if (pieChart.value) {
    pieChart.value.setOption(pieChartOption.value)
  }
}, { deep: true })

// Helper to get current cost (either custom or default)
const getCurrentCost = (volumeKey: VolumeKey, costType: string): number => {
  const defaultCosts = tree_costs[volumeKey][costType]
  if (!defaultCosts) return 0

  const customCosts = uiStore.getCostPercentiles(
    volumeKey,
    costType,
    defaultCosts
  )
  return customCosts.p50
}

// New function to interpolate costs between pot sizes using volume-cost relationship
const interpolateCostByVolume = (costType: string, volume: number): number => {
  // If volume is exactly one of our defined pot sizes, use that directly
  if (volume === 25 || volume === 50) return getCurrentCost('volume_25_50L', costType)
  if (volume === 75 || volume === 100) return getCurrentCost('volume_75_100L', costType)
  if (volume === 250) return getCurrentCost('volume_250L', costType)
  
  // Find the appropriate cost series from our modelValue if available
  const series = costSeries.value.find(s => s.name.toLowerCase().includes(costType.toLowerCase()))
  
  if (series && series.points.length >= 2) {
    // Sort points by volume
    const sortedPoints = [...series.points].sort((a, b) => a.volume - b.volume)
    
    // Find the points that bracket our target volume
    let lowerPoint: { volume: number, cost: number } | null = null
    let upperPoint: { volume: number, cost: number } | null = null
    
    for (let i = 0; i < sortedPoints.length - 1; i++) {
      if (sortedPoints[i].volume <= volume && volume <= sortedPoints[i + 1].volume) {
        lowerPoint = sortedPoints[i]
        upperPoint = sortedPoints[i + 1]
        break
      }
    }
    
    // If we found bracketing points, interpolate between them
    if (lowerPoint && upperPoint) {
      // Determine which relationship type we're using
      const relType = series.id.includes('linear') ? 'linear' : 
                     series.id.includes('power') ? 'power' : 
                     series.id.includes('exponential') ? 'exponential' : 'power'
      
      // Apply appropriate interpolation formula
      switch (relType) {
        case 'linear':
          // Linear interpolation
          const ratio = (volume - lowerPoint.volume) / (upperPoint.volume - lowerPoint.volume)
          return lowerPoint.cost + ratio * (upperPoint.cost - lowerPoint.cost)
        
        case 'power':
          // Power law interpolation
          const exponent = Math.log(upperPoint.cost / lowerPoint.cost) / 
                          Math.log(upperPoint.volume / lowerPoint.volume)
          return lowerPoint.cost * Math.pow(volume / lowerPoint.volume, exponent)
        
        case 'exponential':
          // Exponential interpolation
          const logFactor = Math.log(upperPoint.cost / lowerPoint.cost) / 
                          (upperPoint.volume - lowerPoint.volume)
          return lowerPoint.cost * Math.exp(logFactor * (volume - lowerPoint.volume))
        
        default:
          // Default to linear
          const defaultRatio = (volume - lowerPoint.volume) / (upperPoint.volume - lowerPoint.volume)
          return lowerPoint.cost + defaultRatio * (upperPoint.cost - lowerPoint.cost)
      }
    }
  }
  
  // Fallback to discrete interpolation between our standard pot sizes
  if (volume < 75) {
    // Interpolate between 25-50L and 75-100L
    const ratio = (volume - 25) / 50
    return getCurrentCost('volume_25_50L', costType) * (1 - ratio) + 
           getCurrentCost('volume_75_100L', costType) * ratio
  } else if (volume < 250) {
    // Interpolate between 75-100L and 250L
    const ratio = (volume - 75) / 175
    return getCurrentCost('volume_75_100L', costType) * (1 - ratio) + 
           getCurrentCost('volume_250L', costType) * ratio
  } else {
    // Extrapolate beyond 250L using power law (conservative approach)
    const ratio = volume / 250
    return getCurrentCost('volume_250L', costType) * Math.pow(ratio, 0.7) // Using 0.7 as a typical scaling exponent
  }
}

// Enhanced getCurrentCost function that can handle arbitrary volumes
const getVolumeBasedCost = (volume: number, costType: string): number => {
  // First try using the more accurate volume-cost relationship
  if (costSeries.value.length > 0) {
    return interpolateCostByVolume(costType, volume)
  }
  
  // If no relationship data, fall back to discrete pot sizes
  if (volume <= 50) {
    return getCurrentCost('volume_25_50L', costType)
  } else if (volume <= 100) {
    return getCurrentCost('volume_75_100L', costType)
  } else {
    return getCurrentCost('volume_250L', costType)
  }
}

// Helper to get the closest VolumeKey for a given volume
const getVolumeKeyForSize = (volume: number): VolumeKey => {
  if (volume <= 50) return 'volume_25_50L'
  if (volume <= 100) return 'volume_75_100L'
  return 'volume_250L'
}

// Updated calculateEstablishmentCosts to use the volume-based approach
const calculateEstablishmentCosts = (inputs: MaintenanceInputs) => {
  let totalCost = 0
  let concreteCuttingCost = 0
  let supplyCost = 0
  let treeInstallationCost = 0
  let soilCost = 0
  let mulchCost = 0
  let stakesAndTiesCost = 0
  let treeProtectionFencingCost = 0
  
  // Calculate costs for each tree quantity entry
  inputs.tree_quantities.value.forEach(item => {
    // Get the actual volume for this pot size (use midpoint of range)
    const volumeMap: Record<PotSizeKey, number> = { 
      '25-50L': 37.5, 
      '75-100L': 87.5, 
      '250L': 250 
    }
    const volume = volumeMap[item.size]
    
    // Map pot size to volume key for fallback
    const volumeKey = pot_size_mapping[item.size]
    
    // Calculate concrete cutting cost if applicable
    if (inputs.number_of_trees_requiring_concrete_cutting?.value) {
      const perTreeCost = getVolumeBasedCost(volume, 'concrete_cutting')
      const treesRequiringCutting = Math.min(
        inputs.number_of_trees_requiring_concrete_cutting.value,
        item.quantity
      )
      concreteCuttingCost += perTreeCost * treesRequiringCutting
    }
    
    // Calculate supply cost
    supplyCost += getVolumeBasedCost(volume, 'supply') * item.quantity
    
    // Calculate tree installation cost
    treeInstallationCost += getVolumeBasedCost(volume, 'tree_installation') * item.quantity
    
    // Calculate soil cost
    if (inputs.volume_of_required_soil?.value) {
      soilCost += getVolumeBasedCost(volume, 'soil_cost') * 
                 inputs.volume_of_required_soil.value * item.quantity
    }
    
    // Calculate mulch cost
    if (inputs.volume_of_mulch_required?.value) {
      mulchCost += getVolumeBasedCost(volume, 'mulch_cost') * 
                  inputs.volume_of_mulch_required.value * item.quantity
    }
    
    // Calculate stakes and ties cost
    stakesAndTiesCost += getVolumeBasedCost(volume, 'stakes_and_ties') * item.quantity
    
    // Calculate tree protection fencing cost
    treeProtectionFencingCost += getVolumeBasedCost(volume, 'tree_protection_fencing') * item.quantity
  })
  
  // Calculate total establishment cost
  totalCost = concreteCuttingCost + supplyCost + treeInstallationCost + 
             soilCost + mulchCost + stakesAndTiesCost + treeProtectionFencingCost
  
  // Return establishment costs
  return {
    "Concrete cutting": concreteCuttingCost,
    "Supply": supplyCost,
    "Tree installation": treeInstallationCost,
    "Soil cost": soilCost,
    "Mulch cost": mulchCost,
    "Stakes and ties": stakesAndTiesCost,
    "Tree protection fencing": treeProtectionFencingCost,
    "Total establishment costs": totalCost
  }
}

// We'll also need to update other cost calculations like maintenance costs
// to use the volume-based approach, but I'll focus on establishment for now

// Add this near other input refs
const customPotSize = ref([75]) // Default value between standard sizes as an array

// Add a computed to map current custom pot size value to a human-readable label
const customPotSizeLabel = computed(() => `${customPotSize.value[0]}L`)

// Add this to handle custom pot size changes
const handleCustomPotSizeChange = (newSize: number[]) => {
  customPotSize.value = newSize
  
  // Update tree quantities with the custom size
  const currentTreeQuantities = [...inputs.value.tree_quantities.value]
  
  // If we have quantities, use the first entry to set our custom size
  if (currentTreeQuantities.length) {
    // Map custom pot size to closest standard size key for compatibility
    let sizeKey: PotSizeKey = '25-50L'
    const size = newSize[0] // Get the first value from the array
    if (size > 50 && size <= 100) sizeKey = '75-100L'
    else if (size > 100) sizeKey = '250L'
    
    // Use the existing quantity but with our custom volume
    const quantity = currentTreeQuantities[0].quantity
    
    // Create updated tree quantities with our custom size
    const updatedQuantities = [{
      size: sizeKey,
      quantity
    }]
    
    // Update inputs with the custom quantity
    updateInputs({
      tree_quantities: { value: updatedQuantities }
    })
    
    // Force calculations to use our custom volume
    const customVolumeResults = calculateResults(inputs.value, newSize[0])
    results.value = customVolumeResults
    
    // Update charts
    if (barChart.value) {
      barChart.value.setOption(plotCashflowCosts(inputs.value, newSize[0]))
    }
    if (pieChart.value) {
      pieChart.value.setOption(pieChartOption.value)
    }
  }
}

</script>

<template>
    
      <BorderBeam style="pointer-events: none !important" v-if="includeDataInChat && activePanel === panel_id" borderRadius="10px" :anchor="90" :size="beamSize" />
      <!-- <GodRays class="mt-0 w-full h-[calc(100%-12px)] rounded-b-lg pointer-events-none mix-blend-multiply" :colors="['#f97316', '#fd7e14', '#e44c65', '#c026d3', '#a855f7']" v-if="includeDataInChat && activePanel === panel_id && chatDialogOpen"   /> -->

      <div class="overflow-hidden w-full h-full p-0 m-0 bg-muted/40">
        
          <div class="flex flex-wrap justify-center align-center items-center w-full h-full">
              <div class="flex w-full h-full">
                <!-- Settings panel - collapsible -->
                <div 
                  :class="[
                    'transition-all duration-300 ease-in-out overflow-hidden relative mt-1',
                    isSettingsCollapsed ? 'w-24' : 'w-80'
                  ]"
                >
                  <!-- Move button outside ScrollArea and position it absolutely -->
                  <Button 
                    variant="text" 
                    class="absolute top-2 h-7 z-20 pointer-events-auto"
                    :class="[
                      isSettingsCollapsed ? 'left-0' : 'right-0'
                    ]"
                    @click="handleSettingsCollapse"
                  >
                    <span class="pr-2 ml-2 tracking-tight font-regular text-sm text-muted-foreground" v-if="isSettingsCollapsed">Settings</span>
                    <Icon 
                      :name="isSettingsCollapsed ? 'lucide:chevron-right' : 'lucide:chevron-left'" 
                      class="h-4 w-4 text-muted-foreground"
                    />
                  </Button>

                  <ScrollArea class="h-full mb-24 mt-12"> <!-- Add pt-12 to account for absolute button -->
                    <!-- Settings content -->
                    <div 
                      :class="[
                        'transition-all duration-300 ease-in-out',
                        isSettingsCollapsed ? 'opacity-0' : 'opacity-100 p-3'
                      ]"
                    >
                      <div class="mb-4">
                        <Button 
                          variant="ghost" 
                          class="px-2 ml-0 text-sm w-full text-left flex items-center justify-between bg-background/70 rounded-lg hover:bg-background"
                          @click="explanationState = !explanationState"
                        >
                          <span class="tracking-tight font-regular text-muted-foreground">Tree Costing Explained</span>
                          <Icon 
                            :name="explanationState ? 'lucide:chevron-up' : 'lucide:chevron-down'" 
                            class="h-4 w-4 text-muted-foreground"
                          />
                        </Button>
                        
                        <Transition
                          enter-active-class="transition ease-out duration-200"
                          enter-from-class="opacity-0 -translate-y-1"
                          enter-to-class="opacity-100 translate-y-0"
                          leave-active-class="transition ease-in duration-150"
                          leave-from-class="opacity-100 translate-y-0"
                          leave-to-class="opacity-0 -translate-y-1"
                        >
                          <div v-if="explanationState">
                            <p 
                              class="leading-6 [&:not(:first-child)]:mt-2 text-muted-foreground text-sm py-4 pl-2"
                            >
                              These charts display lifetime costs from initial planting through ongoing maintenance. 
                              The figures are adjusted for inflation to help plan realistic budgets and justify funding. 
                              You can use this data to compare planting options, understand true maintenance costs, and develop 
                              business cases for your tree projects.
                            </p>
                            <p 
                              class="leading-6 [&:not(:first-child)]:mt-2 text-muted-foreground text-sm py-4 pl-2"
                            >
                              The tool factors in tree mortality costs, showing the relationship between establishment methods, maintenance strategies, and survival rates. This helps you evaluate the trade-offs between initial investment in quality planting and long-term replacement costs, giving you a more complete picture of your project's true lifecycle costs.
                            </p>
                          </div>
                        </Transition>
                      </div>

                      <Accordion 
                        v-model="openSections"
                        class="space-y-2 text-sm sm:space-y-4"
                      >
                        <!-- Physical Parameters Section -->
                        <AccordionItem value="physical-params" class="rounded-lg">
                          <AccordionTrigger class="px-3 py-2 text-sm">Quantity</AccordionTrigger>
                          <AccordionContent class="p-3">
                            <div class="space-y-4">
                              <!-- <Label class="text-xs">Tree Quantities by Pot Size</Label> -->
                              <PotSizeSelector
                                v-model="inputs.tree_quantities.value"
                                @update:model-value="(val) => handleInputChange('tree_quantities', val)"
                              />

                              <!-- Add the custom pot size slider below the tree quantity inputs -->
                              <div class="space-y-4 mt-4">
                                <div class="flex justify-between items-center">
                                  <div class="text-sm font-medium">Custom Pot Size: {{ customPotSizeLabel }}</div>
                                  <Button 
                                    variant="ghost" 
                                    size="sm" 
                                    class="h-7 w-7 ml-2"
                                    @click="customPotSize = [75]"
                                    v-tooltip="'Reset to default'"
                                  >
                                    <Icon name="carbon:reset" class="h-4 w-4" />
                                  </Button>
                                </div>
                                <Slider
                                  :model-value="customPotSize"
                                  @update:model-value="handleCustomPotSizeChange"
                                  :min="25"
                                  :max="250"
                                  :step="1"
                                  class="mb-2"
                                />
                                <div class="flex justify-between text-xs text-muted-foreground">
                                  <span>25L</span>
                                  <span>75L</span>
                                  <span>250L</span>
                                </div>
                                <div class="text-xs text-muted-foreground bg-muted/50 p-2 rounded-lg mt-2">
                                  Use this slider to test costs for non-standard pot sizes. Costs are calculated using your volume-cost relationships.
                                </div>
                              </div>
                            </div>
                          </AccordionContent>
                        </AccordionItem>

                        <!-- Mortality Rates Section -->
                        <AccordionItem value="mortality" class="rounded-lg">
                          <AccordionTrigger class="px-3 py-2 text-sm">Survival</AccordionTrigger>
                          <AccordionContent class="p-3">
                            <div class="space-y-6">
                              <!-- Poor maintenance mortality -->
                              <div>
                                <div class="flex justify-between items-center mb-2">
                                  <Label class="text-xs">Poor Maintenance</Label>
                                  <div class="flex items-center gap-1 w-[60px] justify-end">
                                    <span class="text-xs text-muted-foreground">
                                      {{ inputs.mortality_under_a_poor_maintenance_regime.value }}%
                                    </span>
                                    <div class="w-6" v-auto-animate>
                                      <Button 
                                        v-if="hasChanged.mortality_under_a_poor_maintenance_regime"
                                        variant="ghost" 
                                        size="icon" 
                                        class="h-5 w-5 -mt-3 absolute"
                                        @click="resetInput('mortality_under_a_poor_maintenance_regime')"
                                      >
                                        <Icon name="carbon:reset" class="h-3 w-3" />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <BalanceSlider
                                  :key="sliderKeys.mortality_under_a_poor_maintenance_regime"
                                  v-model="inputs.mortality_under_a_poor_maintenance_regime.value"
                                  :initial-value="Number(inputs.mortality_under_a_poor_maintenance_regime.value)"
                                  left-content="Die"
                                  right-content="Survive"
                                  left-color="#FB7185"
                                  right-color="#22C55E"
                                  indicator-color="#94A3B8"
                                  @update:model-value="(val) => handleInputChange('mortality_under_a_poor_maintenance_regime', val)"
                                />
                              </div>

                              <!-- Good maintenance mortality -->
                              <div>
                                <div class="flex justify-between items-center mb-2">
                                  <Label class="text-xs">Good Maintenance</Label>
                                  <div class="flex items-center gap-1 w-[60px] justify-end">
                                    <span class="text-xs text-muted-foreground">
                                      {{ inputs.mortality_under_a_good_maintenance_regime.value }}%
                                    </span>
                                    <div class="w-6" v-auto-animate>
                                      <Button 
                                        v-if="hasChanged.mortality_under_a_good_maintenance_regime"
                                        variant="ghost" 
                                        size="icon" 
                                        class="h-5 w-5 -mt-3 absolute"
                                        @click="resetInput('mortality_under_a_good_maintenance_regime')"
                                      >
                                        <Icon name="carbon:reset" class="h-3 w-3" />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <BalanceSlider
                                  :key="sliderKeys.mortality_under_a_good_maintenance_regime"
                                  v-model="inputs.mortality_under_a_good_maintenance_regime.value"
                                  :initial-value="Number(inputs.mortality_under_a_good_maintenance_regime.value)"
                                  left-content="Die"
                                  right-content="Survive"
                                  left-color="#FB7185"
                                  right-color="#22C55E"
                                  indicator-color="#94A3B8"
                                  @update:model-value="(val) => handleInputChange('mortality_under_a_good_maintenance_regime', val)"
                                />
                              </div>

                              <!-- Post-establishment mortality -->
                              <div>
                                <div class="flex justify-between items-center mb-2">
                                  <Label class="text-xs">Post-establishment</Label>
                                  <div class="flex items-center gap-1 w-[60px] justify-end">
                                    <span class="text-xs text-muted-foreground">
                                      {{ inputs.post_establishment_mortality_rate.value }}%
                                    </span>
                                    <div class="w-6" v-auto-animate>
                                      <Button 
                                        v-if="hasChanged.post_establishment_mortality_rate"
                                        variant="ghost" 
                                        size="icon" 
                                        class="h-5 w-5 -mt-3 absolute"
                                        @click="resetInput('post_establishment_mortality_rate')"
                                      >
                                        <Icon name="carbon:reset" class="h-3 w-3" />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <BalanceSlider
                                  :key="sliderKeys.post_establishment_mortality_rate"
                                  v-model="inputs.post_establishment_mortality_rate.value"
                                  :initial-value="Number(inputs.post_establishment_mortality_rate.value)"
                                  left-content="Die"
                                  right-content="Survive"
                                  left-color="#FB7185"
                                  right-color="#22C55E"
                                  indicator-color="#94A3B8"
                                  @update:model-value="(val) => handleInputChange('post_establishment_mortality_rate', val)"
                                />
                              </div>

                              <!-- Accidents and Vandalism mortality -->
                              <div>
                                <div class="flex justify-between items-center mb-2">
                                  <Label class="text-xs">Accidents & Vandalism</Label>
                                  <div class="flex items-center gap-1 w-[60px] justify-end">
                                    <span class="text-xs text-muted-foreground">
                                      {{ inputs.mortality_rate_due_to_accidents_and_vandalism.value }}%
                                    </span>
                                    <div class="w-6" v-auto-animate>
                                      <Button 
                                        v-if="hasChanged.mortality_rate_due_to_accidents_and_vandalism"
                                        variant="ghost" 
                                        size="icon" 
                                        class="h-5 w-5 -mt-3 absolute"
                                        @click="resetInput('mortality_rate_due_to_accidents_and_vandalism')"
                                      >
                                        <Icon name="carbon:reset" class="h-3 w-3" />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <BalanceSlider
                                  :key="sliderKeys.mortality_rate_due_to_accidents_and_vandalism"
                                  v-model="inputs.mortality_rate_due_to_accidents_and_vandalism.value"
                                  :initial-value="Number(inputs.mortality_rate_due_to_accidents_and_vandalism.value)"
                                  left-content="Die"
                                  right-content="Survive"
                                  left-color="#FB7185"
                                  right-color="#22C55E"
                                  indicator-color="#94A3B8"
                                  @update:model-value="(val) => handleInputChange('mortality_rate_due_to_accidents_and_vandalism', val)"
                                />
                              </div>
                            </div>
                          </AccordionContent>
                        </AccordionItem>

                        <!-- Price Ranges Section -->
                        <AccordionItem value="prices" class="rounded-lg">
                          <AccordionTrigger class="px-3 py-2 text-sm">Costs</AccordionTrigger>
                          <AccordionContent class="p-3">
                            <div class="grid grid-cols-2 gap-3 mb-10">
                              <!-- Inflation Rate -->
                              <div class="col-span-1">
                                <div class="flex justify-between mb-1">
                                  <Label class="text-xs">Inflation</Label>
                                  <div class="flex items-center gap-2">
                                    <span class="text-xs text-muted-foreground">
                                      {{ inputs.inflation_rate.value }}%
                                    </span>
                                    <div class="w-6" v-auto-animate>
                                      <Button 
                                        v-if="hasChanged.inflation_rate"
                                        variant="ghost" 
                                        size="icon" 
                                        class="h-5 w-5 -mt-3 absolute"
                                        @click="resetInput('inflation_rate')"
                                      >
                                        <Icon name="carbon:reset" class="h-3 w-3" />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <Slider 
                                  :model-value="[inputs.inflation_rate.value]"
                                  @update:model-value="(val) => handleInputChange('inflation_rate', val?.[0])"
                                  :min="0" 
                                  :max="10" 
                                  :step="0.1" 
                                  class="w-full"
                                />
                              </div>

                              <!-- Discount Rate -->
                              <div class="col-span-1">
                                <div class="flex justify-between mb-1">
                                  <Label class="text-xs">Discount</Label>
                                  <div class="flex items-center gap-2">
                                    <span class="text-xs text-muted-foreground">
                                      {{ inputs.discount_rate.value }}%
                                    </span>
                                    <div class="w-6" v-auto-animate>
                                      <Button 
                                        v-if="hasChanged.discount_rate"
                                        variant="ghost" 
                                        size="icon" 
                                        class="h-5 w-5 -mt-3 absolute"
                                        @click="resetInput('discount_rate')"
                                      >
                                        <Icon name="carbon:reset" class="h-3 w-3" />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <Slider 
                                  :model-value="[inputs.discount_rate.value]"
                                  @update:model-value="(val) => handleInputChange('discount_rate', val?.[0])"
                                  :min="0" 
                                  :max="20" 
                                  :step="0.5" 
                                  class="w-full"
                                />
                              </div>

                              <!-- Appraisal Period -->
                              <div class="col-span-2">
                                <div class="flex justify-between mb-1">
                                  <Label class="text-xs">Appraisal Period</Label>
                                  <div class="flex items-center gap-2">
                                    <span class="text-xs text-muted-foreground">
                                      {{ inputs.appraisal_period.value }} years
                                    </span>
                                    <div class="w-6" v-auto-animate>
                                      <Button 
                                        v-if="hasChanged.appraisal_period"
                                        variant="ghost" 
                                        size="icon" 
                                        class="h-5 w-5 -mt-3 absolute"
                                        @click="resetInput('appraisal_period')"
                                      >
                                        <Icon name="carbon:reset" class="h-3 w-3" />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <Slider 
                                  :model-value="[inputs.appraisal_period.value]"
                                  @update:model-value="(val) => handleInputChange('appraisal_period', val?.[0] ?? 30)"
                                  :min="5" 
                                  :max="50" 
                                  :step="5" 
                                  class="w-full"
                                />
                              </div>

                              <!-- Add Volume-Cost Relationship Chart -->
                              <div class="col-span-2 mt-6 border-t border-border/50 pt-6">
                                <div class="flex justify-between mb-3">
                                  <Label class="text-sm font-medium">Volume-Cost Relationship</Label>
                                </div>
                                
                                <div class="text-xs text-muted-foreground mb-4">
                                  <p>
                                    Customize how tree volume (pot size) affects different cost categories. 
                                    Drag points to adjust the relationship or select a curve type.
                                  </p>
                                </div>
                                
                                <!-- Use our new VolumeCostRelationship component -->
                                <VolumeCostRelationship
                                  v-model="costSeries"
                                  :min-volume="25"
                                  :max-volume="250"
                                />
                                
                               
                              </div>
                            </div>

                            <div class="space-y-4 pb-20">
                              <div class="flex justify-between mb-1 items-center">
                                
                                <div class="flex items-center gap-2 w-full" v-auto-animate>
                                  <Select v-model="selectedCostType" class="w-40">
                                    <SelectTrigger class="h-auto py-1">
                                      <SelectValue>
                                        <div class="truncate text-left">
                                          <div class="font-medium truncate">{{ formatCostLabel(selectedCostType) }}</div>
                                          <p class="text-[0.7em] leading-[1.2em] text-muted-foreground truncate text-left">
                                            {{ costDescriptions[selectedCostType as keyof typeof costDescriptions] }}
                                          </p>
                                        </div>
                                      </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent class="max-h-[50vh] overflow-y-auto">
                                      <SelectItem 
                                        v-for="(costs, key) in tree_costs[volumeKey]" 
                                        :key="key" 
                                        :value="key"
                                        :class="[
                                          isCostTypeCustomized(key) ? 'bg-muted-foreground/10' : ''
                                        ]"
                                      >
                                        <div class="flex flex-col">
                                          <span class="truncate">{{ formatCostLabel(key) }}</span>
                                          <span class="text-[0.7em] leading-[1.2em] text-muted-foreground">
                                            {{ costDescriptions[key as keyof typeof costDescriptions] }}
                                          </span>
                                        </div>
                                      </SelectItem>
                                    </SelectContent>
                                  </Select>
                                  <div v-if="hasCustomValues" class="w-7"> <!-- Container for reset button -->
                                    <Button 
                                      
                                      variant="ghost" 
                                      size="icon" 
                                      class="h-7 w-7"
                                      @click="resetCurrentCostType"
                                      v-tooltip="'Reset to default values'"
                                    >
                                      <Icon name="carbon:reset" class="h-4 w-4" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              
                              <div class="h-[200px]">
                                <LineChart
                                  ref="priceChart"
                                  :data="priceChartData"
                                  :options="priceChartOptions"
                                />
                              </div>
                              
                              <!-- Add explanation -->
                              <div class="text-xs text-muted-foreground space-y-2 bg-muted/50 p-3 rounded-lg">
                                <p class="font-medium">About Cost Distribution</p>
                                <p>
                                  This chart shows the range of costs from lowest (p0) to highest (p100) observed in the market. 
                                  The middle point (p50) represents the median cost.
                                </p>
                                <p>
                                  <span class="font-regular">Drag the points</span> to adjust costs based on your local market conditions. 
                                  Changes will automatically update the cash flow and lifecycle cost calculations.
                                </p>
                                <Accordion type="single" collapsible>
                                    <AccordionItem value="maintenance-details">
                                      <AccordionTrigger class="text-xs text-left">Learn more</AccordionTrigger>
                                      <AccordionContent class="text-xs">
                                        <p>
                                          While urban tree establishment involves significant upfront costs, strategic choices can optimize long-term value. Smaller trees allow for easier transport and proactive maintenance, species-appropriate watering systems reduce establishment failures, and thoughtful planning around infrastructure and community needs minimizes future conflicts. These proactive approaches, though initially more expensive, typically prove more cost-effective than reactive management over a tree's lifetime.
                                        </p>
                                       
                                      </AccordionContent>
                                    </AccordionItem>
                                  </Accordion>
                              </div>
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </ScrollArea>
                </div>

                <!-- Main content area - grows to fill space -->
                <div class="flex-1 transition-all duration-300 min-w-0">
                  <div class="flex flex-col h-full p-0 sm:px-6 px-4 pt-2">
                    <template v-if="isNarrowScreen">
                      <Tabs v-model="activeTab" class="h-full w-full">
                        <TabsList class="grid w-full grid-cols-2">
                          <TabsTrigger value="cashflow" class="min-w-0 overflow-hidden">
                            <span class="truncate block w-full">Cash Flow</span>
                            
                          </TabsTrigger>
                          <TabsTrigger value="distribution" class="min-w-0 overflow-hidden">
                            <span class="truncate block w-full">Life Cycle Costs</span>
                          </TabsTrigger>
                        </TabsList>
                        
                        <TabsContent value="cashflow" class="h-[calc(100%-40px)] w-full bg-background squircle-20 p-10">
                          <div class="chart-container h-full w-full">
                            <VChart 
                              ref="barChart" 
                              :option="localChartOption" 
                              :autoresize="true" 
                              class="!w-full !h-full"
                              @mousemove="onChartMouseMove"
                              @mouseout="onChartMouseOut"
                            />
                          </div>
                        </TabsContent>
                        
                        <TabsContent value="distribution" class="h-[calc(100%-40px)] w-full bg-background squircle-20 p-10">
                          <div class="chart-container h-full w-full">
                            <template v-if="shouldShowPieChart">
                              <VChart 
                                ref="pieChart" 
                                :option="pieChartOption" 
                                :autoresize="true"
                                class="!w-full !h-full"
                              />
                            </template>
                            <template v-else>
                              <div class="flex items-center justify-center h-full text-muted-foreground">
                                Add trees to see life cycle costs
                              </div>
                            </template>
                          </div>
                        </TabsContent>
                      </Tabs>
                    </template>

                    <template v-else>
                      <div class="flex flex-col h-full">
                        <!-- Charts grid directly -->
                        <div 
                          :class="[
                            'h-full w-full gap-4 p-6',
                            uiStore.windowWidth >= 1280 && isWideAspectRatio 
                              ? 'grid grid-cols-2' 
                              : uiStore.windowHeight > 1200 
                                ? 'grid grid-rows-2' 
                                : 'grid grid-cols-2'
                          ]"
                        >
                          <div class="bg-background squircle-20 p-10 min-h-0 flex flex-col h-full">
                            <h3 class="text-lg font-semibold text-muted-foreground mb-4">Cash Flow</h3>
                            <div class="flex-1 min-h-0 w-full relative">
                              <VChart 
                                ref="barChart" 
                                :option="localChartOption" 
                                :autoresize="true" 
                                class="!w-full !h-full absolute inset-0"
                                @mousemove="onChartMouseMove"
                                @mouseout="onChartMouseOut"
                              />
                            </div>
                          </div>
                          
                          <div class="bg-background squircle-20 p-10 min-h-0 flex flex-col h-full">
                            <h3 class="text-lg font-semibold text-muted-foreground mb-4">Life Cycle Costs</h3>
                            <div class="flex-1 min-h-0 w-full relative">
                              <template v-if="shouldShowPieChart">
                                <VChart 
                                  ref="pieChart" 
                                  :option="pieChartOption" 
                                  :autoresize="true"
                                  class="!w-full !h-full absolute inset-0"
                                />
                              </template>
                              <template v-else>
                                <div class="flex items-center justify-center h-full text-muted-foreground">
                                  Add trees to see life cycle costs
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

          </div>
        
      </div>
</template>

<style>
.chart-container {
  @apply w-full h-full flex justify-center items-center min-h-0 min-w-0 relative;
}

/* Force VChart to respect container dimensions */
:deep(.echarts) {
  width: 100% !important;
  height: 100% !important;
  min-width: 0 !important;
  min-height: 0 !important;
  position: absolute !important;
  inset: 0 !important;
}

.echarts-tooltip-dark {
  @apply bg-white/90 backdrop-blur-sm rounded-lg shadow-lg !important;
  pointer-events: all !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 12px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.echarts-tooltip-dark .flex {
  @apply hover:bg-gray-50/50 transition-colors duration-150;
}

.no-data-message {
  font-size: 16px;
  @apply text-primary;
}

/* Add some spacing between accordion items */
.accordion-item + .accordion-item {
  margin-top: 0.5rem;
}

/* Style the accordion content */
.accordion-content {
  padding: 1rem;
  @apply bg-background rounded-lg;
}

/* Add smooth transition for width changes */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Prevent content from wrapping during collapse animation */
.overflow-hidden {
  overflow: hidden;
}

/* Add to your existing styles */
[role="tab"] {
  @apply min-w-0 px-2 overflow-hidden; /* Add overflow-hidden */
}

[role="tab"] span {
  @apply block truncate w-full; /* Add w-full */
}

/* Remove or replace the hover style that was causing issues */
[role="tab"]:hover span {
  @apply truncate; /* Keep truncate on hover */
}

/* Optional: Add title attribute for tooltip */
[role="tab"] {
  @apply relative;
}


</style>
