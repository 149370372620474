<!-- Create new shared component -->
<script setup lang="ts">
import type { GridApi } from 'ag-grid-community'
import type { Panel } from '@/types/projectdock'
import { storeToRefs } from 'pinia'
import { useDockStore } from '@/stores/dockStore'
import { Button } from '@/components/ui/button'
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuLabel,
  DropdownMenuCheckboxItem
} from '@/components/ui/dropdown-menu'

const props = defineProps<{
  panel: Panel
  gridApi?: GridApi
  selectedRowCount?: number
  undoAvailable?: boolean
  redoAvailable?: boolean
  onAddRow: () => void
  onDeleteSelectedRows: () => void
  onRefresh: () => void
  useFitContents?: boolean
  zoomLevel?: number
  onZoomIn?: () => void
  onZoomOut?: () => void
  onToggleSizing?: () => void
  minZoom?: number
  maxZoom?: number
  onUndo?: () => void
  onRedo?: () => void
}>()

const emit = defineEmits<{
  'update:validationTools': [enabledTools: string[]]
}>()

// Add store
const dockStore = useDockStore()
const { defaultApiService } = storeToRefs(dockStore)

// Define validation tools
const validationTools = [
  { id: 'name-validation-tool', label: 'Name Validation' },
  { id: 'biosecurity-validation-tool', label: 'Biosecurity Validation' },
  { id: 'diversity-validation-tool', label: 'Diversity Validation' },
  { id: 'availability-validation-tool', label: 'Availability Validation' }
]

// Track enabled validation tools (include all to begin with)
const enabledValidationTools = ref<string[]>(validationTools.map(tool => tool.id))

// Initialize from panel data if available
onMounted(() => {
  if (props.panel.data?.options?.enabledValidationTools) {
    enabledValidationTools.value = props.panel.data.options.enabledValidationTools
  }
  
  // Emit the initial validation tools state to ensure parent component is aware
  emit('update:validationTools', enabledValidationTools.value)
})

// Function to toggle validation tool
const toggleValidationTool = (toolId: string) => {
  if (enabledValidationTools.value.includes(toolId)) {
    enabledValidationTools.value = enabledValidationTools.value.filter(id => id !== toolId)
  } else {
    enabledValidationTools.value.push(toolId)
  }

  // Update panel data if needed
  if (!props.panel.data.options) {
    props.panel.data.options = {}
  }
  props.panel.data.options.enabledValidationTools = enabledValidationTools.value
  
  // Emit update event
  emit('update:validationTools', enabledValidationTools.value)
}

// Default values for zoom props
const MIN_ZOOM = props.minZoom || 0.5
const MAX_ZOOM = props.maxZoom || 2

// Add selectedApiService computed
const selectedApiService = computed({
  get: () => {
    // First try to get from panel data
    const panelService = props.panel.data?.options?.apiService
    if (panelService) return panelService
    
    // Otherwise use store default
    return defaultApiService.value
  },
  set: (value) => {
    // Update both panel and store
    if (!props.panel.data.options) {
      props.panel.data.options = {}
    }
    props.panel.data.options.apiService = value
    dockStore.setDefaultApiService(value)
  }
})
</script>

<template>
  <div class="absolute top-2 right-2 z-10 flex items-center gap-2">
    <!-- Zoom and sizing controls - moved to the left side -->
    <div class="flex items-center" v-if="onZoomIn && onZoomOut && onToggleSizing">
      <div class="relative group/zoom">
        <Button
          variant="ghost"
          size="icon"
          class="rounded-full translate-x-16 opacity-0 group-hover/zoom:opacity-100 group-hover/zoom:translate-x-0 transition-all duration-300 absolute right-20"
          :class="[useFitContents ? 'text-primary' : 'text-muted-foreground']"
          @click="onToggleSizing"
        >
          <Icon name="fluent:auto-fit-width-20-filled" class="w-4 h-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          class="rounded-full ml-2"
          @click="onZoomIn"
          :disabled="zoomLevel >= MAX_ZOOM"
        >
          <Icon name="lucide:zoom-in" class="w-4 h-4" />
        </Button>
        
        <Button
          variant="ghost"
          size="icon"
          class="rounded-full translate-x-8 opacity-0 group-hover/zoom:opacity-100 group-hover/zoom:translate-x-0 transition-all duration-300 absolute right-10"
          @click="onZoomOut"
          :disabled="zoomLevel <= MIN_ZOOM"
        >
          <Icon name="lucide:zoom-out" class="w-4 h-4" />
        </Button>
      </div>
    </div>
    
    <!-- Add undo/redo buttons before the Add Row button -->
    <div class="flex items-center gap-1">
      <Button
        variant="ghost"
        size="sm"
        class="rounded-full"
        @click="onUndo"
        :disabled="!undoAvailable"
        title="Undo (Ctrl+Z)"
      >
        <Icon name="solar:undo-left-round-linear" class="w-4 h-4" />
      </Button>
      
      <Button
        variant="ghost"
        size="sm"
        class="rounded-full"
        @click="onRedo"
        :disabled="!redoAvailable"
        title="Redo (Ctrl+Y)"
      >
        <Icon name="solar:undo-right-round-linear" class="w-4 h-4" />
      </Button>
    </div>
    
    <Button
      variant="ghost"
      size="sm"
      class="rounded-full"
      @click="onAddRow"
    >
      <Icon name="lucide:plus" class="w-4 h-4 mr-2" />
      <span>Add Row</span>
    </Button>

    <!-- Preload trash icon by always rendering it but hiding button -->
    <Button
      variant="ghost"
      size="sm"
      class="rounded-full"
      :class="{ 'hidden': !selectedRowCount }"
      @click="onDeleteSelectedRows"
    >
      <Icon name="lucide:trash-2" class="w-4 h-4 mr-2" preload />
      <span>Delete {{ selectedRowCount > 1 ? `${selectedRowCount} Rows` : 'Row' }}</span>
    </Button>
    
    <DropdownMenu>
      <DropdownMenuTrigger as="div">
        <Button size="sm" variant="ghost"
                class="self-center rounded-full w-8 h-8 p-0">
          <Icon name="mdi:dots-vertical" size="1.5em" class="w-5 h-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent class="min-w-[220px] rounded-xl p-4 space-y-2">
          <DropdownMenuLabel class="text-sm font-[400] font-intervariable select-none">
            Converted PDF with
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup v-model="selectedApiService" class="space-y-2">
            <DropdownMenuRadioItem value="gemini" class="hover:bg-muted-foreground/10 py-3" :class="{'bg-muted-foreground/10': selectedApiService === 'gemini'}">
              <Icon name="logos:google-gemini" size="1.2em" class="pl-0 ml-0 relative -left-4 min-w-[80px]" />
            </DropdownMenuRadioItem>
            
            <DropdownMenuRadioItem value="llamaparse" class="hover:bg-muted-foreground/10 py-3" :class="{'bg-muted-foreground/10': selectedApiService === 'llamaparse'}">
              <img src="/images/logos/openai-logo.svg" alt="OpenAI" class="w-5 h-5 dark:invert opacity-75 mr-2" />
              OpenAI
            </DropdownMenuRadioItem>

            <DropdownMenuRadioItem value="openai" class="hover:bg-muted-foreground/10 py-3" :class="{'bg-muted-foreground/10': selectedApiService === 'openai'}">
              <img src="/images/logos/llamacloud-logo.webp" alt="LlamaParse" class="w-5 h-5 dark:invert opacity-75 mr-2 rounded-sm" />
              LlamaParse
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>

          <!-- Validation Tools Section -->
          <DropdownMenuSeparator />
          <DropdownMenuLabel class="text-sm font-[400] font-intervariable select-none">
            Validation Tools
          </DropdownMenuLabel>
          <div class="space-y-2">
            <DropdownMenuCheckboxItem
              v-for="tool in validationTools"
              :key="tool.id"
              :checked="enabledValidationTools.includes(tool.id)"
              @select="toggleValidationTool(tool.id)"
              class="hover:bg-muted-foreground/10 py-2"
            >
              {{ tool.label }}
            </DropdownMenuCheckboxItem>
          </div>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  </div>
</template> 