<script setup lang="ts">
import 'vue3-photo-preview/dist/index.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import hashSum from 'hash-sum';
import { titleCase } from "title-case";
import { useThrottleFn } from '@vueuse/core'
import { toolDefinitions } from '@/config/tools';

const mitter = useMitter()

dayjs.extend(relativeTime);
dayjs.extend(calendar);

mitter.listen('panel:data:send' as keyof MitterEvents, (event: any) => {
  if (event.targetPanelId === props.panel.name) {
    console.log(`[Gallery] DEBUG: panel:data:send event detected targeting this panel:`, event)

    // Call onDataReceived directly with the transformed data
    onDataReceived({
      source: event.sourcePanelId,
      type: event.sourceToolId,
      data: event.data
    })
  }
})

const onDataReceived = async (event: any) => {
  console.log('[Gallery] Received data:', event);
  loading.value = true;

  if (!event.data) {
    console.warn('[Gallery] No data received');
    loading.value = false;
    return;
  }
  
  // Process the received data
  if (event.data.images) {
    photos.value = event.data.images;
    
    // Update panel's output data to preserve images when panel is moved
    emit('update:output', { images: photos.value });
  }
  loading.value = false;
}

const tool = toolDefinitions.find(t => t.id === 'gallery')

const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false }
});

const emit = defineEmits(['error', 'update:output']);

// Initialize empty photos
const photos = ref([]);
const loading = ref(true);

onMounted(() => {
  // Check if panel data already has images
  if (props.panel?.data?.output?.images?.length) {
    photos.value = props.panel.data.output.images;
    loading.value = false;
    console.log('[Gallery] Restored images from panel data:', photos.value.length);
  } else if (!photos.value.length) {
    loading.value = true;
  } else {
    loading.value = false;
  }
});

// Compute image list
const imgList = computed(() => photos.value || []);

const selectedImage = ref(null);

</script>

<template>
      <div class="overflow-hidden w-full h-full p-0 m-0 rounded-md p-2 pl-4">
        <ScrollArea class="w-full h-full">
          <div  class="flex flex-wrap justify-evenly align-center items-center w-full h-full">
            <!-- only show skeleton if there is a connection to the panel -->
            <template v-if="!imgList.length && loading && props.panel.connection">
              <Skeleton v-for="i in 12" :key="i" class="w-32 h-32 m-1 masked brightness-75" />
            </template>
            <photo-provider v-else :should-transition="true">
              <photo-consumer v-for="img in imgList" :key="img.image_url" :intro="img.image_url" :src="img.image_url"
                :download-name="img.species">
                <div :style="{ backgroundColor: img.color }"
                  class="z-[1] relative top-8 left-5 rounded-full w-fit h-4 block -mr-4 -mt-4 p-0 px-1 flex items-center justify-center text-xs text-white">
                  {{ img.species.split(' ')[0].slice(0, 3)?.toUpperCase() + (img.species.split(' ')[1] ? ' ' + img.species.split(' ')[1].slice(0, 3).toLowerCase() : '') }}
                </div>
                <VTooltip :content="{__html: img.attribution}">
                  <img v-if="img" :src="`https://ik.imagekit.io/8qxqs9cznn/${img.image_url}`" :alt="img.species"
                    class="w-32 h-32 object-cover m-1 cursor-pointer masked" @click="selectedImage = img" />
                </VTooltip>
              </photo-consumer>
            </photo-provider>
          </div>
        </ScrollArea>
      </div>
</template>

<style scoped>
.masked {
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.tabs-container {
  @apply w-full;
}
</style>