<script setup lang="ts">
import { watchDebounced, useDebounceFn, useThrottleFn } from '@vueuse/core'
import {
  MglMap,
  MglNavigationControl,
  MglMarker,
  MglPopup,
  useMap
} from '@indoorequal/vue-maplibre-gl';
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { isEqual } from 'lodash-es';
import { onMounted, onUnmounted, nextTick } from 'vue'
import { useUiStore } from '@/stores/uiStore'
import { useDockStore } from '@/stores/dockStore'
import { useValidationStore } from '@/stores/validationStore'
import { useProjectStore } from '@/stores/projectStore'
import type { PlantDropEvent } from '@/stores/uiStore'
import type { Map as MapLibre, LngLatBounds } from 'maplibre-gl'
import { h, createApp } from 'vue'
import dayjs from 'dayjs';
import { MercatorCoordinate } from 'maplibre-gl'
import { point as turfPoint } from '@turf/helpers'
import { buffer } from '@turf/buffer'
import { distance } from '@turf/distance'
import { useTools } from '@/composables/tools/useTools'
import type { Tool } from '@/composables/tools'
import type { MitterEvents } from '@/types/mitterEvents'
import maplibregl from 'maplibre-gl'
import { clearNuxtData } from '#app'
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarProvider,
  SidebarTrigger,
  SidebarSeparator,
  SidebarMenu,
  SidebarMenuButton,
  SidebarFooter,
  useSidebar
} from '@/components/ui/sidebar'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion'
import SpeciesLayerAccordion from '@/components/SpeciesLayerAccordion.vue'
import { computed, ref, watch } from 'vue';



// Add store references
const dockStore = useDockStore()


const validationStore = useValidationStore()
const { allValidations } = storeToRefs(validationStore)

const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)

const { setActivePanel} = dockStore;
const mitter = useMitter()

const { mapSpeciesToColors } = useDataUtils();
const colorMode = useColorMode();


const { updatePanelData } = usePanelData()

// Add these constants near the top of the script section, after imports
const BASE_ICON_SIZE = 40 // Base size of plant icon in pixels
const DEFAULT_RADIUS_KM = 20 // Default search radius in kilometers
const MIN_RADIUS_KM = 0.5
const MAX_RADIUS_KM = 50

const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false }
});

const emit = defineEmits(['error', 'update:shared-data', 'update:map-data', 'loaded']);

const mapInstance = useMap()
const mapLoaded = ref(false)

// Add a ref to track if we need to reload species data
const pendingSpeciesLoad = ref(false)

const INATURALIST_RATE_LIMIT = 1000;
let lastINatRequest = 0;

// Add this type for occurrences
interface Occurrence {
  lat: number
  lng: number
  source: 'ALA' | 'iNaturalist'
  date?: string
  image_url?: string
  uri?: string
  observer?: string
  license_code?: string
  species?: string
}

interface LayerVisibility {
  [key: string]: boolean;
}

// Add this interface near the other interfaces at the top of the file
interface SpeciesLayer {
  name: string;
  color: string;
  count: number;
  source: string;
  taxon_id?: number;
}

// Later where speciesLayers is defined, update it to use the interface:
const speciesLayers = ref<Record<string, SpeciesLayer>>({})

// Add rate limiting helper
const rateLimit = async () => {
  const now = Date.now();
  const timeSinceLastRequest = now - lastINatRequest;

  if (timeSinceLastRequest < INATURALIST_RATE_LIMIT) {
    await new Promise(resolve =>
      setTimeout(resolve, INATURALIST_RATE_LIMIT - timeSinceLastRequest)
    );
  }

  lastINatRequest = Date.now();
};

// Modify the onMounted handler to check for stored features
onMounted(() => {
  // Add event listeners
  window.addEventListener('mouseup', refreshMap)
  window.addEventListener('touchend', refreshMap)
  window.addEventListener('resize', resizeMap)
  
  setTimeout(resizeMap, 100)

  console.log('Map component mounted, panel ID:', props.panel.name)

  // Register plant drop handler
  const { addEventListener } = useUiStore()
  addEventListener('plant-dropped', handlePlantDrop)
  console.log('Plant drop event listener registered')

  // Wait for map to be available and loaded
  watch(() => mapInstance.isLoaded, (isLoaded) => {
    if (isLoaded && mapInstance.map) {
      mapLoaded.value = true
      console.log('Map is loaded and ready')
      
      // Check for stored features in panel data and restore them
      if (props.panel.data?.savedFeatures) {
        console.log('[MyMap] Found saved features in panel data, restoring...');
        restoreMapFeaturesFromPanelData();
      } else if (pendingSpeciesLoad.value) {
        loadGeocodedSpecies()
        pendingSpeciesLoad.value = false
      }
    }
  }, { immediate: true })
})

onUnmounted(() => {
  // Save map state one last time before unmounting
  saveMapFeaturesToPanelData();
  
  // // Remove event listeners
  // if (mapInstance.map) {
  //   mapInstance.map.off('moveend', handleMapChange);
  //   mapInstance.map.off('zoomend', handleMapChange);
  //   mapInstance.map.off('styledata', handleMapChange);
  // }
  
  // window.removeEventListener('mouseup', refreshMap);
  // window.removeEventListener('touchend', refreshMap);
  // window.removeEventListener('resize', resizeMap);
  
  // // Clean up UI store listener
  // console.log('Map component unmounting')
  // const { removeEventListener } = useUiStore()
  // removeEventListener('plant-dropped', handlePlantDrop)

  // // Clean up data
  // abortController.value?.abort()
  // allOccurrences.value = []
  // processedDataSignatures.value.clear()
})


mitter.listen('panel:data:send' as keyof MitterEvents, (event: any) => {
  if (event.targetPanelId === props.panel.name) {
    console.log(`[MyMap] DEBUG: panel:data:send event detected targeting this panel:`, event)

    // Call onDataReceived directly with the transformed data
    onDataReceived({
      source: event.sourcePanelId,
      type: event.sourceToolId,
      data: event.data
    })
  }
})


const _refreshedKey = ref(0);




const layerVisibility = ref<LayerVisibility>({
  // Remove these two entries
  
})

const toggleLayer = (layerId: string) => {
  if (!mapInstance.map || !mapInstance.map.getLayer(layerId)) return;

  // Toggle visibility state
  layerVisibility.value[layerId] = !layerVisibility.value[layerId];
  const isVisible = layerVisibility.value[layerId];
  
  // Apply visibility to map
  mapInstance.map.setLayoutProperty(
    layerId,
    'visibility',
    isVisible ? 'visible' : 'none'
  );
}

// Watch for changes in layer visibility
watch(layerVisibility, (newVal) => {
  Object.entries(newVal).forEach(([layerId, isVisible]) => {
    if (mapInstance.map && mapInstance.map.getLayer(layerId)) {
      mapInstance.map.setLayoutProperty(
        layerId,
        'visibility',
        isVisible ? 'visible' : 'none'
      );
    }
  });
}, { deep: true });

// TODO: https://github.com/zimmermannpeter/com-tiles
// TODO: https://dev.to/mierune/comtiles-cloud-optimized-map-tiles-hosted-on-amazon-s3-and-visualized-with-maplibre-gl-js-3bed

const style = ref('https://api.maptiler.com/maps/landscape/style.json?key=tVYcCqbqU5I3tpqgTmCu')

const mapStyles = {
  light: {
    'default': 'https://api.maptiler.com/maps/landscape/style.json?key=tVYcCqbqU5I3tpqgTmCu',
    'satellite': 'https://api.maptiler.com/maps/satellite/style.json?key=tVYcCqbqU5I3tpqgTmCu',
    'streets': 'https://api.maptiler.com/maps/streets-v2/style.json?key=tVYcCqbqU5I3tpqgTmCu',
    'topo': 'https://api.maptiler.com/maps/2df52934-65af-4223-a3e2-b150a005eda6/style.json?key=tVYcCqbqU5I3tpqgTmCu'
  },
  dark: {
    'default': 'https://api.maptiler.com/maps/5d061ce6-6981-4fda-8752-ffc8cca65c0d/style.json?key=tVYcCqbqU5I3tpqgTmCu',
    'satellite': 'https://api.maptiler.com/maps/satellite/style.json?key=tVYcCqbqU5I3tpqgTmCu',
    'toner': 'https://api.maptiler.com/maps/493a1e31-3e26-4e90-bd54-ba5da5e36318/style.json?key=tVYcCqbqU5I3tpqgTmCu',
    'grayscale': 'https://api.maptiler.com/maps/bd4b0aa1-64d7-4fce-9f43-cb39fb8c6b0f/style.json?key=tVYcCqbqU5I3tpqgTmCu'
  }
}

const currentStyle = ref<keyof typeof mapStyles.light>('default');

// Fix for the 'any' type in the availableStyles indexing
const availableStyles = computed(() => mapStyles[colorMode.value as keyof typeof mapStyles]);

watch([colorMode, currentStyle], () => {
  style.value = availableStyles.value[currentStyle.value as keyof typeof availableStyles.value];
}, { immediate: true });

// Store previous map sources and layers to restore after style change
const mapSources = ref({});
const mapLayers = ref<any[]>([]); // Add explicit type

// Function to store current map sources and layers before style change
const storeMapSourcesAndLayers = () => {
  if (!mapInstance.map) return;

  const map = mapInstance.map;
  const sourceIds = map.getStyle()?.sources ? Object.keys(map.getStyle().sources) : [];
  console.log(`[MyMap] Found ${sourceIds.length} total sources`);
  
  const sources: Record<string, any> = {};
  const layers: maplibregl.LayerSpecification[] = [];
  
  // Only process species layers (those starting with 'species-layer-')
  const speciesSourceIds = sourceIds.filter(id => id.startsWith('species-layer-'));
  console.log(`[MyMap] Found ${speciesSourceIds.length} species sources to store`);

  // Store sources - ensure we make deep copies of GeoJSON data
  speciesSourceIds.forEach(id => {
    try {
      const source = map.getSource(id);
      if (source) {
        const sourceData = (source as any)._data;
        if (sourceData) {
          sources[id] = {
            type: 'geojson',
            data: JSON.parse(JSON.stringify(sourceData))
          };
          console.log(`[MyMap] Stored source for ${id} with ${sources[id].data?.features?.length || 0} features`);
        }
      }
    } catch (error) {
      console.error(`[MyMap] Error storing source ${id}:`, error);
    }
  });
  
  // Store layers
  const existingLayers = map.getStyle().layers || [];
  existingLayers.forEach(layer => {
    if (layer.id && layer.id.startsWith('species-layer-')) {
      try {
        // Make a deep copy of the layer
        layers.push(JSON.parse(JSON.stringify(layer)));
        console.log(`[MyMap] Stored layer: ${layer.id}`);
      } catch (error) {
        console.error(`[MyMap] Error storing layer ${layer.id}:`, error);
      }
    }
  });
  
  mapSources.value = sources;
  mapLayers.value = layers;
  
  console.log(`[MyMap] Successfully stored ${Object.keys(sources).length} sources and ${layers.length} layers`);
};

// Function to restore sources and layers after style change
const restoreMapSourcesAndLayers = () => {
  if (!mapInstance.map) {
    console.warn('[MyMap] Map not available for restoration');
    return;
  }
  
  const map = mapInstance.map;
  const sources = mapSources.value;
  const layers = mapLayers.value;

  console.log(`[MyMap] Starting to restore ${Object.keys(sources).length} sources and ${layers.length} layers...`);
  
  let successfulSources = 0;
  let successfulLayers = 0;

  // First, make sure we have a consistent state by removing any existing sources/layers
  [...Object.keys(sources), ...layers.map(l => l.id)].forEach(id => {
    try {
      // First remove any existing layers
      if (map.getLayer(id)) {
        console.log(`[MyMap] Removing existing layer before restoration: ${id}`);
        map.removeLayer(id);
      }
      
      // Then remove any existing sources
      if (map.getSource(id)) {
        console.log(`[MyMap] Removing existing source before restoration: ${id}`);
        map.removeSource(id);
      }
    } catch (error) {
      console.error(`[MyMap] Error cleaning up before restoration for ${id}:`, error);
    }
  });

  // Now add all sources with a clean slate
  Object.entries(sources).forEach(([id, sourceData]) => {
    try {
      // Add the source with explicit casting
      map.addSource(id, sourceData as maplibregl.AnySourceData);
      successfulSources++;
      console.log(`[MyMap] Restored source: ${id} with ${sourceData.data?.features?.length || 0} features`);
    } catch (error) {
      console.error(`[MyMap] Error restoring source ${id}:`, error);
    }
  });

  // Then add back all layers
  layers.forEach(layerData => {
    try {
      // Add the layer
      map.addLayer(layerData);
      successfulLayers++;
      
      // Ensure layer is visible based on our visibility state
      const isVisible = layerVisibility.value[layerData.id] !== false;
      map.setLayoutProperty(
        layerData.id,
        'visibility',
        isVisible ? 'visible' : 'none'
      );
      
      // Re-attach event handlers for the layer
      attachLayerEventHandlers(map, layerData.id);
      
      console.log(`[MyMap] Restored layer: ${layerData.id}`);
    } catch (error) {
      console.error(`[MyMap] Error restoring layer ${layerData.id}:`, error);
    }
  });

  console.log(`[MyMap] Finished restoring species layers: ${successfulSources}/${Object.keys(sources).length} sources and ${successfulLayers}/${layers.length} layers`);
  
  // If we are hiding markers without photos, reapply that filter
  if (hideMarkersWithoutPhotos.value) {
    updateMarkerVisibility();
  }
};

// Add this helper function to reattach event handlers to restored layers
const attachLayerEventHandlers = (map: maplibregl.Map, layerId: string) => {
  // Add click handler for this layer
  map.on('click', layerId, (e) => {
    if (!e.features?.length) return;

    const feature = e.features[0];
    // Cast to specific geometry type that has coordinates
    const pointGeometry = feature.geometry as GeoJSON.Point;
    const coordinates = pointGeometry.coordinates.slice() as [number, number];
    if (!coordinates) return;

    // Show popup with feature properties
    popupCoordinates.value = coordinates;
    popupContent.value = {
      ...feature.properties,
      source: feature.properties.source || 'Unknown'
    };
  });

  // Change cursor on hover
  map.on('mouseenter', layerId, () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', layerId, () => {
    map.getCanvas().style.cursor = '';
  });
};

// geographic coordinates for the center of the selectedProject.value.project_location.coordinates


const center = ref(selectedProject.value?.project_location?.coordinates
  ? [selectedProject.value.project_location.coordinates[0], selectedProject.value.project_location.coordinates[1]]
  : [144.9631, -37.8136]);

const zoom = ref(8);

// Fix for nameStatus result properties
const species = computed(() => {
  const projectId = selectedProject.value?.id
  if (!projectId) return []

  // Add type assertions to handle the inexact structure
  const exact = (allValidations.value?.[projectId]?.['nameStatus']?.['result'] as any)?.exact || [];
  const inexact = (allValidations.value?.[projectId]?.['nameStatus']?.['result'] as any)?.inexact || [];
  return [...exact, ...inexact];
})



watch(() => mapInstance.isLoaded, (isLoaded) => {
  if (isLoaded) {
    console.log('Map loaded');
    loadGeocodedSpecies();
  }
});

// Add this ref to store feature counts
const featureCounts = ref<Record<string, number>>({})

// Update the loadGeocodedSpecies function to track counts
const loadGeocodedSpecies = async () => {
  if (!mapInstance.map || !mapInstance.isLoaded) {
    console.log('Map not ready, setting pending load')
    pendingSpeciesLoad.value = true
    return
  }

  console.log("Starting loadGeocodedSpecies function", species.value)

  try {
    await rateLimit(); // Add rate limiting
    const map = mapInstance.map
    featureCounts.value = {} // Reset counts

    // First remove all existing layers
    Object.keys(speciesLayers.value).forEach(layerId => {
      try {
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId)
        }
      } catch (error) {
        console.warn(`Error removing layer ${layerId}:`, error)
      }
    })

    // Remove sources
    Object.keys(speciesLayers.value).forEach(layerId => {
      try {
        if (map.getSource(layerId)) {
          map.removeSource(layerId)
        }
      } catch (error) {
        console.warn(`Error removing source ${layerId}:`, error)
      }
    })

    speciesLayers.value = {}

    // Map species to colors
    const speciesColorMap = mapSpeciesToColors(species.value.map(s => s.original))

    // Create and add new layers for each species
    const queries = species.value.map(async (s, index) => {
      // Add an identifier for iNaturalist sources in the layerId
      const layerId = `species-layer-${s.original.toLowerCase().replace(/\s+/g, '-')}-inaturalist`;
      const color = speciesColorMap[s.original];

      try {
        await rateLimit(); // Add rate limiting
        const { data: response } = await useAsyncData(
          `iNaturalist-${s.original}`,
          () => $fetch(`https://api.inaturalist.org/v1/observations`, {
            params: {
              taxon_name: s.original,
              photos: true,
              geo: true,
              per_page: 200,
              quality_grade: 'research,needs_id',
              license: 'cc-by,cc-by-sa,cc0',
              order_by: 'observed_on',
              order: 'desc',
              verifiable: true,
              geoprivacy: 'open',
              taxon_geoprivacy: 'open',
              iconic_taxa: 'Plantae'
            }
          })
        );

        if (!response.value) {
          console.error(`No data received for ${s.original}`);
          return;
        }

        // Fix for properly checking response.value with optional chaining
        const filteredFeatures = (response.value?.results || []).filter(obs =>
          obs?.license_code && !obs.license_code.includes('nc')
        ) || [];

        // Store the count
        featureCounts.value[layerId] = filteredFeatures.length

        // Create GeoJSON features from coordinates
        const geoJSON = {
          type: 'FeatureCollection' as const,
          features: filteredFeatures.map((obs: any) => {
            if (!obs.geojson?.coordinates) {
              console.warn('Missing coordinates for observation:', obs.id);
              return null;
            }
            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: obs.geojson.coordinates
              },
              properties: {
                source: 'iNaturalist',
                date: obs.observed_on,
                image_url: obs.image_url,
                uri: obs.uri,
                observer: obs.user_login,
                license_code: obs.license_code,
                species: obs.species_guess
              }
            };
          }).filter((f: any) => f !== null)
        };

        console.log(`Created GeoJSON for ${s.original} with ${geoJSON.features.length} features from iNaturalist`);

        // Debug log the GeoJSON
        if (geoJSON.features.length > 0) {
          console.log(`Sample feature for ${s.original}:`, geoJSON.features[0]);
        }

        try {
          // Always recreate the source to ensure clean data
          if (map.getSource(layerId)) {
            map.removeSource(layerId);
          }

          map.addSource(layerId, {
            type: 'geojson',
            data: geoJSON as GeoJSON.FeatureCollection
          });
          console.log(`Added source for ${layerId} with ${geoJSON.features.length} features`);

          // Add the layer
          if (!map.getLayer(layerId)) {
            map.addLayer({
              id: layerId,
              type: 'circle',
              source: layerId,
              paint: {
                'circle-radius': 4,
                'circle-color': color,
                'circle-opacity': [
                  'case',
                  ['has', 'image_url'], 0.7,
                  0.2
                ],
                'circle-stroke-width': 0,
                // 'circle-stroke-color': '#fff'
              }
            });

            // Add click handler for this layer
            map.on('click', layerId, (e) => {
              if (!e.features?.length) return;

              const feature = e.features[0];
              // Cast to specific geometry type that has coordinates
              const pointGeometry = feature.geometry as GeoJSON.Point;
              const coordinates = pointGeometry.coordinates.slice() as [number, number];
              if (!coordinates) return;

              console.log('Clicked feature:', feature);

              // Show popup with feature properties
              popupCoordinates.value = coordinates;
              popupContent.value = {
                ...feature.properties,
                source: feature.properties.source || 'Unknown'
              };
            });

            // Change cursor on hover
            map.on('mouseenter', layerId, () => {
              map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', layerId, () => {
              map.getCanvas().style.cursor = '';
            });
          }

          // Update the speciesLayers object
          if (!speciesLayers.value[layerId]) {
            speciesLayers.value[layerId] = {
              color,
              name: s.original,
              count: geoJSON.features.length,
              source: 'iNaturalist' // Explicitly set source for iNaturalist features
            };
          }

          // Ensure layer is visible
          map.setLayoutProperty(layerId, 'visibility', 'visible');
          layerVisibility.value[layerId] = true;
          layerCounts.value[layerId] = geoJSON.features.length;

        } catch (error) {
          console.error(`Error handling map operations for ${s.original}:`, error)
        }

      } catch (error) {
        console.error(`Error processing species ${s.original}:`, error);
      }
    });

    await Promise.all(queries);

  } catch (error) {
    console.error('Error in loadGeocodedSpecies:', error)
  }
}



// Use a separate ref to track changes
const lastEmittedData = ref(null);


// Watch for changes in species and reload data
watch(() => species.value, (newSpecies, oldSpecies) => {
  if (isEqual(newSpecies, oldSpecies)) return;
}, { deep: true });

// Fix for error object type
const handleError = (error: any) => {  // Add explicit type
  console.error('Map error:', error);
  emit('error', error);
};



const handleMove = () => {
  if (!mapInstance.map || !mapInstance.isLoaded) return;

  const bounds = mapInstance.map.getBounds();
  const center = mapInstance.map.getCenter();
  const zoom = mapInstance.map.getZoom();

  // Calculate tile coordinates based on the center of the viewport
  const { x, y, z } = bboxToXYZTile(center.lng, center.lat, Math.floor(zoom));

  


}
const debouncedHandleMove = useDebounceFn(handleMove, 300)

const bboxToXYZTile = (lon: number, lat: number, zoom: number) => {
  const x = Math.floor((lon + 180) / 360 * Math.pow(2, zoom));
  const y = Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom));
  return { x, y, z: zoom };
}


const allowedModes = computed(() => getAvailableModes(colorMode.value))

// Access shared data from other panels
// const galleryData = computed(() => props.panel.data.sharedData['gallery-panel-id'] || {});

const mapContainer = ref(null)
const { width: mapWidth } = useElementSize(mapContainer)
const isResizing = ref(false)



// Watch for mapWidth changes
watch(mapWidth, () => {
  isResizing.value = true
})



const mapRef = ref(null)


// Add loading state
const isLoadingOccurrences = ref(false)

// Add ref for abort controller
const abortController = ref<AbortController | null>(null)


// Add a proper type for GeoJSONSource with getData method
interface ExtendedGeoJSONSource extends maplibregl.Source {
  setData: (data: any) => void;
  getData: () => { features: any[] };
}

// Modify the onDataReceived function to save features after processing
const onDataReceived = async (event: any) => {
  console.log('[MyMap] Received data:', event);
  
  if (!event.data) {
    console.warn('[MyMap] No data received');
    return;
  }
  
  // Create a signature for this data to deduplicate
  const dataSignature = getDataSignature(event.data);
  
  // Skip if we've already processed this exact data
  if (processedDataSignatures.value.has(dataSignature)) {
    console.log('[MyMap] Skipping duplicate data with signature:', dataSignature.substring(0, 40) + '...');
    return;
  }
  
  // Record that we've processed this data
  processedDataSignatures.value.add(dataSignature);
  
  // Handle case where data is a Promise
  let resolvedData = event.data;
  if (resolvedData instanceof Promise) {
    try {
      console.log('[MyMap] Data is a Promise, resolving...');
      resolvedData = await resolvedData;
      console.log('[MyMap] Promise resolved:', resolvedData);
    } catch (error) {
      console.error('[MyMap] Error resolving data promise:', error);
      return;
    }
  }
  
  // Check if the data is GeoJSON
  if (resolvedData && resolvedData.type === 'FeatureCollection' && Array.isArray(resolvedData.features)) {
    console.log('[MyMap] Processing GeoJSON data with', resolvedData.features.length, 'features');
    
    // Process the GeoJSON data
    await processGeoJSON(resolvedData);
    
    // Update species from metadata if available
    if (resolvedData.metadata?.species) {
      // Update species state with the species data from the transformer
      updateSpeciesState(resolvedData.metadata.species);
    }
    
    // Save map features to panel data after processing
    saveMapFeaturesToPanelData();
  } else {
    console.warn('[MyMap] Received data is not in a recognized GeoJSON format. Data transformation should happen in the transformer.');
  }
};

// Helper to get a unique signature for incoming data to deduplicate
const getDataSignature = (data: any): string => {
  // For simple primitives, just stringify them
  if (typeof data !== 'object' || data === null) {
    return String(data);
  }

  try {
    // For GeoJSON data, create a signature based on key properties
    if (data.type === 'FeatureCollection' && Array.isArray(data.features)) {
      return JSON.stringify({
        type: data.type,
        featureCount: data.features.length,
        // Include a hash of species from metadata if available
        speciesHash: data.metadata?.species?.map((s: any) => s.original || s.name)?.join(',') || '',
        // Add a timestamp bucket (15 seconds) to allow reprocessing after some time
        timeBucket: Math.floor(Date.now() / 15000)
      });
    }

    // For other objects, include basic identifying information
    return JSON.stringify({
      tool_uid: data.tool_uid,
      type: data.type,
      // Use timestamp bucket to allow reprocessing the same data after some time
      timeBucket: Math.floor(Date.now() / 15000)
    });
  } catch (error) {
    console.error('[MyMap] Error generating data signature:', error);
    // If we can't create a signature, use a timestamp to ensure processing
    return `fallback-${Date.now()}`;
  }
};

// Helper function to update species state to avoid direct assignment 
const updateSpeciesState = (speciesData: any[]) => {
  if (!Array.isArray(speciesData)) return;
  
  // Use proper Vue reactivity methods instead of direct assignment
  const currentSpecies = [...species.value];
  
  // Clear and replace with new values
  currentSpecies.splice(0, currentSpecies.length, ...speciesData);
  
  // Update state in a proper way that respects read-only refs
  // This depends on how species is actually managed
  if (typeof species.value === 'object' && Array.isArray(species.value)) {
    species.value.splice(0, species.value.length, ...speciesData);
  }
}

// Add these near the top of the script section
const dropCoordinates = ref<{ lat: number, lng: number } | null>(null);

// Update the handlePlantDrop function
const handlePlantDrop = async (event: PlantDropEvent) => {
  if (event.payload.panelId === props.panel.name && mapInstance.map) {
    const map = mapInstance.map
    const canvas = map.getCanvas()
    const rect = canvas.getBoundingClientRect()

    // Get the drop point coordinates
    const dropCoords = map.unproject([
      event.payload.x - rect.left,
      event.payload.y - rect.top
    ])

    // Create a Turf point from the coordinates
    const dropPoint = turfPoint([dropCoords.lng, dropCoords.lat])

    // Calculate radius based on current icon size with smaller multiplier
    const currentIconSize = BASE_ICON_SIZE * 0.9 // Reduced from 1.5 to 1.2
    const radiusInKm = calculateRadiusFromPixels(
      currentIconSize,
      dropCoords.lat,
      map.getZoom()
    )

    console.log('Calculated radius:', radiusInKm, 'km')

    // Create a buffer around the point
    const searchArea = buffer(dropPoint, radiusInKm, { units: 'kilometers' }) as GeoJSON.Feature<GeoJSON.Polygon>;

    // Ensure searchArea is never undefined
    if (map.getSource('search-area')) {
      (map.getSource('search-area') as maplibregl.GeoJSONSource).setData(searchArea);
    } else {
      map.addSource('search-area', {
        type: 'geojson',
        data: searchArea
      });
    }

    dropCoordinates.value = {
      lat: dropCoords.lat,
      lng: dropCoords.lng
    }

    await fetchOccurrenceRecords(
      event.payload.species,
      dropCoordinates.value.lat,
      dropCoordinates.value.lng,
      radiusInKm
    )
  }
}


// Add this to your component's state
const occurrenceLayerId = computed(() => `occurrence-layer-${props.panel.name}`)
const occurrenceSourceId = computed(() => `occurrence-source-${props.panel.name}`)

// Add refs for popup management
const popupCoordinates = ref<[number, number] | null>(null)
const popupContent = ref<{
  source: string
  title?: string
  species?: string
  date?: string
  image_url?: string
  uri?: string
  observer?: string
  license_code?: string
  location?: string
} | null>(null)

// Add a ref to store all occurrences
const allOccurrences = ref<Occurrence[]>([])

// Update plotOccurrenceRecords to append new occurrences
const plotOccurrenceRecords = (newOccurrences: Occurrence[]) => {
  if (!mapInstance.map || !mapInstance.isLoaded) return;

  const map = mapInstance.map;
  const sourceId = occurrenceSourceId.value;
  const layerId = occurrenceLayerId.value;

  // Add new occurrences to existing ones
  allOccurrences.value = [...allOccurrences.value, ...newOccurrences];

  // Convert all occurrences to GeoJSON
  const geoJSON = {
    type: 'FeatureCollection' as const,
    features: allOccurrences.value.map(occ => ({
      type: 'Feature' as const,
      geometry: {
        type: 'Point' as const,
        coordinates: [occ.lng, occ.lat]
      },
      properties: {
        source: occ.source,
        date: occ.date,
        image_url: occ.image_url,
        uri: occ.uri,
        observer: occ.observer,
        license_code: occ.license_code,
        species: occ.species
      }
    }))
  };

  // Update or create the occurrences source/layer
  if (map.getSource(sourceId)) {
    (map.getSource(sourceId) as maplibregl.GeoJSONSource).setData(geoJSON as GeoJSON.FeatureCollection);
  } else {
    map.addSource(sourceId, {
      type: 'geojson',
      data: geoJSON
    });

    map.addLayer({
      id: layerId,
      type: 'circle',
      source: sourceId,
      paint: {
        'circle-radius': 4,
        'circle-color': [
          'match',
          ['get', 'source'],
          'ALA', '#ff4444',
          'iNaturalist', '#44ff44',
          '#888888'
        ],
        'circle-opacity': [
          'case',
          ['has', 'image_url'], 0.7,
          0.2
        ],
        'circle-stroke-width': 0,
        // 'circle-stroke-color': '#ffffff'
      }
    });

    // Add cursor handlers
    map.on('mouseenter', layerId, () => {
      map.getCanvas().style.cursor = 'pointer'
    });

    map.on('mouseleave', layerId, () => {
      map.getCanvas().style.cursor = ''
    });

    // Add click handler for popups
    map.on('click', layerId, (e) => {
      if (!e.features?.length) return;

      const feature = e.features[0];
      // Cast to specific geometry type that has coordinates
      const pointGeometry = feature.geometry as GeoJSON.Point;
      const coordinates = pointGeometry.coordinates.slice() as [number, number];

      popupCoordinates.value = coordinates;
      popupContent.value = {
        source: feature.properties.source,
        title: feature.properties.species,
        date: feature.properties.date,
        image_url: feature.properties.image_url,
        uri: feature.properties.uri,
        observer: formatObserver(feature.properties.observer),
        license_code: feature.properties.license_code
      };
    });

    // Add to layer visibility state
    layerVisibility.value[layerId] = true;
  }
}



// Update the layerCounts computed property to use the stored counts
const layerCounts = computed(() => {
  return {
    ...featureCounts.value,
    [occurrenceLayerId.value]: allOccurrences.value.length
  }
})

// Fix the removeLayer function to properly remove both layer and source
const removeLayer = (layerId: string) => {
  if (!mapInstance.map) return;

  const map = mapInstance.map;

  try {
    // First check if the layer exists and remove it
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }

    // Then check if the source exists and remove it
    if (map.getSource(layerId)) {
      map.removeSource(layerId);
    }

    // Update state
    delete layerVisibility.value[layerId];
    delete speciesLayers.value[layerId];
    delete layerCounts.value[layerId];

    console.log(`[MyMap] Removed layer and source: ${layerId}`);
  } catch (error) {
    console.error(`[MyMap] Error removing layer ${layerId}:`, error);
  }
};

// Update the calculateRadiusFromPixels function
const calculateRadiusFromPixels = (pixelRadius: number, latitude: number, zoom: number) => {
  try {
    // Calculate meters per pixel at this zoom level and latitude
    const metersPerPixel = 156543.03392 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom)

    // Add zoom-based scaling factor (inverse relationship with zoom)
    const zoomScaleFactor = Math.max(0.5, (20 - zoom) / 10) // Scale down as zoom increases

    // Convert pixels to meters, then to kilometers, applying zoom scaling
    const radiusInKm = (pixelRadius * metersPerPixel * zoomScaleFactor) / 1000

    // Ensure minimum and maximum reasonable values
    return Math.min(Math.max(radiusInKm, MIN_RADIUS_KM), MAX_RADIUS_KM)
  } catch (error) {
    console.error('Error calculating radius:', error)
    return DEFAULT_RADIUS_KM
  }
}

// Update shared data whenever relevant map data changes
watch([allOccurrences, layerVisibility, () => mapInstance.map?.getBounds()], () => {
  if (mapInstance.map) {
    const mapData = {
      occurrences: toRaw(allOccurrences.value).map(occ => ({
        lat: occ.lat,
        lng: occ.lng,
        source: occ.source,
        date: occ.date,
        image_url: occ.image_url,
        uri: occ.uri,
        observer: occ.observer,
        license_code: occ.license_code,
        species: occ.species
      })),
      visibleLayers: Object.entries(layerVisibility.value)
        .filter(([_, isVisible]) => isVisible)
        .map(([layerId]) => layerId),
      bounds: mapInstance.map.getBounds(),
      center: mapInstance.map.getCenter(),
      zoom: mapInstance.map.getZoom(),
      species: Object.entries(speciesLayers.value).map(([layerId, layer]) => ({
        name: layer.name,
        color: layer.color,
        visible: layerVisibility.value[layerId],
        count: featureCounts.value[layerId] || 0
      }))
    }

    // Update panel state
    // if (props.panel.name) {
    //   const dockStore = useDockStore()
    //   // Keep existing panel data
    //   const existingPanelData = dockStore.panelState[props.panel.name]?.data || {}
    //   dockStore.addPanelState({
    //     [props.panel.name]: {
    //       data: {
    //         ...existingPanelData,
    //         sharedData: toRaw(mapData)
    //       }
    //     }
    //   })
    // }

    emit('update:map-data', mapData)
    emit('update:shared-data', mapData)
  }
}, { deep: true });

// Add these new handler functions after other event handlers
const handleMapClick = (event: any) => {
  // Check if originalEvent exists and has a target property
  if (!event?.originalEvent?.target) return;

  const target = event.originalEvent.target as HTMLElement;
  if (!isClickTargetWithinElements(target)) {
    setActivePanel(props.panel.name);
  }
};

// Add the missing isClickTargetWithinElements function
const isClickTargetWithinElements = (target: HTMLElement | null): boolean => {
  if (!target) return false;

  // Check if the click target is within any UI elements that should not trigger panel activation
  // For example, check for dropdown menus, buttons, or other interactive elements
  const isWithinDropdown = target.closest('.dropdown-menu') !== null;
  const isWithinButton = target.closest('button') !== null;
  const isWithinPopup = target.closest('.maplibregl-popup') !== null;
  const isWithinControl = target.closest('.maplibregl-ctrl') !== null;

  return isWithinDropdown || isWithinButton || isWithinPopup || isWithinControl;
};

// For deduplication
const processedPayloads = ref(new Map<string, number>())
const lastCleanup = ref(Date.now())
const pendingData = ref<any[]>([])

// Get a random color for map layers
const getRandomColor = () => {
  // Use Tailwind CSS colors for better consistency with the design system
  const tailwindColors = [
    'rgb(239, 68, 68)',   // red-500
    'rgb(249, 115, 22)',  // orange-500
    'rgb(245, 158, 11)',  // amber-500
    'rgb(234, 179, 8)',   // yellow-500
    'rgb(132, 204, 22)',  // lime-500
    'rgb(34, 197, 94)',   // green-500
    'rgb(16, 185, 129)',  // emerald-500
    'rgb(20, 184, 166)',  // teal-500
    'rgb(6, 182, 212)',   // cyan-500
    'rgb(14, 165, 233)',  // sky-500
    'rgb(59, 130, 246)',  // blue-500
    'rgb(99, 102, 241)',  // indigo-500
    'rgb(139, 92, 246)',  // violet-500
    'rgb(168, 85, 247)',  // purple-500
    'rgb(217, 70, 239)',  // fuchsia-500
    'rgb(236, 72, 153)',  // pink-500
    'rgb(244, 63, 94)',   // rose-500
    'rgb(75, 85, 99)',    // gray-600
    'rgb(55, 65, 81)',    // gray-700
    'rgb(31, 41, 55)'     // gray-800
  ];

  // Pick a random color from the array
  return tailwindColors[Math.floor(Math.random() * tailwindColors.length)];
};

const handleMapLoaded = (e: any) => {
  console.log('[MyMap] Map loaded');
  
  mapLoaded.value = true;
  mapInstance.map = e.map;
  
  // Process any pending data
  if (pendingData.value.length > 0) {
    console.log(`[MyMap] Processing ${pendingData.value.length} pending data items`);
    pendingData.value.forEach(data => {
      onDataReceived({ data });
    });
    pendingData.value = [];
  }
  
  // Add map controls
  mapInstance.map?.addControl(new maplibregl.NavigationControl(), 'top-right');
  mapInstance.map?.addControl(new maplibregl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true
  }), 'top-right');
  mapInstance.map?.addControl(new maplibregl.ScaleControl(), 'bottom-left');
  
  // Handle initial bounds
  if (bounds?.value) {
    mapInstance.map?.fitBounds(bounds.value);
  }
  
  isMapLoaded.value = true;
  emit('loaded', mapInstance.map);
  
  // Wait for the style to be fully loaded before setting up listeners
  if (mapInstance.map) {
    mapInstance.map.once('styledata', () => {
      console.log('[MyMap] Map style fully loaded, setting up change listeners');
      setupMapChangeListeners();
      // Check for stored features after style is loaded
      if (props.panel.data?.savedFeatures) {
        console.log('[MyMap] Style loaded, now restoring saved features');
        restoreMapFeaturesFromPanelData();
      }
    });
  }
}

const clearMapCache = async () => {
  try {
    // Clear occurrence data cache
    if (selectedProject.value) {
      // Clear species-specific caches
      if (species.value && species.value.length > 0) {
        for (const s of species.value) {
          // Clear ALA cache
          await clearNuxtData(`ala-occurrences-${s.original}`)
          // Clear iNaturalist cache
          await clearNuxtData(`inaturalist-occurrences-${s.original}`)
        }
      }

      // Clear general map caches
      await clearNuxtData('map-data')
      await clearNuxtData('map-bounds')
      await clearNuxtData('map-species')

      // Clear panel-specific caches
      if (props.panel?.name) {
        await clearNuxtData(`panel-map-data-${props.panel.name}`)
      }

      // Show success notification

      push.success({
        title: "Cache cleared",
        message: "Map data cache has been cleared successfully.",
        duration: 3000,
      })


    }
  } catch (error) {
    console.error('Error clearing map cache:', error)
    // Show error notification

    push.error({
      title: "Error clearing cache",
      message: `There was a problem clearing the map cache. ${error}`,
      duration: 3000,
    })
  }
}
// Function to close the popup
const closePopup = () => {
  popupCoordinates.value = null
  popupContent.value = null
  isImageHovered.value = false
}

// Format date for popup display
const formatDate = (dateString: string | undefined) => {
  if (!dateString) return 'Unknown date'
  try {
    return dayjs(dateString).format('MMM D, YYYY')
  } catch (e) {
    return dateString
  }
}

// Unified license handling utility
const licenseUtil = {
  // Clean up license code to standardized format
  cleanLicenseCode: (licenseCode: string | undefined): string => {
    if (!licenseCode) return ''
    
    return licenseCode.toLowerCase()
      .replace(/^cc-?/i, '') // Remove cc- or cc prefix
      .replace(/\s*\d+\.\d+\s*(?:\(int\))?$/i, '') // Remove version number and (Int)
      .trim()
  },
  
  // Map of license codes to readable names
  displayMap: {
    'by': 'CC BY',
    'by-nc': 'CC BY-NC',
    'by-nd': 'CC BY-ND',
    'by-sa': 'CC BY-SA',
    'by-nc-sa': 'CC BY-NC-SA',
    'by-nc-nd': 'CC BY-NC-ND',
    'by-nd-nc': 'CC BY-NC-ND', // Handle alternate ordering
    '0': 'CC0', // Public domain
    'cc0': 'CC0',
    'publicdomain': 'Public Domain'
  } as Record<string, string>,
  
  // Map of license codes to standardized URL paths
  urlPathMap: {
    'by': 'by',
    'by-nc': 'by-nc',
    'by-nd': 'by-nd',
    'by-sa': 'by-sa',
    'by-nc-sa': 'by-nc-sa',
    'by-nc-nd': 'by-nc-nd',
    'by-nd-nc': 'by-nc-nd', // Redirect to standard form
    '0': 'zero', // Special case for CC0
    'cc0': 'zero',
    'publicdomain': 'zero'
  } as Record<string, string>,
  
  // Get human-readable license name
  getReadableName: (licenseCode: string | undefined): string => {
    if (!licenseCode) return 'Unknown license'
    
    const cleaned = licenseUtil.cleanLicenseCode(licenseCode)
    return licenseUtil.displayMap[cleaned] || licenseCode
  },
  
  // Get license URL
  getUrl: (licenseCode: string | undefined): string => {
    if (!licenseCode) return '#'
    
    const cleaned = licenseUtil.cleanLicenseCode(licenseCode)
    
    // Special case for public domain/CC0
    if (['0', 'cc0', 'publicdomain'].includes(cleaned)) {
      return 'https://creativecommons.org/publicdomain/zero/1.0/'
    }
    
    const urlPath = licenseUtil.urlPathMap[cleaned] || cleaned
    
    // Use latest version (4.0) for standard licenses
    return `https://creativecommons.org/licenses/${urlPath}/4.0/`
  },
  
  // Get license image URL
  getImageUrl: (licenseCode: string | undefined): string => {
    if (!licenseCode) return ''
    
    const cleaned = licenseUtil.cleanLicenseCode(licenseCode)
    
    // Special case for public domain/CC0
    if (['0', 'cc0', 'publicdomain'].includes(cleaned)) {
      return 'https://licensebuttons.net/p/zero/1.0/88x31.png'
    }
    
    const urlPath = licenseUtil.urlPathMap[cleaned] || cleaned
    
    // Use latest version (4.0) for standard licenses
    return `https://licensebuttons.net/l/${urlPath}/4.0/88x31.png`
  }
}

// Format license code for display - kept for backward compatibility
const formatLicense = (licenseCode: string | undefined) => {
  return licenseUtil.getReadableName(licenseCode)
}

// Update the generateAttribution function with correct license URLs
const generateAttribution = (properties: any) => {
  let attribution = '<div class="text-xs w-full whitespace-normal">';

  // Title/Species
  if (properties.species) {
    attribution += `<span class='font-bold text-base'>${properties.species}</span><br/>`;
  }

  // Image - if available
  if (properties.image_url) {
    attribution += `
      <div class="my-2">
        <img src="${properties.image_url}" 
             alt="${properties.species || 'Occurrence photo'}" 
             class="w-32 h-32 object-cover rounded-md"
        />
      </div>
    `;
  }

  // Observer - only show if not from Plant Schedule
  if (properties.observer && properties.source !== 'Plant Schedule') {
    attribution += `<span class="text-[11px] text-gray-600">Observer: ${formatObserver(properties.observer)}</span><br/>`;
  }

  // Date - Format using dayjs
  if (properties.date) {
    attribution += `<span class="text-[11px] text-gray-600">Observed on ${dayjs(properties.date).format('MMMM D, YYYY')}</span><br/>`;
  }

  // Source with link
  if (properties.uri) {
    attribution += `<span class="text-[11px] text-gray-600">Source: <a href="${properties.uri}" target="_blank" rel="noopener noreferrer" class="underline">${properties.source}</a></span>`;
  } else {
    attribution += `<span class="text-[11px] text-gray-600">Source: ${properties.source}</span>`;
  }

  // License - Using the unified license utility
  if (properties.license_code) {
    const licenseUrl = licenseUtil.getUrl(properties.license_code);
    const licenseImage = licenseUtil.getImageUrl(properties.license_code);
    const licenseName = licenseUtil.getReadableName(properties.license_code);

    attribution += `
      <br/>
      <a href="${licenseUrl}" 
         target="_blank" 
         rel="noopener noreferrer" 
         class="inline-block mt-2"
         title="${licenseName}">
        <img src="${licenseImage}" 
             alt="${licenseName}" 
             class="h-[31px]" 
             style="vertical-align:middle;" />
      </a>`;
  }

  attribution += '</div>';

  // Add view original image link if available
  if (properties.image_url) {
    attribution += `
      <br/>
      <a href="${properties.image_url}" 
         target="_blank" 
         rel="noopener noreferrer" 
         class="text-xs underline hover:text-primary">
        View original image
      </a>`;
  }

  return attribution;
};

// Get license URL - kept for backward compatibility
const getLicenseUrl = (licenseCode: string | undefined) => {
  return licenseUtil.getUrl(licenseCode)
}

// Add these refs for image hover functionality
const imageRef = ref<HTMLElement | null>(null)
const isImageHovered = ref(false)

const mapInitialized = ref(false)
const markersLayerGroup = ref(null)
const hideMarkersWithoutPhotos = ref(false)

// Function to update the marker visibility based on the hideMarkersWithoutPhotos setting
const updateMarkerVisibility = () => {
  if (!mapInstance.map) return
  
  // For each layer in the map
  Object.keys(layerVisibility.value).forEach(layerId => {
    if (!mapInstance.map.getLayer(layerId)) return
    
    // Update the filter for the layer
    if (hideMarkersWithoutPhotos.value) {
      // Only show markers with images
      mapInstance.map.setFilter(layerId, ['has', 'image_url'])
    } else {
      // Show all markers
      mapInstance.map.setFilter(layerId, null)
    }
  })
}

// Watch for changes to the hideMarkersWithoutPhotos setting
watch(hideMarkersWithoutPhotos, () => {
  updateMarkerVisibility()
})

// Add this near your other refs/reactive variables
const sidebarOpen = ref(false) // Initially closed

const getLayerSource = (layerId: string): string | null => {
  console.log('getLayerSource checking layer:', layerId);
  
  // First check if layerId ends with a source name
  if (layerId.endsWith('-ala')) {
    console.log('Layer ID ends with -ala, returning ALA as source');
    return 'ALA';
  }
  
  if (layerId.endsWith('-inaturalist')) {
    console.log('Layer ID ends with -inaturalist, returning iNaturalist as source');
    return 'iNaturalist';
  }

  // Check if we have the layer in speciesLayers
  const layer = speciesLayers.value[layerId];
  if (layer) {
    console.log('Layer found:', layer);
    
    // If layer has a source property, use that
    if (layer.source) {
      console.log(`Direct source for ${layerId}:`, layer.source);
      return layer.source;
    }
  }

  // Try to get source from map features
  if (mapInstance.map) {
    try {
      const source = mapInstance.map.getSource(layerId);
      if (source) {
        // Try to get data from source
        const sourceData = (source as any)._data;
        if (sourceData?.features?.length > 0) {
          const firstFeature = sourceData.features[0];
          if (firstFeature.properties?.source) {
            const src = firstFeature.properties.source;
            console.log(`Source from features for ${layerId}:`, src);
            
            // Store source in layer for future reference
            if (layer) {
              layer.source = src;
            }
            
            return src;
          }
        }
      }
    } catch (error) {
      console.error('Error getting source:', error);
    }
  }

  // Check if layerId contains species-layer pattern
  if (String(layerId).includes('species-layer')) {
    const match = String(layerId).match(/species-layer-.*-(inaturalist|ala)$/i);
    if (match?.[1]) {
      const source = match[1].toLowerCase() === 'ala' ? 'ALA' : 'iNaturalist';
      console.log(`Extracted source ${source} from layer ID pattern`);
      return source;
    }
    
    // Fallback for older data format
    console.log('Layer ID contains species-layer but no source info, assuming iNaturalist');
    return 'iNaturalist';
  }

  // Fallback for legacy iNaturalist layers
  if (layerId.toLowerCase().includes('inaturalist')) {
    return 'iNaturalist';
  }
  
  return null;
}


// Note: When constructing the legend, it should use:
// if (String(layerId).includes('species-layer') && getLayerSource(layerId) === 'iNaturalist') { ... }

// Update the hasAlaLayers computed property to focus on the source property and new layer ID format
const hasAlaLayers = computed(() => {
  // Look for layers where the source is explicitly set to 'ALA' in speciesLayers
  // or where the layerId ends with '-ala'
  const layersWithSource = Object.entries(speciesLayers.value)
    .filter(([id, layer]) => {
      // Check if source is ALA or if the ID ends with -ala
      return layer.source === 'ALA' || id.toLowerCase().endsWith('-ala');
    })
    .map(([id]) => id);
  
  const hasLayersWithSource = layersWithSource.length > 0;
  
  // For compatibility, also check if any occurrences have ALA as source
  const occurrencesFromALA = allOccurrences.value.filter(occ => 
    occ.source === 'ALA'
  );
  
  const hasOccurrencesFromSource = occurrencesFromALA.length > 0;
  
  // Log the result for debugging
  console.log(`hasAlaLayers: ${hasLayersWithSource || hasOccurrencesFromSource}`);
  console.log(`- Layers with ALA source: ${layersWithSource.length}`, layersWithSource);
  console.log(`- Occurrences with ALA source: ${occurrencesFromALA.length}`);
  
  return hasLayersWithSource || hasOccurrencesFromSource;
})

// Update the hasInaturalistLayers computed property to focus on the source property and new layer ID format
const hasInaturalistLayers = computed(() => {
  // Look for layers where the source is explicitly set to 'iNaturalist' in speciesLayers
  // or where the layerId ends with '-inaturalist'
  const layersWithSource = Object.entries(speciesLayers.value)
    .filter(([id, layer]) => {
      // Check if source is iNaturalist or if the ID ends with -inaturalist
      return layer.source === 'iNaturalist' || id.toLowerCase().endsWith('-inaturalist');
    })
    .map(([id]) => id);
  
  const hasLayersWithSource = layersWithSource.length > 0;
  
  // For compatibility, also check if any occurrences have iNaturalist as source
  const occurrencesFromINat = allOccurrences.value.filter(occ => 
    occ.source === 'iNaturalist'
  );
  
  const hasOccurrencesFromSource = occurrencesFromINat.length > 0;
  
  // Log the result for debugging
  console.log(`hasInaturalistLayers: ${hasLayersWithSource || hasOccurrencesFromSource}`);
  console.log(`- Layers with iNaturalist source: ${layersWithSource.length}`, layersWithSource);
  console.log(`- Occurrences with iNaturalist source: ${occurrencesFromINat.length}`);
  
  return hasLayersWithSource || hasOccurrencesFromSource;
})

// Function to handle map refresh
const refreshMap = () => {
  if (isResizing.value && mapInstance.map) {
    _refreshedKey.value++
    mapInstance.map.resize()
    isResizing.value = false
  }
}

// Watch for mapWidth changes
watch(mapWidth, () => {
  isResizing.value = true
})

// Function to handle map resize
const resizeMap = () => {
  nextTick(() => {
    if (mapInstance.map) {
      mapInstance.map.resize()
    }
  })
}

const processGeoJSON = (geoJSON: any) => {
  if (!mapInstance.map || !geoJSON || !geoJSON.features) return;
  
  const map = mapInstance.map;
  
  // Group features by species AND source
  const featuresBySpeciesAndSource = new Map<string, any[]>();
  
  // Process each feature
  geoJSON.features.forEach((feature: any) => {
    if (!feature.properties) return;
    
    const species = feature.properties.species || 'Unknown species';
    
    // Ensure source property is set
    if (!feature.properties.source) {
      feature.properties.source = 'Unknown';
    }
    
    const source = feature.properties.source;
    
    // Create a combined key for species+source
    const groupKey = `${species}:${source}`;
    
    // Group by species and source
    if (!featuresBySpeciesAndSource.has(groupKey)) {
      featuresBySpeciesAndSource.set(groupKey, []);
    }
    featuresBySpeciesAndSource.get(groupKey)?.push(feature);
  });
  
  // Create layers for each species+source combination
  for (const [groupKey, features] of featuresBySpeciesAndSource.entries()) {
    const [species, sourceType] = groupKey.split(':');
    
    // Generate a consistent layer ID based on the species name and source
    const layerId = `species-layer-${species.toLowerCase().replace(/\s+/g, '-')}-${sourceType.toLowerCase()}`;
    
    // Generate a random color for this species if not already assigned
    // Note: use the species name without the source as the base for consistent coloring
    const speciesKey = `species-layer-${species.toLowerCase().replace(/\s+/g, '-')}`;
    let color;
    
    if (speciesLayers.value[speciesKey]) {
      // Use the same color for the same species across sources
      color = speciesLayers.value[speciesKey].color;
    } else if (!speciesLayers.value[layerId]) {
      // Generate a new color if this is a new species
      color = getRandomColor();
    } else {
      // Use existing color for this layer
      color = speciesLayers.value[layerId].color;
    }
    
    // Update or create layer info
    speciesLayers.value[layerId] = {
      color: color,
      name: species,
      count: features.length,
      source: sourceType // Store the source type
    };

    // Check if layer exists first
    if (map.getLayer(layerId)) {
      // Remove the layer first
      map.removeLayer(layerId);
    }

    // Then check if source exists
    if (map.getSource(layerId)) {
      try {
        // Try to update the source data if it exists
        const source = map.getSource(layerId) as ExtendedGeoJSONSource;
        if (source && typeof source.setData === 'function') {
          source.setData({
            type: 'FeatureCollection',
            features: features
          });
          
        } else {
          // If source exists but can't be updated, recreate it
          // First remove the source
          map.removeSource(layerId);

          // Then add it again
          map.addSource(layerId, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: features
            }
          });
          console.log(`[MyMap] Recreated source for ${layerId}`);
        }
      } catch (error) {
        console.error(`[MyMap] Error updating source ${layerId}:`, error);
      }
    } else {
      // Add new source if it doesn't exist
      map.addSource(layerId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features
        }
      });
      console.log(`[MyMap] Added new source for ${layerId}`);
    }

    // Add the layer if it doesn't exist
    if (!map.getLayer(layerId)) {
      map.addLayer({
        id: layerId,
        type: 'circle',
        source: layerId,
        paint: {
          'circle-radius': 4,
          'circle-color': color,
          'circle-opacity': [
            'case',
            ['has', 'image_url'], 0.7,
            0.2
          ],
          'circle-stroke-width': 0,
          // 'circle-stroke-color': '#fff'
        }
      });

      // Set initial visibility
      layerVisibility.value[layerId] = true;
      layerCounts.value[layerId] = features.length;

      // Add click handler for this layer
      map.on('click', layerId, (e) => {
        if (!e.features?.length) return;

        const feature = e.features[0];
        // Cast to specific geometry type that has coordinates
        const pointGeometry = feature.geometry as GeoJSON.Point;
        const coordinates = pointGeometry.coordinates.slice() as [number, number];
        if (!coordinates) return;

        console.log('[MyMap] Clicked feature:', feature);

        // Show popup with feature properties
        popupCoordinates.value = coordinates;
        popupContent.value = {
          ...feature.properties,
          source: feature.properties.source || 'Unknown'
        };
      });

      // Change cursor on hover
      map.on('mouseenter', layerId, () => {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', layerId, () => {
        map.getCanvas().style.cursor = '';
      });
    }
  }
}

// Add a debug function to log the state of all layers
const logLayerStatus = () => {
  console.log('===== LAYER STATUS =====');
  console.log('speciesLayers:', JSON.stringify(speciesLayers.value));
  console.log('layerVisibility:', JSON.stringify(layerVisibility.value));
  console.log('Has ALA Layers:', hasAlaLayers.value);
  console.log('Has iNaturalist Layers:', hasInaturalistLayers.value);
  console.log('======================');
}

// Replace the watch for currentStyle with this improved implementation
watch(currentStyle, async (newStyle, oldStyle) => {
  console.log('[MyMap] Style about to change from', oldStyle, 'to', newStyle);
  
  // Only proceed if map is available
  if (!mapInstance.map || !mapInstance.isLoaded) {
    console.warn('[MyMap] Map not ready, cannot store layers before style change');
    return;
  }
  
  try {
    // Store sources and layers before changing the style
    console.log('[MyMap] Storing sources and layers...');
    storeMapSourcesAndLayers();
    
    // Update the style URL (this triggers the style change)
    style.value = availableStyles.value[newStyle as keyof typeof availableStyles.value];
    
    // Wait briefly to ensure style change has started
    await new Promise(resolve => setTimeout(resolve, 50));
    
    // The handleStyleChange will be triggered by the map's styledata event
  } catch (error) {
    console.error('[MyMap] Error during style change:', error);
  }
}, { immediate: false });

// Add these near the top of the script section with other refs
const isRestoringLayers = ref(false);
const styleChangeTimeout = ref<NodeJS.Timeout | null>(null);

// Improve the handleStyleChange function to prevent infinite loops
const handleStyleChange = () => {
  // If we're already in the process of restoring layers, don't trigger another restore
  if (isRestoringLayers.value) {
    console.log('[MyMap] Already restoring layers, ignoring style change event');
    return;
  }

  // Clear any existing timeout
  if (styleChangeTimeout.value) {
    clearTimeout(styleChangeTimeout.value);
  }

  console.log('[MyMap] Style changed, waiting for style to load completely...');
  isRestoringLayers.value = true;
  
  // Set a one-time timeout with a single attempt
  styleChangeTimeout.value = setTimeout(() => {
    try {
      if (!mapInstance.map) {
        console.warn('[MyMap] Map instance not available');
        isRestoringLayers.value = false;
        return;
      }
      
      // Always wait a bit longer for style to fully load
      setTimeout(() => {
        console.log('[MyMap] Attempting to restore layers now');
        restoreMapSourcesAndLayers();
        
        // Important: Reset the flag after a delay to avoid multiple calls
        setTimeout(() => {
          isRestoringLayers.value = false;
        }, 500);
      }, 500);
    } catch (error) {
      console.error('[MyMap] Error during style change restoration:', error);
      isRestoringLayers.value = false;
    }
  }, 300);
};



// Update the soloLayer function to handle null (show all)
const soloLayer = (selectedLayerId: string | null) => {
  if (!mapInstance.map) return;
  
  // If selectedLayerId is null, show all layers
  if (selectedLayerId === null) {
    Object.keys(layerVisibility.value).forEach(layerId => {
      layerVisibility.value[layerId] = true;
      
      if (mapInstance.map && mapInstance.map.getLayer(layerId)) {
        mapInstance.map.setLayoutProperty(
          layerId,
          'visibility',
          'visible'
        );
      }
    });
    
    // Reset the currentSoloedLayer in both accordion components
    currentALASoloedLayer.value = null;
    currentINaturalistSoloedLayer.value = null;
    
    return;
  }
  
  // Update visibility state for all layers
  Object.keys(layerVisibility.value).forEach(layerId => {
    // Set all layers to hidden except the selected one
    const isVisible = layerId === selectedLayerId;
    layerVisibility.value[layerId] = isVisible;
    
    // Apply visibility to map layers if they exist
    if (mapInstance.map && mapInstance.map.getLayer(layerId)) {
      mapInstance.map.setLayoutProperty(
        layerId,
        'visibility',
        isVisible ? 'visible' : 'none'
      );
    }
  });
}

// Fix for isMapLoaded
const isMapLoaded = ref(false);

// Fix for bounds
const bounds = ref<LngLatBounds | null>(null);

// Add these refs to track which layer is currently soloed in each section
const currentALASoloedLayer = ref(null);
const currentINaturalistSoloedLayer = ref(null);

// Add this helper function
const getAvailableModes = (mode: string) => {
  return mode === 'dark' 
    ? Object.keys(mapStyles.dark) 
    : Object.keys(mapStyles.light);
}

// Add a method to check style loading status
const isStyleLoaded = () => {
  return mapInstance.map?.isStyleLoaded() || false;
};

// Helper function to format observer data
function formatObserver(observer: any): string {
  if (!observer) return '';

  // First convert to string in case it's an array or other type
  const observerStr = String(observer);
  
  // Remove all brackets, quotes, and extra characters more aggressively
  const cleaned = observerStr
    .replace(/[\[\]"'{}()]/g, '') // Remove various brackets and quotes
    .replace(/\\"/g, '') // Remove escaped quotes
    .trim(); // Remove extra whitespace

  // Split by semicolons or commas if present and clean each part
  if (cleaned.includes(';')) {
    return cleaned.split(';').map(part => part.trim()).join(', ');
  } else if (cleaned.includes(',')) {
    return cleaned;
  }
  return cleaned;
}

// Update fetchOccurrenceRecords to use abort controller
const fetchOccurrenceRecords = async (
  species: string,
  lat?: number,
  lng?: number,
  radiusKm?: number
) => {
  console.log('Fetching occurrence records for:', species)
  isLoadingOccurrences.value = true

  // Create new abort controller
  abortController.value = new AbortController()

  try {
    const { data } = await useFetch('/api/bio/search', {
      method: 'POST',
      body: {
        species,
        searchType: 'occurrences',
        lat,
        lng,
        radius: radiusKm || DEFAULT_RADIUS_KM
      },
      signal: abortController.value.signal // Add abort signal
    })

    console.log('Received occurrence data:', data.value)
    if (data.value?.occurrences) {
      console.log('Found occurrences:', data.value.occurrences.length)
      plotOccurrenceRecords(data.value.occurrences)
    }

  } catch (error) {
    if (error instanceof Error && error.name === 'AbortError') {
      console.log('Fetch aborted')
      return
    }
    console.error('Error fetching occurrence records:', error)
    push.error({
      title: "Error",
      message: "Failed to fetch occurrence records"
    })
  } finally {
    isLoadingOccurrences.value = false
    abortController.value = null
  }
}

// Add near the top of the script section with other reactive variables
const processedDataSignatures = ref(new Set<string>());

// Add a function to restore map features from panel data
const restoreMapFeaturesFromPanelData = () => {
  if (!props.panel.data?.savedFeatures || !mapInstance.map) return;
  
  try {
    const { sources, layers, bounds, center, zoom, speciesLayers: savedSpeciesLayers } = props.panel.data.savedFeatures;
    
    console.log('[MyMap] Restoring saved map state:', {
      sourcesCount: Object.keys(sources || {}).length,
      layersCount: Object.keys(layers || {}).length
    });
    
    // Get existing sources and layers from the map
    const existingSources = mapInstance.map.getStyle().sources || {};
    const existingLayers = mapInstance.map.getStyle().layers || [];
    const existingSourceIds = Object.keys(existingSources);
    const existingLayerIds = existingLayers.map(layer => layer.id);
    
    // Remove layers first (must happen before removing sources they depend on)
    existingLayerIds.forEach(layerId => {
      if (layerId.startsWith('maplibre-') || layerId.startsWith('mapbox-')) return;
      
      // Only remove layers that are not in the saved layers
      if (!layers || !(layerId in layers)) {
        try {
          mapInstance.map.removeLayer(layerId);
          console.log(`[MyMap] Removed layer: ${layerId}`);
        } catch (error) {
          console.error(`[MyMap] Error removing layer ${layerId}:`, error);
        }
      }
    });
    
    // Then remove sources
    existingSourceIds.forEach(sourceId => {
      if (sourceId.startsWith('maplibre-') || sourceId.startsWith('mapbox-')) return;
      
      // Only remove sources that are not in the saved sources
      if (!sources || !(sourceId in sources)) {
        try {
          mapInstance.map.removeSource(sourceId);
          console.log(`[MyMap] Removed source: ${sourceId}`);
        } catch (error) {
          console.error(`[MyMap] Error removing source ${sourceId}:`, error);
        }
      }
    });
    
    // Add/update sources
    if (sources) {
      Object.entries(sources).forEach(([id, sourceData]) => {
        try {
          // Check if source already exists
          if (mapInstance.map.getSource(id)) {
            // If source exists and is a GeoJSON source, we can update its data
            const source = mapInstance.map.getSource(id);
            if (source && typeof source.setData === 'function' && sourceData.data) {
              source.setData(sourceData.data);
              console.log(`[MyMap] Updated source data: ${id}`);
            }
          } else {
            // Add new source
            mapInstance.map.addSource(id, sourceData as maplibregl.SourceSpecification);
            console.log(`[MyMap] Added source: ${id}`);
          }
        } catch (error) {
          console.error(`[MyMap] Error adding/updating source ${id}:`, error);
        }
      });
    }
    
    // Add layers
    if (layers) {
      Object.entries(layers).forEach(([id, layerData]) => {
        try {
          if (!mapInstance.map.getLayer(id)) {
            mapInstance.map.addLayer(layerData);
            
            // Restore layer visibility
            if (layerVisibility.value[id] !== undefined) {
              const visibility = layerVisibility.value[id] ? 'visible' : 'none';
              mapInstance.map.setLayoutProperty(id, 'visibility', visibility);
            }
            
            console.log(`[MyMap] Added layer: ${id}`);
          }
        } catch (error) {
          console.error(`[MyMap] Error adding layer ${id}:`, error);
        }
      });
    }
    
    // Restore view state (bounds or center/zoom)
    try {
      if (bounds && bounds.length === 4) {
        mapInstance.map.fitBounds([
          [bounds[0], bounds[1]], // Southwest
          [bounds[2], bounds[3]]  // Northeast
        ]);
      } else if (center && center.length === 2 && zoom) {
        mapInstance.map.setCenter(center);
        mapInstance.map.setZoom(zoom);
      }
    } catch (error) {
      console.error('[MyMap] Error restoring view state:', error);
    }
    
    // Update the species layers state
    if (savedSpeciesLayers) {
      speciesLayers.value = savedSpeciesLayers;
    }
    
    console.log('[MyMap] Successfully restored map state from panel data');
  } catch (error) { 
    console.error('[MyMap] Error restoring map features from panel data:', error);
  }
};

// Update the saveMapFeaturesToPanelData function with better error handling
const saveMapFeaturesToPanelData = () => {
  if (!mapInstance.map || !mapLoaded.value) return;
  
  try {
    const map = mapInstance.map;
    const style = map.getStyle();
    
    // Check if style and its properties are available
    if (!style || !style.sources || !style.layers) {
      console.warn('[MyMap] Map style not fully loaded yet, skipping save');
      return;
    }
    
    const savedSources = {};
    const savedLayers = {};
    
    // Get all sources
    Object.entries(style.sources).forEach(([id, source]) => {
      // Skip built-in sources
      if (!id.startsWith('maplibre-') && !id.startsWith('mapbox-')) {
        savedSources[id] = source;
      }
    });
    
    // Get all layers
    style.layers.forEach(layer => {
      // Skip built-in layers
      if (!layer.id.startsWith('maplibre-') && !layer.id.startsWith('mapbox-')) {
        savedLayers[layer.id] = layer;
      }
    });
    
    // Only continue if we have bounds available
    if (!map.getBounds) {
      console.warn('[MyMap] Map bounds not available yet, skipping save');
      return;
    }
    
    // Get current view state
    const bounds = map.getBounds();
    const boundsArray = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth()
    ];
    
    // Save everything to panel data
    const savedFeatures = {
      sources: savedSources,
      layers: savedLayers,
      bounds: boundsArray,
      center: map.getCenter().toArray(),
      zoom: map.getZoom(),
      speciesLayers: speciesLayers.value,
      timestamp: Date.now()
    };
    
    // Only save if we have actual data to save
    if (Object.keys(savedSources).length === 0 && Object.keys(savedLayers).length === 0) {
      console.log('[MyMap] No custom sources or layers to save, skipping');
      return;
    }
    
    // mutate the panel data and the parent will update the panel
    props.panel.data = {
      ...props.panel.data,
      savedFeatures
    }

    console.log('[MyMap] Saved map features to panel data:', {
      sourcesCount: Object.keys(savedSources).length,
      layersCount: Object.keys(savedLayers).length
    });
  } catch (error) {
    console.error('[MyMap] Error saving map features to panel data:', error);
  }
};

// Add handler to save map state on major map events
const handleMapChange = useDebounceFn(() => {
  if (mapLoaded.value && mapInstance.map) {
    saveMapFeaturesToPanelData();
  }
}, 1000);

// Add event listeners for map changes
const setupMapChangeListeners = () => {
  if (!mapInstance.map) return;
  
  // Listen for events that should trigger saving the map state
  mapInstance.map.on('moveend', handleMapChange);
  mapInstance.map.on('zoomend', handleMapChange);
  mapInstance.map.on('styledata', handleMapChange);
};

// Add these near the top with other reactive variables
const showTernWms = ref(true)
const ternLayersVisible = ref<Record<string, boolean>>({
  'ASC': true,  // Australian Soil Classification
})

// Add this function to toggle the TERN WMS layer visibility
const toggleTernLayer = (layerId: string) => {
  ternLayersVisible.value[layerId] = !ternLayersVisible.value[layerId]
  updateTernLayerVisibility()
}

// Function to update TERN layer visibility
const updateTernLayerVisibility = () => {
  if (!mapInstance.map) return

  Object.entries(ternLayersVisible.value).forEach(([layerId, isVisible]) => {
    const mapLayerId = `tern-wms-${layerId}`
    if (mapInstance.map?.getLayer(mapLayerId)) {
      mapInstance.map.setLayoutProperty(
        mapLayerId,
        'visibility',
        isVisible ? 'visible' : 'none'
      )
    }
  })
}

// Fix the WMS layer addition to use the correct ASRIS WMS URL
const addTernWmsLayers = () => {
  if (!mapInstance.map) return

  const map = mapInstance.map

  // Add TERN WMS source if it doesn't exist
  if (!map.getSource('tern-wms-source')) {
    map.addSource('tern-wms-source', {
      'type': 'raster',
      'tiles': [
        'https://www.asris.csiro.au/arcgis/services/TERN/ASC_ACLEP_AU_NAT_C/MapServer/WmsServer?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&WIDTH=256&HEIGHT=256&LAYERS=ASC_EV_C_P_AU_TRN_N&STYLES=&FORMAT=image/png&TRANSPARENT=TRUE'
      ],
      'tileSize': 256
    })

    try {
      // Add the WMS layer before any other layers to ensure it's at the bottom
      const firstSymbolLayer = map.getStyle().layers.find(layer => layer.type === 'symbol')
      
      map.addLayer({
        'id': 'tern-wms-ASC',
        'type': 'raster',
        'source': 'tern-wms-source',
        'paint': {
          'raster-opacity': 0.7
        },
        'layout': {
          'visibility': ternLayersVisible.value['ASC'] ? 'visible' : 'none'
        }
      }, firstSymbolLayer?.id); // Insert before the first symbol layer
      
      console.log('[MyMap] Added Australian Soil Classification WMS layer')
    } catch (error) {
      console.error('[MyMap] Error adding WMS layer:', error)
    }
  }

  // Update the layer visibility state
  updateTernLayerVisibility()
}

// Watch for map loaded state to add WMS layers
watch(() => mapInstance.isLoaded, (isLoaded) => {
  if (isLoaded && mapInstance.map) {
    // Wait for the style to be fully loaded
    mapInstance.map.once('styledata', () => {
      if (showTernWms.value) {
        addTernWmsLayers()
      }
    })
  }
}, { immediate: true })

// Watch showTernWms to add/remove the layers
watch(showTernWms, (newValue) => {
  if (newValue==true && mapInstance.map && mapInstance.isLoaded) {
    addTernWmsLayers()
  } else if (!newValue && mapInstance.map) {
    // If turning off, hide the layers
    Object.keys(ternLayersVisible.value).forEach(layerId => {
      const mapLayerId = `tern-wms-${layerId}`
      if (mapInstance.map?.getLayer(mapLayerId)) {
        mapInstance.map?.setLayoutProperty(mapLayerId, 'visibility', 'none')
      }
    })
  }
})

</script>

<template>
  <div class="flex flex-col w-full h-full justify-center items-center">
    <!-- Loading spinner for initial map load -->
    <div v-if="!mapInstance.isLoaded" class="absolute flex flex-col w-full h-full justify-center items-center">
      <Icon name="lucide:loader-circle" class="w-12 h-12 animate-spin" />
    </div>

    <!-- Loading spinner for occurrence fetching -->
    <div v-if="isLoadingOccurrences"
      class="absolute z-50 flex flex-col w-full h-full justify-center items-center bg-background/50 backdrop-blur-sm">
      <div class="flex flex-col items-center space-y-2">
        <Icon name="lucide:loader-circle" class="w-12 h-12 animate-spin" />
        <span class="text-sm text-muted-foreground">Loading occurrence records...</span>
        <span @click="() => { if (abortController.value) abortController.value.abort(); isLoadingOccurrences = false }"
          class="text-xs text-muted-foreground opacity-75 cursor-pointer hover:opacity-100">
          <kbd
            class="pointer-events-none inline select-none align-center justify-center items-center gap-1 rounded border bg-muted px-1.5 pb-0.5 font-mono font-medium text-muted-foreground">
            <Icon name="mdi:keyboard-esc" class="w-4 h-4" />
          </kbd>
          to cancel
        </span>
      </div>
    </div>

    <div class="w-full h-full relative">
      <div class="overflow-hidden w-full h-full" ref="mapContainer">
        
        <!-- <div class="absolute top-2 right-14 z-20">
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <Button size="sm" variant="ghost" class="self-center rounded-full w-8 h-8 p-0">
                <Icon name="mdi:dots-vertical" size="1.5em" class="w-5 h-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" class="w-48 rounded-xl ring ring-1 ring-muted-foreground/10">
              <DropdownMenuItem @select="clearMapCache" class="m-1 cursor-pointer">
                <Icon name="mingcute:broom-fill" class="mr-2 h-4 w-4" />
                Clear Cache
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div> -->
        
        
        
        
        
        
        
          

          <div class="flex justify-center align-center items-center w-full h-full">
            <div class="absolute top-0 left-2 md:left-24 z-20">
              <div class="flex flex-row w-[300px] h-fit p-4">
                <Button 
                  v-for="(url, styleName) in availableStyles" 
                  :key="styleName"
                  @click="currentStyle = styleName"
                  variant="ghost" 
                  :class="[
                    'shadow-xl aspect-square ml-1 whitespace-nowrap m-2 w-14 h-14 !p-0 m-0',
                    { 
                      '!border-2 border-muted-foreground': currentStyle === styleName, 
                      'border-white': currentStyle === 'satellite', 
                      '!border-black': styleName === 'toner' || styleName === 'grayscale'
                    }
                  ]"
                >
                  <img 
                    :src="`/map-styles/${colorMode.value}-${styleName}.webp`"
                    :class="['w-14 h-13 m-0 p-0 rounded-md']" 
                    :alt="`${styleName} map style`"
                  />
                </Button>
                <V-Tooltip :content="showTernWms ? 'Turn off soil layer' : 'Turn on soil layer'" :delay="0">
                <Button 
                  @click="showTernWms = !showTernWms"
                  variant="ghost" 
                  :class="[
                    'shadow-xl aspect-square ml-1 whitespace-nowrap m-2 w-14 h-14 !p-0 m-0',
                    { 
                      '!border-2 border-muted-foreground': showTernWms, 
                      'border-black': showTernWms,
                    }
                  ]"
                >
                  <img 
                    :src="`/map-styles/${colorMode.value}-soil.webp`"
                    :class="['w-14 h-13 m-0 p-0 rounded-md']" 
                    :alt="`soil map layer`"
                  />
                </Button>
              </V-Tooltip>
              </div>
            </div>
            <!-- loading overlay -->
            <div v-if="isLoadingOccurrences" class="absolute top-0 left-0 w-full h-full bg-background/50 backdrop-blur-sm z-50">
              <Icon name="line-md:loading-loop" class="w-12 h-12" />
            </div>
            <div v-if="props.panel" class="w-full h-full">
              <ClientOnly>
                <MglMap 
                  :trackResize="true"
                  
                  ref="mapRef" 
                  :mapStyle="style" 
                  :center="center" 
                  :zoom="zoom" 
                  @map:move="debouncedHandleMove"
                  @map:load="handleMapLoaded" 
                  @map:click="handleMapClick" 
                  @map:error="handleError"
                  @map:styledata="handleStyleChange"
                >
                  <template #default>


                    <!-- Popup for occurrence details -->
                    <MglPopup v-if="popupCoordinates && popupContent" :coordinates="popupCoordinates"
:closeButton="true" :closeOnClick="true" :offset="10" @close="closePopup">
<div class="p-4 max-w-[320px] bg-white dark:bg-background rounded-lg shadow-xxl !m-0">
  <div v-if="popupContent.image_url" class="mb-3 relative overflow-visible h-[180px]">
    <div class="w-full h-full bg-muted animate-pulse rounded-md absolute top-0 left-0"></div>
    <NuxtImg ref="imageRef" :src="popupContent.image_url"
    class="select-none w-full h-full rounded-md object-cover transition-transform duration-300 ease-in-out z-10 relative" 
    :class="{ 'scale-120 shadow-lg': isImageHovered }" @mouseenter="isImageHovered = true"
    @mouseleave="isImageHovered = false" placeholder loading="lazy" />
  </div>
  <div class="flex flex-col space-y-2">
    <h3 class="text-lg font-medium truncate" :title="popupContent.species || 'Unknown species'">{{
      popupContent.species || 'Unknown species' }}</h3>
      
      <div class="grid grid-cols-3 gap-4 mt-2">
        <div v-if="popupContent.observer" class="col-span-1">
          <div class="text-[0.9em] text-muted-foreground/70 uppercase tracking-wide font-medium">
            OBSERVER</div>
            <div class="text-xs text-muted-foreground truncate max-w-full"
            :title="popupContent.observer">{{ popupContent.observer.replace(' ', '').replace(',', '').replace('[', '').replace(']', '').replace('(', '').replace(')', '').replace('"', '') }}</div>
          </div>
          
          <div v-if="popupContent.date" class="col-span-1">
            <div class="text-[0.9em] text-muted-foreground/70 uppercase tracking-wide font-medium">
              DATE</div>
              <div class="text-xs text-muted-foreground truncate"
              :title="formatDate(popupContent.date)">{{ formatDate(popupContent.date) }}</div>
            </div>
            
            <div v-if="popupContent.source" class="col-span-1">
              <div class="text-[0.9em] text-muted-foreground/70 uppercase tracking-wide font-medium">
                SOURCE</div>
                <div class="text-xs text-muted-foreground truncate" :title="popupContent.source">{{
                  popupContent.source }}</div>
                </div>
              </div>
              
              <div v-if="popupContent.license_code" class="text-xs text-muted-foreground/70 flex items-center mt-2">
                <Icon name="lucide:copyright" class="w-3 h-3 mr-1" />
                <a :href="getLicenseUrl(popupContent.license_code)" target="_blank" class="hover:underline">
                  {{ formatLicense(popupContent.license_code) }}
                </a>
              </div>
              
              <a v-if="popupContent.uri" :href="popupContent.uri" target="_blank"
              class="text-[0.9em] text-primary hover:underline mt-1 inline-flex items-center">
              View on {{ popupContent.source }}
              <Icon name="lucide:external-link" class="w-3 h-3 ml-1" />
            </a>
          </div>
        </div>
      </MglPopup>
                  </template>
                </MglMap>
              </ClientOnly>
            </div>
            <div v-else>
              <span alt="prop panel is undefined">Oops something went wrong</span>
            </div>
          </div>

          <!-- Sidebar for Legend -->
          <div class="absolute inset-y-0 left-0 h-full">
            <SidebarProvider v-model:open="sidebarOpen" class="relative">
              <Sidebar class="absolute inset-y-0 left-0 w-[280px] z-20 shadow-lg" collapisble="offcanvas">
                <SidebarContent class="p-4 h-fit bg-background/30 backdrop-blur-md flex flex-col">                                    
                  <SidebarGroup>
                    <SidebarGroupLabel class="text-md font-regular pt-4 select-none -left-2 relative mb-2" @click="sidebarOpen = !sidebarOpen">
                      Settings
                    </SidebarGroupLabel>
                    <SidebarGroupContent class="max-h-[60vh] overflow-y-auto py-2 !overflow-x-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted-foreground/20 hover:scrollbar-thumb-muted-foreground/40 scrollbar-thumb-rounded-full">
                      <Accordion type="multiple" collapsible class="w-full !overflow-x-hidden">
                        <!-- ALA Section -->
                        <SpeciesLayerAccordion
                          v-if="hasAlaLayers"
                          title="Atlas of Living Australia"
                          value="ala-section"
                          source="ALA"
                          :layers="speciesLayers"
                          :layer-visibility="layerVisibility"
                          v-model:current-soloed-layer="currentALASoloedLayer"
                          @toggle-layer="toggleLayer"
                          @remove-layer="removeLayer"
                          @solo-layer="soloLayer"
                        />

                        <!-- iNaturalist Section -->
                        <SpeciesLayerAccordion
                          v-if="hasInaturalistLayers"
                          title="iNaturalist"
                          value="inaturalist-section"
                          source="iNaturalist"
                          :layers="speciesLayers"
                          :layer-visibility="layerVisibility"
                          v-model:current-soloed-layer="currentINaturalistSoloedLayer"
                          @toggle-layer="toggleLayer"
                          @remove-layer="removeLayer"
                          @solo-layer="soloLayer"
                        />
                      </Accordion>
                      
                      <!-- Debug info -->
                      
                    </SidebarGroupContent>
                  </SidebarGroup>
                  <div class="flex flex-col gap-2 w-full">
                    <div class="p-4 border-t flex flex-col gap-2 w-full !whitespace-nowrap">
                      <div class="flex items-center space-x-2 cursor-pointer rounded-lg hover:bg-background  transition-all p-1" @click="hideMarkersWithoutPhotos = !hideMarkersWithoutPhotos; updateMarkerVisibility()" :class="{ 'pl-4': hideMarkersWithoutPhotos }" >
                        <div class="min-w-12 flex items-center space-x-2">
                        <Button 
                          variant="ghost" 
                          class="rounded-full w-12 h-12 flex items-center justify-center transition-all relative px-0"
                          :class="{
                            '-left-6 -mr-8': hideMarkersWithoutPhotos
                          }"
                          aria-label="Toggle photo visibility"
                        >
                        <Icon name="solar:map-point-linear" class="w-5 h-5 transition-all text-primary/30" :class="{ '!text-primary': hideMarkersWithoutPhotos }" />
                        </Button>
                        <span v-motion-fade-visible class="font-light text-xl !px-0 !mx-0 relative -left-1">{{ hideMarkersWithoutPhotos ? '+' : '' }}</span>
                        <Icon v-motion-fade-visible
                            v-if="hideMarkersWithoutPhotos"
                            name="ion:md-images" 
                            class="w-6 h-6 transition-all !px-0 !-ml-1 !-mr-1" 
                            :class="{ 
                              '!text-primary': hideMarkersWithoutPhotos,
                              '!text-background': !hideMarkersWithoutPhotos,
                              'scale-100': !hideMarkersWithoutPhotos
                            }"
                          />
                        </div>
                        <div class="flex flex-col select-none" :class="{ '!pl-2': hideMarkersWithoutPhotos }" >
                          <span class="text-sm font-medium">{{ hideMarkersWithoutPhotos ? 'Markers with photos' : 'All markers' }}</span>
                          <span class="text-xs text-muted-foreground tracking-normal" v-html="hideMarkersWithoutPhotos ? 'Toggle to see all markers' : 'Toggle to hide markers<br/>without photos'" />
                        </div>
                      </div>
                    </div>
                  </div>
                </SidebarContent>
              </Sidebar>
              <div class="absolute right-0 top-7 z-50">
                <SidebarTrigger :as-child="true">
                  <template #default="{ toggle }">
                    <Button 
                      variant="text" 
                      class="hidden md:flex absolute top-2 h-7 z-20 pointer-events-auto"
                      :class="[
                        !sidebarOpen ? 'left-0' : 'right-0'
                      ]"
                      @click="toggle"
                    >
                      <span class="pr-2 ml-4 tracking-tight font-regular text-sm text-muted-foreground" v-if="!sidebarOpen">Settings</span>
                      <Icon 
                        :name="!sidebarOpen ? 'lucide:chevron-right' : 'lucide:chevron-left'" 
                        class="h-4 w-4 text-muted-foreground relative"
                        :class="{ 'left-4': sidebarOpen }"
                      />
                    </Button>
                  </template>
                </SidebarTrigger>
              </div>
            </SidebarProvider>
          </div>
        
      </div>

    </div>
    
  </div>
</template>

<style>
@import "maplibre-gl/dist/maplibre-gl.css";

.map-container {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px !important;
}

.maplibregl-ctrl-top-right *{
  box-shadow: none !important;
}

.dark .maplibregl-ctrl-top-right * {
  background-color: hsl(var(--background)) !important;
  border-color: hsl(var(--muted)) !important;
}

.dark .maplibregl-ctrl-compass *{
  
}

.dark .maplibregl-ctrl-scale{
  background-color: hsl(var(--background)) !important;
  color: hsl(var(--muted-foreground)) !important;
}

.dark .maplibregl-ctrl .maplibregl-ctrl-scale *{
  color: hsl(var(--muted-foreground)) !important;
}

.dark .maplibregl-ctrl-top-right::after{
  content: '+-';
  font-size: 1.3rem !important;
  text-indent: -0.1em;
  font-weight: 600 !important;
  font-family: theme('fontFamily.intervariable') !important;
  color: hsl(var(--muted-foreground)) !important;
  width: 5px;
  word-break: break-all !important;
  line-height: 1.9rem !important;
  height: 100% !important;
  border-radius: 0px !important;
  position: absolute !important;
  top: 8px !important;
  left: 10px !important;
  text-align: left !important;
  pointer-events: none !important;  
}


.dark .maplibregl-ctrl .maplibregl-ctrl-icon{
  /* filter: invert(1) !important; */
}

.maplibregl-ctrl-icon *{
  /* color: hsl(var(--primary)) !important; */
  /* fill: hsl(var(--primary)) !important; */
  /* background-color: hsl(var(--background)) !important; */
}

.maplibregl-map {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px !important;
}

:deep(.maplibregl-ctrl-attrib) {
  display: none !important;
}

.maplibregl-popup-content{
  padding: 0 !important;
}

:deep(.maplibregl-popup-content) {
  border-radius: 8px !important;
  padding: 0px !important;
  overflow: visible !important;
  background-color: hsl(var(--background)) !important;
  color: hsl(var(--foreground)) !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
}

:deep(.maplibregl-popup-tip) {
  border-top-color: hsl(var(--background)) !important;
  border-bottom-color: hsl(var(--background)) !important;
}

/* change x close button to bigger */
.maplibregl-popup-close-button {
  font-size: 1.3rem !important;
  font-weight: 300 !important;
  font-family: theme('fontFamily.intervariable') !important;
  margin: 0.5rem !important;
  line-height: 1rem !important;
  /* padding: 0.25rem !important; */
  padding-top: 0rem !important;
  color: hsl(var(--foreground)) !important;
  background-color: hsl(var(--background)) !important;
  border-radius: 50% !important;
  width: 2rem !important;
  height: 2rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: 0rem !important;
  right: 0rem !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
  z-index: 10 !important;
}

.maplibregl-popup-tip{
  border-top-color: hsl(var(--background)) !important;
}

.maplibregl-popup-close-button:hover {
  background-color: hsl(var(--primary)) !important;
  color: hsl(var(--background)) !important;
}

/* Add styles for image hover effect */
.maplibregl-popup-content img {
  transform-origin: center;
  position: relative;
}

.maplibregl-popup-content img.scale-120 {
  transform: scale(4) !important;
  z-index: 500 !important;
}

/* Ensure the popup content doesn't clip the image */
.maplibregl-popup-content {
  background: transparent !important;
  box-shadow: none !important;
  overflow: visible !important;
  margin: 0 !important;
}

/* Add styles for sidebar */
:root {
  --sidebar-background: hsl(var(--background));
  --sidebar-foreground: hsl(var(--foreground));
  --sidebar-primary: hsl(var(--primary));
  --sidebar-primary-foreground: hsl(var(--primary-foreground));
  --sidebar-accent: hsl(var(--muted));
  --sidebar-accent-foreground: hsl(var(--muted-foreground));
  --sidebar-border: hsl(var(--border));
  --sidebar-ring: hsl(var(--ring));
}

.dark {
  --sidebar-background: hsl(var(--background));
  --sidebar-foreground: hsl(var(--foreground));
  --sidebar-primary: hsl(var(--primary));
  --sidebar-primary-foreground: hsl(var(--primary-foreground));
  --sidebar-accent: hsl(var(--muted));
  --sidebar-accent-foreground: hsl(var(--muted-foreground));
  --sidebar-border: hsl(var(--border));
  --sidebar-ring: hsl(var(--ring));
}

/* Update sidebar positioning styles */
:deep(.group[data-variant=floating]) {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: 3.5rem;
  height: calc(100% - 3.5rem);
}

:deep(.group[data-variant=floating] [data-sidebar=sidebar]) {
  background-color: hsl(var(--background));
  border-right: 1px solid hsl(var(--border));
  box-shadow: none;
  height: 100%;
}

/* Ensure the sidebar content scrolls properly */
:deep(.group[data-variant=floating] [data-sidebar=sidebar] [data-radix-scroll-area-viewport]) {
  height: 100%;
}

:deep(.maplibregl-ctrl-group) {
  margin: 20px 20px 0 0
}


.maplibregl-ctrl-scale{
  left: 20px !important;
  bottom: 15px !important;
  border-radius: 6px !important;
  border: 0px;
  position: relative !important;
}

.maplibregl-ctrl-attrib{
  display: none !important;
}

.maplibregl-ctrl-attrib.maplibregl-compact{
  display: none !important;
}



</style>
