import type { GridData, InferredColumnData } from '@/types/schedule'
import type { PanelOutput } from '@/types/projectdock'
import { columnSchema, columnDisplayLabels } from '@/types/schedule'
import { storeToRefs } from 'pinia'

interface PerfectTable {
  rows: string[][]
  isPerfectTable: boolean
}

interface TableData {
  headers: string[]
  rows: any[][]
}

export const usePlantGrid = () => {
  /**
   * Parse tool output into a format compatible with AG Grid
   * @param output The tool output to parse
   * @param parser The parser to use (currently supports 'llamaparse', 'gemini')
   * @returns GridData object with columnDefs and rowData
   */
  const parse = (output: PanelOutput | undefined, parser: 'llamaparse' | 'gemini' = 'llamaparse'): GridData | null => {
    console.log('Starting parse with:', { output, parser });
    if (!output) {
      console.log('No output provided');
      return null;
    }

    switch (parser) {
      case 'llamaparse':
        console.log('Using llamaparse parser');
        return parseLlamaOutput(output);
      case 'gemini':
        console.log('Using gemini parser');
        return parseGeminiOutput(output);
      default:
        console.warn(`Unknown parser type: ${parser}`);
        return null;
    }
  }

  /**
   * Extract table data from LlamaCloud output
   */
  const extractTableData = (output: PanelOutput): TableData | null => {
    if (!output?.json?.[0]) return null
    
    const items = output.json[0].items || output.json[0].rowsitems
    if (!items) return null

    // Find the first perfect table in the items
    const perfectTable = items.find((item: any): item is PerfectTable => 
      item?.isPerfectTable && Array.isArray(item.rows) && item.rows.length >= 2
    )
    if (!perfectTable) return null

    return {
      headers: perfectTable.rows[0],
      rows: perfectTable.rows.slice(1)
    }
  }

  /**
   * Parse LlamaCloud output format into AG Grid format
   */
  const parseLlamaOutput = (output: PanelOutput): GridData | null => {
    const data = extractTableData(output)
    if (!data) return null

    const { headers, rows } = data

    // Create column definitions with metadata
    const columnDefs = headers.map((header: string) => {
      // Default column definition
      const colDef = {
        field: header,
        headerName: header,
        sortable: true,
        filter: true,
        resizable: true,
        type: 'textColumn', // Default type, can be overridden later
        metadata: {
          units: null,
          originalType: 'string' as const
        }
      }

      // Infer number type if all values in column are numbers
      const columnValues = rows.map(row => row[headers.indexOf(header)])
      const isNumberColumn = columnValues.every(value => 
        value === null || value === '' || !isNaN(Number(value))
      )

      if (isNumberColumn) {
        colDef.type = 'numberColumn'
        colDef.metadata.originalType = 'number'
      }

      return colDef
    })

    // Convert rows to AG Grid format
    const rowData = rows.map((row: any[]) => {
      const rowObj: Record<string, any> = {}
      headers.forEach((header: string, index: number) => {
        const value = row[index]
        // Convert to number if the column is numeric
        if (columnDefs[index].type === 'numberColumn' && value !== null && value !== '') {
          rowObj[header] = Number(value)
        } else {
          rowObj[header] = value
        }
      })
      return rowObj
    })

    return {
      columnDefs,
      rowData
    }
  }

  /**
   * Parse Gemini output format into AG Grid format
   */
  const parseGeminiOutput = (output: PanelOutput): GridData | null => {
    // Get the JSON data from the output
    const jsonDataRaw = output?.json;
    if (!jsonDataRaw) return null;

    // Handle array or direct object response
    let jsonData: any[] = [];
    if (Array.isArray(jsonDataRaw)) {
      jsonData = jsonDataRaw;
    } else if (typeof jsonDataRaw === 'object') {
      jsonData = [jsonDataRaw];
    }

    if (jsonData.length === 0) return null;

    // Use the predefined schema from schedule.ts
    const headers = Object.keys(columnSchema.properties)

    // Create column definitions with metadata
    const columnDefs = headers.map(header => {
      const schemaProperty = columnSchema.properties[header as keyof typeof columnSchema.properties];
      const colDef = {
        field: header,
        headerName: columnDisplayLabels[header as keyof typeof columnDisplayLabels],
        sortable: true,
        filter: true,
        resizable: true,
        type: schemaProperty.type === 'number' ? 'numberColumn' : 'textColumn',
        metadata: {
          units: null,
          originalType: schemaProperty.type as 'string' | 'number'
        }
      };
      return colDef;
    });

    // Convert rows to AG Grid format, ensuring they match the schema
    const rowData = jsonData.map(row => {
      const formattedRow: Record<string, any> = {};
      headers.forEach(header => {
        const value = row[header];
        const schemaProperty = columnSchema.properties[header as keyof typeof columnSchema.properties];
        if (schemaProperty.type === 'number' && value !== null && value !== '') {
          formattedRow[header] = Number(value);
        } else {
          formattedRow[header] = value ?? null;
        }
      });
      return formattedRow;
    });

    return {
      columnDefs,
      rowData
    };
  }

  return {
    parse,
    extractTableData
  }
} 