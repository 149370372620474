<script setup lang="ts">
import { useTools } from '@/composables/tools/useTools';
const props = defineProps({
    panel: { type: Object, required: true },
    height: { type: [Number, String], required: false },
    panel_id: { type: String, required: true },
    toolLabel: { type: String, required: true },
    dataKey: { type: String, default: 'data' },
});

// Declare emits
const emit = defineEmits(['error', 'update:panel']);

const dockStore = useDockStore();
const { saving } = storeToRefs(dockStore);

const projectData = useProjectData();
const { setupPanelWatchers } = projectData;

const { tools } = useTools();

const data = ref(null)
const metadata = ref(null)

const tool_id = computed(() => tools.find(tool => tool.label === props.toolLabel)?.uid)

// Use the new panel data composable
const panelData = usePanelData()



// Watch for data changes
watch(() => data.value, (newData) => {
    if (newData) {
        updateState(newData)
    }
}, { deep: true })

// Watch for metadata changes
watch(() => metadata.value, (newMetadata) => {
    if (newMetadata) {
        panelData.updatePanelData(props.panel_id, {
            ...props.panel.data,
            metadata: newMetadata
        })
        emit('update:panel', props.panel)
    }
}, { deep: true })

// Watch for external panel data changes
watch(() => panelData.getPanelData(props.panel_id), (newPanelData) => {
    if (newPanelData) {
        data.value = newPanelData[props.dataKey]
        metadata.value = newPanelData.metadata
    }
}, { deep: true })

// Handle error
const handleError = (error: any) => {
    emit('error', error)
}
</script>

<template>
    <slot
        :height="height"
        :data="data" 
        :tool_id="tool_id" 
        :metadata="metadata"
        :panel_id="panel_id"
        :updateState="updateState"
        :onError="handleError"
    >
    {{ data }}
    </slot>
</template>