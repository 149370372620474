<script setup lang="ts">
import { useValidation } from '@/composables/useValidation'
import { useSpeciesDiversity } from '@/composables/useSpeciesDiversity'
import { useValidationStore } from '@/stores/validationStore'
import { useDockStore } from '@/stores/dockStore'
import { useProjectStore } from '@/stores/projectStore'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Badge } from '@/components/ui/badge'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion'
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card'
import { Loader2 } from 'lucide-vue-next'
import type { TNRSResponse } from '@/types/validation'
import chroma from 'chroma-js'
import ContainerScrollCard from '@/components/ContainerScrollCard.vue'
import hashSum from 'hash-sum'
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { TooltipComponent, GridComponent } from 'echarts/components'
import { ToolboxComponent } from 'echarts/components'
import { theme } from '#tailwind-config'
import { useResizeObserver, useElementSize } from '@vueuse/core'
import { useScroll, useIntersectionObserver } from '@vueuse/core'
import { slowOut } from '@/lib/utils'
import type { VChart } from 'vue-echarts'
import type { PanelState } from '@/types/panels'
import { Skeleton } from '@/components/ui/skeleton'
import { Button } from '@/components/ui/button'
import { useTools } from '@/composables/tools/useTools'
import { toolDefinitions } from '@/config/tools'

use([BarChart, TooltipComponent, GridComponent, ToolboxComponent])

interface Row {
  [key: string]: any
}

// Add interface for the plant data structure
interface Plant {
  species: string
  genus: string
  family: string
  quantity: number
  score: number
  originalName?: string
  source?: string
  matchType?: string
}

interface MitterEvents {
  'name:validation:results': any
  // Other events could be defined here
}

const { fetchTnrs } = useValidation()
const { evaluateDiversity, calculateSimpsonsIndex } = useSpeciesDiversity()
const validationStore = useValidationStore()
const { allValidations } = storeToRefs(validationStore)
const { addValidation } = validationStore
const userStore = useUserStore()
const { plan } = storeToRefs(userStore)
const planName = computed(() => plan.value?.product?.name || '')

const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)

const dockStore = useDockStore()
const { activePanel, panelToRefresh } = storeToRefs(dockStore)

const { setActivePanel } = dockStore

const { tools } = useTools()
const mitter = useMitter()
useSquircleObserver()

const props = defineProps<{
  data?: string[],
  metadata?: any,
  cell?: any,
  project_id?: string,
  panel_id?: string
}>()

const emit = defineEmits(['results'])

const speciesNames = ref<string[]>([])

const validationLoadingState = ref<"idle" | "pending" | "error">("idle")
const summaryLoadingState = ref<"idle" | "pending" | "error">("idle")
const santamourLoadingState = ref<"idle" | "pending" | "error">("idle")
const simpsonLoadingState = ref<"idle" | "pending" | "error">("idle")

const processedSpecies = ref<any[]>([])
const diversityResults = ref<any>(null)
const unmatchedSpecies = ref<any[]>([])
const noQuantitySpecies = ref<any[]>([])
const biodiversitySummary = ref('')
const isRefreshing = ref(false)
// Add a cache for the last processed species names
const lastProcessedSpecies = ref<Set<string>>(new Set())


const tool = toolDefinitions.find(t => t.id === 'diversityValidation')


mitter.listen('name:validation:results' as keyof MitterEvents, (event: any) => {
  console.log('[DiversityStatus] Received name validation results:', event)
  
  if (!event || !event.plants || !Array.isArray(event.plants)) {
    console.warn('[DiversityStatus] Invalid validation results format', event)
    return
  }

  // Reset data arrays
  processedSpecies.value = []
  unmatchedSpecies.value = []
  noQuantitySpecies.value = []
  
  // Set species names from the validation results
  speciesNames.value = event.plants.map((plant: Plant) => plant.originalName || plant.species)
  
  // Log the validated names we received
  console.log('[DiversityStatus] Received validated names:', 
    event.plants.map((p: Plant) => ({
      original: p.originalName,
      validated: p.species,
      matchType: p.matchType,
      source: p.source
    }))
  )
  
  // Process plants from the validation results
  // Each plant should represent one original botanical name with its best match
  const validPlants = event.plants
    .filter((plant: Plant) => {
      // Only include plants that have a valid match and quantity
      const hasValidMatch = plant.matchType === 'exact' || plant.matchType === 'inexact'
      const hasValidQuantity = plant.quantity && plant.quantity > 0

      // Track plants without quantities
      if (!hasValidQuantity && hasValidMatch) {
        noQuantitySpecies.value.push({
          species: plant.species,
          genus: plant.genus,
          family: plant.family,
          quantity: null,
          rawQuantity: plant.quantity
        })
        return false
      }

      // Track unmatched plants
      if (!hasValidMatch) {
        unmatchedSpecies.value.push({
          name: plant.originalName || plant.species,
          score: plant.score,
          matched: plant.species || null
        })
        return false
      }

      return hasValidMatch && hasValidQuantity
    })
    .map((plant: Plant) => {
      // Create processed species object
      const speciesData = {
        species: plant.species,
        genus: plant.genus || plant.species.split(' ')[0],
        family: plant.family || 'Unknown',
        quantity: plant.quantity,
        score: plant.score,
        // Pass through additional useful information
        originalName: plant.originalName,
        source: plant.source,
        matchType: plant.matchType
      }
      
      // Add to processedSpecies array for display
      processedSpecies.value.push(speciesData)
      
      return speciesData
    })
    .filter(Boolean) as Plant[]
  
  // Log the plants being used for diversity evaluation
  console.log('[DiversityStatus] Plants for diversity evaluation:', 
    validPlants.map(p => ({
      species: p.species,
      genus: p.genus,
      quantity: p.quantity,
      originalName: p.originalName,
      source: p.source,
      matchType: p.matchType
    }))
  )
  
  // Evaluate diversity with the processed plants
  if (validPlants.length > 0) {
    const results = evaluateDiversity(validPlants)
    diversityResults.value = results
    
    // Create validation object
    const validation = {
      name: 'diversityStatus',
      result: results,
      show: !results.isValid,
      unmatchedSpecies: unmatchedSpecies.value,
      noQuantitySpecies: noQuantitySpecies.value
    }
    
    // Add to validation store
    if (selectedProject.value?.id) {
      addValidation(validation, selectedProject.value.id.toString())
    }
    
    emit('results', validation)
    
    // Generate summary with the new data
    refreshSummary()
    
    // Recalculate Simpson's index
    refreshSimpson()
  } else {
    console.warn('[DiversityStatus] No valid plants found for diversity evaluation')
  }
})



const formatNumber = (value: number | null | undefined): string => {
  if (value === null || value === undefined) return 'no value'
  return value.toLocaleString('en-US', { maximumFractionDigits: 0 })
}

const formatPercentage = (count: number, total: number): string => {
  return `${Math.round((count / total) * 100)}%`
}

const parseQuantity = (value: any): number | null => {
  if (value === null || value === undefined) return null

  // Convert to string and clean up
  let str = String(value).trim()

  // Remove any non-numeric characters except dots and commas
  str = str.replace(/[^0-9.,]/g, '')

  // Handle different number formats:
  // 1. If there's a period, treat commas before it as thousand separators
  // 2. If there are multiple commas (e.g., "1,234,567"), treat them as thousand separators
  // 3. If there's only one comma and it's not followed by exactly 3 digits, treat it as a decimal point
  if (str.includes('.')) {
    // Remove all commas (treating them as thousand separators)
    str = str.replace(/,/g, '')
  } else if ((str.match(/,/g) || []).length > 1) {
    // Multiple commas - treat as thousand separators
    str = str.replace(/,/g, '')
  } else if (str.includes(',')) {
    // Single comma - check if it's a thousand separator or decimal point
    const parts = str.split(',')
    if (parts[1].length === 3) {
      // If followed by exactly 3 digits, it's a thousand separator
      str = str.replace(/,/g, '')
    } else {
      // Otherwise treat as decimal point
      str = str.replace(',', '.')
    }
  }

  // If empty after cleanup, return null
  if (!str) return null

  // Convert to number
  const num = parseFloat(str)
  return isNaN(num) ? null : Math.round(num)
}

const isValidQuantity = (qty: any): boolean => {
  const parsedQty = parseQuantity(qty)
  return parsedQty !== null && parsedQty > 0
}

// Add function to get summary from Groq
const getBiodiversitySummary = async () => {
  if (!diversityResults.value) return

  // Get most dominant genus
  const dominantGenus = Object.entries(diversityResults.value.genusDistribution)
    .sort(([,a], [,b]) => (b as number) - (a as number))
    .map(([genus, count]) => `${genus} (${((count as number) / diversityResults.value.totalPlants * 100).toFixed(1)}%)`)[0]

  // Get most dominant family
  const dominantFamily = Object.entries(diversityResults.value.familyDistribution)
    .sort(([,a], [,b]) => (b as number) - (a as number))
    .map(([family, count]) => `${family} (${((count as number) / diversityResults.value.totalPlants * 100).toFixed(1)}%)`)[0]

  // Create a unique key based on the diversity data
  const keyData = {
    species: processedSpecies.value.map(s => `${s.species}-${s.quantity}`).join(','),
    unmatched: unmatchedSpecies.value.length,
    noQuantity: noQuantitySpecies.value.length,
    readiness: calculateBiodiversityReadiness.value,
    dominantGenus,
    dominantFamily
  }
  const uniqueKey = `grq-js-${hashSum(keyData)}`

  const prompt = `
Current Assessment:
Species count: ${diversityResults.value.speciesCount} species (${diversityResults.value.totalPlants} total plants)
Individual species quantities: ${processedSpecies.value.map(species => `${species.species} (${species.quantity})`).join(', ')}
Minimum species requirement: ${diversityResults.value.criteria.minimumSpecies.passed ? 'Met' : 'Not met'}
Genus distribution: ${Object.keys(diversityResults.value.genusDistribution).length} genera
Most dominant genus: ${dominantGenus}
Family distribution: ${Object.keys(diversityResults.value.familyDistribution).length} families
Most dominant family: ${dominantFamily}
Unmatched species: ${unmatchedSpecies.value.length}
Species without quantities: ${noQuantitySpecies.value.length}
Gini-Simpson Index: ${diversityResults.value.simpsonsIndex.toFixed(2)}
Overall readiness score: ${calculateBiodiversityReadiness.value}%`

  try {
    const { data: response } = await useAsyncData(
      uniqueKey,
      () => $fetch('/api/ai/grq', {
        method: 'POST',
        body: {
          prompt: '',
          prompts: [
            {hidden: true, prompt: 'biodiversitySummaryPrompt'},
            {hidden: false, prompt}
          ]
        }
      })
    )
    
    if (response.value?.text) {
      biodiversitySummary.value = response.value.text.trim()
    }
  } catch (error) {
    console.error('Error getting biodiversity summary:', error)
  }
}

// Helper function to ensure minimum loading duration
const withMinDuration = async (fn: () => Promise<void>, minDuration: number = 500) => {
  const start = Date.now()
  await fn()
  const elapsed = Date.now() - start
  if (elapsed < minDuration) {
    await new Promise(resolve => setTimeout(resolve, minDuration - elapsed))
  }
}

// Update the findPlantGridId function to fix the tool_uid path
const findPlantGridId = () => {
  // Get schedule tool UID from tools
  const scheduleToolUid = tools.find(t => t.id === 'schedule')?.uid
  if (!scheduleToolUid) {
    console.warn('Could not find schedule tool UID')
    return null
  }
  
  console.log('Looking for panel with schedule tool UID:', scheduleToolUid)


  
  console.warn('No PlantSchedule panel found in panelState')
  return null
}

// Update the quantity lookup in refreshSantamour
const refreshSantamour = async () => {
  isRefreshing.value = true
  santamourLoadingState.value = "pending"
  
  await withMinDuration(async () => {
    // Get raw species names and filter out empty/null values
    // const speciesNames.value = getSpeciesNames(props.data, props.panel_id)
    //   .filter(name => name && name.trim().length > 0)
    santamourLoadingState.value = "idle"
    
    // if (speciesNames.value.length === 0) {
    //   console.log('No valid species names to process')
    //   return
    // }

    // Set active panel to PlantSchedule before processing
    const schedulePanel = findPlantGridId()
    if (schedulePanel) {
      console.log('Setting active panel from santamour refresh:', schedulePanel)
      setActivePanel(schedulePanel)
    }
    
    // Make concurrent requests for each species name
    const tnrsPromises = speciesNames.value.map(name => fetchTnrs([name]))
    const tnrsResponses = await Promise.all(tnrsPromises)
    
    console.log('TNRS Responses:', tnrsResponses)

 
    // console.log('Metadata found:', metadata)
    // console.log('Rows found:', rows)
    // console.log('Column mapping:', metadata?.columnToCanonical)

    // Reset arrays
    unmatchedSpecies.value = []
    noQuantitySpecies.value = []
    
    // Process TNRS responses and create Plant objects
    const plants = tnrsResponses
      .map((response, index) => {
        const result = response?.result?.[0] || null
        const nameScore = result?.Overall_score ? parseFloat(result.Overall_score) : 0

        if (!result || !result.Accepted_name || nameScore < 0.5) {
          unmatchedSpecies.value.push({
            name: speciesNames.value[index],
            score: nameScore,
            matched: result?.Accepted_name || null
          })
          return null
        }

        // Update the quantity lookup to be more robust
        const findQuantity = (speciesRow: any) => {
          if (!metadata?.columnToCanonical?.qty) {
            console.warn('No quantity column mapping found in metadata')
            return null
          }

          const qtyValue = speciesRow[metadata.columnToCanonical.qty]
          console.log('Found quantity value:', qtyValue, 'for row:', speciesRow)
          return qtyValue
        }

        // Find quantity from plant schedule with more logging
        const speciesRow = rows.find(row => {
          const rowBotanicalName = row[metadata?.columnToCanonical?.botanicalname || '']
          console.log('Checking row botanical name:', rowBotanicalName, 'against:', speciesNames.value[index], 'or', result?.Accepted_name)
          return rowBotanicalName && 
            (rowBotanicalName.toLowerCase() === speciesNames.value[index].toLowerCase() ||
             rowBotanicalName.toLowerCase() === result?.Accepted_name?.toLowerCase())
        })

        const rawQuantity = speciesRow ? findQuantity(speciesRow) : null
        console.log('Raw quantity found:', rawQuantity, 'for species:', speciesNames.value[index])
        
        // Parse the raw quantity
        const quantity = parseQuantity(rawQuantity)
        console.log('Parsed quantity:', quantity, 'for species:', speciesNames.value[index])

        if (!isValidQuantity(rawQuantity)) {
          noQuantitySpecies.value.push({
            species: result.Accepted_name,
            genus: result.Accepted_genus,
            family: result.Accepted_family,
            quantity: null,
            rawQuantity // Add this to help debug quantity parsing issues
          })
          return null
        }

        const speciesData = {
          species: result.Accepted_name,
          genus: result.Accepted_genus || result.Accepted_name.split(' ')[0],
          family: result.Accepted_family || 'Unknown',
          quantity: quantity || 1,
          score: nameScore
        }

        // Add to speciesNames array for display
        processedSpecies.value.push(speciesData)

        console.log('Processed plant:', speciesData)
        return speciesData
      })
      .filter(Boolean) as Plant[]

    console.log('Final processed plants:', plants)

    // Evaluate diversity with the processed plants
    const results = evaluateDiversity(plants)
    diversityResults.value = results

    // Create validation object
    const validation = {
      name: 'diversityStatus',
      result: results,
      show: !results.isValid,
      unmatchedSpecies: unmatchedSpecies.value,
      noQuantitySpecies: noQuantitySpecies.value
    }

    // Add to validation store
    if (selectedProject.value?.id) {
      addValidation(validation, selectedProject.value.id.toString())
    }

    emit('results', validation)
  })
  
  santamourLoadingState.value = "idle"
  isRefreshing.value = false
}

const refreshSimpson = async () => {
  isRefreshing.value = true
  simpsonLoadingState.value = "pending"
  
  await withMinDuration(async () => {
    // Set active panel to PlantSchedule before processing
    const schedulePanel = findPlantGridId()
    if (schedulePanel) {
      console.log('Setting active panel from simpson refresh:', schedulePanel)
      setActivePanel(schedulePanel)
    }

    if (diversityResults.value) {
      // Recalculate Simpson's index
      const speciesCounts: Record<string, number> = {}
      processedSpecies.value.forEach(species => {
        speciesCounts[species.species] = species.quantity
      })
      
      diversityResults.value.simpsonsIndex = calculateSimpsonsIndex(speciesCounts)
      diversityResults.value.criteria.simpsonsRule = {
        passed: diversityResults.value.simpsonsIndex >= 0.5,
        message: diversityResults.value.simpsonsIndex >= 0.5 ?
          `Good diversity` :
          `Low diversity`,
        violations: diversityResults.value.simpsonsIndex < 0.5 ? 
          ["Gini-Simpson Index is below 0.5, indicating low diversity"] : []
      }
    }
  })
  
  simpsonLoadingState.value = "idle"
  isRefreshing.value = false
}


// Main refresh function that calls all section refreshes
const refresh = async () => {
  isRefreshing.value = true
  validationLoadingState.value = "pending"
  
  // If we have species names but no processedSpecies yet,
  // manually trigger the santamour process
  if (speciesNames.value.length > 0 && processedSpecies.value.length === 0) {
    await refreshSantamour()
  }
  
  // Then get the summary if we have diversity results
  if (diversityResults.value) {
    await refreshSummary()
    await refreshSimpson()
  }
  
  validationLoadingState.value = "idle"
  isRefreshing.value = false
}

// Add refreshSummary function back
const refreshSummary = async () => {
  isRefreshing.value = true
  summaryLoadingState.value = "pending"
  
  await withMinDuration(async () => {
    // Set active panel to PlantSchedule before processing
    const schedulePanel = findPlantGridId()
    if (schedulePanel) {
      console.log('Setting active panel from summary refresh:', schedulePanel)
      setActivePanel(schedulePanel)
    }

    if (diversityResults.value) {
      await getBiodiversitySummary()
    }
  })
  
  summaryLoadingState.value = "idle"
  isRefreshing.value = false
}

onMounted(async () => {
  // Add shadow animation for sticky header

  // await refresh()
})


const santamourSteps = [
  {
    title: "Species Count",
    description: "Minimum 10 different species required",
    status: computed(() => {
      if (!diversityResults.value?.speciesCount || diversityResults.value.speciesCount === 0) {
        return 'error'
      }
      return diversityResults.value?.criteria.minimumSpecies.passed ? 'complete' : 'error'
    })
  },
  {
    title: "Genus Distribution",
    description: "No more than 30% from the same genus",
    status: computed(() => {
      const genusCount = Object.keys(diversityResults.value?.genusDistribution || {}).length
      if (!genusCount || genusCount === 0) {
        return 'error'
      }
      return diversityResults.value?.criteria.genusRule.passed ? 'complete' : 'error'
    })
  },
  {
    title: "Family Distribution",
    description: "No more than 30% from the same family",
    status: computed(() => {
      const familyCount = Object.keys(diversityResults.value?.familyDistribution || {}).length
      if (!familyCount || familyCount === 0) {
        return 'error'
      }
      return diversityResults.value?.criteria.familyRule.passed ? 'complete' : 'error'
    })
  }
]

const simpsonSteps = [
  {
    title: "Species Richness",
    description: "Number of different species",
    status: computed(() => {
      if (!diversityResults.value?.speciesCount) return 'error'
      return diversityResults.value.speciesCount >= 5 ? 'complete' : 'error'
    })
  },
  {
    title: "Species Evenness",
    description: "Distribution of individuals among species",
    status: computed(() => {
      if (!diversityResults.value?.simpsonsIndex) return 'error'
      // Check if no species has more than 50% of individuals
      const maxProportion = Math.max(...Object.values(diversityResults.value.genusDistribution)
        .map(count => count / diversityResults.value.totalPlants))
      return maxProportion <= 0.5 ? 'complete' : 'error'
    })
  },
  {
    title: "Gini-Simpson Index",
    description: "Overall diversity score (target > 0.5)",
    status: computed(() => {
      if (!diversityResults.value?.simpsonsIndex) return 'error'
      return diversityResults.value.criteria.simpsonsRule.passed ? 'complete' : 'error'
    })
  }
]

const colorMode = useColorMode()
const cm = computed(() => colorMode.value || 'light')

// Calculate biodiversity readiness percentage
const calculateBiodiversityReadiness = computed(() => {
  if (!diversityResults.value) return 0

  // Species count readiness (weight: 30%)
  const minSpecies = 10
  const speciesCount = diversityResults.value.speciesCount || 0
  const speciesScore = speciesCount === 0 ? 0 : Math.floor(Math.min(100, (speciesCount / minSpecies) * 100) * 0.3)

  // Genus distribution readiness (weight: 25%)
  const maxGenusPercentage = 0.3
  const genusCount = Object.keys(diversityResults.value.genusDistribution || {}).length
  let genusScore = 0
  
  if (genusCount > 0) {
    const genusViolations = Object.values(diversityResults.value.genusDistribution)
      .map(count => (count as number) / diversityResults.value.totalPlants)
      .filter(percentage => percentage > maxGenusPercentage)
    const worstGenusViolation = Math.max(0, ...genusViolations)
    genusScore = worstGenusViolation > maxGenusPercentage
      ? Math.floor(Math.max(0, (1 - (worstGenusViolation - maxGenusPercentage) / maxGenusPercentage) * 100))
      : 100
  }
  const weightedGenusScore = Math.floor(genusScore * 0.25)

  // Family distribution readiness (weight: 25%)
  const maxFamilyPercentage = 0.3
  const familyCount = Object.keys(diversityResults.value.familyDistribution || {}).length
  let familyScore = 0
  
  if (familyCount > 0) {
    const familyViolations = Object.values(diversityResults.value.familyDistribution)
      .map(count => (count as number) / diversityResults.value.totalPlants)
      .filter(percentage => percentage > maxFamilyPercentage)
    const worstFamilyViolation = Math.max(0, ...familyViolations)
    familyScore = worstFamilyViolation > maxFamilyPercentage
      ? Math.floor(Math.max(0, (1 - (worstFamilyViolation - maxFamilyPercentage) / maxFamilyPercentage) * 100))
      : 100
  }
  const weightedFamilyScore = Math.floor(familyScore * 0.25)

  // Simpson's Index readiness (weight: 20%)
  const simpsonsScore = Math.floor(diversityResults.value.simpsonsIndex * 100)
  const weightedSimpsonsScore = Math.floor(simpsonsScore * 0.2)

  // Total readiness score
  return Math.floor(speciesScore + weightedGenusScore + weightedFamilyScore + weightedSimpsonsScore)
})

// Create color scale for progress bar
const getProgressColor = (percentage: number) => {
  // Create a color scale from indigo-500 to green-500
  const colorScale = chroma.scale(['#6366f1', '#22c55e'])
  return colorScale(percentage / 100).hex()
}

const genusChart = ref<InstanceType<typeof VChart> | null>(null)
const familyChart = ref<InstanceType<typeof VChart> | null>(null)
const genusChartContainer = ref<HTMLElement | null>(null)
const familyChartContainer = ref<HTMLElement | null>(null)

const initOptions = ref({
  renderer: 'svg',
  width: 100,
  height: 100
})

// Use VueUse's useResizeObserver for genus chart
useResizeObserver(genusChartContainer, (entries) => {
  const entry = entries[0]
  if (genusChart.value && entry) {
    const { width, height } = entry.contentRect
    genusChart.value.resize({
      width: Math.max(width, 100),
      height: Math.max(height, 100)
    })
  }
})

// Use VueUse's useResizeObserver for family chart
useResizeObserver(familyChartContainer, (entries) => {
  const entry = entries[0]
  if (familyChart.value && entry) {
    const { width, height } = entry.contentRect
    familyChart.value.resize({
      width: Math.max(width, 100),
      height: Math.max(height, 100)
    })
  }
})

const colors = computed(() => {
  if (cm.value === 'dark') {
    return {
      mutedForeground: 'hsl(90 20.2% 65.1%)',
      accent: 'hsl(160 32.6% 21.5%)',
      muted: 'hsl(160 22.6% 13.5%)',
      primary: 'hsl(90 20% 84%)'
    }
  } else {
    return {
      mutedForeground: 'hsl(160 21% 36.9%)',
      accent: 'hsl(160 30% 79.1%)',
      muted: 'hsl(90 11% 91%)',
      primary: 'hsl(120 27.4% 21.2%)'
    }
  }
})

const genusChartOption = computed(() => {
  if (!diversityResults.value) return null

  const threshold = 20
  const data = Object.entries(diversityResults.value.genusDistribution || {})
    .map(([genus, count]) => {
      const percentage = (count as number) / diversityResults.value.totalPlants * 100
      return {
        name: genus || 'Unknown',
        value: percentage,
        absoluteValue: count,
        percentage: percentage.toFixed(1),
        baseValue: Math.min(percentage, threshold),
        excessValue: percentage > threshold ? percentage - threshold : 0
      }
    })
    .sort((a, b) => b.value - a.value)

  return {
    animation: false,
    tooltip: {
      show: false
    },
    toolbox: {
      itemSize: 15,
      top: '0px',
      showTitle: false,
      itemGap: 0,
      tooltip: {
        show: false
      },
      feature: {
        saveAsImage: {
          title: 'Save as image'
        }
      }
    },
    grid: {
      top: '10%',
      left: '10%',
      right: '5%',
      bottom: '20%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: data.map(item => item.name),
      axisLabel: {
        rotate: 0,
        color: colors.value.mutedForeground,
        overflow: 'truncate',
        width: 80,
        interval: 0
      },
      axisLine: {
        lineStyle: {
          color: colors.value.muted
        }
      }
    },
    yAxis: {
      type: 'value',
      name: 'Percentage',
      nameLocation: 'middle',
      nameGap: 40,
      axisLabel: {
        color: colors.value.primary,
        fontWeight: 500,
        formatter: '{value}%'
      },
      axisLine: {
        lineStyle: {
          color: colors.value.border
        }
      },
      splitLine: {
        lineStyle: {
          color: colors.value.border,
          opacity: 0.3
        }
      },
      nameTextStyle: {
        color: colors.value.primary,
        fontSize: 13,
        fontWeight: 600,
        padding: [0, 0, 10, 0]
      }
    },
    series: [
      {
        type: 'bar',
        stack: 'total',
        z: 2,
        emphasis: {
          disabled: true
        },
        data: data.map(item => ({
          ...item,
          value: item.baseValue,
          itemStyle: {
            color: colors.value.mutedForeground
          }
        }))
      },
      {
        type: 'bar',
        stack: 'total',
        z: 2,
        emphasis: {
          disabled: true
        },
        data: data.map(item => ({
          ...item,
          value: item.excessValue,
          itemStyle: {
            color: theme.colors.background,
            decal: {
              symbol: 'line',
              dashArrayX: [1, 0],
              dashArrayY: [2, 1],
              symbolSize: 3,
              rotation: Math.PI / 4,
              color: 'rgba(255, 0, 0, 0.3)'
            }
          }
        }))
      },
      {
        type: 'line',
        z: 1,
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: {
            color: 'rgba(255,0,0,0.8)',
            type: 'solid'
          },
          data: [{
            yAxis: threshold,
            label: {
              formatter: `${threshold}% threshold`,
              position: 'insideEndTop',
              color: 'rgba(255,0,0,0.8)'
            }
          }]
        }
      }
    ]
  }
})

const familyChartOption = computed(() => {
  if (!diversityResults.value) return null

  const threshold = 30
  const data = Object.entries(diversityResults.value.familyDistribution || {})
    .map(([family, count]) => {
      const percentage = (count as number) / diversityResults.value.totalPlants * 100
      return {
        name: family || 'Unknown',
        value: percentage,
        absoluteValue: count,
        percentage: percentage.toFixed(1),
        baseValue: Math.min(percentage, threshold),
        excessValue: percentage > threshold ? percentage - threshold : 0
      }
    })
    .sort((a, b) => b.value - a.value)

  return {
    animation: false,
    tooltip: {
      show: false
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: 'Save as image'
        }
      }
    },
    grid: {
      top: '10%',
      left: '10%',
      right: '5%',
      bottom: '20%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: data.map(item => item.name),
      axisLabel: {
        rotate: 0,
        color: colors.value.mutedForeground,
        overflow: 'truncate',
        width: 80,
        interval: 0
      },
      axisLine: {
        lineStyle: {
          color: colors.value.muted
        }
      }
    },
    yAxis: {
      type: 'value',
      name: 'Percentage',
      nameLocation: 'middle',
      nameGap: 40,
      axisLabel: {
        color: colors.value.primary,
        fontWeight: 500,
        formatter: '{value}%'
      },
      axisLine: {
        lineStyle: {
          color: colors.value.border
        }
      },
      splitLine: {
        lineStyle: {
          color: colors.value.border,
          opacity: 0.3
        }
      },
      nameTextStyle: {
        color: colors.value.primary,
        fontSize: 13,
        fontWeight: 600,
        padding: [0, 0, 10, 0]
      }
    },
    series: [
      {
        type: 'bar',
        stack: 'total',
        z: 2,
        emphasis: {
          disabled: true
        },
        data: data.map(item => ({
          ...item,
          value: item.baseValue,
          itemStyle: {
            color: colors.value.mutedForeground
          }
        }))
      },
      {
        type: 'bar',
        stack: 'total',
        z: 2,
        emphasis: {
          disabled: true
        },
        data: data.map(item => ({
          ...item,
          value: item.excessValue,
          itemStyle: {
            color: theme.colors.background,
            decal: {
              symbol: 'line',
              dashArrayX: [1, 0],
              dashArrayY: [2, 1],
              symbolSize: 3,
              rotation: Math.PI / 4,
              color: 'rgba(255, 0, 0, 0.3)'
            }
          }
        }))
      },
      {
        type: 'line',
        z: 1,
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: {
            color: 'rgba(255,0,0,0.8)',
            type: 'solid'
          },
          data: [{
            yAxis: threshold,
            label: {
              formatter: `${threshold}% threshold`,
              position: 'insideEndTop',
              color: 'rgba(255,0,0,0.8)'
            }
          }]
        }
      }
    ]
  }
})

const simpsonChart = ref<InstanceType<typeof VChart> | null>(null)
const simpsonChartContainer = ref<HTMLElement | null>(null)

// Use VueUse's useResizeObserver for Simpson chart
useResizeObserver(simpsonChartContainer, (entries) => {
  const entry = entries[0]
  if (simpsonChart.value && entry) {
    const { width, height } = entry.contentRect
    simpsonChart.value.resize({
      width: Math.max(width, 100),
      height: Math.max(height, 100)
    })
  }
})

const simpsonChartOption = computed(() => {
  if (!diversityResults.value) return null

  // Use speciesNames array which contains our processed species data
  const data = processedSpecies.value
    .map(species => {
      return {
        name: species.species,
        value: species.quantity,
        relativeAbundance: (species.quantity / diversityResults.value.totalPlants) * 100
      }
    })
    .sort((a, b) => b.relativeAbundance - a.relativeAbundance)
    .map((item, index) => ({
      ...item,
      rank: index + 1,
      logAbundance: Math.log10(item.relativeAbundance)
    }))

  console.log('Rank-Abundance data:', data)

  return {
    animation: false,
    tooltip: {
      show: true,
      trigger: 'item',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderWidth: 0,
      padding: [8, 12],
      className: 'echarts-tooltip-glassmorphic',
      textStyle: {
        color: '#334155', // slate-700
        fontSize: 12,
        fontFamily: 'Inter'
      },
      formatter: (params: any) => {
        return `
          <div class="font-medium mb-1 text-slate-900">${params.data.name}</div>
          <div class="flex justify-between items-center gap-3 text-xs py-0.5">
            <span class="text-slate-500">Rank:</span>
            <span class="font-medium text-slate-700">#${params.data.rank}</span>
          </div>
          <div class="flex justify-between items-center gap-3 text-xs py-0.5">
            <span class="text-slate-500">Abundance:</span>
            <span class="font-medium text-slate-700">${params.data.relativeAbundance.toFixed(1)}%</span>
          </div>
        `
      }
    },
    toolbox: {
      itemSize: 15,
      top: '0px',
      showTitle: false,
      itemGap: 0,
      tooltip: {
        show: false
      },
      feature: {
        saveAsImage: {
          title: 'Save as image'
        }
      }
    },
    grid: {
      left: '12%',
      right: '5%',
      top: '10%',
      bottom: '20%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      name: 'Species Rank',
      nameLocation: 'middle',
      nameGap: 30,
      data: data.map(item => item.rank),
      axisLabel: {
        formatter: (value: number) => value.toString(),
        color: colors.value.mutedForeground,
        fontSize: 12,
        fontWeight: 500
      },
      axisLine: {
        lineStyle: {
          color: colors.value.border
        }
      },
      nameTextStyle: {
        color: colors.value.mutedForeground,
        fontSize: 13,
        fontWeight: 600,
        padding: [10, 0, 0, 0]
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: colors.value.border,
          type: 'dashed',
          opacity: 0.3,
          width: 1
        }
      }
    },
    yAxis: {
      type: 'value',
      name: 'Relative\nAbundance (%)',
      nameLocation: 'middle',
      nameGap: 65,
      scale: true,
      axisLabel: {
        color: colors.value.mutedForeground,
        fontSize: 12,
        fontWeight: 500,
        formatter: '{value}%'
      },
      axisLine: {
        lineStyle: {
          color: colors.value.border
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: colors.value.border,
          type: 'dashed',
          opacity: 0.3,
          width: 1
        }
      },
      nameTextStyle: {
        color: colors.value.mutedForeground,
        fontSize: 13,
        fontWeight: 600,
        padding: [0, 0, 20, 0],
        lineHeight: 16,
        align: 'center',
        verticalAlign: 'middle'
      }
    },
    series: [
      {
        type: 'line',
        symbol: 'circle',
        symbolSize: 8,
        z: 2,
        data: data.map(item => ({
          ...item,
          value: item.relativeAbundance
        })),
        itemStyle: {
          color: colors.value.primary
        },
        lineStyle: {
          width: 2,
          color: colors.value.primary
        },
        emphasis: {
          disabled: true
        }
      }
    ]
  }
})

const sections = computed(() => [
  { 
    id: 'summary', 
    label: "Summary",
    score: diversityResults.value ? `${Math.floor(calculateBiodiversityReadiness.value)}%` : null
  },
  { 
    id: 'santamour', 
    label: "Santamour's Rule",
    score: diversityResults.value ? 
      `${[
        diversityResults.value.criteria.minimumSpecies.passed,
        diversityResults.value.criteria.genusRule.passed,
        diversityResults.value.criteria.familyRule.passed
      ].filter(Boolean).length}/3` : null
  },
  { 
    id: 'simpson', 
    label: "Gini-Simpson Index",
    score: diversityResults.value?.simpsonsIndex ? 
      diversityResults.value.simpsonsIndex.toFixed(2) : null
  }
])

const activeSection = ref('summary')
const summarySection = ref<HTMLElement | null>(null)
const santamourSection = ref<HTMLElement | null>(null)
const simpsonSection = ref<HTMLElement | null>(null)

// Create intersection observers for each section
const sectionRefs = {
  summary: summarySection,
  santamour: santamourSection,
  simpson: simpsonSection
}

// Update the intersection observer setup
onMounted(() => {
  // Wait for scroll container to be available
  nextTick(() => {
    const scrollContainer = document.querySelector('.scroll-container')
    if (scrollContainer) {
      Object.entries(sectionRefs).forEach(([id, ref]) => {
        useIntersectionObserver(
          ref,
          ([{ isIntersecting }]) => {
            if (isIntersecting) {
              activeSection.value = id
            }
          },
          { 
            threshold: [0.1, 0.5, 0.9], // Multiple thresholds for better detection
            rootMargin: '-100px 0px 0px 0px', // Offset to account for sticky header
            root: scrollContainer
          }
        )
      })
    }
  })
})

const scrollAreaRef = ref<InstanceType<typeof ScrollArea> | null>(null)

// Scroll to section function
const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId)
  const viewport = document.querySelector('[data-radix-scroll-area-viewport]')
  if (section && viewport) {
    const targetScroll = section.offsetTop - 120
    viewport.scrollTop = targetScroll
    activeSection.value = sectionId
  }
}

// Add container ref and size tracking
const tabsContainer = ref<HTMLElement | null>(null)
const { width: containerWidth } = useElementSize(tabsContainer)

// Add computed for narrow width
const isNarrowWidth = computed(() => containerWidth.value < 300)



// Main refresh function that calls all section refreshes

</script>

<template>
  <div>
    <div v-if="validationLoadingState === 'pending'" class="flex items-center justify-center py-4">
      <Loader2 class="w-6 h-6 animate-spin" />
      <span class="ml-2">Analyzing species diversity...</span>
    </div>
    <template v-else-if="diversityResults">
      <div 
        ref="tabsContainer"
        class="sticky sticky-header top-0 z-10 bg-background/80 backdrop-blur-sm border-b mb-0 rounded-full m-4 mx-5 mb-4"
      >
        <div 
          class="flex gap-2 p-1.5 w-full overflow-x-hidden rounded-full"
          :class="isNarrowWidth ? 'justify-center' : 'justify-evenly'"
        >
          <button
            v-for="section in sections" 
            :key="section.id"
            v-show="!isNarrowWidth || activeSection === section.id"
            @click.stop="scrollToSection(section.id)"
            class="relative pl-2 pr-1.5 py-1.5 text-sm font-medium transition-all duration-300 rounded-full min-w-[120px] text-center"
            :class="[
              activeSection === section.id
                ? 'text-background bg-primary shadow-sm' 
                : 'text-muted-foreground hover:text-foreground/80'
            ]"
          >
            <div class="flex items-center justify-between ">
              <p class="truncate overflow-hidden h-5">{{ section.label }}</p>
              <Badge 
                v-if="section.score"
                :variant="activeSection === section.id ? 'secondary' : 'outline'"
                class="text-[0.8em] py-0 px-1 ml-1 border-none ring-0"
              >
                {{ section.score }}
              </Badge>
            </div>
          </button>
        </div>
      </div>
      <ScrollArea 
        ref="scrollAreaRef"
        class="h-[calc(100vh-380px)] w-full px-[6%] scroll-thumb"
      >
        <div class="flex flex-col gap-4">
          <section 
            id="summary"
            ref="summarySection"
            class="scroll-mt-20"
          >
          <div class="flex justify-between items-start pb-10 px-4 py-2">
            <div>
              <h3 
                class="text-md font-[600] mb-2 group hover:cursor-pointer relative"
                @click="() => refresh(false)"
              >
                <Icon 
                  v-if="isRefreshing" 
                  name="line-md:loading-loop" 
                  class="w-4 h-4 absolute left-0 transition-all duration-200 mr-2"
                />
                <Icon 
                  v-else
                  name="lucide:refresh-cw" 
                  class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
                />
                <div class="relative transition-all duration-200" :class="[isRefreshing ? 'translate-x-6' : 'group-hover:translate-x-6']">Diversity Summary</div>
              </h3>
              <p class="text-sm text-muted-foreground max-w-2xl">
                <!-- write diversity explainer here -->
                This analysis evaluates your planting diversity to assess resilience against pests and diseases. We measure species, genus, and family distributions against established ecological thresholds. Higher scores indicate better protection through biodiversity, with clear indicators of areas needing improvement.
              </p>
            </div>
          </div>
          
            <ContainerScrollCard 
              v-auto-animate
              :beam-width="48" 
              :border-thickness="2"
              background="bg-background"
              :show-blob="false"
              :blob-speed="0.5"
              :fit-content="true"
              class="relative my-8 mt-3 p-6"
            >
              <div class="flex flex-col gap-6">
                <div class="flex flex-col items-center justify-center">
                  <div :class="[
                    'progress-bar',
                    cm
                  ]">
                    <AnimatedCircularProgressBar
                      :max="100"
                      :min="0"
                      :value="calculateBiodiversityReadiness"
                      :gaugePrimaryColor="getProgressColor(calculateBiodiversityReadiness)"
                      :gaugeSecondaryColor="'var(--gauge-secondary-color)'"
                      class="w-24 h-24"
                    />
                  </div>
                  <div class="text-center mt-2">
                    <V-Tooltip content="This weighted score shows how species diverse your planting is. It combines species count (30%), distribution across genera (25%) and families (25%), and the Gini-Simpson diversity index (20%). Higher scores indicate better resilience against pests and diseases.">
                      <h4 class="text-sm font-medium">Biodiversity Score<Icon name="material-symbols:info" class="w-4 h-4 ml-1" /></h4>
                      <p class="text-xs text-muted-foreground">
                        {{ Math.floor(calculateBiodiversityReadiness) }}% of criteria met
                      </p>
                    </V-Tooltip>
                  </div>
                </div>

                <div class="flex flex-col gap-2">
                  <img src="/images/logos/superseeded_logo_s.webp" class="w-12 h-12 mb-4" />
                  <p v-if="biodiversitySummary" class="leading-6 [&:not(:first-child)]:mt-2 text-muted-foreground text-md py-4 pl-2">
                    {{ biodiversitySummary }}
                  </p>
                  <p v-else class="leading-6 [&:not(:first-child)]:mt-2 text-muted-foreground text-md py-4 pl-2">
                    Analyzing your planting schedule to provide a biodiversity assessment...
                  </p>
                  <div class="flex justify-end pt-4">
                    <button class="inline-flex items-center gap-1.5 pl-4 pr-6 py-1.5 text-md font-[400] text-background rounded-full bg-primary hover:shadow-xxl hover:translate-y-[-2px] transition-all duration-300 ease-in-out">
                      <Icon name="material-symbols:magic-button" class="w-5 h-5 mr-2" />
                      Optimise
                      <Badge 
                        v-if="!['Teams', 'Pro'].includes(planName)"
                        variant="secondary" 
                        class="ml-1 text-[10px] py-0 px-1"
                      >
                        Pro
                      </Badge>
                    </button>
                  </div>
                </div>
              </div>
            </ContainerScrollCard>
          </section>

          <section 
            id="santamour"
            ref="santamourSection"
            class="scroll-mt-20"
          >
            <div class="flex justify-between items-start pb-10 ml-2">
              <div>
                <h3 
                  class="text-md font-[600] mb-2 group hover:cursor-pointer relative"
                  @click="refreshSantamour"
                >
                  <Icon 
                    v-if="santamourLoadingState === 'pending'" 
                    name="line-md:loading-loop" 
                    class="w-4 h-4 animate-spin absolute opacity-100 -translate-x-2 transition-all duration-200 mr-2"
                  />
                  <Icon 
                    v-else
                    name="lucide:refresh-cw" 
                    class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
                  />
                  <div class="relative group-hover:translate-x-6 transition-all duration-200">10-20-30 Rule (Santamour, 1990)</div>
                </h3>
                <p class="text-sm text-muted-foreground max-w-2xl">
                  Santamour's Rule promotes urban forest diversity by limiting the percentage of any single family to 30%, 
                  any genus to 20%, and any species to 10%. This helps protect against pests and diseases that could 
                  devastate a homogeneous tree population.
                </p>
              </div>
            </div>

            

            <div class="relative after:absolute after:left-[19px] after:top-[20px] after:h-[calc(100%-40px)] after:w-[2px] after:bg-muted-foreground/20 mb-10">
              <div v-for="(step, index) in santamourSteps" :key="index" class="relative flex gap-3 pb-8">
                <div class="relative z-10 flex h-10 w-10 items-center justify-center rounded-full border-2"
                     :class="{
                       'bg-green-400': step.status.value === 'complete',
                       'bg-red-400': step.status.value === 'error',
                       'bg-muted border-muted-foreground': step.status.value === 'pending' as const
                     }">
                  <Icon 
                    v-if="step.status.value === 'complete'" 
                    name="iconoir:security-pass" 
                    class="h-5 w-5 text-emerald-700" 
                  />
                  <Icon 
                    v-else-if="step.status.value === 'error'" 
                    name="ph:exclamation-mark-bold" 
                    class="h-5 w-5 text-red-100" 
                  />
                  <span v-else class="h-5 w-5 text-muted-foreground">{{ index + 1 }}</span>
                </div>
                <div class="flex flex-col">
                  <h3 class="text-sm font-medium leading-6">{{ step.title }}</h3>
                  <p class="text-xs text-muted-foreground">{{ step.description }}</p>
                </div>
              </div>
            </div>



            <div class="bg-background rounded-xl p-6 mb-6">
              <div class="flex flex-col items-center gap-2">
                <h3 class="text-sm font-medium text-muted-foreground">Santamour's Rule Score</h3>
                <div class="flex items-baseline gap-2">
                  <span 
                    class="text-4xl font-bold"
                    
                  >
                    {{ [
                      diversityResults?.criteria?.minimumSpecies?.passed || false,
                      diversityResults?.criteria?.genusRule?.passed || false,
                      diversityResults?.criteria?.familyRule?.passed || false
                    ].filter(Boolean).length }}
                  </span>
                  <span class="text-md text-muted-foreground">/3 </span>
                </div>
                <div class="flex flex-wrap justify-center gap-2 mt-2">
                  <Badge 
                    v-for="(criterion, index) in [
                      { name: 'Species', passed: diversityResults?.criteria?.minimumSpecies?.passed },
                      { name: 'Genus', passed: diversityResults?.criteria?.genusRule?.passed },
                      { name: 'Family', passed: diversityResults?.criteria?.familyRule?.passed }
                    ]"
                    :key="index"
                    
                    class="text-xs"
                  >
                    {{ criterion.name }} {{ criterion.passed ? '✓' : '×' }}
                  </Badge>
                </div>
              </div>
            </div>


            <Accordion type="multiple" :default-value="['species', 'genus', 'family']" class="space-y-1">
              <AccordionItem value="species-list" class="rounded-sm accordion-item">
                <AccordionTrigger class="px-3 py-2 text-sm hover:no-underline !bg-background/80 rounded-md">
                  <h3 class="font-semibold flex flex-row items-center gap-1">
                    Species List
                    <Badge 
                      v-if="diversityResults?.totalPlants"
                      class="select-none bg-muted-foreground/20 hover:text-background text-muted-foreground text-xs py-0 px-1 font-normal whitespace-nowrap"
                    >
                      {{ processedSpecies.length }} species ({{ diversityResults.totalPlants }} plants)
                    </Badge>
                    <Badge 
                      v-if="unmatchedSpecies.length" 
                      variant="destructive" 
                      class="select-none text-xs py-0 px-1 font-normal whitespace-nowrap"
                    >
                      {{ unmatchedSpecies.length }} unmatched
                    </Badge>
                    <Badge 
                      v-if="noQuantitySpecies.length" 
                      variant="secondary" 
                      class="select-none text-xs py-0 px-1 font-normal whitespace-nowrap"
                    >
                      {{ noQuantitySpecies.length }} no quantity
                    </Badge>
                  </h3>
                </AccordionTrigger>
                <AccordionContent class="px-3 mt-2 py-1 border-b-0">
                  <div class="flex flex-col gap-4">
                    <div>
                      <h4 class="text-sm font-medium mb-2">Matched Species</h4>
                      <div class="flex flex-row flex-wrap gap-1">
                        <Badge v-for="item in processedSpecies" :key="item.species"
                          variant="default"
                          class="select-none text-xs py-0 px-2 font-normal whitespace-nowrap dark:bg-muted-foreground/10 dark:text-muted-foreground">
                          {{ item.species }} ({{ formatNumber(item.quantity) }})
                        </Badge>
                      </div>
                    </div>

                    <div v-if="noQuantitySpecies.length">
                      <h4 class="text-sm font-medium mb-2 text-muted-foreground">No Quantity</h4>
                      <div class="flex flex-row flex-wrap gap-1">
                        <Badge v-for="item in noQuantitySpecies" :key="item.species"
                          variant="secondary"
                          class="select-none text-xs py-0 px-2 font-normal whitespace-nowrap">
                          {{ item.species }} ({{ formatNumber(item.rawQuantity) }} plants)
                        </Badge>
                      </div>
                      <p class="text-xs text-muted-foreground mt-2">
                        These species have invalid or missing quantities and are excluded from diversity calculations.
                      </p>
                    </div>

                    <div v-if="unmatchedSpecies.length">
                      <h4 class="text-sm font-medium mb-2 text-destructive">Unmatched Species</h4>
                      <div class="flex flex-row flex-wrap gap-1">
                        <Badge v-for="item in unmatchedSpecies" :key="item.name"
                          variant="destructive"
                          class="select-none text-xs py-0 px-2 font-normal whitespace-nowrap">
                          {{ item.name }}
                          <span v-if="item.matched" class="text-xs opacity-75">
                            {{ item.matched }} ({{ Math.round(item.score * 100) }}%)
                          </span>
                        </Badge>
                      </div>
                      <p class="text-xs text-muted-foreground mt-2">
                        These species could not be matched in the taxonomic database or had low confidence matches (below 50%) and are excluded from diversity calculations.
                      </p>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              
              <AccordionItem value="genus" class="rounded-sm accordion-item">
                <AccordionTrigger class="px-3 py-2 text-sm hover:no-underline !bg-background/80 rounded-md">
                  <div class="w-full">
                    <h3 class="font-semibold flex flex-row items-center gap-1 mb-2">
                      Genus Distribution
                      <Badge class="select-none bg-muted-foreground/20 hover:text-background text-muted-foreground text-xs py-0 px-1 font-normal whitespace-nowrap"
                        :class="{ 'bg-destructive/20 text-destructive': !diversityResults.criteria.genusRule.passed }">
                        {{ Object.keys(diversityResults.genusDistribution).length }}
                      </Badge>
                    </h3>
                    <div class="chart-container genus-chart" ref="genusChartContainer">
                      <VChart 
                        ref="genusChart" 
                        :option="genusChartOption as EChartsOption" 
                        :init-options="initOptions as EChartsInitOpts" 
                        :autoresize="true" 
                      />
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent class="px-3 mt-2 py-1 border-b-0">
                  <p class="text-sm text-muted-foreground mb-2">
                    {{ diversityResults.criteria.genusRule.message }}
                  </p>
                  <div class="space-y-1">
                    <div v-for="genus in Object.entries(diversityResults.genusDistribution)" 
                         :key="genus[0]"
                         class="text-sm flex justify-between items-center">
                      <span>{{ genus[0] }}</span>
                      <div class="flex items-center gap-2">
                        <span class="text-xs text-muted-foreground">{{ formatNumber(Number(genus[1])) }} plants</span>
                        <Badge :variant="(Number(genus[1]) / diversityResults.totalPlants) > 0.2 ? 'destructive' : 'outline'">
                          {{ formatPercentage(Number(genus[1]), diversityResults.totalPlants) }}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="family" class="rounded-sm accordion-item">
                <AccordionTrigger class="px-3 py-2 text-sm hover:no-underline !bg-background/80 rounded-md">
                  <div class="w-full">
                    <h3 class="font-semibold flex flex-row items-center gap-1 mb-2">
                      Family Distribution
                      <Badge class="select-none bg-muted-foreground/20 hover:text-background text-muted-foreground text-xs py-0 px-1 font-normal whitespace-nowrap"
                        :class="{ 'bg-destructive/20 text-destructive': !diversityResults.criteria.familyRule.passed }">
                        {{ Object.keys(diversityResults.familyDistribution).length }}
                      </Badge>
                    </h3>
                    <div class="chart-container family-chart" ref="familyChartContainer">
                      <VChart 
                        ref="familyChart" 
                        :option="familyChartOption as EChartsOption" 
                        :init-options="initOptions as EChartsInitOpts" 
                        :autoresize="true" 
                      />
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent class="px-3 mt-2 py-1 border-b-0">
                  <p class="text-sm text-muted-foreground mb-2">
                    {{ diversityResults.criteria.familyRule.message }}
                  </p>
                  <div class="space-y-1">
                    <div v-for="family in Object.entries(diversityResults.familyDistribution)" 
                         :key="family[0]"
                         class="text-sm flex justify-between items-center">
                      <span>{{ family[0] }}</span>
                      <div class="flex items-center gap-2">
                        <span class="text-xs text-muted-foreground">{{ formatNumber(Number(family[1])) }} plants</span>
                        <Badge :variant="(Number(family[1]) / diversityResults.totalPlants) > 0.3 ? 'destructive' : 'outline'">
                          {{ formatPercentage(Number(family[1]), diversityResults.totalPlants) }}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              
            </Accordion>
          </section>

          <section 
            id="simpson"
            ref="simpsonSection"
            class="scroll-mt-20"
          >
            <div class="flex justify-between items-start py-10 ml-2">
              <div>
                <h3 
                  class="text-md font-[600] mb-2 group hover:cursor-pointer relative"
                  @click="refreshSimpson"
                >
                  <Icon 
                    v-if="simpsonLoadingState === 'pending'" 
                    name="line-md:loading-loop" 
                    class="w-4 h-4 animate-spin absolute opacity-100 -translate-x-2 transition-all duration-200 mr-2"
                  />
                  <Icon 
                    v-else
                    name="lucide:refresh-cw" 
                    class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
                  />
                  <div class="relative group-hover:translate-x-6 transition-all duration-200">Gini-Simpson Index</div>
                </h3>
                <p class="text-sm text-muted-foreground max-w-2xl">
                  The Gini-Simpson Index measures both species richness (number of species) and evenness (how equally abundant they are). 
                  It ranges from 0 (no diversity) to 1 (infinite diversity), with values above 0.5 indicating good biodiversity. 
                  The index represents the probability that two randomly selected plants will be different species.
                </p>
              </div>
            </div>

            <div class="relative after:absolute after:left-[19px] after:top-[20px] after:h-[calc(100%-40px)] after:w-[2px] after:bg-muted-foreground/20 mb-10">
              <div v-for="(step, index) in simpsonSteps" :key="index" class="relative flex gap-3 pb-8">
                <div class="relative z-10 flex h-10 w-10 items-center justify-center rounded-full border-2"
                     :class="{
                       'bg-green-400': step.status.value === 'complete',
                       'bg-red-400': step.status.value === 'error',
                       'bg-muted border-muted-foreground': step.status.value === 'pending'
                     }">
                  <Icon 
                    v-if="step.status.value === 'complete'" 
                    name="iconoir:security-pass" 
                    class="h-5 w-5 text-emerald-700" 
                  />
                  <Icon 
                    v-else-if="step.status.value === 'error'" 
                    name="ph:exclamation-mark-bold" 
                    class="h-5 w-5 text-red-100" 
                  />
                  <span v-else class="h-5 w-5 text-muted-foreground">{{ index + 1 }}</span>
                </div>
                <div class="flex flex-col">
                  <h3 class="text-sm font-medium leading-6">{{ step.title }}</h3>
                  <p class="text-xs text-muted-foreground">{{ step.description }}</p>
                </div>
              </div>
            </div>

            <Accordion type="multiple" :default-value="['simpson-richness', 'simpson-evenness']" class="space-y-1">
              <div class="bg-background rounded-xl p-6 mb-6">
                <div class="flex flex-col items-center gap-2">
                  <h3 class="text-sm font-medium text-muted-foreground">Gini-Simpson Index Score</h3>
                  <div class="flex items-baseline gap-2">
                    <span 
                      class="text-4xl font-bold"
                      :class="diversityResults?.criteria.simpsonsRule.passed ? 'text-primary' : 'text-destructive'"
                    >
                      {{ diversityResults?.simpsonsIndex?.toFixed(2) || '0.00' }}
                    </span>
                    <span class="text-sm text-muted-foreground">/1.00</span>
                  </div>
                  <p class="text-sm text-center text-muted-foreground mt-2">
                    {{ diversityResults?.criteria.simpsonsRule.message }}
                  </p>
                  <div 
                    v-if="diversityResults?.criteria.simpsonsRule.violations?.length" 
                    class="mt-2 text-center"
                  >
                    <p 
                      v-for="violation in diversityResults?.criteria.simpsonsRule.violations" 
                      :key="violation"
                      class="text-sm text-destructive"
                    >
                      {{ violation }}
                    </p>
                  </div>
                </div>
              </div>

              <AccordionItem value="simpson-evenness" class="rounded-sm accordion-item">
                <AccordionTrigger class="px-3 py-2 text-sm hover:no-underline !bg-background/80 rounded-md">
                  <div class="w-full">
                    <h3 class="font-semibold flex flex-row items-center gap-1 mb-2">
                      Rank-Abundance Curve
                    </h3>
                    <div class="chart-container simpson-chart" ref="simpsonChartContainer">
                      <VChart 
                        ref="simpsonChart" 
                        :option="simpsonChartOption as EChartsOption" 
                        :init-options="initOptions as EChartsInitOpts" 
                        :autoresize="true" 
                      />
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent class="px-3 mt-2 py-1 border-b-0">
                  <p class="text-sm text-muted-foreground mb-2">
                    The Rank-Abundance Curve shows species ranked by their relative abundance. A gentle slope indicates high evenness 
                    (species are similarly abundant), while a steep slope suggests dominance by few species. This visualization helps 
                    understand both the number of species and how evenly they are distributed.
                  </p>
                  <div class="space-y-1">
                    <div v-for="species in Object.entries(diversityResults.value?.genusDistribution || {})" 
                         :key="species[0]"
                         class="text-sm flex justify-between items-center">
                      <span>{{ species[0] }}</span>
                      <div class="flex items-center gap-2">
                        <span class="text-xs text-muted-foreground">{{ formatNumber(Number(species[1])) }} plants</span>
                        <Badge :variant="(Number(species[1]) / diversityResults.value.totalPlants) > 0.5 ? 'destructive' : 'outline'">
                          {{ formatPercentage(Number(species[1]), diversityResults.value.totalPlants) }}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>

            
          </section>
        </div>
      </ScrollArea>
    </template>
    <template v-if="validationLoadingState === 'pending'">
      <div class="flex flex-col gap-4">
        <!-- Summary Card Skeleton -->
        <ContainerScrollCard 
          :beam-width="48" 
          :border-thickness="2"
          background="bg-background"
          :show-blob="true"
          :blob-speed="0.5"
          :fit-content="true"
          class="relative my-8 mt-3 p-8 mx-[6%]"
        >
          <div class="flex flex-col gap-8">
            <!-- Progress Circle Skeleton -->
            <div class="flex flex-col items-center justify-center">
              <Skeleton class="w-[61px] h-[61px] rounded-full" />
              <div class="text-center mt-2">
                <Skeleton class="w-36 h-[18px] rounded-full" />
                <Skeleton class="w-28 h-[14px] mt-1 rounded-full" />
              </div>
            </div>

            <!-- Summary Text Skeleton -->
            <div class="flex flex-col gap-3">
              <Skeleton class="w-full h-[18px] rounded-full" />
              <Skeleton class="w-[98%] h-[18px] rounded-full" />
              <div class="flex justify-end pt-6">
                <Skeleton class="w-[88px] h-[34px] rounded-full" />
              </div>
            </div>
          </div>
        </ContainerScrollCard>

        <!-- Santamour Section Skeleton -->
        <div class="px-[6%]">
          <Skeleton class="w-[180px] h-[20px] mb-10 ml-2 rounded-full" />
          
          <!-- Steps - Made line lighter -->
          <div class="relative after:absolute after:left-[19px] after:top-[20px] after:h-[calc(100%-40px)] after:w-[2px] after:bg-muted-foreground/10">
            <div v-for="i in 3" :key="i" class="relative flex gap-3 pb-8">
              <Skeleton class="h-10 w-10 rounded-full shrink-0" />
              <div class="flex flex-col gap-1">
                <Skeleton class="h-[18px] w-32 rounded-full" />
                <Skeleton class="h-[14px] w-56 rounded-full" />
              </div>
            </div>
          </div>

          <!-- Distribution Charts Skeleton -->
          <div class="space-y-4 mt-8">
            <!-- Species List Skeleton -->
            <div class="rounded-md border p-4 bg-background/80">
              <div class="flex items-center gap-2 mb-4">
                <Skeleton class="w-24 h-[18px] rounded-full" />
                <Skeleton class="w-16 h-[18px] rounded-full" />
              </div>
            </div>

            <!-- Genus Distribution -->
            <div class="rounded-md border p-4 bg-background/80">
              <div class="flex items-center gap-2 mb-4">
                <Skeleton class="w-36 h-[18px] rounded-full" />
                <Skeleton class="w-8 h-[18px] rounded-full" />
              </div>
              <Skeleton class="w-full h-[160px] rounded-md" />
            </div>

            <!-- Family Distribution -->
            <div class="rounded-md border p-4 bg-background/80">
              <div class="flex items-center gap-2 mb-4">
                <Skeleton class="w-36 h-[18px] rounded-full" />
                <Skeleton class="w-8 h-[18px] rounded-full" />
              </div>
              <Skeleton class="w-full h-[160px] rounded-md" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
:deep(.accordion-content) {
  padding: 0.25rem 0.75rem;
}

:deep(.accordion-item) {
  border-bottom: none;
}

:deep(.accordion-item > div) {
  border-bottom: none;
}

:deep(.accordion-item > div > button) {
  border-bottom: none;
}

.scroll-thumb {
  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  scrollbar-width: thin;
}

.scroll-thumb::-webkit-scrollbar {
  width: var(--sb-size);
}

.scroll-thumb::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

.scroll-thumb::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
}

.progress-bar {
  --gauge-secondary-color: theme('colors.zinc.200');
}

.progress-bar.dark {
  --gauge-secondary-color: theme('colors.zinc.800');
}

:deep(.echarts) {
  width: 100% !important;
  height: 100% !important;
  min-width: 0 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 160px;
  min-width: 0;
  overflow: hidden;
}

.scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.backdrop-blur-sm {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

/* Add glassmorphic tooltip styles */
:deep(.echarts-tooltip-glassmorphic) {
  backdrop-filter: blur(8px) !important;
  -webkit-backdrop-filter: blur(8px) !important;
  border-radius: 8px !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px -1px rgba(0, 0, 0, 0.03) !important;
}

/* Dark mode styles */
.dark :deep(.echarts-tooltip-glassmorphic) {
  background-color: rgba(30, 41, 59, 0.8) !important; /* slate-800 with opacity */
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.dark :deep(.echarts-tooltip-glassmorphic) {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity)) !important;
}

/* Override tooltip text colors for dark mode */
.dark :deep(.echarts-tooltip-glassmorphic .font-medium) {
  color: rgba(226, 232, 240, 0.9) !important;
}

.dark :deep(.echarts-tooltip-glassmorphic .text-slate-500) {
  color: rgba(148, 163, 184, 0.8) !important;
}

.dark :deep(.echarts-tooltip-glassmorphic .text-slate-700) {
  color: rgba(226, 232, 240, 0.9) !important;
}

.sticky-header {
  min-height: 52px;
  display: flex;
  align-items: center;
}
</style> 