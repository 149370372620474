import { ref, computed, watch, nextTick } from 'vue'
import type { CodeLayoutSplitNInstance, CodeLayoutSplitNPanelInternal } from '@edanweis/vue-code-layout'
import { useLayoutStore } from '@edanweis/vue-code-layout'
import { useState } from '#imports'
import type { PanelData as BasePanelData } from '@/types/projectdock'

// Update PanelData interface to include hover property
export interface PanelData extends BasePanelData {
  hover?: boolean;
}

// Add PanelDataState interface
export interface PanelDataState {
  [panelId: string]: {
    data: PanelData;
    lastUpdate: number;
  }
}

export const usePanelData = () => {
  // Use Nuxt's useState for SSR compatibility
  const panelDataState = useState<PanelDataState>('panel-data-state', () => ({}))
  const layoutStore = useLayoutStore()
  
  // Keep track of registered panels
  const registeredPanels = new Set<string>()
  
  // Flag to prevent recursive updates
  const isUpdating = ref(false)

  // Register a panel to be tracked
  const registerPanel = (panelId: string, initialData?: PanelData) => {
    if (!registeredPanels.has(panelId)) {
      panelDataState.value[panelId] = {
        data: {
          ...initialData || {
            tool_uid: '',
            output: undefined,
            running: false,
            connections: [],
            hover: false
          },
          created_at: initialData?.created_at || new Date().toISOString()
        },
        lastUpdate: Date.now()
      }
      registeredPanels.add(panelId)
    }
  }

  // Find panel by file_id and tool_uid
  const findPanelByFileAndTool = (file_id: string, tool_uid: string): { panelId: string; data: PanelData } | null => {
    const entries = Object.entries(panelDataState.value)
    const match = entries.find(([_, state]) => 
      state.data.file_id === file_id && state.data.tool_uid === tool_uid
    )
    return match ? { panelId: match[0], data: match[1].data } : null
  }

  // Find panel by panel_id and tool_uid
  const findPanelByIdAndTool = (panel_id: string, tool_uid: string): { panelId: string; data: PanelData } | null => {
    if (panelDataState.value[panel_id]?.data.tool_uid === tool_uid) {
      return { panelId: panel_id, data: panelDataState.value[panel_id].data }
    }
    return null
  }

  // Unregister a panel
  const unregisterPanel = (panelId: string) => {
    if (registeredPanels.has(panelId)) {
      delete panelDataState.value[panelId]
      registeredPanels.delete(panelId)
    }
  }

  // Update panel data
  const updatePanelData = (panelId: string, newData: Partial<PanelData>, splitLayoutRef?: CodeLayoutSplitNInstance) => {
    // If already updating, prevent recursive updates
    if (isUpdating.value) return;
    
    // Check if we're only updating hover state and it's the same as current
    const currentData = panelDataState.value[panelId]?.data || {};
    if (Object.keys(newData).length === 1 && 'hover' in newData && newData.hover === currentData.hover) {
      return; // Skip update if only hover is changing and it's the same value
    }
    
    try {
      isUpdating.value = true;
      
      if (!registeredPanels.has(panelId)) {
        registerPanel(panelId);
      }
      
      // Create a new object to break reactivity chains
      let newDataCopy;
      try {
        // Try modern structuredClone first
        newDataCopy = structuredClone(newData);
      } catch (e) {
        // Fallback to JSON method if not available
        try {
          newDataCopy = JSON.parse(JSON.stringify(newData));
        } catch (e2) {
          // Last resort - shallow copy
          newDataCopy = { ...newData };
        }
      }
      
      // Handle array properties specially to create new arrays
      if (Array.isArray(newDataCopy.connections)) {
        newDataCopy.connections = [...newDataCopy.connections];
      } else if (Array.isArray(currentData.connections)) {
        newDataCopy.connections = [...currentData.connections];
      }
      
      if (Array.isArray(newDataCopy.history)) {
        newDataCopy.history = [...newDataCopy.history];
      } else if (Array.isArray(currentData.history)) {
        newDataCopy.history = [...currentData.history];
      }
      
      // Create a completely new object
      const updatedData = {
        ...currentData,
        ...newDataCopy
      };
      
      // Update state
      panelDataState.value[panelId] = {
        data: updatedData,
        lastUpdate: Date.now()
      };
      
      // Update layout if provided
      if (splitLayoutRef) {
        const panel = splitLayoutRef.getPanelByName(panelId);
        if (panel) {
          // Avoid direct mutation of panel.data
          setTimeout(() => {
            panel.data = { ...updatedData };
            
            setTimeout(() => {
              try {
                splitLayoutRef.getRootGrid().notifyRelayout();
              } catch (error) {
                console.error('Error updating layout:', error);
              }
            }, 50);
          }, 0);
        }
      }
    } catch (error) {
      console.error(`Error updating panel data for ${panelId}:`, error);
    } finally {
      // Reset flag after a delay to break call stack
      setTimeout(() => {
        isUpdating.value = false;
      }, 10);
    }
  };

  // Get panel data
  const getPanelData = (panelId: string) => {
    return panelDataState.value[panelId]?.data
  }

  // Get last update time
  const getPanelLastUpdate = (panelId: string) => {
    return panelDataState.value[panelId]?.lastUpdate
  }

  // Watch for changes in the layout instance
  watch(() => layoutStore.layoutInstance?.value, (instance) => {
    if (instance) {
      // Prevent recursive updates when syncing
      if (isUpdating.value) return
      
      try {
        isUpdating.value = true
        // When layout instance changes, sync all registered panels
        registeredPanels.forEach(panelId => {
          const panel = instance.getPanelByName(panelId)
          if (panel && panelDataState.value[panelId]) {
            // Important: preserve existing panel data by using object spread
            panel.data = {
              ...panel.data,
              ...panelDataState.value[panelId].data
            }
          }
        })
        instance.getRootGrid().notifyRelayout()
      } finally {
        isUpdating.value = false
      }
    }
  })

  return {
    registerPanel,
    unregisterPanel,
    updatePanelData,
    getPanelData,
    getPanelLastUpdate,
    panelDataState,
    findPanelByFileAndTool,
    findPanelByIdAndTool
  }
} 