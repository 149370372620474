<script setup lang="ts">
const props = defineProps({
  width: {
    type: String,
    default: '100%'  // Changed default to 100%
  },
  height: {
    type: String,
    default: '100%'  // Changed default to 100%
  },
  gradient: {
    type: Array as () => string[],
    default: () => ['#3023AE', '#f09']
  },
  animationDuration: {
    type: String,
    default: '6s'  // Faster default animation
  },
  opacity: {
    type: Number,
    default: 0.1
  }
})

const gradientStyle = computed(() => {
  const color1 = props.gradient[0]
  const color2 = props.gradient[1] || props.gradient[0]
  
  // If colors are already in HSLA format, use them directly
  if (color1.startsWith('hsla')) {
    return `linear-gradient(135deg, ${color1}, ${color2})`
  }
  
  // Otherwise, apply opacity
  return `linear-gradient(135deg, ${addOpacityToColor(color1, props.opacity)}, ${addOpacityToColor(color2, props.opacity)})`
})

const addOpacityToColor = (color: string, opacity: number): string => {
  if (color.startsWith('rgba')) {
    return color.replace(/[\d.]+\)$/g, `${opacity})`)
  }
  
  if (color.startsWith('#')) {
    const r = parseInt(color.slice(1, 3), 16)
    const g = parseInt(color.slice(3, 5), 16)
    const b = parseInt(color.slice(5, 7), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }
  
  if (color.startsWith('rgb')) {
    return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`)
  }
  
  return color
}
</script>

<template>
  <div 
    class="blob" 
    :style="{
      width: width,
      height: height,
      backgroundImage: gradientStyle,
      animationDuration: animationDuration
    }"
  >
    <slot></slot>
  </div>
</template>

<style scoped>
.blob {
  position: absolute; 
  inset: 0; 
  background: v-bind('gradient[0]');
  animation: wobble ease-in-out infinite;
  transition: all 0.3s ease;
  mix-blend-mode: multiply;
}

@keyframes wobble {
  0% {
    border-radius: 80% 20% 55% 45% / 70% 15% 85% 30%;
    transform: rotate(0deg) scale(1);
  }
  25% {
    border-radius: 15% 85% 25% 75% / 85% 25% 75% 15%;
    transform: rotate(-1deg) scale(1.01);
  }
  50% {
    border-radius: 75% 25% 85% 15% / 20% 80% 10% 90%;
    transform: rotate(1deg) scale(0.99);
  }
  75% {
    border-radius: 25% 75% 15% 85% / 75% 15% 85% 25%;
    transform: rotate(-1deg) scale(1.01);
  }
  100% {
    border-radius: 80% 20% 55% 45% / 70% 15% 85% 30%;
    transform: rotate(0deg) scale(1);
  }
}
</style>
