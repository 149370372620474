<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import Button from '@/components/ui/button/Button.vue'
import { cn } from '@/lib/utils'
import { PanelLeft } from 'lucide-vue-next'
import { useSidebar } from './utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
  asChild?: boolean
}>()

const { toggleSidebar } = useSidebar()
</script>

<template>
  <slot v-if="asChild" :toggle="toggleSidebar" />
  <Button
    v-else
    data-sidebar="trigger"
    variant="secondary"
    :class="cn('h-8', props.class)"
    @click="toggleSidebar"
  >
    <!-- <PanelLeft /> -->
    <Icon name="lucide:settings" class="h-4 w-4 mr-2 -top-[1px] relative" />
    Settings
    <span class="sr-only">Toggle Sidebar</span>
  </Button>
</template>
