import type { Tool } from '@/types/tools'
import { MyMap, Gallery, PdfViewerPanel, VisualisePanel, CommunityTool, Discovery, Maintenance, PlantGrid, DiagnosticPanel } from "#components"
import { markRaw } from 'vue'
import { NameStatus, BiosecurityStatus, AvailabilityStatus, DiversityStatus } from '#components'

export const toolDefinitions: Tool[] = [
  {
    uid: "name-validation-tool",
    options: {},
    order: 1000,
    inUse: false,
    buttonType: "Hidden",
    description: "Validates plant species names",
    apiRoute: "",
    location: "",
    id: "nameValidation",
    label: "Name Validation",
    tooltip: "Validates plant species names",
    icon: "lucide:check-circle",
    actionType: "validation",
    component: markRaw(NameStatus),
    componentName: "NameStatus",
    connectionsAllowed: ["plantgrid"],
    connectionsDefault: ["plantgrid"],
    autoConnect: true,
    hidden: true,
  },
  {
    uid: "biosecurity-validation-tool",
    options: {},
    order: 1001,
    inUse: false,
    buttonType: "Hidden",
    description: "Validates plant biosecurity status",
    apiRoute: "",
    location: "",
    id: "biosecurityValidation",
    label: "Biosecurity Validation",
    tooltip: "Validates plant biosecurity status",
    icon: "lucide:shield",
    actionType: "validation",
    component: markRaw(BiosecurityStatus),
    componentName: "BiosecurityStatus",
    connectionsAllowed: ["plantgrid"],
    connectionsDefault: ["plantgrid"],
    autoConnect: true,
    hidden: true,
  },
  {
    uid: "availability-validation-tool",
    options: {},
    order: 1002,
    inUse: false,
    buttonType: "Hidden",
    description: "Validates plant availability",
    apiRoute: "",
    location: "",
    id: "availabilityValidation",
    label: "Availability Validation",
    tooltip: "Validates plant availability",
    icon: "lucide:shopping-cart",
    actionType: "validation",
    component: markRaw(AvailabilityStatus),
    componentName: "AvailabilityStatus",
    connectionsAllowed: ["plantgrid"],
    connectionsDefault: ["plantgrid"],
    autoConnect: true,
    hidden: true,
  },
  {
    uid: "diversity-validation-tool",
    options: {},
    order: 1003,
    inUse: false,
    buttonType: "Hidden",
    description: "Validates plant diversity",
    apiRoute: "",
    location: "",
    id: "diversityValidation",
    label: "Diversity Validation",
    tooltip: "Validates plant diversity",
    icon: "lucide:tree",
    actionType: "validation",
    component: markRaw(DiversityStatus),
    componentName: "DiversityStatus",
    connectionsAllowed: ["plantgrid"],
    connectionsDefault: ["plantgrid"],
    autoConnect: true,
    hidden: true,
  },
  {
    uid: "70da4191-cc91-47c7-9d19-a55f2dad613b",
    options: {},
    order: 91,
    inUse: false,
    buttonType: "Button",
    description: "",
    location: "toolbar",
    id: "maintenance",
    label: "Maintenance",
    tooltip: "Maintenance",
    icon: "tabler:shovel",
    actionType: "addPanel",
    component: Maintenance,
    componentName: "Maintenance",
    connectionsAllowed: [],
    connectionsDefault: [],
  },
  {
    uid: "2d06ebb1-9a56-46f0-83ac-910c6974a80b",
    options: {},
    order: 10,
    inUse: false,
    buttonType: "Button",
    description: "",
    apiRoute: "",
    location: "toolbar",
    id: "discovery",
    label: "Discover Plants",
    tooltip: "Discover Plants",
    icon: "streamline:nature-ecology-tree-2-tree-plant-circle-round-park",
    actionType: "addPanel",
    component: null,
    componentName: "Discovery",
    connectionsAllowed: ["gallery"],
    connectionsDefault: ["gallery"],
  },
  {
    uid: "28840ba0-1bd2-4ceb-93fa-322131ad1659",
    options: {},
    order: 20,
    inUse: false,
    buttonType: "Button",
    description: "",
    apiRoute: "/api/tool/map",
    location: "toolbar",
    id: "map",
    label: "Map",
    tooltip: "Add Map",
    icon: "carbon:earth-filled",
    actionType: "addPanel",
    defaultData: { center: [12.550343, 55.665957], zoom: 8 },
    component: null,
    componentName: "MyMap",
    connectionsAllowed: ["schedule", "visualise", "plantgrid", "gallery"],
    connectionsDefault: ["plantgrid"],
  },
  {
    uid: "49cff42f-c0a8-44cb-9f8a-85012507ba5e",
    options: {},
    order: 40,
    inUse: false,
    buttonType: "Button",
    description: "",
    apiRoute: "/api/tool/optimise",
    location: "",
    id: "optimise",
    label: "Optimise",
    tooltip: "Optimise planting pallet",
    icon: "lucide:scatter-chart",
    actionType: "addPanel",
    component: null,
    componentName: "",
    connectionsAllowed: ["schedule"],
    connectionsDefault: ["schedule"],
  },
  {
    uid: "5b1d91fd-39ae-4ff4-849e-2508541fb1e3",
    options: {},
    order: 50,
    inUse: false,
    buttonType: "Button",
    description: "",
    apiRoute: "/api/tool/plants",
    location: "",
    id: "plants",
    label: "Plants",
    tooltip: "Add plants",
    icon: "ph:plant",
    actionType: "toggleCommandDialog",
    component: null,
    componentName: "",
    connectionsAllowed: [],
    connectionsDefault: [],
  },
  {
    uid: "7cd08128-4c43-4016-9bb1-48bd11b8c93f",
    options: {
      premium_mode: false,
      invalidate_cache: false,
      use_vendor_multimodal_model: false,
      apiService: 'gemini',
      noImageExtraction: true
    },
    order: 71,
    inUse: true,
    buttonType: "Button",
    description: "Create a new plant schedule",
    location: "toolbar",
    id: "plantgrid",
    label: "Plant Schedule",
    tooltip: "Create New Schedule",
    icon: "mdi-light:table",
    actionType: "addPanel",
    defaultData: {
      name: "plantgrid",
      columnDefs: [],
      rowData: []
    },
    component: PlantGrid,
    componentName: "PlantGrid",
    connectionsAllowed: ["map", "optimise", "visualise", "gallery", "pdfviewer"],
    connectionsDefault: [],
  },
  {
    uid: "f82b9927-7d8a-45e4-85df-1ced6b82af8b",
    options: {},
    order: 70,
    inUse: false,
    buttonType: "Toggle",
    description: "",
    apiRoute: "/api/tool/chat",
    location: "",
    id: "chat",
    label: "Chat",
    tooltip: "Chat",
    icon: "solar:chat-line-linear",
    actionType: "toggleChat",
    component: null,
    componentName: "",
    connectionsAllowed: [],
    connectionsDefault: [],
  },
  {
    uid: "392216f6-c82d-4950-81dc-3188cdaa69cc",
    options: {},
    order: 80,
    inUse: false,
    buttonType: "Button",
    description: "",
    apiRoute: "/api/tool/visualise",
    location: "",
    id: "visualise",
    label: "Visualise",
    tooltip: "Visualise",
    icon: "uim:scenery",
    actionType: "addPanel",
    component: VisualisePanel,
    componentName: "VisualisePanel",
    connectionsAllowed: ["schedule", "map"],
    connectionsDefault: ["map"],
  },
  {
    uid: "1ee002ac-ba72-45be-9441-2cf2c7f9d22b",
    options: {},
    order: 90,
    inUse: true,
    buttonType: "Button",
    description: "",
    apiRoute: "/api/tool/gallery",
    location: "toolbar",
    id: "gallery",
    label: "Gallery",
    tooltip: "Gallery",
    icon: "solar:gallery-wide-bold",
    actionType: "addPanel",
    defaultData: { images: [] },
    component: Gallery,
    componentName: "Gallery",
    connectionsAllowed: ["plantgrid", "map"],
    connectionsDefault: ["plantgrid"],
  },
  // {
  //   uid: "6a337643-2770-4cfe-8557-7c8409bdea7e",
  //   options: {},
  //   order: 100,
  //   inUse: false,
  //   buttonType: "Button",
  //   description: "",
  //   apiRoute: "/api/tool/review",
  //   location: "",
  //   id: "review",
  //   label: "Review",
  //   tooltip: "Review your project",
  //   icon: "material-symbols-light:planner-review-rounded",
  //   actionType: "addPanel",
  //   component: Review,
  //   componentName: "Review",
  //   connectionsAllowed: ["schedule"],
  //   connectionsDefault: ["schedule"],
  // },
  {
    uid: "8b6ee2d8-ef11-462a-a6c8-b31b6bd21424",
    options: {},
    order: 110,
    inUse: true,
    buttonType: "Button",
    description: "",
    apiRoute: "/api/tool/pdfviewer",
    location: "toolbar",
    id: "pdfviewer",
    label: "PDF",
    tooltip: "View PDF",
    icon: "ph:file-pdf-light",
    actionType: "addPanel",
    defaultData: {
      url: "",
    },
    component: PdfViewerPanel,
    componentName: "PdfViewerPanel",
    connectionsAllowed: ["plantgrid"],
    connectionsDefault: [],
  },
  {
    uid: "34f07a70-3633-4408-bda9-ebf80dc01231",
    options: {},
    order: 120,
    inUse: false,
    buttonType: "Button",
    description: "",
    apiRoute: "/api/tool/community",
    location: "",
    id: "community",
    label: "Community",
    tooltip: "Community",
    icon: "material-symbols-light:hive-outline-rounded",
    actionType: "showCommunity",
    component: CommunityTool,
    componentName: "CommunityTool",
    connectionsAllowed: [],
    connectionsDefault: [],
  },
  {
    uid: "e4f07a70-3633-4408-bda9-ebf80dc01232",
    options: {},
    order: 130,
    inUse: false,
    buttonType: "Button",
    description: "Enrich uploaded files with additional data",
    apiRoute: "/api/tool/enrich",
    location: "",
    id: "enrich",
    label: "Enrich",
    tooltip: "Enrich file data",
    icon: "material-symbols:enhanced-encryption",
    actionType: "addPanel",
    component: null,
    componentName: "",
    connectionsAllowed: [],
    connectionsDefault: [],
  },
  {
    uid: "9bf08d60-4e7a-4c42-a1ab-3e8f1d8c9e10",
    options: {},
    order: 140,
    inUse: false,
    buttonType: "Button",
    description: "Monitor panel connections and data transformations",
    location: "",
    id: "diagpanel",
    label: "Diagnostics",
    tooltip: "Connection Monitor",
    icon: "mdi:monitor-dashboard",
    actionType: "addPanel",
    defaultData: {
      events: [],
      maxEvents: 100,
      autoScroll: true,
      filterTypes: []
    },
    component: DiagnosticPanel,
    componentName: "DiagnosticPanel",
    connectionsAllowed: ["plantgrid"], // Can connect to any panel type
    connectionsDefault: [],
  }
]; 


export const extensionMap: Record<string, string[]> = {
  png: ["plantImage"],
  jpg: ["plantImage"],
  jpeg: ["plantImage"],
  xlsx: ["PlantGrid"],
  doc: ["report"],
  pdf: ["PlantGrid"],
};

export const mimeTypeMap: Record<string, string[]> = {
  "image/": ["plantImage"],
  "text/": ["PlantGrid"],
  "application/pdf": ["PlantGrid"],
}; 