// Import types and constants from separate files
import type { MaintenanceInputs, CostResults } from '~/types/maintenance'
import { 
  tree_costs,
  cost_explanations,
  input_variables,
  pot_size_mapping,
  costCategories,
  cashflow_budget_data
} from '~/constants/maintenance'
import { useUiStore } from '~/stores/uiStore'

// Add type for yearlyData
interface YearlyData {
  establishment: number[];
  inspections_and_maintenance: number[];
  net_mortality: number[];
}

// Add type for pot size mapping at the top of the file
type PotSizeKey = "25-50L" | "75-100L" | "250L"
type VolumeKey = "volume_25_50L" | "volume_75_100L" | "volume_250L"

// First, let's add proper typing for the tree costs
type CostStructure = {
  p0: number;
  p5: number;
  p25: number;
  p50: number;
  p75: number;
  p95: number;
  p100: number;
}

type TreeCosts = {
  [K in VolumeKey]: {
    concrete_cutting: CostStructure;
    supply: CostStructure;
    tree_installation: CostStructure;
    mulch_cost: CostStructure;
    stakes_and_ties: CostStructure;
    installation_cost: CostStructure;
    machine_rate: CostStructure;
    tree_removal: CostStructure;
    soil_cost: CostStructure;
    guard_rails: CostStructure;
    tree_protection_fencing: CostStructure;
    maintenance_year_1?: CostStructure;
    maintenance_year_2?: CostStructure;
    maintenance_year_3_plus?: CostStructure;
    gis_mapping?: CostStructure;
    watering?: CostStructure;
    visual_inspection?: CostStructure;
    arborist_inspection?: CostStructure;
  }
}

// Add type for custom costs
interface CustomCosts {
  [volumeKey: string]: {
    [costType: string]: number  // Using p50 values
  }
}

export function useMaintenance() {
  const uiStore = useUiStore()

  // State with default values
  const defaultInputs: MaintenanceInputs = {
    tree_quantities: { 
      value: [
        { size: "25-50L", quantity: 100 }
      ] 
    },
    appraisal_period: { value: 30 },
    inflation_rate: { value: 2.5 },
    discount_rate: { value: 7 },
    mortality_under_a_poor_maintenance_regime: { value: 15 },
    mortality_under_a_good_maintenance_regime: { value: 5 },
    post_establishment_mortality_rate: { value: 2 },
    mortality_rate_due_to_accidents_and_vandalism: { value: 3 },
    maintenance_periods: {
      value: [
        { year: 1, intensity: 100 }, // Establishment
        { year: 3, intensity: 60 },  // Post-establishment
        { year: 5, intensity: 30 }   // Recurrent
      ]
    },
    post_establishment_years: { value: 6 }
  }

  // Make inputs reactive
  const inputs = ref<MaintenanceInputs>(defaultInputs)
  const results = ref<CostResults | null>(null)
  const calculationStatus = ref<'idle' | 'calculating' | 'error'>('idle')

  // Constants
  const DEFAULT_PERCENTILE = 'p50'
  const DEFAULT_NUM_TREES = 100

  // Computed values that update when inputs change
  const chartOption = computed(() => {
    console.log('Recalculating chart option with inputs:', inputs.value)
    return plotCashflowCosts(inputs.value)
  })

  // Function to update inputs
  const updateInputs = (newInputs: Partial<MaintenanceInputs>) => {
    console.log('updateInputs called with:', newInputs);
    
    // Create a new object with all inputs
    const updatedInputs = {
      ...inputs.value,
      ...Object.entries(newInputs).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: { value: value?.value }
      }), {})
    };
    
    console.log('Updated inputs will be:', updatedInputs);
    
    // Update the inputs ref
    inputs.value = updatedInputs;
    
    // Force recalculation of all dependent values
    const newResults = calculateResults(updatedInputs);
    results.value = newResults;
    
    // Update chart with new values
    const newChartOption = plotCashflowCosts(updatedInputs);
    chartOption.value = newChartOption;
  }

  // Function to reset inputs to defaults
  const resetInputs = () => {
    inputs.value = { ...defaultInputs }
  }

  // Watch for costSeries changes and recalculate results
  watch(
    () => uiStore.costSeries,
    () => {
      console.log('Cost series changed, recalculating results');
      results.value = calculateResults(inputs.value);
      chartOption.value = plotCashflowCosts(inputs.value);
    },
    { deep: true }
  );

  // Watch for changes and recalculate results
  watch(
    () => ({
      potSize: inputs.value.average_tree_pot_size?.value,
      treeCount: inputs.value.number_of_trees_in_the_project?.value,
      appraisalPeriod: inputs.value.appraisal_period?.value,
      inflationRate: inputs.value.inflation_rate?.value,
      discountRate: inputs.value.discount_rate?.value,
      poorMaintenance: inputs.value.mortality_under_a_poor_maintenance_regime?.value,
      goodMaintenance: inputs.value.mortality_under_a_good_maintenance_regime?.value,
      postEstablishment: inputs.value.post_establishment_mortality_rate?.value,
      accidents: inputs.value.mortality_rate_due_to_accidents_and_vandalism?.value
    }),
    (newValues, oldValues) => {
      console.log('Inputs changed:', {
        from: oldValues,
        to: newValues
      });
      
      // Recalculate results with all current inputs
      results.value = calculateResults(inputs.value);
      chartOption.value = plotCashflowCosts(inputs.value);
    },
    { deep: true }
  );

  // Calculate all results
  const calculateResults = (currentInputs: MaintenanceInputs, customVolume?: number) => {
    console.log('Calculating results with inputs:', currentInputs);
    
    const establishmentCosts = calculateEstablishmentCosts(currentInputs, customVolume);
    const maintenanceCosts = calculateMaintenanceCosts(currentInputs);
    const yearlyData = generateYearlyData(currentInputs, customVolume);
    const lifeCycleCosts = calculateLifeCycleCosts(currentInputs, yearlyData);

    console.log('Calculation results:', {
      establishmentCosts,
      maintenanceCosts,
      yearlyData,
      lifeCycleCosts
    });

    return {
      establishmentCosts,
      maintenanceCosts,
      yearlyData,
      lifeCycleCosts
    };
  }

  // Helper functions
  const getDefaultValue = (inputName: string) => {
    const variable = input_variables.find(v => v.input === inputName)
    return variable?.default
  }

  // Add helper to get current cost (either custom or default)
  const getCurrentCost = (volumeKey: VolumeKey, costType: string): number => {
    const defaultCosts = tree_costs[volumeKey][costType]
    if (!defaultCosts) return 0

    const customCosts = uiStore.getCostPercentiles(
      volumeKey,
      costType,
      defaultCosts
    )
    return customCosts.p50
  }

  // Main calculation functions
  const calculateEstablishmentCosts = (inputs: MaintenanceInputs, customVolume?: number) => {
    const costs: Record<string, number> = {};
    const treeQuantities = inputs.tree_quantities.value;
    
    // Initialize cost categories
    Object.keys(costCategories.establishment).forEach(category => {
      costs[category] = 0;
    });

    // Calculate costs for each pot size
    treeQuantities.forEach(({ size, quantity }) => {
      const costKey = pot_size_mapping[size] as VolumeKey;
      
      // If a custom volume is provided, use it instead of the standard pot size
      const volumeMap = { 
        "25-50L": 37.5, 
        "75-100L": 87.5, 
        "250L": 250 
      };
      // Use either custom volume or default for the pot size
      const volume = customVolume || volumeMap[size];
      
      // Calculate each establishment cost for this pot size
      Object.entries(costCategories.establishment).forEach(([name, config]) => {
        const costType = config.unitCost
        
        // Get either volume-interpolated cost or standard cost
        let baseCost = 0;
        if (customVolume) {
          // Volume-based interpolation for custom volumes
          // Find series in tree_costs or interpolate between standard volumes
          let foundExactSize = false;
          
          // Check if we have an exact volume match in tree_costs
          if (volume === 37.5) {
            baseCost = getCurrentCost('volume_25_50L', costType);
            foundExactSize = true;
          } else if (volume === 87.5) {
            baseCost = getCurrentCost('volume_75_100L', costType);
            foundExactSize = true;
          } else if (volume === 250) {
            baseCost = getCurrentCost('volume_250L', costType);
            foundExactSize = true;
          }
          
          // Interpolate between standard volumes if no exact match
          if (!foundExactSize) {
            if (volume < 87.5) {
              // Interpolate between 25-50L and 75-100L
              const ratio = (volume - 37.5) / 50;
              baseCost = getCurrentCost('volume_25_50L', costType) * (1 - ratio) + 
                     getCurrentCost('volume_75_100L', costType) * ratio;
            } else if (volume < 250) {
              // Interpolate between 75-100L and 250L
              const ratio = (volume - 87.5) / 162.5;
              baseCost = getCurrentCost('volume_75_100L', costType) * (1 - ratio) + 
                     getCurrentCost('volume_250L', costType) * ratio;
            } else {
              // Extrapolate beyond 250L using power law
              const ratio = volume / 250;
              baseCost = getCurrentCost('volume_250L', costType) * Math.pow(ratio, 0.7);
            }
          }
        } else {
          // Standard cost lookup without interpolation
          baseCost = getCurrentCost(costKey, costType);
        }

        switch (name) {
          case "Concrete cutting":
            const numConcreteCuts = inputs.number_of_trees_requiring_concrete_cutting?.value ?? 2;
            costs[name] += baseCost * (numConcreteCuts * (quantity / treeQuantities.reduce((sum, q) => sum + q.quantity, 0)));
            break;

          case "Supply":
          case "Tree installation":
          case "Stakes and ties":
            costs[name] += baseCost * quantity;
            break;

          case "Tree protection fencing":
            if (quantity > 0) {
              costs[name] += baseCost * quantity;
            }
            break;

          case "Soil cost":
            const soilVolume = inputs.volume_of_required_soil?.value ?? 2;
            costs[name] += baseCost * soilVolume * quantity;
            break;

          case "Mulch cost":
            const mulchVolume = inputs.volume_of_mulch_required?.value ?? 0.5;
            costs[name] += baseCost * mulchVolume * quantity;
            break;
        }
      });
    });

    // Calculate total establishment cost
    costs["Total establishment costs"] = Object.values(costs).reduce((a, b) => a + b, 0);

    return costs as EstablishmentCosts;
  };

  const calculateMaintenanceCosts = (inputs: MaintenanceInputs) => {
    const treeQuantities = inputs.tree_quantities.value;
    const maintenancePeriods = inputs.maintenance_periods.value;
    
    // Initialize costs by year
    const yearlyMaintenance: Record<number, Record<string, number>> = {};
    
    // Calculate maintenance intensity for each year
    const getIntensityForYear = (year: number) => {
      const sortedPeriods = [...maintenancePeriods].sort((a, b) => a.year - b.year);
      const maxYear = inputs.post_establishment_years?.value ?? 6;
      
      // If before first point, use first point's intensity
      if (year < sortedPeriods[0].year) {
        return sortedPeriods[0].intensity / 100;
      }
      
      // If after post-establishment period, use last point's intensity
      if (year > maxYear) {
        return sortedPeriods[sortedPeriods.length - 1].intensity / 100;
      }
      
      // Find the two points that surround this year
      for (let i = 0; i < sortedPeriods.length - 1; i++) {
        const current = sortedPeriods[i];
        const next = sortedPeriods[i + 1];
        
        if (year >= current.year && year <= next.year) {
          // Linear interpolation between the two points
          const yearRange = next.year - current.year;
          const yearProgress = (year - current.year) / yearRange;
          const intensityDiff = next.intensity - current.intensity;
          const interpolatedIntensity = current.intensity + (intensityDiff * yearProgress);
          
          return interpolatedIntensity / 100;
        }
      }
      
      // If we're exactly on one of the points, use its intensity
      const exactPoint = sortedPeriods.find(p => p.year === year);
      if (exactPoint) {
        return exactPoint.intensity / 100;
      }
      
      // Fallback to last point's intensity
      return sortedPeriods[sortedPeriods.length - 1].intensity / 100;
    };

    // Calculate base costs for each year
    for (let year = 1; year <= 50; year++) {
      const intensity = getIntensityForYear(year);
      yearlyMaintenance[year] = {
        Watering: 0,
        Maintenance: 0,
        "Arborist inspection": 0,
        "Visual inspection": 0,
        total: 0
      };
      
      // Calculate costs for each pot size with intensity scaling
      treeQuantities.forEach(({ size, quantity }) => {
        const costKey = pot_size_mapping[size] as VolumeKey;
        const treeCosts = tree_costs[costKey];
        
        if (!treeCosts) return;

        // Get base costs from UI store - use the new 'maintenance' cost type
        const wateringCosts = uiStore.getCostPercentiles(costKey, 'watering', treeCosts.watering || {});
        const maintenanceBaseCost = uiStore.getCostPercentiles(costKey, 'maintenance', treeCosts.maintenance || {});
        const arboristInspectionCosts = uiStore.getCostPercentiles(costKey, 'arborist_inspection', treeCosts.arborist_inspection || {});
        const visualInspectionCosts = uiStore.getCostPercentiles(costKey, 'visual_inspection', treeCosts.visual_inspection || {});
        
        // Scale costs by intensity
        yearlyMaintenance[year].Watering += wateringCosts.p50 * quantity * intensity;
        yearlyMaintenance[year].Maintenance += maintenanceBaseCost.p50 * quantity * intensity;
        
        // Handle inspections (not scaled by intensity)
        for (const [type, inspConfig] of Object.entries(costCategories.maintenance.annual["Tree inspections"].types)) {
          const frequency = inputs[inspConfig.frequency]?.value ?? "Every year";
          if (frequency === "Every year") {
            const numTreesToInspect = inputs[inspConfig.quantity]?.value ?? inspConfig.defaultQuantity;
            const inspectionCost = type === "Arborist" ? 
              arboristInspectionCosts.p50 : 
              visualInspectionCosts.p50;
            yearlyMaintenance[year][`${type} inspection`] = inspectionCost * 
              (numTreesToInspect * (quantity / treeQuantities.reduce((sum, q) => sum + q.quantity, 0)));
          }
        }
      });

      // Calculate total for this year
      yearlyMaintenance[year].total = Object.entries(yearlyMaintenance[year])
        .filter(([key]) => key !== 'total')
        .reduce((sum, [_, value]) => sum + value, 0);
    }

    // Return structured costs for different periods
    return {
      "Year 1": yearlyMaintenance[1],
      "Year 2": yearlyMaintenance[2],
      "Year 3 onwards": yearlyMaintenance[3],
      "Total maintenance costs": Object.values(yearlyMaintenance)
        .reduce((sum, yearCosts) => sum + yearCosts.total, 0),
      "GIS mapping and inventory assessment": treeQuantities.reduce((sum, { size, quantity }) => {
        const costKey = pot_size_mapping[size] as VolumeKey;
        const treeCosts = tree_costs[costKey];
        if (!treeCosts?.gis_mapping) return sum;
        const gisMappingCosts = uiStore.getCostPercentiles(costKey, 'gis_mapping', treeCosts.gis_mapping);
        return sum + gisMappingCosts.p50 * quantity;
      }, 0)
    } as MaintenanceCosts;
  };

  const generateYearlyData = (inputs: MaintenanceInputs, customVolume?: number) => {
    const appraisalPeriod = inputs.appraisal_period?.value || 30;
    const establishmentCosts = calculateEstablishmentCosts(inputs, customVolume);
    const maintenanceCosts = calculateMaintenanceCosts(inputs);
    const discountRate = (inputs.discount_rate?.value ?? 7) / 100;
    const inflationRate = (inputs.inflation_rate?.value ?? 2.5) / 100;
    
    // Calculate real discount rate using Fisher equation
    const realDiscountRate = (1 + discountRate) / (1 + inflationRate) - 1;
    
    // Get total number of trees
    const totalTrees = inputs.tree_quantities.value.reduce((sum, item) => sum + item.quantity, 0);
    
    // Initialize arrays
    const establishment = new Array(50).fill(0);
    const inspections_and_maintenance = new Array(50).fill(0);
    const net_mortality = new Array(50).fill(0);
    
    // Calculate mortality values for each pot size
    inputs.tree_quantities.value.forEach(({ size, quantity }) => {
      const costKey = pot_size_mapping[size];
      const treeCosts = tree_costs[costKey];
      
      if (!treeCosts) {
        console.warn(`No costs found for pot size ${size}`);
        return;
      }

      const poorMaintenanceMortality = (inputs.mortality_under_a_poor_maintenance_regime?.value ?? 15) / 100;
      const goodMaintenanceMortality = (inputs.mortality_under_a_good_maintenance_regime?.value ?? 5) / 100;
      const postEstablishmentMortality = (inputs.post_establishment_mortality_rate?.value ?? 2) / 100;
      const accidentAndVandalismRate = (inputs.mortality_rate_due_to_accidents_and_vandalism?.value ?? 3) / 100;
      const postEstablishmentYears = inputs.post_establishment_years?.value ?? 6;
      
      // Calculate base cost for mortality calculations
      const baseCostForMortality = (
        establishmentCosts["Total establishment costs"] -
        (establishmentCosts["Guard rails"] || 0) -
        (establishmentCosts["Tree protection fencing"] || 0) -
        (establishmentCosts["Tree removal"] || 0) -
        (establishmentCosts["Concrete cutting"] || 0) -
        (establishmentCosts["StrataVault or Strata cells"] || 0)
      );

      // Calculate mortality for each year
      for (let i = 0; i < 50; i++) {
        const discountFactor = 1 / Math.pow(1 + realDiscountRate, i);
        
        if (i === 0) {
          // Year 1: No mortality costs
          net_mortality[i] = 0;
        }
        else if (i === 1) {
          // Year 2: First mortality costs
          const poorMaintenanceLoss = poorMaintenanceMortality * baseCostForMortality;
          const goodMaintenanceLoss = goodMaintenanceMortality * baseCostForMortality;
          const avoidedMortalityCost = poorMaintenanceLoss - goodMaintenanceLoss;
          const accidentLoss = accidentAndVandalismRate * baseCostForMortality;
          
          net_mortality[i] = (poorMaintenanceLoss + accidentLoss) * discountFactor;
        }
        else if (i < postEstablishmentYears) { // Use postEstablishmentYears instead of fixed value
          // Years until post-establishment period ends: Compound the mortality rates
          const previousYearMortality = net_mortality[i - 1] || 0;
          const poorMaintenanceLoss = (1 - poorMaintenanceMortality) * previousYearMortality;
          const goodMaintenanceLoss = (1 - goodMaintenanceMortality) * previousYearMortality;
          const avoidedMortalityCost = poorMaintenanceLoss - goodMaintenanceLoss;
          const accidentLoss = accidentAndVandalismRate * baseCostForMortality;
          
          net_mortality[i] = (poorMaintenanceLoss - avoidedMortalityCost + accidentLoss) * discountFactor;
        }
        else {
          // After post-establishment period: Include post-establishment mortality
          const postEstablishmentLoss = postEstablishmentMortality * baseCostForMortality;
          const accidentLoss = accidentAndVandalismRate * baseCostForMortality;
          
          // Calculate tree removal costs
          let totalRemovalCost = 0;
          inputs.tree_quantities.value.forEach(({ size, quantity }) => {
            const costKey = pot_size_mapping[size];
            const treeCosts = tree_costs[costKey];
            if (treeCosts) {
              const treeRemovalCost = treeCosts.tree_removal?.[DEFAULT_PERCENTILE] ?? 871;
              const proportionalQuantity = quantity / totalTrees;
              const affectedTrees = totalTrees * (postEstablishmentMortality + accidentAndVandalismRate) * proportionalQuantity;
              totalRemovalCost += treeRemovalCost * affectedTrees;
            }
          });

          net_mortality[i] = (postEstablishmentLoss + accidentLoss + totalRemovalCost) * discountFactor;
        }
      }
    });

    // Set establishment and maintenance values with discounting
    establishment[0] = establishmentCosts["Total establishment costs"];
    inspections_and_maintenance[0] = maintenanceCosts["Year 1"]?.total || 0;
    
    // Set future values
    for (let i = 1; i < 50; i++) {
      const discountFactor = 1 / Math.pow(1 + realDiscountRate, i);
      
      // Set establishment costs (only tree replacement costs after year 1)
      if (i >= 1) {
        establishment[i] = net_mortality[i - 1] * discountFactor;
      }

      // Set maintenance values
      if (i === 1) {
        inspections_and_maintenance[i] = (maintenanceCosts["Year 2"]?.total || 0) * discountFactor;
      } else {
        inspections_and_maintenance[i] = (maintenanceCosts["Year 3 onwards"]?.total || 0) * discountFactor;
      }
    }

    // Debug logs
    console.log('Tree Quantities:', inputs.tree_quantities.value);
    console.log('Mortality Calculations:', {
      yearlyMortality: net_mortality.slice(0, 5),
      yearlyEstablishment: establishment.slice(0, 5),
      yearlyMaintenance: inspections_and_maintenance.slice(0, 5)
    });

    return {
      establishment,
      inspections_and_maintenance,
      net_mortality
    };
  }

  // Update the calculateLifeCycleCosts function
  const calculateLifeCycleCosts = (inputs: MaintenanceInputs, yearlyData: YearlyData) => {
    const appraisalPeriod = inputs.appraisal_period?.value ?? 30;
    const discountRate = (inputs.discount_rate?.value ?? 7) / 100;
    const inflationRate = (inputs.inflation_rate?.value ?? 2.5) / 100;
    
    // Calculate real discount rate using Fisher equation
    const realDiscountRate = (1 + discountRate) / (1 + inflationRate) - 1;
    
    const costs: Record<string, number> = {};
    
    // Helper function to calculate NPV
    const calculateNPV = (values: number[]) => {
      const year1Value = values[0] || 0;
      const futureValues = values.slice(1, appraisalPeriod);
      
      // Calculate NPV of future values using real discount rate
      const npv = futureValues.reduce((acc, value, index) => {
        return acc + (value / Math.pow(1 + realDiscountRate, index + 1));
      }, 0);
      
      return year1Value + npv;
    };
    
    // Calculate establishment costs NPV
    costs["Establishment"] = calculateNPV(yearlyData.establishment);
    
    // Calculate inspections and maintenance NPV
    costs["Inspections and maintenance"] = calculateNPV(yearlyData.inspections_and_maintenance);
    
    // Calculate net mortality NPV
    costs["Net mortality"] = calculateNPV(yearlyData.net_mortality);
    
    // Calculate total life cycle costs
    costs["Total life cycle costs"] = Object.entries(costs)
      .filter(([key]) => key !== "Total life cycle costs")
      .reduce((sum, [_, value]) => sum + value, 0);
    
    // Debug log
    console.log('Life Cycle Costs (NPV):', {
      appraisalPeriod,
      discountRate,
      inflationRate,
      realDiscountRate,
      yearByYear: {
        establishment: yearlyData.establishment.slice(0, 5),
        maintenance: yearlyData.inspections_and_maintenance.slice(0, 5),
        mortality: yearlyData.net_mortality.slice(0, 5)
      },
      npvTotals: {
        establishment: costs["Establishment"],
        maintenance: costs["Inspections and maintenance"],
        mortality: costs["Net mortality"],
        total: costs["Total life cycle costs"]
      }
    });
    
    return costs;
  }

  // Update the plotCashflowCosts function to accept a custom volume
  const plotCashflowCosts = (inputs: MaintenanceInputs, customVolume?: number) => {
    const yearlyData = generateYearlyData(inputs, customVolume);
    const appraisalPeriod = inputs.appraisal_period?.value || 30;
    const establishmentCosts = calculateEstablishmentCosts(inputs, customVolume);
    const maintenanceCosts = calculateMaintenanceCosts(inputs);
    const inflationRate = (inputs.inflation_rate?.value || 2.5) / 100;

    // Initialize arrays for the chart
    const years = Array.from({ length: appraisalPeriod }, (_, i) => `Year ${i + 1}`);
    
    // Create series data
    const seriesData: Record<string, number[]> = {
      "Concrete cutting": [],
      "Supply": [],
      "Tree installation": [],
      "Soil cost": [],
      "Mulch cost": [],
      "Stakes and ties": [],
      "Tree protection fencing": [],
      "Maintenance": [],
      "Watering": [],
      "Tree inspections": [],
      "GIS mapping": [],
      "Net Mortality": []
    };

    // Calculate costs for each year
    for (let year = 1; year <= appraisalPeriod; year++) {
      const inflationFactor = Math.pow(1 + inflationRate, year - 1);
      const yearIndex = year - 1;

      if (year === 1) {
        // First year includes all establishment costs
        seriesData["Concrete cutting"].push((establishmentCosts["Concrete cutting"] || 0) * inflationFactor);
        seriesData["Supply"].push((establishmentCosts["Supply"] || 0) * inflationFactor);
        seriesData["Tree installation"].push((establishmentCosts["Tree installation"] || 0) * inflationFactor);
        seriesData["Soil cost"].push((establishmentCosts["Soil cost"] || 0) * inflationFactor);
        seriesData["Mulch cost"].push((establishmentCosts["Mulch cost"] || 0) * inflationFactor);
        seriesData["Stakes and ties"].push((establishmentCosts["Stakes and ties"] || 0) * inflationFactor);
        seriesData["Tree protection fencing"].push((establishmentCosts["Tree protection fencing"] || 0) * inflationFactor);
      } else {
        // Zero out establishment costs after year 1
        Object.keys(seriesData).forEach(key => {
          if (key in establishmentCosts) {
            seriesData[key].push(0);
          }
        });
      }

      // Add maintenance costs
      const yearMaintenance = year === 1 ? maintenanceCosts["Year 1"] :
                            year === 2 ? maintenanceCosts["Year 2"] :
                            maintenanceCosts["Year 3 onwards"];

      seriesData["Maintenance"].push(((yearMaintenance?.Maintenance || 0)) * inflationFactor);
      seriesData["Watering"].push(((yearMaintenance?.Watering || 0)) * inflationFactor);
      seriesData["Tree inspections"].push(((yearMaintenance?.["Arborist inspection"] || 0) + 
                                         (yearMaintenance?.["Visual inspection"] || 0)) * inflationFactor);
      seriesData["GIS mapping"].push(year === 1 ? (maintenanceCosts["GIS mapping and inventory assessment"] || 0) * inflationFactor : 0);

      // Add mortality costs
      seriesData["Net Mortality"].push(yearlyData.net_mortality[yearIndex] * inflationFactor);
    }

    // Define component colors
    const componentColors = {
      // Establishment components
      "Concrete cutting": "#60A5FA",     // blue-400
      "Supply": "#4ADE80",               // green-400
      "Tree installation": "#A3E635",    // lime-400
      "Soil cost": "#34D399",           // emerald-400
      "Mulch cost": "#2DD4BF",          // teal-400
      "Stakes and ties": "#38BDF8",      // sky-400
      "Tree protection fencing": "#22D3EE", // cyan-400
      
      // Maintenance components
      "Maintenance": "#A78BFA",         // violet-400
      "Watering": "#8B5CF6",            // purple-500
      "Tree inspections": "#818CF8",     // indigo-400
      "GIS mapping": "#F87171",         // red-400
      
      // Mortality component
      "Net Mortality": "#FB7185"         // rose-400
    };

    // Create ECharts option
    const option = {
      title: false,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderWidth: 0,
        padding: 10,
        textStyle: {
          color: '#666'
        },
        formatter: (params: any[]) => {
          let result = `<div class="font-semibold text-sm mb-3 px-1">${params[0].name}</div>`;
          let subtotals = {
            establishment: 0,
            maintenance: 0,
            mortality: 0
          };
          
          // Group items by category
          const categories = {
            'Establishment Costs': ["Concrete cutting", "Supply", "Tree installation", "Soil cost", 
                                      "Mulch cost", "Stakes and ties", "Tree protection fencing"],
            'Maintenance Costs': ["Maintenance", "Watering", "Tree inspections", "GIS mapping"],
            'Tree Removal Costs': ["Net Mortality"]
          };
          
          // Create sections for each category
          for (const [category, items] of Object.entries(categories)) {
            let categoryTotal = 0;
            let hasValues = false;
            let categoryContent = `
              <div class="mt-3 px-2">
                <div class="font-medium text-xs text-gray-500 mb-2">${category}</div>`;
            
            params.forEach(param => {
              if (items.includes(param.seriesName) && param.value > 0) {
                hasValues = true;
                const color = componentColors[param.seriesName];
                
                // Add description for mortality costs based on year
                let description = '';
                if (param.seriesName === "Net Mortality") {
                  const year = parseInt(param.name.split(' ')[1]);
                  if (year === 1) {
                    description = '(Initial establishment losses)';
                  } else if (year <= inputs.post_establishment_years?.value) {
                    description = '(Early establishment losses)';
                  } else {
                    description = '(Post-establishment & accident losses)';
                  }
                }
                
                categoryContent += `
                  <div class="flex justify-between text-xs py-1 px-1">
                    <span class="text-gray-600">
                      <span style="
                        background-color: ${color}15;
                        color: ${color};
                        padding: 3px 8px;
                        border-radius: 4px;
                        border: 1px solid ${color}40;
                        font-size: 11px;
                      ">${param.seriesName} ${description}</span>
                    </span>
                    <span class="font-medium ml-6" style="color: ${color}">
                      $${param.value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </span>
                  </div>`;
                categoryTotal += param.value;
                
                // Add to subtotals
                if (category === 'Establishment Costs') subtotals.establishment += param.value;
                if (category === 'Maintenance Costs') subtotals.maintenance += param.value;
                if (category === 'Tree Removal Costs') subtotals.mortality += param.value;
              }
            });
            
            if (hasValues) {
              categoryContent += `
                <div class="flex justify-between text-xs py-2 px-1 border-t border-gray-200 mt-2">
                  <span class="font-medium text-gray-600">Subtotal:</span>
                  <span class="font-semibold ml-6">
                    $${categoryTotal.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </span>
                </div>`;
              categoryContent += '</div>';
              result += categoryContent;
            }
          }
          
          // Add total
          const total = subtotals.establishment + subtotals.maintenance + subtotals.mortality;
          result += `
            <div class="mt-4 pt-2 px-2 border-t-2 border-gray-300">
              <div class="flex justify-between font-semibold text-sm">
                <span>Total:</span>
                <span>$${total.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}</span>
              </div>
            </div>`;
          
          return result;
        }
      },
      legend: {
        data: Object.keys(seriesData),
        bottom: 0,
        type: 'scroll',
        pageButtonPosition: 'end'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: years,
        name: 'Time (years)',
        axisLabel: {
          interval: 0, // Show all labels
          rotate: 45,  // Rotate labels for better fit
          fontSize: 10
        }
      },
      yAxis: {
        type: 'value',
        name: 'Cost ($)',
        min: 0,
        max: function(value: { max: number }) {
          // Return at least 60,000, or round up to next 10k if larger
          return Math.max(60000, Math.ceil(value.max / 10000) * 10000);
        },
        axisLabel: {
          formatter: (value: number) => `$${value.toLocaleString()}`
        }
      },
      series: Object.entries(seriesData).map(([name, data]) => ({
        name,
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: componentColors[name]
        },
        animation: false,
        animationDuration: 300,
        animationDurationUpdate: 300,
        animationEasing: 'cubicInOut',
        animationEasingUpdate: 'cubicInOut',
        data
      }))
    };

    return option;
  }

  const plotCumulativeCosts = (inputs: MaintenanceInputs) => {
    // Calculate yearly data
    const yearlyData = generateYearlyData(inputs);
    const appraisalPeriod = inputs.appraisal_period?.value || 30;
    const inflationRate = (inputs.inflation_rate?.value || 2.5) / 100;

    // Initialize arrays for the chart
    const years = [];
    const cumulativeCosts = [];
    let runningTotal = 0;

    // Create series data for different cost types
    const seriesData = {
      maintenance: [],
      watering: [],
      soil: [],
      tree_installation: [],
      concrete_cutting: [],
      supply: [],
      mulch: [],
      stakes: [],
      tree_protection: [],
      tree_removal: [],
      guard_rails: [],
      strata_vault: [],
      traffic_control: [],
      gis_mapping: [],
      visual_inspection: [],
      arborist_inspection: [],
      user_costs: []
    };

    // Calculate cumulative costs for each year
    for (let year = 1; year <= appraisalPeriod; year++) {
      years.push(`Year ${year}`);

      // Get base costs for this year
      const yearCosts = {
        maintenance: yearlyData.inspections_and_maintenance[year - 1] || 0,
        mortality: yearlyData.net_mortality[year - 1] || 0,
        establishment: yearlyData.establishment[year - 1] || 0
      };

      // Apply inflation adjustment
      const inflationFactor = Math.pow(1 + inflationRate, year - 1);
      Object.keys(yearCosts).forEach(key => {
        yearCosts[key] *= inflationFactor;
      });

      // Add to running total
      runningTotal += Object.values(yearCosts).reduce((sum, cost) => sum + cost, 0);
      cumulativeCosts.push(runningTotal);

      // Add individual cost components (adjusted for inflation)
      Object.entries(seriesData).forEach(([key, array]) => {
        const baseCost = yearCosts[key] || 0;
        array.push(baseCost * inflationFactor);
      });
    }

    // Create ECharts option
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params) => {
          let result = `${params[0].name}<br/>`;
          let total = 0;
          params.forEach(param => {
            if (param.value > 0) {
              result += `${param.seriesName}: $${param.value.toFixed(2)}<br/>`;
              total += param.value;
            }
          });
          result += `<strong>Total: $${total.toFixed(2)}</strong>`;
          return result;
        }
      },
      legend: {
        data: [
          'Maintenance',
          'Watering costs',
          'Soil cost',
          'Tree installation',
          'Concrete cutting',
          'Supply',
          'Mulch cost',
          'Stakes and ties',
          'Tree protection fencing',
          'Tree removal',
          'Guard rails',
          'StrataVault or Strata cells',
          'Traffic control cost',
          'GIS mapping',
          'Visual tree inspection',
          'Arborist tree health inspection',
          'User specified costs'
        ],
        bottom: 0
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: years,
        name: 'Time (years)'
      },
      yAxis: {
        type: 'value',
        name: 'Cost ($)'
      },
      series: Object.entries(seriesData).map(([key, data], index) => ({
        name: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'series'
        },
        data: data
      }))
    };

    return option;
  }

  // Test null pot size
  const testNullPotSize = () => {
    const testInputs = {
      ...defaultInputs,
      average_tree_pot_size: { value: null }
    };
    
    console.log('Testing null pot size:', {
      inputs: testInputs,
      establishmentCosts: calculateEstablishmentCosts(testInputs),
      maintenanceCosts: calculateMaintenanceCosts(testInputs)
    });
  };

  // Public API
  return {
    // State
    inputs,
    results,
    calculationStatus,
    chartOption,

    // Functions
    updateInputs,
    resetInputs,
    calculateEstablishmentCosts,
    calculateMaintenanceCosts,
    calculateLifeCycleCosts,
    generateYearlyData,
    plotCashflowCosts,
    plotCumulativeCosts,
    getDefaultValue,
    calculateResults,

    // Constants
    DEFAULT_PERCENTILE,
    DEFAULT_NUM_TREES,
    pot_size_mapping,
    cost_explanations,
    input_variables
  }
}